import { useMemo, useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { getSignedCookieValue } from './getSignedCookieValue'
const REAUTH_COOKIE_NAME = 'reauth_timeout'

const getTimestamp = (val: unknown): number | undefined => {
  if (!val) return undefined

  if (typeof val === 'number') return val

  if (typeof val === 'string') {
    return parseInt(val, 10) || undefined
  }

  return undefined
}

type ExpState = {
  now: number
  expiresIn: number | undefined
  expiresAt: number | undefined
  isExpired: boolean
  expiresInMins: number | undefined
}

const getState = (expiresAt: number | undefined): ExpState => {
  const now = Date.now()

  const isExpired = expiresAt ? now > expiresAt : true

  const expiresIn = expiresAt && !isExpired ? expiresAt - now : undefined

  const expiresInMins = expiresIn ? expiresIn / 60000 : undefined

  return { now, isExpired, expiresIn, expiresAt, expiresInMins }
}

const INTERVAL = 5000

const useReauthStatus = () => {
  const [cookies] = useCookies()

  const cookie = cookies[REAUTH_COOKIE_NAME]

  const expiresAt = useMemo(() => {
    const parsedValue = getSignedCookieValue(cookie)
    return getTimestamp(parsedValue)
  }, [cookie])

  const [expState, setExpState] = useState<ExpState>(() => getState(expiresAt))

  useEffect(() => {
    const id = setInterval(() => {
      setExpState(getState(expiresAt))
    }, INTERVAL)

    return () => clearInterval(id)
  }, [expiresAt])

  return expState
}

export { useReauthStatus }
