import * as React from 'react'
import { TFunction } from 'i18next'
import {
  format,
  Formats,
  isValidDateString
} from '@toasttab/buffet-pui-date-utilities'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { DeleteIcon } from '@toasttab/buffet-pui-icons'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { Money, PayInterval } from '@local/generated/graphql'
import { trackScheduledPayChange } from '@local/track'

type PayChangeRateProps = {
  /** the label for the rate */
  label: string
  /** the rate as a money object */
  rate: Money | null
  /** the interval for the rate, i.e. hourly or annually */
  interval: PayInterval
}

type EffectiveDateProps = {
  /** a string representing the effective date */
  effectiveDate: string
  /** the label for the effective date*/
  label: string
}

export type PayChangeCardProps = {
  /** the unique id for the element, will be set to React key */
  id: string
  /** job name and location name to display in the scheduled pay change card **/
  jobProps: {
    name: string
    locationName?: string
  }
  /** old rate props including the label, rate, and pay interval **/
  oldRateProps: PayChangeRateProps
  /** new rate props including the label, rate, and pay interval **/
  newRateProps: PayChangeRateProps
  /** effective date props including the label, and date **/
  effectiveDateProps: EffectiveDateProps
  /** the function to call when the trash can is clicked. if nothing is provided, the trash can will not be displayed **/
  deleteOnClick?: VoidFunction
  /** i18n translation function used to translate the copy **/
  /** translations used for this component are amountPerANNUAL, amountPerHOUR, and cancel and expected to exist in the 'common' namespace **/
  t: TFunction
} & TestIdentifiable

export const PayChangeCard: React.FunctionComponent<PayChangeCardProps> = ({
  jobProps,
  oldRateProps,
  newRateProps,
  effectiveDateProps,
  id,
  deleteOnClick,
  t,
  testId
}) => {
  return (
    <CardContainer
      testId={testId}
      className={'space-y-4 p-4'}
      key={id}
      noElevation
      noPadding
    >
      <JobRow {...jobProps} deleteOnClick={deleteOnClick} t={t} />
      <PayRow
        oldRateProps={oldRateProps}
        newRateProps={newRateProps}
        effectiveDateProps={effectiveDateProps}
        t={t}
      />
    </CardContainer>
  )
}

export const JobRow: React.FunctionComponent<
  {
    name: string
    locationName?: string
    deleteOnClick?: VoidFunction
    t: TFunction
  } & TestIdentifiable
> = ({
  testId = 'scheduled-pay-change-job-row',
  name,
  locationName,
  deleteOnClick,
  t
}) => {
  return (
    <div data-testid={testId} className={'flex flex-row space-between'}>
      <div className={'flex flex-col flex-grow space-y-1'}>
        <div className='font-semibold text-default'>{name}</div>
        {locationName && <div>{locationName}</div>}
      </div>
      {deleteOnClick && (
        <div className={'flex'}>
          <IconButton
            {...trackScheduledPayChange('cancel-initiate')}
            testId={`${testId}-cancel`}
            icon={
              <DeleteIcon
                aria-label={t('cancel', 'Cancel', { ns: 'common' })}
              />
            }
            iconColor={'secondary'}
            cropToIcon={true}
            onClick={deleteOnClick}
          />
        </div>
      )}
    </div>
  )
}

export const PayRow: React.FunctionComponent<
  {
    oldRateProps: PayChangeRateProps
    newRateProps: PayChangeRateProps
    effectiveDateProps: EffectiveDateProps
    t: TFunction
  } & TestIdentifiable
> = ({
  oldRateProps,
  newRateProps,
  effectiveDateProps,
  t,
  testId = 'scheduled-pay-change-pay-row'
}) => {
  return (
    oldRateProps.rate &&
    newRateProps.rate && (
      <div data-testid={testId} className={'flex flex-row justify-between'}>
        <RateColumn
          testId={'scheduled-pay-change-old-rate'}
          className='text-disabled'
          {...oldRateProps}
          t={t}
        />
        <RateColumn
          testId={'scheduled-pay-change-new-rate'}
          {...newRateProps}
          t={t}
        />
        <EffectiveDateColumn
          testId='scheduled-pay-change-effective-date'
          {...effectiveDateProps}
        />
      </div>
    )
  )
}

type RateColumnProps = {
  className?: string
  t: TFunction
} & PayChangeRateProps &
  TestIdentifiable

const RateColumn: React.FunctionComponent<RateColumnProps> = ({
  className,
  interval,
  label,
  rate,
  t,
  testId = 'scheduled-pay-change-current-pay-rate'
}) => {
  const { locale } = useBuffetContext()
  return (
    <Column
      className={className}
      testId={testId}
      label={label}
      value={
        rate
          ? t(`amountPer${interval}`, formatCurrency(rate, locale), {
              ns: 'common',
              amount: formatCurrency(rate, locale)
            })
          : null
      }
    />
  )
}

const EffectiveDateColumn: React.FunctionComponent<
  EffectiveDateProps & TestIdentifiable
> = ({
  label,
  effectiveDate: effectiveDateString,
  testId = 'scheduled-pay-change-effective-date-column'
}) => {
  const { locale } = useBuffetContext()
  const effectiveDate = isValidDateString(effectiveDateString)
    ? new Date(effectiveDateString)
    : null
  return (
    <Column
      testId={testId}
      label={label}
      value={
        !!effectiveDate
          ? format(effectiveDate, Formats.date.medium, {
              locale: locale,
              timeZone: 'UTC'
            })
          : effectiveDate
      }
    />
  )
}

const Column: React.FunctionComponent<
  { label: string; value: string | null; className?: string } & TestIdentifiable
> = ({
  label,
  value,
  className,
  testId = 'scheduled-pay-change-pay-row-column'
}) => {
  return label && value ? (
    <div data-testid={testId} className={'flex flex-col'}>
      <div className={'type-overline'}>{label}</div>
      <div className={className}>{value}</div>
    </div>
  ) : null
}
