import { create } from 'zustand'
import { HumanResourcesPermission } from '@local/generated/graphql'

type ActorPermissionsStore = {
  actorPermissions: HumanResourcesPermission[]
  setActorPermissions: (value: HumanResourcesPermission[]) => void
}

const initialState = {
  actorPermissions: []
}

export const useActorPermissionsStore = create<ActorPermissionsStore>()(
  (set) => ({
    ...initialState,
    setActorPermissions: (value?: HumanResourcesPermission[]) => {
      set({ actorPermissions: value })
    }
  })
)
