import { useMemo, useLayoutEffect, useRef } from 'react'

type AnyFunc = (...args: any[]) => any

// note: this functionality will eventually be added to react itself and which point this can be removed
// see: https://github.com/reactjs/rfcs/pull/220

/** stabilizes the reference to a callback
 */
const useEvent = <T extends AnyFunc>(fn: T): T => {
  const fnRef = useRef(fn)

  const stableFn = useMemo((...args) => {
    const wrappedfn = () => {
      const currentFn = fnRef.current
      return currentFn?.(...args)
    }

    return wrappedfn as T
  }, [])

  useLayoutEffect(() => {
    fnRef.current = fn
  }, [fn])

  return stableFn
}

export default useEvent
