import { ChevronLeftIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import React from 'react'
import { ActionTable } from './ActionTable'
import { TableRowContent } from '../domain'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

interface Props extends TestIdentifiable {
  /** The function that controls what happens when someone clicks the back chevron (<) button on the page */
  backButtonFn: () => void
  /** The title of the page, shows up at the top */
  title: string
  /** The subtitle of the page, shows up below the title in a smaller font */
  subtitle: string
  /**
   * Denotes if all actions are complete, if so, it will show the button users can click when they've finished all of
   * their actions
   */
  allActionsComplete: boolean
  /** The function that controls what happens when the user clicks the button
   * when they've finished all of their actions */
  allActionsCompleteFn: () => void
  /** The title of the button that shows up when the user has finished all of their actions */
  allActionsCompleteBtnTitle: string
  /** The TableRowContent objects that populate the table */
  tableRowContent: TableRowContent[]
  /**
   * The "type" of logical thing the row represents (documents, forms, employees, etc.). This is used in the footer
   * that says you are "Viewing 1 to X of {tableRowContent.length} type(s)
   */
  tableRowType: string
  /**
   * Denotes whether or not this component is loading and waiting for data. Which will use skellies instead.
   */
  isLoading: boolean
  /** Denotes whether or not this component should display an error instead of data. */
  isError: boolean
  /** This is the text displayed to the user when an error occurs */
  errorText?: string
}

export const ActionTablePage = ({
  backButtonFn,
  title,
  subtitle,
  allActionsComplete,
  allActionsCompleteFn,
  allActionsCompleteBtnTitle = 'Go Back',
  tableRowContent,
  tableRowType = 'row',
  isLoading,
  isError,
  testId = 'action-table',
  errorText = 'There was an error loading this page.'
}: Props) => {
  return (
    <div className='my-8 type-default md:my-12 md:mx-32' data-testid={testId}>
      <div className='flex mx-4 mb-10 md:mx-0'>
        <span
          className='px-2 -mt-1 cursor-pointer md:mt-0 md:pt-2 md:px-4'
          onClick={backButtonFn}
        >
          <ChevronLeftIcon />
        </span>
        <div>
          <div className='mb-4 font-semibold type-headline-4 md:type-headline-1'>
            {title}
          </div>
          <div className='text-secondary'>{subtitle}</div>
          {allActionsComplete && !isLoading && (
            <Button onClick={allActionsCompleteFn} className='mt-6'>
              {allActionsCompleteBtnTitle}
            </Button>
          )}
          {isError && (
            <div>
              <Alert variant={'error'} showIcon>
                {errorText}
              </Alert>
            </div>
          )}
        </div>
      </div>
      {!isError && (
        <ActionTable
          rows={tableRowContent}
          type={tableRowType}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}
