import { create } from 'zustand'
import { HourlyRetroPay, SalaryRetroPay } from '@local/generated/graphql'

type PayChangeModalStore = {
  isHourly?: boolean
  setIsHourly: (value?: boolean) => void
  loading?: boolean
  setLoading: (value: boolean) => void
  nextPaycheckDate?: Date
  setNextPaycheckDate: (value?: Date) => void
  retroPay?: HourlyRetroPay | SalaryRetroPay
  setRetroPay: (value?: HourlyRetroPay | SalaryRetroPay) => void
  saving: boolean
  setSaving: (value: boolean) => void
  reset: () => void
}

const initialState = {
  isHourly: undefined,
  loading: false,
  nextPaycheckDate: undefined,
  retroPay: undefined,
  saving: false
}

export const usePayChangeModalStore = create<PayChangeModalStore>()((set) => ({
  ...initialState,
  setIsHourly: (value?: boolean) => {
    set({ isHourly: value })
  },
  setLoading: (value: boolean) => {
    set({ loading: value })
  },
  setNextPaycheckDate: (value?: Date) => {
    set({ nextPaycheckDate: value })
  },
  setRetroPay: (value?: HourlyRetroPay | SalaryRetroPay) => {
    set({ retroPay: value })
  },
  setSaving: (value: boolean) => {
    set({ saving: value })
  },
  reset: () => {
    set(initialState)
  }
}))
