import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldArray, useFormikContext } from 'formik'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { AddIcon } from '@toasttab/buffet-pui-icons'
import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import { Modal } from '@toasttab/buffet-pui-modal'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  AttachEarningsMutation,
  Currency,
  EmploymentTaxType,
  HourlyEarningInput,
  LumpSumEarningInput,
  useActiveEarningCodesQuery
} from '@local/generated/graphql'
import { EarningsInputPanel } from '@local/change-pay'
import { trackAttachEarnings } from '@local/track'
import {
  AttachEarningsError,
  AttachEarningsErrorActions
} from './AttachEarningsError'
import { getFormattedPaycheckDate } from '../../utils'
import { LoadSpinner } from '../../../components'
import { EarningSchema, EarningsSchema, PayChangeSchema } from '../../domain'
import { RetroPayInputPanel } from './RetroPayInputPanel'
import { useIsViewingSelf } from '../../../../../../hooks'
import { usePayChangeModalStore } from '../../PayChangeModalStore'
import { useJobAssignmentsStore } from '../../../JobAssignmentsStore'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'

export type AttachEarningsModalContentsProps = {
  employmentTaxType?: EmploymentTaxType | null
  title: string
  subtitle: string
  showAttachError: boolean
  onClose: VoidFunction
  onPayChangeSuccess: VoidFunction
}

export const AttachEarningsModalContents: React.FunctionComponent<
  AttachEarningsModalContentsProps
> = ({
  employmentTaxType,
  title,
  subtitle,
  showAttachError,
  onClose,
  onPayChangeSuccess
}) => {
  const { t } = useTranslation('employees')

  const { loading, data } = useActiveEarningCodesQuery({
    variables: {
      employmentTaxType: employmentTaxType || null
    }
  })

  const earningCodes = data?.activeEarningCodes || []

  const { values } = useFormikContext<PayChangeSchema>()
  const saving = usePayChangeModalStore((x) => x.saving)

  return (
    <>
      <Modal.Header>
        <div data-testid='pay-change-modal-header'>{title}</div>
      </Modal.Header>
      <Modal.Body
        data-testid='attach-earnings-modal-contents'
        className='relative'
      >
        <div className='grid gap-y-6'>
          <div
            className='type-default font-normal text-secondary'
            data-testid='pay-change-modal-sub-header'
          >
            {subtitle}
          </div>
          {loading ? (
            <LoadSpinner message={t('loading')} />
          ) : saving ? (
            <LoadSpinner message={t('savingPayChange')} />
          ) : (
            <>
              {showAttachError && (
                <AttachEarningsError translationKeyBase='attachEarningsError' />
              )}
              <RetroPayInputPanel earningCodes={earningCodes} />
              <FieldArray
                name='earnings.earnings'
                render={(arrayHelpers) => (
                  <>
                    {values.earnings?.earnings.map((_earning, index) => (
                      <EarningsInputPanel
                        earningCodeProps={{
                          ...trackAttachEarnings('earning-code'),
                          testId:
                            'pay-change-modal.additional-earning-earning-code-dropdown',
                          label: t('earningCode'),
                          name: `earnings.earnings.${index}.earningCode`,
                          options: earningCodes,
                          placeholder: t('selectEarningCode')
                        }}
                        hoursProps={{
                          ...trackAttachEarnings('hours'),
                          testId: 'pay-change-modal.additional-earning-hours',
                          label: t('hours'),
                          name: `earnings.earnings.${index}.hoursWorked`,
                          suffix: t('hoursAbbr'),
                          helperText: t('retroHoursWorkedInputHelperText')
                        }}
                        hourlyRateProps={{
                          ...trackAttachEarnings('rate'),
                          testId: 'pay-change-modal.additional-earning-rate',
                          label: t('payChange'),
                          name: `earnings.earnings.${index}.hourlyRate`,
                          currency: Currency.USD,
                          suffix: t('dollarPerHour')
                        }}
                        lumpSumProps={{
                          ...trackAttachEarnings('pay'),
                          testId:
                            'pay-change-modal.additional-earning-lump-sum',
                          // removal of hardcoded currency is blocked by support of internationalization of payroll processing
                          currency: Currency.USD,
                          label: t('additionalPay'),
                          name: `earnings.earnings.${index}.lumpSumAmount`,
                          helperText: t('retroLumpSumInputHelperText')
                        }}
                        buttonProps={{
                          ...trackAttachEarnings('delete'),
                          label: t('removeAdditionalPay'),
                          onClick: () => arrayHelpers.remove(index)
                        }}
                        key={index}
                        testId='earnings-input-panel'
                      />
                    ))}
                    <Button
                      {...trackAttachEarnings('add')}
                      testId='pay-change-modal.add-earning'
                      as='a'
                      className='justify-self-start '
                      iconLeft={<AddIcon accessibility='decorative' />}
                      onClick={() =>
                        arrayHelpers.push({ currency: Currency.USD })
                      }
                      variant='text-link'
                    >
                      {t('addAdditionalPay')}
                    </Button>
                  </>
                )}
              />
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {showAttachError && (
          <AttachEarningsErrorActions
            onClose={onClose}
            onPayChangeSuccess={onPayChangeSuccess}
          />
        )}
        {!showAttachError && <Actions />}
      </Modal.Footer>
    </>
  )
}

const Actions: React.FunctionComponent = () => {
  const { t } = useTranslation('employees')
  const { locale } = useBuffetContext()

  const { dirty, isValid, values, setFieldValue } =
    useFormikContext<PayChangeSchema>()
  const loading = usePayChangeModalStore((x) => x.loading)
  const saving = usePayChangeModalStore((x) => x.saving)
  const disabled = loading || saving || !dirty || !isValid

  const nextPaycheckDate = usePayChangeModalStore((x) => x.nextPaycheckDate)

  const label = !!values.earnings
    ? t('attachEarningsButtonLabel', {
        amount: getFormattedSum(values.earnings, locale),
        paycheckDate: getFormattedPaycheckDate(nextPaycheckDate, locale)
      })
    : t('save')

  const onBackClick = () => setFieldValue('currentFormId', 'pay-change-form')

  return (
    <ButtonGroup className='w-full' direction='vertical' buttonSize='lg'>
      <Button
        {...trackAttachEarnings('back')}
        variant='secondary'
        size='lg'
        onClick={onBackClick}
      >
        {t('back')}
      </Button>
      <Button
        {...trackAttachEarnings('save')}
        testId='attach-earnings-modal-save'
        className='flex-grow'
        disabled={disabled}
        form='pay-change-form'
        size='lg'
        type='submit'
      >
        {label}
      </Button>
    </ButtonGroup>
  )
}

export const calculateAmount = (earningData: EarningSchema): number => {
  const isHourlyEarningCode = earningData?.earningCode?.isHourly

  return isHourlyEarningCode
    ? earningData?.hourlyRate * earningData?.hoursWorked
    : earningData?.lumpSumAmount
}

export const getFormattedSum = (
  values: EarningsSchema,
  language: string
): string => {
  const retroPayAmount = calculateAmount(values?.retro)

  let sum = !isNaN(retroPayAmount) ? Number(retroPayAmount) : 0

  values.earnings.forEach((earning: EarningSchema) => {
    const additionalEarningAmount = calculateAmount(earning)

    sum += !isNaN(additionalEarningAmount) ? additionalEarningAmount : 0
  })

  return formatCurrency(
    {
      amount: sum,
      currency: values.retro.currency as Currency
    },
    language as Locale
  )
}

export const submitEarnings = (
  saveAdditionalEarnings: any,
  employeeId: string,
  jobAssignmentId: string | undefined,
  onError: VoidFunction,
  onCompleted: (mutation: AttachEarningsMutation) => void,
  values: EarningsSchema
) => {
  saveAdditionalEarnings({
    variables: {
      employeeId: employeeId,
      jobAssignmentId: jobAssignmentId,
      lumpSumEarnings: formatAdditionalLumpSumEarnings(values),
      hourlyEarnings: formatAdditionalHourlyEarnings(values)
    },
    onCompleted: onCompleted,
    onError: onError
  })
}

const getEarningAmount = (earningData: EarningSchema): string =>
  earningData?.earningCode?.isHourly
    ? earningData?.hourlyRate.toString()
    : earningData?.lumpSumAmount.toString()

const getEarningFormat = (
  earningData: EarningSchema,
  returnHourlyEarnings: boolean
) => {
  const earningAmount = getEarningAmount(earningData)
  const currency = earningData?.currency as Currency
  const earningCodeId = earningData?.earningCode?.value

  const amount = {
    amount: earningAmount,
    currency
  }

  if (returnHourlyEarnings) {
    return {
      rate: amount,
      hours: earningData?.hoursWorked.toString(),
      earningCodeId
    }
  } else {
    return {
      amount,
      earningCodeId
    }
  }
}

const isValidLumpSumEarning = (earningData: EarningSchema) => {
  return (
    earningData?.earningCode?.isHourly === false &&
    earningData?.earningCode?.value &&
    earningData?.currency &&
    earningData?.lumpSumAmount
  )
}

export const formatAdditionalLumpSumEarnings = (
  values: EarningsSchema
): LumpSumEarningInput[] => {
  const additionalEarnings = []

  if (values?.earnings) {
    values.earnings
      .filter((earning) => isValidLumpSumEarning(earning))
      .forEach((earning) => {
        additionalEarnings.push(getEarningFormat(earning, false))
      })
  }

  const retroEarningData = values?.retro

  const isValidLumpSumRetroEarning = isValidLumpSumEarning(retroEarningData)

  if (isValidLumpSumRetroEarning) {
    const formattedEarning = getEarningFormat(retroEarningData, false)

    additionalEarnings.unshift(formattedEarning)
  }

  return additionalEarnings as LumpSumEarningInput[]
}

const isValidHourlyEarning = (earningData: EarningSchema) => {
  return (
    earningData?.earningCode?.isHourly &&
    earningData?.earningCode?.value &&
    earningData?.currency &&
    earningData?.hourlyRate &&
    earningData?.hoursWorked
  )
}

export const formatAdditionalHourlyEarnings = (
  values: EarningsSchema
): HourlyEarningInput[] => {
  const additionalEarnings = []

  if (values?.earnings) {
    values.earnings
      .filter((earning) => isValidHourlyEarning(earning))
      .forEach((earning) => {
        additionalEarnings.push(getEarningFormat(earning, true))
      })
  }

  const retroEarningData = values?.retro

  const isValidHourlyRetroEarning = isValidHourlyEarning(retroEarningData)

  if (isValidHourlyRetroEarning) {
    const formattedEarning = getEarningFormat(retroEarningData, true)

    additionalEarnings.unshift(formattedEarning)
  }

  return additionalEarnings as HourlyEarningInput[]
}

export const useAttachEarningsSnackBar = () => {
  const { t } = useTranslation('employees')
  const { showSuccessSnackBar: baseShowSuccessSnackBar } = useSnackBar()
  const isViewingSelf = useIsViewingSelf()
  const employeeFirstName = useJobAssignmentsStore((x) => x.employeeFirstName)

  return {
    showSuccessSnackBar: () => {
      baseShowSuccessSnackBar(
        isViewingSelf
          ? t('attachEarningsSuccessSelf')
          : t('attachEarningsSuccess', {
              firstName: employeeFirstName
            }),
        {
          testId: 'attach-earnings-success-snackbar'
        }
      )
    }
  }
}
