import * as React from 'react'
import { GeneralErrorBoundary } from '@local/ec-app'
import { useQueryErrorResetBoundary } from 'react-query'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { OverviewCard } from './OverviewCard'
import { useOverviewCardContext } from './OverviewCardBoundary'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'

export const OverviewCardError = ({
  onReloadClick,
  onActionClick,
  cardTitle,
  children
}: React.PropsWithChildren<{
  cardTitle: string
  onReloadClick: () => void
  onActionClick?: () => void
}>) => {
  return (
    <OverviewCard onActionClick={onActionClick} cardTitle={cardTitle}>
      <div className='flex flex-col justify-center items-center'>
        <EmptyState icon={<WarningOutlineIcon />}>
          <div>{children}</div>
          <div>
            Please{' '}
            <button className='text-link p-0' onClick={onReloadClick}>
              reload the page.
            </button>
          </div>
        </EmptyState>
      </div>
    </OverviewCard>
  )
}

export const OverviewCardErrorBoundary = ({
  children
}: React.PropsWithChildren<{}>) => {
  const { reset } = useQueryErrorResetBoundary()
  const { onActionClick, cardTitle, errorText } = useOverviewCardContext()

  return (
    <GeneralErrorBoundary
      fallback={(_err, context) => (
        <OverviewCardError
          cardTitle={cardTitle}
          onActionClick={onActionClick}
          onReloadClick={() => {
            context.resetGroup()
            reset()
          }}
        >
          {errorText}
        </OverviewCardError>
      )}
    >
      {children}
    </GeneralErrorBoundary>
  )
}
