import * as React from 'react'

import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { ChevronRightIcon, MoreHorizIcon } from '@toasttab/buffet-pui-icons'
import { Pagination } from '@toasttab/buffet-pui-pagination'

import { usePayHistoryPaging } from '../stores/PayHistoryStore'
import { FormattedPayHistoryRecord } from '../utils'

import { useOpenPaystubModal } from '../modals/usePaystubModal'
import { MenuDropdown } from '@toasttab/buffet-pui-dropdowns'

export const PaystubsList = ({
  paystubs
}: {
  paystubs: Array<FormattedPayHistoryRecord>
}) => {
  return (
    <>
      <div className='space-y-3 border-b divide-y'>
        {paystubs.map((paystub) => (
          <Paystub paystub={paystub} key={paystub.id} />
        ))}
      </div>
      <PaginationDisplay />
    </>
  )
}

const Paystub = ({ paystub }: { paystub: FormattedPayHistoryRecord }) => {
  const open = useOpenPaystubModal()

  const hasSingleCheck = paystub.view.length === 1
  const [isTapped, setIsTapped] = React.useState(false)

  const handleRowClick = () => {
    if (hasSingleCheck) {
      open(paystub.view[0])
    } else {
      setIsTapped((state) => !state)
    }
  }

  return (
    <div
      key={paystub.id}
      className='flex justify-between py-3'
      role='button'
      onClick={handleRowClick}
    >
      <div>
        <div className='font-semibold text-default' data-testid='check-date'>
          {paystub.formattedCheckDate}
        </div>
        <div className='text-secondary' data-testid='fein'>
          {paystub.allBusinesses.join(', ')}
        </div>
      </div>
      <div className='flex items-center'>
        <span className='tabular-nums' data-testid='net'>
          {paystub.formattedNet}
        </span>
        {hasSingleCheck ? (
          <IconButton
            testId='paystub-link'
            onClick={() => open(paystub.view[0])}
            icon={<ChevronRightIcon className='text-secondary' />}
          />
        ) : (
          <div className='flex justify-end p-1'>
            <MenuDropdown
              forceShow={isTapped}
              renderToggle={(props) => (
                <IconButton
                  {...props}
                  icon={<MoreHorizIcon className='text-secondary' />}
                />
              )}
            >
              {paystub.view.map((view, idx) => (
                <Button
                  className='flex justify-between w-full text-default'
                  key={view}
                  variant='link'
                  data-testid='paystub-link'
                  onClick={() => open(view)}
                  iconRight={<ChevronRightIcon className='text-secondary' />}
                >
                  {paystub.name[idx]}
                </Button>
              ))}
            </MenuDropdown>
          </div>
        )}
      </div>
    </div>
  )
}

const PaginationDisplay = () => {
  const pagination = usePayHistoryPaging()

  if (pagination.showPagination)
    return (
      <div className='flex flex-col items-center'>
        <Pagination {...pagination} />
      </div>
    )

  return null
}
