import * as React from 'react'

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Pagination } from '@toasttab/buffet-pui-pagination'

import { ModalHooksFor } from '@local/modal-provider'
import {
  PayHistoryAuditStoreProvider,
  usePayHistoryAuditPagination,
  usePayHistoryAuditQuery
} from './stores/PayHistoryAuditStore'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useTranslation } from 'react-i18next'
import { useEmployeeId } from '../../../hooks'
import { SpaDateTime } from '@local/shared-services'

const { useOpenModal, useIsModalOpen, useCloseModal } =
  ModalHooksFor<string>('pay-history-audit')

export const usePayHistoryAuditModal = () => {
  const open = useOpenModal()
  return {
    open
  }
}

export const PayHistoryAuditModal = () => {
  const employeeId = useEmployeeId()
  const { t } = useTranslation('employees')
  const isOpen = useIsModalOpen()
  const close = useCloseModal()

  if (!isOpen) {
    return null
  }
  return (
    <PayHistoryAuditStoreProvider employeeId={employeeId}>
      <Modal
        position='pin-right'
        size='xxl'
        isOpen={true}
        shouldCloseOnEsc
        onRequestClose={close}
      >
        <ModalHeader>{t('payHistoryAudit')}</ModalHeader>
        <ModalBody>
          <React.Suspense
            fallback={
              <div className='flex justify-center py-4'>
                <MerryGoRound />
              </div>
            }
          >
            <PayHistoryAuditContent />
          </React.Suspense>
        </ModalBody>
        <ModalFooter>
          <React.Suspense fallback={''}>
            <div className='flex justify-center flex-grow'>
              <PayHistoryAuditPaging />
            </div>
          </React.Suspense>
          <Button className='justify-self-end' onClick={close}>
            {t('close')}
          </Button>
        </ModalFooter>
      </Modal>
    </PayHistoryAuditStoreProvider>
  )
}

export const PayHistoryAuditPaging = () => {
  const pagination = usePayHistoryAuditPagination()

  return <Pagination {...pagination} />
}

export const PayHistoryAuditContent = () => {
  const { t } = useTranslation('employees')
  const query = usePayHistoryAuditQuery()
  const records = query?.data?.data?.PayHistoryAudit?.records || []

  return (
    <div className='divide-y type-default'>
      {records.map((record, index) => (
        <div className='p-2 even:bg-darken-4' key={index}>
          <span className='font-bold'>{record.payPeriod}</span>
          <div className='type-small'>
            {t('payHistoryRecord', {
              action: record.action,
              user: record.modifiedBy,
              date: SpaDateTime.fromString(record.modifiedDate).toFormat('ff')
            })}
          </div>
        </div>
      ))}
    </div>
  )
}
