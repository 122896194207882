import React, { ReactNode } from 'react'
import { Validations } from '@local/shared-services'

export const getSubLabelForRequiredField = (error: string): ReactNode => {
  if (!Validations.isBlank(error)) {
    return getErrorLabel(error, false)
  }
  return null
}

export const getSubLabelForOptionalField = (error: string): ReactNode => {
  if (!Validations.isBlank(error)) {
    return getErrorLabel(error, true)
  }
  return (
    <label className='block pl-1 text-secondary type-subhead'>Optional</label>
  )
}

const getErrorLabel = (error: string, optional: boolean) => {
  return (
    <label className='block pl-1 text-error type-default'>
      {getErrorMessage(error, optional)}
    </label>
  )
}

const getErrorMessage = (error: string, optional: boolean): string => {
  return optional ? 'Optional. ' + error : error
}
