import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { CardContainer } from '@toasttab/buffet-pui-card'

import { FinancialReportIcon } from '@toasttab/buffet-pui-icons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

import { PaystubsList } from './paystubs/list'
import { PaystubsTable } from './paystubs/table'
import { Filters } from './paystubs/filters'

import {
  PayHistoryReportModal,
  usePayHistoryReportModal
} from './modals/usePayHistoryReportModal'
import { ResponsiveLatest } from './latest'
import {
  PayHistoryStoreProvider,
  useFormattedPayHistoryRecords
} from './stores/PayHistoryStore'
import { PayHistoryReport } from '../PayHistoryReport'

import { PayHistoryAuditModal, usePayHistoryAuditModal } from './audit'
import { PayHistoryReportStoreProvider } from '../PayHistoryReport/stores/PayHistoryReportStore'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { PayStubModal } from './modals/usePaystubModal'

export const PayHistory = () => (
  <PayHistoryReportStoreProvider>
    <PayStubModal />
    <PayHistoryReport />
    <PayHistoryReportModal />
    <PayHistoryAuditModal />
    <Contents />
  </PayHistoryReportStoreProvider>
)

const Contents = () => {
  const { t } = useTranslation('employees')

  const audit = usePayHistoryAuditModal()

  return (
    <div className='mb-8 space-y-6'>
      <ResponsiveLatest />
      <CardContainer className='space-y-4'>
        <div className='flex items-center justify-between'>
          <div className='font-bold type-headline-5'>{t('paystubs')}</div>
          <ResponsiveReportButton />
        </div>
        <PayHistoryStoreProvider>
          <Filters />

          <React.Suspense
            fallback={
              <div className='flex items-center justify-center py-4'>
                <MerryGoRound />
              </div>
            }
          >
            <ResponsivePaystubs />
          </React.Suspense>
        </PayHistoryStoreProvider>
      </CardContainer>
      <div className='flex flex-col items-center px-4 space-y-6 md:px-0 md:space-y-0 md:items-start md:justify-between md:pl-4 md:flex-row'>
        <span className='text-secondary'>{t('payHistoryDisclaimer')}</span>

        <Button variant='link' onClick={() => audit.open()}>
          {t('audit')}
        </Button>
      </div>
    </div>
  )
}

const ResponsiveReportButton = () => {
  const { t } = useTranslation('employees')
  const payHistoryReportModal = usePayHistoryReportModal()
  const screen = useScreenSize()

  if (screen > ScreenSize.SM) {
    return (
      <Button
        variant='link'
        type='button'
        onClick={() => payHistoryReportModal.open()}
        iconLeft={<FinancialReportIcon />}
      >
        {t('runReport')}
      </Button>
    )
  }
  return (
    <IconButton
      className='text-secondary'
      type='button'
      icon={<FinancialReportIcon />}
      title={t('runReport')}
      onClick={() => payHistoryReportModal.open()}
    />
  )
}

const ResponsivePaystubs = () => {
  const screen = useScreenSize()
  const paystubs = useFormattedPayHistoryRecords()

  if (paystubs.length === 0) {
    return null
  }
  if (screen > ScreenSize.SM) return <PaystubsTable paystubs={paystubs} />

  return <PaystubsList paystubs={paystubs} />
}
