import * as React from 'react'
import { useState } from 'react'
import i18next, { TFunction } from 'i18next'
import { useTable } from 'react-table'
import { useTranslation } from 'react-i18next'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { Modal } from '@toasttab/buffet-pui-modal'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  HumanResourcesPermission,
  JobAssignmentsListJobAssignmentFragment,
  JobAssignmentsListSalaryFragment,
  PayInterval,
  PendingPayChangeStatus,
  UpdateScheduledPayChangeStatusesMutation,
  useUpdateScheduledPayChangeStatusesMutation
} from '@local/generated/graphql'
import { trackScheduledPayChange, useTracking } from '@local/track'
import { i18Formats } from '@local/ec-app'
import { PayChangeCardProps } from '@local/change-pay'
import {
  filterHourlyJobsForScheduledPayChange,
  filterSalaryJobsForScheduledPayChange
} from './utils'
import { LoadSpinner } from '../../components'
import { useEmployeeId } from '../../../../../hooks'
import { PayChangesColumns, PayChangesTable } from '../components'
import { useJobAssignmentsStore } from '../../JobAssignmentsStore'
import { useHasPermission } from '../../../hooks'

type Props = {
  /** used to display a single pay change */
  selectedScheduledPayChangeId?: string | null
  /** salary with a scheduled pay change */
  salary?: JobAssignmentsListSalaryFragment | null
  /** list of salary jobs with scheduled pay changes */
  salaryJobs: Array<JobAssignmentsListJobAssignmentFragment>
  /** list of hourly jobs with scheduled pay changes */
  hourlyJobs: Array<JobAssignmentsListJobAssignmentFragment>
  /** whether the modal is open */
  isOpen: boolean
  /** function to call on close of the modal */
  onClose: VoidFunction
  /** function to call after a scheduled pay change is cancelled */
  onCancelScheduledPayChange: VoidFunction
} & TestIdentifiable

export const ScheduledPayChangesModal: React.FunctionComponent<Props> = ({
  selectedScheduledPayChangeId,
  salary,
  salaryJobs,
  hourlyJobs,
  isOpen,
  onClose,
  onCancelScheduledPayChange,
  testId = 'scheduled-pay-change-modal'
}) => {
  const { t } = useTranslation(['scheduled-pay-changes', 'common'])
  const { locale } = useBuffetContext()

  const employeeId = useEmployeeId()
  const employeeFirstName = useJobAssignmentsStore((x) => x.employeeFirstName)

  const [modalState, setModalState] = useState<'view' | 'cancel'>('view')

  const { showErrorSnackBar, showSnackBar } = useSnackBar()

  const { filteredSalary, filteredSalaryJobs } =
    filterSalaryJobsForScheduledPayChange(
      salaryJobs,
      selectedScheduledPayChangeId,
      salary
    )

  const { filteredHourlyJobs } = filterHourlyJobsForScheduledPayChange(
    hourlyJobs,
    selectedScheduledPayChangeId
  )

  const initiateScheduledPayChangeCancel = useHasPermission(
    HumanResourcesPermission.PAY_EDIT
  )
    ? (scheduledPayChange: PayChangeCardProps) => {
        setScheduledPayChanges([scheduledPayChange])
        setModalState('cancel')
      }
    : undefined

  const scheduledPayChangesFromProps = React.useMemo(() => {
    return getScheduledPayChanges(
      t,
      filteredSalary,
      filteredSalaryJobs,
      filteredHourlyJobs,
      initiateScheduledPayChangeCancel
    )
  }, [t, salary, salaryJobs, hourlyJobs])

  const [scheduledPayChanges, setScheduledPayChanges] = useState(
    scheduledPayChangesFromProps
  )

  const { rows, prepareRow } = useTable({
    columns: PayChangesColumns,
    data: scheduledPayChanges
  })

  const [updatePendingPayChangeStatuses, { loading: saving }] =
    useUpdateScheduledPayChangeStatusesMutation()

  const onUnschedule = (pendingPayChangeId: string) => {
    updatePendingPayChangeStatuses({
      variables: {
        employeeId: employeeId,
        scheduledPayChangeStatusInput: {
          pendingPayChangeId: pendingPayChangeId,
          pendingPayChangeStatus: PendingPayChangeStatus.CANCELED
        }
      },
      onCompleted: (mutation: UpdateScheduledPayChangeStatusesMutation) => {
        if (
          mutation.updatePendingPayChangeStatuses?.__typename ===
          'PendingPayChangeStatusResponse'
        ) {
          showSnackBar(
            t(
              'scheduledCancelSuccess',
              'You have successfully cancelled a scheduled pay change'
            )
          )
          onCancelScheduledPayChange()
        } else {
          showErrorSnackBar(
            t(
              'scheduledCancelError',
              "We couldn't cancel the scheduled pay change. Please try again."
            )
          )
        }
      },
      onError: () =>
        showErrorSnackBar(
          t(
            'scheduledCancelError',
            "We couldn't cancel the scheduled pay change. Please try again."
          )
        )
    })
  }

  const { track: trackingHook } = useTracking()

  return (
    <Modal
      testId={`${testId}-modal`}
      isOpen={isOpen}
      onRequestClose={() => {
        trackingHook('scheduled-pay-change-modal.close')
        onClose()
      }}
      position='pin-right'
      className='relative'
    >
      {modalState === 'view' && (
        <>
          <Modal.Header>
            {t('scheduledHeader', 'Scheduled pay changes')}
          </Modal.Header>
          <Modal.Body className='space-y-3'>
            {t(
              'scheduledDescription',
              'New changes to pay will replace prior pay changes until employee is paid'
            )}
            <PayChangesTable
              testId={`${testId}-table`}
              rows={rows}
              prepareRow={prepareRow}
            />
          </Modal.Body>
        </>
      )}
      {modalState === 'cancel' && (
        <>
          <Modal.Header>
            {t('scheduledCancelHeader', 'Cancel pay change?')}
          </Modal.Header>
          <Modal.Body className='space-y-3'>
            {t(
              !!salary
                ? 'scheduledSalaryCancelDescription'
                : 'scheduledHourlyCancelDescription',
              {
                firstName:
                  employeeFirstName ??
                  i18next.format(
                    t('yourEmployee', 'your employee', { ns: 'common' }),
                    i18Formats.CAPITALIZE
                  ),
                previousRate: scheduledPayChanges[0].oldRateProps.rate
                  ? t(
                      `amountPer${scheduledPayChanges[0].oldRateProps.interval}`,
                      formatCurrency(
                        scheduledPayChanges[0].oldRateProps.rate,
                        locale
                      ),
                      {
                        ns: 'common',
                        amount: formatCurrency(
                          scheduledPayChanges[0].oldRateProps.rate,
                          locale
                        )
                      }
                    )
                  : null
              }
            )}
            <PayChangesTable
              testId={`${testId}-table`}
              rows={rows}
              prepareRow={prepareRow}
            />
          </Modal.Body>
          {saving ? (
            <LoadSpinner
              message={t('cancelingPayChange', 'Canceling pay change...')}
            />
          ) : undefined}
          <Modal.Footer>
            <CancellationButtonGroup
              scheduledPayChangeBeingCancelled={scheduledPayChanges}
              onKeep={() => {
                setModalState('view')
                setScheduledPayChanges(scheduledPayChangesFromProps)
              }}
              onUnschedule={(pendingPayChangeId) =>
                onUnschedule(pendingPayChangeId)
              }
              onClose={onClose}
              disabled={saving}
            />
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export const getScheduledPayChanges = (
  t: TFunction,
  salary: JobAssignmentsListSalaryFragment | null | undefined,
  salaryJobs: JobAssignmentsListJobAssignmentFragment[],
  hourlyJobs: JobAssignmentsListJobAssignmentFragment[],
  initiateScheduledPayChangeCancel?: (
    payChangeCardProps: PayChangeCardProps
  ) => void
): PayChangeCardProps[] => {
  let scheduledPayChanges: PayChangeCardProps[] = hourlyJobs.map((job) => {
    const payChangeCardProps: PayChangeCardProps = {
      id: job.pendingPayChange?.id!,
      jobProps: {
        name: job.name,
        locationName: job.locationName
      },
      oldRateProps: {
        label: t('oldPay', 'Old pay'),
        rate: job.activeHourlyRateOfPay,
        interval: PayInterval.HOUR
      },
      newRateProps: {
        label: t('newPay', 'New pay'),
        rate: job.pendingPayChange?.rate ?? null,
        interval: PayInterval.HOUR
      },
      effectiveDateProps: {
        label: t('effectiveDate', 'Effective date', { ns: 'pay-change' }),
        effectiveDate: job.pendingPayChange?.effectiveDate
      },
      t
    }

    return {
      ...payChangeCardProps,
      deleteOnClick: initiateScheduledPayChangeCancel
        ? () => {
            initiateScheduledPayChangeCancel(payChangeCardProps)
          }
        : undefined,
      t
    }
  })

  if (!!salary) {
    const salaryJob = salaryJobs.length === 1 ? salaryJobs[0] : undefined
    const payChangeCardProps = {
      id: salary?.pendingPayChange?.id || salary?.id,
      jobProps: {
        name: !!salaryJob ? salaryJob.name : t('multipleSalaryJobName'),
        locationName: !!salaryJob
          ? salaryJob.locationName
          : t('multipleSalaryJobLocation')
      },
      oldRateProps: {
        label: t('oldPay', 'Old pay'),
        rate: salary.activeSalaryRateOfPay,
        interval: PayInterval.ANNUAL
      },
      newRateProps: {
        label: t('newPay', 'New pay'),
        rate: salary.pendingPayChange?.rate ?? null,
        interval: PayInterval.ANNUAL
      },
      effectiveDateProps: {
        label: t('effectiveDate', 'Effective date', { ns: 'pay-change' }),
        effectiveDate: salary.pendingPayChange?.effectiveDate
      },
      t: t
    }

    scheduledPayChanges.unshift({
      ...payChangeCardProps,
      deleteOnClick: initiateScheduledPayChangeCancel
        ? () => {
            initiateScheduledPayChangeCancel(payChangeCardProps)
          }
        : undefined,
      t
    })
  }

  return scheduledPayChanges
}

export const CancellationButtonGroup: React.FunctionComponent<{
  scheduledPayChangeBeingCancelled: PayChangeCardProps[]
  disabled: boolean
  onKeep: VoidFunction
  onUnschedule: (pendingPayChangeId: string) => void
  onClose: VoidFunction
}> = ({
  scheduledPayChangeBeingCancelled,
  disabled,
  onKeep,
  onUnschedule,
  onClose
}) => {
  const { t } = useTranslation('scheduled-pay-changes')
  const { showErrorSnackBar } = useSnackBar()

  return (
    <ButtonGroup className='w-full' direction='vertical' buttonSize='lg'>
      <Button
        {...trackScheduledPayChange('keep')}
        testId={'scheduled-pay-change-keep'}
        disabled={disabled}
        onClick={onKeep}
        size='lg'
        variant={'secondary'}
      >
        {t('keepPayChange', 'Keep pay change')}
      </Button>
      <Button
        {...trackScheduledPayChange('cancel-confirm')}
        testId={'scheduled-pay-change-cancel'}
        disabled={disabled}
        onClick={() => {
          if (scheduledPayChangeBeingCancelled.length === 1) {
            onUnschedule(scheduledPayChangeBeingCancelled[0].id)
          } else {
            showErrorSnackBar(
              t(
                'scheduledCancelError',
                "We couldn't cancel the scheduled pay change. Please try again."
              )
            )
          }
          onClose()
        }}
        size='lg'
        variant={'destructive'}
      >
        {t('cancelPayChange', 'Cancel pay change')}
      </Button>
    </ButtonGroup>
  )
}
