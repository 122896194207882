import * as React from 'react'
import { Select, SelectItem } from '@toasttab/buffet-pui-select'

interface SelectFieldProps {
  label: string
  options: Array<SelectItem>
  onChange: (value: string) => void
  selectedVal?: string | null
  errorText?: string
  id: string
  autoComplete?: string
  disabled?: boolean
  required?: boolean
}

export const SelectFormField = ({
  label,
  options,
  onChange,
  selectedVal,
  errorText,
  id,
  autoComplete,
  disabled = false,
  required
}: SelectFieldProps) => (
  <Select
    required={required}
    label={label}
    name={id}
    value={selectedVal ?? undefined}
    invalid={!!errorText}
    errorText={errorText}
    options={options}
    onChange={(value) => onChange(value.toString())}
    testId={id}
    autoComplete={autoComplete}
    containerClassName='mb-6 w-full lg:w-1/2 xl:w-1/3'
    disabled={disabled}
    itemToValue={(item) => {
      if (typeof item === 'string' || typeof item === 'number') {
        return item
      } else {
        return item && item.value ? item.value : ''
      }
    }}
  />
)
