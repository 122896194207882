import * as React from 'react'
import { useMemo, createContext, useContext } from 'react'
import { ApolloProvider } from '@apollo/client'
import { useWindowProvider } from '@local/ec-app'
import { useOperationErrorLogger } from '@local/ec-app/apollo'

import makeApolloClients from './makeApolloClients'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'

const LOGGER_SOURCE = 'ApolloErrorLogger'

type Value = ReturnType<typeof makeApolloClients>

export const ApolloClientsContext = createContext<Value>({} as any)

const useApolloClients = () => useContext(ApolloClientsContext)

export const useRestClient = () => useApolloClients().rest

export const useLoginClient = () => useApolloClients().login

export const useHrClient = () => useApolloClients().humanResources

export const useEcWebClient = () => useApolloClients().legacy

type Props = {
  children: React.ReactNode
}

export const ApolloClientsProvider = ({ children }: Props) => {
  const { locale } = useBuffetContext()

  const { origin } = useWindowProvider().location

  const logSentryError = useOperationErrorLogger({ source: LOGGER_SOURCE })

  const clients = useMemo(() => {
    return makeApolloClients({ logSentryError, origin, language: locale })
  }, [logSentryError, origin, locale])

  return (
    <ApolloClientsContext.Provider value={clients}>
      <ApolloProvider client={clients.humanResources}>
        {children}
      </ApolloProvider>
    </ApolloClientsContext.Provider>
  )
}
