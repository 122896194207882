import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ErrorCallToActionSection,
  ErrorContainer,
  ErrorHeading,
  ErrorSubheading
} from '@toasttab/buffet-pui-error-pages'
import { TabletTeamIllustration } from '@toasttab/buffet-pui-illustrations'
import { Button } from '@toasttab/buffet-pui-buttons'

export const EmployeeNotFound: React.FunctionComponent = () => {
  const { t } = useTranslation('employees')
  return (
    <ErrorContainer>
      <div data-testid='employee-not-found-illustration' className='flex'>
        <div className='hidden md:block visible mx-auto'>
          <TabletTeamIllustration
            accessibility='semantic'
            title='Employee not found'
          />
        </div>
        <div className='block md:hidden visible mx-auto'>
          <TabletTeamIllustration resizeToContainer />
        </div>
      </div>
      <div>
        <ErrorHeading>{t('employeeNotFoundHeading')}</ErrorHeading>
        <ErrorSubheading>{t('employeeNotFoundSubheading1')}</ErrorSubheading>
        <ErrorSubheading>{t('employeeNotFoundSubheading2')}</ErrorSubheading>
      </div>
      <ErrorCallToActionSection spacing='flex flex-col'>
        <Button
          testId={'employee-not-found-go-back'}
          variant='primary'
          onClick={() => window.history.back()}
        >
          {t('goBack')}
        </Button>
      </ErrorCallToActionSection>
    </ErrorContainer>
  )
}
