import * as React from 'react'
import { useState } from 'react'
import { QueryClientProvider, QueryClient } from 'react-query'

const getQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      mutations: {
        // Mutation errors should be handled at the call site.
        useErrorBoundary: false
      },
      queries: {
        suspense: true,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  })
}

type Props = {
  children: React.ReactNode
}

const ReactQueryVersionTwoProvider = ({ children }: Props) => {
  const [queryClient] = useState(() => getQueryClient())

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

export { ReactQueryVersionTwoProvider }
