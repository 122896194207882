import React, { useEffect, useState } from 'react'
import { OperationError } from '@local/ec-app/apollo'
import { getCountryOptions, PreparerOptions } from '../shared/formConstants'
import { getInfoLink, PREPARER_COUNT_OPTIONS } from '../shared/formHelpers'
import { getDateAsDigits } from '../../../shared/formHelpers'
import { PreparerSection, SignaturePad } from '../components'
import { Button } from '@toasttab/buffet-pui-buttons'
import {
  AsyncFormFieldContainer,
  SelectFormField,
  TextInputFormField
} from '../../profile/personal/components'
import { NumberInput } from '@toasttab/buffet-pui-text-input'
import { FormikErrors, useFormik } from 'formik'
import { Option, Preparer } from '../domain/TaxForms'
import { RadioGroup } from '@toasttab/buffet-pui-radio-group'
import { Country } from '../domain'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useEmployeeId, useCompanyCode } from '../../hooks'
import { useApi } from '../../../ApiProvider'
import { ValidationError } from 'yup'
import { I92023Form, WorkAuthTypes2023 } from './i92023Models'

export interface I92023FormProps {
  pageIsInEditMode: boolean
}

const getReadOnlySearchParam = (): boolean => {
  const params = new URLSearchParams(window.location.search)
  return params.get('readOnly') === 'true'
}

const getReturnUrlSearchParam = (): string => {
  const params = new URLSearchParams(window.location.search)
  return params.get('returnUrl') as string
}

/*
  This file represents the I9 after the updates required by the US Govt in Autumn 2023
 */
export const I92023 = (props: I92023FormProps) => {
  const [submitting, setSubmitting] = useState(false)
  const [saveSucessful, setSaveSucessful] = useState(true)
  const [error, setError] = useState('')
  const [i9FormData, setI9FormData] = useState<I92023Form | null>(null)
  const [countryList, setCountryList] =
    useState<Array<Country>>(getCountryOptions)
  const [stateList, setStateList] = useState<Array<Option>>([])
  const [pageIsReadOnly, setPageIsReadOnly] = useState<boolean>(
    getReadOnlySearchParam()
  )
  const pageIsInEditMode = props.pageIsInEditMode
  const returnUrl = getReturnUrlSearchParam()
  const [waitingFori9FormData, setWaitingFori9FormData] =
    useState<boolean>(false)
  const api = useApi()
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()

  const redirectToNextPage = () => {
    window.location.href = returnUrl
      ? returnUrl
      : (window.location.href = `/${companyCode}/dashboard`)
  }

  const numPreparers = (): number => (i9FormData?.preparers || []).length

  useEffect(() => {
    const loadI9FormOptions = async () => {
      setError('')

      try {
        const i9OptionsResponse = await api.getEmployeeI9FormOptions(
          companyCode,
          employeeId
        )

        setCountryList(i9OptionsResponse.options.countries)
        setStateList(i9OptionsResponse.options.states)
        await setFieldValue('isUsingEverify', i9OptionsResponse.isUsingEverify)
      } catch (e) {
        console.error(e)
        setError('There was an error retrieving the form options')
      }
    }

    const loadI9FormData = async () => {
      setError('')
      setWaitingFori9FormData(true)

      try {
        const i9FormResponse = await api.getEmployeeI9Form(
          companyCode,
          employeeId
        )

        setI9FormData(i9FormResponse.i9Form)
        setCountryList(i9FormResponse.options.countries)
        setStateList(i9FormResponse.options.states)
        await setFieldValue('isUsingEverify', i9FormResponse.isUsingEverify)
      } catch (error) {
        setPageIsReadOnly(true)

        if (OperationError.hasError(error, ValidationError)) {
          setError(
            'There was an error loading your I9 information. This may happen when the employee is being rehired.' +
              ' Once they have completed rehire, try again.'
          )
        } else {
          setError('There was an error when retrieving your I9 information.')
        }
      } finally {
        setWaitingFori9FormData(false)
      }
    }

    if (pageIsReadOnly || pageIsInEditMode) {
      loadI9FormData()
    } else {
      loadI9FormOptions()
    }
  }, [api, companyCode, employeeId, pageIsInEditMode, pageIsReadOnly])
  const { values, setFieldValue, errors, handleSubmit } = useFormik({
    initialValues:
      i9FormData ||
      ({
        preparerCount: '0',
        preparers: [] as Preparer[]
      } as I92023Form),
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: I92023Form.commonFormSchema,
    enableReinitialize: true,
    onSubmit: () => {
      setSubmitting(true)
      const formattedValues = setAuthorizedWorkDate(values)
      const promise = pageIsInEditMode
        ? api.editI9(companyCode, employeeId, formattedValues)
        : api.saveI9(companyCode, employeeId, formattedValues)

      promise
        .then(() => {
          redirectToNextPage()
        })
        .catch(() => setSaveSucessful(false))
        .finally(() => setSubmitting(false))
    }
  })

  const setAuthorizedWorkDate = (values: I92023Form) => {
    if (
      values.requiredDocs.citizenshipStatus !==
      WorkAuthTypes2023.alien_auth_to_work.value
    ) {
      return {
        ...values,
        requiredDocs: { ...values.requiredDocs, authorizedWorkDate: null }
      }
    }
    return values
  }

  const getErrorBannerAbove = () => {
    // https://github.com/formium/formik/issues/1670
    // Formik doesn't have great support for "on validation fail"
    let message = ''
    if (Object.keys(errors).length > 0) {
      message = 'Please fix the errors above'
    } else if (!saveSucessful) {
      message = 'There was a problem saving. Please try again.'
    } else if (error) {
      message = error
    }
    return message ? (
      <div className='mb-6' data-testid='error-banner'>
        <Alert variant='error' className='w-full'>
          {message}
        </Alert>
      </div>
    ) : null
  }

  const getErrorBannerBelow = () => {
    // https://github.com/formium/formik/issues/1670
    // Formik doesn't have great support for "on validation fail"
    let message = ''
    if (Object.keys(errors).length > 0) {
      message = 'Please fix the errors below'
    } else if (!saveSucessful) {
      message = 'There was a problem saving. Please try again.'
    } else if (error) {
      message = error
    }
    return message ? (
      <div className='mb-6' data-testid='error-banner'>
        <Alert variant='error' className='w-full'>
          {message}
        </Alert>
      </div>
    ) : null
  }

  const setPreparerCount = (value: string) => {
    const count = parseInt(value)
    setFieldValue('preparerCount', value)
    if (values.preparers.length === count) {
      return
    }
    if (values.preparers.length > count) {
      setFieldValue('preparers', values.preparers.slice(0, count))
      return
    }

    const preparerList = [...values.preparers]
    for (let i = values.preparers.length; i < count; i++) {
      preparerList.push(new Preparer())
    }
    setFieldValue('preparers', preparerList)
  }

  const getPreparerSection = () => {
    if (!values.preparerCertification && !pageIsReadOnly) {
      return null
    }

    return values.preparers.map((preparer: Preparer, index: number) => (
      <PreparerSection
        key={preparer.uniqueIdentifier}
        preparer={preparer}
        setPreparer={(newPreparer) => {
          const newPreparerList = [...values.preparers]
          newPreparerList[index] = newPreparer
          setFieldValue('preparers', newPreparerList)
        }}
        index={index}
        errors={
          // TS is very finicky about how this looks...
          errors.preparers
            ? (errors.preparers[index] as FormikErrors<Preparer>)
            : ({} as FormikErrors<Preparer>)
        }
        disabled={pageIsReadOnly || pageIsInEditMode}
        hasMiddleInitial={true}
      />
    ))
  }

  const getDocumentsForWorkAuth = () => {
    const arnValue = 'arn'
    const uscisValue = 'uscis'

    const arnOrUscisOptions = [
      { value: arnValue, label: 'A-Number' },
      { value: uscisValue, label: 'USCIS Number' }
    ]

    /**
     * This function will return which value to set in the ARN/USCIS number dropdown iff the page is in edit or
     * read only mode. If it's not, we don't want to pick ANY value so we return undefined
     */
    const selectArnOrUscisOption = () => {
      if (values.requiredDocs.isAlienNumber !== undefined) {
        return values.requiredDocs.isAlienNumber ? 'arn' : 'uscis'
      }

      return undefined
    }

    const uscis = (
      <>
        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <NumberInput
            label='1. USCIS A-Number'
            value={values.requiredDocs?.alienUscisNumber}
            onChange={(event) =>
              setFieldValue(
                'requiredDocs.alienUscisNumber',
                event.formattedValue
              )
            }
            errorText={errors?.requiredDocs?.alienUscisNumber}
            invalid={errors?.requiredDocs?.alienUscisNumber != null}
            testId='alien-uscis-number'
            containerClassName={'mb-6 w-full lg:w-1/2 xl:w-1/3'}
            disabled={pageIsReadOnly}
            prefix={values.requiredDocs?.isAlienNumber ? 'A' : undefined}
          />
        </AsyncFormFieldContainer>

        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          {values.requiredDocs?.alienUscisNumber ? (
            <SelectFormField
              label='Which number did you enter?'
              options={arnOrUscisOptions}
              onChange={(value) => {
                const isArn = value === arnValue
                setFieldValue('requiredDocs.isAlienNumber', isArn)
              }}
              selectedVal={selectArnOrUscisOption()}
              id={'input-arn-selection'}
              errorText={errors?.requiredDocs?.isAlienNumber}
              disabled={pageIsReadOnly}
            />
          ) : null}
        </AsyncFormFieldContainer>
      </>
    )

    const i94 = (
      <AsyncFormFieldContainer
        isLoading={waitingFori9FormData}
        containerClassName='mb-6 w-full lg:w-1/2'
      >
        <TextInputFormField
          label='2. Form I-94 Admission Number'
          value={values.requiredDocs?.i94AdmissionNumber}
          onChange={(value) =>
            setFieldValue('requiredDocs.i94AdmissionNumber', value)
          }
          errorMessage={errors?.requiredDocs?.i94AdmissionNumber}
          testId='i94-number'
          disabled={pageIsReadOnly}
        />
      </AsyncFormFieldContainer>
    )

    const foreignPassport = (
      <>
        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <TextInputFormField
            label='3. Foreign Passport Number'
            value={values.requiredDocs?.foreignPassportNumber}
            onChange={(value) =>
              setFieldValue('requiredDocs.foreignPassportNumber', value)
            }
            errorMessage={errors.requiredDocs?.foreignPassportNumber}
            testId='foreign-passport-number'
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>
        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <SelectFormField
            label='Country of Issuance'
            options={countryList}
            selectedVal={values.requiredDocs?.foreignPassportIssuingCountry}
            onChange={(value) =>
              setFieldValue('requiredDocs.foreignPassportIssuingCountry', value)
            }
            errorText={errors.requiredDocs?.foreignPassportIssuingCountry}
            id='country-of-issuance'
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>
      </>
    )

    const workAuthDate = (
      <>
        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <TextInputFormField
            label='Authorized to work until (mm/dd/yyyy)'
            value={values.requiredDocs?.authorizedWorkDate}
            onChange={(value) =>
              setFieldValue('requiredDocs.authorizedWorkDate', value)
            }
            errorMessage={errors?.requiredDocs?.authorizedWorkDate}
            testId='authorized-work-date'
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>
      </>
    )

    switch (values.requiredDocs?.citizenshipStatus) {
      case WorkAuthTypes2023.lawful_permanent_res.value:
        return <>{uscis}</>
      case WorkAuthTypes2023.alien_auth_to_work.value:
        return (
          <>
            {workAuthDate}
            {uscis}
            <div className='mb-8'>OR</div>
            {i94}
            <div className='mb-8'>OR</div>
            {foreignPassport}
          </>
        )
      default:
        return <></>
    }
  }

  return (
    /* I9 form below */
    <div
      className='flex justify-center'
      data-testid={`i9-form${pageIsInEditMode ? '-edit' : ''}`}
    >
      <div className='max-w-3xl m-4 type-default'>
        {getErrorBannerBelow()}

        <div className='my-10 font-semibold type-headline-2'>
          Fill out your I-9
        </div>
        <div className='mb-1 font-semibold type-headline-5'>
          Employment Eligibility Verification
        </div>
        <div className='font-semibold type-headline-5 text-secondary'>
          Department of Homeland Security
        </div>
        <div className='mb-6 font-semibold type-headline-5 text-secondary'>
          U.S. Citizenship and Immigration Services
        </div>
        <div className='mb-2 font-semibold type-headline-5'>USCIS Form I-9</div>
        <div className='mb-1 text-secondary'>OMB No. 1615-0047</div>
        <div className='mb-8 text-secondary'>Expires 07/31/2026</div>
        <div className='mb-4 type-large font-semibold'>
          START HERE:{' '}
          <span className='font-semibold'>
            Employers must ensure the form instructions are available to
            employees when completing this form. Employers are liable for
            failing to comply with the requirements for completing this form.
            See below and the{' '}
            <a
              href='https://www.uscis.gov/i-9'
              target='_blank'
              rel='noreferrer'
            >
              Instructions.
            </a>
          </span>
        </div>
        <div className='mb-4'>
          {getInfoLink(
            'https://www.uscis.gov/sites/default/files/document/forms/i-9instr.pdf',
            'Form I-9 Instructions'
          )}
        </div>
        <div className='mb-8 type-large font-semibold'>
          ANTI-DISCRIMINATION NOTICE:{' '}
          <span className='font-normal'>
            All employees can choose which acceptable documentation to present
            for Form I-9. Employers cannot ask employees for documentation to
            verify information in{' '}
            <span className='font-semibold'>Section 1</span>, or specify which
            acceptable documentation employees must present for{' '}
            <span className='font-semibold'>Section 2</span> or Supplement B,
            Reverification and Rehire. Treating employees differently based on
            their citizenship, immigration status, or national origin may be
            illegal.
          </span>
        </div>
        <div className='font-semibold type-headline-4'>
          Section 1. Employee Information and Attestation
        </div>
        <div className='mb-10 type-subhead'>
          Employees must complete and sign Section 1 of Form I-9 no later than
          the <span className='font-semibold'>first day of employment</span>,
          but not before accepting a job offer.
        </div>

        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <TextInputFormField
            label='Last Name (Family Name)'
            value={values.lastName}
            onChange={(value) => setFieldValue('lastName', value)}
            errorMessage={errors?.lastName}
            testId='last-name'
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>

        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <TextInputFormField
            label='First Name (Given Name)'
            value={values.firstName}
            onChange={(value) => setFieldValue('firstName', value)}
            errorMessage={errors?.firstName}
            testId='first-name'
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>

        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <TextInputFormField
            label='Middle Initial (if any)'
            value={values.middleInitial}
            onChange={(value) => setFieldValue('middleInitial', value)}
            errorMessage={errors?.middleInitial}
            testId='middle-initial'
            optional={true}
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>

        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <TextInputFormField
            label='Other Last Names Used (if any)'
            value={values.otherLastNames}
            onChange={(value) => setFieldValue('otherLastNames', value)}
            errorMessage={errors?.otherLastNames}
            testId='other-last-names'
            optional={true}
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>

        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <TextInputFormField
            label='Address (Street Number and Name)'
            value={values.address?.address}
            onChange={(value) => setFieldValue('address.address', value)}
            errorMessage={errors?.address?.address}
            testId='street-address'
            autoComplete='address-line1'
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>

        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <TextInputFormField
            label='Apt. Number (if any)'
            value={values.address?.address2}
            onChange={(value) => setFieldValue('address.address2', value)}
            errorMessage={errors?.address?.address2}
            testId='apt-number'
            autoComplete='address-line2'
            optional={true}
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>

        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <TextInputFormField
            label='City or Town'
            value={values.address?.locality}
            onChange={(value) => setFieldValue('address.locality', value)}
            errorMessage={errors?.address?.locality}
            testId='city'
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>

        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <SelectFormField
            label='State'
            options={stateList}
            selectedVal={values.address?.administrativeArea}
            onChange={(value) =>
              setFieldValue('address.administrativeArea', value)
            }
            errorText={errors?.address?.administrativeArea}
            id='state'
            autoComplete='address-level1'
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>

        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <TextInputFormField
            label='ZIP Code'
            value={values.address?.postalCode}
            onChange={(value) => setFieldValue('address.postalCode', value)}
            errorMessage={errors?.address?.postalCode}
            testId='zip'
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>

        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <NumberInput
            label='Date of Birth (mm/dd/yyyy)'
            value={getDateAsDigits(values.dateOfBirth)}
            onChange={(event: any) => {
              setFieldValue('dateOfBirth', event.formattedValue)
            }}
            format='##/##/####'
            allowEmptyFormatting
            mask=' '
            name='dob'
            testId='input-date-of-birth'
            errorText={errors?.dateOfBirth}
            invalid={!!errors?.dateOfBirth}
            className='tabular-nums'
            containerClassName='mb-6 w-full lg:w-1/2 xl:w-1/3'
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>

        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          {pageIsReadOnly ? (
            <TextInputFormField
              label='U.S. Social Security Number'
              value={
                pageIsReadOnly && values.socialSecurityNumber
                  ? '•••-••-' + values.socialSecurityNumber.slice(-4)
                  : values.socialSecurityNumber
              }
              onChange={(event: any) => event.preventDefault()}
              testId='ro-ssn'
              disabled={true}
            />
          ) : (
            <NumberInput
              format='###-##-####'
              allowEmptyFormatting
              mask=' '
              inputMode='text'
              value={values.socialSecurityNumber || ''}
              name='socialSecurityNumber'
              label='U.S. Social Security Number'
              onChange={(event: any) => {
                // if the SSN is blank or not defined, we'll make sure to leave it empty.
                // formattedValue will always have '   -  -    ' if the field is blank
                if (event.value) {
                  setFieldValue('socialSecurityNumber', event.formattedValue)
                } else {
                  setFieldValue('socialSecurityNumber', '')
                }
              }}
              invalid={errors?.socialSecurityNumber != null}
              errorText={errors?.socialSecurityNumber}
              testId='input-ssn'
              className='tabular-nums'
              containerClassName='mb-6 w-full lg:w-1/2 xl:w-1/3'
              helperText={values.isUsingEverify ? '' : 'Optional'}
              disabled={pageIsReadOnly}
            />
          )}
        </AsyncFormFieldContainer>

        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <TextInputFormField
            label="Employee's Email Address"
            value={values.email}
            onChange={(value) => setFieldValue('email', value)}
            errorMessage={errors?.email}
            testId='email'
            optional={true}
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>

        <AsyncFormFieldContainer
          isLoading={waitingFori9FormData}
          containerClassName='mb-6 w-full lg:w-1/2'
        >
          <TextInputFormField
            label="Employee's Telephone Number"
            value={values.telephoneNumber}
            onChange={(value) => setFieldValue('telephoneNumber', value)}
            errorMessage={errors?.telephoneNumber}
            testId='telephone-number'
            optional={true}
            disabled={pageIsReadOnly}
          />
        </AsyncFormFieldContainer>

        <div className='mt-8 mb-6 text-normal font-semibold'>
          I am aware that federal law provides for imprisonment and/or fines for
          false statements, or the use of false documents, in connection with
          the completion of this form. I attest, under penalty of perjury, that
          this information, including my selection of the box attesting to my
          citizenship or immigration status, is true and correct.
        </div>

        <div className='text-normal font-semibold'>
          Check one of the following boxes to attest to your citizenship or
          immigration status (See page 2 and 3 of the instructions.):
        </div>
        <div className='mb-8'>
          <RadioGroup
            labelledbyId='citizenshipStatus'
            onChange={(e) =>
              setFieldValue('requiredDocs.citizenshipStatus', e?.target?.value)
            }
            // @ts-ignore
            // ugly workaround since RadioGroups don't technically support JSX
            options={Object.values(WorkAuthTypes2023).map((workAuthType) => ({
              label:
                workAuthType.value ===
                WorkAuthTypes2023.non_citizen_national.value ? (
                  <>
                    2. A noncitizen national of the United States{' '}
                    <a
                      href='https://www.uscis.gov/sites/default/files/document/forms/i-9instr.pdf'
                      target='_blank'
                      rel='noreferrer'
                    >
                      (See Instructions)
                    </a>
                  </>
                ) : (
                  workAuthType.label
                ),
              value: workAuthType.value,
              disabled: pageIsReadOnly,
              checked:
                values?.requiredDocs?.citizenshipStatus === workAuthType.value
            }))}
            testId='citizenship-status'
          />
          {errors?.requiredDocs?.citizenshipStatus && (
            <div className='mb-4 text-error type-caption'>
              Please select an option above
            </div>
          )}
        </div>
        {getDocumentsForWorkAuth()}

        <label className='text-secondary'>Signature of Employee</label>
        <div>
          {pageIsReadOnly || pageIsInEditMode ? (
            <div className={'p-3'}>
              <img
                src={values.employeeSignatureUrl}
                id='ee-signature-img'
                alt='employees signature'
              />
            </div>
          ) : (
            <SignaturePad
              onUpdateSignature={(sigBytes: string) =>
                setFieldValue(
                  'employeeSignatureBytes',
                  sigBytes.substring(sigBytes.indexOf(',') + 1)
                )
              }
            />
          )}
        </div>
        {errors?.employeeSignatureBytes && (
          <div className='pt-2 mb-6 text-error type-caption'>
            Please sign your name above
          </div>
        )}
        <div className='mb-12 text-secondary'>
          {pageIsReadOnly || pageIsInEditMode ? (
            <div id='ee-signed-on-date'>{`Signed on ${values.dateSigned}`}</div>
          ) : (
            <>Today's Date {new Date().toLocaleDateString()}</>
          )}
        </div>

        <div className='font-semibold type-headline-4 mb-7'>
          <div>Supplement A,</div>
          <div>Preparer and/or Translator Certification for Section 1</div>
        </div>
        <div className='mb-2'>
          <RadioGroup
            labelledbyId={'usedPreparer'}
            onChange={(e) =>
              setFieldValue('preparerCertification', e?.target?.value)
            }
            options={Object.values(PreparerOptions).map((option) => ({
              disabled: pageIsReadOnly,
              checked: option.value === values.preparerCertification,
              ...option
            }))}
            testId='used-preparer'
          />

          {errors?.preparerCertification && (
            <div className='mb-6 text-error type-caption'>
              Please select an option above
            </div>
          )}
        </div>
        <div>
          <SelectFormField
            label='How many preparers/translators?'
            options={PREPARER_COUNT_OPTIONS}
            selectedVal={
              pageIsReadOnly ? numPreparers().toString() : values.preparerCount
            }
            onChange={setPreparerCount}
            errorText={errors?.preparerCount}
            id='preparer-count'
            disabled={
              pageIsReadOnly || values.preparerCertification !== 'used_preparer'
            } // !((truthy) values.preparerCertification), basically. "Do we have a value provided".
          />
        </div>

        <div className='mb-12'>
          (Fields below must be completed and signed when preparers and/or
          translators assist an employee in completing Section 1.)
        </div>

        {getPreparerSection()}

        <div className='mb-12'>
          {getInfoLink(
            'https://www.uscis.gov/sites/default/files/document/forms/i-9.pdf',
            'List of Acceptable Documents'
          )}
        </div>

        <div>{getErrorBannerAbove()}</div>

        <div className='flex justify-between'>
          <Button
            className='p-0 bg-transparent cursor-pointer text-primary-50'
            onClick={() => redirectToNextPage()}
            data-testid='i9-cancel-btn'
            variant='secondary'
          >
            Cancel
          </Button>
          {!pageIsReadOnly && (
            <Button
              className='p-0 cursor-pointer '
              onClick={() => handleSubmit()}
              data-testid='i9-submit-btn'
              disabled={submitting}
            >
              {submitting
                ? 'Submitting...'
                : pageIsInEditMode
                ? 'Save'
                : 'Next'}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
