import * as React from 'react'
import { create, StoreApi } from 'zustand'
import createContext from 'zustand/context'
import { useQuery } from 'react-query'

import { useEcWebClient } from '@local/api/ApolloClientsProvider'

import {
  PayHistoryAuditDocument,
  PayHistoryAuditQuery,
  PayHistoryAuditQueryVariables
} from '@local/generated/graphql'

import {
  CreatePagedStore,
  PagedStore,
  usePagination
} from '../../stores/PagedStore'

const { Provider, useStore } = createContext<StoreApi<PayHistoryAuditStore>>()

export interface PayHistoryAuditStore extends PagedStore {
  employeeUuid: string
}

const createStore = (employeeUuid: string) =>
  create<PayHistoryAuditStore>((set) => ({
    ...CreatePagedStore(set),
    employeeUuid
  }))

const PayHistoryAuditStoreProvider: React.FunctionComponent<
  { employeeId: string } & React.PropsWithChildren
> = ({ children, employeeId }) => {
  return (
    <Provider createStore={() => createStore(employeeId)}>{children}</Provider>
  )
}

const usePayHistoryAuditQuery = () => {
  const client = useEcWebClient()
  const employeeUuid = useStore((store) => store.employeeUuid)
  const first = useStore((store) => store.paging.first)
  const offset = useStore((store) => store.paging.offset)

  return useQuery(
    ['pay-history-audit', employeeUuid, first, offset],
    () =>
      client.query<PayHistoryAuditQuery, PayHistoryAuditQueryVariables>({
        query: PayHistoryAuditDocument,
        variables: {
          employeeUuid,
          first,
          offset
        }
      }),
    {
      suspense: true
    }
  )
}
const usePayHistoryAuditPagination = () => {
  const query = usePayHistoryAuditQuery()
  return usePagination(useStore, query.data?.data.PayHistoryAudit.paging)
}

export {
  PayHistoryAuditStoreProvider,
  usePayHistoryAuditQuery,
  usePayHistoryAuditPagination,
  useStore as usePayHistoryAuditStore,
  createStore as __createStore
}
