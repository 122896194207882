import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@toasttab/buffet-pui-buttons'
import { EmailIcon } from '@toasttab/buffet-pui-icons'
import { LevelId } from '@local/generated/graphql'
import { trackTransparentMapping } from '@local/track'
import { EmailSupportModal } from '../EmailSupportModal'
import { Overview } from '../../domain'
import { useEmployee } from '../../hooks'

const jobUnmappedDefaultEmail = (name: string, jobName?: string) => `Hello,

${name}'s ${
  jobName ?? ''
} job is not mapped to POS. Please update the job mapping for this role.`

interface Props {
  payrollJobName: string
  payrollJobId: LevelId
  payrollLocationId: LevelId
}

export const EmailSupportButton = ({
  payrollJobName,
  payrollJobId,
  payrollLocationId
}: Props) => {
  const { t } = useTranslation('employees')
  const employee = useEmployee()
  const [isOpen, setIsOpen] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false)

  return (
    <>
      <EmailSupportModal
        isOpen={isOpen}
        onCloseModal={() => setIsOpen(false)}
        payrollJobId={payrollJobId!}
        payrollLocationId={payrollLocationId}
        defaultEmailContent={jobUnmappedDefaultEmail(
          Overview.getName(employee.overview),
          payrollJobName
        )}
        setDisabledEmailSupportButton={setIsDisabled}
      />
      <Button
        {...trackTransparentMapping('contact-support')}
        testId='contact-support-button'
        iconLeft={<EmailIcon />}
        onClick={() => setIsOpen(true)}
        variant='link'
        disabled={isDisabled}
      >
        {t('emailSupport')}
      </Button>
    </>
  )
}
