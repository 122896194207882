import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useShowTerminatedEmployeeAlert } from './utils'

export const TerminatedEmployeeAlert: React.FunctionComponent<{
  employeeFirstName: string
  employmentStatus?: string
}> = ({ employeeFirstName, employmentStatus }) => {
  const { t } = useTranslation('employees')
  const showTerminatedEmployeeAlert =
    useShowTerminatedEmployeeAlert(employmentStatus)

  return showTerminatedEmployeeAlert ? (
    <Alert
      variant={'warning'}
      title={t('payChangeTerminatedTitle', {
        employeeFirstName: employeeFirstName
      })}
      testId='pay-change-modal-terminated-employee-alert'
    >
      <Trans
        t={t}
        i18nKey={'payChangeTerminatedMessage'}
        values={{ employeeFirstName: employeeFirstName }}
        components={[
          <a
            href='https://central.toasttab.com/s/article/Toast-Payroll-Adding-an-Employee-to-the-Employee-Step'
            target='_blank'
            rel='noopener noreferrer'
          />,
          <a
            href='https://central.toasttab.com/s/article/Toast-Payroll-Adding-or-Editing-Earnings-Deductions'
            target='_blank'
            rel='noopener noreferrer'
          />
        ]}
      />
    </Alert>
  ) : null
}
