import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AddIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { trackEmploymentOverview } from '@local/track'
import { useApi } from '../../../../../ApiProvider'
import { useNavigateTo } from '../../../../../navigation/hooks'

export const AddJobsButton: React.FunctionComponent = () => {
  const api = useApi()
  const navigateTo = useNavigateTo()
  const { t } = useTranslation('employees')

  const navigateToAddJob = async () => {
    const esxWebGenericResponse = await api.getAddJobUrl()
    if (esxWebGenericResponse?.url) {
      navigateTo(esxWebGenericResponse.url, true)
    }
  }

  return (
    <Button
      {...trackEmploymentOverview('job-add')}
      testId='add-jobs-button'
      className='flex flex-grow md:flex-none'
      iconLeft={<AddIcon testId='add-jobs-icon' />}
      onClick={navigateToAddJob}
      variant='secondary'
    >
      {t('addJob')}
    </Button>
  )
}
