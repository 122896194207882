import * as React from 'react'
import { Tabs, TabList, Tab } from '@toasttab/buffet-pui-tabs'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

import { useNavigateTo, useNavigationPath } from './hooks'
import { useEmployee } from '../employee/hooks'
import { NavigationTreeNode } from '../employee/domain'

export const AppHeaderTabsView = ({
  tabIndex,
  onChange,
  nodes,
  testId = 'app-header-tab'
}: {
  tabIndex: number
  onChange: (index: number) => void
  nodes: NavigationTreeNode[]
} & TestIdentifiable) => (
  <Tabs index={tabIndex} onChange={onChange} className='mt-6'>
    <TabList block className='mb-px'>
      {nodes.map((node, index) => (
        <Tab key={node.id} testId={`${testId}-${index}`}>
          <div data-toast-track-id={`navigation.${node.value}`}>
            <span className='whitespace-nowrap'>{node.value}</span>
          </div>
        </Tab>
      ))}
    </TabList>
  </Tabs>
)

export const AppHeaderTabs = ({ testId }: TestIdentifiable) => {
  const [rootNode] = useNavigationPath()
  const activeEmployee = useEmployee()
  const navigateTo = useNavigateTo()
  const navigationNodes = activeEmployee?.navigation ?? []
  const tabIndex = navigationNodes.findIndex((node) => node.id === rootNode?.id)

  const onTabChange = (index: number) => {
    const node = navigationNodes[index]
    const linkNode = node?.children?.[0] ?? (node?.data ? node : null)

    if (linkNode?.data) {
      navigateTo(linkNode.data.url, linkNode.data.isLegacy)
    }
  }

  return Boolean(navigationNodes.length) ? (
    <AppHeaderTabsView
      testId={testId}
      tabIndex={tabIndex}
      onChange={onTabChange}
      nodes={navigationNodes}
    />
  ) : null
}
