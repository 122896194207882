import * as yup from 'yup'

export class TelephoneNumber {
  static schema = yup
    .object({
      number: yup
        .string()
        .nullable()
        .required('Telephone number is required')
        .defined()
        .phone()
        .formattedPhone(),
      isMobile: yup.boolean().required()
    })
    .defined()

  static of(obj: any): TelephoneNumber {
    return TelephoneNumber.copyOf(
      TelephoneNumber.schema.validateSync(obj, { abortEarly: false })
    )
  }

  static copyOf(valid: TelephoneNumber): TelephoneNumber {
    return new TelephoneNumber(valid.number, valid.isMobile)
  }

  private constructor(readonly number: string, readonly isMobile: boolean) {}
}
