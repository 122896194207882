import * as React from 'react'
import Heading from './heading'
import ReadOnlyField from './readonly-field'
import PeekableField from './peekable-field'
import { useTranslation } from 'react-i18next'
import { Employee } from '../models/employee'

type EmployeeSectionProps = {
  employee: Employee
}

export const EmployeeSection = ({ employee }: EmployeeSectionProps) => {
  const { t } = useTranslation('employees')

  return (
    <Heading title={t('employeeParam', { name: employee.employeeName })}>
      <ReadOnlyField title={t('employeeName')} value={employee.employeeName} />
      <PeekableField
        title={t('ssn')}
        secret={employee.ssn}
        mask={'XXX-XX-XXXX'}
      />
    </Heading>
  )
}
