import { ApolloLink, Observable } from '@apollo/client'

/**
 * Calls the logging function on each operation error.
 */
export const errorLoggerLink = <TError extends Error = Error>(
  log: (error: TError) => void
) =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) =>
        forward(operation).subscribe({
          next: (result) => observer.next(result),
          error: (error) => {
            log(error)
            observer.error(error)
          },
          complete: () => observer.complete()
        })
      )
  )
