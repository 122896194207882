import * as React from 'react'
import i18next from 'i18next'
import { Form, FormikProvider, useFormik, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { FetchResult } from '@apollo/client'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { Button } from '@toasttab/buffet-pui-buttons'
import { CalendarTodayIcon } from '@toasttab/buffet-pui-icons'
import { format } from '@toasttab/buffet-pui-date-utilities'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Prefix } from '@toasttab/buffet-pui-text-base'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import {
  ChangeSalaryAllocationsMutation,
  ChangeSalaryAllocationsMutationOptions,
  ChangeSalaryAllocationsMutationVariables,
  JobAssignment,
  Money,
  SalaryAllocation,
  useChangeSalaryAllocationsMutation
} from '@local/generated/graphql'
import { trackSalaryAllocationChange, useTracking } from '@local/track'
import { i18Formats } from '@local/ec-app'
import { SalaryAllocationPanel } from '@local/change-pay/src/salary-allocation-input/SalaryAllocationPanel'
import {
  PayChangeSchema,
  SalaryAllocationsSchema,
  useSalaryAllocationsSchema
} from '../../domain'
import { formatDateInputValue } from '../../utils'
import { LoadSpinner } from '../../../components'
import { PayChangeThresholdAlert } from '../payChange/components'
import { SalaryAllocationsAlert } from './SalaryAllocationsAlert'
import { useJobAssignmentsStore } from '../../../JobAssignmentsStore'
import { useEmployeeId } from '../../../../../../hooks'
import { usePayChangeSnackBar } from '../payChange'

export type SalaryAllocationModalContentsProps = {
  title: string
  subtitle: string
  salary: Money
  salaryAllocations: Array<
    Pick<SalaryAllocation, 'id' | 'rate'> & {
      jobAssignment: Pick<JobAssignment, 'name' | 'locationName'>
    }
  >
  onClose: VoidFunction
  onSuccess: VoidFunction
  testId?: string
}

export const SalaryAllocationsModal: React.FunctionComponent<
  SalaryAllocationModalContentsProps
> = ({
  title,
  subtitle,
  salary,
  salaryAllocations,
  onClose,
  onSuccess,
  testId
}) => {
  const { t } = useTranslation('employees')
  const employeeFirstName = useJobAssignmentsStore((x) => x.employeeFirstName)

  const [changeSalary, { loading: saving }] =
    useChangeSalaryAllocationsMutation()

  const effectiveDate = new Date()
  const onSubmit = useOnSubmit(
    salaryAllocations,
    changeSalary,
    effectiveDate,
    onClose,
    onSuccess
  )

  const { track: trackingHook } = useTracking()
  const onRequestClose = () => {
    trackingHook('salary-allocation-change-modal.close')
    onClose()
  }

  const salaryAllocationsSchema = useSalaryAllocationsSchema()
  const formik = useFormik<SalaryAllocationsSchema>({
    initialValues: {
      salaryAllocations: salaryAllocations.map((salaryAllocation) => {
        return {
          id: salaryAllocation.id,
          rate: salaryAllocation.rate
        }
      }),
      rateConfirmed: false,
      rateConfirmationRequired: false
    },
    onSubmit: onSubmit,
    validationSchema: salaryAllocationsSchema
  })

  const initialTotalAnnualSalary = getTotalAnnualSalary(
    salary,
    formik.initialValues
  )
  const newTotalAnnualSalary = getTotalAnnualSalary(salary, formik.values)

  return (
    <FormikProvider value={formik}>
      <Form
        id='salary-allocations-form'
        data-testid={`${testId}-form`}
        onSubmit={formik.handleSubmit}
      >
        <Modal
          isOpen
          onRequestClose={onRequestClose}
          position='pin-right'
          shouldCloseOnOverlayClick={false}
          size='sm'
          testId={testId}
        >
          <Modal.Header>
            <div data-testid='salary-allocations-modal-header'>{title}</div>
          </Modal.Header>
          <Modal.Body
            data-testid='salary-allocations-modal-contents'
            className='relative'
          >
            <div className='grid gap-y-6 mb-2'>
              <div
                data-testid='salary-allocations-modal-sub-header'
                className='type-default font-normal text-secondary'
              >
                {subtitle}
              </div>
              {saving ? (
                <LoadSpinner
                  message={t('savingPayChange', 'Saving pay change...')}
                />
              ) : (
                <>
                  <SalaryAllocationPanel
                    {...trackSalaryAllocationChange('rate')}
                    allocationInputLabel={t(
                      'allocatedSalary',
                      'Allocated salary'
                    )}
                    allocations={salaryAllocations}
                    caption={t(
                      'changeAllocatedSalarySubtitle',
                      'Change the allocated salary for each job. {{ employeeFirstName }} will receive a total annual salary for all jobs.',
                      {
                        employeeFirstName: i18next.format(
                          employeeFirstName,
                          i18Formats.CAPITALIZE
                        )
                      }
                    )}
                    containerClassName={'!mb-0'}
                    legend={t('salaryAllocation', 'Salary allocation')}
                    salary={newTotalAnnualSalary}
                    salaryLabel={t('totalAnnualSalary', 'Total annual salary')}
                    unsupportedCurrencyMessage={t(
                      'unsupportedCurrency',
                      'Unsupported currency'
                    )}
                  />
                  <div className='space-y-2'>
                    <EffectiveDate effectiveDate={effectiveDate} />
                    <SalaryAllocationsAlert
                      message={t(
                        'salaryAllocationsAlert',
                        'For salary allocated across locations, pay can only be changed effective immediately'
                      )}
                      testId={testId}
                    />
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='flex flex-grow flex-col gap-y-4'>
              <PayChangeThresholdAlert
                initialRate={initialTotalAnnualSalary.amount}
                newRate={newTotalAnnualSalary.amount}
                rateConfirmed={formik.values.rateConfirmed}
                setFieldTouched={formik.setFieldTouched}
                setFieldValue={formik.setFieldValue}
              />
              <Actions saving={saving} />
            </div>
          </Modal.Footer>
        </Modal>
      </Form>
    </FormikProvider>
  )
}

const EffectiveDate: React.FunctionComponent<{ effectiveDate: Date }> = ({
  effectiveDate
}) => {
  const { locale } = useBuffetContext()
  const { t } = useTranslation('employees')

  return (
    <TextInput
      testId={'salary-allocations-input-date'}
      label={t('whenShouldPayBeApplied', 'When should this take effect?')}
      prefix={<CalendarTodayIcon accessibility='decorative' />}
      prefixVariant={Prefix.Variant.icon}
      readOnly={true}
      value={formatDateInputValue(t, locale, effectiveDate)}
    />
  )
}

const Actions: React.FunctionComponent<{ saving: boolean }> = ({ saving }) => {
  const { t } = useTranslation('employees')
  const { dirty, isValid } = useFormikContext<PayChangeSchema>()
  return (
    <Button
      testId='salary-allocations-save'
      className='flex-grow'
      disabled={!dirty || !isValid || saving}
      form='salary-allocations-form'
      size='lg'
      type='submit'
    >
      {t('save', 'Save')}
    </Button>
  )
}

const getTotalAnnualSalary = (
  salary: Money,
  salaryAllocations: SalaryAllocationsSchema
): Money => {
  let sum = 0
  salaryAllocations.salaryAllocations.forEach((salaryAllocation) => {
    if (salaryAllocation.rate.currency === salary.currency) {
      sum += Number(salaryAllocation.rate.amount)
    }
  })

  return { amount: sum, currency: salary.currency }
}

const useOnSubmit = (
  salaryAllocations: Array<Pick<SalaryAllocation, 'id' | 'rate'>>,
  changeSalary: {
    (options: ChangeSalaryAllocationsMutationOptions): Promise<
      FetchResult<ChangeSalaryAllocationsMutation>
    >
    (arg0: {
      variables: ChangeSalaryAllocationsMutationVariables
      onCompleted: (mutation: ChangeSalaryAllocationsMutation) => void
      onError: () => void
    }): void
  },
  effectiveDate: Date,
  onClose: VoidFunction,
  onSuccess: VoidFunction
): ((values: SalaryAllocationsSchema) => void) => {
  const employeeId = useEmployeeId()
  const { track: trackingHook } = useTracking()
  const { showSuccessSnackBar, showErrorSnackBar } = usePayChangeSnackBar()

  const onError = () => {
    onClose()
    showErrorSnackBar()
  }

  return (values: SalaryAllocationsSchema) => {
    trackingHook('salary-allocation-change-modal.save')
    changeSalary({
      variables: {
        employeeId: employeeId,
        changeSalaryAllocationInputs: values.salaryAllocations
          .filter((salaryAllocation) => {
            return (
              Number(
                salaryAllocations.find(
                  (originalSalaryAllocation) =>
                    originalSalaryAllocation.id === salaryAllocation.id
                )?.rate.amount
              ) !== Number(salaryAllocation.rate.amount)
            )
          })
          .map((salaryAllocation) => {
            return {
              id: salaryAllocation.id,
              newRate: {
                amount: salaryAllocation.rate.amount.toString(),
                currency: salaryAllocation.rate.currency
              },
              effectiveDate: format(effectiveDate, 'yyyy-MM-dd')
            }
          })
      },
      onCompleted: (mutation: ChangeSalaryAllocationsMutation) => {
        if (mutation.changeSalaryAllocations?.__typename === 'Salary') {
          showSuccessSnackBar()
          onSuccess()
        } else {
          onError()
        }
      },
      onError: onError
    })
  }
}
