import {
  differenceInDays,
  formatRange,
  Formats
} from '@toasttab/buffet-pui-date-utilities'
import { Currency, formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { checkLocale } from '@toasttab/buffet-pui-locale-utilities'
import { Money } from '@local/generated/graphql'
import { useFormikContext } from 'formik'
import { PayChangeSchema } from '../../domain'
import { usePayChangeModalStore } from '../../PayChangeModalStore'

export const formatPaidHoursDateRange = (
  effectiveDate: Date,
  language: string
) => {
  const today = new Date()
  return formatRange(
    { from: effectiveDate, to: today },
    Formats.date.medium,
    checkLocale(language)
  )
}

export const formatHrCurrency = (pay: Money, language: string) => {
  return formatCurrency(
    {
      amount: pay.amount,
      currency: pay.currency.toString() as Currency
    },
    checkLocale(language)
  )
}

export const formatHrCurrencyDifference = (pay: Money, language: string) => {
  return formatCurrency(
    {
      amount: Math.abs(pay.amount),
      currency: pay.currency.toString() as Currency
    },
    checkLocale(language)
  )
}

export const useShouldCalculateRetroPay = (): boolean => {
  const { initialValues, values } = useFormikContext<PayChangeSchema>()
  const effectiveDate = values?.effectiveDate
  const today = new Date()
  const dirty = values.rate !== initialValues.rate

  return dirty && !!effectiveDate && differenceInDays(effectiveDate, today) < 0
}

export const useShowRetroPayDisbursementOptions = (
  employmentStatus?: string
): boolean => {
  const { initialValues, values } = useFormikContext<PayChangeSchema>()
  const effectiveDate = values?.effectiveDate
  const today = new Date()
  const retroPay = usePayChangeModalStore((x) => x.retroPay)
  const dirty = values?.rate !== initialValues.rate

  return (
    !(employmentStatus === 'TerminatedEmploymentStatus') &&
    dirty &&
    !!effectiveDate &&
    differenceInDays(effectiveDate, today) < 0 &&
    retroPay?.retroPay.amount > 0
  )
}

export const useShowRetroDeductionPanel = (): boolean => {
  const { initialValues, values } = useFormikContext<PayChangeSchema>()
  const dirty = values?.rate !== initialValues.rate

  const retroPay = usePayChangeModalStore((x) => x.retroPay)

  const effectiveDate = values?.effectiveDate

  return (
    dirty &&
    retroPay?.retroPay.amount < 0 &&
    differenceInDays(effectiveDate, new Date()) < 0
  )
}

export const useShowTerminatedEmployeeAlert = (
  employmentStatus?: string
): boolean => {
  const { initialValues, values } = useFormikContext<PayChangeSchema>()
  const dirty = values.rate !== initialValues.rate

  const retroPay = usePayChangeModalStore((x) => x.retroPay)

  const effectiveDate = values?.effectiveDate

  return (
    employmentStatus === 'TerminatedEmploymentStatus' &&
    dirty &&
    (retroPay?.retroPay.amount < 0 || retroPay?.retroPay.amount > 0) &&
    differenceInDays(effectiveDate, new Date()) < 0
  )
}
