import { QueryErrorResetBoundary } from 'react-query'
import { ErrorBoundaryGroup, useEnvironment } from '@local/ec-app'
import * as React from 'react'
import { MappingStatusLocationCard } from './MappingStatusLocationCard'
import { ActionButtons } from '../profile/personal/components'
import {
  EmployeeMappingStatus,
  useGetEmployeeMappingStatusQuery
} from '@local/generated/graphql'
import { useEmployeeId } from '../hooks'
import { TEST_MAPPING_STATUS } from './MappingConstants'
import { levelIdToString } from './utils'
import { useQueryParams } from '../../shared/useQueryParams'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'
import { ErrorModal } from '@toasttab/buffet-pui-modal'
import { EmployeeOnboardingError } from '../../error/EmployeeErrorBoundary'

export const MappingStatusApp = () => {
  return (
    <QueryErrorResetBoundary>
      <ErrorBoundaryGroup>
        <MappingStatusAppContent />
      </ErrorBoundaryGroup>
    </QueryErrorResetBoundary>
  )
}

const MappingStatusAppContent: React.FunctionComponent = () => {
  const employeeId = useEmployeeId()
  const environmentContext = useEnvironment()
  const { t } = useTranslation('employees')
  const [errorModalCanOpen, setErrorModalCanOpen] = React.useState(true)

  const queryParams = useQueryParams()
  const mockData = queryParams.get('mappingData') === 'mock'

  // for errors see https://www.apollographql.com/docs/apollo-server/data/errors/
  const resp = useGetEmployeeMappingStatusQuery({
    variables: { employeeUuid: employeeId }
  })

  if (resp.loading) {
    // matches the approximate size of the expanded location card
    return <Skeleton width={'755px'} height={'290px'} />
  }

  if (!resp.data) {
    return (
      <>
        <div className='mb-3 type-headline-5'>POS Mapping</div>
        <ErrorModal
          heading={t('sorrySomethingWentWrong')}
          isOpen={errorModalCanOpen}
          onOkay={() => setErrorModalCanOpen(false)}
          testId='error-loading-modal'
        >
          <p className='mb-2'>{t('tryAgainLater')}</p>
          <p>
            {t('ifIssueContinuesContactCustomerCare1')}{' '}
            <a
              className='mt-0 text-link'
              href='https://central.toasttab.com/'
              rel='noopener noreferrer'
            >
              {t('ifIssueContinuesContactCustomerCare2')}
            </a>
            .
          </p>
        </ErrorModal>
      </>
    )
  }

  const employee = (
    mockData && !environmentContext.isProduction
      ? TEST_MAPPING_STATUS
      : resp.data.getEmployeeMappingStatus
  ) as EmployeeMappingStatus
  const locations = employee.locations.filter(
    (location) => location.mappingStatus !== 'POS_ONLY'
  )

  if (locations.length === 0) {
    return (
      <div className={'mt-6'}>
        <EmployeeOnboardingError reset={() => {}} />
      </div>
    )
  }

  return (
    <>
      <div>
        <div className='mb-3 type-headline-5'>POS Mapping</div>
        {locations.map((location) => {
          return location.posLocationGuid != null ? (
            <MappingStatusLocationCard
              key={levelIdToString(location.payrollLocationId!)}
              employee={employee}
              location={location}
            />
          ) : null
        })}
        <div className='type-subhead text-gray-75'>
          {t('onlyShowingIntegratedLocations')}
        </div>
      </div>
      <ActionButtons />
    </>
  )
}
