import * as React from 'react'
import cx from 'classnames'
import { Row as ReactTableRow } from 'react-table'
import { Body, Cell, Row, Table } from '@toasttab/buffet-pui-table'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { PayChangeCardProps } from '@local/change-pay'

type Props = {
  /** pay change data rows, retrieved by the useTable hook  */
  rows: ReactTableRow<PayChangeCardProps>[]
  /** function to call on the row to prepare it to be used in the table, retrieved by the useTable hook  */
  prepareRow: (row: ReactTableRow<PayChangeCardProps>) => void
} & TestIdentifiable

export const PayChangesTable: React.FunctionComponent<Props> = ({
  testId,
  rows,
  prepareRow
}) => {
  return (
    <Table testId={testId?.toString()} variant='summary-card'>
      <Body>
        {rows.map((row) => {
          prepareRow(row)
          const rowProps = row.getRowProps()
          return (
            <Row
              {...row.getRowProps()}
              key={row.index}
              className={cx(rowProps.className, 'border-b-0')}
            >
              {row.cells.map((cell) => {
                return (
                  <Cell
                    {...cell.getCellProps({
                      className: 'pl-0 pr-0'
                    })}
                  >
                    <>{cell.render('Cell')}</>
                  </Cell>
                )
              })}
            </Row>
          )
        })}
      </Body>
    </Table>
  )
}
