import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { trackEmploymentOverview } from '@local/track'
import {
  HumanResourcesPermission,
  useGetSupervisorsQuery
} from '@local/generated/graphql'
import { useApi } from '../../../../ApiProvider'
import { useEmployeeId } from '../../../hooks'
import { useHasPermission } from '../hooks'
import { useNavigateTo } from '../../../../navigation/hooks'
import { OverviewCard, OverviewCardBoundary } from '../components'
import { SupervisorsTable } from './components/SupervisorsTable'
import { SupervisorsEmptyState, SupervisorsLoadingState } from './components'

export const SupervisorsCard = () => {
  const api = useApi()
  const canEditEmployee = useHasPermission(
    HumanResourcesPermission.EMPLOYMENT_EDIT
  )
  const navigateTo = useNavigateTo()
  const { t } = useTranslation('employees')

  const navigateToJobDetails = async () => {
    const esxResponse = await api.getEditPositionDetails()
    if (esxResponse?.url) {
      navigateTo(esxResponse.url, true)
    }
  }

  const employeeId = useEmployeeId()
  const { loading, data } = useGetSupervisorsQuery({
    variables: { employeeId }
  })

  const employee = data?.findEmployeeById
  const hasSupervisors = !!employee?.supervisors?.length

  return (
    <div data-testid='supervisors-card' className='grid'>
      <OverviewCardBoundary
        cardTitle={t('reportsTo')}
        errorText={t('cardErrorText', {
          fullName: employee?.user?.name.formatted,
          details: t('reportsTo')
        })}
      >
        <OverviewCard
          {...trackEmploymentOverview('supervisor-edit')}
          cardTitle={t('reportsTo')}
          noPadding
          onActionClick={canEditEmployee ? navigateToJobDetails : undefined}
        >
          {loading ? (
            <SupervisorsLoadingState />
          ) : !hasSupervisors ? (
            <SupervisorsEmptyState
              canEditSupervisors={canEditEmployee}
              firstName={
                employee?.user?.name.chosen ?? employee?.user?.name.first
              }
            />
          ) : (
            <SupervisorsTable supervisors={employee.supervisors} />
          )}
        </OverviewCard>
      </OverviewCardBoundary>
    </div>
  )
}
