import * as React from 'react'
import { RehireDialogue } from '@local/rehire-dialogue'
import * as Yup from 'yup'
import { useFormik, FormikProvider } from 'formik'
import type { RehireDialogueValues } from '@local/rehire-dialogue'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useRehireEmployeeNoChangesMutation } from '@local/generated/graphql'
import { useTranslation } from 'react-i18next'
import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import { differenceInCalendarMonths } from 'date-fns'
import { useEmployee } from '../../../hooks'
// disabling because this is not getting fixed
// eslint-disable-next-line @toasttab/buffet/hard-coded-dates
import { DateTime } from 'luxon'
import { usePersonalProfile } from '../../../profile/personal/hooks'
import { useJobAssignmentsStore } from '../jobs/JobAssignmentsStore'

const REHIRE_CHANGES_OPTIONS = [
  { labelKey: 'no', value: 'false', helpTextKey: 'rehireChangesNo' },
  { labelKey: 'yes', value: 'true', helpTextKey: 'rehireChangesYes' }
] as const

type Props = {
  firstName: string
  lastName: string
  employeeId: string
  rehireDialogueOpen: boolean
  onSubmit: (changes: RehireDialogueValues) => void
  onClose: () => void
}

type RehireChangesArgs = {
  isBothSalaryAndHourly: boolean
  hasSsn: boolean
  hasEmail: boolean
  multiplePrimaryPositions: boolean
  noJobAssignments: boolean
}

type OptionToDisable = 'yes' | 'no' | 'neither'

const getRehireChangesDefault = (args: RehireChangesArgs): OptionToDisable => {
  const {
    isBothSalaryAndHourly,
    hasEmail,
    hasSsn,
    multiplePrimaryPositions,
    noJobAssignments
  } = args

  if (isBothSalaryAndHourly) {
    return 'yes'
  }
  if (!hasEmail || !hasSsn || multiplePrimaryPositions || noJobAssignments) {
    return 'no'
  }
  return 'neither'
}

const RehireDialogueController = (props: Props) => {
  const {
    employeeId,
    firstName,
    lastName,
    rehireDialogueOpen,
    onClose,
    onSubmit
  } = props

  const { t } = useTranslation('employees')
  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()
  const [rehireEmployee, { loading }] = useRehireEmployeeNoChangesMutation()
  const personalProfile = usePersonalProfile()
  const employee = useEmployee()

  const jobAssignments = useJobAssignmentsStore((x) => x.jobAssignments)

  const isBothSalaryAndHourly =
    jobAssignments.some(
      (jobAssignment) => jobAssignment.activeHourlyRateOfPay === null
    ) &&
    jobAssignments.some(
      (jobAssignment) => jobAssignment.activeHourlyRateOfPay !== null
    )

  const multiplePrimaryPositions =
    jobAssignments.filter((j) => j.isPrimary).length > 1

  const email = employee.overview.emailAddress
  const hasEmail =
    (email && email.includes('@example.com')) || email === '' || email === null
      ? false
      : true
  const hasSsn = personalProfile.basic.ssnToken

  const optionToDisable = getRehireChangesDefault({
    hasEmail,
    hasSsn: hasSsn !== null,
    multiplePrimaryPositions,
    isBothSalaryAndHourly,
    noJobAssignments: jobAssignments.length === 0
  })

  const rehireEmployeeNoChanges = async (
    employeeId: string,
    rehireValues: RehireDialogueValues
  ) => {
    return rehireEmployee({
      variables: {
        request: {
          employeeId,
          rehireDate: format(new Date(rehireValues.rehireDate), 'yyyy-MM-dd')
        }
      },
      onCompleted: () => {
        showSuccessSnackBar(t('rehireSuccess', { name: firstName }), {
          showIcon: true
        })
        onSubmit(rehireValues)
      },
      onError: () => {
        showErrorSnackBar(t('sorrySomethingWentWrong'))
      }
    })
  }

  const submitRehireEmployee = (rehireValues: RehireDialogueValues) => {
    if (!rehireValues.rehireChanges) {
      return rehireEmployeeNoChanges(employeeId, rehireValues)
    }
    if (rehireValues.rehireChanges) {
      return Promise.resolve(onSubmit(rehireValues))
    }
  }

  const rehireOptions = REHIRE_CHANGES_OPTIONS.map(
    ({ labelKey, value, helpTextKey }) => {
      return {
        label: t(labelKey),
        value,
        helpText: t(helpTextKey, { name: firstName }),
        disabled: optionToDisable === labelKey
      }
    }
  )

  const validationSchema = useRehireValidationSchema()

  const formik = useFormik<RehireDialogueValues>({
    initialValues: {
      rehireDate: new Date().toString(),
      rehireChanges: optionToDisable === 'no'
    },
    validationSchema,
    onSubmit: (modalValues) => submitRehireEmployee(modalValues)
  })

  return (
    <>
      {rehireDialogueOpen && (
        <FormikProvider value={formik}>
          <RehireDialogue
            isLoading={loading}
            onClose={onClose}
            rehireNameLabel={t('rehireName', {
              name: firstName + ' ' + lastName
            })}
            rehireContinueLabel={t('rehireName', {
              name: firstName
            })}
            rehireDescriptionLabel={t('rehireDescription', { name: firstName })}
            rehireDateHelpLabel={t('rehireDateHelpText', { name: firstName })}
            rehireDateNoDashLabel={t('rehireDateNoDash')}
            rehireChangesLabel={t('rehireChangesLabel', { name: firstName })}
            whatsNextLabel={t('whatsNext')}
            whatsNextRehireLabel={t('whatsNextRehire', { name: firstName })}
            primaryHelperText={
              optionToDisable === 'no'
                ? t('rehireIncompleteEmployee', {
                    name: firstName
                  })
                : optionToDisable === 'yes'
                ? t('rehireSalaryHourlyEmployee', {
                    name: firstName
                  })
                : undefined
            }
            continueLabel={t('continue')}
            cancelButtonLabel={t('cancel')}
            rehireOptions={rehireOptions}
          />
        </FormikProvider>
      )}
    </>
  )
}

export { RehireDialogueController }

export const useRehireValidationSchema = () => {
  const { t } = useTranslation('employees')
  const { overview } = useEmployee()
  const terminationDate = overview?.terminationDate as DateTime
  const formattedTerminationDate = format(
    overview?.terminationDate?.toJSDate() as Date,
    Formats.date.full
  )

  const isWithinSixMonths = () => {
    return Yup.date()
      .required(t('rehiredDateRequired'))
      .test(
        'isWithinSixMonths',
        t('rehireDateSixMonths'),
        (value: Date): value is Date => {
          return differenceInCalendarMonths(new Date(value), new Date()) < 7
        }
      )
      .test(
        'isAfterTerminationDate',
        t('rehireDatePriorTermination', {
          name: overview?.firstName,
          terminationDate: formattedTerminationDate
        }),
        (newHireDate: Date): newHireDate is Date => {
          return newHireDate >= terminationDate.toJSDate()
        }
      )
  }
  return Yup.object({
    rehireDate: isWithinSixMonths(),
    rehireChanges: Yup.boolean().required(t('required'))
  })
}
