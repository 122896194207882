import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import en from '../locales/en-US'
import common from '../locales/common-en-US'
import payChangeTranslations from '../locales/payChange/pay-change-en-US'
import scheduledPayChangeTranslations from '../locales/payChange/scheduled-pay-changes-en-US'

type Props = {
  children: React.ReactNode
}

export const I18nConfigurationProvider = ({ children }: Props) => {
  const {
    i18n: { addResourceBundle }
  } = useTranslation()

  useEffect(() => {
    addResourceBundle('en-US', 'employees', en)
    addResourceBundle('en-US', 'common', common)
    addResourceBundle('en-US', 'pay-change', payChangeTranslations)
    addResourceBundle(
      'en-US',
      'scheduled-pay-changes',
      scheduledPayChangeTranslations
    )
  }, [addResourceBundle])

  return <>{children}</>
}
