import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { CategorizeIcon } from '@toasttab/buffet-pui-icons'
import { useIsViewingSelf } from '../../../../hooks'

export interface Props {
  canEditSupervisors: boolean
  firstName?: string | null
}

export const SupervisorsEmptyState: React.FunctionComponent<Props> = ({
  canEditSupervisors,
  firstName: firstNameProp
}) => {
  const { t } = useTranslation('employees')
  const isViewingSelf = useIsViewingSelf()
  const firstName = firstNameProp ?? t('yourEmployee')
  return (
    <div
      data-testid='no-supervisors'
      className='flex flex-col justify-center items-center'
    >
      <EmptyState
        testId='supervisors-empty-state'
        icon={<CategorizeIcon />}
        className='px-4 pt-4 pb-6 md:pb-8'
      >
        {isViewingSelf && canEditSupervisors
          ? t('noSupervisorsSelfCanAdd')
          : isViewingSelf
          ? t('noSupervisorsSelf')
          : canEditSupervisors
          ? t('noSupervisorsCanAdd', {
              firstName: firstName ?? t('yourEmployee')
            })
          : t('noSupervisors', {
              firstName: firstName ?? t('yourEmployee')
            })}
      </EmptyState>
    </div>
  )
}
