import * as React from 'react'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

type SalaryAllocationsAlertProps = {
  message: string
} & TestIdentifiable

export const SalaryAllocationsAlert: React.FunctionComponent<
  SalaryAllocationsAlertProps
> = ({ message, testId }) => {
  return (
    <Alert
      id='SalaryAllocationsAlert'
      variant='info'
      className='w-full'
      testId={`${testId}-alert`}
    >
      {message}
    </Alert>
  )
}
