import * as React from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { format } from '@toasttab/buffet-pui-date-utilities'
import {
  Currency,
  HourlyRetroPay,
  PayInterval,
  useRetroPayForHourlyPayChangeQuery
} from '@local/generated/graphql'
import { RetroPayPanel } from '@local/change-pay'
import { BaseRetroPayPanel } from './BaseRetroPayPanel'
import { LoadSpinner } from '../../../components'
import { PayChangeSchema } from '../../domain'
import { usePayChangeModalStore } from '../../PayChangeModalStore'
import { useShouldCalculateRetroPay } from './utils'
import { useJobAssignmentsStore } from '../../../JobAssignmentsStore'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export type HourlyRetroPayPanelProps = {
  employeeId: string
  jobAssignmentId?: string
} & TestIdentifiable

export const HourlyRetroPayPanel: React.FunctionComponent<
  HourlyRetroPayPanelProps
> = (props) => {
  const { values } = useFormikContext<PayChangeSchema>()
  const shouldCalculateRetroPay = useShouldCalculateRetroPay()

  return shouldCalculateRetroPay ? (
    <BaseHourlyRetroPayPanel effectiveDate={values.effectiveDate} {...props} />
  ) : null
}

const BaseHourlyRetroPayPanel: React.FunctionComponent<
  {
    effectiveDate: Date
  } & HourlyRetroPayPanelProps
> = ({ effectiveDate, employeeId, jobAssignmentId, testId }) => {
  const { t } = useTranslation('employees')
  const { initialValues, values } = useFormikContext<PayChangeSchema>()
  const today = new Date()

  const { loading, data } = useRetroPayForHourlyPayChangeQuery({
    variables: {
      hourlyRetroPayInput: {
        employeeId: employeeId,
        endDate: format(today, 'yyyy-MM-dd'),
        jobAssignmentId: jobAssignmentId!,
        newRate: {
          amount: values.rate.toString(),
          currency: values.currency as Currency
        },
        oldRate: {
          amount: initialValues.rate.toString(),
          currency: initialValues.currency as Currency
        },
        startDate: format(effectiveDate, 'yyyy-MM-dd')
      }
    }
  })

  const setLoading = usePayChangeModalStore((x) => x.setLoading)
  React.useEffect(() => {
    setLoading(loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const hourlyRetroPay = data?.retroPayForHourlyPayChange as HourlyRetroPay

  const setRetroPay = usePayChangeModalStore((x) => x.setRetroPay)
  const { setFieldValue } = useFormikContext<PayChangeSchema>()
  React.useEffect(() => {
    setRetroPay(hourlyRetroPay)
    setFieldValue('earnings', undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, values?.rate, values?.effectiveDate])

  const isRetroPayZero = Number(hourlyRetroPay?.retroPay.amount) === 0

  const employeeFirstName = useJobAssignmentsStore((x) => x.employeeFirstName)

  return loading ? (
    <LoadSpinner
      message={t('calculatingRetroPay', 'Calculating retro pay...')}
    />
  ) : !hourlyRetroPay ? null : isRetroPayZero ? (
    <RetroPayZeroPanel
      employeeFirstName={employeeFirstName}
      testId={`${testId}-zero`}
    />
  ) : (
    <BaseRetroPayPanel
      legend={t('retroPayPanelLegend', 'You have chosen a past date')}
      caption={t(
        'retroPayPanelCaption',
        "Unapproved hours will be updated with {{firstName}}'s new pay. Retro pay does not include overtime, taxes, or deductions.",
        { firstName: employeeFirstName }
      )}
      containerClassName='!mb-2.5'
      testId={testId}
      newRate={{
        amount: {
          amount: values.rate.toString(),
          currency: values.currency as Currency
        },
        interval: PayInterval.HOUR
      }}
      oldRate={{
        amount: {
          amount: initialValues.rate.toString(),
          currency: initialValues.currency as Currency
        },
        interval: PayInterval.HOUR
      }}
      t={t}
    />
  )
}

const RetroPayZeroPanel: React.FunctionComponent<
  {
    employeeFirstName: string
  } & TestIdentifiable
> = ({ employeeFirstName, testId }) => {
  const { t } = useTranslation('employees')
  return (
    <RetroPayPanel
      legend={t('retroPayPanelLegend', 'You have chosen a past date')}
      caption={t(
        'retroPayPanelCaption',
        "Unapproved hours will be updated with {{firstName}}'s new pay. Retro pay does not include overtime, taxes, or deductions.",
        { firstName: employeeFirstName }
      )}
      testId={testId}
      t={t}
    />
  )
}
