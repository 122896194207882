import * as React from 'react'
import { CookiesProvider } from 'react-cookie'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import {
  FeaturesProvider,
  CurrentUserProvider,
  I18nProvider,
  ConfigProvider,
  TrackingProvider,
  EnvironmentProvider,
  WindowProvider
} from './contexts'

const defaultFallback = (
  <div className='pin-center'>
    <MerryGoRound size='md' />
  </div>
)

type Props = {
  testId: string
  fallback?: React.ReactNode
  tailwindVersion?: string
  children: React.ReactNode
  name: string
}

const EcApplicationWrapper = (props: Props) => {
  const { fallback = defaultFallback, children } = props

  return (
    <React.Suspense fallback={fallback}>
      <WindowProvider>
        <EnvironmentProvider>
          <I18nProvider>
            <ConfigProvider>
              <CurrentUserProvider>
                <TrackingProvider>
                  <FeaturesProvider>
                    <CookiesProvider>{children}</CookiesProvider>
                  </FeaturesProvider>
                </TrackingProvider>
              </CurrentUserProvider>
            </ConfigProvider>
          </I18nProvider>
        </EnvironmentProvider>
      </WindowProvider>
    </React.Suspense>
  )
}

export { EcApplicationWrapper }
