import * as React from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'

type Props = {
  onClose: () => void
  showInfoModal: boolean
  infoHeaderLabel: string
  infoDescriptionLabel: string
  closeButtonLabel: string
}

const AdditionalInfoModal = (props: Props) => {
  const {
    onClose,
    showInfoModal,
    infoHeaderLabel,
    infoDescriptionLabel,
    closeButtonLabel
  } = props

  return (
    <Modal onRequestClose={onClose} isOpen={showInfoModal}>
      <Modal.Header>{infoHeaderLabel}</Modal.Header>
      <Modal.Body>
        <p>{infoDescriptionLabel}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>{closeButtonLabel}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export { AdditionalInfoModal }
