import * as React from 'react'
import {
  HeadingGroup,
  Title,
  PageBack,
  PageActions
} from '@toasttab/buffet-pui-config-templates'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { ChevronLeftIcon } from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import {
  useEmployee,
  useCompanyCode,
  useIsViewingSelf
} from '../employee/hooks'
import { useNavigateTo } from './hooks'
import { Overview } from '../employee/domain'
import { PageActionsContentSection } from './PageActionsContentSection'
import { useNavigationVersion } from '@toasttab/ec-features'

export const AppHeaderTitle = ({
  testId = 'app-header-title'
}: TestIdentifiable) => {
  const activeEmployee = useEmployee()
  const companyCode = useCompanyCode()
  const overview = activeEmployee?.overview ?? null
  const navigateTo = useNavigateTo()
  const isViewingSelf = useIsViewingSelf()

  const { version } = useNavigationVersion()
  const hasNavV3 = version === 'v3'

  const onBackButtonClick = () => {
    if (isViewingSelf) {
      navigateTo(`/mvc/${companyCode}/dashboard`, true)
    } else {
      if (hasNavV3) {
        navigateTo(`/${companyCode}/team/employees`, false)
      } else {
        navigateTo(`/mvc/${companyCode}/Team/Dashboard/Index`, true)
      }
    }
  }

  return (
    <div className='relative flex flex-row' data-testid={testId}>
      <HeadingGroup
        subTitle={isViewingSelf ? 'Myself' : 'Team'}
        className='no-site-style'
        testId='app-header-heading-group'
      >
        <Title className='no-site-style font-normal'>
          {overview ? Overview.getName(overview) : ''}
        </Title>
      </HeadingGroup>
      <PageActions>
        <PageActionsContentSection />
      </PageActions>
      <PageBack className='nav__desktop page-back'>
        <IconButton
          onClick={onBackButtonClick}
          className='flex p-1 flex-col w-12 h-12 justify-center items-center -mt-1.5'
          aria-label='Back'
          testId='app-header-title-back'
          iconColor='secondary'
          icon={<ChevronLeftIcon />}
        />
      </PageBack>
    </div>
  )
}
