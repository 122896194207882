import { List } from 'immutable'
import { DateTime } from 'luxon'
import {
  SocialSecurity,
  SocialSecurityAuditAction,
  SocialSecurityAuditEntry
} from './employee/profile/personal/domain/SocialSecurity'
import { Page } from './employee/profile/personal/domain/Page'

const parseSocialSecurityAuditAction = (
  json: any
): SocialSecurityAuditAction => {
  switch (json) {
    case 'viewed':
      return SocialSecurityAuditAction.VIEWED
    default:
      return SocialSecurityAuditAction.EDITED
  }
}

const parseSocialSecurityAuditEntry = (json: any): SocialSecurityAuditEntry => {
  return new SocialSecurityAuditEntry(
    json.id,
    json.email,
    json.firstName,
    json.lastName,
    json.chosenName,
    json.socialSecurityNumber,
    parseSocialSecurityAuditAction(json.action),
    DateTime.fromISO(json.date)
  )
}

const parseAuditLogItems = (json: any): List<SocialSecurityAuditEntry> => {
  if (json instanceof Array) {
    return List(json.map(parseSocialSecurityAuditEntry))
  } else {
    throw new Error(
      'Expecting an array of SocialSecurityAuditEntry, but got: ' +
        JSON.stringify(json)
    )
  }
}

export const parseAuditLog = (json: any): Page<SocialSecurityAuditEntry> => {
  const links = json._links
  return new Page(parseAuditLogItems(json.auditLog), links.next, links.previous)
}

export const parseSocialSecurity = (json: any): SocialSecurity => {
  return new SocialSecurity(json.number, parseAuditLog(json))
}
