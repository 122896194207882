import * as React from 'react'
import { SecondaryNavSection } from '@toasttab/buffet-pui-navigation'
import { useWindowProvider } from '@local/ec-app'
import { DynamicVisibilityPortal } from '@local/layout'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

import { NAV_PAGE_SECTION_ID } from './common'

export const NavigationContentSection = ({
  testId = 'navigation-content'
}: TestIdentifiable) => (
  <div className={NAV_PAGE_SECTION_ID} data-testid={testId} />
)

export const NavigationContent = ({
  children
}: React.PropsWithChildren<{}>) => {
  const { document } = useWindowProvider()

  return (
    <DynamicVisibilityPortal
      container={document}
      selector={`.${NAV_PAGE_SECTION_ID}`}
    >
      <SecondaryNavSection>{children}</SecondaryNavSection>
    </DynamicVisibilityPortal>
  )
}
