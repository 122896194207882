import * as React from 'react'
import { useQuery, useQueryClient } from 'react-query'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import { makeTimer } from '@local/shared-services'
import { useEmployee } from '../../../../hooks'
import {
  OverviewCardBoundary,
  useOverviewCardContext
} from './OverviewCardBoundary'
import { OverviewCard } from './OverviewCard'
import { ReadonlyTextSkeleton } from '../../../../../shared/ReadonlyTextSkeleton'

/**
 * The main card content loader. This will most likely be some configuration
 * of skeletons.
 */
export const ExampleOverviewCardLoader = () => {
  return (
    <div className='flex flex-col'>
      <Skeleton height='60px' />
      <ReadonlyTextSkeleton className='mt-4' />
      <ReadonlyTextSkeleton className='mt-4' />
    </div>
  )
}

/**
 * This is the top level component for a card.
 * For milestone 1, the card action is defined here since it will be available
 * during load and error states.
 * For milestone 2, the action will not be available at all because it will require
 * data that is fetched in the content component.
 *
 * This component will be the entry in the main app component.
 */
export const ExampleOverviewCardContainer = () => {
  const navigate = useNavigate()
  const onActionClick = () => navigate('/Employee/PositionDetail.aspx')
  // This will suspend the whole page
  const { overview } = useEmployee()
  const cardErrorText = `Sorry, we are unable to load ${
    overview.chosenName ?? overview.firstName
  } ${overview.lastName}'s example details'`
  const cardTitle = 'Example details'

  return (
    <OverviewCardBoundary
      onActionClick={onActionClick}
      cardTitle={cardTitle}
      loadingView={<ExampleOverviewCardLoader />}
      errorText={cardErrorText}
    >
      <ExampleOverviewCard />
    </OverviewCardBoundary>
  )
}

/**
 * This is the main card content. This is responsible for
 * - Fetching the cards data
 * - Handling results of edit state (v2)
 * - Updating data (v2)
 */
export const ExampleOverviewCard = () => {
  const { onActionClick, cardTitle } = useOverviewCardContext()
  const queryClient = useQueryClient()

  useQuery('my-query', () => makeTimer(3_000).then(() => ({})))

  // Uncomment to test error state
  // useQuery('my-query', () =>
  //   makeTimer(3_000).then(() => {
  //     throw new Error()
  //   })
  // )

  const onSuspendClicked = () => {
    queryClient.invalidateQueries('my-query')
  }

  return (
    <OverviewCard onActionClick={onActionClick} cardTitle={cardTitle}>
      <div className='flex flex-col justify-center items-center'>
        <div>This is an example card</div>
        <div className='flex flex-row'>
          <Button onClick={onSuspendClicked}>Refresh</Button>
        </div>
      </div>
    </OverviewCard>
  )
}
