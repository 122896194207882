import {
  track as ecTrack,
  useTracking as useEcTracking
} from '@toasttab/ec-track'

type DataToastTrackIdProps = {
  'data-toast-track-id': TrackId
}

type EmploymentOverviewTrackId = 'employment-overview'
type EmploymentOverviewActionTrackIds =
  | 'status-edit'
  | 'employment-edit'
  | 'job-add'
  | 'job-edit'
  | 'jobs-pagination'
  | 'pay-edit'
  | 'pay-change-history-view'
  | 'salary-edit'
  | 'scheduled-pay-change-view'
  | 'errored-pay-changes-view'
  | 'supervisor-view'
  | 'supervisor-edit'
  | 'supervisors-pagination'

export const trackEmploymentOverview = (
  actionTrackId: EmploymentOverviewActionTrackIds
): DataToastTrackIdProps => {
  return track(`employment-overview.${actionTrackId}`)
}

type SalaryAllocationsChangeModalTrackId = 'salary-allocation-change-modal'
type SalaryAllocationsChangeModalActionTrackIds = 'rate' | 'save' | 'close'

export const trackSalaryAllocationChange = (
  actionTrackId: SalaryAllocationsChangeModalActionTrackIds
): DataToastTrackIdProps => {
  return track(`salary-allocation-change-modal.${actionTrackId}`)
}

type PayChangeModalTrackId = 'pay-change-modal'
type PayChangeModalActionTrackIds =
  | 'rate'
  | 'date'
  | 'retro-disbursement'
  | 'deductions-link'
  | 'threshold-acknowledged'
  | 'save'
  | 'close'

export const trackPayChange = (
  actionTrackId: PayChangeModalActionTrackIds
): DataToastTrackIdProps => {
  return track(`pay-change-modal.${actionTrackId}`)
}

type AttachEarningsModalTrackId = 'attach-earnings-modal'
type AttachEarningsModalActionTrackIds =
  | 'retro-earning-code'
  | 'retro-pay'
  | 'overtime-link'
  | 'earning-code'
  | 'pay'
  | 'add'
  | 'delete'
  | 'back'
  | 'error-close'
  | 'save'
  | 'request-close'
  | 'close'
  | 'close-confirmation-stay'
  | 'hours'
  | 'rate'

export const trackAttachEarnings = (
  actionTrackId: AttachEarningsModalActionTrackIds
): DataToastTrackIdProps => {
  return track(`attach-earnings-modal.${actionTrackId}`)
}

type ScheduledPayChangeModalTrackId = 'scheduled-pay-change-modal'
type ScheduledPayChangeModalActionTrackIds =
  | 'cancel-initiate'
  | 'cancel-confirm'
  | 'keep'
  | 'close'

export const trackScheduledPayChange = (
  actionTrackId: ScheduledPayChangeModalActionTrackIds
): DataToastTrackIdProps => {
  return track(`scheduled-pay-change-modal.${actionTrackId}`)
}

type ErroredPayChangeModalTrackId = 'errored-pay-change-modal'
type ErroredPayChangeModalActionTrackIds = 'close' | 'acknowledge'

export const trackErroredPayChange = (
  actionTrackId: ErroredPayChangeModalActionTrackIds
): DataToastTrackIdProps => {
  return track(`errored-pay-change-modal.${actionTrackId}`)
}

type TransparentMappingTrackId = 'transparent-mapping'
type TransparentMappingActionTrackIds =
  | 'dropdown-confirm'
  | 'dropdown-ignore'
  | 'contact-support'

export const trackTransparentMapping = (
  actionTrackId: TransparentMappingActionTrackIds
): DataToastTrackIdProps => {
  return track(`transparent-mapping.${actionTrackId}`)
}

type AddEmployeeTrackId = 'add-employee'
type AddEmployeeActionTrackIds =
  | 'rehire-with-changes'
  | 'rehire-no-changes'
  | 'cancel'

export const trackAddEmployee = (
  actionTrackId: AddEmployeeActionTrackIds
): DataToastTrackIdProps => {
  return track(`add-employee.${actionTrackId}`)
}

export type TrackId =
  | `${EmploymentOverviewTrackId}.${EmploymentOverviewActionTrackIds}`
  | `${SalaryAllocationsChangeModalTrackId}.${SalaryAllocationsChangeModalActionTrackIds}`
  | `${PayChangeModalTrackId}.${PayChangeModalActionTrackIds}`
  | `${AttachEarningsModalTrackId}.${AttachEarningsModalActionTrackIds}`
  | `${ScheduledPayChangeModalTrackId}.${ScheduledPayChangeModalActionTrackIds}`
  | `${ErroredPayChangeModalTrackId}.${ErroredPayChangeModalActionTrackIds}`
  | `${TransparentMappingTrackId}.${TransparentMappingActionTrackIds}`
  | `${AddEmployeeTrackId}.${AddEmployeeActionTrackIds}`

const track = (trackId: TrackId): { 'data-toast-track-id': TrackId } =>
  ecTrack(trackId) as DataToastTrackIdProps

export const useTracking = (): { track: (trackId: TrackId) => void } => {
  const { track: ecTrack } = useEcTracking()
  const track = (trackId: TrackId) => {
    ecTrack(trackId)
  }
  return { track }
}
