import * as React from 'react'

export type ReadOnlyFieldProps = {
  title: string
  value: string
}

export default ({ title, value }: ReadOnlyFieldProps) => {
  return (
    <div className='flex items-start w-full mb-3 sm:w-1/2'>
      <div className='flex-1 font-bold type-subhead text-darken-56'>
        {title}
      </div>
      <div className='flex-1 type-subhead'>{value}</div>
    </div>
  )
}
