import { useApi } from '../../../ApiProvider'
import { useCompanyCode, useEmployeeId } from '../../hooks'
import { useQuery } from 'react-query'
import { useFeature } from '@toasttab/ec-features'
import { useFeatureFlag } from '@local/ec-app'
import { HumanResourcesPermission } from '@local/generated/graphql'
import { useActorPermissionsStore } from './ActorPermissionsStore'

export const useIsEmploymentOverviewReadonlyActive = () =>
  useFeatureFlag('ec-hr-employment-overview-readonly', false)

export const useIsEmploymentOverviewChangePayActive = () =>
  useFeature('ec-hr-employment-overview-change-pay', false)

export const useStatusChange = () => {
  const employeeId = useEmployeeId()
  const companyCode = useCompanyCode()
  const api = useApi()

  return useQuery(['statusChange', companyCode, employeeId], () =>
    api.getStatusChangeUrl()
  )
}

export const useHasPermission = (
  permission: HumanResourcesPermission
): boolean => {
  const actorPermissions = useActorPermissionsStore((x) => x.actorPermissions)
  return actorPermissions.some(
    (actorPermission) => actorPermission === permission
  )
}
