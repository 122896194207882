import * as yup from 'yup'
import { DateTime } from 'luxon'
import { isoDate } from '../../shared/yupHelpers'

export class Overview {
  static schema = yup
    .object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      emailAddress: yup.string().stripToNull().nullable(),
      chosenName: yup.string().stripToNull().nullable(),
      employeeNumber: yup.string().stripToNull().nullable(),
      employmentStatus: yup.string().required(),
      hireDate: isoDate().required(),
      terminationDate: isoDate(),
      primaryPhone: yup
        .string()
        .phone()
        .formattedPhone()
        .nullable()
        .default(null),
      photoUrl: yup.string().stripToNull().nullable(),
      toastIdentityGuid: yup.string().stripToNull().nullable()
    })
    .defined()

  static of(obj: any): Overview {
    return Overview.copyOf(
      Overview.schema.validateSync(obj, { abortEarly: false })
    )
  }

  static copyOf(valid: Overview): Overview {
    return new Overview(
      valid.firstName,
      valid.lastName,
      valid.chosenName,
      valid.emailAddress,
      valid.employeeNumber,
      valid.employmentStatus,
      valid.hireDate,
      valid.terminationDate,
      valid.primaryPhone,
      valid.photoUrl,
      valid.toastIdentityGuid
    )
  }

  private constructor(
    readonly firstName: string,
    readonly lastName: string,
    readonly chosenName: string | null,
    readonly emailAddress: string | null,
    readonly employeeNumber: string | null,
    readonly employmentStatus: string,
    readonly hireDate: DateTime,
    readonly terminationDate: DateTime | null,
    readonly primaryPhone: string | null,
    readonly photoUrl: string | null,
    readonly toastIdentityGuid: string | null
  ) {}

  static getEmploymentDateTime(overview: Overview | null): DateTime | null {
    if (!overview) {
      return null
    } else if (
      overview.employmentStatus.toLowerCase().includes('terminated') &&
      overview.terminationDate
    ) {
      return overview.terminationDate
    } else {
      return overview.hireDate
    }
  }

  static getName(overview: Overview | null): string {
    if (!overview) {
      return ''
    } else if (overview.chosenName) {
      return `${overview.chosenName} ${overview.lastName}`
    } else {
      return `${overview.firstName} ${overview.lastName}`
    }
  }

  static isUserMissingCriticalInfo(overview: Overview | null): boolean {
    return !overview?.emailAddress || !overview?.employeeNumber
  }
}
