import * as React from 'react'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { EarningsInput, EarningsInputProps } from './EarningsInput'

export const EarningsInputPanel: React.FunctionComponent<
  EarningsInputProps & TestIdentifiable
> = ({ testId, ...rest }) => {
  return (
    <Panel testId={testId} className='text-default !mb-0 !p-6' noElevation>
      <EarningsInput {...rest} />
    </Panel>
  )
}
