import React, { ReactNode } from 'react'
import { Option } from './formConstants'
import { TextInput } from '../components'
import { HelpIcon } from '@toasttab/buffet-pui-icons'
import { RadioGroup } from '@toasttab/buffet-pui-radio-group'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'

export const radioGroup = (
  id: string,
  options: Array<Option>,
  selectedOption: string | number,
  onChange: (val: string) => void
) =>
  addSpacing(
    <RadioGroup
      labelledbyId={id}
      onChange={(e) => onChange(e?.target?.value)}
      options={options.map((option) => ({
        checked: selectedOption.toString() === option.value,
        ...option
      }))}
    />
  )

export const textInput = (
  label: string,
  value: string,
  onChange: Function,
  sublabel?: ReactNode,
  placeholder?: string
) => configurableInput(label, value, onChange, 'string', sublabel, placeholder)

const configurableInput = (
  label: string,
  value: any,
  onChange: Function,
  inputType: string = 'string',
  sublabel?: ReactNode,
  placeholder?: string
) =>
  addSpacing(
    <div className='type-default text-secondary'>
      <TextInput
        label={label}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        inputType={inputType}
      />
      <div className='block py-1 text-darken-56'>{sublabel}</div>
    </div>
  )

// remove non-numeric characters, ignoring decimals
export const stripNonNumeric = (input: string) => input.replace(/[^0-9.]/g, '')

const currencyWithDecimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
})

export const currencyTextInput = (
  label: string,
  value: string,
  setValue: Function,
  subtext: JSX.Element | string
) => (
  <div className='mb-12'>
    <span
      onBlur={() =>
        setValue(
          currencyWithDecimals.format(parseFloat(stripNonNumeric(value) || '0'))
        )
      }
    >
      {textInput(label, value, setValue, subtext)}
    </span>
  </div>
)

export const checkbox = (
  label: ReactNode,
  checked: boolean,
  onChange: (checked: boolean) => void
) =>
  addSpacing(
    <Checkbox
      checked={checked}
      onChange={() => onChange(!checked)}
      label={label}
    />
  )

export const addSpacing = (element: ReactNode, spacing: number = 8) => (
  <div className={`mb-${spacing}`}>{element}</div>
)

export const getInfoLink = (url: string, label: ReactNode) => (
  <div className='flex items-center'>
    <HelpIcon size='md' />
    <a
      href={url}
      className='ml-1 font-bold'
      target='_blank'
      rel='noopener noreferrer'
    >
      {label}
    </a>
  </div>
)

export const PREPARER_COUNT_OPTIONS = [
  {
    value: '0',
    label: '0'
  },
  {
    value: '1',
    label: '1'
  },
  {
    value: '2',
    label: '2'
  },
  {
    value: '3',
    label: '3'
  },
  {
    value: '4',
    label: '4'
  },
  {
    value: '5',
    label: '5'
  }
]
