import * as React from 'react'
import { OverviewCard } from './OverviewCard'
import { useOverviewCardContext } from './OverviewCardBoundary'

export const OverviewCardSuspenseBoundary = ({
  children
}: React.PropsWithChildren<{}>) => {
  const { onActionClick, cardTitle, loadingView } = useOverviewCardContext()

  return (
    <React.Suspense
      fallback={
        <SuspendedOverviewCard
          onActionClick={onActionClick}
          cardTitle={cardTitle}
        >
          {loadingView}
        </SuspendedOverviewCard>
      }
    >
      {children}
    </React.Suspense>
  )
}

export const SuspendedOverviewCard = ({
  onActionClick,
  cardTitle,
  children
}: React.PropsWithChildren<{
  cardTitle: string
  onActionClick?: () => void
}>) => {
  return (
    <OverviewCard onActionClick={onActionClick} cardTitle={cardTitle}>
      {children}
    </OverviewCard>
  )
}
