import {
  JobAssignmentsListJobAssignmentFragment,
  JobAssignmentsListSalaryFragment
} from '@local/generated/graphql'

export const filterSalaryJobsForScheduledPayChange = (
  jobs: JobAssignmentsListJobAssignmentFragment[],
  selectedScheduledPayChangeId?: string | null,
  salary?: JobAssignmentsListSalaryFragment | null
): {
  filteredSalary: JobAssignmentsListSalaryFragment | undefined | null
  filteredSalaryJobs: JobAssignmentsListJobAssignmentFragment[]
} => {
  const salaryJobs = jobs.filter((it) => it.activeHourlyRateOfPay === null)

  const { filteredSalary, filteredSalaryJobs } =
    selectedScheduledPayChangeId && salary
      ? salary?.pendingPayChange?.id === selectedScheduledPayChangeId
        ? {
            filteredSalary: salary,
            filteredSalaryJobs: salaryJobs
          }
        : {
            filteredSalary: undefined,
            filteredSalaryJobs: []
          }
      : { filteredSalary: salary, filteredSalaryJobs: salaryJobs }

  return { filteredSalary, filteredSalaryJobs }
}

export const filterHourlyJobsForScheduledPayChange = (
  jobs: JobAssignmentsListJobAssignmentFragment[],
  selectedScheduledPayChangeId?: string | null
): { filteredHourlyJobs: Array<JobAssignmentsListJobAssignmentFragment> } => {
  const hourlyJobs = jobs.filter((it) => it.activeHourlyRateOfPay !== null)

  const { filteredHourlyJobs } =
    selectedScheduledPayChangeId && hourlyJobs
      ? {
          filteredHourlyJobs: hourlyJobs.filter(
            (it: JobAssignmentsListJobAssignmentFragment) =>
              it?.pendingPayChange?.id === selectedScheduledPayChangeId
          )
        }
      : { filteredHourlyJobs: hourlyJobs }

  return { filteredHourlyJobs }
}
