import * as yup from 'yup'
import { Option } from '../../../domain/Option'
import { Basic } from './Basic'
import { Contact } from './Contact'
import { EmergencyContact } from './EmergencyContact'
import { Addresses } from './Addresses'

export class PersonalProfileOptions {
  static optionsSchema = yup.array(Option.schema).defined()

  static schema = yup
    .object({
      gender: PersonalProfileOptions.optionsSchema,
      maritalStatus: PersonalProfileOptions.optionsSchema,
      veteranStatus: PersonalProfileOptions.optionsSchema,
      telephoneNumberTypes: PersonalProfileOptions.optionsSchema,
      employmentStatus: PersonalProfileOptions.optionsSchema,
      disability: PersonalProfileOptions.optionsSchema,
      ethnicity: PersonalProfileOptions.optionsSchema,
      statesInUnitedStates: PersonalProfileOptions.optionsSchema,
      countries: PersonalProfileOptions.optionsSchema
    })
    .defined()

  static of(obj: any): PersonalProfileOptions {
    return PersonalProfileOptions.schema.validateSync(obj, {
      abortEarly: false
    })
  }

  constructor(
    readonly gender: Array<Option>,
    readonly maritalStatus: Array<Option>,
    readonly veteranStatus: Array<Option>,
    readonly telephoneNumberTypes: Array<Option>,
    readonly employmentStatus: Array<Option>,
    readonly disability: Array<Option>,
    readonly ethnicity: Array<Option>,
    readonly statesInUnitedStates: Array<Option>,
    readonly countries: Array<Option>
  ) {}
}

export class PersonalProfileLinks {
  static schema = yup
    .object({
      onboarding: yup.string().required(),
      basic: yup.string().required(),
      socialSecurity: yup.string().required(),
      contact: yup.string().required(),
      emergencyContacts: yup.string().required(),
      addresses: yup.string().required(),
      personalProfile: yup.string().required(),
      settings: yup.string().required(),
      dashboard: yup.string().required(),
      syncToToastPos: yup.string().required(),
      rehire: yup.string().required(),
      terminate: yup.string().required(),
      workOpportunityTaxCredit: yup.string().nullable().optional()
    })
    .defined()

  static of(obj: any) {
    return PersonalProfileLinks.copyOf(
      PersonalProfileLinks.schema.validateSync(obj, { abortEarly: false })
    )
  }

  static copyOf(links: PersonalProfileLinks) {
    return new PersonalProfileLinks(
      links.onboarding,
      links.basic,
      links.socialSecurity,
      links.contact,
      links.emergencyContacts,
      links.addresses,
      links.personalProfile,
      links.settings,
      links.dashboard,
      links.syncToToastPos,
      links.rehire,
      links.terminate,
      links.workOpportunityTaxCredit
    )
  }

  private constructor(
    readonly onboarding: string,
    readonly basic: string,
    readonly socialSecurity: string,
    readonly contact: string,
    readonly emergencyContacts: string,
    readonly addresses: string,
    readonly personalProfile: string,
    readonly settings: string,
    readonly dashboard: string,
    readonly syncToToastPos: string,
    readonly rehire: string,
    readonly terminate: string,
    readonly workOpportunityTaxCredit: string | undefined | null
  ) {}
}

export class PersonalProfile {
  static schema = yup
    .object({
      companyCode: yup.string().required(),
      employeeId: yup.string().required(),
      basic: Basic.schema.defined(),
      contact: Contact.schema.defined(),
      addresses: Addresses.schema.defined(),
      emergencyContacts: yup.array(EmergencyContact.schema.defined()).defined(),
      options: PersonalProfileOptions.schema.defined(),
      links: PersonalProfileLinks.schema.defined()
    })
    .defined()

  static formSchema = PersonalProfile.schema.clone().shape({
    basic: Basic.onboardingFormSchema.required(),
    addresses: Addresses.formSchema,
    contact: Contact.formSchema
  })

  static of(obj: any): PersonalProfile {
    return this.copyOf(
      <PersonalProfile>PersonalProfile.schema.validateSync(obj, {
        abortEarly: false
      })
    )
  }

  static fromForm(obj: any): PersonalProfile {
    return this.copyOf(
      <PersonalProfile>PersonalProfile.formSchema.validateSync(obj, {
        abortEarly: false
      })
    )
  }

  static copyOf(valid: PersonalProfile) {
    return new PersonalProfile(
      valid.companyCode,
      valid.employeeId,
      Basic.copyOf(valid.basic),
      Contact.copyOf(valid.contact),
      Addresses.of(valid.addresses),
      valid.emergencyContacts.map(EmergencyContact.copyOf),
      PersonalProfileOptions.of(valid.options),
      PersonalProfileLinks.copyOf(valid.links)
    )
  }

  private constructor(
    readonly companyCode: string,
    readonly employeeId: string,
    readonly basic: Basic,
    readonly contact: Contact,
    readonly addresses: Addresses,
    readonly emergencyContacts: Array<EmergencyContact>,
    readonly options: PersonalProfileOptions,
    readonly links: PersonalProfileLinks
  ) {}
}
