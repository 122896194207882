import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { format } from '@toasttab/buffet-pui-date-utilities'
import {
  useChangeHourlyPayMutation,
  useGetEmploymentStatusQuery,
  useJobAssignmentForPayChangeQuery
} from '@local/generated/graphql'
import { PayChangeSchema } from './domain'
import { HourlyRetroPayPanel } from './components'
import { PayChangeModal } from './PayChangeModal'
import { useEmployeeId } from '../../../../hooks'
import { usePayChangeModalStore } from './PayChangeModalStore'

type HourlyPayChangeModalProps = {
  jobAssignmentId: string
  onClose: VoidFunction
  onSuccess: VoidFunction
}

export const HourlyPayChangeModal: React.FunctionComponent<
  HourlyPayChangeModalProps
> = ({ jobAssignmentId, onClose, onSuccess }) => {
  const { t } = useTranslation('employees')

  const employeeId = useEmployeeId()
  const { data: employmentStatusData, loading: employmentStatusLoading } =
    useGetEmploymentStatusQuery({
      variables: { employeeId }
    })
  const { data: jobAssignmentsData, loading: jobAssignmentsLoading } =
    useJobAssignmentForPayChangeQuery({
      variables: { employeeId, jobAssignmentIds: [jobAssignmentId] }
    })

  const employee = employmentStatusData?.findEmployeeById
  const jobAssignment =
    jobAssignmentsData?.jobAssignmentsById.length === 1
      ? jobAssignmentsData.jobAssignmentsById[0]
      : undefined

  const [changeHourlyPay, { loading: saving }] = useChangeHourlyPayMutation()

  const setIsHourly = usePayChangeModalStore((x) => x.setIsHourly)
  React.useEffect(() => {
    setIsHourly(true)
  }, [])

  const onSaveChange = (
    values: PayChangeSchema,
    onCompleted: VoidFunction,
    onError: VoidFunction
  ) => {
    onSaveHourlyChange(
      changeHourlyPay,
      employeeId,
      jobAssignment?.id ?? '',
      onCompleted,
      onError,
      values
    )
  }

  return employmentStatusLoading ||
    jobAssignmentsLoading ? null : !!jobAssignment ? (
    <PayChangeModal
      testId={'hourly-pay-change-modal'}
      currency={jobAssignment.activeHourlyRateOfPay?.currency}
      employmentStatus={employee?.employment?.employmentStatus.__typename}
      employmentTaxType={jobAssignment.employmentTaxType}
      jobAssignmentId={jobAssignment.id}
      onClose={onClose}
      onSavePayChange={onSaveChange}
      onSuccess={onSuccess}
      payGroupId={jobAssignment.payGroupId}
      rate={jobAssignment.activeHourlyRateOfPay?.amount}
      rateLabel={t('hourlyPay')}
      RetroPayPanel={HourlyRetroPayPanel}
      saving={saving}
      subtitle={jobAssignment.locationName}
      title={jobAssignment.name}
    />
  ) : null
}

export const onSaveHourlyChange = (
  changeHourlyPay: any,
  employeeId: string,
  jobAssignmentId: string,
  onCompleted: (attachRetroPay: boolean) => void,
  onError: VoidFunction,
  values: PayChangeSchema
) => {
  changeHourlyPay({
    variables: {
      employeeId: employeeId,
      jobAssignmentId: jobAssignmentId,
      changePayInput: {
        rate: { amount: values.rate.toString(), currency: values.currency },
        effectiveDate: format(values.effectiveDate, 'yyyy-MM-dd')
      }
    },
    onCompleted: onCompleted,
    onError: onError
  })
}
