import React, { useEffect, useState } from 'react'
import { AddressForm } from './AddressForm'
import { useFormik } from 'formik'
import { FormAlert } from './FormAlert'
import { Button } from '@toasttab/buffet-pui-buttons'
import { FormContainer } from './FormContainer'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import { Addresses, PostalAddress } from '../domain'
import {
  useUpdatePersonalProfile,
  useLocalityGnisCodesQuery,
  usePersonalProfile,
  useNavigateToProfilePage
} from '../hooks'
import { useEvent } from '@local/hooks'
import { useCompanyCode } from '../../../hooks'
import { useApi } from '../../../../ApiProvider'

export const AddressesForm = () => {
  const handleBackToProfilePage = useEvent(useNavigateToProfilePage())

  const companyCode = useCompanyCode()
  const personalProfile = usePersonalProfile()
  const updatePersonalProfile = useUpdatePersonalProfile()
  const addresses = personalProfile?.addresses
  const links = personalProfile?.links
  const options = personalProfile?.options
  const api = useApi()

  const [
    mailingAddressIsSameAsHomeAddress,
    setMailingAddressIsSameAsHomeAddress
  ] = useState(addresses?.mailingAddressIsSameAsHomeAddress())

  const [alertMessage, setAlertMessage] = useState('')

  const homeAddressConfig = useFormik({
    initialValues: addresses?.homeAddress || {},
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: PostalAddress.homeAddressformSchema,
    onSubmit: (_values, { setSubmitting }) => {
      setSubmitting(false)
    },
    enableReinitialize: true
  })

  const mailingAddressConfig = useFormik({
    initialValues: addresses?.mailingAddress || {},
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: PostalAddress.formSchema,
    onSubmit: (_values, { setSubmitting }) => {
      setSubmitting(false)
    },
    enableReinitialize: true
  })

  const handleSubmit = () => {
    if (!mailingAddressIsSameAsHomeAddress) {
      mailingAddressConfig.handleSubmit()
    }
    homeAddressConfig.handleSubmit()
  }

  useEffect(() => {
    const sendAddress = async () => {
      if (links) {
        setAlertMessage('')
        try {
          const newAddresses = Addresses.of({
            homeAddress: homeAddressConfig.values,
            mailingAddress: mailingAddressIsSameAsHomeAddress
              ? null
              : mailingAddressConfig.values
          })
          api.saveAddresses(links.addresses, newAddresses).then(() => {
            updatePersonalProfile({ addresses: newAddresses })
            handleBackToProfilePage()
          })
        } catch (e) {
          setAlertMessage('Could not save addresses')
        }
      }
    }

    if (
      homeAddressConfig.submitCount > 0 &&
      !homeAddressConfig.isSubmitting &&
      !homeAddressConfig.isValidating &&
      !mailingAddressConfig.isSubmitting &&
      !mailingAddressConfig.isValidating
    ) {
      if (
        (homeAddressConfig.errors &&
          Object.keys(homeAddressConfig.errors).length > 0) ||
        (!mailingAddressIsSameAsHomeAddress &&
          mailingAddressConfig.errors &&
          Object.keys(mailingAddressConfig.errors).length > 0)
      ) {
        setAlertMessage('Please fix the errors below')
        window.scrollTo(0, 0)
      } else {
        sendAddress()
      }
    }
  }, [
    homeAddressConfig.submitCount,
    homeAddressConfig.isSubmitting,
    homeAddressConfig.isValidating,
    mailingAddressConfig.submitCount,
    mailingAddressConfig.isSubmitting,
    mailingAddressConfig.isValidating,
    homeAddressConfig.values,
    homeAddressConfig.errors,
    mailingAddressConfig.values,
    mailingAddressConfig.errors,
    links,
    mailingAddressIsSameAsHomeAddress,
    api,
    updatePersonalProfile,
    handleBackToProfilePage
  ])

  // @ts-ignore
  const { localityGnisCode, postalCode } = homeAddressConfig.values

  // To clear locality gnis code when zipcode is invalid
  const clearLocalityCode = () => {
    if (localityGnisCode) {
      homeAddressConfig.setFieldValue('localityGnisCode', null)
    }
  }

  const { localityGnisCodes } = useLocalityGnisCodesQuery(
    companyCode,
    postalCode,
    {
      suspense: false,
      onError: (error) => {
        console.error(error)
        clearLocalityCode()
      }
    }
  )

  if (addresses && options) {
    return (
      <FormContainer headerText='Edit Address'>
        <FormAlert error={alertMessage} />
        <AddressForm
          label="What is the employee's home address?"
          instructions="This is the address used to calculate the employee's taxes"
          countries={options.countries}
          statesInUnitedStates={options.statesInUnitedStates}
          localityGnisCodes={localityGnisCodes}
          values={homeAddressConfig.values}
          errors={homeAddressConfig.errors}
          setFieldValue={homeAddressConfig.setFieldValue}
          addressType='home'
        />
        <div className='mb-6'>
          <Checkbox
            checked={mailingAddressIsSameAsHomeAddress}
            testId='checkbox-same-mailing-address'
            onChange={() =>
              setMailingAddressIsSameAsHomeAddress(
                !mailingAddressIsSameAsHomeAddress
              )
            }
            label='Mailing address is same as home address'
          />
        </div>
        {!mailingAddressIsSameAsHomeAddress && (
          <AddressForm
            label="What is the employee's mailing address?"
            instructions='This is the address used to mail checks'
            countries={options.countries}
            statesInUnitedStates={options.statesInUnitedStates}
            values={mailingAddressConfig.values}
            errors={mailingAddressConfig.errors}
            setFieldValue={mailingAddressConfig.setFieldValue}
            addressType='mailing'
          />
        )}

        <div className='flex justify-between'>
          <Button
            onClick={handleBackToProfilePage}
            variant='secondary'
            data-testid='cancel-btn'
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} data-testid='save-btn'>
            Save changes
          </Button>
        </div>
      </FormContainer>
    )
  } else {
    return null
  }
}
