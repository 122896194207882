import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { HumanResourcesPermission } from '@local/generated/graphql'
import { trackEmploymentOverview } from '@local/track'
import { ErroredPayChangesModal } from './ErroredPayChangesModal'
import { useHasPermission } from '../../../hooks'

type ErroredScheduledPayChangesAlertProps = {
  count: number
  onErrorAcknowledge: VoidFunction
} & TestIdentifiable
export const ErroredPayChangesAlert: React.FunctionComponent<
  ErroredScheduledPayChangesAlertProps
> = ({ count, onErrorAcknowledge, testId = 'errored-pay-changes-alert' }) => {
  const { t } = useTranslation('scheduled-pay-changes')
  const canChangePay = useHasPermission(HumanResourcesPermission.PAY_EDIT)
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  return count > 0 && canChangePay ? (
    <Alert testId={testId} className='w-full mt-3' variant='error'>
      <Trans
        t={t}
        i18nKey='erroredAlert'
        defaults='Scheduled pay changes can no longer be applied.<0>View</0>'
        count={count}
        components={[
          <button
            {...trackEmploymentOverview('errored-pay-changes-view')}
            className={'underline font-semibold'}
            onClick={() => setIsModalOpen(true)}
          />
        ]}
      />
      {isModalOpen && (
        <ErroredPayChangesModal
          onClose={() => {
            setIsModalOpen(false)
          }}
          onErrorAcknowledge={() => {
            setIsModalOpen(false)
            onErrorAcknowledge()
          }}
        />
      )}
    </Alert>
  ) : null
}
