import * as yup from 'yup'

export class PayrollBankAccount {
  static schema = yup
    .object({
      id: yup.string().required(),
      accountType: yup.string(),
      customerUuid: yup.string(),
      accountNumber: yup.string(),
      routingNumber: yup.string(),
      bankName: yup.string(),
      accountName: yup.string(),
      percentage: yup.number().default(0),
      amount: yup.number().default(0),
      sequence: yup.number()
    })
    .defined()

  static of(obj: any) {
    return PayrollBankAccount.schema.validateSync(obj, { abortEarly: false })
  }

  static fromList(objs: any[]): PayrollBankAccount[] {
    return objs.map((obj: any) => PayrollBankAccount.of(obj))
  }

  constructor(
    readonly id: string,
    readonly accountType?: string,
    readonly customerUuid?: string,
    readonly accountNumber?: string,
    readonly routingNumber?: string,
    readonly bankName?: string,
    readonly accountName?: string,
    readonly percentage?: number,
    readonly amount?: number,
    readonly sequence?: number
  ) {}
}
