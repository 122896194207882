import { DateTime } from 'luxon'
import * as yup from 'yup'
import { toDate } from '../../../shared/formHelpers'
import { isoDate } from '../../../shared/yupHelpers'
import { WorkAuthTypes } from '../shared/formConstants'
import { parsePhoneNumberWithError } from 'libphonenumber-js'
import { validatePhoneNumber } from '../../../shared/validatePhoneNumber'
import { v4 as uuid } from 'uuid'

/*
MOST OF THESE OBJECTS ARE REDUNDANT WITH PERSONAL PROFILE.
...however, since tax forms require EXACT verbiage in all aspects, some object definitions are duplicated here.

This should hopefully minimize the chances of "we changed the error message on the profile, but then unwittingly made our I9 non legally compliant."
*/

export const SOCIAL_SECURITY_REGEX = new RegExp('^$|^$|^\\d{3}-\\d{2}-\\d{4}$')
export const ALIEN_REG_NUMBER_REGEX = new RegExp('^\\d{7,9}$')
export const I94_ADMISSION_NUMBER_REGEX = new RegExp(
  '^\\d{11}|\\d{9}[A-Za-z]\\d'
)
export const VALID_SSN_MSG = 'Please enter a valid social security number'

export const formDate = (message?: string) =>
  yup
    .mixed<DateTime | null | undefined>()
    .transform(function (value: DateTime | string | null): DateTime | null {
      return toDate(value)
    })
    .test(
      'isValidDate',
      message || 'Please enter a date in the format mm/dd/yyyy',
      function (value: DateTime | null | undefined): value is DateTime {
        return value instanceof DateTime ? value.isValid : true
      }
    )

/**
 * Takes a StringSchema and checks to see if its a valid phone number as long as an I9 is concerned. So either it's a
 * valid phone number, or it's "N/A".
 */
const validI9PhoneNumber = () =>
  yup
    .string()
    .transform((value: string | undefined | null): string => {
      if (!value) {
        return 'N/A'
      } else if (value.toLowerCase() === 'n/a') {
        return 'N/A'
      } else {
        return phoneNumberFormatted(value)
      }
    })
    .test(
      'naOrPhoneNumber',
      'Please provide a valid phone number or N/A',
      (value: string): value is string => {
        if (!value) {
          return false
        } else {
          return value.toLowerCase() === 'n/a' || validatePhoneNumber(value)
        }
      }
    )

yup.addMethod(yup.string, 'validI9PhoneNumber', validI9PhoneNumber)

const needsRequiredDoc = (citizenshipStatus: string): boolean => {
  return (
    citizenshipStatus === WorkAuthTypes.lawful_permanent_res.value ||
    citizenshipStatus === WorkAuthTypes.alien_auth_to_work.value
  )
}

const hasProperRequiredDoc = () =>
  yup
    .string()
    .test(
      'exactlyOneDoc',
      'Please provide one required document number',
      function (): boolean {
        return (
          !needsRequiredDoc(this.parent.citizenshipStatus) ||
          (needsRequiredDoc(this.parent.citizenshipStatus) &&
            [
              this.parent.foreignPassportNumber,
              this.parent.alienUscisNumber,
              this.parent.i94AdmissionNumber
            ].filter((val) => !!val).length === 1)
        )
      }
    )

const phoneNumberFormatted = (phoneNumber: string | null): string => {
  if (phoneNumber) {
    try {
      const parsedPhoneNum = parsePhoneNumberWithError(phoneNumber, 'US')

      return parsedPhoneNum.country === 'US'
        ? parsedPhoneNum.formatNational()
        : parsedPhoneNum.formatInternational()
    } catch (parseError) {
      return ''
    }
  }

  return ''
}

export class Address {
  static schema = yup.object({
    address: yup.string(),
    address2: yup.string(),
    locality: yup.string(),
    administrativeArea: yup.string(),
    postalCode: yup.string()
  })

  static commonFormSchema = yup.object({
    address: yup
      .string()
      .required('Street address is required')
      .max(255, 'Street address must be 255 characters or fewer'),
    address2: yup.string().max(255),
    locality: yup
      .string()
      .required('City is required')
      .max(255, 'City must be 255 characters or fewer'),
    administrativeArea: yup
      .string()
      .required('State is required')
      .max(255, 'State must be 255 characters or fewer'),
    postalCode: yup
      .string()
      .required('Zip is required')
      .matches(/^[0-9]{5}$/, 'Zip must be 5 digits')
  })

  constructor(
    readonly address: string | null = '',
    readonly address2: string | null = '',
    readonly locality: string | null = '',
    readonly administrativeArea: string | null = '',
    readonly postalCode: string | null = ''
  ) {}
}

export class Preparer {
  static schema = yup.object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    middleInitial: yup.string(),
    address: Address.commonFormSchema,
    dateSigned: isoDate()
      .test(
        'pastDate',
        'Date signed must be in the past',
        function (date): date is DateTime | null {
          const dateDt = DateTime.fromFormat(date, 'MM/dd/yyyy')
          return dateDt ? dateDt.diffNow('days').days < 0 : true
        }
      )
      .required('Date signed is required')
      .transform((date: DateTime): string => date.toFormat('MM/dd/yyyy')),
    preparerSignatureUrl: yup
      .string()
      .required('A valid preparer signature is required')
  })
  static formSchema = yup.object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    middleInitial: yup.string(),
    address: Address.commonFormSchema,
    preparerSignatureBytes: yup.string().when('preparerSignatureUrl', {
      is: (val) => val,
      then: yup.string().nullable(),
      otherwise: yup.string().required('Please sign your name above')
    })
  })

  constructor(
    readonly firstName: string = '',
    readonly lastName: string = '',
    readonly middleInitial: string = '',
    readonly address: Address = new Address(),
    readonly preparerSignatureBytes: string = '',
    readonly preparerSignatureUrl: string = '',
    readonly dateSigned: DateTime | null = null,
    readonly uniqueIdentifier: string = uuid()
  ) {}
}

export class i9RequiredDocs {
  static schema = yup.object({
    citizenshipStatus: yup.string(),
    alienUscisNumber: yup.string(),
    isAlienNumber: yup.boolean(),
    i94AdmissionNumber: yup.string(),
    foreignPassportNumber: yup.string(),
    foreignPassportIssuingCountry: yup.string(),
    authorizedWorkDate: isoDate()
      .default(null)
      .when('citizenshipStatus', {
        is: (val) => val === WorkAuthTypes.alien_auth_to_work.value,
        then: isoDate().transform(function (value): string | null {
          if (value != null) {
            if (value instanceof DateTime) {
              return value.toFormat('MM/dd/yyyy')
            } else if (typeof value === 'string') {
              if (value.toLowerCase() === 'n/a') {
                return null
              }
            }
          }

          return 'N/A'
        }),
        otherwise: yup.string().nullable().default(null)
      })
  })
  static formSchema = yup.object({
    citizenshipStatus: yup
      .string()
      .required('Please select a citizenship status')
      .oneOf([
        'us_citizen',
        'noncitizen_national',
        'lawful_permanent_resident',
        'alien_authorized'
      ]),

    alienUscisNumber: hasProperRequiredDoc().when('citizenshipStatus', {
      is: (citizenshipStatus) =>
        citizenshipStatus === WorkAuthTypes.lawful_permanent_res.value ||
        citizenshipStatus === WorkAuthTypes.alien_auth_to_work.value,
      then: yup
        .string()
        .transform((alienNumber) =>
          alienNumber && alienNumber.charAt(0) === 'A'
            ? alienNumber.replace('A', '')
            : alienNumber
        )
        .nullable()
        .stripToNull()
        .matches(
          ALIEN_REG_NUMBER_REGEX,
          'Valid A-Numbers only contain numbers, 7-9 digits long'
        )
    }),

    i94AdmissionNumber: hasProperRequiredDoc().when('citizenshipStatus', {
      is: (val) => val === WorkAuthTypes.alien_auth_to_work.value,
      then: yup
        .string()
        .nullable()
        .stripToNull()
        .matches(
          I94_ADMISSION_NUMBER_REGEX,
          'Valid I-94 admission numbers are 11 characters long containing only numbers, or 9 numbers followed by one letter and ending with a number'
        ),
      otherwise: yup.string()
    }),

    foreignPassportNumber: hasProperRequiredDoc(),
    foreignPassportIssuingCountry: yup.string().when('foreignPassportNumber', {
      is: (val) => !!val,
      then: yup.string().required('Please select an issuing country'),
      otherwise: yup.string()
    }),
    isAlienNumber: yup.boolean().when('alienUscisNumber', {
      is: (val) => val,
      then: yup
        .boolean()
        .required("Please select which number you've entered."),
      otherwise: yup
        .boolean()
        .transform(() => false)
        .oneOf([undefined, false]) // we must ensure this is false or undefined when the ARN field is not filled
    }),

    authorizedWorkDate: yup
      .string()
      .ensure()
      .when('citizenshipStatus', {
        is: (val) => val === WorkAuthTypes.alien_auth_to_work.value,
        then: yup
          .string()
          .ensure()
          .test(
            'naOrDateTime',
            'Please provide a future date in the form mm/dd/yyyy or mark as N/A',
            (value: string): value is string => {
              const date = toDate(value)
              // if the string value is 'n/a', or can be casted to a DateTime in the future...
              return (
                value.toLowerCase() === 'n/a' ||
                (date instanceof DateTime &&
                  date.isValid &&
                  date.diffNow('days').days > 0)
              )
            }
          ),
        otherwise: yup.string().nullable().default(null)
      })
  })

  static copyOf(valid: i9RequiredDocs) {
    return new i9RequiredDocs(
      valid.citizenshipStatus,
      valid.alienUscisNumber,
      valid.i94AdmissionNumber,
      valid.foreignPassportNumber,
      valid.foreignPassportIssuingCountry,
      valid.authorizedWorkDate,
      valid.isAlienNumber
    )
  }

  static of(obj: any): i9RequiredDocs {
    return this.copyOf(
      <i9RequiredDocs>(
        i9RequiredDocs.schema.validateSync(obj, { abortEarly: false })
      )
    )
  }

  constructor(
    readonly citizenshipStatus: string,
    readonly alienUscisNumber: string = '',
    readonly i94AdmissionNumber: string = '',
    readonly foreignPassportNumber: string = '',
    readonly foreignPassportIssuingCountry: string = '',
    readonly authorizedWorkDate: string | null,
    readonly isAlienNumber: boolean
  ) {}
}

export class I9Form {
  // this schema is used for API responses like when we GET the form for edit or read-only
  // We don't want to do much validation here in case the database has invalid data
  static schema = yup
    .object({
      // required fields
      firstName: yup.string(),
      lastName: yup.string(),
      address: Address.schema,
      dateOfBirth: isoDate().transform(
        (date: DateTime): string | null => date && date.toFormat('MM/dd/yyyy')
      ),
      employeeSignatureBytes: yup.string(),
      employeeSignatureUrl: yup.string(),
      preparerCertification: yup.string(),
      preparerCount: yup.string(),
      requiredDocs: i9RequiredDocs.schema,
      dateSigned: isoDate().transform(
        (date: DateTime): string | null => date && date.toFormat('MM/dd/yyyy')
      ),

      //optional fields
      middleInitial: yup.string(),
      otherLastNames: yup.string(),
      email: yup.string(),
      telephoneNumber: yup.string(),

      socialSecurityNumber: yup.string(),
      preparers: yup.array(Preparer.schema)
    })
    .defined()

  // this schema is used when the employee clicks "save"
  // We want to do a full validation here to avoid saving invalid data
  static commonFormSchema = yup
    .object({
      // required fields
      firstName: yup.string().required('First Name is required'),
      lastName: yup.string().required('Last Name is required'),
      address: Address.commonFormSchema.required(),
      dateOfBirth: formDate()
        .test(
          'pastDate',
          'Date of birth must be in the past',
          function (date: DateTime): date is DateTime {
            return date ? date.diffNow('days').days < 0 : true
          }
        )
        .required('Date of birth is required'),
      employeeSignatureBytes: yup.string().when('employeeSignatureUrl', {
        is: (val) => val,
        then: yup.string().nullable(),
        otherwise: yup.string().required('Please sign your name above')
      }),
      preparerCertification: yup
        .string()
        .required('Please select an option above')
        .oneOf(['no_preparer', 'used_preparer']),
      preparerCount: yup.string().required('Please indicate a preparer count'),
      requiredDocs: i9RequiredDocs.formSchema.required(),

      //optional fields
      middleInitial: yup.string(),
      otherLastNames: yup.string(),
      email: yup.lazy((rawEmail) =>
        typeof rawEmail === 'string' && rawEmail.toLowerCase() === 'n/a'
          ? yup
              .string()
              .transform((val) => val.toUpperCase())
              .oneOf(['N/A'])
          : yup
              .string()
              .required('Please provide an email address or type N/A')
              .email('Please provide a valid email address or type N/A')
      ),
      telephoneNumber: yup
        .string()
        .required('Please enter a phone number or type N/A')
        .validI9PhoneNumber(),

      socialSecurityNumber: yup
        .string()
        .stripToNull()
        .nullable()
        .matches(SOCIAL_SECURITY_REGEX, VALID_SSN_MSG),
      preparers: yup.array(Preparer.formSchema)
    })
    .defined()

  static of(obj: any): I9Form {
    return this.copyOf(
      <I9Form>I9Form.schema.validateSync(obj, { abortEarly: false })
    )
  }

  static copyOf(valid: I9Form) {
    return new I9Form(
      false,
      valid.firstName,
      valid.lastName,
      valid.address,
      valid.dateOfBirth,
      valid.employeeSignatureBytes,
      valid.employeeSignatureUrl,
      valid.dateSigned,
      valid.preparerCertification,
      valid.preparerCount,
      valid.preparers,
      valid.requiredDocs,
      valid.middleInitial,
      valid.otherLastNames,
      valid.email,
      valid.telephoneNumber,
      valid.socialSecurityNumber
    )
  }

  constructor(
    //required fields
    readonly isUsingEverify: boolean = false,
    readonly firstName: string | null,
    readonly lastName: string | null,
    readonly address: Address,
    readonly dateOfBirth: DateTime | null,
    readonly employeeSignatureBytes: string | '',
    readonly employeeSignatureUrl: string | '',
    readonly dateSigned: DateTime | null,
    readonly preparerCertification: string,
    readonly preparerCount: string,
    readonly preparers: Array<Preparer>,
    readonly requiredDocs: i9RequiredDocs,

    //optional fields
    readonly middleInitial: string | null,
    readonly otherLastNames: string | null,
    readonly email: string | null,
    readonly telephoneNumber: string | null,
    readonly socialSecurityNumber: string | null
  ) {}
}

export class Option {
  static schema = yup.object({
    value: yup.string().stripToNull().nullable().defined(),
    label: yup.string().stripToNull().nullable().defined()
  })

  static of(obj: any): Option {
    return this.copyOf(
      <Option>Option.schema.validateSync(obj, { abortEarly: false })
    )
  }

  static copyOf(valid: Option) {
    return new Option(valid.value, valid.label)
  }

  constructor(readonly value: string, readonly label: string) {}
}

export class Options {
  static schema = yup.object({
    countries: yup.array().of(Option.schema).defined()
  })

  static of(obj: any): Options {
    return this.copyOf(
      <Options>Options.schema.validateSync(obj, { abortEarly: false })
    )
  }

  static copyOf(valid: Options) {
    return new Options(valid.countries, valid.states)
  }

  constructor(
    readonly countries: Array<Option>,
    readonly states: Array<Option>
  ) {}
}

export class I9FormResponse {
  static schema = yup.object({
    i9Form: I9Form.schema,
    options: Options.schema,
    isUsingEverify: yup.boolean()
  })

  static of(obj: any): I9FormResponse {
    return this.copyOf(
      <I9FormResponse>(
        I9FormResponse.schema.validateSync(obj, { abortEarly: false })
      )
    )
  }

  static copyOf(valid: I9FormResponse) {
    return new I9FormResponse(valid.i9Form, valid.options, valid.isUsingEverify)
  }

  constructor(
    readonly i9Form: I9Form,
    readonly options: Options,
    readonly isUsingEverify: boolean
  ) {}
}
