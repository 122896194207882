import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Body, Cell, Row, Table } from '@toasttab/buffet-pui-table'
import { PayCheckIcon } from '@toasttab/buffet-pui-icons'
import {
  JobAssignmentsListJobAssignmentFragment,
  JobAssignmentsListSalaryFragment
} from '@local/generated/graphql'
import { trackEmploymentOverview } from '@local/track'
import { useIsMobile } from '@local/use-is-mobile'
import { PayCell, PayCellProps } from './JobsTableColumns'

interface Props {
  jobAssignment: JobAssignmentsListJobAssignmentFragment
  salary: JobAssignmentsListSalaryFragment
  onPayChangeSuccess: () => void
  viewScheduledPayChange: (scheduledPayChangeId: string) => void
}

export const SalaryCard: React.FunctionComponent<Props> = (props) => {
  const isMobile = useIsMobile()
  return isMobile ? (
    <MobileSalaryCard {...trackEmploymentOverview('salary-edit')} {...props} />
  ) : (
    <DesktopSalaryCard {...trackEmploymentOverview('salary-edit')} {...props} />
  )
}

const DesktopSalaryCard: React.FunctionComponent<PayCellProps> = (props) => {
  return (
    <div
      data-testid={'desktop-salary-card'}
      className={'flex items-center ml-8 pl-8 pr-4 mr-4 my-4'}
    >
      <PayCell testId={`job-${props.jobAssignment.id}-salary`} {...props} />
    </div>
  )
}

const MobileSalaryCard: React.FunctionComponent<PayCellProps> = (props) => {
  const { t } = useTranslation('employees')
  return (
    <Table data-testid={'mobile-salary-card'} variant={'summary-card'}>
      <Body className={'flex border-b'}>
        <Row className={'flex flex-grow items-center'}>
          <Cell>
            <PayCheckIcon
              className={'text-secondary'}
              accessibility='decorative'
            />
          </Cell>
          <Cell className={'flex flex-grow text-left'}>
            <div className={'font-semibold'}>{t('salary')}</div>
          </Cell>
          <Cell className={'relative text-left'}>
            <PayCell
              testId={`job-${props.jobAssignment.id}-salary`}
              {...props}
            />
          </Cell>
        </Row>
      </Body>
    </Table>
  )
}
