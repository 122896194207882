import * as React from 'react'
import Skeleton from 'react-loading-skeleton'
import { ReadonlyTextSkeleton } from '../../../../../shared/ReadonlyTextSkeleton'

export const EmploymentStatusLoadingState = () => {
  return (
    <div className='flex flex-col'>
      <Skeleton height='60px' />
      <ReadonlyTextSkeleton className='mt-4' />
      <ReadonlyTextSkeleton className='mt-4' />
    </div>
  )
}
