import React from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Field, FieldName, FieldValue } from './Field'
import { EmergencyContact } from '../domain'

export interface ContactDetailsModalProps {
  emergencyContact: EmergencyContact
  isOpen: boolean
  onClose: () => void
}

export const ContactDetailsModal = ({
  emergencyContact,
  isOpen,
  onClose
}: ContactDetailsModalProps) => {
  if (!emergencyContact) {
    return null
  } else {
    return (
      <Modal
        isOpen={isOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={onClose}
      >
        <Modal.Header>Emergency Contact</Modal.Header>
        <Modal.Body>
          <div className='mt-4'>
            <Field>
              <FieldName>Name</FieldName>
              <FieldValue>
                {emergencyContact.firstName} {emergencyContact.lastName}
              </FieldValue>
            </Field>
            <Field>
              <FieldName>Relationship</FieldName>
              <FieldValue>{emergencyContact.relation}</FieldValue>
            </Field>
            {emergencyContact.primaryTelephone && (
              <Field>
                <FieldName>Phone</FieldName>
                <FieldValue>{emergencyContact.primaryTelephone}</FieldValue>
              </Field>
            )}
            {emergencyContact.email && (
              <Field>
                <FieldName>Email</FieldName>
                <FieldValue>{emergencyContact.email}</FieldValue>
              </Field>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
