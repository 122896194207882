import * as React from 'react'
import Skeleton from 'react-loading-skeleton'
import { ReadonlyTextSkeleton } from '../../../../../shared/ReadonlyTextSkeleton'

const ROW_COUNT = 2

export const SupervisorsLoadingState = () => {
  return (
    <div className='flex flex-col px-8'>
      <ul className='flex flex-col divide-y'>
        {Array(ROW_COUNT)
          .fill(null)
          .map((_value, index) => (
            <li key={index} className='flex flex-row items-center py-4'>
              <div className='pr-4 flex-none'>
                <Skeleton height='24px' width='24px' />
              </div>
              <div className='grow flex-1'>
                <ReadonlyTextSkeleton />
              </div>
              <div className='flex-none'>
                <Skeleton width='92px' height='24px' />
              </div>
            </li>
          ))}
      </ul>
    </div>
  )
}
