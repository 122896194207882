import * as React from 'react'
import {
  SecondaryNavSection,
  SecondaryNavItem,
  MobileSecondaryNav
} from '@toasttab/buffet-pui-navigation'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

import {
  useNavigationPath,
  useNavigateTo,
  useNavigationNodes,
  useIsMobileNavModalOpen,
  useSetIsMobileNavModalOpen
} from './hooks'
import { useEmployee } from '../employee/hooks'
import { NavigationTreeNode } from '../employee/domain'
import { useWindowProvider } from '@local/ec-app'
import { NavigationContentSection } from './NavigationContentSection'
import { useDynamicPortalsUpdater } from '@local/layout'
import { MappingStatusAlert } from '../employee/posMapping/MappingStatusAlert'

export enum SecondaryNavIconTitle {
  POS_MAPPING = 'POS Mapping'
}

const getIconForSecondaryNav = (
  secondaryNavItemValue: String
): React.JSX.Element | null => {
  switch (secondaryNavItemValue) {
    case SecondaryNavIconTitle.POS_MAPPING:
      return <MappingStatusAlert />
    default:
      return null
  }
}
export interface NavigationNodeSectionProps extends TestIdentifiable {
  selectedIndex: number
  onSelectionChange: (index: number) => void
  navigationNodes: NavigationTreeNode[]
  parentNode: NavigationTreeNode | null
  portalEl: HTMLElement
  onOpenStateChanged: (isOpen: boolean) => void
  isOpen: boolean
}

export const NavigationNodeSectionView = ({
  onSelectionChange,
  selectedIndex,
  navigationNodes,
  testId = 'navigation-node-section'
}: NavigationNodeSectionProps) => (
  <SecondaryNavSection
    selected={selectedIndex !== -1 ? selectedIndex : undefined}
    onSelectionChange={onSelectionChange}
    persist={false}
  >
    {navigationNodes.map((node, index) => (
      <SecondaryNavItem
        key={node.id}
        title={node.value}
        value={index}
        icon={getIconForSecondaryNav(node.value)}
        testId={`${testId}-${index}`}
      />
    ))}
  </SecondaryNavSection>
)

export const MobileNavigationNodeSectionView = ({
  parentNode,
  portalEl,
  selectedIndex,
  isOpen,
  onOpenStateChanged,
  navigationNodes,
  testId = 'mobile-navigation-node-section',
  ...props
}: NavigationNodeSectionProps) => (
  <MobileSecondaryNav
    title={navigationNodes[selectedIndex]?.value ?? ''}
    portalEl={portalEl}
    isOpen={isOpen}
    testId={testId}
    onOpenStateChange={onOpenStateChanged}
    modalContainerClassName='nav__mobile'
  >
    <NavigationNodeSectionView
      testId={testId}
      isOpen={isOpen}
      selectedIndex={selectedIndex}
      navigationNodes={navigationNodes}
      onOpenStateChanged={onOpenStateChanged}
      portalEl={portalEl}
      parentNode={parentNode}
      {...props}
    />
    <NavigationContentSection />
  </MobileSecondaryNav>
)

const makeNavigationNodeSection = (
  SectionComp: React.ComponentType<NavigationNodeSectionProps>
) => {
  return ({ testId }: TestIdentifiable) => {
    const overview = useEmployee()?.overview ?? null
    const [parentNode, childNode] = useNavigationPath()
    const navigateTo = useNavigateTo()
    const window = useWindowProvider()
    const isMobileNavModalOpen = useIsMobileNavModalOpen()
    const setIsMobileNavModalOpen = useSetIsMobileNavModalOpen()
    const updatePortals = useDynamicPortalsUpdater()
    // We need to create a wrapper for the mobile portal and put it in the tailwind context.
    const portalEl = React.useMemo(() => {
      const el = window.document.createElement('div')

      el.setAttribute('data-hr', 'true')
      window.document.body.appendChild(el)

      return el
    }, [window.document])
    // This state keeps track if the user clicks a navigation node. It's derived
    // by default, but once the user clicks we want that new node to be highlighted
    // instead of the derived one.
    // This only applies when clicking on a legacy page node!
    const [userSelectedNodeIndex, setUserSelectedNodeIndex] = React.useState<
      number | null
    >(null)
    const navigationNodes = useNavigationNodes()
    const selectedIndex =
      userSelectedNodeIndex ?? navigationNodes.indexOf(childNode)
    const onSelectionChange = (index: number) => {
      const node = navigationNodes[index]

      if (node?.data) {
        if (node.data.isLegacy) {
          // Only set if a legacy page...
          // This means we are navigating with a reload.
          setUserSelectedNodeIndex(index)
        }

        navigateTo(node.data.url, node.data.isLegacy)
      }

      setIsMobileNavModalOpen(false)
    }

    const onOpenStateChanged = (isOpen: boolean) => {
      setIsMobileNavModalOpen(isOpen)
      updatePortals()
    }

    return overview && navigationNodes.length ? (
      <SectionComp
        parentNode={parentNode}
        portalEl={portalEl}
        isOpen={isMobileNavModalOpen}
        onSelectionChange={onSelectionChange}
        onOpenStateChanged={onOpenStateChanged}
        selectedIndex={selectedIndex}
        navigationNodes={navigationNodes}
        testId={testId}
      />
    ) : null
  }
}

export const NavigationNodeSection = makeNavigationNodeSection(
  NavigationNodeSectionView
)
export const MobileNavigationNodeSection = makeNavigationNodeSection(
  MobileNavigationNodeSectionView
)
