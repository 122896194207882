import * as React from 'react'

export type HeadingProps = {
  title: string
  caption?: string
  children: React.ReactNode
}

export default ({ title, caption = '', children }: HeadingProps) => {
  return (
    <>
      <div className='flex items-center justify-between p-3 mb-3 bg-gray-25'>
        <div className='type-headline-4'>{title}</div>
        <div className='type-default text-darken-56'>{caption}</div>
      </div>
      <div className='flex flex-wrap pb-12'>{children}</div>
    </>
  )
}
