import * as React from 'react'
import Heading from './heading'
import ReadOnlyField from './readonly-field'
import { useTranslation } from 'react-i18next'
import { Employee } from '../models/employee'
import { Employer } from '../models/employer'
import { DateTime } from 'luxon'

type EmployerSectionProps = {
  employer: Employer
  employee: Employee
}

const FormatDate = (
  dateString: string | undefined,
  format: string = 'M/d/y hh:mm:ss a'
) => {
  if (dateString === undefined) return ''

  const date = DateTime.fromFormat(dateString, format)

  if (date.isValid) {
    return date.toLocaleString() || ''
  } else {
    return ''
  }
}

export const EmployerSection = ({
  employee,
  employer
}: EmployerSectionProps) => {
  const { t } = useTranslation('employees')

  return (
    <Heading title={t('employerParam', { name: employer.name })}>
      <ReadOnlyField title={t('companyName')} value={employer.name} />
      <ReadOnlyField
        title={t('employmentStartDate')}
        value={FormatDate(employee.employmentStartDate)}
      />
      <ReadOnlyField title={t('fein')} value={employer.tin} />
      <ReadOnlyField
        title={t('employmentEndDate')}
        value={FormatDate(employee.employmentEndDate)}
      />
      <ReadOnlyField title={t('companyPhoneNumber')} value={employer.phone} />
      <ReadOnlyField
        title={t('dateOfFinalPaycheck')}
        value={FormatDate(employee.dateOfFinalPaycheck, 'y-M-d')}
      />
      <ReadOnlyField
        title={t('companyAddress')}
        value={`${employer.addressLine1} \r\n ${employer.addressLine2} \r\n , ${employer.city}, \r\n ${employer.state}, \r\n ${employer.zip}`}
      />
      <ReadOnlyField
        title={t('terminationReason')}
        value={employee.terminationReason}
      />
      <ReadOnlyField title={t('reportsTo')} value={employee.supervisorName} />
    </Heading>
  )
}
