import moment from 'moment'
import { AllEmploymentStatuses, DateRange } from '@local/generated/graphql'

export const isSupportedStatus = (
  employmentStatus?: Partial<AllEmploymentStatuses> | null
) => {
  switch (employmentStatus?.__typename) {
    case 'ActiveEmploymentStatus':
    case 'DemoEmploymentStatus':
    case 'LeaveOfAbsenceEmploymentStatus':
    case 'PendingEmploymentStatus':
    case 'RehiredEmploymentStatus':
    case 'SeasonalEmploymentStatus':
    case 'TerminatedEmploymentStatus':
      return true
    default:
      return false
  }
}

export const getEmploymentDateRangeString = (
  dateRange?: DateRange | null
): string => {
  if (dateRange?.startDate && dateRange?.endDate) {
    const startDate = convertUTCDateToBrowserTZ(dateRange.startDate)
    const endDate = convertUTCDateToBrowserTZ(dateRange.endDate)

    const endDateString =
      endDate.toDateString() === new Date().toDateString()
        ? 'Present'
        : formatEmploymentDate(endDate, 'N/A')

    return `${formatEmploymentDate(startDate, 'N/A')} - ${endDateString}`
  }
  return 'N/A'
}

export const getEmploymentDetails = (
  employmentStatus?: Partial<AllEmploymentStatuses> | null
): string => {
  switch (employmentStatus?.__typename) {
    case 'LeaveOfAbsenceEmploymentStatus':
      return employmentStatus.leaveType ?? 'N/A'

    case 'RehiredEmploymentStatus':
      return formatEmploymentDateString(employmentStatus.rehireDate)

    case 'TerminatedEmploymentStatus':
      return formatEmploymentDateString(employmentStatus.lastDayOfEmployment)

    default:
      return ''
  }
}

export const getEmploymentSubDetails = (
  employmentStatus?: Partial<AllEmploymentStatuses> | null
): string => {
  switch (employmentStatus?.__typename) {
    case 'LeaveOfAbsenceEmploymentStatus':
      return getEmploymentDateRangeString(employmentStatus.scheduledLeave)

    case 'RehiredEmploymentStatus':
      return getEmploymentDateRangeString(employmentStatus.dismissalPeriod)

    case 'TerminatedEmploymentStatus':
      return formatEmploymentDateString(
        employmentStatus.lastDayToReceiveBenefits
      )

    default:
      return ''
  }
}

const convertUTCDateToBrowserTZ = (dateWithoutTZ: string): Date => {
  const browserTimeZoneOffsetInMinutes = new Date().getTimezoneOffset()

  return moment(new Date(dateWithoutTZ))
    .add(browserTimeZoneOffsetInMinutes, 'm')
    .toDate()
}

export const formatEmploymentDateString = (
  dateStr: string | undefined | null,
  formatOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC'
  }
): string => {
  if (dateStr && moment(dateStr, 'YYYY-MM-DD').isValid()) {
    return formatDate(dateStr, formatOptions)
  }

  return 'N/A'
}

export const formatEmploymentDate = (
  date: Date | undefined,
  defaultString?: string | undefined,
  formatOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC'
  }
): string | undefined => {
  if (date && moment(date).format('YYYY-MM-DD') !== 'Invalid date') {
    return formatDate(date, formatOptions)
  }

  return defaultString
}

const formatDate = (
  date: number | string | Date,
  formatOptions: Intl.DateTimeFormatOptions
): string => {
  return new Date(date).toLocaleDateString(
    Intl.DateTimeFormat().resolvedOptions().locale,
    formatOptions
  )
}
