import * as yup from 'yup'

export class Option {
  static schema = yup
    .object({
      label: yup.string().required(),
      value: yup.string().required()
    })
    .defined()

  static of(obj: any): Option {
    return Option.schema.validateSync(obj, { abortEarly: false })
  }

  constructor(readonly value: string, readonly label: string) {}
}
