import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select, SelectOption } from '@toasttab/buffet-pui-select'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { CloseIcon, DoneIcon } from '@toasttab/buffet-pui-icons'
import { MerryGoRound, SIZE } from '@toasttab/buffet-pui-loading-indicators'
import {
  PayrollEmployeeMapping,
  useGetPosMappingOptionsQuery
} from '@local/generated/graphql'
import { trackTransparentMapping } from '@local/track'
import { useEmployee, useEmployeeId } from '../hooks'
import { MappingSelectRenderItem } from './mappingActions/MappingSelectRenderItem'

interface Props {
  applyMapping: (restaurantUserGuid: string | null) => void
  setEditingMapping: (value: boolean) => void
  locationUuid: string | null
}

interface MappingSelectOption extends SelectOption {
  archived: boolean
  email: string
  mostRecentClockInDate: Date | null
  mappedTo: PayrollEmployeeMapping
}

export const EditMappingSection = ({
  applyMapping,
  setEditingMapping,
  locationUuid
}: Props) => {
  const { t } = useTranslation('employees')
  const [value, setValue] = useState<string | null>(null)
  const employee = useEmployee()
  const employeeUuid = useEmployeeId()
  const {
    lastName: viewedEmployeeLastName,
    emailAddress: viewedEmployeeEmailAddress
  } = employee.overview

  const { loading, data } = useGetPosMappingOptionsQuery({
    variables: { locationUuid: locationUuid || '', employeeUuid: employeeUuid }
  })

  if (loading) {
    return (
      <div className='w-full flex justify-center'>
        <MerryGoRound size={SIZE.xs} />
      </div>
    )
  }

  const mappingSelectOptions: MappingSelectOption[] = (
    data?.getPosMappingOptions || []
  )
    .map((option) => {
      const externalIdTag = option.externalEmployeeId
        ? `#${option.externalEmployeeId}`
        : ''

      return {
        label: `${option.userName.formatted} ${externalIdTag}`,
        value: option.restaurantUserGuid,
        disabled: option.mappedToEmployee != null,
        archived: option.archived,
        email: option.email,
        mostRecentClockInDate: option.mostRecentClockInDate,
        mappedTo: option.mappedToEmployee
      } as MappingSelectOption
    })
    .sort((a, b) =>
      sortMappingOptions(
        a,
        b,
        viewedEmployeeEmailAddress,
        viewedEmployeeLastName
      )
    )

  return (
    <>
      <div className='w-full'>
        <Select
          aria-label='mapping'
          enableSearch
          searchAnyPartOfString
          searchPlaceholder={t('searchByName')}
          onChange={setValue}
          value={value}
          options={mappingSelectOptions}
          renderItem={MappingSelectRenderItem}
          additionalActions={
            <div
              {...trackTransparentMapping('dropdown-ignore')}
              data-testid='ignore-mapping-option'
              className='cursor-pointer hover:bg-gray-0 py-2 -mx-3 -my-2'
              onClick={() => applyMapping(null)}
            >
              <div className='px-3'>
                {t('ignoreMapping')}
                <div className='type-caption text-gray-75'>
                  {t('ignoreMappingSubtext')}
                </div>
              </div>
            </div>
          }
          placeholder={t('selectAProfile')}
          testId='mapping-dropdown'
        />
      </div>
      <div className='flex'>
        <IconButton
          icon={<CloseIcon />}
          onClick={() => setEditingMapping(false)}
          testId={'mapping-dropdown-cancel'}
        />
        <IconButton
          {...trackTransparentMapping('dropdown-confirm')}
          testId={'mapping-dropdown-confirm'}
          disabled={value === null}
          icon={<DoneIcon />}
          onClick={() => applyMapping(value)}
        />
      </div>
    </>
  )
}

const sortMappingOptions = (
  a: MappingSelectOption,
  b: MappingSelectOption,
  viewedEmployeeEmailAddress: string | null,
  viewedEmployeeLastName: string
): number => {
  const aSuggested =
    a.label
      .toString()
      .toLowerCase()
      .includes(viewedEmployeeLastName.toLowerCase()) ||
    viewedEmployeeEmailAddress === a.email

  const bSuggested =
    b.label
      .toString()
      .toLowerCase()
      .includes(viewedEmployeeLastName.toLowerCase()) ||
    viewedEmployeeEmailAddress === b.email

  // 1. Suggested, unmapped POS
  // 2. Suggested, mapped POS
  // 3. Everyone else (alphabetic)

  // suggested users always take precedence
  if (aSuggested > bSuggested) {
    return -1
  } else if (aSuggested < bSuggested) {
    return 1
  }

  // if both are suggested, prefer unmapped ones
  if (aSuggested === true && bSuggested === true) {
    if (a.mappedTo !== null && b.mappedTo === null) {
      return 1
    } else if (a.mappedTo === null && b.mappedTo !== null) {
      return -1
    }
  }

  // otherwise, alphabetically sort by label
  if (a.label > b.label) {
    return 1
  } else if (a.label < b.label) {
    return -1
  }
  return 0
}
