import * as React from 'react'

import { create, StoreApi } from 'zustand'
import createContext from 'zustand/context'

const { Provider, useStore } = createContext<StoreApi<Store>>()
interface Store {
  modal?: string
  context?: any
  _open: (key: string, context?: any) => void
  close: () => void
}

const createStore = () => {
  return create<Store>((set) => ({
    modal: undefined,
    context: undefined,
    _open: (key: string, context?: any) => set({ modal: key, context }),
    close: () => set({ modal: undefined, context: undefined })
  }))
}

const ModalProvider: React.FunctionComponent<React.PropsWithChildren> = ({
  children
}) => <Provider createStore={() => createStore()}>{children}</Provider>
function useModalContext<TContext>(defaultValue: TContext) {
  return useStore((state) => (state.context as TContext) ?? defaultValue)
}

function useOpenModal<TContext>(key: string) {
  const open = useStore((state) => state._open)

  return (context: TContext | undefined = undefined) => {
    open(key, context)
  }
}

function useIsModalOpen(key: string) {
  return useStore((state) => state.modal === key)
}
function useCloseModal() {
  return useStore((state) => state.close)
}

function ModalHooksFor<TContext = {}>(key: string) {
  return {
    useIsModalOpen: () => useIsModalOpen(key),
    useOpenModal: () => useOpenModal<TContext>(key),
    useCloseModal,
    useModalContext: (defaultState: TContext) =>
      useModalContext<TContext>(defaultState),
    /**
     * Auto close will force the modal closed when this components is unmounted
     */
    useAutoClose: () => {
      const isOpen = useIsModalOpen(key)
      const close = useCloseModal()
      React.useEffect(() => {
        return () => {
          if (isOpen) {
            close()
          }
        }
      }, [close, isOpen])
    }
  }
}
function useHasOpenModal() {
  return useStore((state) => state.modal !== undefined)
}

export { ModalHooksFor, ModalProvider, useHasOpenModal }
