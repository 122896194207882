import { useQuery } from 'react-query'
import { toCurrency } from '@toasttab/buffet-utils'

import { SpaDate } from '@local/shared-services'

import { useEcWebClient } from '@local/api/ApolloClientsProvider'
import {
  LatestEmployeePayHistoryDocument,
  LatestEmployeePayHistoryQuery,
  LatestEmployeePayHistoryQueryVariables
} from '@local/generated/graphql'
import { useEmployeeId } from '../../hooks'

const MapLatest = (result: LatestEmployeePayHistoryQuery) => {
  const latest = result.PayHistory.latest

  if (latest === null || latest === undefined) {
    return undefined
  }

  const checkDate = SpaDate.fromString(latest.checkDate)

  return {
    formattedCheckDate: checkDate.toFormat('MMM d'),
    formattedGross: toCurrency(latest.gross),
    formattedNet: toCurrency(latest.net),
    viewPaystubUrl: latest.payStubs[0].viewPayStubUrl,
    isFuturePayDate: checkDate.isGreater(SpaDate.fromToday())
  }
}

export const useLatestEmployeePayHistory = () => {
  const employeeId = useEmployeeId()
  const client = useEcWebClient()
  return useQuery(['latest-pay-history', employeeId], () =>
    client
      .query<
        LatestEmployeePayHistoryQuery,
        LatestEmployeePayHistoryQueryVariables
      >({
        query: LatestEmployeePayHistoryDocument,
        variables: {
          employeeUuid: employeeId
        }
      })
      .then((query) => query.data)
      .then(MapLatest)
  )
}
