import { usePagination as useBuffetPagination } from '@toasttab/buffet-pui-pagination'
import { Paged } from '@local/generated/graphql'

/**
 * This is a type to mimic the query pattern:
 * >> first: $first, offset: $offset
 * that we use for paging queries via graphql
 */
type Paging = {
  first: number
  offset: number
}

export type PagedStore = {
  paging: Paging
  applyPaging(paging: Paging): void
}

export function CreatePagedStore<TStore extends PagedStore>(
  set: {
    (
      partial:
        | PagedStore
        | Partial<PagedStore>
        | ((state: PagedStore) => PagedStore | Partial<PagedStore>),
      replace?: boolean | undefined
    ): void
  },
  defaults = {
    first: 25,
    offset: 1
  }
): PagedStore {
  return {
    paging: defaults,
    applyPaging: (paging: Paging) => set({ paging })
  }
}

export const usePagination = (useStore: any, paged?: Paged) => {
  if (paged === undefined) {
    paged = {
      total: 0,
      pageIndex: 0,
      pageCount: 1
    }
  }

  const applyPaging = useStore((store: PagedStore) => store.applyPaging)
  const first = useStore((store: PagedStore) => store.paging.first)
  const pagination = useBuffetPagination({
    visiblePages: 5,
    currentPage: paged.pageIndex - 1, //TODO(mlee): this appears to be a bug in the indexing of pageIndex being 1 when it's on the first page
    pageData: new Array(paged.pageCount).map((_, index) => `Page ${index}`),
    shouldWrap: false,
    onChangePage: (pageNumber) =>
      applyPaging({
        first,
        offset: pageNumber + 1
      })
  })

  return {
    showPagination:
      pagination.totalPageCount === undefined
        ? false
        : pagination.totalPageCount > 1,
    ...pagination
  }
}
