import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ChangeSalaryAllocationsMutation,
  SalaryAllocationForPayChangeFragment,
  useChangeSalaryAllocationsMutation,
  useSalaryForPayChangeQuery
} from '@local/generated/graphql'
import { PayChangeSchema } from './domain'
import { useEmployeeId } from '../../../../hooks'
import { PayChangeModal } from './PayChangeModal'
import { usePayChangeModalStore } from './PayChangeModalStore'
import { useJobAssignmentsStore } from '../JobAssignmentsStore'
import { SalaryAllocationsModal, SalaryRetroPayPanel } from './components'
import { format } from '@toasttab/buffet-pui-date-utilities'

type SalaryChangeModalProps = {
  onClose: VoidFunction
  onSuccess: VoidFunction
}

export const SalaryChangeModal: React.FunctionComponent<
  SalaryChangeModalProps
> = ({ onClose, onSuccess }) => {
  const { t } = useTranslation('employees')

  const employeeId = useEmployeeId()
  const { data, loading } = useSalaryForPayChangeQuery({
    variables: { employeeId }
  })

  const employee = data?.findEmployeeById
  const salary = employee?.salary
  const salaryAllocations = salary?.salaryAllocations || []
  const salaryJobsCount = salaryAllocations.length

  const salaryAllocation = getSalaryAllocation(salaryAllocations)

  const subtitle = useSalarySubtitle(salaryJobsCount)
  const title =
    salaryJobsCount === 1 && salaryAllocation
      ? salaryAllocation.jobAssignment.name
      : t('salaryUpdate', 'Salary update')
  const rateLabel = t('annualSalary', 'Annual salary')
  const rate = salary?.activeSalaryRateOfPay?.amount
  const currency = salary?.activeSalaryRateOfPay?.currency
  const showSalaryAllocationsModal =
    useShowSalaryAllocationsModal(salaryAllocations)

  const [changeSalary, { loading: saving }] =
    useChangeSalaryAllocationsMutation()

  const setIsHourly = usePayChangeModalStore((x) => x.setIsHourly)
  React.useEffect(() => {
    setIsHourly(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSaveChange = (
    values: PayChangeSchema,
    onCompleted: VoidFunction,
    onError: VoidFunction
  ) => {
    onSaveSalaryChange(
      changeSalary,
      employeeId,
      salaryAllocation?.id,
      onCompleted,
      onError,
      values
    )
  }

  return loading ? null : salary?.activeSalaryRateOfPay &&
    showSalaryAllocationsModal ? (
    <SalaryAllocationsModal
      testId={'salary-allocations-modal'}
      title={title}
      subtitle={subtitle}
      salary={salary.activeSalaryRateOfPay}
      salaryAllocations={salaryAllocations}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  ) : !!salary ? (
    <PayChangeModal
      testId={'salary-change-modal'}
      currency={currency}
      employmentStatus={employee?.employment?.employmentStatus.__typename}
      employmentTaxType={salary?.employmentTaxType}
      jobAssignmentId={salaryAllocation?.jobAssignment?.id}
      onClose={onClose}
      onSavePayChange={onSaveChange}
      onSuccess={onSuccess}
      payGroupId={salaryAllocation?.jobAssignment?.payGroupId}
      rate={rate}
      rateLabel={rateLabel}
      RetroPayPanel={SalaryRetroPayPanel}
      saving={saving}
      subtitle={subtitle}
      title={title}
    />
  ) : null
}

const getSalaryAllocation = (
  salaryAllocations: SalaryAllocationForPayChangeFragment[]
): SalaryAllocationForPayChangeFragment | undefined => {
  if (salaryAllocations.length === 1) {
    return salaryAllocations[0]
  }
  const filteredSalaryAllocations = salaryAllocations.filter(
    (salaryAllocation) => salaryAllocation.rate.amount > 0
  )
  if (filteredSalaryAllocations.length === 1) {
    return filteredSalaryAllocations[0]
  }
  if (filteredSalaryAllocations.length === 0) {
    return salaryAllocations.find(
      (salaryAllocation) => salaryAllocation.jobAssignment.isPrimary
    )
  }
  return undefined
}

const useSalarySubtitle = (salaryJobsCount: number): string => {
  const { t } = useTranslation('employees')
  const jobAssignments = useJobAssignmentsStore((x) => x.jobAssignments)
  const employeeFirstName = useJobAssignmentsStore((x) => x.employeeFirstName)

  const hourlyJobCount = jobAssignments.length - salaryJobsCount

  return salaryJobsCount === 1 && hourlyJobCount === 0
    ? t(
        'salaryChange',
        { employeeFirstName: employeeFirstName },
        `Applies to ${employeeFirstName}'s annual salary.`
      )
    : salaryJobsCount > 1 && hourlyJobCount === 0
    ? t(
        'salaryChangeAppliesToMultipleJobs',
        { employeeFirstName: employeeFirstName },
        `Applies to ${employeeFirstName}'s annual salary assigned to multiple jobs.`
      )
    : salaryJobsCount === 1
    ? t(
        'salaryChangeWithHourlyJobs',
        { employeeFirstName: employeeFirstName },
        `Applies to ${employeeFirstName}'s annual salary. Hourly pay can be updated in the jobs & pay section.`
      )
    : t(
        'salaryChangeAppliesToMultipleJobsWithHourlyJobs',
        { employeeFirstName: employeeFirstName },
        `Applies to ${employeeFirstName}'s annual salary assigned to multiple jobs. Hourly pay can be updated in the jobs & pay section.`
      )
}

export const onSaveSalaryChange = (
  changeSalary: any,
  employeeId: string,
  id: string | undefined,
  onCompleted: VoidFunction,
  onError: VoidFunction,
  values: PayChangeSchema
) => {
  changeSalary({
    variables: {
      employeeId: employeeId,
      changeSalaryAllocationInputs: [
        {
          id: id,
          newRate: {
            amount: values.rate.toString(),
            currency: values.currency
          },
          effectiveDate: format(values.effectiveDate, 'yyyy-MM-dd')
        }
      ]
    },
    onCompleted: (mutation: ChangeSalaryAllocationsMutation) => {
      if (mutation.changeSalaryAllocations?.__typename === 'Salary') {
        onCompleted()
      } else {
        onError()
      }
    },
    onError: onError
  })
}

const useShowSalaryAllocationsModal = (
  salaryAllocations: SalaryAllocationForPayChangeFragment[]
): boolean => {
  if (salaryAllocations && salaryAllocations.length > 1) {
    return (
      salaryAllocations.filter(
        (salaryAllocation) => salaryAllocation.rate.amount > 0
      ).length > 1
    )
  }
  return false
}
