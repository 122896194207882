import {
  PayGroupCalendarResult,
  PayPeriodCalendar,
  PayrollStatusV2
} from '@local/generated/graphql'
import {
  addDays,
  differenceInDays,
  format,
  Formats
} from '@toasttab/buffet-pui-date-utilities'
import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import { TFunction } from 'i18next'
import { isToday } from 'date-fns'

export const normalizeAndSortPayPeriods = (
  payPeriodCalendar?: PayGroupCalendarResult
): PayPeriodCalendar[] | undefined => {
  if (
    !(payPeriodCalendar?.__typename === 'PayGroupCalendar') ||
    !payPeriodCalendar?.payPeriods
  )
    return undefined

  return payPeriodCalendar?.payPeriods
    .filter((payPeriod) => !payPeriod.isArchived)
    .map((payPeriod) => {
      return {
        ...payPeriod,
        startDate: new Date(payPeriod.startDate.replace('-', '/')),
        checkDate: new Date(payPeriod.checkDate.replace('-', '/'))
      }
    })
    .sort(
      (payPeriod1, payPeriod2) =>
        payPeriod1.startDate.getTime() - payPeriod2.startDate.getTime()
    )
}

export const parseSortedPayPeriods = (
  payPeriods: PayPeriodCalendar[] | undefined
): { payPeriodStartDates: Date[]; paycheckDates: Date[] } => {
  const payPeriodStartDates: Date[] = []
  const paycheckDates: Date[] = []
  payPeriods?.forEach((payPeriod: PayPeriodCalendar) => {
    payPeriodStartDates.push(payPeriod.startDate)
    paycheckDates.push(payPeriod.checkDate)
  })
  return {
    payPeriodStartDates,
    paycheckDates
  }
}

export const getLastValidChangePayDate = (
  sortedPayPeriodStartDates: Date[]
): Date | undefined => {
  const lastPayPeriodStart = sortedPayPeriodStartDates.at(-1)
  if (lastPayPeriodStart) {
    return addDays(lastPayPeriodStart, -1)
  }
}

export const getNextPaycheckDate = (
  effectiveDate: Date,
  sortedPayPeriods?: PayPeriodCalendar[]
): Date | undefined => {
  const today = new Date()
  const comparisonDate =
    differenceInDays(effectiveDate, today) < 0 ? today : effectiveDate

  const futureSortedPayPeriods = sortedPayPeriods?.filter(
    (payPeriod) =>
      differenceInDays(payPeriod.checkDate, comparisonDate) >= 0 &&
      payPeriod.status === PayrollStatusV2.CREATED
  )

  const payPeriod = futureSortedPayPeriods?.find(
    (payPeriod) => differenceInDays(payPeriod.startDate, comparisonDate) <= 0
  )

  return (!!payPeriod ? payPeriod : futureSortedPayPeriods?.at(-1))?.checkDate
}

export const getFormattedPaycheckDate = (
  paycheckDate: Date | undefined,
  language: string
): string | null | undefined => {
  return !!paycheckDate
    ? format(paycheckDate, 'date_medium', language as Locale)
    : undefined
}

export const formatDateInputValue = (
  t: TFunction,
  language: string,
  date?: Date
): string => {
  const formattedDate = !!date
    ? format(date, Formats.date.long, language as Locale)
    : ''
  return (
    (date &&
      (isToday(date)
        ? t('todayWithDate', { formattedDate: formattedDate })
        : formattedDate)) ||
    ''
  )
}
