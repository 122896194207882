import * as React from 'react'
import { trackEmploymentOverview } from '@local/track'
import { useApi } from '../../../../../ApiProvider'
import { useNavigateTo } from '../../../../../navigation/hooks'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useTranslation } from 'react-i18next'

export const PayChangeHistoryLink: React.FunctionComponent = () => {
  const { t } = useTranslation('employees')

  const api = useApi()
  const navigateTo = useNavigateTo()

  const payChangeHistoryUrl = async () => {
    const esxWebGenericResponse = await api.getPayChangeHistoryUrl()
    if (esxWebGenericResponse?.url) {
      navigateTo(esxWebGenericResponse.url, true)
    }
  }

  return (
    <Button
      {...trackEmploymentOverview('pay-change-history-view')}
      testId='view-pay-change-history-link'
      onClick={payChangeHistoryUrl}
      as='a'
      variant='link'
    >
      {t('viewPayChangeHistory')}
    </Button>
  )
}
