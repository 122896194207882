import React, { useEffect, useState } from 'react'
import { getIn, useFormik } from 'formik'
import { TextInputFormField } from './TextInputFormField'
import { Button } from '@toasttab/buffet-pui-buttons'
import { MultiSelectFormField } from './MultiSelectFormField'
import { EmployeePermissions } from '../../../domain'
import { FormContainer } from './FormContainer'
import { NumberInput } from '@toasttab/buffet-pui-text-input'
import { SelectFormField } from './SelectFormField'
import { getDateAsDigits } from '../../../../shared/formHelpers'
import { Basic } from '../domain'
import {
  useUpdatePersonalProfile,
  usePersonalProfile,
  useNavigateToProfilePage
} from '../hooks'
import { useUpdateOverview, useEmployee } from '../../../hooks'
import { useApi } from '../../../../ApiProvider'
import { useEvent } from '@local/hooks'
import { SsnInput } from './SsnInput'

export const BasicForm = () => {
  const handleBackToProfilePage = useEvent(useNavigateToProfilePage())
  const [error, setError] = useState('')
  const personalProfile = usePersonalProfile()
  const employee = useEmployee()
  const updatePersonalProfile = useUpdatePersonalProfile()
  const updateOverview = useUpdateOverview()
  const [basicToSave, setBasicToSave] = useState<Basic | null>(null)
  const [initialSsn, setInitialSsn] = useState('')
  const basic = personalProfile?.basic
  const links = personalProfile?.links
  const options = personalProfile?.options
  const api = useApi()

  const permissions = employee?.permissions ?? EmployeePermissions.NONE

  const { values, setFieldValue, errors, handleSubmit } = useFormik({
    initialValues: basic || ({} as Basic),
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Basic.formSchema,
    onSubmit: (basic) => {
      setBasicToSave(
        Basic.copyOf({
          ...basic,
          socialSecurityNumber:
            basic.socialSecurityNumber === initialSsn
              ? '•••-••-••••'
              : basic.socialSecurityNumber
        })
      )
    },
    enableReinitialize: true
  })

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      window.scrollTo(0, 0)
      setError('Please fix the errors below')
    }
  }, [errors])

  useEffect(() => {
    const sendBasicInfo = async () => {
      if (basicToSave && links) {
        setError('')
        api
          .saveBasic(links.basic, basicToSave)
          .then(() => {
            updatePersonalProfile({ basic: basicToSave })
            updateOverview({
              firstName: basicToSave.firstName,
              lastName: basicToSave.lastName,
              chosenName: basicToSave.chosenName
            })
            handleBackToProfilePage()
          })
          .catch((e) => {
            if (e.message.includes('409')) {
              window.scrollTo(0, 0)
              setError(
                "There's an issue completing your profile information. Please reach out to your HR Manager for assistance."
              )
            } else {
              console.error(e)
              setError('Could not save basic info')
            }
            setBasicToSave(null)
          })
      }
    }

    sendBasicInfo()
  }, [
    api,
    basicToSave,
    handleBackToProfilePage,
    links,
    updateOverview,
    updatePersonalProfile
  ])

  if (options) {
    return (
      <FormContainer headerText='Edit Basic Information' error={error}>
        <TextInputFormField
          label='First name'
          value={values.firstName}
          onChange={(value) => setFieldValue('firstName', value)}
          errorMessage={errors.firstName}
          testId='first-name'
        />
        <TextInputFormField
          label='Middle name'
          value={values.middleName || ''}
          onChange={(value) => setFieldValue('middleName', value)}
          errorMessage={errors.middleName}
          testId='middle-name'
        />
        <TextInputFormField
          label='Last name'
          value={values.lastName}
          onChange={(value) => setFieldValue('lastName', value)}
          errorMessage={errors.lastName}
          testId='last-name'
        />
        <TextInputFormField
          label='Chosen name'
          value={values.chosenName}
          onChange={(value) => setFieldValue('chosenName', value)}
          errorMessage={errors.chosenName}
          testId='chosen-name'
        />
        <NumberInput
          label='Date of birth'
          value={getDateAsDigits(values.dateOfBirth)}
          onChange={(event: any) =>
            setFieldValue('dateOfBirth', event.formattedValue)
          }
          format='##/##/####'
          allowEmptyFormatting
          mask=' '
          name='dob'
          testId='input-dob'
          errorText={errors.dateOfBirth}
          invalid={!!errors.dateOfBirth}
          className='tabular-nums'
          containerClassName='mb-6 w-full lg:w-1/2 xl:w-1/3'
          helperText='MM/DD/YYYY'
        />
        {permissions.canEditSsn && permissions.canViewSsn && (
          <div className='mb-6'>
            <SsnInput
              value={values.socialSecurityNumber}
              error={errors.socialSecurityNumber}
              url={personalProfile.links.socialSecurity}
              onChange={(e: any) =>
                setFieldValue('socialSecurityNumber', e.formattedValue)
              }
              setInitialSsn={(ssn: string) => setInitialSsn(ssn)}
              hasSsnToken={values.ssnToken !== ''}
            />
          </div>
        )}
        <SelectFormField
          label='Gender'
          options={options.gender}
          selectedVal={values.gender}
          onChange={(value) => setFieldValue('gender', value)}
          id='gender'
        />
        <SelectFormField
          label='Marital Status'
          options={options.maritalStatus}
          selectedVal={values.maritalStatus}
          onChange={(value) => setFieldValue('maritalStatus', value)}
          id='marital-status'
        />
        <SelectFormField
          label='Ethnicity'
          options={options.ethnicity}
          selectedVal={values.ethnicity}
          onChange={(value) => setFieldValue('ethnicity', value)}
          id='ethnicity'
        />
        <SelectFormField
          label='Disability'
          options={options.disability}
          selectedVal={values.disability}
          onChange={(value) => setFieldValue('disability', value)}
          id='disability'
        />
        <MultiSelectFormField
          options={options.veteranStatus}
          initialSelections={values.veteranStatus}
          multiSelect={options.veteranStatus.length > 2}
          onChange={(values) => setFieldValue('veteranStatus', values)}
          label='Veteran Status'
          testId='veteran-status'
        />
        <TextInputFormField
          label="Driver's license number"
          value={
            values.driversLicense?.number
              ? values.driversLicense.number
              : undefined
          }
          onChange={(value) => setFieldValue('driversLicense.number', value)}
          errorMessage={getIn(errors, 'driversLicense.number')}
          testId='license-num'
        />
        <SelectFormField
          label="Driver's license state"
          options={options.statesInUnitedStates}
          selectedVal={values.driversLicense?.state}
          onChange={(value) => setFieldValue('driversLicense.state', value)}
          errorText={getIn(errors, 'driversLicense.state')}
          id='license-state'
        />
        <NumberInput
          label="Driver's license expiration date"
          value={getDateAsDigits(values.driversLicense?.expirationDate)}
          onChange={(event: any) =>
            setFieldValue('driversLicense.expirationDate', event.formattedValue)
          }
          format='##/##/####'
          allowEmptyFormatting
          mask=' '
          name='license-expiration'
          testId='input-license-expiration'
          errorText={getIn(errors, 'driversLicense.expirationDate')}
          invalid={!!getIn(errors, 'driversLicense.expirationDate')}
          className='tabular-nums'
          containerClassName='mb-6 w-full lg:w-1/2 xl:w-1/3'
          helperText='MM/DD/YYYY'
        />
        <div className='flex justify-between'>
          <Button
            onClick={handleBackToProfilePage}
            variant='secondary'
            testId='cancel-btn'
          >
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()} testId='save-btn'>
            Save changes
          </Button>
        </div>
      </FormContainer>
    )
  } else {
    return null
  }
}
