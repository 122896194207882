import * as React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button } from '@toasttab/buffet-pui-buttons'
import { FormContainer } from './components'
import { useHireTechUrlQuery, useNavigateToProfilePage } from './hooks'

export const WorkOpportunityTaxCredit = () => {
  const navigateToProfilePage = useNavigateToProfilePage(true)

  const { isError, isLoading, hireTechUrl } = useHireTechUrlQuery()

  React.useEffect(() => {
    if (!isLoading && !hireTechUrl) {
      navigateToProfilePage()
    }
  }, [isLoading, hireTechUrl])

  return (
    <div>
      <FormContainer headerText='Work Opportunity Tax Credit'>
        <div className='mb-5'>
          Please click the secure link below and answer each survey question.
          E-sign once complete and hold on to your Reference ID.
        </div>
        <div className='mb-10' data-testid='hiretech-url'>
          {hireTechUrl ? (
            <a
              href={hireTechUrl}
              className='cursor-pointer'
              target='_blank'
              rel='noopener noreferrer'
            >
              https://newhire.hiretech.com
            </a>
          ) : isError ? (
            <Alert variant='error' className='w-full'>
              Server error
            </Alert>
          ) : (
            <Skeleton />
          )}
        </div>
        <div className='flex'>
          <div className='flex-1'>
            <Button onClick={navigateToProfilePage} data-testid='wotc-done'>
              Done
            </Button>
          </div>
          <Button onClick={navigateToProfilePage} variant='link'>
            Skip
          </Button>
        </div>
      </FormContainer>
    </div>
  )
}
