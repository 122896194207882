import * as React from 'react'
import { ComponentType } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import { Modal } from '@toasttab/buffet-pui-modal'
import {
  AttachEarningsMutation,
  Currency,
  EmploymentTaxType,
  useAttachEarningsMutation
} from '@local/generated/graphql'
import {
  AttachEarningsModalContents,
  CloseConfirmationModalContents,
  PayChangeModalContents,
  submitEarnings,
  useAttachEarningsSnackBar,
  usePayChangeSnackBar
} from './components'
import { PayChangeSchema, usePayChangeSchema } from './domain'
import { useEmployeeId } from '../../../../hooks'
import { usePayChangeModalStore } from './PayChangeModalStore'
import { useTracking } from '@local/track'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export type PayChangeRetroPayPanelProps = {
  employeeId: string
  jobAssignmentId?: string
} & TestIdentifiable

type PayChangeModalProps = {
  currency?: Currency
  employmentStatus?: string
  employmentTaxType?: EmploymentTaxType | null
  jobAssignmentId?: string
  onClose: VoidFunction
  onSavePayChange: (
    values: PayChangeSchema,
    onCompleted: VoidFunction,
    onError: VoidFunction
  ) => void
  onSuccess: VoidFunction
  payGroupId?: string | null
  rate: number
  rateLabel: string
  RetroPayPanel: ComponentType<PayChangeRetroPayPanelProps>
  saving: boolean
  subtitle: string
  title: string
} & TestIdentifiable

export const PayChangeModal: React.FunctionComponent<PayChangeModalProps> = ({
  currency = Currency.USD,
  employmentTaxType,
  rate,
  onClose,
  onSavePayChange,
  onSuccess,
  saving,
  subtitle,
  title,
  testId,
  ...rest
}) => {
  const [showAttachError, setShowAttachError] = React.useState<boolean>(false)

  const {
    showSuccessSnackBar: showPayChangeSuccessSnackbar,
    showErrorSnackBar: showPayChangeErrorSnackbar
  } = usePayChangeSnackBar()

  const onPayChangeOnlyCompleted = () => {
    showPayChangeSuccessSnackbar()
    onClose()
    onSuccess()
  }

  const onPayChangeError = () => {
    showPayChangeErrorSnackbar()
    onClose()
  }

  const { showSuccessSnackBar: showAttachSuccessSnackbar } =
    useAttachEarningsSnackBar()

  const onPayChangeComplete = () => {
    submitEarnings(
      saveAdditionalEarnings,
      employeeId,
      rest.jobAssignmentId,
      onAttachError,
      onAttachCompleted,
      values.earnings!
    )
  }

  const onAttachError = () => {
    setShowAttachError(true)
  }

  const [saveAdditionalEarnings, { loading: savingAdditionalEarnings }] =
    useAttachEarningsMutation()
  const employeeId = useEmployeeId()

  const onSubmit = (values: PayChangeSchema) => {
    if (values.currentFormId === 'pay-change-form') {
      onSavePayChange(values, onPayChangeOnlyCompleted, onPayChangeError)
    } else if (values.currentFormId === 'attach-earnings-form') {
      onSavePayChange(values, onPayChangeComplete, onPayChangeError)
    } else if (values.currentFormId === 'cancellation-confirmation') {
      setFieldValue('currentFormId', 'attach-earnings-form')
    }
  }

  const onAttachCompleted = (mutation: AttachEarningsMutation) => {
    if (mutation.saveAdditionalEarnings?.__typename === 'Paycheck') {
      showAttachSuccessSnackbar()
      onClose()
      onSuccess()
    } else {
      onAttachError()
    }
  }

  const payChangeSchema = usePayChangeSchema()
  const formik = useFormik<PayChangeSchema>({
    initialValues: {
      currentFormId: 'pay-change-form',
      rate: rate,
      rateConfirmationRequired: false,
      rateConfirmed: false,
      currency: currency || Currency.USD,
      effectiveDate: new Date()
    },
    onSubmit: onSubmit,
    validationSchema: payChangeSchema
  })

  const { handleSubmit, setFieldValue, values } = formik

  const { track: trackingHook } = useTracking()
  const onRequestClose =
    values.currentFormId === 'pay-change-form'
      ? () => {
          trackingHook('pay-change-modal.close')
          onClose()
        }
      : values.currentFormId === 'attach-earnings-form' && !showAttachError
      ? () => {
          trackingHook('attach-earnings-modal.request-close')
          setFieldValue('currentFormId', 'cancellation-confirmation')
        }
      : undefined

  const reset = usePayChangeModalStore((x) => x.reset)
  React.useEffect(() => {
    return reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (values.currentFormId === 'pay-change-form') {
      setShowAttachError(false)
    }
  }, [values.currentFormId])

  const setSaving = usePayChangeModalStore((x) => x.setSaving)
  React.useEffect(() => {
    setSaving(saving || savingAdditionalEarnings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving, savingAdditionalEarnings])

  return (
    <FormikProvider value={formik}>
      <Form id='pay-change-form' data-testid={testId} onSubmit={handleSubmit}>
        <Modal
          isOpen
          onRequestClose={onRequestClose}
          position='pin-right'
          shouldCloseOnOverlayClick={false}
          size='sm'
        >
          {values.currentFormId === 'pay-change-form' ? (
            <PayChangeModalContents
              title={title}
              subtitle={subtitle}
              {...rest}
            />
          ) : values.currentFormId === 'attach-earnings-form' ? (
            <AttachEarningsModalContents
              title={title}
              subtitle={subtitle}
              employmentTaxType={employmentTaxType}
              showAttachError={showAttachError}
              onClose={onClose}
              onPayChangeSuccess={onSuccess}
            />
          ) : values.currentFormId === 'cancellation-confirmation' ? (
            <CloseConfirmationModalContents onClose={onClose} />
          ) : null}
        </Modal>
      </Form>
    </FormikProvider>
  )
}
