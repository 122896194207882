import * as React from 'react'
import { Badge, Colors } from '@toasttab/buffet-pui-badge'
import { useTranslation } from 'react-i18next'
import { MappingCondition } from '@local/generated/graphql'

interface Props {
  testId?: string
  status: MappingCondition
  isProfileMapped?: boolean
  forEntity: 'job' | 'employee'
}

const badgeColorMap: Record<string, keyof typeof Colors> = {
  [MappingCondition.MAPPED_USER_MAPPING_IGNORED]: Badge.Color.neutral,
  [MappingCondition.MAPPED_USER_MAPPED]: Badge.Color.success
}

const jobBadgeColorMap: Record<string, keyof typeof Colors> = {
  ...badgeColorMap,
  [MappingCondition.MAPPED_USER_NOT_MAPPED]: Badge.Color.neutral
}

const mappingStatusToDictionaryKey: Record<string, string> = {
  [MappingCondition.NOT_MAPPED]: 'notMapped',
  [MappingCondition.MAPPED_INVALID_GUID]: 'mappingError',
  [MappingCondition.MAPPED_NO_POS_COUNTERPART]: 'mappingError',
  [MappingCondition.MAPPED_USER_NOT_MAPPED]: 'notMapped',
  [MappingCondition.MAPPED_USER_MAPPING_IGNORED]: 'mappingIgnored',
  [MappingCondition.MAPPED_USER_MAPPED]: 'userMapped',
  [MappingCondition.POS_ONLY]: 'notMapped'
}

const jobMappingStatusToDictionaryKey: Record<string, string> = {
  ...mappingStatusToDictionaryKey,
  [MappingCondition.MAPPED_USER_NOT_MAPPED]: 'pendingSync'
}

export const MappingStatus: React.FunctionComponent<Props> = ({
  testId,
  status,
  isProfileMapped,
  forEntity
}) => {
  const { t } = useTranslation('employees')
  const statusDictionaryKey =
    forEntity === 'job' && isProfileMapped === true
      ? jobMappingStatusToDictionaryKey[status]
      : mappingStatusToDictionaryKey[status]

  const color =
    (forEntity === 'job' && isProfileMapped === true
      ? jobBadgeColorMap[status]
      : badgeColorMap[status]) || Badge.Color.error

  return (
    <Badge testId={testId} color={color}>
      {t(statusDictionaryKey)}
    </Badge>
  )
}
