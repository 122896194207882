import * as React from 'react'
import Skeleton from 'react-loading-skeleton'

export interface AsyncFormFieldContainerProps {
  isLoading: boolean
  containerClassName?: string
}

/**
 * Replace a form field and its label with two skeletons when data is
 * being requested from the server. A class name applied to the form
 * field component should be applied to this component as well. A skeleton will
 * represent the label (128 x 24) and another will represent the input (320 x 40).
 */
export const AsyncFormFieldContainer: React.FunctionComponent<
  React.PropsWithChildren<AsyncFormFieldContainerProps>
> = ({ isLoading, containerClassName, children }) => {
  if (isLoading) {
    return (
      <div className={containerClassName || 'mb-6 w-full lg:w-1/2 xl:w-1/3'}>
        <Skeleton height={24} width={128} />
        <Skeleton height={40} width={320} />
      </div>
    )
  } else {
    return <>{children}</>
  }
}
