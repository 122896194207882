import * as React from 'react'
import {
  Select,
  SelectProps,
  SelectMultiple,
  SelectMultipleProps
} from '@toasttab/buffet-pui-select'
import {
  getSubLabelForOptionalField,
  getSubLabelForRequiredField
} from '../shared/subLabelHelpers'

export interface SelectFormFieldProps
  extends Omit<SelectProps, 'onChange' | 'value'> {
  label: string
  onChange: SelectMultipleProps['onChange']
  optional?: boolean
  errorMessage?: string
  testId?: string
  multiSelect?: boolean
  initialSelections: Array<string>
}

export const MultiSelectFormField = ({
  options,
  initialSelections,
  multiSelect,
  onChange,
  label,
  optional = false,
  errorMessage = '',
  testId
}: SelectFormFieldProps) => (
  <div className='w-full mb-6 lg:w-1/2 xl:w-1/3' data-testid={testId}>
    <label className='block mb-1 font-semibold text-default type-default'>
      {label}
    </label>
    {multiSelect ? (
      <SelectMultiple
        aria-label={label}
        options={options}
        value={initialSelections}
        maxChips={3}
        testId={`select-multiple-ec-${testId}`}
        onChange={onChange}
      />
    ) : (
      <Select
        aria-label={label}
        options={options}
        testId={`select-regular-ec-${testId}`}
        onChange={(value) => onChange([value])}
        value={initialSelections.length > 0 ? initialSelections[0] : undefined}
      />
    )}

    {optional
      ? getSubLabelForOptionalField(errorMessage)
      : getSubLabelForRequiredField(errorMessage)}
  </div>
)
