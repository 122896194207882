import * as React from 'react'
import { ModalHooksFor } from '@local/modal-provider'
import { Button } from '@toasttab/buffet-pui-buttons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Modal, ModalBody, ModalFooter } from '@toasttab/buffet-pui-modal'

const { useOpenModal, useIsModalOpen, useCloseModal, useModalContext } =
  ModalHooksFor<string>('employee-paystub')

const PayStubModal = () => {
  const isOpen = useIsModalOpen()

  if (isOpen) {
    return <PayStubIFrame />
  } else {
    return null
  }
}

const PayStubIFrame = () => {
  const url = useModalContext('')
  const close = useCloseModal()
  const [isLoaded, setIsLoaded] = React.useState(false)
  const ref = React.useRef<HTMLIFrameElement>(null)

  React.useEffect(() => {
    if (!isLoaded) {
      const frame = ref.current?.contentDocument

      if (frame) {
        frame.styleSheets[0].insertRule(
          'body { box-sizing: border-box; margin: 0; }'
        )
        frame.styleSheets[0].insertRule('.small { width: 100%!important; }')
        frame.styleSheets[0].insertRule(
          'th, td { word-wrap: anywhere; min-width: 40px }'
        )
      }
    }
  }, [isLoaded])

  return (
    <Modal isOpen={true} onRequestClose={close}>
      <ModalBody>
        {!isLoaded && (
          <div
            //TODO: inset doesn't work?
            className='fixed inset-2/4 -translate-x-2/4 -translate-y-2/4'
            style={{
              top: '50%',
              right: '50%',
              bottom: '50%',
              left: '50%'
            }}
          >
            <MerryGoRound />
          </div>
        )}
        <div className='h-full -mx-6 max-w-screen'>
          <iframe
            ref={ref}
            className='block w-full h-full'
            onLoad={() => setIsLoaded(true)}
            title='Paystub'
            src={url}
          />
        </div>
      </ModalBody>

      <div className='border-t'>
        <ModalFooter>
          <Button onClick={() => close()}>Close</Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export { useOpenModal as useOpenPaystubModal, PayStubModal }
