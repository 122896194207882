import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

export type EarningCodeSchema = {
  isHourly: boolean
  label: string
  value: string
}

export type EarningSchema = {
  earningCode: EarningCodeSchema
  lumpSumAmount: number
  hourlyRate: number
  hoursWorked: number
  currency: string
}

const useEarningCodeSchema = () => {
  const { t } = useTranslation('employees')
  return yup
    .object({
      isHourly: yup.boolean(),
      label: yup.string(),
      value: yup.string()
    })
    .default(undefined)
    .required(t('required'))
}

const useEarningSchema = () => {
  const { t } = useTranslation('employees')
  const earningCodeSchema = useEarningCodeSchema()

  return yup.object({
    earningCode: earningCodeSchema,
    hourlyRate: yup.number().when('earningCode.isHourly', {
      is: true,
      then: () =>
        yup
          .number()
          .required(t('required'))
          .positive(t('mustBePositive'))
          .max(99_999_999.99, t('amountTooLarge'))
    }),
    hoursWorked: yup.number().when('earningCode.isHourly', {
      is: true,
      then: () =>
        yup
          .number()
          .required(t('required'))
          .positive(t('mustBePositive'))
          .max(99_999_999.99, t('amountTooLarge'))
    }),
    lumpSumAmount: yup.number().when('earningCode.isHourly', {
      is: false,
      then: () =>
        yup
          .number()
          .required(t('required'))
          .positive(t('mustBePositive'))
          .max(99_999_999.99, t('amountTooLarge'))
    }),
    currency: yup.string().required(t('required'))
  })
}

export type EarningsSchema = {
  retro: EarningSchema
  earnings: EarningSchema[]
}

export const useEarningsSchema = () => {
  const earningSchema = useEarningSchema()
  return yup.object({
    retro: earningSchema.required(),
    earnings: yup.array(earningSchema).defined()
  })
}
