import * as React from 'react'

export function useKeyPressAction(
  targetKey: string,
  action: () => void,
  options: { debug: boolean } = { debug: false }
) {
  const isPressed = useKeyPress(targetKey, options)

  React.useEffect(() => {
    if (isPressed) action()
  }, [isPressed, action])
}

export function useKeyPress(
  targetKey: string,
  options: { debug: boolean } = { debug: false }
) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = React.useState<boolean>(false)
  // If pressed key is our target key then set to true
  function downHandler({ key }: KeyboardEvent) {
    if (key === targetKey) {
      setKeyPressed(true)
    }

    if (options.debug) {
      console.log('useKeyPress: ', key)
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key }: KeyboardEvent) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }

    if (options.debug) {
      console.log('useKeyPress: ', key)
    }
  }
  // Add event listeners
  React.useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount
  return keyPressed
}
