import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { LaunchIcon, PayCheckIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { trackAttachEarnings } from '@local/track'
import { BaseRetroPayPanel } from '../retroPay'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { Currency, PayInterval } from '@local/generated/graphql'
import { useFormikContext } from 'formik'
import { PayChangeSchema } from '../../domain'
import { usePayChangeModalStore } from '../../PayChangeModalStore'

export type RetroPayInputPanelProps = {
  earningCodes: { label: string; value: string; isHourly: boolean }[]
}

export const RetroPayInputPanel: React.FunctionComponent<
  RetroPayInputPanelProps & TestIdentifiable
> = ({ earningCodes, testId }) => {
  const { t } = useTranslation('employees')
  const isHourly = usePayChangeModalStore((x) => x.isHourly)
  const { initialValues, values } = useFormikContext<PayChangeSchema>()

  return (
    <BaseRetroPayPanel
      IconComponent={PayCheckIcon}
      legend={t('retroactivePay')}
      caption={t('retroactivePayCaption')}
      t={t}
      newRate={{
        amount: {
          amount: values.rate.toString(),
          currency: values.currency as Currency
        },
        interval: isHourly ? PayInterval.HOUR : PayInterval.ANNUAL
      }}
      oldRate={{
        amount: {
          amount: initialValues.rate.toString(),
          currency: initialValues.currency as Currency
        },
        interval: isHourly ? PayInterval.HOUR : PayInterval.ANNUAL
      }}
      earningsInputProps={{
        earningCodeProps: {
          ...trackAttachEarnings('earning-code'),
          testId: 'pay-change-modal.retro-earning-code-dropdown',
          label: t('earningCode'),
          name: 'earnings.retro.earningCode',
          options: earningCodes,
          placeholder: t('selectEarningCode')
        },
        hoursProps: {
          ...trackAttachEarnings('hours'),
          testId: 'pay-change-modal.retro-hours',
          label: t('hours'),
          name: 'earnings.retro.hoursWorked',
          helperText: t('retroHoursWorkedInputHelperText'),
          suffix: t('hoursAbbr')
        },
        hourlyRateProps: {
          ...trackAttachEarnings('rate'),
          testId: 'pay-change-modal.retro-rate',
          label: t('payChange'),
          name: 'earnings.retro.hourlyRate',
          currency: Currency.USD,
          suffix: t('dollarPerHour')
        },
        lumpSumProps: {
          ...trackAttachEarnings('retro-pay'),
          testId: 'pay-change-modal.retro-lump-sum',
          helperText: t('retroLumpSumInputHelperText'),
          label: t('grossPayOwed'),
          name: 'earnings.retro.lumpSumAmount',
          currency: Currency.USD
        }
      }}
      footer={<Footer testId={testId} />}
    />
  )
}

const Footer: React.FunctionComponent<TestIdentifiable> = ({ testId }) => {
  const url = 'https://central.toasttab.com/s/article/Toast-Payroll-Overtime'
  const { t } = useTranslation('employees')
  return (
    <Button
      {...trackAttachEarnings('overtime-link')}
      as='a'
      href={url}
      iconRight={<LaunchIcon accessibility='decorative' />}
      rel='noopener noreferrer'
      target='_blank'
      variant='text-link'
      testId={`${testId}-footer-link`}
    >
      {t('overtimeDisclaimer')}
    </Button>
  )
}
