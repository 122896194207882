import React from 'react'
import { Document, TableRowContent } from './domain'
import { DoneIcon, RestaurantMenuIcon } from '@toasttab/buffet-pui-icons'
import { ActionTablePage } from './components'
import { useQuery } from 'react-query'
import { useEmployeeId, useCompanyCode } from '../hooks'
import { useApi } from '../../ApiProvider'

export const RequiredDocuments = () => {
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()
  const api = useApi()

  const returnToDashboard = () => {
    window.location.href = `/${companyCode}/dashboard`
  }

  const mapDocumentsToRows = (
    documents: Array<Document>
  ): Array<TableRowContent> =>
    documents.map((doc, index) => {
      const nextIndex = index + 1
      const nextId =
        documents.length - 1 === index ? 0 : documents[nextIndex].id

      return new TableRowContent<'complete' | 'incomplete'>(
        doc.name,
        doc.signatureRequired ? 'Signature Required' : 'No signature required',
        doc.isComplete ? <DoneIcon /> : <RestaurantMenuIcon />,
        doc.href,
        nextId,
        doc.isComplete ? 'complete' : 'incomplete'
      )
    })

  const { isError, isLoading, isSuccess, data } = useQuery(
    ['required-documents', companyCode, employeeId],
    () => api.getRequiredDocuments(companyCode, employeeId),
    {
      useErrorBoundary: false,
      suspense: false
    }
  )

  const allDocsComplete = data?.every?.((doc) => doc.isComplete) ?? false

  const rows = isSuccess && data ? mapDocumentsToRows(data) : []

  return (
    <ActionTablePage
      backButtonFn={returnToDashboard}
      title={'New Hire Documents'}
      subtitle={'Please review and sign the following documents'}
      allActionsComplete={allDocsComplete}
      allActionsCompleteFn={returnToDashboard}
      allActionsCompleteBtnTitle={'Back to Dashboard'}
      tableRowContent={rows}
      tableRowType={'document'}
      isLoading={isLoading}
      isError={isError}
      testId='required-documents-table'
      errorText={'There was an issue loading your documents.'}
    />
  )
}

export default RequiredDocuments
