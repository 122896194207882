import * as React from 'react'
import { NumberInput, TextInput } from '@toasttab/buffet-pui-text-input'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { Suffix, Label } from '@toasttab/buffet-pui-text-base'
import {
  VisibilityOffIcon,
  VisibilityOnIcon,
  CloseIcon,
  EditIcon
} from '@toasttab/buffet-pui-icons'
import { useEffect, useState } from 'react'
import { useSocialSecurityQuery } from '../hooks'
import { useTranslation } from 'react-i18next'

type Props = {
  value: string | null
  error: string | undefined
  url: string
  onChange: (e: any) => void
  setInitialSsn: (ssn: string) => void
  hasSsnToken: boolean
}

export const SsnInput = (props: Props) => {
  const { value, error, hasSsnToken, onChange, url, setInitialSsn } = props

  const { t } = useTranslation('employees')

  const [maskedSsn, setMaskedSsn] = useState(true)
  const [editableSsn, setEditableSsn] = useState(false)

  const { socialSecurity, isSuccess } = useSocialSecurityQuery(url, true)

  useEffect(() => {
    if (socialSecurity?.number && isSuccess) {
      setInitialSsn(socialSecurity.number)
      onChange({ formattedValue: socialSecurity.number })
    }
  }, [isSuccess, socialSecurity?.number])

  const iconButton = (
    <IconButton
      icon={
        maskedSsn ? (
          <VisibilityOnIcon aria-label='Toggle Off' />
        ) : (
          <VisibilityOffIcon aria-label='Toggle On' />
        )
      }
      contained
      onClick={() => {
        setMaskedSsn((maskedSsn) => !maskedSsn)
      }}
    />
  )

  return (
    <>
      <Label name={t('socialSecurityNumber')} required>
        {t('socialSecurityNumber')}
      </Label>
      <div className='flex items-baseline gap-2'>
        {hasSsnToken && !editableSsn ? (
          <TextInput
            required
            testId='input-masked-ssn'
            value={maskedSsn ? `•••-••-${value?.slice(-4)}` : value || ''}
            size='sm'
            readOnly
            invalid={error != null}
            errorText={error}
            containerClassName='w-full lg:w-1/2 xl:w-1/3 mr-2'
            suffix={iconButton}
            suffixVariant={Suffix.Variant.iconButton}
          />
        ) : (
          <NumberInput
            required
            readOnly={hasSsnToken && !editableSsn}
            maxLength={maskedSsn ? 9 : undefined}
            format={maskedSsn ? null : '###-##-####'}
            allowEmptyFormatting
            mask={maskedSsn ? '•' : ' '}
            type={maskedSsn ? 'password' : ''}
            inputMode='decimal'
            containerClassName='w-full lg:w-1/2 xl:w-1/3 mr-2'
            value={value || ''}
            name='socialSecurityNumber'
            onChange={onChange}
            invalid={error != null}
            errorText={error}
            testId='input-ssn'
            className='tabular-nums'
            suffix={iconButton}
            suffixVariant={Suffix.Variant.iconButton}
          />
        )}
        {hasSsnToken && (
          <IconButton
            className='pb-1 mb-0'
            cropToIcon
            icon={
              editableSsn ? (
                <CloseIcon aria-label={'stop edit social security number'} />
              ) : (
                <EditIcon aria-label={'edit social security number'} />
              )
            }
            onClick={() => setEditableSsn(!editableSsn)}
            data-toast-track-id='profile-onboarding-edit-ssn'
          />
        )}
      </div>
    </>
  )
}
