import * as React from 'react'
import { TextInput } from '@toasttab/buffet-pui-text-input'

export interface TextInputProps {
  label: string
  value: any
  onChange: (value: string, isDelete?: boolean) => void
  placeholder?: string
  optional?: boolean
  errorMessage?: string
  containerClassName?: string
  testId: string
  disabled?: boolean
  autoComplete?: string
  prefix?: string
  required?: boolean
}

export const TextInputFormField = ({
  label,
  value,
  onChange,
  placeholder,
  optional = false,
  errorMessage = '',
  containerClassName,
  testId,
  disabled = false,
  autoComplete,
  prefix,
  required
}: TextInputProps) => {
  return (
    <TextInput
      required={required}
      name={testId}
      label={label}
      placeholder={placeholder}
      value={value}
      invalid={!!errorMessage}
      helperText={optional ? 'Optional' : undefined}
      errorText={errorMessage}
      onChange={(event: any) => onChange(event.target.value)}
      containerClassName={containerClassName || 'mb-6 w-full lg:w-1/2 xl:w-1/3'}
      testId={`input-${testId}`}
      disabled={disabled}
      autoComplete={autoComplete}
      prefix={prefix}
    />
  )
}
