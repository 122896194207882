import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { lowerFirst } from 'lodash'
import {
  AllEmploymentStatuses,
  HumanResourcesPermission,
  useGetEmploymentDetailsQuery
} from '@local/generated/graphql'
import { trackEmploymentOverview } from '@local/track'
import { useEmployeeId } from '../../../hooks'
import { OverviewCard, OverviewCardBoundary } from '../components'
import { EmploymentDetails, EmploymentDetailsLoadingState } from './components'
import { useNavigateTo } from '../../../../navigation/hooks'
import { useApi } from '../../../../ApiProvider'
import { useHasPermission } from '../hooks'
import { isSupportedStatus } from '../utils'

export const EmploymentDetailsCard = () => {
  const { t } = useTranslation('employees')

  const employeeId = useEmployeeId()
  const { loading, data } = useGetEmploymentDetailsQuery({
    variables: { employeeId }
  })
  const employee = data?.findEmployeeById

  const onActionClick = useActionClick(employee?.employment?.employmentStatus)

  return (
    <div data-testid='employment-details-card' className='grid'>
      <OverviewCardBoundary
        cardTitle={t('employmentDetails')}
        errorText={t('cardErrorText', {
          fullName: employee?.user?.name.formatted,
          details: lowerFirst(t('employmentDetails'))
        })}
        loadingView={<EmploymentDetailsLoadingState />}
        onActionClick={onActionClick}
      >
        <OverviewCard
          {...trackEmploymentOverview('employment-edit')}
          cardTitle={t('employmentDetails')}
          onAbort={onActionClick}
          onActionClick={onActionClick}
        >
          {loading ? (
            <EmploymentDetailsLoadingState />
          ) : (
            <EmploymentDetails
              employeeNumber={employee?.employment?.employeeNumber}
              employmentType={employee?.employment?.employmentType}
              employmentStatus={employee?.employment?.employmentStatus}
            />
          )}
        </OverviewCard>
      </OverviewCardBoundary>
    </div>
  )
}

const useActionClick = (
  employmentStatus?: Partial<AllEmploymentStatuses> | null
) => {
  const api = useApi()
  const navigateTo = useNavigateTo()
  const canEditEmployee = useHasPermission(
    HumanResourcesPermission.EMPLOYMENT_EDIT
  )
  const navigateToPositionDetails = async () => {
    const esxResponse = await api.getEditPositionDetails()
    if (esxResponse?.url) {
      navigateTo(esxResponse.url, true)
    }
  }

  return canEditEmployee && isSupportedStatus(employmentStatus)
    ? navigateToPositionDetails
    : undefined
}
