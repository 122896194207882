import * as React from 'react'
import { stripNonNumeric } from '../../../onboarding/shared/formHelpers'

export const formSectionHeader = (heading: string, description: string) => (
  <>
    <div className='p-0 mb-1 font-bold type-headline-5'>{heading}</div>
    <p className='pt-0 mt-0 mb-4 md:mb-8 type-default text-secondary'>
      {description}
    </p>
  </>
)

export const MASKED_SSN = (
  <>
    <b>•••</b> - <b>••</b> - <b>••••</b>
  </>
)

/*
 * Skip reformatting phone number if we are just deleting
 * non-numeric format characters
 * Example: "(617)" => "(617" by deleting the ")"
 * If we reformatted in these cases we'd be unable to delete the ")" since
 * reformatting would continue to re-add it as you backspace
 */
export const skipPhoneReformat = (
  currentPhone: string | undefined | null,
  newPhone: string
) => {
  if (!currentPhone) {
    return false
  }
  const isDelete = currentPhone.length > newPhone.length
  const isSameNumber =
    stripNonNumeric(currentPhone) === stripNonNumeric(newPhone)
  return isDelete && isSameNumber
}
