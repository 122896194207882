import React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useIsEmployeeProfileNavEnabled } from '../../../hooks'
import { FormAlert } from './FormAlert'

type FormContainerProps = {
  headerText: React.ReactNode
  children: React.ReactNode
  error?: string | null
} & TestIdentifiable

export const FormContainer = ({
  testId,
  error,
  headerText,
  children
}: FormContainerProps) => {
  const isEmployeeProfileNavEnabled = useIsEmployeeProfileNavEnabled()
  return (
    <div
      data-testid={testId}
      className={cx(
        'flex-grow md:bg-gray-0',
        isEmployeeProfileNavEnabled ? 'pb-12' : 'p-8 md:p-12'
      )}
    >
      <FormAlert error={error} />
      <div
        className='pb-2 mx-4 font-normal md:m-0 type-headline-4'
        data-testid='form-header'
      >
        {headerText}
      </div>
      <div
        className={
          isEmployeeProfileNavEnabled
            ? 'p-4 bg-white md:rounded shadow-md-flush md:p-10 md:pt-8 md:shadow-md'
            : 'p-8 bg-white rounded md:p-12 shadow-md'
        }
      >
        {children}
      </div>
    </div>
  )
}
