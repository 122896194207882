import React, { useEffect, useState } from 'react'
import { getIn, useFormik } from 'formik'
import { TextInputFormField } from './TextInputFormField'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import { AsYouType } from 'libphonenumber-js'
import { FormContainer } from './FormContainer'
import { skipPhoneReformat } from '../shared/formHelpers'
import { Contact } from '../domain'
import {
  useUpdatePersonalProfile,
  usePendingContact,
  useSetPendingContact,
  usePersonalProfile,
  useNavigateToProfilePage
} from '../hooks'
import { useIsViewingSelf, useUpdateOverview } from '../../../hooks'
import { useApi } from '../../../../ApiProvider'
import { useEvent } from '@local/hooks'
import { useTranslation } from 'react-i18next'

export const ContactForm = () => {
  const handleBackToProfilePage = useEvent(useNavigateToProfilePage())
  const [error, setError] = useState('')
  const personalProfile = usePersonalProfile()
  const updatePersonalProfile = useUpdatePersonalProfile()
  const updateOverview = useUpdateOverview()
  const contactToSave = usePendingContact()
  const setContactToSave = useSetPendingContact()
  const isViewingSelf = useIsViewingSelf()
  const { t } = useTranslation('employees')
  const api = useApi()

  const contact = personalProfile?.contact
  const links = personalProfile?.links

  const { values, setFieldValue, errors, handleSubmit } = useFormik({
    initialValues: contact || ({} as Contact),
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: Contact.schema,
    onSubmit: (values: Contact): void => {
      try {
        setContactToSave(Contact.of(values))
      } catch {
        setError('Please fix the errors below')
      }
    },
    enableReinitialize: true
  })

  useEffect(() => {
    const abortController = new AbortController()

    const sendBasicInfo = async () => {
      if (contactToSave && links) {
        setError('')
        try {
          api.saveContactInfo(links.contact, contactToSave).then(() => {
            updatePersonalProfile({ contact: contactToSave })

            if (contactToSave.primaryTelephone) {
              updateOverview({
                primaryPhone: contactToSave.primaryTelephone.number
              })
            }
          })
          handleBackToProfilePage()
        } catch (e) {
          setError('Could not save basic info')
        }
        setContactToSave(null)
      }
    }

    sendBasicInfo()

    return () => {
      abortController.abort()
    }
  }, [
    api,
    contactToSave,
    handleBackToProfilePage,
    links,
    setContactToSave,
    updateOverview,
    updatePersonalProfile
  ])

  return (
    <FormContainer headerText='Edit Contact Information' error={error}>
      <div className='mb-1 font-bold type-headline-5'>
        {isViewingSelf
          ? t('whatIsYourPhoneNumberMyself')
          : t('whatIsYourPhoneNumberTeam')}
      </div>
      <div className='mb-4 md:mb-8 type-default text-secondary'>
        {isViewingSelf ? t('smsPreferredMyself') : t('smsPreferredTeam')}
      </div>
      <TextInputFormField
        label='Phone number'
        value={values.primaryTelephone?.number}
        onChange={(value) => {
          const skipFormat = skipPhoneReformat(
            values.primaryTelephone?.number,
            value
          )
          setFieldValue(
            'primaryTelephone.number',
            skipFormat ? value : new AsYouType('US').input(value)
          )
        }}
        placeholder='+1 555 555 0100'
        errorMessage={getIn(errors, 'primaryTelephone.number')}
        testId='contact-phone-number'
      />
      <div className='pb-4 -mt-6'>
        <Checkbox
          checked={!!values.primaryTelephone?.isMobile}
          label={'This is a mobile number'}
          onChange={() => {
            setFieldValue(
              'primaryTelephone.isMobile',
              !values.primaryTelephone?.isMobile
            )
          }}
          testId='contact-mobile-checkbox'
        />
      </div>

      <div className='flex justify-between'>
        <Button onClick={handleBackToProfilePage} variant='secondary'>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()} testId='contact-save-btn'>
          Save changes
        </Button>
      </div>
    </FormContainer>
  )
}
