import { Alert } from '@toasttab/buffet-pui-alerts'
import React from 'react'

export interface FormErrorProps {
  error: string | null | undefined
}

export const FormAlert = ({ error }: FormErrorProps) => {
  if (error) {
    return (
      <div className='mx-8 mb-12 md:mx-0' data-testid='error-msg'>
        <Alert showIcon className='w-full' variant='error'>
          {error}
        </Alert>
      </div>
    )
  } else {
    return null
  }
}
