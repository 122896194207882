import * as React from 'react'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { I18nextProvider } from 'react-i18next'

export enum i18Formats {
  CAPITALIZE = 'capitalize'
}

i18n.use(LanguageDetector).init({
  fallbackLng: 'en-US',
  interpolation: {
    escapeValue: false,
    format: function (value, format) {
      // capitalizes the first letter
      if (format === i18Formats.CAPITALIZE)
        return `${value.substring(0, 1).toUpperCase()}${value.substring(1)}`
      return value
    }
  },
  detection: {
    order: ['htmlTag', 'navigator']
  }
})

export const I18nProvider = ({ children }: React.PropsWithChildren<{}>) => (
  <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
)
