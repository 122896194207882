/**
 * Walks an object tree, depth first.
 * TODO: This would be better suited as a generator but something is keeping downlevelIteration from working...
 * @param nodeOrList A node or list of nodes to walk
 * @param callback Callback for each node in the tree. The value returned from the callback
 *   is treated as the child/child list and will continue walking.
 * @param [parentPath] Current parent nodes from top to bottom
 */
export function walkTree<T extends object = object>(
  nodeOrList: T | T[],
  callback: (result: { node: T; path: T[] }) => T | T[] | null | undefined,
  parentPath: T[] = []
): void | null {
  const nodes = Array.isArray(nodeOrList) ? nodeOrList : [nodeOrList]

  for (const node of nodes) {
    const childOrList = callback({ node, path: parentPath })
    const hasChildren = Boolean(
      Array.isArray(childOrList) ? childOrList.length : childOrList
    )

    // Short circuit if null is explicitly returned
    if (childOrList === null) {
      return null
    } else if (hasChildren) {
      const childResult = walkTree(childOrList!, callback, [
        ...parentPath,
        node
      ])

      // Propagate up short circuiting
      if (childResult === null) {
        return null
      }
    }
  }
}
