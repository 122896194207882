import React from 'react'
import { generate } from 'shortid'

export class TableRowContent<TState extends string = string> {
  readonly id: string = generate()

  constructor(
    readonly title: string,
    readonly subtitle: string,
    readonly icon: React.ReactNode,
    readonly link: string,
    readonly nextDocId: number = 0,
    readonly state: TState = '' as TState
  ) {}
}
