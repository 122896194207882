import * as React from 'react'
import Heading from './heading'
import { useTranslation } from 'react-i18next'
import { DataTable } from '@toasttab/buffet-datatable'
import { QuarterlyWage } from '../models/quarterlyWage'
import { Employee } from '../models/employee'
import { ProcessWageDate } from '../utils/processWageDate'

const RawHTML = ({ children = '', className = '' }) => (
  <span
    className={className}
    dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />') }}
  />
)

type QuarterlyWageHistoryProps = {
  quarterlyWages: QuarterlyWage[]
  employee: Employee
}

export const QuarterlyWageHistory = ({
  quarterlyWages,
  employee
}: QuarterlyWageHistoryProps) => {
  const { t } = useTranslation('employees')

  const quarterlyWagesTableSchema = {
    dates: {
      label: `${t('payPeriod')}`,
      renderCol(value: string) {
        return ProcessWageDate(value)
      }
    },
    amount: {
      label: `${t('earnings')}**`,
      renderCol(value: number) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(value)
      }
    },
    hours: {
      label: `${t('hoursWorked')}**`
    }
  }

  return (
    <Heading
      title={t('quarterlyEarningsAndHoursWorked')}
      caption={t('mostRecentQuartersPriorToEmploymentEndDate')}
    >
      <div className='w-full -mt-3'>
        <DataTable
          data={quarterlyWages}
          hover={false}
          striped
          schema={quarterlyWagesTableSchema}
        />
      </div>
      <p>
        {'** '}
        {t('employmentSummarySupplement1')}{' '}
        <RawHTML>
          {t('employmentSummarySupplement3', {
            link: `<a href='${employee.payInformationURL}' target="_blank">${t(
              'viewPayInformation'
            )}</a>`
          })}
        </RawHTML>
      </p>
    </Heading>
  )
}
