import * as yup from 'yup'
export class EmergencyContact {
  static schema = yup
    .object({
      emergencyContactId: yup.number().nullable().default(null).defined(),
      isPrimary: yup.boolean().default(false).required(),
      firstName: yup
        .string()
        .required('First name is required')
        .max(100, 'First name must be 100 characters or less'),
      lastName: yup
        .string()
        .required('Last name is required')
        .max(100, 'Last name must be 100 characters or less'),
      relation: yup
        .string()
        .required('Relation is required')
        .max(100, 'Relation must be 100 characters or less'),
      primaryTelephone: yup
        .string()
        .stripToNull()
        .nullable()
        .default(null)
        .phone()
        .formattedPhone()
        .defined(),
      email: yup
        .string()
        .stripToNull()
        .nullable()
        .email('Must be a valid email address')
        .default(null)
        .defined()
    })
    .defined()

  static of(obj: any) {
    return EmergencyContact.copyOf(
      EmergencyContact.schema.validateSync(obj, {
        abortEarly: false
      })
    )
  }

  static copyOf(valid: Omit<EmergencyContact, 'togglePrimary' | 'setPrimary'>) {
    return new EmergencyContact(
      valid.emergencyContactId,
      valid.isPrimary,
      valid.firstName,
      valid.lastName,
      valid.relation,
      valid.primaryTelephone,
      valid.email
    )
  }

  private constructor(
    readonly emergencyContactId: number | null,
    readonly isPrimary: boolean,
    readonly firstName: string,
    readonly lastName: string,
    readonly relation: string,
    readonly primaryTelephone: string | null,
    readonly email: string | null
  ) {}

  public togglePrimary(): EmergencyContact {
    return this.setPrimary(!this.isPrimary)
  }

  public setPrimary(primary: boolean): EmergencyContact {
    return new EmergencyContact(
      this.emergencyContactId,
      primary,
      this.firstName,
      this.lastName,
      this.relation,
      this.primaryTelephone,
      this.email
    )
  }
}
