import { ApolloLink } from '@apollo/client'
import isObject from 'lodash/isObject'

export const restSerializerLink = () =>
  new ApolloLink((operation, forward) => {
    // This is really gross, but we rely on JSON stringification to serialize dates to ISOs
    // instead of serializing when passing in variables.
    // Unfortunately, the rest link recursively crawls the variables and picks
    // out all keys and messes up any complex objects before they would get to a body
    // serializer. This is the least invasive way and is temporary until we move to fully GQL.
    if (isObject(operation.variables)) {
      operation.variables = JSON.parse(JSON.stringify(operation.variables))
    }

    return forward(operation)
  })
