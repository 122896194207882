import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { CardContainer } from '@toasttab/buffet-pui-card'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import {
  PayCheckIcon,
  MoneyBagIcon,
  CheckCircleSelectedIcon,
  ChevronRightIcon
} from '@toasttab/buffet-pui-icons'

import { useLatestEmployeePayHistory } from '../../api/useLatestEmployeePayHistory'
import { useOpenPaystubModal } from './modals/usePaystubModal'

export const ResponsiveLatest = () => {
  const screen = useScreenSize()

  if (screen > ScreenSize.SM) {
    return <Desktop />
  } else {
    return <Mobile />
  }
}

const ViewPaystubButton = ({ url }: { url: string }) => {
  const { t } = useTranslation('employees')
  const open = useOpenPaystubModal()

  return (
    <Button
      data-testid='latest-paystub-link'
      iconRight={<ChevronRightIcon accessibility='decorative' />}
      onClick={() => open(url)}
      variant='link'
    >
      {t('viewPaystub')}
    </Button>
  )
}

const Mobile = () => {
  const { t } = useTranslation('employees')
  const { data: latest } = useLatestEmployeePayHistory()

  if (latest === undefined) {
    return null
  }

  const payDateI18nKey = latest.isFuturePayDate
    ? 'upcomingPay'
    : 'mostRecentPay'

  return (
    <CardContainer className='flex flex-col space-y-6'>
      <div className='flex flex-col space-y-4'>
        <div className='flex flex-col'>
          <span className='uppercase text-hint type-caption text-gray-75'>
            {t(`${payDateI18nKey}`)}
          </span>
          <span className='font-bold type-headline-5'>
            {latest.formattedCheckDate}
          </span>
        </div>
        <div className='flex justify-between'>
          <div className='text-secondary'>{`${t('grossPay')}:`}</div>
          <div data-testid='latest-gross'>{latest.formattedGross}</div>
        </div>
        <div className='flex justify-between'>
          <div className='text-secondary'>{`${t('takeHomePay')}*:`}</div>
          <div data-testid='latest-net'>{latest.formattedNet}</div>
        </div>
      </div>
      <div className='flex justify-between pt-8 border-t border-dashed'>
        <div className='flex items-center space-x-2'>
          {latest.isFuturePayDate ? null : (
            <>
              <CheckCircleSelectedIcon className='mt-px text-success' />
              <span data-testid='latest-paid-on-date'>
                {t(`paidOnDate`, {
                  date: latest.formattedCheckDate
                })}
              </span>
            </>
          )}
        </div>
        <div className='-mr-4'>
          <ViewPaystubButton url={latest.viewPaystubUrl} />
        </div>
      </div>
    </CardContainer>
  )
}

const Desktop = () => {
  const { t } = useTranslation('employees')
  const { data: latest } = useLatestEmployeePayHistory()

  if (latest === undefined) {
    return null
  }

  const payDateI18nKey = latest.isFuturePayDate
    ? 'upcomingPay'
    : 'mostRecentPay'
  const checkDayI18nKey = latest.isFuturePayDate ? 'payday' : 'paidOn'

  return (
    <CardContainer>
      <div className='flex flex-row items-center justify-between space-x-4 space-y-0'>
        <div className='flex items-center space-x-2 space-y-2'>
          <PayCheckIcon className='mt-2' />
          <span className='capitalize text-default type-headline-4'>
            {t(`${payDateI18nKey}`)}
          </span>
        </div>
        <div className='flex-col ml-0 space-x-0 space-y-2'>
          <div className='flex flex-row items-center space-x-2'>
            <MoneyBagIcon />
            <span className='whitespace-nowrap'>{`${t('grossPay')}:`}</span>
            <span data-testid='latest-gross'>{latest.formattedGross}</span>
          </div>
          <div className='flex flex-row items-center space-x-2'>
            <CheckCircleSelectedIcon />
            <span className='whitespace-nowrap'>
              {`${t(`${checkDayI18nKey}`)}:`}
            </span>
            <span data-testid='latest-paid-on-date'>
              {latest.formattedCheckDate}
            </span>
          </div>
        </div>
        <div className='flex items-center self-end space-x-6'>
          <div className='flex flex-col'>
            <span className='font-semibold uppercase type-caption'>
              {`${t('takeHomePay')}*:`}
            </span>
            <span
              className='type-headline-1 tabular-nums'
              data-testid='latest-net'
            >
              {latest.formattedNet}
            </span>
          </div>
          <IconButton
            as='a'
            data-testid={'latest-paystub-link'}
            target={'_blank'}
            href={latest.viewPaystubUrl}
            icon={<ChevronRightIcon className='text-secondary' size='md' />}
          />
        </div>
      </div>
    </CardContainer>
  )
}
