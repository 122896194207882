import * as React from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useWindowProvider } from '../src/AppContainer'
import { LOGIN_URL } from '../src/login'
import { useTranslation } from 'react-i18next'

type Props = {
  isOpen: boolean
  returnUrl: string
  canCancel?: boolean
  onCancel: () => void
  testId?: string
}

export const ReauthenticationRedirectModal = (props: Props) => {
  const {
    onCancel,
    returnUrl,
    canCancel = true,
    isOpen,
    testId = 'reauthentication-redirect-modal'
  } = props

  const { t } = useTranslation('employees')

  const { location } = useWindowProvider()

  const onContinue = () => {
    const url = encodeURIComponent(returnUrl)
    location.href = `${LOGIN_URL}?ReturnUrl=${url}&reauth=true`
  }

  return (
    <Modal
      size='xl'
      testId={testId}
      isOpen={isOpen}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      overlayClassName={'!z-[100]'}
    >
      <Modal.Header>Log in to continue</Modal.Header>

      <Modal.Body>
        <p>{t('accessSensitiveInfoMessage')}</p>
      </Modal.Body>

      <Modal.Footer>
        {canCancel && (
          <Button onClick={onCancel} variant='link'>
            {t('cancel')}
          </Button>
        )}

        <Button
          onClick={onContinue}
          iconRight={<ArrowForwardIcon accessibility='decorative' />}
        >
          Log in
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
