import * as React from 'react'
import cx from 'classnames'
import { NetworkStatus } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { trackEmploymentOverview } from '@local/track'
import { useJobAssignmentsQuery } from '@local/generated/graphql'
import { useIsMobile } from '@local/use-is-mobile'
import {
  ErroredPayChangesAlert,
  ScheduledPayChangesAlert,
  ScheduledPayChangesModal
} from './payChanges'
import { JobsEmptyState, JobsLoadingState, JobsTable } from './components'
import { OverviewCard, OverviewCardBoundary } from '../components'
import { useEmployeeId } from '../../../hooks'
import { useIsEmploymentOverviewChangePayActive } from '../hooks'
import { useJobAssignmentsStore } from './JobAssignmentsStore'

export const JobsCard: React.FunctionComponent = () => {
  const { t } = useTranslation('employees')
  const isMobile = useIsMobile()

  const employeeId = useEmployeeId()

  const { loading, data, refetch, networkStatus } = useJobAssignmentsQuery({
    variables: { employeeId },
    notifyOnNetworkStatusChange: true
  })

  const [selectedScheduledPayChangeId, setSelectedScheduledPayChangeId] =
    React.useState<string | null>(null)

  const employee = data?.findEmployeeById
  const hasJobs = !!employee?.jobAssignments?.length

  const setEmployeeFirstName = useJobAssignmentsStore(
    (x) => x.setEmployeeFirstName
  )
  const setSalary = useJobAssignmentsStore((x) => x.setSalary)
  const setJobAssignments = useJobAssignmentsStore((x) => x.setJobAssignments)
  React.useEffect(() => {
    setEmployeeFirstName(
      employee?.user?.name?.chosen ??
        employee?.user?.name?.first ??
        t('yourEmployee')
    )
    setSalary(employee?.salary)
    if (hasJobs) {
      setJobAssignments(employee?.jobAssignments)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee])

  const hasScheduledSalaryChange = !!employee?.salary?.pendingPayChange

  const salaryJobsWithScheduledPayChanges = hasScheduledSalaryChange
    ? employee?.jobAssignments.filter((jobAssignment) => {
        return jobAssignment.activeHourlyRateOfPay === null
      })
    : []

  const hourlyJobsWithScheduledPayChanges = employee?.jobAssignments?.filter(
    (job) => {
      return job.pendingPayChange !== null
    }
  )

  const hasScheduledPayChanges =
    hasScheduledSalaryChange || !!hourlyJobsWithScheduledPayChanges?.length

  const refetchJobAssignments = () => {
    refetch({
      employeeId
    })
  }

  const [isScheduledPayChangesModalOpen, setIsScheduledPayChangesModalOpen] =
    React.useState<boolean>(false)

  const viewScheduledPayChanges = (
    scheduledPayChangeId: string | null = null
  ) => {
    setIsScheduledPayChangesModalOpen(true)
    setSelectedScheduledPayChangeId(scheduledPayChangeId)
  }

  const closeScheduledPayChange = () => {
    setIsScheduledPayChangesModalOpen(false)
    setSelectedScheduledPayChangeId(null)
  }

  const changePayIsActive = useIsEmploymentOverviewChangePayActive()
  const erroredPayChangesCount =
    Number(data?.findEmployeeById?.jobAssignmentPayChangeErrors?.length || 0) +
    Number(!!data?.findEmployeeById?.salaryChangeErrors ? 1 : 0)

  return (
    <div data-testid='jobs-card' className='grid'>
      <OverviewCardBoundary
        cardTitle={t('jobsCardTitle')}
        errorText={t('cardErrorText', {
          fullName: employee?.user?.name.formatted,
          details: t('jobs')
        })}
      >
        <OverviewCard
          {...trackEmploymentOverview('job-edit')}
          cardTitle={t('jobsCardTitle')}
          noPadding
          className='space-y-1'
        >
          {(loading && !data) || networkStatus === NetworkStatus.refetch ? (
            <JobsLoadingState />
          ) : !hasJobs ? (
            <JobsEmptyState />
          ) : (
            <>
              <div className='space-y-1'>
                <div className={cx(isMobile ? 'mx-4' : 'mx-8')}>
                  <ErroredPayChangesAlert
                    count={erroredPayChangesCount}
                    onErrorAcknowledge={refetchJobAssignments}
                  />
                  {hasScheduledPayChanges && changePayIsActive && (
                    <ScheduledPayChangesAlert
                      salary={hasScheduledSalaryChange ? employee.salary : null}
                      hourlyJobs={hourlyJobsWithScheduledPayChanges ?? []}
                      viewScheduledPayChanges={viewScheduledPayChanges}
                    />
                  )}
                </div>
                <JobsTable
                  viewScheduledPayChange={viewScheduledPayChanges}
                  onPayChangeSuccess={refetchJobAssignments}
                />
              </div>
              {isScheduledPayChangesModalOpen && (
                <ScheduledPayChangesModal
                  selectedScheduledPayChangeId={selectedScheduledPayChangeId}
                  salary={hasScheduledSalaryChange ? employee.salary : null}
                  salaryJobs={salaryJobsWithScheduledPayChanges}
                  hourlyJobs={hourlyJobsWithScheduledPayChanges ?? []}
                  isOpen={isScheduledPayChangesModalOpen}
                  onClose={closeScheduledPayChange}
                  onCancelScheduledPayChange={refetchJobAssignments}
                />
              )}
            </>
          )}
        </OverviewCard>
      </OverviewCardBoundary>
    </div>
  )
}
