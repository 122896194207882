const en = {
  cancelPayChange: 'Cancel pay change',
  cancelingPayChange: 'Canceling pay change...',
  erroredAlert: 'A scheduled pay change can no longer be applied.<0>View</0>',
  erroredAlert_plural:
    '{{count}} scheduled pay changes can no longer be applied.<0>View</0>',
  erroredJobDeleted: 'Job name and location not available',
  erroredModalHeader: 'Remove scheduled pay change',
  erroredModalHeader_plural: 'Remove scheduled pay changes',
  erroredModalSubHeader: '{{firstName}} will continue making their current pay',
  erroredModalAlertTitle: 'Pay change is no longer applicable',
  erroredModalAlertTitle_plural: 'Pay changes are no longer applicable',
  erroredModalAlertContent:
    "{{firstName}}'s scheduled pay change can no longer be applied due to a recent change in their job settings",
  erroredModalAlertContent_plural:
    "{{firstName}}'s scheduled pay changes can no longer be applied due to recent changes in their jobs settings",
  erroredModalButton: 'Remove scheduled pay change',
  erroredModalButton_plural: 'Remove scheduled pay changes',
  erroredPayChangeAcknowledged:
    'You have successfully removed the failed pay change',
  erroredPayChangeAcknowledged_plural:
    'You have successfully removed the failed pay changes',
  erroredPayChangeAcknowledgeError:
    "We couldn't remove the failed pay change. Please try again.",
  erroredPayChangeAcknowledgeError_plural:
    "We couldn't remove the failed pay changes. Please try again.",
  keepPayChange: 'Keep pay change',
  multipleSalaryJobName: 'Salary update',
  multipleSalaryJobLocation: 'Applies to multiple jobs',
  newPay: 'New pay',
  oldPay: 'Old pay',
  scheduledAlert: 'There is a scheduled pay change.<0>View</0>',
  scheduledAlert_plural: '{{ count }} scheduled pay changes.<0>View</0>',
  scheduledCancelError:
    "We couldn't cancel the scheduled pay change. Please try again.",
  scheduledCancelHeader: 'Cancel pay change?',
  scheduledCancelSuccess:
    'You have successfully cancelled a scheduled pay change',
  scheduledDescription:
    'New changes to pay will replace prior pay changes until employee is paid',
  scheduledHourlyCancelDescription:
    '{{ firstName}} will continue making {{ previousRate }}',
  scheduledSalaryCancelDescription:
    ' {{ firstName }} will continue making {{ previousRate }}',
  scheduledHeader: 'Scheduled pay changes',
  scheduledHourlyAlert:
    "There is a scheduled pay change for {{ employeeFirstName }}'s {{ jobName }} job on {{ date }}.<0>View</0>",
  scheduledSalaryAlert:
    "There is a scheduled pay change for {{ employeeFirstName }}'s salary on {{ date }}.<0>View</0>"
}

export default en
