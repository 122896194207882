import * as React from 'react'
import i18next from 'i18next'
import { Trans, useTranslation } from 'react-i18next'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button } from '@toasttab/buffet-pui-buttons'
import { trackAttachEarnings } from '@local/track'
import { useIsViewingSelf } from '../../../../../../hooks'
import { useJobAssignmentsStore } from '../../../JobAssignmentsStore'

type AttachEarningsErrorProps = {
  translationKeyBase: string
}

export const AttachEarningsError: React.FunctionComponent<
  AttachEarningsErrorProps
> = ({ translationKeyBase }) => {
  const { t } = useTranslation('employees')
  const isViewingSelf = useIsViewingSelf()
  const employeeFirstName = useJobAssignmentsStore((x) => x.employeeFirstName)
  return (
    <Alert id='AttachEarningsError' variant='error' className='w-full'>
      {isViewingSelf ? (
        <Trans
          t={t}
          i18nKey={`${translationKeyBase}Self`}
          components={[
            <a
              href='https://central.toasttab.com/s/article/Toast-Payroll-Adding-or-Editing-Earnings-Deductions'
              target='_blank'
              rel='noopener noreferrer'
            />
          ]}
        />
      ) : (
        <Trans
          t={() =>
            i18next.format(
              t(translationKeyBase, {
                firstName: employeeFirstName
              }),
              'capitalize'
            )
          }
          i18nKey={translationKeyBase}
          components={[
            <a
              href='https://central.toasttab.com/s/article/Toast-Payroll-Adding-or-Editing-Earnings-Deductions'
              target='_blank'
              rel='noopener noreferrer'
            />
          ]}
        />
      )}
    </Alert>
  )
}

export const AttachEarningsErrorActions: React.FunctionComponent<{
  onClose: VoidFunction
  onPayChangeSuccess: VoidFunction
}> = ({ onClose, onPayChangeSuccess }) => {
  const { t } = useTranslation('employees')
  return (
    <Button
      {...trackAttachEarnings('error-close')}
      className='w-full'
      onClick={() => {
        onClose()
        onPayChangeSuccess()
      }}
      size='lg'
      variant='destructive'
    >
      {t('close')}
    </Button>
  )
}
