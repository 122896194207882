import * as React from 'react'
import {
  AddressLookup,
  mapboxPublicToken,
  Address
} from '@toasttab/buffet-patterns-address-lookup'
import { AddressValues, USA, US } from './AddressForm'

interface AddressLookupFormProps {
  label: string
  value: AddressValues
  placeholder?: string
  errorMessage?: string
  testId: string
  onChange: (value: Address) => void
}

export const AddressLookupForm = ({
  label,
  value,
  errorMessage = '',
  placeholder,
  testId,
  onChange
}: AddressLookupFormProps) => (
  <div className='w-full mb-6 xl:w-1/2'>
    <AddressLookup
      countries='us'
      label={label}
      lookupServiceAccessToken={mapboxPublicToken}
      name='address'
      onChange={(selection: Address) => onChange(selection)}
      invalid={!!errorMessage}
      placeholder={placeholder}
      testId={`input-${testId}`}
      value={{
        address1: value.address,
        address2: value.address2,
        city: value.locality,
        country: value.country === USA ? US : value.country,
        stateCode: value.administrativeArea,
        zipCode: `${value.postalCode}`
      }}
    />
  </div>
)
