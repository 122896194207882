import React, { useEffect, useState } from 'react'

import { AsYouType } from 'libphonenumber-js'
import { FormikErrors, useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import { NumberInput } from '@toasttab/buffet-pui-text-input'
import { Contact, PersonalProfile, TelephoneNumber } from '../domain'
import { TextInputFormField } from './TextInputFormField'
import { AddressForm } from './AddressForm'
import { FormContainer } from './FormContainer'
import { AdditionalInfoModal } from './AdditionalInfoModal'
import { skipPhoneReformat, formSectionHeader } from '../shared/formHelpers'
import { getDateAsDigits } from '../../../../shared/formHelpers'
import {
  useUpdatePersonalProfile,
  useLocalityGnisCodesQuery,
  usePersonalProfile
} from '../hooks'
import { useUpdateOverview } from '../../../hooks'
import { useApi } from '../../../../ApiProvider'
import { SelectFormField } from './SelectFormField'
import { useCustomerSettingsQuery } from '@local/generated/graphql'
import { useTranslation } from 'react-i18next'
import { SsnInput } from './SsnInput'

type Props = {
  companyCode: string
}

export const EEO_ENABLED_CUSTOMER_SETTING = 338

export const ProfileOnboadingForm = ({ companyCode }: Props) => {
  const [alertMessage, setAlertMessage] = useState('')
  const navigate = useNavigate()
  const personalProfile = usePersonalProfile()
  const updateOverview = useUpdateOverview()
  const updatePersonalProfile = useUpdatePersonalProfile()
  const api = useApi()

  const { t } = useTranslation('employees')

  const [showInfoModal, setShowInfoModal] = useState(false)
  const [initialSsn, setInitialSsn] = useState('')

  const { values, setFieldValue, errors, handleSubmit } =
    useFormik<PersonalProfile>({
      initialValues: personalProfile || ({} as PersonalProfile),
      validateOnChange: false,
      validateOnBlur: true,
      validationSchema: PersonalProfile.formSchema,
      onSubmit: (profile, { setSubmitting }) => {
        // due to an issue in esx-web, if the SSN already exists and hasn't changed
        // in the DB we do not want it updated
        savePersonalProfile(
          PersonalProfile.fromForm({
            ...profile,
            basic: {
              ...profile.basic,
              socialSecurityNumber:
                profile.basic.socialSecurityNumber === initialSsn
                  ? '•••-••-••••'
                  : profile.basic.socialSecurityNumber
            }
          })
        )
        setSubmitting(false)
      }
    })

  const savePersonalProfile = (profile: PersonalProfile) =>
    api
      .saveProfile(personalProfile.links.onboarding, profile)
      .then(() => {
        // Update the employee summary in left nav
        const basicToSave = profile.basic
        updatePersonalProfile({ basic: basicToSave })
        updateOverview({
          firstName: basicToSave.firstName,
          lastName: basicToSave.lastName,
          chosenName: basicToSave.chosenName
        })

        if (personalProfile.links.workOpportunityTaxCredit) {
          navigate(personalProfile.links.workOpportunityTaxCredit)
        } else {
          navigate(personalProfile.links.personalProfile, {
            state: { isOnboarded: true }
          })
        }
      })
      .catch((e) => {
        if (e.message.includes('409')) {
          window.scrollTo(0, 0)
          setAlertMessage(
            "There's an issue completing your profile information. Please reach out to your HR Manager for assistance."
          )
        }
      })

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      window.scrollTo(0, 0)
      setAlertMessage('Please fix the errors below')
    } else {
      setAlertMessage('')
    }
  }, [errors])

  const postalCode = values.addresses?.homeAddress?.postalCode
  const localityGnisCode = values.addresses?.homeAddress?.localityGnisCode

  // To clear locality gnis code when zipcode is invalid
  const clearLocalityCode = () => {
    if (localityGnisCode) {
      setFieldValue('addresses.homeAddress.localityGnisCode', null)
    }
  }

  const { localityGnisCodes } = useLocalityGnisCodesQuery(
    companyCode,
    postalCode,
    {
      suspense: false,
      useErrorBoundary: false,
      onSuccess: (codes) => {
        if (!codes.length) {
          clearLocalityCode()
        }
      }
    }
  )

  const contactErrorMessage = (
    error: FormikErrors<Contact> | undefined
  ): string => {
    if (error == null) return ''

    const telephone = error.primaryTelephone as FormikErrors<TelephoneNumber>

    // number is invalid
    if (telephone.number) {
      return telephone.number
    }
    // phone number is not present
    return error.primaryTelephone || ''
  }

  const options = personalProfile.options

  const { data } = useCustomerSettingsQuery({
    variables: {
      settings: [EEO_ENABLED_CUSTOMER_SETTING]
    }
  })

  const eeoCustomerSetting = data?.findCustomerSettings.find(
    (response) => response.id === EEO_ENABLED_CUSTOMER_SETTING
  )

  const eeoRequired = eeoCustomerSetting && eeoCustomerSetting.value === 'true'

  React.useEffect(() => {
    if (eeoRequired) {
      setFieldValue('basic.eeoRequired', true)
    }
    if (values.basic?.ssnToken) {
      setFieldValue('basic.hasInitialSsn', true)
    }
  }, [eeoRequired, values.basic?.ssnToken])

  return (
    <FormContainer
      testId='profile-onboarding-form'
      headerText='Personal Profile'
      error={alertMessage}
    >
      <>
        <div className='pb-6'>
          {formSectionHeader(
            'Tell us about yourself',
            'This information is necessary to get you paid'
          )}
          <TextInputFormField
            required={true}
            label='First name'
            value={values.basic?.firstName}
            onChange={(value) => setFieldValue('basic.firstName', value)}
            errorMessage={errors.basic?.firstName}
            testId='first-name'
          />
          <TextInputFormField
            required={true}
            label='Last name'
            value={values.basic?.lastName}
            onChange={(value) => setFieldValue('basic.lastName', value)}
            errorMessage={errors.basic?.lastName}
            testId='last-name'
          />
          <TextInputFormField
            label='Chosen name'
            value={values.basic?.chosenName ? values.basic?.chosenName : ''}
            onChange={(value) => setFieldValue('basic.chosenName', value)}
            optional={true}
            errorMessage={errors.basic?.chosenName}
            testId='chosen-name'
          />
          <NumberInput
            required={true}
            label='Date of birth'
            value={getDateAsDigits(values.basic?.dateOfBirth)}
            onChange={(event: any) => {
              setFieldValue('basic.dateOfBirth', event.formattedValue)
            }}
            format='##/##/####'
            allowEmptyFormatting
            mask=' '
            name='dob'
            testId='input-dob'
            errorText={errors.basic?.dateOfBirth}
            invalid={!!errors.basic?.dateOfBirth}
            className='tabular-nums'
            containerClassName='mb-6 w-full lg:w-1/2 xl:w-1/3'
            helperText='MM/DD/YYYY'
          />
          <SsnInput
            value={values.basic?.socialSecurityNumber}
            error={errors.basic?.socialSecurityNumber}
            url={personalProfile.links.socialSecurity}
            onChange={(e: any) =>
              setFieldValue('basic.socialSecurityNumber', e.formattedValue)
            }
            setInitialSsn={(ssn: string) => setInitialSsn(ssn)}
            hasSsnToken={values.basic?.ssnToken !== ''}
          />
        </div>
        <div className='pb-20'>
          {formSectionHeader(
            'Contact Info',
            "This is only used for your employer's records"
          )}

          <TextInputFormField
            required={true}
            label='Phone number'
            value={values.contact?.primaryTelephone?.number}
            onChange={(value: string) => {
              const skipReformat = skipPhoneReformat(
                values.contact?.primaryTelephone?.number,
                value
              )
              setFieldValue(
                'contact.primaryTelephone.number',
                skipReformat ? value : new AsYouType('US').input(value)
              )
              setFieldValue('contact.primaryTelephone.isMobile', false)
            }}
            placeholder='+1 555 555 0100'
            errorMessage={contactErrorMessage(errors.contact)}
            testId='contact-phone-number'
          />
        </div>
        {personalProfile && (
          <div className='pb-20'>
            <AddressForm
              required={true}
              label='Home address'
              instructions='This is used to calculate your taxes'
              countries={personalProfile.options.countries}
              statesInUnitedStates={
                personalProfile.options.statesInUnitedStates
              }
              values={values.addresses?.homeAddress || {}}
              errors={errors.addresses?.homeAddress || {}}
              setFieldValue={(field, value) =>
                setFieldValue('addresses.homeAddress.' + field, value)
              }
              addressType='home'
              localityGnisCodes={localityGnisCodes}
            />
          </div>
        )}
        {eeoRequired ? (
          <div className='pb-20'>
            <div className='p-0 mb-1 font-bold type-headline-5'>
              {t('eeoClassificationHeader')}
            </div>
            <p className='w-5/6 pt-0 mt-0 mb-4 md:mb-8 type-default text-secondary'>
              {t('eeoClassificationDescription')}
              <button
                className='font-medium text-link'
                onClick={() => setShowInfoModal(true)}
              >
                {t('learnMore')}
              </button>
            </p>
            <SelectFormField
              required={true}
              label={t('eeoSexSubheader')}
              options={options.gender}
              selectedVal={values.basic.gender}
              onChange={(value) => setFieldValue('basic.gender', value)}
              id='sex'
              errorText={errors.basic?.gender}
            />
            <SelectFormField
              required={true}
              label={t('eeoRaceEthnicitySubheader')}
              options={options.ethnicity}
              selectedVal={values.basic.ethnicity}
              onChange={(value) => setFieldValue('basic.ethnicity', value)}
              id='ethnicity'
              errorText={errors.basic?.ethnicity}
            />
            <AdditionalInfoModal
              showInfoModal={showInfoModal}
              onClose={() => setShowInfoModal(false)}
              infoHeaderLabel={t('eeoClassificationHeader')}
              infoDescriptionLabel={t('eeoAdditionalClassificationDescription')}
              closeButtonLabel={t('close')}
            />
          </div>
        ) : null}
        <div className='flex flex-row-reverse'>
          <Button onClick={() => handleSubmit()} testId='save-button'>
            Save changes
          </Button>
        </div>
      </>
    </FormContainer>
  )
}
