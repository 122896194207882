import { DocumentNode } from '@apollo/client'
import { OperationError } from '@local/ec-app/apollo'
import { User } from '../employee/profile/account/Domain'
import * as Queries from '@local/api/queries'
import { useHrClient } from '@local/api/ApolloClientsProvider'
import { ERROR_STATE } from '../error/ErrorStates'

export type HrClient = ReturnType<typeof useHrClient>

const makeHrApi = (hrClient: HrClient) => {
  type Args<V, R, D extends object> = {
    query: DocumentNode
    variables: V
    postProcess: (data: D) => R
  }

  const hrApicall = async <Variables, Result, Data extends object>(
    args: Args<Variables, Result, Data>
  ) => {
    const { query, variables, postProcess } = args

    const resp = await hrClient.query<Data>({ query, variables })

    try {
      return postProcess(resp.data)
    } catch (error) {
      throw new OperationError({
        query,
        result: resp.data,
        originalError: error as Error
      })
    }
  }

  return {
    findUserByCompanyCodeAndEmployeeUuid: (
      companyCode: string,
      employeeUuid: string
    ) => {
      type Data = { findUsers: { list: User[] } }
      return hrApicall({
        query: Queries.FIND_USER_BY_COMPANY_CODE_AND_EMPLOYEE_UUID,
        variables: { companyCode, employeeUuid },
        postProcess: (data: Data) => {
          if (data.findUsers.list[0]) {
            return User.of(data.findUsers.list[0])
          }

          throw new Error(ERROR_STATE.USER_NOT_FOUND_ERROR)
        }
      })
    }
  }
}

export default makeHrApi
