import * as yup from 'yup'

export class LocalityCodeOption {
  static schema = yup
    .object({
      zipCode: yup.string().required(),
      gnisCode: yup.string().required(),
      gnisCaption: yup.string().required()
    })
    .defined()

  static of(objs: any[]): LocalityCodeOption[] {
    return objs.map((obj) =>
      LocalityCodeOption.schema.validateSync(obj, { abortEarly: false })
    )
  }

  constructor(
    readonly zipCode: string,
    readonly gnisCode: string,
    readonly gnisCaption: string
  ) {}
}
