import * as React from 'react'
import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { format } from '@toasttab/buffet-pui-date-utilities'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import {
  Currency,
  PayInterval,
  SalaryRetroPay,
  useRetroPayForSalaryChangeQuery
} from '@local/generated/graphql'
import { useShouldCalculateRetroPay } from './utils'
import { LoadSpinner } from '../../../components'
import { PayChangeSchema } from '../../domain'
import { BaseRetroPayPanel } from './BaseRetroPayPanel'
import { usePayChangeModalStore } from '../../PayChangeModalStore'

export type SalaryRetroPayPanelProps = {
  employeeId: string
  jobAssignmentId?: string
} & TestIdentifiable

export const SalaryRetroPayPanel: React.FunctionComponent<
  SalaryRetroPayPanelProps
> = (props) => {
  const { values } = useFormikContext<PayChangeSchema>()
  const shouldCalculateRetroPay = useShouldCalculateRetroPay()

  return shouldCalculateRetroPay ? (
    <BaseSalaryRetroPayPanel effectiveDate={values.effectiveDate} {...props} />
  ) : null
}

const BaseSalaryRetroPayPanel: React.FunctionComponent<
  {
    effectiveDate: Date
  } & SalaryRetroPayPanelProps
> = ({ effectiveDate, employeeId, testId }) => {
  const { t } = useTranslation('employees')
  const { initialValues, values } = useFormikContext<PayChangeSchema>()
  const today = new Date()

  const { loading, data } = useRetroPayForSalaryChangeQuery({
    variables: {
      salaryRetroPayInput: {
        employeeId: employeeId,
        endDate: format(today, 'yyyy-MM-dd'),
        newRate: {
          amount: values.rate.toString(),
          currency: values.currency as Currency
        },
        oldRate: {
          amount: initialValues.rate.toString(),
          currency: initialValues.currency as Currency
        },
        startDate: format(effectiveDate, 'yyyy-MM-dd')
      }
    }
  })

  const setLoading = usePayChangeModalStore((x) => x.setLoading)
  useEffect(() => {
    setLoading(loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const salaryRetroPay = data?.retroPayForSalaryChange as SalaryRetroPay
  const setRetroPay = usePayChangeModalStore((x) => x.setRetroPay)
  const { setFieldValue } = useFormikContext<PayChangeSchema>()
  useEffect(() => {
    setRetroPay(salaryRetroPay as SalaryRetroPay)
    setFieldValue('earnings', undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, values?.rate, values?.effectiveDate])

  return loading ? (
    <LoadSpinner
      message={t('calculatingRetroPay', 'Calculating retro pay...')}
    />
  ) : !salaryRetroPay ? null : (
    <BaseRetroPayPanel
      containerClassName='!mb-2.5'
      legend={t('retroPayPanelLegend', 'You have chosen a past date')}
      salaryLabel={t(
        `${salaryRetroPay?.payFrequency}Salary`,
        t('salaryPerPayPeriod', 'Salary per pay period')
      )}
      testId={testId}
      newRate={{
        amount: {
          amount: values.rate.toString(),
          currency: values.currency as Currency
        },
        interval: PayInterval.ANNUAL
      }}
      oldRate={{
        amount: {
          amount: initialValues.rate.toString(),
          currency: initialValues.currency as Currency
        },
        interval: PayInterval.ANNUAL
      }}
      t={t}
    />
  )
}
