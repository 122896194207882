import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Money } from '@local/generated/graphql'
import { trackAttachEarnings } from '@local/track'
import { formatHrCurrency } from '../retroPay/utils'
import { PayChangeSchema } from '../../domain'
import { useIsViewingSelf } from '../../../../../../hooks'
import { useFormikContext } from 'formik'
import { useJobAssignmentsStore } from '../../../JobAssignmentsStore'
import { usePayChangeModalStore } from '../../PayChangeModalStore'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'

type CloseConfirmationModalContentsProps = {
  onClose: VoidFunction
}

export const CloseConfirmationModalContents: React.FunctionComponent<
  CloseConfirmationModalContentsProps
> = ({ onClose }) => {
  const { t } = useTranslation('employees')
  const { locale } = useBuffetContext()

  const { initialValues } = useFormikContext<PayChangeSchema>()
  const isHourly = usePayChangeModalStore((x) => x.isHourly)
  const pay = {
    amount: initialValues.rate,
    currency: initialValues.currency
  } as Money
  const suffix = isHourly ? t('hourAbbr') : t('yearAbbr')
  const formattedPay = pay
    ? `${formatHrCurrency(pay, locale)}/${suffix}`
    : undefined

  const employeeFirstName = useJobAssignmentsStore((x) => x.employeeFirstName)
  const translationSuffix = useIsViewingSelf() ? 'Self' : ''

  return (
    <>
      <Modal.Header>
        <div data-testid='pay-change-modal-header'>
          {t(`changePayCloseConfirmationTitle${translationSuffix}`, {
            employeeFirstName: employeeFirstName
          })}
        </div>
      </Modal.Header>
      <Modal.Body
        data-testid='close-confirmation-modal-contents'
        className='relative'
      >
        <div className='grid gap-y-6'>
          {t(`changePayCloseConfirmationContent${translationSuffix}`, {
            employeeFirstName: employeeFirstName,
            pay: formattedPay
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CloseConfirmationButtonGroup onClose={onClose} />
      </Modal.Footer>
    </>
  )
}

export const CloseConfirmationButtonGroup: React.FunctionComponent<
  CloseConfirmationModalContentsProps
> = ({ onClose }) => {
  const { t } = useTranslation('employees')

  return (
    <ButtonGroup className='w-full' direction='vertical' buttonSize='lg'>
      <Button
        {...trackAttachEarnings('close')}
        variant='secondary'
        size='lg'
        onClick={onClose}
      >
        {t('changePayCloseConfirmationLeave')}
      </Button>
      <Button
        {...trackAttachEarnings('close-confirmation-stay')}
        form='pay-change-form'
        size='lg'
        type='submit'
      >
        {t('changePayCloseConfirmationStay')}
      </Button>
    </ButtonGroup>
  )
}
