import * as React from 'react'
import { Column } from 'react-table'
import { PayChangeCard, PayChangeCardProps } from '@local/change-pay'

export const PayChangesColumns: Column<PayChangeCardProps>[] = [
  {
    id: 'payChange',
    accessor: 'jobProps',
    Cell: ({ row: { original: scheduledPayChangeCardProps } }) => {
      return <PayChangeCard {...scheduledPayChangeCardProps} />
    }
  } as const
]
