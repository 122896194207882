import * as React from 'react'
import { useEffect } from 'react'
import { useFlexLayout, useRowSelect, useTable } from 'react-table'
import { OverviewCardTable } from '@local/overview-card'
import { useIsMobile } from '@local/use-is-mobile'
import {
  JobsTableColumns,
  MOBILE_HIDDEN_COLUMNS,
  VIEW_JOBS_COLUMNS
} from './JobsTableColumns'
import {
  useHasPermission,
  useIsEmploymentOverviewChangePayActive
} from '../../hooks'
import { AddJobsButton } from './AddJobsButton'
import { SalaryCard } from './SalaryCard'
import {
  HumanResourcesPermission,
  JobAssignmentsListJobAssignmentFragment
} from '@local/generated/graphql'
import { useJobAssignmentsStore } from '../JobAssignmentsStore'
import { PayChangeHistoryLink } from './PayChangeHistoryLink'

const PAGE_SIZE = 5

export type Props = {
  onPayChangeSuccess: () => void
  viewScheduledPayChange: (scheduledPayChangeId: string) => void
}

export const JobsTable: React.FunctionComponent<Props> = ({
  onPayChangeSuccess,
  viewScheduledPayChange
}) => {
  const isMobile = useIsMobile()

  const employeeFirstName = useJobAssignmentsStore((x) => x.employeeFirstName)
  const jobAssignments = useJobAssignmentsStore((x) => x.jobAssignments)
  const salary = useJobAssignmentsStore((x) => x.salary)

  const canAddJobs =
    useHasPermission(HumanResourcesPermission.JOB_ASSIGNMENT_EDIT) &&
    !!jobAssignments?.length
  const canViewJob = useHasPermission(
    HumanResourcesPermission.JOB_ASSIGNMENT_VIEW
  )

  const canViewPayChangeHistory = useHasPermission(
    HumanResourcesPermission.PAY_CHANGE_HISTORY_VIEW
  )

  const isEmploymentOverviewChangePayActive =
    useIsEmploymentOverviewChangePayActive()

  const salaryJob = jobAssignments?.find(
    (it: JobAssignmentsListJobAssignmentFragment) =>
      it.activeHourlyRateOfPay === null
  )

  const showSalaryCard =
    jobAssignments?.length > 1 && salary?.activeSalaryRateOfPay && !!salaryJob

  const { rows, prepareRow, setHiddenColumns } = useTable(
    {
      columns: JobsTableColumns,
      data: jobAssignments
    },
    useRowSelect,
    useFlexLayout
  )

  useEffect(() => {
    // @ts-ignore
    let hiddenColumns: string[] = []
    if (isMobile) {
      hiddenColumns.push(...MOBILE_HIDDEN_COLUMNS)
    }
    if (!canViewJob) {
      hiddenColumns.push(...VIEW_JOBS_COLUMNS)
    }
    setHiddenColumns(hiddenColumns)
  }, [isMobile, canViewJob])

  return (
    <OverviewCardTable
      testId='jobs'
      rows={rows}
      prepareRow={prepareRow}
      pageSize={PAGE_SIZE}
      userProps={
        showSalaryCard
          ? {
              employeeFirstName: employeeFirstName,
              onPayChangeSuccess: onPayChangeSuccess,
              viewScheduledPayChange: viewScheduledPayChange
            }
          : {
              employeeFirstName: employeeFirstName,
              salary: salary,
              onPayChangeSuccess: onPayChangeSuccess,
              viewScheduledPayChange: viewScheduledPayChange
            }
      }
      fullTableDiv={
        showSalaryCard ? (
          <SalaryCard
            jobAssignment={salaryJob}
            salary={salary!!}
            onPayChangeSuccess={onPayChangeSuccess}
            viewScheduledPayChange={viewScheduledPayChange}
          />
        ) : undefined
      }
      pageLink={
        isEmploymentOverviewChangePayActive && canViewPayChangeHistory ? (
          <PayChangeHistoryLink />
        ) : undefined
      }
      button={canAddJobs ? <AddJobsButton /> : undefined}
    />
  )
}
