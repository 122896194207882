import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { CheckboxField } from '@toasttab/buffet-pui-forms'
import { usePayChangeModalStore } from '../../../PayChangeModalStore'
import { useIsViewingSelf } from '../../../../../../../hooks'
import { useJobAssignmentsStore } from '../../../../JobAssignmentsStore'
import { trackPayChange } from '@local/track'

export type PayChangeThresholdAlertProps = {
  initialRate: number
  newRate: number
  rateConfirmed: boolean
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

export const PayChangeThresholdAlert: React.FunctionComponent<
  PayChangeThresholdAlertProps
> = ({
  initialRate,
  newRate,
  rateConfirmed,
  setFieldTouched,
  setFieldValue
}) => {
  const { t } = useTranslation('employees')
  const [canShowAlert, setCanShowAlert] = React.useState(false)

  const increaseConfirmationRequired = payIncreaseConfirmationRequired(
    initialRate,
    newRate
  )
  const decreaseConfirmationRequired = payDecreaseConfirmationRequired(
    initialRate,
    newRate
  )
  const confirmationRequired =
    increaseConfirmationRequired || decreaseConfirmationRequired

  React.useEffect(() => {
    setFieldValue('rateConfirmationRequired', confirmationRequired)
    // forces validation after setFieldValue is complete
    // use case is appending a number to the rate i.e. 6.00 -> 16.00
    // https://github.com/jaredpalmer/formik/issues/2457
    setTimeout(() => setFieldTouched('rateConfirmed', true), 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationRequired])

  React.useEffect(() => {
    setFieldValue('rateConfirmed', false)
    setCanShowAlert(false)
    const delay = setTimeout(() => {
      setCanShowAlert(true)
    }, 250)
    return () => clearTimeout(delay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRate])

  const alertContents = useAlertText(initialRate, newRate)

  return confirmationRequired && canShowAlert ? (
    <div className='grid gap-y-2'>
      {!rateConfirmed && <Alert variant='warning'>{alertContents}</Alert>}
      <CheckboxField
        {...trackPayChange('threshold-acknowledged')}
        name='rateConfirmed'
        label={t('payChangeRateConfirmation')}
      />
    </div>
  ) : null
}

export const payIncreaseConfirmationRequired = (
  initialRate?: number | null,
  newRate?: number | null
): boolean => {
  if (
    initialRate === undefined ||
    initialRate === null ||
    newRate === undefined ||
    newRate === null
  ) {
    return false
  }
  return 2 * initialRate <= newRate
}

export const payDecreaseConfirmationRequired = (
  initialRate?: number | null,
  newRate?: number | null
): boolean => {
  if (
    initialRate === undefined ||
    initialRate === null ||
    newRate === undefined ||
    newRate === null
  ) {
    return false
  }
  return initialRate > newRate
}

export const useAlertText = (
  initialRate?: number | null,
  newRate?: number | null
): string | null => {
  const { t } = useTranslation('employees')
  const isViewingSelf = useIsViewingSelf()

  const employeeFirstName = useJobAssignmentsStore((x) => x.employeeFirstName)
  const isHourly = usePayChangeModalStore((x) => x.isHourly)

  const increaseConfirmationRequired = payIncreaseConfirmationRequired(
    initialRate,
    newRate
  )
  const decreaseConfirmationRequired = payDecreaseConfirmationRequired(
    initialRate,
    newRate
  )

  if (!increaseConfirmationRequired && !decreaseConfirmationRequired) {
    return null
  }

  let translationKey = isHourly ? 'hourlyPay' : 'salary'
  translationKey += increaseConfirmationRequired ? 'Increase' : 'Decrease'
  translationKey += 'Confirmation'
  translationKey += isViewingSelf ? 'Self' : ''

  return t(translationKey, {
    firstName: employeeFirstName
  })
}
