import * as React from 'react'
import { useState } from 'react'
import { useFeature } from '@toasttab/ec-features'
import { ReauthenticationRedirectModal } from '@local/ec-app/reauthentication/ReauthenticationRedirectModal'
import { useReauthStatus } from './useReauthStatus'

type Props = {
  children: React.ReactNode
  onCancel: () => void
}

const ReauthContainer = ({ children, onCancel }: Props) => {
  const url = window.location.href

  const isReauthEnabled = useFeature('ec-hr-reauth-enabled')

  // isExpired will stay up to date while the component is mounted
  const { isExpired } = useReauthStatus()

  // expiredOnMount will evaluate when this component mounts and but remain the same value
  //  even if isExpired becomes true while the user is on the page.
  //
  // in the future, it may be prudent to show a banner or show some other indicator to show
  // that their session is old

  const [expiredOnMount] = useState<boolean>(() => isExpired)

  if (!isReauthEnabled) {
    return <>{children}</>
  }

  return (
    <>
      {children}

      <ReauthenticationRedirectModal
        isOpen={expiredOnMount}
        returnUrl={url}
        onCancel={onCancel}
      />
    </>
  )
}

export default ReauthContainer
