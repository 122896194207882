import * as React from 'react'
import cx from 'classnames'
import { TFunction } from 'i18next'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { formatCurrency, Money } from '@toasttab/buffet-pui-number-utilities'
import {
  ArrowDecreaseIcon,
  ArrowIncreaseIcon,
  IconSize
} from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { AmountWithPayInterval } from '@local/generated/graphql'

export type RateDifferenceProps = {
  /** whether to align the contents to the right, default is false**/
  alignRight?: boolean
  /** icon size, default is xs **/
  iconSize?: IconSize
  /** the new rate to compare to the original rate to show the difference **/
  newRate: AmountWithPayInterval
  /** the original rate to compare to the new rate to show the difference **/
  oldRate?: AmountWithPayInterval | null
  /** the translation function **/
  t: TFunction
  /** the class name to use for styling the text i.e. text-display, or type-caption */
  textClassName?: string
} & TestIdentifiable

/**
 * RateDifference component
 */
export const RateDifference: React.FunctionComponent<RateDifferenceProps> = ({
  alignRight = false,
  iconSize = 'xs',
  newRate,
  oldRate,
  t,
  testId = 'rate-difference',
  textClassName
}) => {
  const { locale } = useBuffetContext()

  if (!oldRate || !newRate) {
    return null
  }

  const notSupported =
    oldRate.amount.currency !== newRate.amount.currency ||
    oldRate.interval !== newRate.interval

  if (notSupported) {
    return (
      <div
        data-testid={testId}
        className={cx(alignRight ? 'text-end' : '', textClassName)}
      >
        <>
          {t(
            'payDifferenceNotSupported',
            'Displaying a pay difference for different currencies or different pay intervals is not supported'
          )}
        </>
      </div>
    )
  }

  const noChange =
    oldRate.amount.currency === newRate.amount.currency &&
    Number(oldRate.amount.amount) === Number(newRate.amount.amount) &&
    oldRate.interval === newRate.interval

  if (noChange) {
    return (
      <div
        data-testid={testId}
        className={cx(alignRight ? 'text-end' : '', textClassName)}
      >
        <>{t('noChange', 'No change')}</>
      </div>
    )
  }

  const payDifference: Money = {
    amount: Math.abs(
      Number(newRate.amount.amount) - Number(oldRate.amount.amount)
    ),
    currency: oldRate.amount.currency
  }

  const isRateDifferencePositive =
    Number(newRate.amount.amount) > Number(oldRate.amount.amount)

  const DirectionIcon = isRateDifferencePositive
    ? ArrowIncreaseIcon
    : ArrowDecreaseIcon

  const className = isRateDifferencePositive ? 'text-success' : 'text-error'

  return (
    <div
      data-testid={testId}
      className={cx('flex items-center break-all justify-end', className)}
    >
      <DirectionIcon
        testId={`rate-difference-${
          isRateDifferencePositive ? 'positive' : 'negative'
        }-icon`}
        aria-label={
          isRateDifferencePositive
            ? t('positive', 'Positive')
            : t('negative', 'Negative')
        }
        size={iconSize}
      />
      <div className={textClassName}>
        <>
          {formatCurrency(payDifference, locale)}/
          {t(`${oldRate.interval}Abbr`, 'pay period')}
        </>
      </div>
    </div>
  )
}
