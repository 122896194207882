export const WorkAuthTypes = {
  us_citizen: {
    label: 'A citizen of the United States',
    value: 'us_citizen'
  },
  non_citizen_national: {
    label: 'A noncitizen national of the United States (See Instructions)',
    value: 'noncitizen_national'
  },
  lawful_permanent_res: {
    label: 'A lawful permanent resident',
    value: 'lawful_permanent_resident'
  },
  alien_auth_to_work: {
    label:
      'An alien authorized to work until (expiration date, if applicable, mm/dd/yyyy):',
    value: 'alien_authorized'
  }
}

export const PreparerOptions = {
  no_preparer: {
    label: 'I did not use a preparer or translator.',
    value: 'no_preparer'
  },
  used_preparer: {
    label:
      'A preparer(s) and/or translator(s) assisted the employee in completing Section 1.',
    value: 'used_preparer'
  }
}

export type NumericalOption = {
  label: string
  value: number
}

export type Option = {
  label: string
  value: string
}

export const Document: Record<string, Option> = {
  alien_reg_num: {
    label: 'Alien Registration Number/USCIS Number',
    value: 'alien_reg_num'
  },
  form_i94_num: {
    label: 'Form I-94 Admissions Number',
    value: 'form_i94_num'
  },
  foreign_passport_num: {
    label: 'Foreign Passport Number',
    value: 'foreign_passport_num'
  }
}

export const getDocLabelByVal = (val: number): string => {
  switch (val) {
    case 1:
      return Document.alien_reg_num.label
    case 2:
      return Document.form_i94_num.label
    case 3:
      return Document.foreign_passport_num.label
    default:
      return ''
  }
}

export const FilingStatuses = {
  filingSeparately: {
    label: 'Single or married filing separately',
    value: 'S'
  },
  filingJointly: {
    label: 'Married filing jointly',
    value: 'M'
  },
  headOfHousehold: {
    label: 'Head of Household',
    value: 'H'
  }
}

export const ExtraWithholdingOptions = {
  irsEstimator: {
    label: 'Use the IRS estimator (takes the longest, most accurate)',
    value: 'irs_estimator'
  },
  multipleJobsWorksheet: {
    label: 'Use the Multiple Jobs Worksheet and enter the result below',
    value: 'multiple_jobs_worksheet'
  },
  twoTotalJobs: {
    label:
      'Use this option if you and a spouse (if exists) are filing for two total jobs of similar pay',
    value: 'two_total_jobs'
  }
}

export const getCountryOptions = () => [
  {
    value: 'Mexico',
    label: 'Mexico'
  },
  {
    value: 'USA',
    label: 'United States'
  }
]

export interface ImportRecord {
  [key: string]: string | number | boolean
}
