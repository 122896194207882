import { EmergencyContact } from './profile/personal/domain/EmergencyContact'

export const mapContacts = (
  emergencyContacts: Array<EmergencyContact>,
  isPrimaryCallback: (
    emergencyContact: EmergencyContact,
    index: number
  ) => boolean = (contact) => contact.isPrimary
): EmergencyContact[] => {
  if (emergencyContacts.length === 0) {
    return emergencyContacts
  } else if (emergencyContacts.length === 1) {
    return [emergencyContacts[0].setPrimary(true)]
  } else {
    return emergencyContacts.map(
      (emergencyContact: EmergencyContact, index: number) =>
        emergencyContact.setPrimary(isPrimaryCallback(emergencyContact, index))
    )
  }
}

export const findPrimaryContactForNotEmptyList = (
  emergencyContacts: EmergencyContact[]
): EmergencyContact | null => {
  return (
    emergencyContacts.find(
      (emergencyContact) => emergencyContact.isPrimary === true
    ) ?? null
  )
}
// These emails are not valid for all intents and purposes.
export const getDisplayEmail = (email: string | null) => {
  if (email == null || email.endsWith('@example.com')) {
    return ''
  }
  return email
}

export const ISO8859_1_SINGLE_BYTE_REGEX = /^[\x20-\x7E\xA0-\xFF]+$/
