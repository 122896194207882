import { create } from 'zustand'
import {
  JobAssignmentsListJobAssignmentFragment,
  JobAssignmentsListSalaryFragment
} from '@local/generated/graphql'

type JobAssignmentsStore = {
  employeeFirstName: string
  setEmployeeFirstName: (value: string) => void
  jobAssignments: JobAssignmentsListJobAssignmentFragment[]
  setJobAssignments: (value: JobAssignmentsListJobAssignmentFragment[]) => void
  salary?: JobAssignmentsListSalaryFragment | null
  setSalary: (value?: JobAssignmentsListSalaryFragment | null) => void
}

const initialState = {
  employeeFirstName: '',
  jobAssignments: [],
  salary: undefined
}

export const useJobAssignmentsStore = create<JobAssignmentsStore>()((set) => ({
  ...initialState,
  setEmployeeFirstName: (value: string) => {
    set({ employeeFirstName: value })
  },
  setJobAssignments: (value: JobAssignmentsListJobAssignmentFragment[]) => {
    set({ jobAssignments: value })
  },
  setSalary: (value?: JobAssignmentsListSalaryFragment | null) => {
    set({ salary: value })
  }
}))
