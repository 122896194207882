import * as React from 'react'
import { useFormikContext } from 'formik'

export const FormAutoSubmit = () => {
  const { values, initialValues, submitForm } = useFormikContext()
  const [latest, setLatest] = React.useState(values)

  const latestHash = JSON.stringify(latest)

  React.useEffect(() => {
    const current = values

    const currentHash = JSON.stringify(current)

    const initialHash = JSON.stringify(initialValues)

    const hasLatestValues = currentHash === latestHash
    const hasInitialValues = currentHash === initialHash

    if (!hasLatestValues) {
      setLatest(current)
    }

    if (!hasLatestValues && !hasInitialValues) {
      submitForm()
    }
  }, [values, initialValues, submitForm, latestHash])

  return null
}
