import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ArrowBackIcon } from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

type LegacyPageBackHeaderProps = {
  url: string
  text: string
} & TestIdentifiable

export const LegacyPageBackHeader = ({
  testId = 'back-header',
  url,
  text
}: LegacyPageBackHeaderProps) => {
  // use window location instead of react router useNavigate
  // to always force refresh when on a legacy page... legacy pages can't
  // be cleaned up and can lead to unexpected behavior.
  const handleClick = () => {
    window.location.assign(url)
  }
  return (
    <div className='py-2'>
      <Button
        data-testid={testId}
        className='text-secondary'
        iconLeft={<ArrowBackIcon />}
        onClick={handleClick}
        variant='link'
      >
        {text}
      </Button>
    </div>
  )
}
