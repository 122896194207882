import {
  Currency,
  EmployeeMappingStatus,
  EmploymentStatusType,
  MappingCondition
} from '@local/generated/graphql'
import { DeepPartial } from '@local/types'

export const TEST_MAPPING_STATUS: DeepPartial<EmployeeMappingStatus> = {
  id: '07a24134-c541-11ec-b66b-129eca3c4799',
  name: {
    first: 'John',
    last: 'Smith',
    formatted: 'John Smith',
    chosen: '',
    middle: ''
  },
  email: 'e@mail.com',
  status: EmploymentStatusType.ACTIVE,
  locations: [
    {
      payrollLocationName: '1. Location not mapped',
      payrollLocationId: { databaseId: 1, sequenceId: 1 },
      mappingStatus: MappingCondition.NOT_MAPPED,
      jobs: [
        {
          payrollId: { databaseId: 11, sequenceId: 11 },
          payrollTitle: 'Need OC help',
          payrollRateOfPay: { amount: 1, currency: Currency.USD },
          mappingStatus: MappingCondition.NOT_MAPPED
        }
      ]
    },
    {
      payrollLocationName: '2. Location mapped, broken ext guid',
      payrollLocationId: { databaseId: 2, sequenceId: 2 },
      mappingStatus: MappingCondition.MAPPED_INVALID_GUID,
      jobs: [
        {
          payrollId: { databaseId: 21, sequenceId: 21 },
          payrollTitle: 'Need OC help',
          payrollRateOfPay: { amount: 1, currency: Currency.USD },
          mappingStatus: MappingCondition.NOT_MAPPED
        }
      ]
    },
    {
      payrollLocationName: '3. Location mapped, no POS counterpart',
      payrollLocationId: { databaseId: 3, sequenceId: 3 },
      mappingStatus: MappingCondition.MAPPED_NO_POS_COUNTERPART,
      jobs: [
        {
          payrollId: { databaseId: 31, sequenceId: 31 },
          payrollTitle: 'Need OC help',
          payrollRateOfPay: { amount: 1, currency: Currency.USD },
          mappingStatus: MappingCondition.NOT_MAPPED
        }
      ]
    },
    {
      payrollLocationName:
        '4. Location mapped (EE not mapped!), Job is not mapped',
      payrollLocationId: { databaseId: 4, sequenceId: 4 },
      mappingStatus: MappingCondition.MAPPED_USER_NOT_MAPPED,
      jobs: [
        {
          payrollId: { databaseId: 41, sequenceId: 41 },
          payrollTitle: 'Need OC help',
          payrollRateOfPay: { amount: 1, currency: Currency.USD },
          mappingStatus: MappingCondition.NOT_MAPPED
        }
      ]
    },
    {
      payrollLocationName:
        '5. Location mapped (EE not mapped!), Job has broken ext guid',
      payrollLocationId: { databaseId: 5, sequenceId: 5 },
      mappingStatus: MappingCondition.MAPPED_USER_NOT_MAPPED,
      jobs: [
        {
          payrollId: { databaseId: 51, sequenceId: 51 },
          payrollTitle: 'Need OC help',
          payrollRateOfPay: { amount: 1, currency: Currency.USD },
          mappingStatus: MappingCondition.MAPPED_INVALID_GUID
        }
      ]
    },
    {
      payrollLocationName:
        '6. Location mapped (EE not mapped!), Job has ext guid, but not found in POS',
      payrollLocationId: { databaseId: 6, sequenceId: 6 },
      mappingStatus: MappingCondition.MAPPED_USER_NOT_MAPPED,
      jobs: [
        {
          payrollId: { databaseId: 61, sequenceId: 61 },
          payrollTitle: 'Need OC help',
          payrollRateOfPay: { amount: 1, currency: Currency.USD },
          mappingStatus: MappingCondition.MAPPED_NO_POS_COUNTERPART
        }
      ]
    },
    {
      payrollLocationName:
        '7. Location mapped, but miss POS counterpart, Job is mapped (EE not mapped!)',
      payrollLocationId: { databaseId: 7, sequenceId: 7 },
      mappingStatus: MappingCondition.MAPPED_NO_POS_COUNTERPART,
      jobs: [
        {
          payrollId: { databaseId: 71, sequenceId: 71 },
          payrollTitle: 'Need OC help',
          payrollRateOfPay: { amount: 1, currency: Currency.USD },
          mappingStatus: MappingCondition.MAPPED_USER_NOT_MAPPED
        }
      ]
    },
    {
      payrollLocationName: '8. Location and job are mapped, EE not mapped',
      payrollLocationId: { databaseId: 8, sequenceId: 8 },
      mappingStatus: MappingCondition.MAPPED_USER_NOT_MAPPED,
      jobs: [
        {
          payrollId: { databaseId: 81, sequenceId: 81 },
          payrollTitle: 'Map me!',
          payrollRateOfPay: { amount: 1, currency: Currency.USD },
          mappingStatus: MappingCondition.MAPPED_USER_NOT_MAPPED
        }
      ]
    },
    {
      payrollLocationName: '9. Location and job are mapped, EE is mapped',
      payrollLocationId: { databaseId: 9, sequenceId: 9 },
      mappingStatus: MappingCondition.MAPPED_USER_MAPPED,
      posRestaurantUserGuid: '...',
      posUserName: {
        first: 'Joe',
        last: 'Miller',
        formatted: 'Joe Miller',
        chosen: '',
        middle: ''
      },
      posEmail: 'pos@email',
      posMostRecentClockInDate: '02/02/2023',
      jobs: [
        {
          payrollId: { databaseId: 91, sequenceId: 91 },
          payrollTitle: 'All good',
          payrollRateOfPay: { amount: 1, currency: Currency.USD },
          mappingStatus: MappingCondition.MAPPED_USER_MAPPED,
          posTitle: 'All good',
          posRateOfPay: { amount: 10.0, currency: Currency.USD }
        }
      ]
    },
    {
      payrollLocationName:
        '10. Location and job are mapped, EE mapping ignored',
      payrollLocationId: { databaseId: 10, sequenceId: 10 },
      mappingStatus: MappingCondition.MAPPED_USER_MAPPING_IGNORED,
      jobs: [
        {
          payrollId: { databaseId: 101, sequenceId: 101 },
          payrollTitle: 'HR can fix if they want',
          payrollRateOfPay: { amount: 1, currency: Currency.USD },
          mappingStatus: MappingCondition.MAPPED_USER_MAPPING_IGNORED
        }
      ]
    },
    // { require separate EmployeeMappingStatus root object to fully model this situation
    // This is basically the same as '8. Location and job are mapped, EE not mapped'
    // + 'dangling mapping' which is described via 'issues' section
    //   payrollLocationName: '11. (hard!) Location and job are mapped, EE has mapping that doesnt match with any externalId for the given company',
    //   payrollLocationId: {databaseId: 11, sequenceId: 11},
    //   mappingStatus: MappingCondition.MAPPED_USER_NOT_MAPPED,
    //   jobs: [{
    //     payrollId: {databaseId: 111, sequenceId: 111},
    //     payrollTitle: 'HR can fix if they want',
    //     payrollRateOfPay: { amount: 1, currency: Currency.USD },
    //     mappingStatus: MappingCondition.MAPPED_NO_POS_COUNTERPART,
    //   }]
    // },
    // { require new MappingCondition - MAPPED_USER_MAPPED_NO_POS_COUNTERPART TODO: ELM-441
    //   payrollLocationName: '12. (hard!) Location and job are mapped, EE is mapped, but can not find POS RU by pem#restaurantUserGuid',
    //   payrollLocationId: {databaseId: 12, sequenceId: 12},
    //   mappingStatus: MappingCondition.MAPPED_NO_POS_COUNTERPART,
    //   jobs: [{
    //     payrollId: {databaseId: 121, sequenceId: 121},
    //     payrollTitle: 'HR can fix if they want',
    //     payrollRateOfPay: { amount: 1, currency: Currency.USD },
    //     mappingStatus: MappingCondition.MAPPED_NO_POS_COUNTERPART,
    //   }]
    // },
    {
      payrollLocationName:
        '13. Location and job are mapped, EE is mapped, Payroll and POS jobs not fully match',
      payrollLocationId: { databaseId: 13, sequenceId: 13 },
      mappingStatus: MappingCondition.MAPPED_USER_MAPPED,
      posRestaurantUserGuid: '...',
      posUserName: {
        first: 'Joe',
        last: 'Miller',
        formatted: 'Joe Miller',
        chosen: '',
        middle: ''
      },
      posEmail: 'pos@email',
      posMostRecentClockInDate: '02/02/2023',
      jobs: [
        {
          payrollId: { databaseId: 131, sequenceId: 131 },
          payrollTitle: 'All good',
          payrollRateOfPay: { amount: 10, currency: Currency.USD },
          mappingStatus: MappingCondition.MAPPED_USER_MAPPED,
          posTitle: 'All good',
          posRateOfPay: { amount: 10.0, currency: Currency.USD }
        },
        {
          payrollId: { databaseId: 132, sequenceId: 132 },
          payrollTitle: 'Payroll EE has this job assigned, POS EE has no',
          payrollRateOfPay: { amount: 10, currency: Currency.USD },
          mappingStatus: MappingCondition.MAPPED_USER_NOT_MAPPED
        },
        {
          mappingStatus: MappingCondition.POS_ONLY,
          posTitle: 'POS EE has this job assigned, Payroll has no',
          posRateOfPay: { amount: 10.0, currency: Currency.USD }
        }
      ]
    },
    // { TODO: ELM-442
    //   payrollLocationName: '14. (not ready) Location and job are mapped, EE is mapped. Payroll EE is active, POS is archived',
    //   payrollLocationId: {databaseId: 14, sequenceId: 14},
    //   mappingStatus: MappingCondition.MAPPED_USER_MAPPED,
    //   posRestaurantUserGuid: '...',
    //   posUserName: { first: "Joe", last: "Miller", formatted: 'Joe Miller', chosen: '', middle: '' },
    //   posEmail: "pos@email",
    //   posMostRecentClockInDate: '02/02/2023',
    //   // posStatus: 'Archived',
    //   jobs: [{
    //     payrollId: {databaseId: 141, sequenceId: 141},
    //     payrollTitle: 'All good',
    //     payrollRateOfPay: { amount: 1, currency: Currency.USD },
    //     mappingStatus: MappingCondition.MAPPED_USER_MAPPED,
    //     posTitle: 'All good',
    //     posRateOfPay: { amount: 10.0, currency: Currency.USD }
    //   }]
    // },
    {
      mappingStatus: MappingCondition.POS_ONLY,
      // posLocationName: '15. There are some unmapped RUs sharing same TU guid',
      posRestaurantUserGuid: '...',
      posUserName: {
        first: 'Joe',
        last: 'Miller',
        formatted: 'Joe Miller',
        chosen: '',
        middle: ''
      },
      posEmail: 'pos@email',
      posMostRecentClockInDate: '02/02/2023',
      jobs: [
        {
          mappingStatus: MappingCondition.POS_ONLY,
          posTitle:
            '15. (not fully implemented) POS account not mapped to Payroll TODO: ELM-434',
          posRateOfPay: { amount: 10.0, currency: Currency.USD }
        }
      ]
    },
    {
      payrollLocationName:
        '16. Loc, job, EE are mapped. EE is Owner, no POS wage',
      payrollLocationId: { databaseId: 16, sequenceId: 16 },
      mappingStatus: MappingCondition.MAPPED_USER_MAPPED,
      posRestaurantUserGuid: '...',
      posUserName: {
        first: 'Jack',
        last: 'Black',
        formatted: 'Jack Black',
        chosen: '',
        middle: ''
      },
      posEmail: 'owner@rest.com',
      posMostRecentClockInDate: '02/02/2023',
      jobs: [
        {
          payrollId: { databaseId: 161, sequenceId: 161 },
          payrollTitle: 'Owner',
          payrollRateOfPay: { amount: 1, currency: Currency.USD },
          mappingStatus: MappingCondition.MAPPED_USER_MAPPED,
          posTitle: 'Owner (no wage!)',
          posRateOfPay: null
        }
      ]
    }
  ],
  issues: []
}
