import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useFeature } from '@toasttab/ec-features'
import { queryAs } from '@local/shared-services'
import { useCurrentUser } from '@local/ec-app'
import { USER_EMAIL_CHANGED, useTopicPublisher } from '@local/publisher'
import { ChangeDetails, ChangePassword } from './Domain'
import { useCompanyCode, useEmployeeId, useUpdateOverview } from '../../hooks'
import { useApi } from '../../../ApiProvider'

const ACCOUNT_USER_QUERY_KEY = 'accountUser'

export const useActorCanResetPassword = () => {
  const isAuth0AccountDetailsEnabled = useFeature(
    'ec-hr-auth0-account-details',
    true
  )
  const user = useCurrentUser()

  return (
    isAuth0AccountDetailsEnabled &&
    (user.isPureUserHRPlusOrAbove ||
      user.isUserHrPlusOrDerivative ||
      user.isUserManager ||
      user.isUserSuperAdmin ||
      user.isUserSuperAdminLight)
  )
}

export const useActorCanModifyEmail = () => {
  const actor = useCurrentUser()
  const { accountUser } = useAccountUserQuery()

  const isViewingSelf = actor.userUuid === accountUser.id
  const isActorAdmin = actor.isUserSuperAdmin || actor.isUserSuperAdminLight
  const isActorHrPlus =
    actor.isPureUserHRPlusOrAbove || actor.isUserHrPlusOrDerivative

  return (
    isViewingSelf ||
    isActorAdmin ||
    (isActorHrPlus && !accountUser.isEmailVerified)
  )
}

export const useInvalidateAccountUser = () => {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries(ACCOUNT_USER_QUERY_KEY)
}

export const useAccountUserQuery = () => {
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()
  const api = useApi()

  return queryAs(
    'accountUser',
    useQuery([ACCOUNT_USER_QUERY_KEY, companyCode, employeeId], () =>
      api.findUserByCompanyCodeAndEmployeeUuid(companyCode, employeeId)
    )
  )
}

export const useSendWelcomeEmailMutation = () => {
  const api = useApi()
  const companyCode = useCompanyCode()
  const { accountUser } = useAccountUserQuery()

  return useMutation(() => api.sendWelcomeEmailHr(companyCode, accountUser.id))
}

export const useAccountChangeDetailsMutation = () => {
  const companyCode = useCompanyCode()
  const updateOverview = useUpdateOverview()
  const invalidateAccountUser = useInvalidateAccountUser()
  const api = useApi()
  const topicPublisher = useTopicPublisher()

  return useMutation(
    (command: ChangeDetails) =>
      api
        .changeUsernameAndEmail(
          companyCode,
          command.userUuid,
          command.username,
          command.email
        )
        .then(() => updateOverview({ emailAddress: command.email }))
        .then(() =>
          topicPublisher.publish(USER_EMAIL_CHANGED, {
            userUuid: command.userUuid,
            email: command.email
          })
        ),
    {
      onSuccess: () => invalidateAccountUser()
    }
  )
}

export const useAccountChangePasswordMutation = () => {
  const companyCode = useCompanyCode()
  const currentUser = useCurrentUser()
  const invalidateAccountUser = useInvalidateAccountUser()
  const api = useApi()

  return useMutation(
    (command: ChangePassword) =>
      api.changePassword(companyCode, currentUser.userUuid, command.password),
    {
      onSuccess: () => invalidateAccountUser()
    }
  )
}

export const useAccountResetPasswordMutation = () => {
  const companyCode = useCompanyCode()
  const api = useApi()

  return useMutation((employeeEmailAddress: string) =>
    api.resetPassword(companyCode, employeeEmailAddress)
  )
}
