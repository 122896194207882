import * as React from 'react'
import { useFormikContext } from 'formik'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { PayPeriodCalendar } from '@local/generated/graphql'
import {
  DatePickerFieldHighlightedDates,
  HighlightedDatesSet
} from '@local/date-picker-field-highlighted-dates'
import { trackPayChange } from '@local/track'
import {
  formatDateInputValue,
  getFormattedPaycheckDate,
  getLastValidChangePayDate,
  getNextPaycheckDate,
  parseSortedPayPeriods
} from '../../utils'
import { PayChangeSchema } from '../../domain'
import { useIsViewingSelf } from '../../../../../../hooks'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'

type PayChangeDatePickerFieldProps = {
  employeeFirstName: string
  employmentStatus?: string
  sortedPayPeriods: PayPeriodCalendar[]
}

export const PayChangeDatePickerField: React.FunctionComponent<
  PayChangeDatePickerFieldProps
> = ({ employeeFirstName, employmentStatus, sortedPayPeriods }) => {
  const { t } = useTranslation('employees')
  const { locale } = useBuffetContext()
  const isViewingSelf = useIsViewingSelf()
  const [disabled, setDisabled] = React.useState<boolean>(true)
  const [helperText, setHelperText] = React.useState<string | undefined>(
    getHelperText(
      employmentStatus,
      t,
      locale,
      isViewingSelf,
      employeeFirstName,
      new Date(),
      sortedPayPeriods
    )
  )

  const { payPeriodStartDates, paycheckDates } =
    parseSortedPayPeriods(sortedPayPeriods)

  const firstValidChangePayDate = payPeriodStartDates.at(0)
  const lastValidChangePayDate =
    employmentStatus === 'TerminatedEmploymentStatus'
      ? new Date()
      : getLastValidChangePayDate(payPeriodStartDates)

  const highlightedDateSets = formatHighlightedDateSets(
    t,
    payPeriodStartDates,
    paycheckDates
  )

  const { initialValues, values } = useFormikContext<PayChangeSchema>()
  React.useEffect(() => {
    setDisabled(Number(initialValues.rate) === Number(values.rate))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.rate])

  const onChange = (input: Date | undefined) => {
    if (!!input) {
      setHelperText(
        getHelperText(
          employmentStatus,
          t,
          locale,
          isViewingSelf,
          employeeFirstName,
          input,
          sortedPayPeriods
        )
      )
    }
  }

  return (
    <DatePickerFieldHighlightedDates
      {...trackPayChange('date')}
      testId='pay-change-modal-date-picker'
      currentDayLabel={t('today')}
      disabled={disabled}
      formatValue={(date?: Date) => formatDateInputValue(t, locale, date)}
      fromDate={firstValidChangePayDate}
      helperText={helperText}
      highlightedDatesSets={highlightedDateSets}
      label={t('whenShouldPayBeApplied')}
      name={'effectiveDate'}
      onChange={onChange}
      placeholder={t('today')}
      showOutsideDays
      toDate={lastValidChangePayDate}
      value={values.effectiveDate}
    />
  )
}

export const getHelperText = (
  employmentStatus: string | undefined,
  t: TFunction,
  language: string,
  isViewingSelf: boolean,
  employeeFirstName: string,
  effectiveDate: Date,
  payPeriods: PayPeriodCalendar[]
): string | undefined => {
  if (employmentStatus === 'TerminatedEmploymentStatus') {
    return undefined
  }
  const nextPaycheckDate = getNextPaycheckDate(effectiveDate, payPeriods)

  if (nextPaycheckDate) {
    const formattedNextPaycheckDate = getFormattedPaycheckDate(
      nextPaycheckDate,
      language
    )
    return isViewingSelf
      ? t('changePayCalendarHelperTextSelf', {
          nextPaycheckDate: formattedNextPaycheckDate
        })
      : t('changePayCalendarHelperText', {
          firstName: employeeFirstName,
          nextPaycheckDate: formattedNextPaycheckDate
        })
  }
  return undefined
}

export const formatHighlightedDateSets = (
  t: TFunction,
  payPeriodStartDates: Date[],
  paycheckDates: Date[]
): HighlightedDatesSet[] | undefined => {
  const returnData = paycheckDates.length || payPeriodStartDates.length
  return returnData
    ? ([
        {
          label: t('payPeriodStartDate'),
          highlightedDates: payPeriodStartDates,
          variant: 'dash'
        },
        {
          label: t('payday'),
          highlightedDates: paycheckDates,
          variant: 'solid'
        }
      ] as HighlightedDatesSet[])
    : undefined
}
