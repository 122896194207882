import { GENERIC_PAYROLL_CONTENT_TYPE } from '../employee/onboarding/shared/apiContentTypes'

type EsxWebGenericResponse = {
  errorCode: string
  guid: string
  hasError: boolean
  hasWarning: boolean
  id: number
  isStatic: false
  operationType: number
  redirectURL: string
  url: string
  wMessage: string
  warningMessage: string
}

type Args = {
  url: string
  method?: 'GET'
  headers?: Record<string, string>
}
async function bareApiCall<T = any>(args: Args) {
  const { url, headers } = args

  const fetchResp = await fetch(url, { headers })

  const json = (await fetchResp.json()) as T

  return json
}

async function genericPayrollApiCall<T = any>(args: Args) {
  const { headers, ...restArgs } = args
  return bareApiCall<T>({
    ...restArgs,
    method: 'GET',
    headers: {
      Accept: GENERIC_PAYROLL_CONTENT_TYPE,
      ...headers
    }
  })
}

const makeBareApi = () => {
  return {
    getNewHireNextStepLink: (companyCode: string, employeeUuid: string) => {
      return bareApiCall<string>({
        url: `/mvc/${companyCode}/Employee/GetNextStepLink?employeeUuid=${employeeUuid}`
      })
    },

    getStatusChangeUrl: () => {
      return genericPayrollApiCall<EsxWebGenericResponse>({
        url: 'status-change'
      })
    },
    getEditPositionDetails: () => {
      return genericPayrollApiCall<EsxWebGenericResponse>({
        url: 'edit-position-details'
      })
    },
    getJobAssignmentUrl: (jobUuid: string) => {
      return genericPayrollApiCall<EsxWebGenericResponse>({
        url: `${jobUuid}/job-assignment-url`
      })
    },
    getAddJobUrl: () => {
      return genericPayrollApiCall<EsxWebGenericResponse>({
        url: 'add-job-url'
      })
    },
    getChangePayUrl: (jobUuid: string) => {
      return genericPayrollApiCall<EsxWebGenericResponse>({
        url: `${jobUuid}/change-pay-url`
      })
    },
    getPayChangeHistoryUrl: () => {
      return genericPayrollApiCall<EsxWebGenericResponse>({
        url: 'pay-change-history-url'
      })
    }
  }
}

export default makeBareApi
