import { ApolloLink, Observable } from '@apollo/client'

import { OperationError } from './OperationError'

/**
 * Wraps any errors with the operation so we can
 * reference it within error boundaries to provide specific
 * handling based on the operation.
 */
export const operationErrorLink = ({
  errorOnPartial = true
}: {
  // Whether to error on partial errors.
  errorOnPartial?: boolean
} = {}) =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) =>
        forward(operation).subscribe({
          next: (result) => {
            if (result.errors && errorOnPartial) {
              observer.error(
                new OperationError({
                  operation,
                  result,
                  query: operation.query
                })
              )
            } else {
              observer.next(result)
            }
          },
          error: (error) =>
            observer.error(
              new OperationError({
                operation,
                originalError: error,
                query: operation.query,
                isNetworkError: true
              })
            ),
          complete: () => observer.complete()
        })
      )
  )
