import * as React from 'react'
import { useField } from 'formik'
import { CardSelectorGroup } from '@toasttab/buffet-pui-card-selector'
import { HelperText } from '@toasttab/buffet-pui-text-base'

type Option = {
  label: string
  value: string
  helpText?: string
  disabled?: boolean
}

type BaseProps = Omit<
  React.ComponentProps<typeof CardSelectorGroup>,
  'options' | 'onChange'
>

type Props = BaseProps & {
  fieldName: string
  onChange?: (date: string) => void
  options: Option[]
  modifiedHelperText?: string
}

const OptionsSelector = (props: Props) => {
  const {
    options,
    fieldName,
    testId,
    errorText,
    invalid,
    name,
    label,
    onChange,
    defaultValue,
    modifiedHelperText,
    ...rest
  } = props

  const [, meta, helpers] = useField(fieldName || '')

  const { value } = meta

  const formattedOptions = options.map((option) => {
    return {
      value: option.value,
      contents: option.helpText ? option.helpText : '',
      title: option.label,
      checked: String(value) === option.value,
      disabled: option.disabled
    }
  })

  const helperTextClasses =
    modifiedHelperText &&
    options.filter((o) => o.disabled && o.value === 'true').length > 0
      ? 'lg:w-80 float-right lg:pl-6'
      : 'lg:w-80 float-left'

  return (
    <div className={'mb-4 lg:mb-6'}>
      <CardSelectorGroup
        {...rest}
        label={label}
        itemsContainerClassName={'w-full lg:grid-cols-2 lg:gap-6 lg:max-w-2xl'}
        options={formattedOptions}
        testId={testId || fieldName}
        name={name || fieldName || ''}
        defaultValue={defaultValue || value}
        onChange={(event) => {
          const newValue = event.target.value
          if (fieldName) helpers.setValue(newValue === 'true')
          if (onChange) onChange(newValue)
        }}
      />
      <div className={helperTextClasses}>
        <HelperText
          testId={`${testId}-helper-text`}
          helperText={modifiedHelperText}
        />
      </div>
    </div>
  )
}
export default OptionsSelector
