import * as React from 'react'
import {
  ErrorBoundaryGroupPublisher,
  ErrorBoundaryGroupSubscription,
  ResetGroupErrorBoundaries
} from './ErrorBoundaryGroup'

export interface GeneralErrorBoundaryContext {
  /**
   * Resets the error boundary state.
   */
  reset: () => void
  /**
   * Rethrows the caught error for a parent error boundary to handle.
   */
  rethrow: () => void
}

type HandlerAction = {
  /**
   * A render function to render error content.
   */
  render?: (resetGroup: () => void) => React.ReactNode
}
type Handler<TError extends Error = Error> = (
  error: TError,
  context: GeneralErrorBoundaryContext
) => HandlerAction | void

export interface GeneralErrorBoundaryProps<
  TKey = string,
  TError extends Error = Error
> extends React.PropsWithChildren {
  /**
   * A map of handlers for specific errors.
   */
  handlers?: Map<TKey, Handler<TError>>
  /**
   * Takes an error and returns a key that will look up the handler in the handler map.
   */
  toKey?: (error: TError) => TKey
  /**
   * Default handler if no handler is found in the handler map or no
   * handler map exists.
   */
  handler?: Handler<TError>
  /**
   * A fallback to UI to render on a caught error.
   * `handler` or `handlers` takes precedence.
   * If a function is given, a context object is provided as the arguments.
   */
  fallback?:
    | React.ReactNode
    | ((
        error: TError,
        context: GeneralErrorBoundaryContext & { resetGroup: () => void }
      ) => React.ReactNode)
  /**
   * Called on every error that is caught.
   * This is meant for side effects.
   */
  onError?: (error: TError) => void
}

/**
 * General error boundary that can do the following:
 *
 * - Define error behavior on an error by error basis.
 * - Universal handler for errors that are not handled.
 * - Fallback render logic with an api to reset or rethrow an error.
 * - Side effects for all errors that get caught.
 */
export class GeneralErrorBoundary<
  TKey = string,
  TError extends Error = Error
> extends React.Component<
  GeneralErrorBoundaryProps<TKey, TError>,
  { currentAction: HandlerAction | null }
> {
  constructor(props: GeneralErrorBoundaryProps<TKey, TError>) {
    super(props)
    this.state = { currentAction: null }
    this.onGroupReset = this.onGroupReset.bind(this)
  }

  reset() {
    this.setState({ currentAction: null })
  }

  componentDidCatch(error: TError) {
    const handler =
      this.props.handlers?.get(
        this.props.toKey?.(error) ?? (error.name as any)
      ) ??
      this.props.handler ??
      null
    const context: GeneralErrorBoundaryContext = {
      reset: () => this.reset(),
      rethrow: () => {
        throw error
      }
    }

    this.props.onError?.(error) //eslint-disable-line no-unused-expressions

    if (handler) {
      const action = handler(error, context)

      this.setState({ currentAction: action || null })
    } else if (this.props.fallback) {
      this.setState({
        currentAction: {
          render:
            typeof this.props.fallback === 'function'
              ? (resetGroup) =>
                  (this.props.fallback as Function)(error, {
                    ...context,
                    resetGroup
                  })
              : () => <>{this.props.fallback}</>
        }
      })
    } else {
      throw error
    }
  }

  render() {
    if (this.state.currentAction?.render) {
      return (
        <>
          <ErrorBoundaryGroupSubscription
            event={ResetGroupErrorBoundaries}
            handler={this.onGroupReset}
          />
          <ErrorBoundaryGroupPublisher>
            {(publish) =>
              this.state.currentAction!.render!(() => {
                this.reset()
                publish(ResetGroupErrorBoundaries, undefined)
              })
            }
          </ErrorBoundaryGroupPublisher>
        </>
      )
    }

    return this.props.children
  }

  private onGroupReset() {
    this.reset()
  }
}
