import * as React from 'react'
import {
  EmployeeAtLocation,
  useGetEmployeeMappingStatusQuery
} from '@local/generated/graphql'
import { useEmployeeId } from '../hooks'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'

/**
 * For each mapped location assigned to an employee, we want to alert on if:
 * 1. Employee is not mapped to a profile for that location
 * 2. They are assigned to an unmapped job
 * 3. They are mapped to an unmapped POS profile
 */
export const hasMappingIssue = (location: EmployeeAtLocation) => {
  // such locations are not displayed at all, so not an issue
  if (location.mappingStatus === 'POS_ONLY') return false

  // NOT_MAPPED or MAPPED_INVALID_GUID or MAPPED_NO_POS_COUNTERPART
  if (location.posLocationGuid === null) return false

  if (location.mappingStatus === 'MAPPED_USER_MAPPING_IGNORED') return false

  // location.posRestaurantUserGuid === null
  if (location.mappingStatus === 'MAPPED_USER_NOT_MAPPED') return true

  // going forward mappingStatus MAPPED_USER_MAPPED

  if (location.posStatus === 'Archived') return true

  // there is a Payroll job mapped to POS, user has this job, but POS counterpart hasn't this job
  return location.jobs.some(
    (job) =>
      job.mappingStatus === 'MAPPED_USER_NOT_MAPPED' ||
      job.mappingStatus === 'NOT_MAPPED'
  )
}

export const MappingStatusAlert: React.FunctionComponent = () => {
  const employeeId = useEmployeeId()

  const { data } = useGetEmployeeMappingStatusQuery({
    variables: { employeeUuid: employeeId }
  })

  const hasMappingAlert = data?.getEmployeeMappingStatus.locations.some(
    (location) => hasMappingIssue(location as EmployeeAtLocation)
  )

  return (
    <div data-testid='mapping-alert'>
      {hasMappingAlert ? (
        <WarningOutlineIcon
          size='sm'
          testId='mapping-alert-warning'
          className='text-error'
        />
      ) : (
        <></>
      )}
    </div>
  )
}
