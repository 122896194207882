import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { debounce, throttle, stubTrue, eq } from 'lodash'

export interface DynamicPortalProps {
  /**
   * Container to query for the element.
   */
  container: Pick<Document, 'querySelectorAll'>
  /**
   * The selector to query for the element.
   */
  selector: Parameters<HTMLElement['querySelectorAll']>[0]
  /**
   * Optional condition for the elements found. This will take the first one
   * that passes the predicate.
   */
  predicate?: (element: HTMLElement) => boolean
  /**
   * Debounce time for the update on render.
   */
  debounceTime?: number
  /**
   * Compares the found element with the existing to see if it is different.
   */
  comparer?: (a: HTMLElement | null, b: HTMLElement | null) => boolean
}

export const DynamicPortalsUpdaterContext = React.createContext<{
  registerUpdate: (fn: () => void) => void
  update: () => void
}>({
  registerUpdate: () => {},
  update: () => {}
})

/**
 * Provides an api for updating DynamicPortals of it's children.
 * Useful when needing to have a portal update when a menu is closed
 * or an elemnt appears conditionally.
 */
export const DynamicPortalsUpdaterProvider = ({
  children,
  container = window,
  updateOnResize = true,
  resizeDebounceTime = 250
}: React.PropsWithChildren<{
  container?: Pick<Window, 'addEventListener' | 'removeEventListener'>
  updateOnResize?: boolean
  resizeDebounceTime?: number
}>) => {
  const callbacks = React.useMemo(() => [] as Array<() => void>, [])

  const provider = React.useMemo(
    () => ({
      registerUpdate: (fn: () => void) => {
        callbacks.push(fn)

        return () => callbacks.splice(callbacks.indexOf(fn), 1)
      },
      // Only let this be called once per loop.
      update: debounce(() => callbacks.forEach((cb) => cb()), 0)
    }),
    [callbacks]
  )

  // Update when the window is resized.
  // Elements may become visible/invisible based on media queries.
  React.useEffect(() => {
    if (updateOnResize) {
      const handler = throttle(() => provider.update(), resizeDebounceTime)

      container.addEventListener('resize', handler)

      return () => {
        handler.cancel()
        container.removeEventListener('resize', handler)
      }
    }
  }, [updateOnResize, container, provider, resizeDebounceTime])

  return (
    <DynamicPortalsUpdaterContext.Provider value={provider}>
      {children}
    </DynamicPortalsUpdaterContext.Provider>
  )
}

export const useDynamicPortalsUpdater = () =>
  React.useContext(DynamicPortalsUpdaterContext).update

/**
 * Creates a portal that will constantly update to the element matching the selector.
 */
export const DynamicPortal = ({
  children,
  container,
  selector,
  debounceTime = 10,
  predicate = stubTrue,
  comparer = eq
}: React.PropsWithChildren<DynamicPortalProps>) => {
  const updater = React.useContext(DynamicPortalsUpdaterContext)
  const [element, setElement] = React.useState<HTMLElement | null>(null)
  const handler = React.useCallback(
    (_element: HTMLElement | null) => {
      const results = Array.from(
        container.querySelectorAll(selector)
      ) as HTMLElement[]

      const result =
        results?.length === 1
          ? results[0]
          : results.find((el) => predicate(el)) ?? null

      if (!comparer(result, _element)) {
        setElement(result)
      }
    },
    [comparer, setElement, container, predicate, selector]
  )

  // Debouncing this just by about 10ms makes this not visually noticable, but
  // cuts down the times we query the DOM by about 50%.
  const debouncedHandler = React.useMemo(
    () =>
      debounce(
        (_element: HTMLElement | null) => handler(_element),
        debounceTime
      ),
    [debounceTime, handler]
  )

  React.useEffect(
    () => updater.registerUpdate(() => handler(element)),
    [element, updater, handler]
  )

  React.useEffect(() => {
    debouncedHandler(element)

    return () => debouncedHandler.cancel()
  })

  return element ? ReactDOM.createPortal(children, element) : null
}

const isElementVisible = (element: HTMLElement) => Boolean(element.offsetParent)

/**
 * Creates a portal that will portal to the first visible element that matches the selector.
 */
export const DynamicVisibilityPortal = ({
  children,
  ...props
}: React.PropsWithChildren<Omit<DynamicPortalProps, 'predicate'>>) => (
  <DynamicPortal predicate={isElementVisible} {...props}>
    {children}
  </DynamicPortal>
)
