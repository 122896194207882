import * as React from 'react'

export function useScrollLock(isOpen: boolean) {
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'

      return () => {
        document.body.style.overflow = ''
      }
    }
  }, [isOpen])
}
