import { useQuery, UseQueryOptions } from 'react-query'
import { queryAs } from '@local/shared-services'
import { useApi } from '../../ApiProvider'
import { DocumentDetail } from './domain'

export const useEmployeeDocument = (
  companyCode: string,
  employeeId: string,
  docId: number,
  options: Omit<UseQueryOptions<DocumentDetail>, 'queryKey' | 'queryFn'> = {}
) => {
  const api = useApi()

  return queryAs(
    'document',
    useQuery(
      ['employee-document', companyCode, employeeId, docId],
      () => api.getEmployeeDocument(companyCode, employeeId, docId),
      options
    )
  )
}
