import {
  makePublisherAndSubscriber,
  PublisherEvent
} from '../PublisherAndSubscriber'

export const ResetGroupErrorBoundaries = new PublisherEvent<void>(
  'resetGroupErrorBoundaries'
)

export const {
  Provider: ErrorBoundaryGroup,
  Subscriber: ErrorBoundaryGroupSubscription,
  useSubscription: useErrorBoundaryGroupSubscription,
  Publisher: ErrorBoundaryGroupPublisher
} = makePublisherAndSubscriber()
