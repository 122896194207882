import * as yup from 'yup'
import { DateTime } from 'luxon'
import { toDate } from './formHelpers'
import { string, StringSchema } from 'yup'

export const isoDate = (message?: string) =>
  yup
    .mixed<DateTime | null>()
    .transform(function (value): DateTime | null {
      if (value instanceof DateTime) {
        return value
      } else if (value) {
        const trimmedValue = value.toString().trim()
        return DateTime.fromISO(trimmedValue)
      } else {
        return null
      }
    })
    .test(
      'isValidDate',
      message || 'Please enter a valid ISO date',
      function (value: DateTime | null): value is DateTime | null {
        return value instanceof DateTime ? value.isValid : true
      }
    )

export const strippedString = yup
  .string()
  .ensure()
  .stripToNull()
  .nullable()
  .defined()

export const formDate = (message?: string) =>
  yup
    .mixed<DateTime | null | undefined>()
    .transform(function (value: DateTime | string | null): DateTime | null {
      return toDate(value)
    })
    .test(
      'isValidDate',
      message || 'Please enter a date in the format mm/dd/yyyy',
      function (value: DateTime | null | undefined): value is DateTime {
        return value instanceof DateTime ? value.isValid : true
      }
    )

export const futureDate = (message?: string) =>
  formDate(message)
    .test(
      'futureDate',
      'Expiration date must be in the future',
      function (date: DateTime | null): date is DateTime {
        return date ? date.diffNow('days').days > 0 : true
      }
    )
    .required('Expiration date is required')

yup.addMethod<StringSchema>(string, 'isEnumOf', function isEnumOf<
  T
>(this: StringSchema, enumObj: { [s: string]: T } | ArrayLike<T>) {
  return this.transform(() => {
    yup.mixed<T>().oneOf(Object.values(enumObj))
  })
})
