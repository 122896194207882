import * as React from 'react'
import cx from 'classnames'
import { DashboardCard, DashboardCardProps } from '@toasttab/buffet-pui-card'
import { ChevronRightIcon, IconProps } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { TrackId } from '@local/track'

export interface OverviewCardProps extends DashboardCardProps {
  'data-toast-track-id'?: TrackId
  onActionClick?: () => void
  iconType?: React.FunctionComponentElement<IconProps>
}

export const OverviewCard = ({
  onActionClick,
  cardAction: propCardAction,
  children,
  iconType = (
    <ChevronRightIcon
      className='text-secondary !p-1'
      data-testid='overview-card-edit'
    />
  ),
  className,
  'data-toast-track-id': dataToastTrackId,
  ...props
}: React.PropsWithChildren<OverviewCardProps>) => {
  // This can be overwritten if need be...
  // There is one use case where we don't use a default chevron...
  const cardAction =
    propCardAction ??
    (onActionClick ? (
      <IconButton
        data-toast-track-id={dataToastTrackId}
        className='-m-3 !p-0'
        icon={iconType}
        onClick={() => onActionClick()}
        textClassName='text-secondary'
      />
    ) : undefined)

  return (
    <DashboardCard
      {...props}
      className={cx('h-full bg-white', className)}
      cardAction={cardAction}
    >
      {children}
    </DashboardCard>
  )
}
