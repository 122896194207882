import React from 'react'
import {
  CheckCircleSelectedIcon,
  CheckCircleUnselectedIcon
} from '@toasttab/buffet-pui-icons'
import cx from 'classnames'
import * as yup from 'yup'

export class FieldRule {
  private constructor(
    readonly label: string,
    readonly isMandatory: boolean,
    readonly condition: (value: string) => boolean
  ) {}

  static schema = yup
    .object({
      label: yup.string().required(),
      isMandatory: yup.boolean().required(),
      condition: yup
        .mixed<(value: string) => boolean>()
        .test(
          'is-predicate',
          'Must be a predicate',
          (value: any) => typeof value === 'function' && value.length === 1
        )
        .defined()
    })
    .defined()

  static copyOf(obj: FieldRule): FieldRule {
    return new FieldRule(obj.label, obj.isMandatory, obj.condition)
  }

  static of(obj: any): FieldRule {
    return FieldRule.copyOf(
      FieldRule.schema.validateSync(obj, { abortEarly: false })
    )
  }
}

export interface FieldRequirementsProps {
  value: string
  rules: Array<FieldRule>
  containerClassName?: string
  itemClassName?: string
  testId?: string
}

class RuleResult {
  constructor(readonly rule: FieldRule, readonly success: boolean) {}
}

class UseFieldRequirementsResult {
  constructor(
    readonly isValid: boolean,
    readonly maxComplexity: number,
    readonly neededComplexity: number,
    readonly additionalComplexity: number,
    readonly results: Array<RuleResult>
  ) {}
}

export const useFieldRequirements = (
  value: string,
  rules: Array<FieldRule>
): UseFieldRequirementsResult => {
  const results = rules.map(
    (rule) => new RuleResult(rule, rule.condition(value))
  )

  const isValid = results
    .filter((rule) => rule.rule.isMandatory)
    .every((rule) => rule.success)

  const maxComplexity = rules.length

  const neededComplexity = results.filter(
    (result) => result.rule.isMandatory && result.success
  ).length

  const additionalComplexity = results.filter(
    (result) => !result.rule.isMandatory && result.success
  ).length

  return new UseFieldRequirementsResult(
    isValid,
    maxComplexity,
    neededComplexity,
    additionalComplexity,
    results
  )
}

/**
 * Displays the rules for a field and whether they have been passed or not.
 * @param {Props} props Props for FieldRequirements component.
 */
export const FieldRequirements = ({
  value,
  rules,
  containerClassName,
  itemClassName,
  testId = 'FieldRequirements'
}: FieldRequirementsProps) => {
  const { results } = useFieldRequirements(value, rules)
  return (
    <ul
      data-testid={`${testId}-list`}
      className={cx('flex flex-row flex-wrap w-full', containerClassName)}
    >
      {results.map((result: RuleResult) => (
        <li
          data-testid={`${testId}-${result.success ? 'valid' : 'invalid'}-item`}
          className={cx('flex flex-1 mb-2  items-center', itemClassName)}
          key={result.rule.label}
        >
          {result.success ? (
            <CheckCircleSelectedIcon
              className='text-success'
              testId='checkCircleSelectedIcon'
            />
          ) : (
            <CheckCircleUnselectedIcon
              className='text-disabled'
              testId='checkCircleUnselectedIcon'
            />
          )}
          <span className='type-caption ml-2 align-middle'>
            {result.rule.label}
          </span>
        </li>
      ))}
    </ul>
  )
}
