import { DateTime } from 'luxon'

const FormatDateForWages = (dateString: string): string => {
  const date = DateTime.fromFormat(dateString, 'yyyy-MM-dd')

  if (date.isValid) {
    return date.toLocaleString() || ''
  } else {
    return ''
  }
}

export const ProcessWageDate = (value: string) => {
  try {
    const parts = value.split(' ')

    switch (parts.length) {
      case 4:
        return `${FormatDateForWages(parts[0])} - ${FormatDateForWages(
          parts[2]
        )} ${parts[3]}`
      case 3:
        return `${FormatDateForWages(parts[0])} - ${FormatDateForWages(
          parts[2]
        )}`
      default:
        console.warn(`unsupported date format: ${value}, using raw value`)
        return value
    }
  } catch (ex) {
    console.error(`unsupported date format: ${value}, using raw value`, ex)
    return value
  }
}
