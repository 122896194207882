export function getMetaContent<Type>(name: string): Type {
  const element = document.querySelector(`meta[name=${name}]`)

  if (!element) {
    //@ts-ignore
    return window.Toast
  }

  const content = element.getAttribute('content') || '{}'

  return JSON.parse(decodeURI(content))
}

export function getRawMetaContent(
  name: string,
  _document: Document = document
): string | null {
  return (
    _document.querySelector(`meta[name=${name}]`)?.getAttribute('content') ??
    null
  )
}
