import { DateTime } from 'luxon'

export const getDateAsDigits = (date?: DateTime | string | null) => {
  if (!date) {
    return ''
  }
  if (typeof date === 'string') {
    // remove whitespace
    return date.replace(/\s/g, '')
  }
  const strVal = date.toFormat('MM/dd/yyyy')
  return strVal.replace(/\D/g, '')
}

// Takes either a formatted date string or a DateTime object
// and converts it to a DateTime objection
export const toDate = (value?: DateTime | string | null) => {
  if (value instanceof DateTime) {
    return value
  } else if (value) {
    const trimmedValue = value.toString().trim()
    return DateTime.fromFormat(trimmedValue, 'M/d/yyyy')
  } else {
    return null
  }
}
