import { RestLink } from 'apollo-link-rest'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink
} from '@apollo/client'
import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import {
  errorLoggerLink,
  operationErrorLink,
  OperationError
} from '@local/ec-app/apollo'
import { restSerializerLink } from '../RestSerializerLink'

/**
 * Creates apollo clients for the application. Things to note:
 *
 * - Retry link MUST come after error logging link to avoid errors
 *   getting spammed to Sentry multiple times.
 */

type Args = {
  logSentryError: (error: OperationError) => void
  origin: string
  language: Locale
}

const makeApolloClients = (args: Args) => {
  const { logSentryError, origin, language } = args

  const onError = (error: OperationError) => {
    // Network errors are mostly 500 errors... those are the
    // only ones we want to log to Sentry.
    if (error.isNetworkError) {
      logSentryError(error)
    }
  }

  const restClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      errorLoggerLink<OperationError>(onError),
      operationErrorLink(),
      restSerializerLink(),
      new RestLink({
        uri: origin,
        headers: {
          Accept: 'application/com.toasttab.payroll.v1+json',
          'Accept-Language': language,
          'Content-Type': 'application/com.toasttab.payroll.v1+json',
          'Cache-Control': 'no-store'
        }
      })
    ]),
    defaultOptions: {
      // We are currently using react-queries cache.
      // Don't change until we migrate to using apollo hooks.
      query: { fetchPolicy: 'no-cache' },
      mutate: { fetchPolicy: 'no-cache' }
    }
  })

  const supergraphClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      errorLoggerLink<OperationError>(onError),
      operationErrorLink(),
      new BatchHttpLink({
        uri: '/graphql',
        headers: { 'Accept-Language': language }
      })
    ]),
    defaultOptions: {
      // We are currently using react-queries cache.
      // Don't change until we migrate to using apollo hooks.
      query: { fetchPolicy: 'no-cache' },
      mutate: { fetchPolicy: 'no-cache' }
    }
  })

  const loginClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      errorLoggerLink<OperationError>(onError),
      operationErrorLink(),
      new HttpLink({
        uri: '/login/graph',
        headers: { 'Accept-Language': language }
      })
    ]),
    defaultOptions: {
      // We are currently using react-queries cache.
      // Don't change until we migrate to using apollo hooks.
      query: { fetchPolicy: 'no-cache' },
      mutate: { fetchPolicy: 'no-cache' }
    }
  })

  const clients = {
    rest: restClient,
    humanResources: supergraphClient,
    login: loginClient,
    legacy: supergraphClient
  }

  return clients
}
export default makeApolloClients
