import * as React from 'react'
import { useWindowProvider } from '@local/ec-app'

import { useSignatureCanvas } from '../../../SignatureCanvasProvider'

export const SignaturePad = ({
  onUpdateSignature
}: {
  onUpdateSignature: (signature: string) => void
}) => {
  const window = useWindowProvider()
  const canvasWidth = React.useMemo(
    () => Math.min(500, window.innerWidth - 96),
    [window.innerWidth]
  )
  const [sigPad, setSigPad] = React.useState<any>({})
  const SignatureCanvas = useSignatureCanvas()
  const onDrawEnd = () => {
    onUpdateSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'))
  }

  return (
    <SignatureCanvas
      canvasProps={{
        width: canvasWidth,
        height: 100,
        className: 'border border-solid rounded-sm border-gray-400'
      }}
      ref={(ref: any) => setSigPad(ref)}
      onEnd={onDrawEnd}
    />
  )
}
