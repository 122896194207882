import * as React from 'react'
import { SecondaryNav } from '@toasttab/buffet-pui-navigation'
import Skeleton from 'react-loading-skeleton'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

import { NavigationProfile } from './NavigationProfileSection'
import { NavigationNodeSection } from './NavigationNodeSection'
import { NavigationContentSection } from './NavigationContentSection'

export const AppNav = ({ testId = 'app-nav' }: TestIdentifiable) => (
  <nav
    aria-label='Secondary'
    className='nav__desktop type-default app-navigation no-print'
    data-testid={testId}
  >
    <React.Suspense
      fallback={
        <>
          <Skeleton height={120} className='mb-6' />
          <div className='border-t' />
          <Skeleton height={250} className='mt-6' />
        </>
      }
    >
      <SecondaryNav>
        <NavigationProfile />
        <NavigationNodeSection />
        <NavigationContentSection />
      </SecondaryNav>
    </React.Suspense>
  </nav>
)
