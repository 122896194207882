import * as React from 'react'
import cx from 'classnames'
import { Row as ReactTableRow } from 'react-table'
import { Pagination, useRowPagination } from '@toasttab/buffet-pui-pagination'
import { Body, Cell, Foot, Row, Table } from '@toasttab/buffet-pui-table'
import { ButtonProps } from '@toasttab/buffet-pui-buttons'
import { trackEmploymentOverview } from '@local/track'

type OverviewCardTableProps<T extends object> = {
  testId: 'jobs' | 'supervisors'
  className?: string
  rows: ReactTableRow<T>[]
  prepareRow: (row: ReactTableRow<T>) => void
  pageSize: number
  userProps?: object
  fullTableDiv?: React.FunctionComponentElement<any>
  pageLink?: React.FunctionComponentElement<ButtonProps>
  button?: React.FunctionComponentElement<ButtonProps>
}

export const OverviewCardTable: React.FunctionComponent<
  OverviewCardTableProps<any>
> = ({
  testId,
  className,
  rows,
  prepareRow,
  pageSize,
  userProps,
  fullTableDiv,
  pageLink,
  button,
  ...tableProps
}) => {
  const { currentPageData, ...paginationProps } = useRowPagination({
    rows: rows,
    pageSize: pageSize
  })

  const showPagination = rows.length > pageSize
  const showFooter = button || showPagination || pageLink

  return (
    <div data-testid={testId} className='px-4 md:px-8'>
      <Table
        variant='summary-card'
        className={showFooter ? 'mb-6 md:mb-8' : 'mb-2 md:mb-4'}
      >
        <Body className={cx('border-t-0', showFooter ? 'border-b' : '')}>
          <Row>
            <div className='flex flex-col-reverse md:flex-row items-stretch divide-x-0 md:divide-x'>
              <Table
                {...tableProps}
                variant='summary-card'
                className={className}
              >
                <Body className={'border-t-0 border-b-0'}>
                  {currentPageData.map((row) => {
                    prepareRow(row)
                    const rowProps = row.getRowProps()
                    return (
                      <Row
                        {...row.getRowProps()}
                        key={row.index}
                        className={cx(
                          rowProps.className,
                          'items-center',
                          'last:border-b-0'
                        )}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <Cell
                              {...cell.getCellProps({
                                className: cx(
                                  'first:pl-0 last:pr-0',
                                  // @ts-ignore
                                  cell.column.className
                                )
                              })}
                            >
                              <>{cell.render('Cell', userProps)}</>
                            </Cell>
                          )
                        })}
                      </Row>
                    )
                  })}
                </Body>
              </Table>
              {fullTableDiv}
            </div>
          </Row>
        </Body>
        {showFooter && (
          <Foot>
            {showPagination && (
              <div className={'flex justify-end pt-4'}>
                <Pagination
                  testId={`${testId}-pagination`}
                  {...trackEmploymentOverview(`${testId}-pagination`)}
                  {...paginationProps}
                />
              </div>
            )}
            <div
              className={cx(
                'flex pt-4',
                pageLink ? 'justify-between' : 'justify-end'
              )}
            >
              {pageLink && <div>{pageLink}</div>}
              {button && <div>{button}</div>}
            </div>
          </Foot>
        )}
      </Table>
    </div>
  )
}
