import { UseQueryResult } from 'react-query'

export const queryAs = <T, K extends string>(
  key: K,
  result: UseQueryResult<T>
): Omit<UseQueryResult<T>, 'data'> & { [A in K]: T } => {
  const { data, ...results } = result

  return { [key]: data, ...results } as Omit<UseQueryResult<T>, 'data'> & {
    [A in K]: T
  }
}
