import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { useTranslation } from 'react-i18next'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Textarea } from '@toasttab/buffet-pui-text-area'
import {
  LevelId,
  useSendJobMappingRequestMutation
} from '@local/generated/graphql'
import { useEmployee, useEmployeeId } from '../hooks'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import React from 'react'
import { Overview } from '../domain'

interface Props {
  testId?: string
  isOpen: boolean
  payrollJobId: LevelId
  payrollLocationId: LevelId
  defaultEmailContent: string
  onCloseModal: () => void
  setDisabledEmailSupportButton: (isDisabled: boolean) => void
}

export const EmailSupportModal = ({
  testId = 'email-support-modal',
  isOpen,
  payrollJobId,
  payrollLocationId,
  defaultEmailContent,
  onCloseModal,
  setDisabledEmailSupportButton
}: Props) => {
  const { t } = useTranslation('employees')
  const [emailContent, setEmailContent] = React.useState(defaultEmailContent)
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const employeeId = useEmployeeId()
  const employee = useEmployee()

  const [sendJobMappingRequest] = useSendJobMappingRequestMutation()

  const onSend = () => {
    sendJobMappingRequest({
      variables: {
        employeeUuid: employeeId,
        payrollJobId: {
          databaseId: payrollJobId.databaseId,
          sequenceId: payrollJobId.sequenceId
        },
        payrollLocationId: {
          databaseId: payrollLocationId.databaseId,
          sequenceId: payrollLocationId.sequenceId
        },
        attachedNote: emailContent
      }
    })
      .then(() => {
        showSuccessSnackBar(
          t('emailSupportSendSuccess', {
            name: Overview.getName(employee.overview)
          })
        )
        setDisabledEmailSupportButton(true)
      })
      .catch(() => {
        showErrorSnackBar(t('emailSendFailure'))
      })
      .finally(() => {
        onCloseModal()
      })
  }

  const maxCharacterCount = 250

  return (
    <Modal
      testId={testId}
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      size='md'
    >
      <ModalHeader>
        <div data-testid={`${testId}-header-text`}>
          {t('emailSupportTitle')}
        </div>
      </ModalHeader>
      <ModalBody>
        <Textarea
          label={t('emailSupportJobMappingSubtext')}
          maxChars={maxCharacterCount}
          maxLength={maxCharacterCount}
          value={emailContent}
          onChange={(e) => setEmailContent(e.target.value)}
          testId={`${testId}-textarea`}
          className='h-36'
        ></Textarea>
      </ModalBody>
      <ModalFooter>
        <Button
          variant='link'
          testId={`${testId}-cancel`}
          onClick={onCloseModal}
        >
          {t('cancel')}
        </Button>
        <Button testId={`${testId}-send`} onClick={onSend}>
          {t('send')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
