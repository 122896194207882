import { DateTime } from 'luxon'
import { Strings, Validations } from '@local/shared-services'
import { Page } from './Page'

// SSN format rules:
// SSN must be numeric
// SSN cannot be less than 9 digits
// SSN cannot consist of 8 or 9 repeating digits (e.g., 111-11-1111, 111-11-1112)
// SSN cannot begin with "9"
// SSN cannot be "123-45-6789"
// SSN cannot be "987-65-4321"
// SSN cannot begin with "000"
// SSN cannot begin with "666"
// SSN cannot have "00" as the middle two digits
// SSN cannot have "0000" as the last four digits
// SSN must contain dash(es)
export const SOCIAL_SECURITY_PLACEHOLDER = '•••-••-••••'
export const SOCIAL_SECURITY_REGEX = new RegExp(
  `^${SOCIAL_SECURITY_PLACEHOLDER}$|^(?!000|666|9|123-45-6789)(?!(\\d)\\1{2}-\\1{2}-\\1{3})(?!(\\d)(\\d)\\3-\\3{2}-\\3{4})(\\d{3}-(?!00)\\d{2}-(?!0000)\\d{4})$`
)

export enum SocialSecurityAuditAction {
  VIEWED = 'Viewed',
  EDITED = 'Edited'
}

export class SocialSecurityAuditEntryDisplay {
  constructor(
    readonly id: number,
    readonly name: string,
    readonly socialSecurityNumber: string | null,
    readonly action: SocialSecurityAuditAction,
    readonly date: DateTime
  ) {}
}

export class SocialSecurityAuditEntry {
  readonly firstName: string | null
  readonly lastName: string | null
  readonly chosenName: string | null
  readonly email: string | null
  readonly socialSecurityNumber: string | null

  constructor(
    readonly id: number,
    email: string | null,
    firstName: string | null,
    lastName: string | null,
    chosenName: string | null,
    socialSecurityNumber: string | null,
    readonly action: SocialSecurityAuditAction,
    readonly date: DateTime
  ) {
    this.firstName = Strings.stripToNull(firstName)
    this.lastName = Strings.stripToNull(lastName)
    this.email = Strings.stripToNull(email)
    this.chosenName = Strings.stripToNull(chosenName)
    this.socialSecurityNumber = Strings.stripToNull(socialSecurityNumber)

    Validations.assertNotNull(id, 'id cannot be null')
    Validations.assertNotNull(action, 'action cannot be null')
    Validations.assertNotNull(date, 'date cannot be null')
  }

  public name(): string {
    if (this.firstName && this.lastName) {
      if (this.chosenName) {
        return `${this.chosenName} ${this.lastName}`
      } else {
        return `${this.firstName} ${this.lastName}`
      }
    } else if (this.email) {
      return this.email
    } else {
      return 'Deleted user'
    }
  }

  public display(): SocialSecurityAuditEntryDisplay {
    return new SocialSecurityAuditEntryDisplay(
      this.id,
      this.name(),
      this.socialSecurityNumber,
      this.action,
      this.date
    )
  }
}

export class SocialSecurity {
  constructor(
    readonly number: string | null,
    readonly auditLog: Page<SocialSecurityAuditEntry>
  ) {
    Validations.assertNullOrNotBlank(number, 'number cannot be blank')
    Validations.assertNotNull(auditLog, 'auditLog cannot be null')
  }
}
