import * as yup from 'yup'

export class Link {
  static schema: yup.ObjectSchema<Link> = yup
    .object({
      url: yup.string().required(),
      isLegacy: yup.boolean().default(false).defined()
    })
    .defined()

  static of(obj: any): Link {
    return Link.copyOf(Link.schema.validateSync(obj, { abortEarly: false }))
  }

  static copyOf(valid: Link): Link {
    return new Link(valid.url, valid.isLegacy)
  }

  constructor(readonly url: string, readonly isLegacy: boolean) {}
}

export class NavigationTreeNode {
  static schema: yup.ObjectSchema<NavigationTreeNode | null> = yup
    .object<NavigationTreeNode>({
      id: yup.number().required(),
      value: yup.string().required(),
      data: Link.schema.nullable().default(null).notRequired() as any,
      children: yup.lazy(() =>
        yup
          .array(NavigationTreeNode.schema.nullable().default(null))
          .default([])
      )
    })
    .nullable()
    .default(null)
    .defined()

  static of(obj: any): NavigationTreeNode {
    return NavigationTreeNode.copyOf(
      NavigationTreeNode.schema.validateSync(obj, {
        abortEarly: false
      }) as NavigationTreeNode
    )
  }

  static copyOf(valid: NavigationTreeNode): NavigationTreeNode {
    return new NavigationTreeNode(
      valid.id,
      valid.value,
      valid.data,
      valid.children
    )
  }

  private constructor(
    readonly id: number,
    readonly value: string,
    readonly data: Link | undefined,
    readonly children: Array<NavigationTreeNode>
  ) {}
}
