import { useLoginClient } from '@local/api/ApolloClientsProvider'
import * as Queries from '@local/api/queries'

export type LoginClient = ReturnType<typeof useLoginClient>

const makeLoginApi = (loginClient: LoginClient) => {
  const resetPassword = async (companyCode: string, email: string) => {
    await loginClient.mutate({
      mutation: Queries.RESET_PASSWORD,
      variables: {
        input: {
          companyCode,
          email
        }
      }
    })
  }

  return {
    resetPassword
  } as const
}

export default makeLoginApi
