import * as React from 'react'
import { useState } from 'react'
import cx from 'classnames'
import {
  PersonalProfileSettings,
  PersonalProfileSettingsValues
} from './Domain'
import { useFormik } from 'formik'
import {
  FormAlert,
  OptionView,
  SelectFormField,
  TextInputFormField
} from '../components'
import { MultiSelectFormField } from '../components/MultiSelectFormField'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useNavigate } from 'react-router-dom'
import { EmployeePermissions } from '../../../domain'
import Skeleton from 'react-loading-skeleton'
import {
  usePersonalProfileSettingsMutation,
  usePersonalProfileSettingsQuery
} from '../hooks'
import { useEmployee, useIsEmployeeProfileNavEnabled } from '../../../hooks'

interface PersonalProfileSettingsFormProps {
  settings: PersonalProfileSettings
  isEmployeeProfileNavEnabled: boolean
}

const PersonalProfileSettingsForm = ({
  settings,
  isEmployeeProfileNavEnabled
}: PersonalProfileSettingsFormProps) => {
  const [error, setError] = useState('')
  const employee = useEmployee()
  const navigate = useNavigate()

  const { status, savePersonalProfileSettings } =
    usePersonalProfileSettingsMutation()

  const permissions = employee?.permissions ?? EmployeePermissions.NONE

  const { values, setFieldValue, errors, handleSubmit } = useFormik({
    initialValues: settings.values,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: PersonalProfileSettingsValues.schema,
    onSubmit: (values: PersonalProfileSettingsValues) =>
      savePersonalProfileSettings(PersonalProfileSettingsValues.of(values))
        .then(() => navigate(settings.links.personalProfile))
        .catch((err) =>
          setError(
            err.networkError.originalError.result.error.includes(
              'Cannot change employee status:'
            ) // show detailed info about status changes, but don't show internal errors
              ? err.networkError.originalError.result.error
              : 'Please fix the errors below'
          )
        )
  })

  const employeeStatus = () => {
    const removedStatuses = [
      'terminated',
      'leave_of_absence',
      'terminated_cobra'
    ]

    const options = settings.options.employmentStatus.filter(
      (option) => !removedStatuses.includes(option.value)
    )

    if (permissions.canEditStatusChanges) {
      return (
        <SelectFormField
          options={options}
          selectedVal={values.employmentStatus}
          onChange={(value) => setFieldValue('employmentStatus', value)}
          label='Employment status'
          id='employment-status'
        />
      )
    } else {
      return (
        <div>
          Status:{' '}
          <OptionView
            selectedValue={values.employmentStatus}
            options={options}
          />
        </div>
      )
    }
  }

  return (
    <div>
      <FormAlert error={error} />
      <div
        className={
          isEmployeeProfileNavEnabled
            ? 'p-4 md:p-10 md:pt-8 bg-white md:rounded shadow-md-flush md:shadow-md'
            : 'p-12 bg-white rounded shadow-md'
        }
      >
        {employeeStatus()}
        <TextInputFormField
          label='Spokeware ID'
          value={values.spokewareId}
          onChange={(value) => setFieldValue('spokewareId', value)}
          errorMessage={errors.spokewareId}
          placeholder=''
          testId='spokeware-id'
        />
        <MultiSelectFormField
          options={settings.options.workflowGroups}
          initialSelections={values.workflowGroups}
          onChange={(values) => setFieldValue('workflowGroups', values)}
          label='Workflow groups'
          multiSelect={true}
        />
        <SelectFormField
          label='Daily overtime threshold'
          options={settings.options.dailyOvertimeThreshold}
          selectedVal={values.dailyOvertimeThreshold}
          onChange={(value) => setFieldValue('dailyOvertimeThreshold', value)}
          id='daily-ot-threshhold'
        />
        <div className='flex justify-between'>
          <Button
            onClick={() => navigate(settings.links.personalProfile)}
            variant='secondary'
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit()}
            disabled={status === 'loading'}
            testId='saveSettings'
          >
            Save changes
          </Button>
        </div>
      </div>
    </div>
  )
}

const PersonalProfileSettingsView = ({
  isEmployeeProfileNavEnabled
}: {
  isEmployeeProfileNavEnabled: boolean
}) => {
  const { personalProfileSettings } = usePersonalProfileSettingsQuery()

  return (
    <React.Suspense
      fallback={
        <div
          className={
            isEmployeeProfileNavEnabled
              ? 'p-4 md:p-10 md:pt-8 bg-white md:rounded shadow-md-flush md:shadow-md'
              : 'p-12 bg-white rounded shadow-md'
          }
        >
          {Array.from({ length: 4 }, (_v, i) => (
            <div key={i} className='mb-12'>
              <Skeleton height={10} width={80} />
              <br />
              <Skeleton height={25} width={200} />
            </div>
          ))}
        </div>
      }
    >
      <PersonalProfileSettingsForm
        settings={personalProfileSettings}
        isEmployeeProfileNavEnabled={isEmployeeProfileNavEnabled}
      />
    </React.Suspense>
  )
}

export const PersonalProfileSettingsPage = () => {
  const isEmployeeProfileNavEnabled = useIsEmployeeProfileNavEnabled()
  return (
    <div
      className={cx(
        'flex-grow',
        isEmployeeProfileNavEnabled ? 'pb-12' : 'p-8 md:p-12'
      )}
    >
      <div className='mx-4 mt-0 mb-2 font-normal md:mx-0 type-headline-4'>
        Settings
      </div>
      <PersonalProfileSettingsView
        isEmployeeProfileNavEnabled={isEmployeeProfileNavEnabled}
      />
    </div>
  )
}
