import * as React from 'react'
import {
  useWindowProvider,
  useUpdateConfig,
  useWindowLocation
} from '@local/ec-app'
import { walkTree } from '@local/shared-services'
import { useNavigate, useLocation } from 'react-router-dom'
import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

import { useEmployee } from '../employee/hooks'
import { NavigationTreeNode } from '../employee/domain'
import { removeUrlSearch, getPathMatchSegments } from './utils'
import { useIsEmploymentOverviewChangePayActive } from '../employee/employment/overview/hooks'

export const APP_NAV_CLASS = 'layout-app-nav'
export const MVC_URL_MATCHER = /^\/mvc\/.*/
export const ASPX_URL_MATCHER = /\.aspx/

export const isMobileNavModalOpenState = atom({
  key: 'isMobileNavModalOpen',
  default: false
})

/**
 * Used to associate an arbitrary path with a navigation node.
 * matchNode will match the node.data.url of the navigation node that should be highlighted
 * to show that it is currently selected
 * matchPath will match the url of the current page
 */

const usePsuedoChildMatcherConfigs = (): Array<{
  matchNode: ((node: NavigationTreeNode) => boolean) | RegExp
  matchPath: ((pathname: string) => boolean) | RegExp[]
}> => {
  const isEmploymentOverviewChangePayActive =
    useIsEmploymentOverviewChangePayActive()

  return [
    {
      matchNode: /CareerProfile\/Position/,
      matchPath: [/CareerProfile\/AddPosition/, /Employee\/PositionDetail/]
    },
    {
      matchNode: /Pay\/PayPreference/,
      matchPath: [/employees\/.*\/paymentMethod$/, /Pay\/DirectDeposits/]
    },
    {
      matchNode: /Pay\/PayHistory/,
      matchPath: [/employees\/.*\/pay\/history$/]
    },
    {
      matchNode: /employees\/.*\/paymentMethod$/,
      matchPath: [/Pay\/DirectDeposits/]
    },
    {
      matchNode: /CareerProfile\/Position/,
      matchPath: [/CareerProfile\/EmploymentSummary/]
    },
    {
      matchNode: /employment/,
      matchPath: isEmploymentOverviewChangePayActive
        ? [/StatusChanges\/StatusChange/, /Pay\/PayChange/]
        : [/StatusChanges\/StatusChange/]
    }
  ]
}

const usePsuedoChildMatchers = () => {
  const psuedoChildMatcherConfigs = usePsuedoChildMatcherConfigs()

  return psuedoChildMatcherConfigs.map(({ matchNode, matchPath }) => ({
    matchNode:
      typeof matchNode === 'function'
        ? matchNode
        : (node: NavigationTreeNode) => matchNode.test(node.data?.url ?? ''),
    matchPath:
      typeof matchPath === 'function'
        ? matchPath
        : (pathname: string) =>
            matchPath.some((pathMatcher) => pathMatcher.test(pathname))
  }))
}

const matchPsuedoChild = (
  psuedoChildMatchers: {
    matchNode: (node: NavigationTreeNode) => boolean
    matchPath: (pathname: string) => boolean
  }[],
  node: NavigationTreeNode,
  pathname: string
) =>
  psuedoChildMatchers.some(
    ({ matchNode, matchPath }) => matchNode(node) && matchPath(pathname)
  )

export const useIsMobileNavModalOpen = () =>
  useRecoilValue(isMobileNavModalOpenState)
export const useSetIsMobileNavModalOpen = () =>
  useSetRecoilState(isMobileNavModalOpenState)

export const useIsLegacyUrl = () => {
  const { pathname } = useLocation()

  return React.useMemo(
    () => MVC_URL_MATCHER.test(pathname) || ASPX_URL_MATCHER.test(pathname),
    [pathname]
  )
}

export const useNavigateTo = () => {
  const location = useWindowLocation()
  const navigate = useNavigate()
  const currentIsLegacy = useIsLegacyUrl()

  return (url: string | null, forceRefresh: boolean) => {
    if (url) {
      // Always force refresh when on a legacy page... legacy pages can't
      // be cleaned up and can lead to unexpected behavior.
      if (currentIsLegacy || forceRefresh) {
        location.assign(url)
      } else {
        navigate(url)
      }
    }
  }
}

export const useNavigationPath = (
  options?: Parameters<typeof useEmployee>[0]
): NavigationTreeNode[] => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const navigation = useEmployee(options)?.navigation ?? []
  const { pathname } = useWindowLocation()

  const psuedoChildMatchers = usePsuedoChildMatchers()

  return React.useMemo(() => {
    let result: NavigationTreeNode[] = []
    let closestMatch: ReturnType<typeof getPathMatchSegments> | null = null

    walkTree(navigation, ({ node, path }) => {
      if (!node.children?.length) {
        const url = node.data?.url ?? ''
        const nodePathname = removeUrlSearch(url)

        if (matchPsuedoChild(psuedoChildMatchers, node, pathname)) {
          result = [...path, node]

          return null
        }

        const matchedPath = getPathMatchSegments(
          nodePathname,
          pathname,
          (nodePart, pathPart) =>
            decodeURIComponent(nodePart) === decodeURIComponent(pathPart)
        )

        if (
          !closestMatch ||
          (matchedPath.matchedSegments >= closestMatch.matchedSegments &&
            matchedPath.totalSegments <= closestMatch.totalSegments)
        ) {
          closestMatch = matchedPath

          if (matchedPath.matchedSegments > 0) {
            result = [...path, node]
          }
        }
      } else {
        return node.children
      }
    })

    return result
  }, [navigation, pathname])
}

export const useNavigationNodes = () => {
  const [rootNode] = useNavigationPath()

  return rootNode?.children ?? []
}

export const useNavigationStyles = () => {
  const { document } = useWindowProvider()

  React.useLayoutEffect(() => {
    document.documentElement.classList.add(APP_NAV_CLASS)

    return () => document.documentElement.classList.remove(APP_NAV_CLASS)
  }, [document.documentElement])
}

/**
 * Disables the context menu bar from navigation.
 */
export const useDisableContextMenuBar = () => {
  const updateConfig = useUpdateConfig()

  React.useEffect(() => {
    updateConfig({ isContextMenuBarEnabled: false })

    return () => updateConfig({ isContextMenuBarEnabled: true })
  }, [updateConfig])
}
