import { IconButton, Button } from '@toasttab/buffet-pui-buttons'
import { MenuDropdown, ListItem } from '@toasttab/buffet-pui-dropdowns'
import cx from 'classnames'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  MoreHorizIcon,
  WarningOutlineIcon,
  AutorenewIcon,
  SettingsIcon,
  DeleteIcon,
  AddCircleIcon
} from '@toasttab/buffet-pui-icons'
import { Modal } from '@toasttab/buffet-pui-modal'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useWindowProvider } from '@local/ec-app'
import { usePersonalProfile } from '../hooks'
import {
  useEmployee,
  useIsEmployeeProfileNavEnabled,
  useEmployeeId,
  useCompanyCode,
  useIsViewingSelf
} from '../../../hooks'
import { NavigationContent } from '../../../../navigation/NavigationContentSection'
import { useSetIsMobileNavModalOpen } from '../../../../navigation/hooks'
import { useApi } from '../../../../ApiProvider'
import { useOperationRequestHandlers } from '@local/ec-app/apollo'
import { useIsEmploymentOverviewReadonlyActive } from '../../../employment/overview/hooks'
import { useTranslation } from 'react-i18next'
import { RehireDialogueController } from '../../../employment/overview/rehireDialogue/RehireDialogueController'
import { RehireEmployeeModal } from '@toasttab/ec-employee-management'
import { useFeature } from '@toasttab/ec-features'
import { QueryClient, useQueryClient } from 'react-query'

export const ActionButtons = ({
  testId = 'personal-profile-action-buttons'
}: TestIdentifiable) => {
  const navigate = useNavigate()
  const window = useWindowProvider()
  const [showSyncWarningModal, setShowSyncWarningModal] = React.useState(false)
  const [isSyncButtonDisabled, setIsSyncButtonDisabled] = React.useState(false)

  const employee = useEmployee()
  const employeeId = useEmployeeId()
  const companyCode = useCompanyCode()
  const { overview } = employee
  const { canSyncToToastPos, canShowSyncToToastPos, canEditStatusChanges } =
    employee.permissions
  const isAddEmployeeFlagEnabled = useFeature('ec-elm-add-employee')
  const queryClient = useQueryClient()
  const [isRehireDialogueOpen, setIsRehireDialogueOpen] =
    React.useState<boolean>(false)
  const [fullRehireModalOpen, setFullRehireModalOpen] =
    React.useState<boolean>(false)
  const [fullRehireDate, setFullRehireDate] = React.useState<string>(
    new Date().toString()
  )
  const personalProfile = usePersonalProfile()
  const isEmployeeProfileNavEnabled = useIsEmployeeProfileNavEnabled()
  const setIsMobileNavModalOpen = useSetIsMobileNavModalOpen()
  const isViewingSelf = useIsViewingSelf()
  const api = useApi()
  const { t } = useTranslation('employees')
  const { withSuccessSnackbar, withErrorSnackbar } =
    useOperationRequestHandlers()
  const isEmploymentOverviewReadonlyActive =
    useIsEmploymentOverviewReadonlyActive()

  const SYNC_MSG = t('syncToToastPos')
  const SYNC_WARNING_ELEMENT = (
    <div className='flex items-center'>
      <span className='pr-2'>{SYNC_MSG}</span>
      <WarningOutlineIcon />
    </div>
  )
  const SYNC_COOLDOWN_MS = 5000

  if (!employee || !personalProfile) {
    return null
  }

  const links = personalProfile.links

  const isTerminated =
    overview.employmentStatus?.toLowerCase().includes('terminated') ?? false
  const showStatusChangeButton = canEditStatusChanges && !isViewingSelf
  const onTerminateClick = () => (window.location.href = links.terminate)

  const onRehireClick = () => {
    if (!isAddEmployeeFlagEnabled) {
      window.location.href = links.rehire
    } else {
      setIsRehireDialogueOpen(true)
    }
  }

  const syncToPos = () => {
    api.syncToToastPos(links.syncToToastPos).then(
      withSuccessSnackbar('Successfully synced to POS'),
      withErrorSnackbar({
        genericErrorMessage: 'There was an error syncing to POS'
      })
    )

    // Disable the button to prevent accidental spamming our poor SQS queue 😅
    setIsSyncButtonDisabled(true)
    setTimeout(() => setIsSyncButtonDisabled(false), SYNC_COOLDOWN_MS)
  }
  const onSyncToPosClick = canSyncToToastPos
    ? syncToPos
    : () => setShowSyncWarningModal(true)

  const onSettingsClick = () => navigate(links.settings)

  const getDropdownItem = (
    text: string,
    onClick: () => void,
    className?: string,
    disabled?: boolean
  ) => (
    <ListItem
      onClick={onClick}
      className={`${className || ''} font-normal`}
      key={text}
      label={disabled ? SYNC_MSG : text}
    />
  )

  const getStatusChangeListItem = () =>
    overview.employmentStatus.toLowerCase().includes('terminated')
      ? getDropdownItem('Rehire', onRehireClick)
      : getDropdownItem('Terminate', onTerminateClick)

  const getDropdownItems = () => {
    const items: React.JSX.Element[] = []
    if (canShowSyncToToastPos) {
      items.push(
        getDropdownItem(
          SYNC_MSG,
          onSyncToPosClick,
          'md:hidden',
          !canSyncToToastPos
        )
      )
    }
    items.push(getDropdownItem('Settings', onSettingsClick))
    if (canEditStatusChanges) {
      items.push(getStatusChangeListItem())
    }
    return items
  }

  const syncWarningModal = (
    <SyncWarningModal
      isOpen={showSyncWarningModal}
      onOpenChanged={setShowSyncWarningModal}
    />
  )

  const rehireDialogue = (
    <RehireDialogueController
      rehireDialogueOpen={isRehireDialogueOpen}
      firstName={overview.chosenName ?? overview.firstName}
      lastName={overview.lastName}
      employeeId={employeeId}
      onSubmit={(changes) => {
        if (changes.rehireChanges) {
          setFullRehireDate(changes.rehireDate)
          setFullRehireModalOpen(true)
        }
        setIsRehireDialogueOpen(false)
        refreshEmployeeQuery(companyCode, employeeId, queryClient)
      }}
      onClose={() => setIsRehireDialogueOpen(false)}
    />
  )
  const fullRehireModal = (
    <RehireEmployeeModal
      isOpen={fullRehireModalOpen}
      employeeId={employeeId}
      initialRehireDate={fullRehireDate}
      onCancel={() => setFullRehireModalOpen(false)}
      onSuccess={() => {
        setFullRehireModalOpen(false)
        refreshEmployeeQuery(companyCode, employeeId, queryClient)
      }}
    />
  )

  if (isEmployeeProfileNavEnabled) {
    const withMobileModalClose =
      (fn: (...args: unknown[]) => unknown) =>
      (...args: unknown[]) => {
        fn(...args)
        setIsMobileNavModalOpen(false)
      }

    return (
      <>
        <NavigationContent>
          <div className='mb-6'>
            {canShowSyncToToastPos && (
              <Button
                variant='secondary'
                iconLeft={<AutorenewIcon />}
                iconRight={
                  canSyncToToastPos ? null : (
                    <WarningOutlineIcon
                      testId={`${testId}-sync-warning-icon`}
                    />
                  )
                }
                size='lg'
                onClick={withMobileModalClose(onSyncToPosClick)}
                className='w-full'
                testId='syncToPos'
                disabled={isSyncButtonDisabled}
              >
                {isSyncButtonDisabled
                  ? t('pleaseWaitToSyncAgain')
                  : t('syncToPOS')}
              </Button>
            )}
            {showStatusChangeButton && !isEmploymentOverviewReadonlyActive && (
              <Button
                variant='secondary'
                iconLeft={
                  isTerminated ? (
                    <AddCircleIcon testId={`${testId}-rehire-icon`} />
                  ) : (
                    <DeleteIcon testId={`${testId}-terminate-icon`} />
                  )
                }
                className={cx('w-full', canShowSyncToToastPos && 'mt-4')}
                size='lg'
                onClick={withMobileModalClose(
                  isTerminated ? onRehireClick : onTerminateClick
                )}
                testId='rehireButton'
              >
                {isTerminated ? t('rehire') : t('terminateOrLOA')}
              </Button>
            )}
            {!isViewingSelf && (
              <Button
                variant='secondary'
                onClick={withMobileModalClose(onSettingsClick)}
                iconLeft={<SettingsIcon />}
                size='lg'
                className={cx(
                  'w-full',
                  (canShowSyncToToastPos || showStatusChangeButton) && 'mt-4'
                )}
                testId='settingsButton'
              >
                {t('profileSettings')}
              </Button>
            )}
          </div>
        </NavigationContent>
        {isAddEmployeeFlagEnabled && isRehireDialogueOpen && rehireDialogue}
        {isAddEmployeeFlagEnabled && fullRehireModal}
        {syncWarningModal}
      </>
    )
  }

  return (
    <>
      <div className='flex items-center'>
        <div className='hidden md:block'>
          {canShowSyncToToastPos && (
            <Button
              variant='secondary'
              onClick={onSyncToPosClick}
              testId='syncToPos'
              disabled={isSyncButtonDisabled}
            >
              {canSyncToToastPos
                ? isSyncButtonDisabled
                  ? t('pleaseWaitToSyncAgain')
                  : SYNC_MSG
                : SYNC_WARNING_ELEMENT}
            </Button>
          )}
          {isTerminated && canEditStatusChanges && (
            <Button
              variant='secondary'
              onClick={onRehireClick}
              testId='rehireButton'
            >
              {t('rehire')}
            </Button>
          )}
        </div>
        <div className={`ml-2 ${isTerminated ? 'md:hidden' : ''}`}>
          <MenuDropdown
            renderToggle={(props: any) => (
              <IconButton {...props} icon={<MoreHorizIcon />} />
            )}
            placement='bottom-end'
          >
            {getDropdownItems()}
          </MenuDropdown>
        </div>
      </div>
      {isAddEmployeeFlagEnabled ? rehireDialogue : null}
      {isAddEmployeeFlagEnabled ? fullRehireModal : null}
      {syncWarningModal}
    </>
  )
}

interface SyncWarningModalProps {
  isOpen: boolean
  onOpenChanged: (isOpen: boolean) => void
}

const SyncWarningModal = ({ isOpen, onOpenChanged }: SyncWarningModalProps) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={() => onOpenChanged(false)}>
      <Modal.Header>Unable to Sync to POS</Modal.Header>
      <Modal.Body>
        You cannot sync to Toast POS without the following: primary email
        address, job & location levels set for each assigned position
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onOpenChanged(false)}>Ok</Button>
      </Modal.Footer>
    </Modal>
  )
}

export const refreshEmployeeQuery = (
  companyCode: string,
  employeeId: string,
  queryClient: QueryClient
) => {
  queryClient.invalidateQueries({
    queryKey: ['employee', companyCode, employeeId]
  })
}
