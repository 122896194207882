import * as React from 'react'
import { useParams } from 'react-router-dom'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

import getData from './useData'
import { Header } from './components/header'
import { EmployeeSection } from './components/employeeSection'
import { EmployerSection } from './components/employerSection'
import { PayRatesSection } from './components/payRatesSection'
import { RecentWageHistory } from './components/recentWageHistory'
import { QuarterlyWageHistory } from './components/quarterlyWageHistory'
import { EmployeeSummaryState } from './models/employeeSummaryState'

const reducer = (
  state: EmployeeSummaryState,
  action: EmployeeSummaryState
): EmployeeSummaryState => {
  return { ...state, ...action }
}

export const EmploymentSummary = () => {
  const [state, dispatch] = React.useReducer(reducer, {})
  let { companyCode, employee_id } = useParams<{
    companyCode: string
    employee_id: string
  }>()

  React.useEffect(() => {
    getData({
      url: `/mvc/${companyCode}/Team/CareerProfile/Employee/${employee_id}`,
      callback: (data) => {
        dispatch({ employee: data })
      }
    })
    getData({
      url: `/mvc/${companyCode}/Team/CareerProfile/Employer/${employee_id}`,
      callback: (data) => {
        dispatch({ employer: data })
      }
    })
    getData({
      url: `/mvc/${companyCode}/Team/CareerProfile/Jobs/${employee_id}`,
      callback: (data) => {
        dispatch({ jobs: data })
      }
    })
    getData({
      url: `/mvc/${companyCode}/Team/CareerProfile/RecentWages/${employee_id}`,
      callback: (data) => {
        dispatch({ recentWages: data })
      }
    })
    getData({
      url: `/mvc/${companyCode}/Team/CareerProfile/QuarterlyWages/${employee_id}`,
      callback: (data) => {
        dispatch({ quarterlyWages: data })
      }
    })
  }, [companyCode, employee_id])

  if (
    !state.employee ||
    !state.employer ||
    !state.jobs ||
    !state.quarterlyWages ||
    !state.recentWages
  ) {
    return (
      <div className='pin-center'>
        <MerryGoRound />
      </div>
    )
  }

  return (
    <div className='px-8 pt-8'>
      <Header />
      <EmployeeSection employee={state.employee} />
      <EmployerSection employee={state.employee} employer={state.employer} />
      <PayRatesSection jobs={state.jobs} />
      <RecentWageHistory recentWages={state.recentWages} />
      <QuarterlyWageHistory
        quarterlyWages={state.quarterlyWages}
        employee={state.employee}
      />
    </div>
  )
}
