import * as React from 'react'
import { QueryErrorResetBoundary } from 'react-query'
import {
  AllEmploymentStatuses,
  HumanResourcesPermission,
  useGetActorPermissionsForEmployeesQuery,
  useGetEmploymentStatusQuery
} from '@local/generated/graphql'
import { ErrorBoundaryGroup } from '@local/ec-app'
import { ModalProvider } from '@local/modal-provider'
import {
  EmploymentOverviewAppSuspenseBoundary,
  SuspendedEmploymentOverviewApp
} from './EmploymentOverviewAppSuspenseBoundary'
import { OverviewRow } from './OverviewRow'
import { EmploymentStatusCard } from './employmentStatus'
import { EmploymentDetailsCard } from './employmentDetails'
import { JobsCard } from './jobs'
import { SupervisorsCard } from './supervisors'
import { ActionButtons } from '../../profile/personal/components'
import { useEmployeeId } from '../../hooks'
import { isSupportedStatus } from './utils'
import { EmployeeNotFound } from './components'
import { useHasPermission } from './hooks'
import { useActorPermissionsStore } from './ActorPermissionsStore'

type Props = {
  employmentStatus?: Partial<AllEmploymentStatuses>
}

export const EmploymentOverviewAppContent: React.FunctionComponent<Props> = ({
  employmentStatus
}) => {
  return (
    <>
      <div
        data-testid='employment-overview-app'
        className='grid grid-cols-1 gap-4 w-full mb-8'
      >
        <OverviewRow classNames='lg:grid-cols-2'>
          <EmploymentStatusCard />
          <EmploymentDetailsCard />
        </OverviewRow>

        {isSupportedStatus(employmentStatus) && (
          <>
            <OverviewRow>
              <JobsCard />
            </OverviewRow>
            <OverviewRow>
              <SupervisorsCard />
            </OverviewRow>
          </>
        )}
      </div>
      <ActionButtons />
    </>
  )
}

export const EmploymentOverviewApp = () => {
  const employeeId = useEmployeeId()
  const { loading, data } = useGetEmploymentStatusQuery({
    variables: { employeeId }
  })
  const { data: actorPermissionsData, loading: actorPermissionsLoading } =
    useGetActorPermissionsForEmployeesQuery({
      variables: { employeeIds: [employeeId] }
    })
  const actorPermissions = actorPermissionsData?.actorPermissionsForEmployees[0]
  const setActorPermissions = useActorPermissionsStore(
    (x) => x.setActorPermissions
  )
  React.useEffect(() => {
    if (actorPermissions) {
      setActorPermissions(actorPermissions.permissions)
    }
  })
  const canViewEmployee = useHasPermission(
    HumanResourcesPermission.EMPLOYEE_VIEW
  )
  return (
    <QueryErrorResetBoundary>
      <ErrorBoundaryGroup>
        <ModalProvider>
          <EmploymentOverviewAppSuspenseBoundary>
            {loading || actorPermissionsLoading ? (
              <SuspendedEmploymentOverviewApp />
            ) : !canViewEmployee ? (
              <EmployeeNotFound />
            ) : (
              <EmploymentOverviewAppContent
                employmentStatus={
                  data?.findEmployeeById?.employment?.employmentStatus
                }
              />
            )}
          </EmploymentOverviewAppSuspenseBoundary>
        </ModalProvider>
      </ErrorBoundaryGroup>
    </QueryErrorResetBoundary>
  )
}
