import * as React from 'react'
import * as Sentry from '@sentry/browser'

export const useSentryAppExceptionLogger = <TError extends Error = Error>(
  fn?: (scope: Sentry.Scope, error: TError) => void
) => {
  return React.useCallback(
    (error: TError) => {
      Sentry.configureScope((scope) => {
        fn?.(scope, error) // eslint-disable-line

        Sentry.captureException(error)
      })
    },
    [fn]
  )
}
