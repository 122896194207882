import {
  atom,
  selector,
  useSetRecoilState,
  useRecoilValue,
  useRecoilCallback,
  UnwrapRecoilValue,
  DefaultValue,
  selectorFamily
} from 'recoil'
import { useNavigate } from 'react-router-dom'
import { queryAs, Objects } from '@local/shared-services'
import { MutationOptions, useQuery, UseQueryOptions } from 'react-query'
import { Contact, EmergencyContact, PersonalProfile } from './domain'
import { mapContacts } from '../../utils'
import {
  PersonalProfileSettingsValues,
  PersonalProfileSettings
} from './settings/Domain'
import {
  useCompanyCode,
  useEmployeeId,
  useOptimisticQueryUpdate,
  useOptimisticMutation
} from '../../hooks'
import { useApi } from '../../../ApiProvider'
import { LocalityCodeOption } from '../../domain'

const POSTAL_CODE_REGEX = /[0-9]{5}/

export const pendingContactState = atom<Contact | null>({
  key: 'personalProfilePendingContact',
  default: null
})

export const manageContactsState = atom<{
  contactToEdit: EmergencyContact | null
  currentContacts: Array<EmergencyContact>
  showConfirmDelete: boolean
}>({
  key: 'personalProfileManageContacts',
  default: {
    contactToEdit: null,
    currentContacts: [],
    showConfirmDelete: false
  }
})

export const primaryContactState = selector<EmergencyContact | null>({
  key: 'personalProfilePrimaryContact',
  get: ({ get }) => {
    const { currentContacts } = get(manageContactsState)

    return currentContacts.find((contact) => contact.isPrimary) ?? null
  },
  set: ({ set }, contact) => {
    set(manageContactsState, (manageContacts) => {
      const isDefaultValue = contact instanceof DefaultValue

      return {
        ...manageContacts,
        currentContacts:
          manageContacts.currentContacts.map((emergencyContact, index) =>
            EmergencyContact.copyOf(emergencyContact).setPrimary(
              isDefaultValue
                ? index === 0
                : Objects.isShallowEqualTo(emergencyContact, contact)
            )
          ) ?? []
      }
    })
  }
})

export const emergencyContactState = selectorFamily<
  EmergencyContact | undefined,
  number
>({
  key: 'emergencyContact',
  get:
    (contactId) =>
    ({ get }) =>
      get(manageContactsState).currentContacts.find(
        (contact) => contact.emergencyContactId === contactId
      )
})

export const useEmergencyContact = (contactId: number) =>
  useRecoilValue(emergencyContactState(contactId))
export const usePrimaryContact = () => useRecoilValue(primaryContactState)
export const useSetPrimaryContact = () => useSetRecoilState(primaryContactState)
export const useManageContacts = () => useRecoilValue(manageContactsState)
export const useSetManageContacts = () => useSetRecoilState(manageContactsState)
export const usePendingContact = () => useRecoilValue(pendingContactState)
export const useSetPendingContact = () => useSetRecoilState(pendingContactState)

export const useShowConfirmDeleteContact = () =>
  useUpdateManageContacts<[EmergencyContact]>((_state, contact) => ({
    contactToEdit: contact,
    showConfirmDelete: true
  }))

export const useDeleteContact = () =>
  useUpdateManageContacts((state) => {
    const { contactToEdit: contactToDelete, currentContacts } = state

    if (currentContacts.length === 1) {
      return {
        contactToEdit: null,
        currentContacts: [],
        showConfirmDelete: false
      }
    } else if (contactToDelete) {
      const index = currentContacts.indexOf(contactToDelete)
      const contacts = [...currentContacts]

      contacts.splice(index, 1)

      if (contactToDelete.isPrimary) {
        const updatedContacts = mapContacts(
          contacts,
          (_emergencyContact: EmergencyContact, index: number) => index === 0
        )
        return {
          contactToEdit: null,
          currentContacts: updatedContacts,
          showConfirmDelete: false
        }
      } else {
        return {
          contactToEdit: null,
          currentContacts: contacts,
          showConfirmDelete: false
        }
      }
    }
  })

export const useShowAddContact = () =>
  useUpdateManageContacts(() => ({
    contactToEdit: null
  }))

export const useShowEditContact = () =>
  useUpdateManageContacts<[EmergencyContact]>((_state, contact) => ({
    contactToEdit: contact
  }))

export const useAddContact = () =>
  useUpdateManageContacts<[EmergencyContact]>((state, newContact) => {
    if (state.currentContacts.length === 0) {
      const contacts: Array<EmergencyContact> = [newContact]
      const updatedContacts = mapContacts(contacts, () => true)

      return {
        currentContacts: updatedContacts
      }
    } else {
      const contacts = [...state.currentContacts, newContact]

      if (newContact.isPrimary) {
        const updatedContacts = mapContacts(
          contacts,
          (emergencyContact: EmergencyContact) =>
            emergencyContact === newContact
        )
        return {
          currentContacts: updatedContacts
        }
      } else {
        return {
          currentContacts: contacts
        }
      }
    }
  })

export const useEditContact = () =>
  useUpdateManageContacts<[EmergencyContact]>((state, contact) => {
    const { contactToEdit, currentContacts } = state

    if (contactToEdit) {
      const index = currentContacts.indexOf(contactToEdit)
      const contacts = [...currentContacts]
      const updatedContact = contact

      contacts[index] = updatedContact

      if (updatedContact.isPrimary) {
        const updatedContacts = mapContacts(
          contacts,
          (emergencyContact: EmergencyContact) =>
            emergencyContact === updatedContact
        )
        return {
          contactToEdit: null,
          currentContacts: updatedContacts
        }
      } else {
        return {
          contactToEdit: null,
          currentContacts: mapContacts(contacts)
        }
      }
    }
  })

export const useChangePrimaryContact = () =>
  useUpdateManageContacts<[EmergencyContact]>((state, contactToEdit) => {
    const updatedContacts = mapContacts(
      state.currentContacts,
      (emergencyContact: EmergencyContact) => emergencyContact === contactToEdit
    )

    return {
      contactToEdit: null,
      currentContacts: updatedContacts
    }
  })

export const useCancelContactChangeAction = () =>
  useUpdateManageContacts(() => ({
    contactToEdit: null,
    showConfirmDelete: false
  }))

export const useSocialSecurityQuery = (
  url: string,
  shouldFetch: boolean = true
) => {
  const api = useApi()

  return queryAs(
    'socialSecurity',
    useQuery(
      ['socialSecurity', url],
      () => api.findSocialSecurityByEmployeeId(url, true),
      {
        enabled: shouldFetch
      }
    )
  )
}

const useUpdateManageContacts = <T extends any[]>(
  fn: (
    state: UnwrapRecoilValue<typeof manageContactsState>,
    ...args: T
  ) => Partial<UnwrapRecoilValue<typeof manageContactsState>> | null | undefined
) =>
  useRecoilCallback(
    ({ set }) =>
      (...args: T) =>
        set(manageContactsState, (state) => ({
          ...state,
          ...fn(state, ...args)
        }))
  )

export const usePersonalProfileQuery = (): {
  personalProfile: PersonalProfile
} => {
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()
  const setManageContacts = useSetManageContacts()
  const api = useApi()

  return queryAs(
    'personalProfile',
    useQuery(
      ['personalProfile', companyCode, employeeId],
      () => api.findPersonalProfileByEmployeeId(companyCode, employeeId),
      {
        onSuccess: (result) => {
          setManageContacts((state) => ({
            ...state,
            currentContacts: result.emergencyContacts
          }))
        }
      }
    )
  )
}

export const usePersonalProfile = () =>
  usePersonalProfileQuery().personalProfile

export const useUpdatePersonalProfile = () => {
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()

  return useOptimisticQueryUpdate<
    PersonalProfile | null,
    [Partial<PersonalProfile>]
  >(
    ['personalProfile', companyCode, employeeId],
    (personalProfile, updatedData) =>
      personalProfile
        ? {
            ...personalProfile,
            ...updatedData
          }
        : null
  )
}

export const useLocalityGnisCodesQuery = (
  companyCode: string,
  postalCode?: string | null,
  options: Omit<
    UseQueryOptions<LocalityCodeOption[]>,
    'queryKey' | 'queryFn'
  > = {}
): {
  localityGnisCodes: LocalityCodeOption[]
} => {
  const api = useApi()

  return queryAs(
    'localityGnisCodes',
    useQuery(
      ['localityGnisCodes', companyCode, postalCode],
      () => {
        return postalCode && POSTAL_CODE_REGEX.test(postalCode)
          ? api.getLocalityGnisCodes(companyCode, postalCode)
          : Promise.resolve([])
      },
      options
    )
  )
}

export const usePersonalProfileSettingsQuery = () => {
  const api = useApi()
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()

  return queryAs(
    'personalProfileSettings',
    useQuery('personalProfileSettings', () =>
      api.findPersonalProfileSettings(companyCode, employeeId)
    )
  )
}

export const usePersonalProfileSettings = () =>
  usePersonalProfileSettingsQuery().personalProfileSettings

export const usePersonalProfileSettingsMutation = (
  options?: MutationOptions
) => {
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()
  const api = useApi()

  const { mutateAsync, status } = useOptimisticMutation<
    PersonalProfileSettingsValues,
    PersonalProfileSettings
  >(
    (settings: PersonalProfileSettingsValues) =>
      api.savePersonalProfileSettings(companyCode, employeeId, settings),
    'personalProfileSettings',
    {
      ...options,
      mapToQuery: (
        settings: PersonalProfileSettings,
        values: PersonalProfileSettingsValues
      ) => ({
        ...settings,
        values: {
          ...settings.values,
          ...values
        }
      })
    }
  )

  return { status, savePersonalProfileSettings: mutateAsync }
}

export const useHireTechUrlQuery = () => {
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()
  const api = useApi()

  return queryAs(
    'hireTechUrl',
    useQuery(['hireTechUrl', companyCode, employeeId], async () => {
      return api
        .getNewHireTechUrl(companyCode, employeeId)
        .then((res) => res.hireTechUrl)
        .catch((_res) => null)
    })
  )
}

export const useNavigateToProfilePage = (
  isOnboarded?: boolean
): VoidFunction => {
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()
  const navigate = useNavigate()
  return () => {
    navigate(`/${companyCode}/employees/${employeeId}/profile`, {
      state: { isOnboarded: isOnboarded }
    })
  }
}

export const useNavigateToEmergencyContactsPage = (): VoidFunction => {
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()
  const navigate = useNavigate()
  return () =>
    navigate(
      `/${companyCode}/employees/${employeeId}/profile/emergency-contacts`
    )
}

export const useNavigateToAccountPage = (): ((replace: boolean) => void) => {
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()
  const navigate = useNavigate()
  return (replace: boolean) =>
    navigate(`/${companyCode}/employees/${employeeId}/account`, {
      replace: replace
    })
}
