import { EmployeePayHistoryQuery } from '@local/generated/graphql'
import { SpaDate } from '@local/shared-services'
import { Unboxed } from '@local/types'
import { toCurrency } from '@toasttab/buffet-utils'

//TODO(mlee) - remove this in favor of doing it on the server
export const SplitFein = (item: string): { fein: string; business: string } => {
  const [business, fein] = item.split(' : ')

  return { fein, business }
}

export type PayHistoryRecord = NonNullable<
  Unboxed<EmployeePayHistoryQuery['PayHistory']['payHistory']>
>

export type FormattedPayHistoryRecord = {
  id: string
  view: Array<string>
  download: Array<string>
  name: Array<string>
  business: string
  allBusinesses: Array<string>
  formattedGross: string
  formattedNet: string
  formattedCheckDate: string
}

export const FeinText = (feins: Array<string>) => {
  const businessesList = feins.map((fein) => SplitFein(fein).business)
  const businessesSet = new Set(businessesList)

  if (businessesSet.size > 1) {
    return {
      text: `${businessesSet.size} businesses`,
      list: [...businessesSet]
    }
  } else {
    return { text: businessesList[0], list: [...businessesSet] }
  }
}

export const MapPaystubs = (
  records: Array<PayHistoryRecord>
): Array<FormattedPayHistoryRecord> =>
  records.map((record) => {
    const { view, download, name } = record.payStubs.reduce(
      (result, current) => {
        result.view.push(current.viewPayStubUrl)
        result.download.push(current.downloadPayStubUrl)
        result.name.push(current.payPeriod)

        return result
      },
      { view: [], download: [], name: [] } as {
        view: Array<string>
        download: Array<string>
        name: Array<string>
      }
    )

    //we cast this an array because for some reason ts can't figure out that we filtered out the null values
    const feins = record.payStubs
      .filter((ps) => ps.fein !== null)
      .map((x) => x.fein) as Array<string>

    const fein = FeinText(feins)

    return {
      id: `${record.checkDate}-${record.gross}-${record.net}`,
      business: fein.text,
      allBusinesses: fein.list,
      formattedGross: toCurrency(record.gross),
      formattedNet: toCurrency(record.net),
      formattedCheckDate: SpaDate.fromString(record.checkDate).toFormat(
        'MMM d, yyyy'
      ),
      view,
      download,
      name
    }
  })
