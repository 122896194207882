import * as React from 'react'
import { create, StoreApi } from 'zustand'
import createContext from 'zustand/context'

import { useEcWebClient } from '@local/api/ApolloClientsProvider'
import {
  PayHistoryReportDocument,
  PayHistoryReportQuery,
  PayHistoryReportQueryVariables,
  PayHistoryReportType
} from '@local/generated/graphql'

import { useQuery } from 'react-query'
import {
  useClearQueryParams,
  useQueryParams,
  useSetQueryParams
} from '../../../../../shared/useQueryParams'

const { Provider, useStore } = createContext<StoreApi<PayHistoryReportStore>>()

export interface PayHistoryReportStore {
  report: {
    config?: PayHistoryReportQueryVariables
  }

  openReport(config: PayHistoryReportQueryVariables): void
  closeReport(): void
}

const PayHistoryReportStoreProvider: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  const params = useQueryParams()
  const setQueryParams = useSetQueryParams()
  const clearQueryParams = useClearQueryParams()

  const defaultConfig: PayHistoryReportQueryVariables = {
    employeeUuid: params.get('employeeUuid') || '',
    to: params.get('to'),
    from: params.get('from'),
    reportType:
      (params.get('reportType') as PayHistoryReportType) ||
      PayHistoryReportType.DETAIL,
    feinId: null
  }

  return (
    <Provider
      createStore={() =>
        create<PayHistoryReportStore>((set) => ({
          filters: undefined,
          report: {
            config:
              defaultConfig.employeeUuid === '' ? undefined : defaultConfig
          },
          openReport: (config: PayHistoryReportQueryVariables) => {
            set({ report: { config } })

            setQueryParams(config)
          },
          closeReport: () => {
            set({ report: { config: undefined } })
            clearQueryParams(defaultConfig)
          }
        }))
      }
    >
      {children}
    </Provider>
  )
}

const usePayHistoryReport = () => {
  const isOpen = useStore((state) => state.report.config !== undefined)
  const open = useStore((state) => state.openReport)
  const close = useStore((state) => state.closeReport)
  const reportConfig = useStore((state) => state.report.config)
  const client = useEcWebClient()

  const data = useQuery(
    ['pay-history-report', reportConfig],
    () =>
      client.query<PayHistoryReportQuery, PayHistoryReportQueryVariables>({
        query: PayHistoryReportDocument,
        variables: reportConfig
      }),
    {
      enabled: isOpen
    }
  )

  return {
    isOpen,
    open,
    close,
    ...data
  }
}

export {
  PayHistoryReportStoreProvider,
  usePayHistoryReport,
  useStore as usePayHistoryReportStore
}
