import * as React from 'react'
import Skeleton from 'react-loading-skeleton'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import {
  SecondaryNav,
  SecondaryNavItem,
  SecondaryNavSection
} from '@toasttab/buffet-pui-navigation'
import { ExpandLessIcon, ExpandMoreIcon } from '@toasttab/buffet-pui-icons'
import { ParagraphSkeleton } from '@local/skeletons'
import {
  useIsOverviewExpanded,
  useEmployee,
  useToggleOverviewExpand,
  useHideEmail
} from './hooks'
import { useNavigateTo } from '../navigation/hooks'
import { Link, NavigationTreeNode, Overview } from './domain'
import { getDisplayEmail } from './utils'

const ImageContainer = ({ isMobile }: { isMobile: boolean }) => {
  const dim = isMobile ? '115px' : '240px'
  const className = isMobile ? 'md:hidden' : 'hidden md:block'
  const employee = useEmployee()
  return (
    <div style={{ width: dim, height: dim }} className={className}>
      {employee ? (
        <div
          style={{
            backgroundImage: `url(${employee.overview.photoUrl ?? ''})`
          }}
          className='w-full h-full p-0 m-0 bg-center bg-cover'
        />
      ) : (
        <Skeleton height={dim} />
      )}
    </div>
  )
}

const ImageView = () => (
  <div>
    <ImageContainer isMobile={true} />
    <ImageContainer isMobile={false} />
  </div>
)

const NavigationView = () => {
  const isOverviewExpanded = useIsOverviewExpanded()
  const employee = useEmployee()
  const navigateTo = useNavigateTo()

  const containerClassName = `md:block font-bold type-default p-6 md:p-0 border-b md:border-0 ${
    isOverviewExpanded ? '' : 'hidden'
  }`

  if (employee) {
    const onSelectionChange = (link?: Link) => {
      navigateTo(link?.url || null, link?.isLegacy || false)
    }

    const getNavItems = (navigationTreeNodes: NavigationTreeNode[]) => {
      return navigationTreeNodes.map(
        (navigationTreeNode: NavigationTreeNode) => {
          return (
            <SecondaryNavItem
              id={navigationTreeNode.id.toString()}
              title={navigationTreeNode.value}
              value={navigationTreeNode.data}
            >
              {getNavItems(navigationTreeNode.children)}
            </SecondaryNavItem>
          )
        }
      )
    }

    const navItems = getNavItems(employee.navigation)

    return (
      <div className={containerClassName}>
        <div className='mb-4 text-secondary type-default md:hidden'>
          EMPLOYEE MENU
        </div>
        <SecondaryNav>
          <SecondaryNavSection onSelectionChange={onSelectionChange}>
            {navItems}
          </SecondaryNavSection>
        </SecondaryNav>
      </div>
    )
  } else {
    return (
      <div className={containerClassName}>
        <div className='mb-4 text-secondary type-default md:hidden'>
          <Skeleton height={10} width={100} />
        </div>
        <div className='p-6'>
          <ParagraphSkeleton height={12} repeat={7} className='mb-4' />
        </div>
      </div>
    )
  }
}

const OverviewTeamView = () => {
  const toggleOverviewExpanded = useToggleOverviewExpand()
  const employee = useEmployee()
  const isOverviewExpanded = useIsOverviewExpanded()

  return (
    <div
      className='flex items-center flex-1 p-6 cursor-pointer md:hidden'
      {...(employee && {
        onClick: () => toggleOverviewExpanded()
      })}
    >
      <div className='flex-1 text-secondary'>
        <div className='mb-1 font-bold type-caption'>TEAM</div>
        {employee ? (
          <div className='type-headline-5'>
            {Overview.getName(employee.overview)}
          </div>
        ) : (
          <Skeleton width={190} height={30} />
        )}
      </div>
      {isOverviewExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </div>
  )
}

const OverviewNameView = () => {
  const employee = useEmployee()

  return (
    <div
      className='hidden mb-2 overflow-hidden font-semibold type-headline-3 md:block'
      data-testid='overview-name'
      style={{ width: '190px', textOverflow: 'ellipsis' }}
      title={employee ? Overview.getName(employee.overview) : ''}
    >
      {employee ? (
        Overview.getName(employee.overview)
      ) : (
        <Skeleton width={190} height={30} />
      )}
    </div>
  )
}

const OverviewView = () => {
  const { t } = useTranslation('employees')
  const employee = useEmployee()
  const isOverviewExpanded = useIsOverviewExpanded()

  const expandClassName = `md:block p-6 border-b md:border-0 ${
    isOverviewExpanded ? '' : 'hidden'
  }`
  const displayEmail = useHideEmail()
    ? getDisplayEmail(employee.overview.emailAddress)
    : employee.overview.emailAddress
  if (employee) {
    return (
      <div>
        <div className='flex items-center border-b md:border-0'>
          <ImageView />
          <OverviewTeamView />
        </div>
        <div className={expandClassName}>
          <OverviewNameView />
          <div className='mb-4 font-bold text-secondary type-default md:hidden'>
            EMPLOYEE SNAPSHOT
          </div>
          <div className='table w-full type-default'>
            {employee.overview.employeeNumber && (
              <div className='table-row'>
                <div className='table-cell md:hidden'>ID</div>
                <div className='table-cell' data-testid='overview-employee-num'>
                  #{employee.overview.employeeNumber}
                </div>
              </div>
            )}
            <div className='table-row'>
              <div className='table-cell md:hidden'>Status</div>
              <div className='table-cell' data-testid='overview-status'>
                {employee.overview.employmentStatus}{' '}
                {Overview.getEmploymentDateTime(
                  employee.overview
                )?.toLocaleString(DateTime.DATE_SHORT)}
              </div>
            </div>
            {employee.overview.primaryPhone && (
              <div className='table-row'>
                <div className='table-cell md:hidden'>Phone</div>
                <div
                  className='table-cell'
                  data-testid='overview-primary-phone'
                >
                  {employee.overview.primaryPhone}
                </div>
              </div>
            )}
            {employee.overview.emailAddress && (
              <div className='table-row'>
                <div className='table-cell md:hidden'>Email</div>
                <div className='table-cell' data-testid='overview-email'>
                  <a
                    className='block overflow-hidden whitespace-nowrap'
                    style={{ width: '190px', textOverflow: 'ellipsis' }}
                    href={`mailto:${employee.overview.emailAddress}`}
                    title={employee.overview.emailAddress}
                  >
                    {displayEmail ? displayEmail : t('noEmailOnFile')}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className='flex items-center border-b md:border-0'>
          <ImageView />
          <OverviewTeamView />
        </div>
        <div className={expandClassName}>
          <OverviewNameView />
          <div className='mb-4 font-bold text-secondary type-default md:hidden'>
            <Skeleton width={200} height={15} />
          </div>
          <div className='table w-full type-default'>
            <ParagraphSkeleton height={12} repeat={4} />
          </div>
        </div>
      </div>
    )
  }
}

export { OverviewView, NavigationView }
