import * as React from 'react'
import cx from 'classnames'

export interface OverviewRowProps {
  classNames?: string
}

export const OverviewRow = ({
  children,
  classNames
}: React.PropsWithChildren<OverviewRowProps>) => (
  <div
    data-testid='overview-row'
    className={cx(
      'grid gap-4 grid-cols-1',
      `lg:grid-cols-${React.Children.count(children)}`,
      '-mx-1.5 lg:mx-0',
      classNames
    )}
  >
    {React.Children.map(children, (child) => (
      <div className='contents'>{child}</div>
    ))}
  </div>
)
