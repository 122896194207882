import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useIsViewingSelf } from '../../../../hooks'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { ForkKnifeIcon } from '@toasttab/buffet-pui-icons'
import { AddJobsButton } from './AddJobsButton'
import { useHasPermission } from '../../hooks'
import { HumanResourcesPermission } from '@local/generated/graphql'

export interface Props {
  testId?: string
}

export const JobsEmptyState: React.FunctionComponent<Props> = ({
  testId = 'jobs-empty-state'
}) => {
  const { t } = useTranslation('employees')
  const isViewingSelf = useIsViewingSelf()
  const canAddJobs = useHasPermission(
    HumanResourcesPermission.JOB_ASSIGNMENT_EDIT
  )

  return (
    <div className='flex flex-col justify-center items-center'>
      <EmptyState
        testId={testId}
        icon={<ForkKnifeIcon />}
        className='px-4 pt-4 pb-6 md:pb-8'
        button={canAddJobs ? <AddJobsButton /> : undefined}
      >
        {isViewingSelf ? t('noJobsViewingSelf') : t('noJobs')}
      </EmptyState>
    </div>
  )
}
