import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An `ISO-8601` compliant Full Date Scalar - e.g. 2022-06-01 */
  Date: any
  /** An `ISO-8601` compliant DateTime Scalar, e.g. 2022-06-01T10:15:00.000 */
  DateTime: any
  /** A signed decimal number, which supports arbitrary precision and is serialized as a string. Example values: "29.99", "29.999". */
  Decimal: any
  HashId: any
  LegacyId: any
  SpaDate: string
  SpaDateTime: string
  join__FieldSet: any
  link__Import: any
}

export type AchConsent = {
  __typename?: 'ACHConsent'
  employee: Maybe<BankConsentEmployee>
  needsConsent: Scalars['Boolean']
  payPeriodUuid: Scalars['ID']
  timestamp: Scalars['DateTime']
}

export type AccessibleCustomer = {
  __typename?: 'AccessibleCustomer'
  companyCode: Scalars['String']
  name: Scalars['String']
  shardName: Scalars['String']
}

export type AccessibleCustomersInput = {
  cursor: InputMaybe<Scalars['ID']>
  pageSize: InputMaybe<Scalars['Int']>
  search: InputMaybe<Scalars['String']>
}

export type AccessibleCustomersResult = {
  __typename?: 'AccessibleCustomersResult'
  customers: Array<AccessibleCustomer>
  nextCursor: Maybe<Scalars['ID']>
}

export type ActiveEmploymentStatus = EmploymentStatus & {
  __typename?: 'ActiveEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type Actor = {
  __typename?: 'Actor'
  /** The ID of the actor */
  id: Scalars['ID']
  /** The actor's primary job name */
  jobName: Maybe<Scalars['String']>
  /** The name of the actor */
  name: Maybe<PersonName>
}

export type ActorPermissionsForEmployee = {
  __typename?: 'ActorPermissionsForEmployee'
  employeeId: Scalars['ID']
  permissions: Array<HumanResourcesPermission>
}

export type AdditionalEarningResponse =
  | EarningListEmptyError
  | EmployeeNotFoundError
  | JobAssignmentNotFoundError
  | MissingPayGroupError
  | NextPayPeriodNotFoundError
  | NextPaycheckDateNotFoundError
  | Paycheck

export type AllEmploymentStatuses =
  | ActiveEmploymentStatus
  | ApplicantEmploymentStatus
  | DeletedEmploymentStatus
  | DemoEmploymentStatus
  | ImplementationEmploymentStatus
  | LeaveOfAbsenceEmploymentStatus
  | NeverEmployedEmploymentStatus
  | PendingEmploymentStatus
  | RehiredEmploymentStatus
  | SeasonalEmploymentStatus
  | TerminatedEmploymentStatus

export type AmountWithPayInterval = {
  __typename?: 'AmountWithPayInterval'
  amount: Money
  interval: PayInterval
}

export type ApplicantEmploymentStatus = EmploymentStatus & {
  __typename?: 'ApplicantEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type ArchiveError = MutationError & {
  __typename?: 'ArchiveError'
  errors: Array<Error>
  status: ResponseStatus
}

export type ArchiveResponse = ArchiveError | ArchiveSuccess

export type ArchiveSuccess = MutationSuccess & {
  __typename?: 'ArchiveSuccess'
  result: Scalars['ID']
  status: ResponseStatus
}

export enum Assignee {
  CUSTOMER = 'CUSTOMER',
  ONBOARDING = 'ONBOARDING'
}

export type Audit = {
  __typename?: 'Audit'
  action: AuditAction
  date: Scalars['SpaDateTime']
  postponedDate: Maybe<Scalars['String']>
  whom: Scalars['String']
}

export enum AuditAction {
  Checks = 'Checks',
  Opened = 'Opened',
  Posted = 'Posted',
  Postponed = 'Postponed',
  Reset = 'Reset'
}

export type AvailabilityRequest = {
  email: InputMaybe<Scalars['String']>
  employeeId: InputMaybe<Scalars['ID']>
  employeeNumber: InputMaybe<Scalars['Int']>
  posAccessCode: InputMaybe<Scalars['Int']>
  ssn: InputMaybe<Scalars['String']>
}

export type AvailabilityResponse = {
  __typename?: 'AvailabilityResponse'
  email: Maybe<Scalars['Boolean']>
  employeeNumber: Maybe<Scalars['Boolean']>
  posAccessCode: Maybe<Scalars['Boolean']>
  ssn: Maybe<Scalars['Boolean']>
}

export type BankAccount = {
  __typename?: 'BankAccount'
  lastFour: Scalars['String']
  name: Scalars['String']
  signatureFileName: Maybe<Scalars['String']>
  uuid: Scalars['String']
}

export type BankAccountConsentInfo = {
  __typename?: 'BankAccountConsentInfo'
  bankAccountUuid: Scalars['ID']
  bankName: Scalars['String']
  consents: Maybe<Array<AchConsent>>
  hasSignature: Scalars['Boolean']
  lastFourAccountNumber: Maybe<Scalars['String']>
  payGroupUuid: Scalars['ID']
}

export type BankConsentEmployee = {
  __typename?: 'BankConsentEmployee'
  employeeNumber: Scalars['String']
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
}

export type Benefit = {
  __typename?: 'Benefit'
  key: Scalars['String']
  label: Scalars['String']
  options: Array<BenefitOption>
}

export type BenefitOption = {
  __typename?: 'BenefitOption'
  label: Scalars['String']
  value: Scalars['ID']
}

export type Benefits = {
  __typename?: 'Benefits'
  acaPlan: Maybe<Scalars['String']>
  benefitPlan: Maybe<Scalars['String']>
  tafwPolicy: Maybe<Scalars['String']>
  washingtonLaI: Maybe<Scalars['String']>
  workersComp: Maybe<Scalars['String']>
}

export type BenefitsInput = {
  acaPlan: InputMaybe<Scalars['String']>
  benefitPlan: InputMaybe<Scalars['String']>
  tafwPolicy: InputMaybe<Scalars['String']>
  washingtonLaI: InputMaybe<Scalars['String']>
  workersComp: InputMaybe<Scalars['String']>
}

export type BulkAddEmployeeResponse = {
  __typename?: 'BulkAddEmployeeResponse'
  status: Scalars['String']
  uuid: Scalars['String']
}

export type BulkAddResponse = {
  __typename?: 'BulkAddResponse'
  employees: Maybe<Array<BulkAddEmployeeResponse>>
}

export type BulkDeductionMutation = {
  amount: Scalars['Float']
  deductionCodeUuid: Scalars['String']
  employeeUuids: InputMaybe<Array<Scalars['String']>>
  isPercentage: Scalars['Boolean']
}

export type BulkEarningMutation = {
  amount: Scalars['Float']
  checkCodeUuid: Scalars['String']
  earningCodeUuid: Scalars['String']
  employeeUuids: Array<Scalars['String']>
  hours: Scalars['Float']
  week: Scalars['Int']
}

export type CashRequirement = {
  __typename?: 'CashRequirement'
  totalEERequirement: EmployeeRequirement
  totalTaxes: Array<PayCheckTaxes>
  vendorNetPays: Array<VendorNetPays>
}

export type ChangePayInput = {
  effectiveDate: Scalars['Date']
  rate: MoneyInput
}

export type ChangeSalaryAllocationInput = {
  /** Date the new rate should be applied. If more than 1 SalaryAllocation is being updated, effectiveDate must be today's date */
  effectiveDate: Scalars['Date']
  /** Id of the salary allocation */
  id: Scalars['ID']
  /** New annual rate for this salary allocation */
  newRate: MoneyInput
}

export type ChangeSalaryResponse =
  | EmployeeNotFoundError
  | EmptySalaryAllocationsError
  | InvalidEffectiveDateError
  | Salary
  | SalaryAllocationNotFoundError
  | SalaryNotFoundError

export type CheckCode = {
  __typename?: 'CheckCode'
  label: Scalars['String']
  value: Scalars['String']
}

export type CheckCodesConfigQuerySuccess = QuerySuccess & {
  __typename?: 'CheckCodesConfigQuerySuccess'
  checkCodes: Array<CheckCode>
  status: ResponseStatus
}

export type CheckCodesConfigResponse =
  | CheckCodesConfigQuerySuccess
  | QueryErrorResponse

export type CheckPackage = {
  __typename?: 'CheckPackage'
  numberOfChecks: Scalars['Int']
  shippingAddress: ShippingAddress
  workTaxLocations: Array<WorkTaxLocation>
}

export type ChecklistState = {
  __typename?: 'ChecklistState'
  customerUuid: Scalars['ID']
  items: Array<Item>
}

export type CompanyProfile = {
  __typename?: 'CompanyProfile'
  allowedToPost: Scalars['Boolean']
  customerStatus: CustomerStatus
  feins: Array<CustomerFein>
  firstPayrollDate: Scalars['SpaDate']
  numActiveEmployees: Scalars['Int']
  numFeins: Scalars['Int']
  numPayGroups: Scalars['Int']
  numRestaurants: Scalars['Int']
}

export type CompanySummary = {
  __typename?: 'CompanySummary'
  companyProfile: CompanyProfile
  integrationSettings: IntegrationSettings
  payGroups: Array<PayGroupSetting>
  payrollSettings: PayrollSettings
}

export type ContactTelephoneNumber = {
  __typename?: 'ContactTelephoneNumber'
  isMobile: Scalars['Boolean']
  telephoneNumber: Scalars['String']
}

export type Country = {
  __typename?: 'Country'
  code: Scalars['String']
  displayName: Scalars['String']
  isoCode: Scalars['String']
}

export type CreateJobTagAssignmentInput = {
  createdByUserGuid: Scalars['ID']
  jobTagId: Scalars['ID']
  managementSetId: Scalars['ID']
  restaurantId: Scalars['ID']
  restaurantJobId: Scalars['ID']
  selectedJobTagValue: Scalars['String']
}

export type CreateJobTagInput = {
  createdByUserGuid: Scalars['ID']
  name: Scalars['String']
  values: Array<Scalars['String']>
}

/** An `ISO-4217` compliant Currency. */
export enum Currency {
  /** Canadian Dollars (CAD). */
  CAD = 'CAD',
  /** Euro (EUR). */
  EUR = 'EUR',
  /** United Kingdom Pounds (GBP). */
  GBP = 'GBP',
  /** United States Dollars (USD). */
  USD = 'USD'
}

export type Customer = {
  __typename?: 'Customer'
  customerId: Scalars['String']
  id: Scalars['ID']
  locations: Array<Location>
  name: Scalars['String']
  status: CustomerStatus
  /** @deprecated Field no longer supported */
  uuid: Scalars['ID']
}

export type CustomerConfiguration = {
  __typename?: 'CustomerConfiguration'
  defaultEarningsIds: DefaultEarningsId
  /** customerUuid */
  id: Scalars['ID']
  locations: Array<PayrollLocation>
  payGroups: Array<PayGroupWithFrequency>
  payrollSecurityRoles: Array<PayrollSecurityRole>
  workTaxLocations: Array<PayrollWorkTaxLocation>
}

export type CustomerFein = {
  __typename?: 'CustomerFein'
  dayOfWeek: Scalars['String']
  isActive: Scalars['Boolean']
  isPayrollPayor: IsPayrollPayor
  name: Scalars['String']
  taxFilingEndDate: Maybe<Scalars['SpaDate']>
  taxFilingStartDate: Maybe<Scalars['SpaDate']>
  tin: Scalars['String']
  uuid: Scalars['String']
}

export type CustomerInput = {
  locations: Array<LocationInput>
  uuid: Scalars['ID']
}

export type CustomerSetting = {
  __typename?: 'CustomerSetting'
  id: Scalars['Int']
  value: Scalars['String']
}

export type CustomerSettings = {
  __typename?: 'CustomerSettings'
  BLENDED_OT: Scalars['Boolean']
  CHECK_PRINTING_PAPER_CHECKS_TYPE: Scalars['Int']
  CHECK_PRINTING_SUFFIX: Scalars['String']
  CREDIT_CARD_TIPS_EARNING_CODE: Scalars['Int']
  DEADLINE_ENFORCEMENT: Scalars['Boolean']
  DECLARED_EARNINGS_TIPS_EARNINGCODE: Scalars['Int']
  EEO_ENABLED_IN_NEW_HIRE_ONBOARDING: Scalars['Boolean']
  ENFORCE_TWO_FACTOR_AUTHENTICATION: Scalars['String']
  HAS_PREVIOUS_PAYROLL_PROVIDER: Scalars['Boolean']
  PAYROLL_POST_DEADLINE: Scalars['Int']
  SECONDARY_RATES_CODE_LEVEL: Scalars['Int']
  SECONDARY_RATES_CONSIDER_UNIQUE_LEVELS_SEQUENCE: Scalars['Boolean']
  SECONDARY_RATES_PER_LEVEL: Scalars['Boolean']
  SINGLE_SIGN_ON: Scalars['Boolean']
  TIP_POOLING_CASH_EARNING_CODE: Scalars['Int']
  TIP_POOLING_CASH_GRATUITY_EARNING_CODE: Scalars['Int']
  TIP_POOLING_NON_CASH_EARNING_CODE: Scalars['Int']
  TIP_POOLING_NON_CASH_GRATUITY_EARNING_CODE: Scalars['Int']
}

export enum CustomerStatus {
  active = 'active',
  conversion = 'conversion',
  demo_company = 'demo_company',
  inactive = 'inactive',
  new_client_clean_quarter = 'new_client_clean_quarter',
  new_client_mid_quarter = 'new_client_mid_quarter',
  none = 'none',
  prospect = 'prospect',
  suspect = 'suspect',
  unverified = 'unverified'
}

export type DataExceptionError = {
  __typename?: 'DataExceptionError'
  message: Maybe<Scalars['String']>
}

export type DateRange = {
  __typename?: 'DateRange'
  endDate: Maybe<Scalars['Date']>
  startDate: Scalars['Date']
}

export type DefaultEarningsId = {
  __typename?: 'DefaultEarningsId'
  contractorHourly: Maybe<Scalars['ID']>
  contractorSalary: Maybe<Scalars['ID']>
  hourly: Scalars['ID']
  salary: Scalars['ID']
}

export type DeletedEmploymentStatus = EmploymentStatus & {
  __typename?: 'DeletedEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export enum DeliveryMethod {
  Invalid = 'Invalid',
  NotRecorded = 'NotRecorded',
  PayYourOwnWay = 'PayYourOwnWay',
  SelfPrinted = 'SelfPrinted',
  ToastPrinted = 'ToastPrinted'
}

export type DemoEmploymentStatus = EmploymentStatus & {
  __typename?: 'DemoEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export enum DisabilityStatus {
  DOES_NOT_WISH_TO_ANSWER = 'DOES_NOT_WISH_TO_ANSWER',
  NO = 'NO',
  YES = 'YES'
}

export type DriversLicense = {
  __typename?: 'DriversLicense'
  expirationDate: Scalars['Date']
  number: Scalars['String']
  state: StateInUnitedStates
}

export type EarningCode = {
  __typename?: 'EarningCode'
  isHourly: Scalars['Boolean']
  isOT: Scalars['Boolean']
  label: Scalars['String']
  value: Scalars['String']
}

export type EarningListEmptyError = {
  __typename?: 'EarningListEmptyError'
  message: Maybe<Scalars['String']>
}

export type EcPayrollResponse = ResponseError | ResponseSuccess

export type EeoClassification = {
  __typename?: 'EeoClassification'
  id: Scalars['Int']
  name: Scalars['String']
}

export type EeoClassificationInput = {
  id: Scalars['Int']
  name: Scalars['String']
}

export type Ein = {
  __typename?: 'Ein'
  effectiveDate: Scalars['Date']
  ein: Scalars['String']
  frequency: Maybe<EinPaymentFrequency>
  id: Scalars['ID']
  sutaRates: Maybe<Array<SutaRate>>
  taxName: Scalars['String']
  type: Scalars['String']
}

export type EinPaymentFrequency = {
  __typename?: 'EinPaymentFrequency'
  name: Scalars['String']
}

export type EinState = {
  __typename?: 'EinState'
  eins: Array<Ein>
  id: Scalars['Int']
  name: Scalars['String']
}

export type EmergencyContact = {
  __typename?: 'EmergencyContact'
  email: Maybe<Scalars['String']>
  firstName: Scalars['String']
  id: Scalars['ID']
  isPrimary: Maybe<Scalars['Boolean']>
  lastName: Scalars['String']
  relation: Scalars['String']
  telephoneNumber: Maybe<Scalars['String']>
}

export type Employee = {
  __typename?: 'Employee'
  benefits: Maybe<Benefits>
  contact: EmployeeContact
  demographics: EmployeeDemographics
  emergencyContacts: Array<EmergencyContact>
  employment: Maybe<Employment>
  hasSsn: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  identification: EmployeeIdentification
  isEmployeeZero: Scalars['Boolean']
  jobAssignmentPayChangeErrors: Array<JobAssignment>
  jobAssignments: Array<JobAssignment>
  payGroupAssignments: Array<PayGroupAssignment>
  payHistory: EmployeePayHistory
  payrollSecurityRole: Maybe<PayrollSecurityRole>
  salary: Maybe<Salary>
  salaryChangeErrors: Maybe<Salary>
  supervisors: Array<EmployeeSupervisor>
  user: Maybe<User>
  /** @deprecated Please use user */
  userUuid: Scalars['ID']
}

export type EmployeeAtLocation = {
  __typename?: 'EmployeeAtLocation'
  jobs: Array<EmployeeJob>
  mappingStatus: MappingCondition
  payrollLocationId: Maybe<LevelId>
  payrollLocationName: Maybe<Scalars['String']>
  posEmail: Maybe<Scalars['String']>
  posLocationGuid: Maybe<Scalars['ID']>
  posMostRecentClockInDate: Maybe<Scalars['Date']>
  posRestaurantUserGuid: Maybe<Scalars['ID']>
  posStatus: Maybe<Scalars['String']>
  posUserName: Maybe<PersonName>
}

export type EmployeeComparison = {
  __typename?: 'EmployeeComparison'
  added: Array<PayrollEmployee>
  removed: Array<PayrollEmployee>
}

export type EmployeeContact = {
  __typename?: 'EmployeeContact'
  homeAddress: Maybe<PostalAddress>
  mailingAddress: Maybe<PostalAddress>
  telephoneNumber: Maybe<ContactTelephoneNumber>
}

export type EmployeeDemographics = {
  __typename?: 'EmployeeDemographics'
  dateOfBirth: Maybe<Scalars['Date']>
  disability: Maybe<DisabilityStatus>
  ethnicity: Maybe<Ethnicity>
  gender: Maybe<Gender>
  maritalStatus: Maybe<MaritalStatus>
  veteranStatus: Array<VeteranStatus>
}

export type EmployeeIdentification = {
  __typename?: 'EmployeeIdentification'
  driversLicense: Maybe<DriversLicense>
  hasSocialSecurityNumber: Scalars['Boolean']
}

export type EmployeeJob = {
  __typename?: 'EmployeeJob'
  mappingStatus: MappingCondition
  payrollId: Maybe<LevelId>
  payrollRateOfPay: Maybe<Money>
  payrollTitle: Maybe<Scalars['String']>
  posGuid: Maybe<Scalars['ID']>
  posRateOfPay: Maybe<Money>
  posTitle: Maybe<Scalars['String']>
}

export type EmployeeMappingStatus = {
  __typename?: 'EmployeeMappingStatus'
  email: Maybe<Scalars['String']>
  id: Scalars['ID']
  issues: Array<MappingIssue>
  locations: Array<EmployeeAtLocation>
  name: PersonName
  status: EmploymentStatusType
}

export type EmployeeNetPays = {
  __typename?: 'EmployeeNetPays'
  amt: Scalars['Float']
  uuid: Scalars['ID']
}

export type EmployeeNotFoundError = {
  __typename?: 'EmployeeNotFoundError'
  message: Maybe<Scalars['String']>
}

export type EmployeePay = {
  __typename?: 'EmployeePay'
  mostRecentPaycheck: Maybe<Scalars['String']>
}

export type EmployeePayHistory = {
  __typename?: 'EmployeePayHistory'
  mostRecentPaycheck: Maybe<Scalars['String']>
}

export type EmployeeRequirement = {
  __typename?: 'EmployeeRequirement'
  employeesPay: Array<EmployeeNetPays>
  invoiceItems: Array<InvoiceItem>
  voidNetPayment: Array<PayCheck>
}

export type EmployeeSupervisor = {
  __typename?: 'EmployeeSupervisor'
  id: Scalars['ID']
  job: Maybe<Scalars['String']>
  location: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type Employment = {
  __typename?: 'Employment'
  eeoClassification: EeoClassification
  employeeNumber: Maybe<Scalars['String']>
  employmentStatus: AllEmploymentStatuses
  employmentType: Maybe<Scalars['String']>
  id: Scalars['ID']
  overtimeEligible: Maybe<Scalars['Boolean']>
  totalLengthOfService: Maybe<DateRange>
}

export type EmploymentDetails = {
  __typename?: 'EmploymentDetails'
  employeeNumber: Scalars['Int']
  employmentTaxType: EmploymentTaxType
  hireDate: Scalars['Date']
}

export type EmploymentDetailsInput = {
  defaultEarningsCode: Scalars['ID']
  eeoClassification: EeoClassificationInput
  employeeNumber: Scalars['Int']
  employmentTaxType: EmploymentTaxType
  hireDate: Scalars['Date']
}

export type EmploymentId = {
  __typename?: 'EmploymentId'
  customerId: Scalars['ID']
  employeeId: Scalars['ID']
}

export type EmploymentStatus = {
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export enum EmploymentStatusType {
  ACTIVE = 'ACTIVE',
  APPLICANT = 'APPLICANT',
  DELETED = 'DELETED',
  DEMO = 'DEMO',
  IMPLEMENTATION = 'IMPLEMENTATION',
  LEAVE_OF_ABSENCE = 'LEAVE_OF_ABSENCE',
  NEVER_EMPLOYED = 'NEVER_EMPLOYED',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  PENDING_ACTIVE = 'PENDING_ACTIVE',
  SEASONAL = 'SEASONAL',
  TERMINATED = 'TERMINATED'
}

export enum EmploymentTaxType {
  K1 = 'K1',
  UNKNOWN = 'UNKNOWN',
  W2 = 'W2',
  _1099 = '_1099'
}

export type EmptySalaryAllocationsError = {
  __typename?: 'EmptySalaryAllocationsError'
  message: Maybe<Scalars['String']>
}

export enum EnumAmountType {
  Currency = 'Currency',
  Percent = 'Percent',
  Undefined = 'Undefined',
  Value = 'Value'
}

export type Error = {
  __typename?: 'Error'
  message: Maybe<Scalars['String']>
}

export enum Ethnicity {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  ASIAN = 'ASIAN',
  BLACK_OR_AFRICAN_AMERICAN = 'BLACK_OR_AFRICAN_AMERICAN',
  DO_NOT_WISH_TO_IDENTIFY = 'DO_NOT_WISH_TO_IDENTIFY',
  HAWAIIAN_OR_OTHER_PACIFIC_ISLAND = 'HAWAIIAN_OR_OTHER_PACIFIC_ISLAND',
  HISPANIC_OR_LATINO = 'HISPANIC_OR_LATINO',
  TWO_OR_MORE_RACES = 'TWO_OR_MORE_RACES',
  WHITE = 'WHITE'
}

export type ExpenseReport = {
  __typename?: 'ExpenseReport'
  approveDateTime: Maybe<Scalars['SpaDateTime']>
  customerUuid: Scalars['String']
  employeeFirstName: Scalars['String']
  employeeLastName: Scalars['String']
  employeeUuid: Scalars['String']
  payPeriodUuid: Maybe<Scalars['String']>
  totalAmount: Scalars['Float']
}

export type Fein = {
  __typename?: 'Fein'
  id: Scalars['ID']
  name: Scalars['String']
  states: Array<EinState>
  tin: Scalars['String']
}

export type FeinOption = {
  __typename?: 'FeinOption'
  id: Scalars['LegacyId']
  name: Scalars['String']
}

export enum Gender {
  DOES_NOT_WISH_TO_IDENTIFY = 'DOES_NOT_WISH_TO_IDENTIFY',
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export type GenericResponse = {
  __typename?: 'GenericResponse'
  message: Scalars['String']
}

export type HourlyEarningInput = {
  earningCodeId: Scalars['ID']
  hours: Scalars['Decimal']
  rate: MoneyInput
}

export type HourlyRetroPay = {
  __typename?: 'HourlyRetroPay'
  hoursWorked: Scalars['Decimal']
  payChangeDifference: AmountWithPayInterval
  retroPay: Money
}

export type HourlyRetroPayInput = {
  employeeId: Scalars['ID']
  endDate: Scalars['Date']
  jobAssignmentId: Scalars['ID']
  newRate: MoneyInput
  oldRate: MoneyInput
  startDate: Scalars['Date']
}

export type HourlyRetroPayResponse =
  | HourlyRetroPay
  | InvalidStartDateError
  | JobAssignmentNotFoundError
  | TimesheetRetrievalError

export enum HumanResourcesPermission {
  CHANGE_UNVERIFIED_USER_EMAIL = 'CHANGE_UNVERIFIED_USER_EMAIL',
  CHANGE_VERIFIED_USER_EMAIL = 'CHANGE_VERIFIED_USER_EMAIL',
  /** @deprecated replace with EMPLOYEE_EDIT */
  EDIT_MY_PROFILE = 'EDIT_MY_PROFILE',
  EMPLOYEE_ACCESS_ALL = 'EMPLOYEE_ACCESS_ALL',
  EMPLOYEE_ACCESS_ALL_FOR_CUSTOMER = 'EMPLOYEE_ACCESS_ALL_FOR_CUSTOMER',
  EMPLOYEE_EDIT = 'EMPLOYEE_EDIT',
  EMPLOYEE_VIEW = 'EMPLOYEE_VIEW',
  EMPLOYMENT_EDIT = 'EMPLOYMENT_EDIT',
  EMPLOYMENT_STATUS_EDIT = 'EMPLOYMENT_STATUS_EDIT',
  EMPLOYMENT_STATUS_VIEW = 'EMPLOYMENT_STATUS_VIEW',
  EMPLOYMENT_VIEW = 'EMPLOYMENT_VIEW',
  JOB_ASSIGNMENT_EDIT = 'JOB_ASSIGNMENT_EDIT',
  JOB_ASSIGNMENT_VIEW = 'JOB_ASSIGNMENT_VIEW',
  MIGRATE_USERS = 'MIGRATE_USERS',
  PAY_CARD_AUDIT_VIEW = 'PAY_CARD_AUDIT_VIEW',
  PAY_CHANGE_HISTORY_VIEW = 'PAY_CHANGE_HISTORY_VIEW',
  PAY_EDIT = 'PAY_EDIT',
  PAY_HISTORY_VIEW = 'PAY_HISTORY_VIEW',
  SSN_EDIT = 'SSN_EDIT',
  SSN_EXISTS_VIEW = 'SSN_EXISTS_VIEW',
  /** @deprecated replace with EMPLOYEE_VIEW */
  VIEW_MY_PROFILE = 'VIEW_MY_PROFILE',
  /** @deprecated removing */
  VIEW_PERMISSIONS = 'VIEW_PERMISSIONS'
}

export type ImplementationEmploymentStatus = EmploymentStatus & {
  __typename?: 'ImplementationEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type IntegrationSettings = {
  __typename?: 'IntegrationSettings'
  active: Scalars['Boolean']
  syncEmployees: Scalars['Boolean']
  tipsType: Maybe<TipsType>
}

export type InvalidEffectiveDateError = {
  __typename?: 'InvalidEffectiveDateError'
  message: Maybe<Scalars['String']>
}

export type InvalidStartDateError = {
  __typename?: 'InvalidStartDateError'
  message: Maybe<Scalars['String']>
}

export type InvoiceItem = {
  __typename?: 'InvoiceItem'
  total: Scalars['Float']
  uuid: Scalars['ID']
}

export enum IsPayrollPayor {
  no = 'no',
  yesall = 'yesall',
  yesnameonly = 'yesnameonly'
}

export enum IssueSeverity {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARN = 'WARN'
}

export enum IssueType {
  ACTIVE_EE_MAPPED_WITH_ARCHIVED_POS = 'ACTIVE_EE_MAPPED_WITH_ARCHIVED_POS',
  JOB_GUID_IS_MALFORMED = 'JOB_GUID_IS_MALFORMED',
  JOB_IS_MISSING_IN_POS = 'JOB_IS_MISSING_IN_POS',
  JOB_NOT_MAPPED = 'JOB_NOT_MAPPED',
  LOCATION_GUID_IS_MALFORMED = 'LOCATION_GUID_IS_MALFORMED',
  LOCATION_IS_MISSING_IN_POS = 'LOCATION_IS_MISSING_IN_POS',
  LOCATION_NOT_MAPPED = 'LOCATION_NOT_MAPPED',
  MAPPED_INTO_DIFFERENT_TOAST_IDENTITY = 'MAPPED_INTO_DIFFERENT_TOAST_IDENTITY',
  PAYROLL_JOBS_MISMATCH_POS_JOBS = 'PAYROLL_JOBS_MISMATCH_POS_JOBS',
  POS_EE_MAPPING_IGNORED = 'POS_EE_MAPPING_IGNORED',
  POS_EE_MAPPING_UNKNOWN_LOCATION_GUID = 'POS_EE_MAPPING_UNKNOWN_LOCATION_GUID',
  RU_COUNTERPART_IS_MISSING_IN_POS = 'RU_COUNTERPART_IS_MISSING_IN_POS',
  UNMAPPED_PAYROLL_SPECIALTY = 'UNMAPPED_PAYROLL_SPECIALTY',
  UNMAPPED_POS_RU = 'UNMAPPED_POS_RU'
}

export type Item = {
  __typename?: 'Item'
  assignedTo: Assignee
  key: Scalars['ID']
  status: TaskStatus
  subItems: Array<Item>
  title: Scalars['String']
}

export type Job = {
  __typename?: 'Job'
  id: Scalars['ID']
  name: Scalars['String']
}

export type JobAssignment = {
  __typename?: 'JobAssignment'
  activeHourlyRateOfPay: Maybe<Money>
  employmentTaxType: Maybe<EmploymentTaxType>
  id: Scalars['ID']
  isPrimary: Scalars['Boolean']
  jobId: Scalars['ID']
  locationId: Scalars['ID']
  locationName: Scalars['String']
  name: Scalars['String']
  payGroupId: Maybe<Scalars['ID']>
  pendingPayChange: Maybe<PendingPayChange>
}

export type JobAssignmentNotFoundError = {
  __typename?: 'JobAssignmentNotFoundError'
  message: Maybe<Scalars['String']>
}

export type JobInput = {
  restaurantJobGuid: InputMaybe<Scalars['ID']>
  uuid: Scalars['ID']
}

export type JobMapping = {
  __typename?: 'JobMapping'
  payrollJob: PayrollJob
  restaurantJob: RestaurantJob
}

export type JobTag = {
  __typename?: 'JobTag'
  createdByUserGuid: Scalars['ID']
  createdDate: Scalars['Date']
  deletedDate: Maybe<Scalars['Date']>
  id: Scalars['ID']
  modifiedByUserGuid: Maybe<Scalars['ID']>
  modifiedDate: Maybe<Scalars['Date']>
  name: Scalars['String']
  values: Array<Scalars['String']>
}

export type JobTagAssignment = {
  __typename?: 'JobTagAssignment'
  createdByUserGuid: Scalars['ID']
  createdDate: Scalars['Date']
  deletedDate: Maybe<Scalars['Date']>
  id: Scalars['ID']
  jobTagId: Scalars['ID']
  jobTagName: Scalars['String']
  managementSetId: Scalars['ID']
  modifiedByUserGuid: Maybe<Scalars['ID']>
  modifiedDate: Maybe<Scalars['Date']>
  restaurantId: Scalars['ID']
  restaurantJobId: Scalars['ID']
  selectedJobTagValue: Scalars['String']
}

export type JobType = {
  __typename?: 'JobType'
  isOrphan: Maybe<Scalars['Boolean']>
  name: Maybe<Scalars['String']>
  uuid: Scalars['ID']
}

export type LeaveOfAbsenceEmploymentStatus = EmploymentStatus & {
  __typename?: 'LeaveOfAbsenceEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
  leaveType: Maybe<Scalars['String']>
  scheduledLeave: Maybe<DateRange>
}

export type LevelConfig = {
  __typename?: 'LevelConfig'
  name: Scalars['String']
  sequence: Scalars['Int']
  type: Scalars['String']
}

export type LevelId = {
  __typename?: 'LevelId'
  databaseId: Scalars['Int']
  sequenceId: Scalars['Int']
}

export type LevelIdInput = {
  databaseId: Scalars['Int']
  sequenceId: Scalars['Int']
}

export type LevelOption = {
  __typename?: 'LevelOption'
  sequenceId: Scalars['Int']
  uuid: Scalars['String']
}

export type LevelUuidSequence = {
  sequenceId: Scalars['Int']
  uuid: Scalars['String']
}

export type LocalizableMessage = {
  __typename?: 'LocalizableMessage'
  key: Scalars['String']
  message: Scalars['String']
}

export type Location = {
  __typename?: 'Location'
  name: Maybe<Scalars['String']>
  restaurant: Maybe<Restaurant>
  status: LocationMappingStatus
  uuid: Scalars['ID']
}

export type LocationInput = {
  restaurantGuid: InputMaybe<Scalars['ID']>
  uuid: Scalars['ID']
}

export type LocationMappingStatus = {
  __typename?: 'LocationMappingStatus'
  code: LocationMappingStatusCode
  message: Scalars['String']
}

export enum LocationMappingStatusCode {
  NoStatus = 'NoStatus',
  RestaurantGuidConnected = 'RestaurantGuidConnected',
  RestaurantGuidDoubleMapped = 'RestaurantGuidDoubleMapped',
  RestaurantGuidEmpty = 'RestaurantGuidEmpty',
  RestaurantGuidInvalid = 'RestaurantGuidInvalid'
}

export type LumpSumEarningInput = {
  amount: MoneyInput
  earningCodeId: Scalars['ID']
}

export enum MappingCondition {
  MAPPED_INVALID_GUID = 'MAPPED_INVALID_GUID',
  MAPPED_NO_POS_COUNTERPART = 'MAPPED_NO_POS_COUNTERPART',
  MAPPED_USER_MAPPED = 'MAPPED_USER_MAPPED',
  MAPPED_USER_MAPPING_IGNORED = 'MAPPED_USER_MAPPING_IGNORED',
  MAPPED_USER_NOT_MAPPED = 'MAPPED_USER_NOT_MAPPED',
  NOT_MAPPED = 'NOT_MAPPED',
  POS_ONLY = 'POS_ONLY'
}

export type MappingIssue = {
  __typename?: 'MappingIssue'
  severity: IssueSeverity
  type: IssueType
}

export enum MaritalStatus {
  DIVORCED = 'DIVORCED',
  HEAD_OF_HOUSEHOLD = 'HEAD_OF_HOUSEHOLD',
  MARRIED = 'MARRIED',
  SINGLE = 'SINGLE',
  WIDOW = 'WIDOW'
}

export type Message = {
  __typename?: 'Message'
  action: MessageActionType
  amount: Scalars['Float']
  amountType: EnumAmountType
  author: Scalars['String']
  datePosted: Scalars['SpaDateTime']
  name: Scalars['String']
  whom: Scalars['String']
}

export enum MessageActionType {
  approved = 'approved',
  recorded = 'recorded',
  scheduled = 'scheduled'
}

export type MissingPayGroupError = {
  __typename?: 'MissingPayGroupError'
  message: Maybe<Scalars['String']>
}

/** A monetary value with currency. */
export type Money = {
  __typename?: 'Money'
  /** The amount of money */
  amount: Scalars['Decimal']
  /** The currency the money is paid in */
  currency: Currency
}

export type MoneyInput = {
  amount: Scalars['Decimal']
  currency: Currency
}

export type Mutation = {
  __typename?: 'Mutation'
  applyMapping: Scalars['String']
  archive: ArchiveResponse
  archivePayPeriod: Maybe<Scalars['String']>
  changeHelloWorldResponse: Maybe<Response>
  changeHourlyPay: Maybe<JobAssignment>
  /** Change salary pay: provide a list of the updated SalaryAllocations and their new annual pay rate */
  changeSalaryAllocations: ChangeSalaryResponse
  contactOnboarding: Maybe<GenericResponse>
  createBulkDeductions: Maybe<BulkAddResponse>
  createBulkEarnings: Maybe<BulkAddResponse>
  createJobTag: Maybe<JobTag>
  createJobTagAssignment: Maybe<JobTagAssignment>
  /** @deprecated please use updatePendingPayChangeStatuses */
  deletePendingPayChange: Maybe<Scalars['ID']>
  deleteUserMigration: Array<UserMigration>
  migrateUsers: Array<UserMigration>
  open: OpenResponse
  payrollUser: Maybe<PayrollUserMutations>
  post: PostResponse
  rehireEmployeeNoChanges: Scalars['ID']
  rehireEmployeeWithChanges: Scalars['ID']
  reset: Maybe<Scalars['String']>
  resyncTasks: ChecklistState
  rolloverPayrolls: EcPayrollResponse
  saveAdditionalEarnings: Maybe<AdditionalEarningResponse>
  saveDeduction: SaveDeductionResponse
  saveEarning: SaveEarningResponse
  saveNewEmployee: SaveNewEmployeeResponse
  saveTaxFilingEndDate: EcPayrollResponse
  sendJobMappingRequest: Maybe<Scalars['String']>
  setHasPreviousPayrollProvider: GenericResponse
  updateCustomer: Customer
  updateJobMappings: Array<PayrollJob>
  updatePayCardCustomerSettings: UpdatePayCardCustomerSettingsResponse
  updatePendingPayChangeStatuses: Maybe<UpdatePendingPayChangeStatusResponse>
  updateTask: ChecklistState
}

export type MutationApplyMappingArgs = {
  locationId: Scalars['ID']
  payrollEmployeeId: Scalars['ID']
  restaurantUserId: InputMaybe<Scalars['ID']>
}

export type MutationArchiveArgs = {
  payPeriodUuid: Scalars['String']
}

export type MutationArchivePayPeriodArgs = {
  payrollUuid: Scalars['ID']
}

export type MutationChangeHelloWorldResponseArgs = {
  response: Scalars['String']
}

export type MutationChangeHourlyPayArgs = {
  changePayInput: ChangePayInput
  employeeId: Scalars['ID']
  jobAssignmentId: Scalars['ID']
}

export type MutationChangeSalaryAllocationsArgs = {
  changeSalaryAllocations: Array<ChangeSalaryAllocationInput>
  employeeId: Scalars['ID']
}

export type MutationContactOnboardingArgs = {
  emailContent: InputMaybe<Scalars['String']>
}

export type MutationCreateBulkDeductionsArgs = {
  input: BulkDeductionMutation
  payrollUuid: Scalars['ID']
}

export type MutationCreateBulkEarningsArgs = {
  input: BulkEarningMutation
  payrollUuid: Scalars['ID']
}

export type MutationCreateJobTagArgs = {
  jobTagInput: CreateJobTagInput
}

export type MutationCreateJobTagAssignmentArgs = {
  jobTagAssignmentInput: CreateJobTagAssignmentInput
}

export type MutationDeletePendingPayChangeArgs = {
  employeeId: Scalars['ID']
  pendingPayChangeId: Scalars['ID']
}

export type MutationDeleteUserMigrationArgs = {
  importId: Scalars['ID']
}

export type MutationMigrateUsersArgs = {
  input: UserMigrationInput
}

export type MutationOpenArgs = {
  payPeriodUuid: Scalars['String']
}

export type MutationPostArgs = {
  consentBankAccountUuids: InputMaybe<Array<Scalars['String']>>
  payPeriodUuid: Scalars['String']
}

export type MutationRehireEmployeeNoChangesArgs = {
  request: RehireEmployeeNoChangesInput
}

export type MutationRehireEmployeeWithChangesArgs = {
  employeeId: Scalars['ID']
  rehireDate: Scalars['Date']
  request: NewEmployeeInput
}

export type MutationResetArgs = {
  payPeriodUuid: Scalars['ID']
}

export type MutationRolloverPayrollsArgs = {
  payPeriodUuid: Scalars['String']
}

export type MutationSaveAdditionalEarningsArgs = {
  employeeId: Scalars['ID']
  hourlyEarnings?: Array<HourlyEarningInput>
  jobAssignmentId: Scalars['ID']
  lumpSumEarnings?: Array<LumpSumEarningInput>
}

export type MutationSaveDeductionArgs = {
  deduction: SaveDeduction
  deductionUuid: InputMaybe<Scalars['String']>
  payPeriodUuid: Scalars['String']
}

export type MutationSaveEarningArgs = {
  earning: SaveEarning
  earningUuid: InputMaybe<Scalars['String']>
  payPeriodUuid: Scalars['String']
}

export type MutationSaveNewEmployeeArgs = {
  request: NewEmployeeInput
}

export type MutationSaveTaxFilingEndDateArgs = {
  customerFeinUuid: Scalars['String']
  taxFilingEndDate: InputMaybe<Scalars['Date']>
}

export type MutationSendJobMappingRequestArgs = {
  attachedNote: InputMaybe<Scalars['String']>
  employeeUuid: Scalars['ID']
  payrollJobId: InputMaybe<LevelIdInput>
  payrollLocationId: InputMaybe<LevelIdInput>
}

export type MutationSetHasPreviousPayrollProviderArgs = {
  hasPreviousPayrollProvider: Scalars['Boolean']
}

export type MutationUpdateCustomerArgs = {
  customerInput: CustomerInput
}

export type MutationUpdateJobMappingsArgs = {
  jobs: Array<JobInput>
  locationId: Scalars['ID']
}

export type MutationUpdatePayCardCustomerSettingsArgs = {
  input: UpdatePayCardCustomerSettingsInput
}

export type MutationUpdatePendingPayChangeStatusesArgs = {
  employeeId: Scalars['ID']
  updatePendingPayChangeStatusInput?: Array<PendingPayChangeStatusInput>
}

export type MutationUpdateTaskArgs = {
  status: TaskStatus
  taskKey: Scalars['String']
}

export type MutationError = {
  errors: Array<Error>
  status: ResponseStatus
}

export type MutationSuccess = {
  status: ResponseStatus
}

export type Navigation = {
  __typename?: 'Navigation'
  children: Array<Navigation>
  /** @deprecated Field no longer supported */
  label: Scalars['String']
  labelNew: LocalizableMessage
  matchUrls: Array<Scalars['String']>
  name: Scalars['String']
  pageType: Maybe<PageType>
  path: Scalars['String']
  url: Scalars['String']
}

export type NavigationError = {
  __typename?: 'NavigationError'
  message: Scalars['String']
  type: Scalars['String']
}

export type NavigationErrors = {
  __typename?: 'NavigationErrors'
  errors: Array<NavigationError>
  path: Scalars['String']
}

export type NavigationInput = {
  params: InputMaybe<Array<NavigationParam>>
  path: InputMaybe<Scalars['String']>
}

export type NavigationParam = {
  key: Scalars['String']
  value: Scalars['String']
}

export type NavigationResult = {
  __typename?: 'NavigationResult'
  errors: Array<NavigationErrors>
  result: Maybe<Navigation>
}

export type NeverEmployedEmploymentStatus = EmploymentStatus & {
  __typename?: 'NeverEmployedEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type NewEmployeeInput = {
  benefits: BenefitsInput
  documents: Array<Scalars['ID']>
  employment: EmploymentDetailsInput
  jobsAndPay: NewJobsAndPayInput
  overtimeEligible: Scalars['Boolean']
  person: PersonInput
  securityRoleId: Scalars['ID']
}

export type NewHireDocument = {
  __typename?: 'NewHireDocument'
  id: Scalars['ID']
  name: Scalars['String']
}

export type NewJobAssignment = {
  __typename?: 'NewJobAssignment'
  hourlyRate: Maybe<Money>
  jobId: Scalars['ID']
  locationId: Scalars['ID']
}

export type NewJobAssignmentInput = {
  hourlyRate: InputMaybe<MoneyInput>
  jobId: Scalars['ID']
  locationId: Scalars['ID']
}

export type NewJobsAndPay = {
  __typename?: 'NewJobsAndPay'
  annualSalary: Maybe<Money>
  jobs: Array<NewJobAssignment>
  payGroupAssignments: Array<PayGroupAssignment>
  salaryPerPayPeriod: Maybe<Money>
  salaryRateOfPay: Maybe<Money>
}

export type NewJobsAndPayInput = {
  annualSalary: InputMaybe<MoneyInput>
  jobs: Array<NewJobAssignmentInput>
  payGroupAssignments: Array<PayGroupAssignmentInput>
  salaryPerPayPeriod: InputMaybe<MoneyInput>
  salaryRateOfPay: InputMaybe<MoneyInput>
}

export type NextPayPeriodNotFoundError = {
  __typename?: 'NextPayPeriodNotFoundError'
  message: Maybe<Scalars['String']>
}

export type NextPaycheckDateNotFoundError = {
  __typename?: 'NextPaycheckDateNotFoundError'
  message: Maybe<Scalars['String']>
}

export type NonCashRequirement = {
  __typename?: 'NonCashRequirement'
  employeesManualPay: Array<EmployeeNetPays>
  vendorChecksNetPays: Array<VendorNetPays>
  voidNetPayment: Array<PayCheck>
}

export enum OnboardingCheckListPermission {
  EDIT_PAYROLL_ONBOARDING = 'EDIT_PAYROLL_ONBOARDING',
  VIEW_PAYROLL_ONBOARDING = 'VIEW_PAYROLL_ONBOARDING'
}

export type OpenError = MutationError & {
  __typename?: 'OpenError'
  errors: Array<Error>
  status: ResponseStatus
}

export type OpenResponse = OpenError | OpenSuccess

export type OpenSuccess = MutationSuccess & {
  __typename?: 'OpenSuccess'
  status: ResponseStatus
}

export enum PageType {
  HEADER_ONLY = 'HEADER_ONLY',
  LINK_ONLY = 'LINK_ONLY'
}

export type Paged = {
  __typename?: 'Paged'
  pageCount: Scalars['Int']
  pageIndex: Scalars['Int']
  total: Scalars['Int']
}

export type PayCardAdminSettings = {
  __typename?: 'PayCardAdminSettings'
  payCardAdminPayoutsLocked: Maybe<Scalars['Boolean']>
}

export enum PayCardBooleanAccessFactor {
  FALSE = 'FALSE',
  TRUE = 'TRUE',
  UNKNOWN = 'UNKNOWN'
}

export type PayCardBusinessAccess = {
  __typename?: 'PayCardBusinessAccess'
  businessId: Maybe<Scalars['ID']>
  existsInPayroll: Scalars['Boolean']
  fein: Scalars['String']
  isContractComplete: Scalars['Boolean']
  restaurants: Array<PayCardRestaurantAccess>
}

export type PayCardCustomerAccess = {
  __typename?: 'PayCardCustomerAccess'
  businesses: Array<PayCardBusinessAccess>
  customerUuid: Scalars['ID']
  detachedRestaurants: Array<PayCardRestaurantAccess>
}

export type PayCardCustomerAccessInput = {
  skipCache: Scalars['Boolean']
}

export type PayCardCustomerSettings = {
  __typename?: 'PayCardCustomerSettings'
  payCardAdminSettings: Maybe<PayCardAdminSettings>
  payCardPayoutConfiguration: Maybe<PayCardPayoutConfiguration>
  payCardPayoutEnabled: Maybe<Scalars['Boolean']>
  payCardTipsConfiguration: Maybe<PayCardTipsConfiguration>
}

/** Represents one atomic change to customer settings. */
export type PayCardCustomerSettingsAuditEntry = {
  __typename?: 'PayCardCustomerSettingsAuditEntry'
  change: PayCardCustomerSettingsAuditHistoryChangeContent
  /** The timestamp in which the change was made */
  datetime: Scalars['DateTime']
  /** User that made the change */
  user: PayCardCustomerSettingsAuditUser
}

/**
 * Content that represents what the change was that occurred.
 * Note we only return a message key here not the actual string.
 * The front end will need to resolve the actual localized
 * string.
 */
export type PayCardCustomerSettingsAuditHistoryChangeContent = {
  __typename?: 'PayCardCustomerSettingsAuditHistoryChangeContent'
  messageKey: Scalars['String']
}

export type PayCardCustomerSettingsAuditUser = {
  __typename?: 'PayCardCustomerSettingsAuditUser'
  displayName: Scalars['String']
}

export type PayCardEmployeeAccess = {
  __typename?: 'PayCardEmployeeAccess'
  accessFactors: PayCardEmployeeAccessFactors
  accessLevel: PayCardEmployeeAccessLevel
  employeeUuid: Scalars['ID']
}

export type PayCardEmployeeAccessFactors = {
  __typename?: 'PayCardEmployeeAccessFactors'
  cardActivated: Maybe<PayCardBooleanAccessFactor>
  highestRestaurantAccessLevel: Maybe<PayCardRestaurantAccessLevel>
  isEmployed: Maybe<Scalars['Boolean']>
  isLocEnabled: Maybe<PayCardBooleanAccessFactor>
  isWorkTaxLocationStateEligible: Maybe<Scalars['Boolean']>
}

export type PayCardEmployeeAccessInput = {
  employeeUuid: Scalars['ID']
  skipCache: Scalars['Boolean']
}

export enum PayCardEmployeeAccessLevel {
  NONE = 'NONE',
  PAYCARD = 'PAYCARD',
  PAYOUTS = 'PAYOUTS',
  SIGNUP = 'SIGNUP'
}

export enum PayCardEmployerAccessFactors {
  ENROLLMENT_FEATURE_FLAG = 'ENROLLMENT_FEATURE_FLAG',
  FEIN_IN_PAYROLL = 'FEIN_IN_PAYROLL',
  HAS_COMPLETED_CONTRACT = 'HAS_COMPLETED_CONTRACT',
  PAYOUT_ENTITLEMENT = 'PAYOUT_ENTITLEMENT',
  PAY_CARD_ENTITLEMENT = 'PAY_CARD_ENTITLEMENT',
  SDP_FEATURE_FLAG = 'SDP_FEATURE_FLAG'
}

export enum PayCardEmployerAccessLevel {
  EMPLOYEE_PAYOUTS = 'EMPLOYEE_PAYOUTS',
  EMPLOYEE_SIGNUP = 'EMPLOYEE_SIGNUP',
  NONE = 'NONE',
  ONBOARDING = 'ONBOARDING'
}

export enum PayCardPayoutConfiguration {
  TIPS_ONLY = 'TIPS_ONLY',
  WAGES_AND_TIPS = 'WAGES_AND_TIPS',
  WAGES_ONLY = 'WAGES_ONLY'
}

export type PayCardRestaurantAccess = {
  __typename?: 'PayCardRestaurantAccess'
  accessFactors: Array<PayCardEmployerAccessFactors>
  accessLevel: PayCardEmployerAccessLevel
  restaurantGuid: Scalars['ID']
}

export enum PayCardRestaurantAccessLevel {
  NONE = 'NONE',
  PAYOUTS = 'PAYOUTS',
  SIGNUP = 'SIGNUP'
}

export enum PayCardTipsConfiguration {
  TIPS_ALL = 'TIPS_ALL',
  TIPS_WITH_WITHHOLDING = 'TIPS_WITH_WITHHOLDING'
}

export type PayChangeEvent = {
  __typename?: 'PayChangeEvent'
  /** Information about the actor that initiated the pay change event. If null, the actor is unknown. */
  actor: Maybe<Actor>
  /** The date and time the pay change event was initiated. If null, the date is unknown. */
  createdAt: Maybe<Scalars['DateTime']>
  /** The current pay rate for the job to which the pay change event is referencing. */
  currentRate: AmountWithPayInterval
  /** The date and time the pay change did / will take effect. */
  effectiveAt: Maybe<Scalars['DateTime']>
  /** The ID of the pay change event */
  id: Scalars['ID']
  /** The job name to which the pay change event is referencing */
  jobName: Scalars['String']
  /** The location name for the job to which the pay change event is referencing */
  locationName: Scalars['String']
  /**
   * The previous pay rate for the job to which the pay change event is referencing.
   * If null, this pay change event is not tied to a previous pay, i.e. for new jobs
   */
  previousRate: Maybe<AmountWithPayInterval>
  /** The current status of the pay change even. */
  status: PayChangeEventStatus
}

export enum PayChangeEventStatus {
  CANCELLATION_APPLIED = 'CANCELLATION_APPLIED',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  ERROR_ACKNOWLEDGED = 'ERROR_ACKNOWLEDGED',
  NEW_JOB = 'NEW_JOB',
  SCHEDULED = 'SCHEDULED',
  UPDATED = 'UPDATED'
}

export type PayChangeLog = {
  __typename?: 'PayChangeLog'
  /**
   * Page token used to retrieve the current page of pay change events. Passing this value through as the pageToken in
   * subsequent requests will result in the same pay change events being retrieved.
   */
  currentPageToken: Scalars['String']
  /** The employee's pay change events, default sort is via createdDate newest -> oldest */
  data: Maybe<Array<PayChangeEvent>>
  /**
   * Page token used to retrieve the next page of pay change events. When this field is null that indicates that
   * [currentPageToken] was the last page in the result set.
   */
  nextPageToken: Maybe<Scalars['String']>
  /**
   * Page token used to retrieve the previous page of pay change events. When the field is null that indicates that indicates
   * that the [currentPageToken] was the first page in the result set.
   */
  previousPageToken: Maybe<Scalars['String']>
  /** The total count of this employee's pay change events */
  totalResultCount: Scalars['Int']
}

export type PayChangeLogResponse = PayChangeLog

export enum PayChangeLogSortField {
  CREATE_DATE = 'CREATE_DATE'
}

export type PayChangeLogSortInput = {
  /** Whether to sort in ascending or descending order for this field */
  direction: SortDirection
  /** The field by which to sort the pay change log events */
  sortField: PayChangeLogSortField
}

export type PayCheck = {
  __typename?: 'PayCheck'
  netEarnings: Scalars['Float']
}

export type PayCheckDeduction = {
  __typename?: 'PayCheckDeduction'
  amount: Scalars['Float']
  deductionCodeUuid: Scalars['String']
  isPercentage: Scalars['Boolean']
  isReadOnly: Scalars['Boolean']
  name: Scalars['String']
  uuid: Scalars['String']
}

export type PayCheckDeliverySettings = {
  __typename?: 'PayCheckDeliverySettings'
  method: DeliveryMethod
  needsPdfFile: Scalars['Boolean']
  usingToastCheckStock: Scalars['Boolean']
}

export type PayCheckEarning = {
  __typename?: 'PayCheckEarning'
  amount: Scalars['Float']
  baseRate: Scalars['Float']
  checkCode: CheckCode
  earningCode: EarningCode
  hours: Scalars['Float']
  isFirstWeek: Scalars['Boolean']
  job: Scalars['String']
  location: Scalars['String']
  name: Scalars['String']
  rate: Scalars['Float']
  uuid: Scalars['String']
}

export type PayCheckTaxes = {
  __typename?: 'PayCheckTaxes'
  amount: Scalars['Float']
  isCollectedByToast: Scalars['Boolean']
  name: Scalars['String']
}

export type PayCheckV2 = {
  __typename?: 'PayCheckV2'
  checkCode: CheckCode
  deductions: Array<PayCheckDeduction>
  earnings: Array<PayCheckEarning>
  isManual: Scalars['Boolean']
  isReadOnly: Scalars['Boolean']
  isVoid: Scalars['Boolean']
  number: Scalars['Int']
  paidByCheck: Scalars['Boolean']
  uuid: Scalars['String']
}

export type PayDetails = {
  __typename?: 'PayDetails'
  checkDate: Maybe<Scalars['SpaDate']>
  gross: Scalars['Float']
  net: Scalars['Float']
  payStubs: Array<PayStub>
  taxes: Scalars['Float']
}

export type PayFrequencies = {
  __typename?: 'PayFrequencies'
  active: Scalars['Boolean']
  calcMethod: Scalars['String']
  default: Scalars['Boolean']
  frequency: Scalars['String']
  name: Scalars['String']
  uuid: Scalars['String']
}

export enum PayFrequency {
  ANNUAL = 'ANNUAL',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  NONE = 'NONE',
  QUARTERLY = 'QUARTERLY',
  SEMIANNUAL = 'SEMIANNUAL',
  SEMIMONTHLY = 'SEMIMONTHLY',
  WEEKLY = 'WEEKLY'
}

export type PayGroup = {
  __typename?: 'PayGroup'
  id: Scalars['ID']
  name: Scalars['String']
}

export type PayGroupAssignment = {
  __typename?: 'PayGroupAssignment'
  checkCodeId: Scalars['ID']
  locationIds: Array<Scalars['ID']>
  payGroupId: Scalars['ID']
  workTaxLocationId: Scalars['ID']
}

export type PayGroupAssignmentInput = {
  checkCodeId: Scalars['ID']
  locationIds: Array<Scalars['ID']>
  payGroupId: Scalars['ID']
  workTaxLocationId: Scalars['ID']
}

export type PayGroupCalendar = {
  __typename?: 'PayGroupCalendar'
  payGroupId: Scalars['String']
  payPeriods: Array<PayPeriodCalendar>
}

export type PayGroupCalendarResult = PayGroupCalendar | Unauthorized

export type PayGroupSetting = {
  __typename?: 'PayGroupSetting'
  bankAccount: Maybe<BankAccount>
  name: Scalars['String']
  payFrequencies: Array<PayFrequencies>
  weekStartDay: Maybe<Scalars['String']>
}

export type PayGroupWithFrequency = {
  __typename?: 'PayGroupWithFrequency'
  checkCodeId: Scalars['ID']
  id: Scalars['String']
  name: Scalars['String']
  payGroupId: Scalars['ID']
  periodsPerYear: Scalars['Int']
}

export type PayHistoryAudit = {
  __typename?: 'PayHistoryAudit'
  paging: Paged
  records: Maybe<Array<PayHistoryAuditRecord>>
}

export type PayHistoryAuditRecord = {
  __typename?: 'PayHistoryAuditRecord'
  action: Scalars['String']
  modifiedBy: Scalars['String']
  modifiedDate: Scalars['SpaDateTime']
  payPeriod: Scalars['String']
}

export type PayHistoryFilters = {
  feinId: InputMaybe<Scalars['Int']>
  from: InputMaybe<Scalars['SpaDateTime']>
  to: InputMaybe<Scalars['SpaDateTime']>
}

export type PayHistoryPage = {
  __typename?: 'PayHistoryPage'
  FeinList: Array<FeinOption>
  employeeUuid: Scalars['ID']
  latest: Maybe<PayDetails>
  paging: Paged
  payHistory: Maybe<Array<PayDetails>>
}

export enum PayHistoryReportType {
  DETAIL = 'DETAIL',
  SUMMARY = 'SUMMARY'
}

export enum PayInterval {
  ANNUAL = 'ANNUAL',
  HOUR = 'HOUR'
}

export type PayPeriod = {
  __typename?: 'PayPeriod'
  adjustmentUrl: Scalars['String']
  checkCode: CheckCode
  checkCodeName: Scalars['String']
  checkCodeUuid: Scalars['String']
  checkDate: Scalars['SpaDate']
  checkDateOverride: Maybe<Scalars['SpaDate']>
  checkPackages: Maybe<Array<Maybe<CheckPackage>>>
  dashboardUrl: Scalars['String']
  datePosted: Maybe<Scalars['SpaDate']>
  debitDate: Maybe<Scalars['SpaDate']>
  deliveryMethod: DeliveryMethod
  dueDate: Maybe<Scalars['SpaDateTime']>
  employeesSpreadsheetUrl: Scalars['String']
  endDate: Scalars['SpaDate']
  finalCheckDate: Maybe<Scalars['SpaDate']>
  forceLiveChecks: Scalars['Boolean']
  isReadOnly: Maybe<Scalars['Boolean']>
  listUrl: Scalars['String']
  messages: Array<Message>
  name: Scalars['String']
  paperChecksSettingsUrl: Scalars['String']
  /** @deprecated Replaced by combination of dueDate and postDeadline. */
  payrollDate: Maybe<Scalars['SpaDateTime']>
  postDeadline: Scalars['Int']
  postDeadlineText: PostDeadline
  preflightUrl: Scalars['String']
  previewUrl: Scalars['String']
  previousPayPeriodEndDate: Maybe<Scalars['SpaDate']>
  previousPayPeriodStartDate: Maybe<Scalars['SpaDate']>
  previousPayPeriodUuid: Maybe<Scalars['String']>
  previousProviderPayrollType: Maybe<PreviousProviderPayrollType>
  reviewUrl: Scalars['String']
  startDate: Scalars['SpaDate']
  status: Scalars['Int']
  taxDebitDate: Scalars['SpaDate']
  taxesImportUrl: Scalars['String']
  timesheetsImportUrl: Scalars['String']
  timesheetsImportUrlAloha: Maybe<Scalars['String']>
  timesheetsUrl: Scalars['String']
  toastPrintedChecks: Maybe<ToastPrinted>
  uuid: Scalars['String']
}

export type PayPeriodCalendar = {
  __typename?: 'PayPeriodCalendar'
  checkDate: Scalars['Date']
  isArchived: Scalars['Boolean']
  startDate: Scalars['Date']
  status: PayrollStatusV2
}

export type PayStub = {
  __typename?: 'PayStub'
  /** @deprecated This function causes contention on the ec-web servers. Use the downloadUrl and pdfDownloadUrl instead */
  DownloadURL: Scalars['String']
  business: Maybe<Scalars['String']>
  checkDate: Scalars['SpaDate']
  checkFile: Scalars['String']
  downloadUrl: Scalars['String']
  employeeUuid: Scalars['ID']
  /** @deprecated if you want the name, use business. If you want fein number, use feinNumber */
  fein: Maybe<Scalars['String']>
  feinNumber: Maybe<Scalars['String']>
  payPeriod: Scalars['String']
  payPeriodId: Scalars['LegacyId']
  payStubNumber: Scalars['Int']
  pdfDownloadUrl: Scalars['String']
}

export type PayStubDownloadUrlArgs = {
  isPDF: InputMaybe<Scalars['Boolean']>
}

export type Paycheck = {
  __typename?: 'Paycheck'
  date: Maybe<Scalars['Date']>
}

export type PayrollCustomerUser = {
  __typename?: 'PayrollCustomerUser'
  companyCode: Scalars['String']
  customerId: Scalars['ID']
  customerName: Scalars['String']
  email: Scalars['String']
  id: Scalars['ID']
  shardName: Scalars['String']
  toastIdentityGuid: Maybe<Scalars['ID']>
  username: Scalars['String']
}

export enum PayrollCustomerUserSource {
  DB = 'DB',
  DEX = 'DEX'
}

export type PayrollCustomerUsersInput = {
  source: PayrollCustomerUserSource
  toastIdentityGuid: InputMaybe<Scalars['ID']>
  userId: InputMaybe<Scalars['ID']>
  username: InputMaybe<Scalars['String']>
}

/**
 * Represents employees tied to a payroll with a paycheck in a payperiod.
 * Corresponds to PayrollEmployeeSummary type in ec-api.
 */
export type PayrollEmployee = {
  __typename?: 'PayrollEmployee'
  employeeNumber: Scalars['String']
  employeeUuid: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  salaryType: Maybe<SalaryType>
  timesheetTiedToPayrollSetting: Maybe<TimeSheetTiedToPayroll>
  validSsn: Scalars['Boolean']
}

export type PayrollEmployeeMapping = {
  __typename?: 'PayrollEmployeeMapping'
  name: PersonName
  payrollEmployeeNumber: Maybe<Scalars['String']>
}

export type PayrollEmployeePosition = {
  __typename?: 'PayrollEmployeePosition'
  isPrimary: Scalars['Boolean']
  levels: Array<Maybe<PayrollEmployeePositionLevel>>
  uuid: Scalars['String']
}

export type PayrollEmployeePositionLevel = {
  __typename?: 'PayrollEmployeePositionLevel'
  sequenceId: Scalars['Int']
  uuid: Maybe<Scalars['String']>
}

/**
 * Used to represent data for all salaried or hourly employees, regardless of whether they will really appear in paycheck.
 * The type is only used internally, and should be converted to PayrollEmployee if being served publicly.
 */
export type PayrollEmployeeScheduled = {
  __typename?: 'PayrollEmployeeScheduled'
  employeeNumber: Scalars['String']
  employeeUuid: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  salaryType: Maybe<SalaryType>
  timesheetTiedToPayrollSetting: Maybe<TimeSheetTiedToPayroll>
  validSsn: Scalars['Boolean']
}

export type PayrollEmployeeV2 = {
  __typename?: 'PayrollEmployeeV2'
  employeeNumber: Scalars['String']
  employeeUuid: Scalars['String']
  name: Scalars['String']
  payPeriodUuid: Scalars['String']
  paystubs: Array<PayCheckV2>
}

export type PayrollJob = {
  __typename?: 'PayrollJob'
  name: Scalars['String']
  uuid: Scalars['ID']
}

export type PayrollLevelOption = {
  __typename?: 'PayrollLevelOption'
  isOrphan: Scalars['Boolean']
  name: Scalars['String']
  sequenceId: Scalars['Int']
  uuid: Scalars['String']
}

export type PayrollLocation = {
  __typename?: 'PayrollLocation'
  id: Scalars['ID']
  jobs: Array<Job>
  name: Scalars['String']
}

export type PayrollRequirement = {
  __typename?: 'PayrollRequirement'
  cashRequirement: CashRequirement
  nonCashRequirement: NonCashRequirement
}

export type PayrollSecurityRole = {
  __typename?: 'PayrollSecurityRole'
  id: Scalars['ID']
  name: Scalars['String']
}

export type PayrollSettings = {
  __typename?: 'PayrollSettings'
  /** @deprecated shouldn't be used in payroll setting domain only customer */
  customerPrintsW2: Maybe<Scalars['Boolean']>
  deadlineEnforcement: Maybe<Scalars['Boolean']>
  importTimesheetEarningsByWeek: Maybe<Scalars['Boolean']>
  matchTimesheetToPayperiod: Maybe<Scalars['Boolean']>
  payrollPayor: Maybe<IsPayrollPayor>
  payrollPostDeadline: PostDeadline
  useBlendedOT: Maybe<Scalars['Boolean']>
}

export enum PayrollStatus {
  calculated = 'calculated',
  created = 'created',
  editing = 'editing',
  generated_ach = 'generated_ach',
  import = 'import',
  opened = 'opened',
  posted = 'posted',
  posting = 'posting',
  processing = 'processing',
  scheduled = 'scheduled',
  scheduling = 'scheduling',
  unknown = 'unknown',
  verified = 'verified'
}

export enum PayrollStatusV2 {
  CALCULATED = 'CALCULATED',
  CREATED = 'CREATED',
  EDITING = 'EDITING',
  EMPLOYEE_MAINTENANCE = 'EMPLOYEE_MAINTENANCE',
  G2N = 'G2N',
  GENERATED_ACH = 'GENERATED_ACH',
  IMPORT = 'IMPORT',
  OPENED = 'OPENED',
  POSTED = 'POSTED',
  POSTING = 'POSTING',
  POST_PAYROLL = 'POST_PAYROLL',
  PROCESSING = 'PROCESSING',
  PR_MAINTENANCE = 'PR_MAINTENANCE',
  REPORTS = 'REPORTS',
  SCHEDULED = 'SCHEDULED',
  SCHEDULING = 'SCHEDULING',
  SPREADSHEET = 'SPREADSHEET',
  STATUS_CHANGES = 'STATUS_CHANGES',
  UNKNOWN = 'UNKNOWN',
  VACATION = 'VACATION',
  VERIFIED = 'VERIFIED'
}

export type PayrollTotalRequirements = {
  __typename?: 'PayrollTotalRequirements'
  cashRequirement: Scalars['Float']
  nonCashRequirement: Scalars['Float']
  nonDebitedTaxes: Scalars['Float']
}

export type PayrollUser = {
  __typename?: 'PayrollUser'
  customerId: Scalars['ID']
  email: Maybe<Scalars['String']>
  employeeId: Maybe<Scalars['ID']>
  id: Scalars['ID']
  toastIdentityGuid: Maybe<Scalars['ID']>
  username: Scalars['String']
}

export type PayrollUserMutations = {
  __typename?: 'PayrollUserMutations'
  link: PayrollUser
  unlink: PayrollUser
}

export type PayrollUserMutationsLinkArgs = {
  customerId: Scalars['ID']
  toastIdentityGuid: Scalars['ID']
  userId: Scalars['ID']
}

export type PayrollUserMutationsUnlinkArgs = {
  customerId: Scalars['ID']
  userId: Scalars['ID']
}

export type PayrollWorkTaxLocation = {
  __typename?: 'PayrollWorkTaxLocation'
  id: Scalars['ID']
  name: Scalars['String']
}

export type PendingEmploymentStatus = EmploymentStatus & {
  __typename?: 'PendingEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type PendingPayChange = {
  __typename?: 'PendingPayChange'
  effectiveDate: Scalars['Date']
  id: Scalars['ID']
  rate: Money
  status: PendingPayChangeStatus
}

export type PendingPayChangeNotFoundError = {
  __typename?: 'PendingPayChangeNotFoundError'
  message: Maybe<Scalars['String']>
}

export type PendingPayChangeNotFoundErrors = {
  __typename?: 'PendingPayChangeNotFoundErrors'
  pendingPayChangeNotFoundErrors: Array<PendingPayChangeNotFoundError>
}

export enum PendingPayChangeStatus {
  APPLIED = 'APPLIED',
  CANCELED = 'CANCELED',
  ERROR = 'ERROR',
  ERROR_ACKNOWLEDGED = 'ERROR_ACKNOWLEDGED',
  PENDING = 'PENDING'
}

export type PendingPayChangeStatusInput = {
  pendingPayChangeId: Scalars['ID']
  pendingPayChangeStatus: PendingPayChangeStatus
}

export type PendingPayChangeStatusResponse = {
  __typename?: 'PendingPayChangeStatusResponse'
  pendingPayChanges: Array<PendingPayChange>
}

export type Person = {
  __typename?: 'Person'
  chosenName: Maybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  phoneNumber: Maybe<Scalars['String']>
  posAccessCode: Scalars['Int']
  ssn: Maybe<Scalars['String']>
}

export type PersonInput = {
  chosenName: InputMaybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  phoneNumber: InputMaybe<Scalars['String']>
  posAccessCode: Scalars['Int']
  ssn: InputMaybe<Scalars['String']>
}

export type PersonName = {
  __typename?: 'PersonName'
  chosen: Maybe<Scalars['String']>
  first: Maybe<Scalars['String']>
  formatted: Maybe<Scalars['String']>
  last: Maybe<Scalars['String']>
  middle: Maybe<Scalars['String']>
}

export type PosEmployee = {
  __typename?: 'PosEmployee'
  archived: Scalars['Boolean']
  email: Scalars['String']
  externalEmployeeId: Maybe<Scalars['String']>
  mappedToEmployee: Maybe<PayrollEmployeeMapping>
  mostRecentClockInDate: Maybe<Scalars['Date']>
  restaurantUserGuid: Scalars['ID']
  userName: PersonName
}

export enum PostDeadline {
  DayOfPayDate = 'DayOfPayDate',
  FiveDaysBefore = 'FiveDaysBefore',
  FourDaysBefore = 'FourDaysBefore',
  NotAllowedToPost = 'NotAllowedToPost',
  OneDayBefore = 'OneDayBefore',
  ThreeDaysBefore = 'ThreeDaysBefore',
  TwoDaysBefore = 'TwoDaysBefore'
}

export type PostError = MutationError & {
  __typename?: 'PostError'
  errors: Array<Error>
  status: ResponseStatus
}

export type PostResponse = PostError | PostSuccess

export type PostSuccess = MutationSuccess & {
  __typename?: 'PostSuccess'
  status: ResponseStatus
}

export type PostalAddress = {
  __typename?: 'PostalAddress'
  administrativeArea: Scalars['String']
  country: Country
  locality: Scalars['String']
  postalCode: Scalars['String']
  streetAddress: Scalars['String']
  streetAddress2: Maybe<Scalars['String']>
}

export enum PreviousProviderPayrollType {
  current_quarter_prior = 'current_quarter_prior',
  none = 'none',
  parallel = 'parallel'
}

export type PreviousYearUnprocessedPayrolls = {
  __typename?: 'PreviousYearUnprocessedPayrolls'
  canWriteToAllPayrolls: Scalars['Boolean']
  totalCount: Scalars['Int']
  unprocessedPayrolls: Maybe<Array<UnprocessedPayroll>>
}

export type PreviousYearUnprocessedPayrollsQuerySuccess = QuerySuccess & {
  __typename?: 'PreviousYearUnprocessedPayrollsQuerySuccess'
  payrolls: PreviousYearUnprocessedPayrolls
  status: ResponseStatus
}

export type PreviousYearUnprocessedPayrollsResponse =
  | PreviousYearUnprocessedPayrollsQuerySuccess
  | QueryErrorResponse

export type Query = {
  __typename?: 'Query'
  PayHistory: PayHistoryPage
  PayHistoryAudit: PayHistoryAudit
  PayHistoryReport: Scalars['String']
  PayHistoryReportPDF: Scalars['String']
  accessibleCustomers: AccessibleCustomersResult
  activeEarningCodes: Array<EarningCode>
  actorPermissionsForEmployees: Array<ActorPermissionsForEmployee>
  audit: Array<Audit>
  bankConsentStatus: Maybe<Array<BankAccountConsentInfo>>
  checkAvailability: AvailabilityResponse
  checkCodes: Array<CheckCode>
  checkCodesConfig: CheckCodesConfigResponse
  companySummary: CompanySummary
  customer: Customer
  customerBenefits: Maybe<Array<Maybe<Benefit>>>
  customerConfiguration: CustomerConfiguration
  customerSettings: CustomerSettings
  earningCodes: Array<EarningCode>
  /** @deprecated Please use findEmployeeById */
  employee: Employee
  employeePositions: Array<PayrollEmployeePosition>
  feins: Array<Fein>
  findCustomerSettings: Array<CustomerSetting>
  findEmployeeById: Maybe<Employee>
  /** @deprecated Please use findEmployeeById */
  findEmployeePay: Maybe<EmployeePay>
  /** @deprecated Please use findEmployeeById */
  findEmployment: Maybe<Employment>
  findJobMappingByJobId: Maybe<JobMapping>
  findJobMappingsByLocationId: Array<JobMapping>
  findPayrollEarningLevels: Array<LevelOption>
  findPayrollJobsByLocationId: Array<PayrollJob>
  findRestaurantJobsByRestaurantId: Array<RestaurantJob>
  findUserByEmployeeId: Maybe<User>
  findUserById: Maybe<User>
  /** @deprecated Please use findUserById */
  findUsers: Maybe<Users>
  getApprovedExpenseReports: Array<ExpenseReport>
  getEmployeeComparison: EmployeeComparison
  getEmployeeMappingStatus: EmployeeMappingStatus
  getLevel: Array<PayrollLevelOption>
  /**
   * ALL hourly and salaried associated with a payroll
   * Will only include salaried employees; and hourly employees with a timesheet in the pay period
   * Should NOT be used for periods prior to the latest/currently open, as those will not properly incorporate employees from timesheets
   * See getEmployeeComparison for that use case instead
   */
  getPayrollEmployees: Array<PayrollEmployee>
  getPayrollEmployeesPosted: Array<PayrollEmployee>
  getPosMappingOptions: Array<PosEmployee>
  helloWorld: Response
  jobAssignmentsById: Array<JobAssignment>
  jobs: Array<JobType>
  levelsConfig: Array<LevelConfig>
  migrations: Array<UserMigration>
  navigation: NavigationResult
  newHireRequiredDocumentsForAssignedFields: Array<NewHireDocument>
  nextAvailableEmployeeNumber: Scalars['Int']
  payCardCustomerAccess: PayCardCustomerAccess
  payCardCustomerSettings: PayCardCustomerSettings
  payCardCustomerSettingsAuditHistory: Array<PayCardCustomerSettingsAuditEntry>
  payCardCustomerSettingsLastEvent: Maybe<PayCardCustomerSettingsAuditEntry>
  payCardEmployeeAccess: PayCardEmployeeAccess
  /** Pay change log for the specified employee. */
  payChangeLog: Maybe<PayChangeLogResponse>
  payGroupCalendarByDateRange: PayGroupCalendarResult
  payGroupEmployees: Maybe<Array<PayrollEmployee>>
  /** retrieves the paycheck shipping settings for a customer */
  paycheckDeliverySettings: PayCheckDeliverySettings
  payperiod: PayPeriod
  payrollCustomer: Customer
  payrollCustomerUsers: Array<PayrollCustomerUser>
  payrollOnboardingPermissions: Array<OnboardingCheckListPermission>
  payrollRequirement: PayrollTotalRequirements
  payrollSettings: PayrollSettings
  previousYearUnprocessedPayrolls: PreviousYearUnprocessedPayrollsResponse
  restaurant: Maybe<Restaurant>
  retroPayForHourlyPayChange: Maybe<HourlyRetroPayResponse>
  retroPayForSalaryChange: Maybe<SalaryRetroPayResponse>
  sections: SectionResult
  timesheetAggregates: TimesheetAggregateResponse
  /**
   * Gets the timesheet data for a payperiod.
   * NOTE: The consumer is responsible for any necessary filtering
   * (e.g. checking if timesheets have been imported when considering previous payrolls)
   */
  timesheets: Array<TimesheetByPayrollAggregate>
  tipsDetails: Maybe<Array<TipsPerDay>>
  tipsIntegrationSettings: TipsIntegrationSettings
  upcoming: Array<UpcomingPayroll>
}

export type QueryPayHistoryArgs = {
  employeeUuid: Scalars['ID']
  filters: InputMaybe<PayHistoryFilters>
  first: InputMaybe<Scalars['Int']>
  offset: InputMaybe<Scalars['Int']>
}

export type QueryPayHistoryAuditArgs = {
  employeeUuid: Scalars['ID']
  first: InputMaybe<Scalars['Int']>
  offset: InputMaybe<Scalars['Int']>
}

export type QueryPayHistoryReportArgs = {
  employeeUuid: Scalars['ID']
  feinId: InputMaybe<Scalars['LegacyId']>
  from: InputMaybe<Scalars['SpaDateTime']>
  to: InputMaybe<Scalars['SpaDateTime']>
  type: PayHistoryReportType
}

export type QueryPayHistoryReportPdfArgs = {
  employeeUuid: Scalars['ID']
  feinId: InputMaybe<Scalars['LegacyId']>
  from: InputMaybe<Scalars['SpaDateTime']>
  to: InputMaybe<Scalars['SpaDateTime']>
  type: PayHistoryReportType
}

export type QueryAccessibleCustomersArgs = {
  input: InputMaybe<AccessibleCustomersInput>
}

export type QueryActiveEarningCodesArgs = {
  employmentTaxType: InputMaybe<EmploymentTaxType>
}

export type QueryActorPermissionsForEmployeesArgs = {
  employeeIds: Array<Scalars['ID']>
}

export type QueryAuditArgs = {
  payrollId: Scalars['ID']
}

export type QueryBankConsentStatusArgs = {
  payPeriodId: InputMaybe<Scalars['ID']>
}

export type QueryCheckAvailabilityArgs = {
  request: AvailabilityRequest
}

export type QueryEmployeeArgs = {
  employeeUuid: Scalars['ID']
}

export type QueryEmployeePositionsArgs = {
  employeeUuid: Scalars['String']
  payPeriodUuid: Scalars['String']
}

export type QueryFindCustomerSettingsArgs = {
  settings: Array<Scalars['Int']>
}

export type QueryFindEmployeeByIdArgs = {
  employeeId: Scalars['ID']
}

export type QueryFindEmployeePayArgs = {
  employeeUuid: Scalars['ID']
}

export type QueryFindEmploymentArgs = {
  employeeUuid: Scalars['ID']
}

export type QueryFindJobMappingByJobIdArgs = {
  jobId: Scalars['ID']
}

export type QueryFindJobMappingsByLocationIdArgs = {
  locationId: Scalars['ID']
}

export type QueryFindPayrollEarningLevelsArgs = {
  payCheckEarningUuid: Scalars['String']
  payPeriodUuid: Scalars['String']
}

export type QueryFindPayrollJobsByLocationIdArgs = {
  locationId: Scalars['ID']
}

export type QueryFindRestaurantJobsByRestaurantIdArgs = {
  restaurantId: Scalars['ID']
}

export type QueryFindUserByEmployeeIdArgs = {
  employeeId: Scalars['ID']
}

export type QueryFindUserByIdArgs = {
  userId: Scalars['ID']
}

export type QueryFindUsersArgs = {
  companyCode: Scalars['ID']
  employeeUuid: Scalars['ID']
}

export type QueryGetApprovedExpenseReportsArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryGetEmployeeComparisonArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryGetEmployeeMappingStatusArgs = {
  employeeUuid: Scalars['ID']
}

export type QueryGetLevelArgs = {
  levelUuid: Scalars['String']
  sequenceId: Scalars['Int']
}

export type QueryGetPayrollEmployeesArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryGetPayrollEmployeesPostedArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryGetPosMappingOptionsArgs = {
  employeeUuid: Scalars['ID']
  locationUuid: Scalars['ID']
}

export type QueryJobAssignmentsByIdArgs = {
  employeeId: Scalars['ID']
  jobAssignmentIds?: InputMaybe<Array<Scalars['ID']>>
}

export type QueryJobsArgs = {
  parentLevelUuid: InputMaybe<Scalars['String']>
}

export type QueryNavigationArgs = {
  input: InputMaybe<NavigationInput>
}

export type QueryNewHireRequiredDocumentsForAssignedFieldsArgs = {
  request: RequiredDocumentsRequest
}

export type QueryPayCardCustomerAccessArgs = {
  input: PayCardCustomerAccessInput
}

export type QueryPayCardEmployeeAccessArgs = {
  input: PayCardEmployeeAccessInput
}

export type QueryPayChangeLogArgs = {
  employeeId: Scalars['ID']
  pageNumber?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  pageToken?: InputMaybe<Scalars['String']>
  sort?: InputMaybe<Array<PayChangeLogSortInput>>
}

export type QueryPayGroupCalendarByDateRangeArgs = {
  endDate: InputMaybe<Scalars['Date']>
  payGroupId: Scalars['ID']
  startDate: InputMaybe<Scalars['Date']>
}

export type QueryPayGroupEmployeesArgs = {
  payPeriodId: InputMaybe<Scalars['ID']>
}

export type QueryPayperiodArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryPayrollCustomerUsersArgs = {
  input: InputMaybe<PayrollCustomerUsersInput>
}

export type QueryPayrollRequirementArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryPreviousYearUnprocessedPayrollsArgs = {
  payPeriodUuid: Scalars['String']
}

export type QueryRestaurantArgs = {
  restaurantGuid: Scalars['ID']
}

export type QueryRetroPayForHourlyPayChangeArgs = {
  hourlyRetroPayInput: HourlyRetroPayInput
}

export type QueryRetroPayForSalaryChangeArgs = {
  salaryRetroPayInput: SalaryRetroPayInput
}

export type QuerySectionsArgs = {
  input: InputMaybe<SectionsInput>
}

export type QueryTimesheetAggregatesArgs = {
  payPeriodUuid: Scalars['String']
}

export type QueryTimesheetsArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryTipsDetailsArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryError = {
  errors: Array<Error>
  status: ResponseStatus
}

export type QueryErrorResponse = QueryError & {
  __typename?: 'QueryErrorResponse'
  errors: Array<Error>
  status: ResponseStatus
}

export type QuerySuccess = {
  status: ResponseStatus
}

export type RehireEmployeeNoChangesInput = {
  employeeId: Scalars['ID']
  rehireDate: Scalars['Date']
}

export type RehiredEmploymentStatus = EmploymentStatus & {
  __typename?: 'RehiredEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  dismissalPeriod: Maybe<DateRange>
  hireDate: Maybe<Scalars['String']>
  rehireDate: Maybe<Scalars['String']>
}

export type RequiredDocumentsRequest = {
  hireDate: Scalars['Date']
  securityRoleId: Scalars['ID']
  workTaxLocationIds: Array<Scalars['ID']>
}

export type Response = {
  __typename?: 'Response'
  response: Maybe<Scalars['String']>
}

export type ResponseError = MutationError & {
  __typename?: 'ResponseError'
  errors: Array<Error>
  status: ResponseStatus
}

export enum ResponseStatus {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}

export type ResponseSuccess = MutationSuccess & {
  __typename?: 'ResponseSuccess'
  status: ResponseStatus
}

export type Restaurant = {
  __typename?: 'Restaurant'
  guid: Scalars['ID']
  name: Maybe<Scalars['String']>
}

export type RestaurantJob = {
  __typename?: 'RestaurantJob'
  guid: Scalars['ID']
  name: Scalars['String']
  status: RestaurantJobStatusCode
}

export enum RestaurantJobStatusCode {
  Available = 'Available',
  /** Restaurant job does not exist */
  Deleted = 'Deleted'
}

export type Salary = {
  __typename?: 'Salary'
  activeSalaryRateOfPay: Maybe<Money>
  employmentTaxType: Maybe<EmploymentTaxType>
  id: Scalars['ID']
  payFrequency: Maybe<PayFrequency>
  pendingPayChange: Maybe<PendingPayChange>
  salaryAllocations: Array<SalaryAllocation>
}

export type SalaryAllocation = {
  __typename?: 'SalaryAllocation'
  id: Scalars['ID']
  jobAssignment: JobAssignment
  rate: Money
}

export type SalaryAllocationNotFoundError = {
  __typename?: 'SalaryAllocationNotFoundError'
  message: Maybe<Scalars['String']>
}

export type SalaryNotFoundError = {
  __typename?: 'SalaryNotFoundError'
  message: Maybe<Scalars['String']>
}

export type SalaryPayFrequencyDoesNotExistError = {
  __typename?: 'SalaryPayFrequencyDoesNotExistError'
  message: Maybe<Scalars['String']>
}

export type SalaryPayFrequencyNoneError = {
  __typename?: 'SalaryPayFrequencyNoneError'
  message: Maybe<Scalars['String']>
}

export type SalaryRetroPay = {
  __typename?: 'SalaryRetroPay'
  payChangeDifference: AmountWithPayInterval
  payFrequency: PayFrequency
  payPeriodEarnings: Money
  retroPay: Money
}

export type SalaryRetroPayInput = {
  employeeId: Scalars['ID']
  endDate: Scalars['Date']
  newRate: MoneyInput
  oldRate: MoneyInput
  startDate: Scalars['Date']
}

export type SalaryRetroPayResponse =
  | InvalidStartDateError
  | SalaryPayFrequencyDoesNotExistError
  | SalaryPayFrequencyNoneError
  | SalaryRetroPay
  | TimesheetRetrievalError

export enum SalaryType {
  commission = 'commission',
  draw = 'draw',
  hourly = 'hourly',
  salary = 'salary',
  ten99 = 'ten99',
  unknown = 'unknown',
  w2 = 'w2'
}

export type SaveDeduction = {
  amount: Scalars['Float']
  checkCodeUuid: Scalars['String']
  deductionCodeUuid: Scalars['String']
  employeeUuid: Scalars['String']
  isPercentage: Scalars['Boolean']
  paystub: Scalars['Int']
}

export type SaveDeductionError = MutationError & {
  __typename?: 'SaveDeductionError'
  errors: Array<Error>
  status: ResponseStatus
}

export type SaveDeductionResponse = SaveDeductionError | SaveDeductionSuccess

export type SaveDeductionSuccess = MutationSuccess & {
  __typename?: 'SaveDeductionSuccess'
  result: PayrollEmployeeV2
  status: ResponseStatus
}

export type SaveEarning = {
  amount: Scalars['Float']
  baseRate: Scalars['Float']
  checkCodeUuid: Scalars['String']
  earningCodeUuid: Scalars['String']
  employeeUuid: Scalars['String']
  hours: Scalars['Float']
  levels: InputMaybe<Array<LevelUuidSequence>>
  paystub: Scalars['Int']
  positionUuid: Scalars['String']
  rate: Scalars['Float']
  week: Scalars['Int']
}

export type SaveEarningError = MutationError & {
  __typename?: 'SaveEarningError'
  errors: Array<Error>
  status: ResponseStatus
}

export type SaveEarningResponse = SaveEarningError | SaveEarningSuccess

export type SaveEarningSuccess = MutationSuccess & {
  __typename?: 'SaveEarningSuccess'
  result: PayrollEmployeeV2
  status: ResponseStatus
}

export type SaveNewEmployeeResponse = {
  __typename?: 'SaveNewEmployeeResponse'
  employeeId: Scalars['ID']
}

export type SeasonalEmploymentStatus = EmploymentStatus & {
  __typename?: 'SeasonalEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type Section = {
  __typename?: 'Section'
  items: Array<Navigation>
  /** @deprecated Field no longer supported */
  label: Scalars['String']
  labelNew: LocalizableMessage
  name: Scalars['String']
}

export type SectionResult = {
  __typename?: 'SectionResult'
  errors: Array<NavigationErrors>
  result: Array<Section>
}

export type SectionsInput = {
  name: InputMaybe<Scalars['String']>
  params: InputMaybe<Array<NavigationParam>>
}

export type ShippingAddress = {
  __typename?: 'ShippingAddress'
  addressLine1: Scalars['String']
  addressLine2: Scalars['String']
  attention: Scalars['String']
  city: Scalars['String']
  recipientCompany: Scalars['String']
  shippingAddressUuid: Scalars['String']
  state: Scalars['String']
  stateName: Scalars['String']
  trackingNumber: Maybe<Scalars['String']>
  zipCode: Scalars['String']
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type StateInUnitedStates = {
  __typename?: 'StateInUnitedStates'
  abbreviation: Scalars['String']
  fipsCode: Scalars['Int']
  fullName: Scalars['String']
}

export type Supervisor = {
  __typename?: 'Supervisor'
  id: EmploymentId
  job: Maybe<Scalars['String']>
  location: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type Supervisors = {
  __typename?: 'Supervisors'
  supervisors: Array<Supervisor>
}

export type SutaRate = {
  __typename?: 'SutaRate'
  code: Scalars['String']
  excludeFromCombinedRate: Scalars['Boolean']
  isExempt: Scalars['Boolean']
  isMajor: Scalars['Boolean']
  name: Scalars['String']
  rate: Maybe<Scalars['Float']>
}

export enum TaskStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  LOCKED = 'LOCKED',
  NOT_STARTED = 'NOT_STARTED',
  ON_HOLD = 'ON_HOLD',
  SKIPPED = 'SKIPPED'
}

export type TerminatedEmploymentStatus = EmploymentStatus & {
  __typename?: 'TerminatedEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
  lastDayOfEmployment: Maybe<Scalars['String']>
  lastDayToReceiveBenefits: Maybe<Scalars['String']>
}

export enum TimeSheetStatus {
  approved = 'approved',
  deleted = 'deleted',
  denied = 'denied',
  open = 'open',
  pending_approval = 'pending_approval',
  returned = 'returned',
  unknown = 'unknown'
}

export enum TimeSheetStatusV2 {
  approved = 'approved',
  deleted = 'deleted',
  denied = 'denied',
  open = 'open',
  pending_approval = 'pending_approval',
  returned = 'returned',
  unknown = 'unknown'
}

export enum TimeSheetTiedToPayroll {
  all = 'all',
  no = 'no',
  ot = 'ot',
  tafwot = 'tafwot'
}

export type TimesheetAggregateError = QueryError & {
  __typename?: 'TimesheetAggregateError'
  errors: Array<Error>
  status: ResponseStatus
}

export type TimesheetAggregateResponse =
  | TimesheetAggregateError
  | TimesheetAggregateSuccess

export type TimesheetAggregateSuccess = QuerySuccess & {
  __typename?: 'TimesheetAggregateSuccess'
  status: ResponseStatus
  timesheetAggregates: Array<TimesheetByPayrollAggregateV2>
}

export type TimesheetByPayrollAggregate = {
  __typename?: 'TimesheetByPayrollAggregate'
  earningEntries: Array<TimesheetEarningEntry>
  employeeUuid: Scalars['String']
  isImported: Scalars['Boolean']
  status: Maybe<TimeSheetStatus>
}

export type TimesheetByPayrollAggregateV2 = {
  __typename?: 'TimesheetByPayrollAggregateV2'
  earningEntries: Array<TimesheetEarningEntryV2>
  employeeUuid: Scalars['String']
  isImported: Scalars['Boolean']
  status: Maybe<TimeSheetStatusV2>
}

export type TimesheetEarningEntry = {
  __typename?: 'TimesheetEarningEntry'
  amount: Maybe<Scalars['Float']>
  date: Maybe<Scalars['SpaDate']>
  earningCode: TimesheetEntryEarningCode
  hoursWorked: Maybe<Scalars['Float']>
  isOvertime: Scalars['Boolean']
}

export type TimesheetEarningEntryV2 = {
  __typename?: 'TimesheetEarningEntryV2'
  amount: Maybe<Scalars['Float']>
  date: Maybe<Scalars['Date']>
  earningCode: TimesheetEntryEarningCodeV2
  hoursWorked: Maybe<Scalars['Float']>
  isOvertime: Scalars['Boolean']
  uuid: Maybe<Scalars['String']>
}

export type TimesheetEntryEarningCode = {
  __typename?: 'TimesheetEntryEarningCode'
  isHourly: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  timeAwaySetupId: Scalars['Int']
  uuid: Scalars['ID']
}

export type TimesheetEntryEarningCodeV2 = {
  __typename?: 'TimesheetEntryEarningCodeV2'
  isHourly: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  timeAwaySetupId: Scalars['Int']
  uuid: Scalars['ID']
}

export type TimesheetRetrievalError = {
  __typename?: 'TimesheetRetrievalError'
  message: Maybe<Scalars['String']>
}

export enum TipsIntegrationSettings {
  NONE = 'NONE',
  NORMAL = 'NORMAL',
  POOLED = 'POOLED'
}

export type TipsPerDay = {
  __typename?: 'TipsPerDay'
  amount: Scalars['Float']
  date: Scalars['Date']
  tipsReadyStatus: TipsReadyStatus
}

export enum TipsReadyStatus {
  inProgress = 'inProgress',
  noTips = 'noTips',
  notMissingTips = 'notMissingTips',
  ready = 'ready'
}

export enum TipsType {
  none = 'none',
  normal = 'normal',
  pooled = 'pooled'
}

export type ToastPrinted = {
  __typename?: 'ToastPrinted'
  totalChecks: Scalars['Int']
  totalEmployees: Scalars['Int']
}

export type Unauthorized = {
  __typename?: 'Unauthorized'
  message: Scalars['String']
}

export type UnprocessedPayroll = {
  __typename?: 'UnprocessedPayroll'
  checkDate: Scalars['Date']
  endDate: Scalars['Date']
  id: Scalars['ID']
  name: Scalars['String']
  payFrequency: PayFrequency
  startDate: Scalars['Date']
}

export type UpcomingPayroll = {
  __typename?: 'UpcomingPayroll'
  dueDate: Maybe<Scalars['DateTime']>
  endDate: Scalars['Date']
  hasTips: Scalars['Boolean']
  id: Scalars['ID']
  manual: Scalars['Boolean']
  name: Scalars['String']
  payDate: Scalars['Date']
  startDate: Scalars['Date']
  status: PayrollStatusV2
  timesheetsCount: Scalars['Int']
  unApprovedTimesheetsCount: Scalars['Int']
  unReceivedTips: Scalars['Int']
}

export type UpdatePayCardAdminSettingsInput = {
  payCardAdminPayoutsLocked: InputMaybe<Scalars['Boolean']>
}

export type UpdatePayCardCustomerSettingsInput = {
  payCardAdminSettings: InputMaybe<UpdatePayCardAdminSettingsInput>
  payCardPayoutConfiguration: InputMaybe<PayCardPayoutConfiguration>
  payCardPayoutEnabled: InputMaybe<Scalars['Boolean']>
  payCardTipsConfiguration: InputMaybe<PayCardTipsConfiguration>
}

export type UpdatePayCardCustomerSettingsResponse = {
  __typename?: 'UpdatePayCardCustomerSettingsResponse'
  payCardCustomerSettings: PayCardCustomerSettings
}

export type UpdatePendingPayChangeStatusResponse =
  | EmployeeNotFoundError
  | PendingPayChangeNotFoundErrors
  | PendingPayChangeStatusResponse

export type User = {
  __typename?: 'User'
  email: Maybe<Scalars['String']>
  employeeUuid: Maybe<Scalars['ID']>
  id: Scalars['ID']
  isEmailVerified: Scalars['Boolean']
  name: PersonName
  toastIdentityGuid: Maybe<Scalars['ID']>
  username: Scalars['String']
}

export type UserMigration = {
  __typename?: 'UserMigration'
  companyCode: Scalars['String']
  customerUuid: Scalars['ID']
  error: UserMigrationError
  importId: Scalars['ID']
  status: UserMigrationStatus
  updatedAt: Scalars['DateTime']
  userUuid: Scalars['ID']
}

export enum UserMigrationError {
  AUTHENTICATION_PROVIDER = 'AUTHENTICATION_PROVIDER',
  DATABASE_WRITE_FAILURE = 'DATABASE_WRITE_FAILURE',
  EMAIL_CONFLICT = 'EMAIL_CONFLICT',
  EXPIRED = 'EXPIRED',
  GUID_CONFLICT = 'GUID_CONFLICT',
  NONE = 'NONE',
  UNKNOWN = 'UNKNOWN'
}

export type UserMigrationInput = {
  companyCodes: Array<Scalars['String']>
}

export enum UserMigrationStatus {
  ERROR = 'ERROR',
  IMPORTING = 'IMPORTING',
  STARTED = 'STARTED'
}

export type Users = {
  __typename?: 'Users'
  list: Array<Maybe<User>>
}

export type VendorNetPays = {
  __typename?: 'VendorNetPays'
  amount: Scalars['Float']
}

export enum VeteranStatus {
  ACTIVE_WAR_TIME_OR_CAMPAIGN_BADGE_VETERAN = 'ACTIVE_WAR_TIME_OR_CAMPAIGN_BADGE_VETERAN',
  ARMED_FORCES_SERVICE_MEDAL_VETERAN = 'ARMED_FORCES_SERVICE_MEDAL_VETERAN',
  DISABLED_VETERAN = 'DISABLED_VETERAN',
  NOT_A_VETERAN = 'NOT_A_VETERAN',
  RECENTLY_SEPARATED_VETERAN = 'RECENTLY_SEPARATED_VETERAN',
  SPECIAL_DISABLED_VETERAN = 'SPECIAL_DISABLED_VETERAN',
  VETERAN = 'VETERAN',
  VIETNAM_ERA_VETERAN = 'VIETNAM_ERA_VETERAN'
}

export type WorkTaxLocation = {
  __typename?: 'WorkTaxLocation'
  customerUuid: Scalars['String']
  name: Scalars['String']
  shippingAddressUuid: Scalars['String']
}

export enum Join__Graph {
  CUSTOMER = 'CUSTOMER',
  EC_HUMAN_RESOURCES = 'EC_HUMAN_RESOURCES',
  EC_JOBS = 'EC_JOBS',
  EC_PAYROLL = 'EC_PAYROLL',
  EC_PAYROLL_ONBOARDING = 'EC_PAYROLL_ONBOARDING',
  EC_WEB = 'EC_WEB',
  NAVIGATION = 'NAVIGATION',
  PAYROLL = 'PAYROLL',
  SDP = 'SDP'
}

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  EXECUTION = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  SECURITY = 'SECURITY'
}

export type AttachEarningsMutationVariables = Exact<{
  employeeId: Scalars['ID']
  jobAssignmentId: Scalars['ID']
  lumpSumEarnings: Array<LumpSumEarningInput> | LumpSumEarningInput
  hourlyEarnings: Array<HourlyEarningInput> | HourlyEarningInput
}>

export type AttachEarningsMutation = {
  __typename?: 'Mutation'
  saveAdditionalEarnings:
    | { __typename?: 'EarningListEmptyError' }
    | { __typename?: 'EmployeeNotFoundError' }
    | { __typename?: 'JobAssignmentNotFoundError' }
    | { __typename?: 'MissingPayGroupError' }
    | { __typename?: 'NextPayPeriodNotFoundError' }
    | { __typename?: 'NextPaycheckDateNotFoundError' }
    | { __typename?: 'Paycheck'; date: any | null }
    | null
}

export type ChangeHourlyPayMutationVariables = Exact<{
  employeeId: Scalars['ID']
  jobAssignmentId: Scalars['ID']
  changePayInput: ChangePayInput
}>

export type ChangeHourlyPayMutation = {
  __typename?: 'Mutation'
  changeHourlyPay: {
    __typename?: 'JobAssignment'
    id: string
    activeHourlyRateOfPay: {
      __typename?: 'Money'
      amount: any
      currency: Currency
    } | null
  } | null
}

export type ChangeSalaryAllocationsMutationVariables = Exact<{
  employeeId: Scalars['ID']
  changeSalaryAllocationInputs:
    | Array<ChangeSalaryAllocationInput>
    | ChangeSalaryAllocationInput
}>

export type ChangeSalaryAllocationsMutation = {
  __typename?: 'Mutation'
  changeSalaryAllocations:
    | { __typename?: 'EmployeeNotFoundError' }
    | { __typename?: 'EmptySalaryAllocationsError' }
    | { __typename?: 'InvalidEffectiveDateError' }
    | {
        __typename?: 'Salary'
        id: string
        activeSalaryRateOfPay: {
          __typename?: 'Money'
          amount: any
          currency: Currency
        } | null
      }
    | { __typename?: 'SalaryAllocationNotFoundError' }
    | { __typename?: 'SalaryNotFoundError' }
}

export type UpdateScheduledPayChangeStatusesMutationVariables = Exact<{
  employeeId: Scalars['ID']
  scheduledPayChangeStatusInput:
    | Array<PendingPayChangeStatusInput>
    | PendingPayChangeStatusInput
}>

export type UpdateScheduledPayChangeStatusesMutation = {
  __typename?: 'Mutation'
  updatePendingPayChangeStatuses:
    | { __typename?: 'EmployeeNotFoundError' }
    | { __typename?: 'PendingPayChangeNotFoundErrors' }
    | { __typename: 'PendingPayChangeStatusResponse' }
    | null
}

export type ActiveEarningCodesQueryVariables = Exact<{
  employmentTaxType: InputMaybe<EmploymentTaxType>
}>

export type ActiveEarningCodesQuery = {
  __typename?: 'Query'
  activeEarningCodes: Array<{
    __typename?: 'EarningCode'
    isHourly: boolean
    value: string
    label: string
  }>
}

export type ErroredPayChangesJobAssignmentFragment = {
  __typename?: 'JobAssignment'
  id: string
  name: string
  locationName: string
  activeHourlyRateOfPay: {
    __typename?: 'Money'
    amount: any
    currency: Currency
  } | null
  pendingPayChange: {
    __typename?: 'PendingPayChange'
    id: string
    effectiveDate: any
    rate: { __typename?: 'Money'; amount: any; currency: Currency }
  } | null
}

export type ErroredPayChangesSalaryFragment = {
  __typename?: 'Salary'
  id: string
  activeSalaryRateOfPay: {
    __typename?: 'Money'
    amount: any
    currency: Currency
  } | null
  salaryAllocations: Array<{
    __typename?: 'SalaryAllocation'
    id: string
    jobAssignment: {
      __typename?: 'JobAssignment'
      locationName: string
      name: string
    }
  }>
  pendingPayChange: {
    __typename?: 'PendingPayChange'
    id: string
    effectiveDate: any
    rate: { __typename?: 'Money'; amount: any; currency: Currency }
  } | null
}

export type ErroredPayChangesQueryVariables = Exact<{
  employeeId: Scalars['ID']
}>

export type ErroredPayChangesQuery = {
  __typename?: 'Query'
  findEmployeeById: {
    __typename?: 'Employee'
    jobAssignmentPayChangeErrors: Array<{
      __typename?: 'JobAssignment'
      id: string
      name: string
      locationName: string
      activeHourlyRateOfPay: {
        __typename?: 'Money'
        amount: any
        currency: Currency
      } | null
      pendingPayChange: {
        __typename?: 'PendingPayChange'
        id: string
        effectiveDate: any
        rate: { __typename?: 'Money'; amount: any; currency: Currency }
      } | null
    }>
    salaryChangeErrors: {
      __typename?: 'Salary'
      id: string
      activeSalaryRateOfPay: {
        __typename?: 'Money'
        amount: any
        currency: Currency
      } | null
      salaryAllocations: Array<{
        __typename?: 'SalaryAllocation'
        id: string
        jobAssignment: {
          __typename?: 'JobAssignment'
          locationName: string
          name: string
        }
      }>
      pendingPayChange: {
        __typename?: 'PendingPayChange'
        id: string
        effectiveDate: any
        rate: { __typename?: 'Money'; amount: any; currency: Currency }
      } | null
    } | null
  } | null
}

export type JobAssignmentForPayChangeQueryVariables = Exact<{
  employeeId: Scalars['ID']
  jobAssignmentIds: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
}>

export type JobAssignmentForPayChangeQuery = {
  __typename?: 'Query'
  jobAssignmentsById: Array<{
    __typename?: 'JobAssignment'
    id: string
    name: string
    locationName: string
    employmentTaxType: EmploymentTaxType | null
    payGroupId: string | null
    activeHourlyRateOfPay: {
      __typename?: 'Money'
      amount: any
      currency: Currency
    } | null
  }>
}

export type PayGroupCalendarByDateRangeQueryVariables = Exact<{
  payGroupId: Scalars['ID']
  startDate: InputMaybe<Scalars['Date']>
  endDate: InputMaybe<Scalars['Date']>
}>

export type PayGroupCalendarByDateRangeQuery = {
  __typename?: 'Query'
  payGroupCalendarByDateRange:
    | {
        __typename: 'PayGroupCalendar'
        payGroupId: string
        payPeriods: Array<{
          __typename?: 'PayPeriodCalendar'
          startDate: any
          checkDate: any
          isArchived: boolean
          status: PayrollStatusV2
        }>
      }
    | { __typename: 'Unauthorized'; message: string }
}

export type RetroPayForHourlyPayChangeQueryVariables = Exact<{
  hourlyRetroPayInput: HourlyRetroPayInput
}>

export type RetroPayForHourlyPayChangeQuery = {
  __typename?: 'Query'
  retroPayForHourlyPayChange:
    | {
        __typename?: 'HourlyRetroPay'
        hoursWorked: any
        payChangeDifference: {
          __typename?: 'AmountWithPayInterval'
          interval: PayInterval
          amount: { __typename?: 'Money'; amount: any; currency: Currency }
        }
        retroPay: { __typename?: 'Money'; amount: any; currency: Currency }
      }
    | { __typename?: 'InvalidStartDateError' }
    | { __typename?: 'JobAssignmentNotFoundError' }
    | { __typename?: 'TimesheetRetrievalError' }
    | null
}

export type RetroPayForSalaryChangeQueryVariables = Exact<{
  salaryRetroPayInput: SalaryRetroPayInput
}>

export type RetroPayForSalaryChangeQuery = {
  __typename?: 'Query'
  retroPayForSalaryChange:
    | { __typename?: 'InvalidStartDateError' }
    | { __typename?: 'SalaryPayFrequencyDoesNotExistError' }
    | { __typename?: 'SalaryPayFrequencyNoneError' }
    | {
        __typename?: 'SalaryRetroPay'
        payFrequency: PayFrequency
        payChangeDifference: {
          __typename?: 'AmountWithPayInterval'
          interval: PayInterval
          amount: { __typename?: 'Money'; amount: any; currency: Currency }
        }
        retroPay: { __typename?: 'Money'; amount: any; currency: Currency }
        payPeriodEarnings: {
          __typename?: 'Money'
          amount: any
          currency: Currency
        }
      }
    | { __typename?: 'TimesheetRetrievalError' }
    | null
}

export type SalaryAllocationJobAssignmentForPayChangeFragment = {
  __typename?: 'JobAssignment'
  id: string
  locationName: string
  isPrimary: boolean
  name: string
  employmentTaxType: EmploymentTaxType | null
  payGroupId: string | null
}

export type SalaryAllocationForPayChangeFragment = {
  __typename?: 'SalaryAllocation'
  id: string
  rate: { __typename?: 'Money'; amount: any; currency: Currency }
  jobAssignment: {
    __typename?: 'JobAssignment'
    id: string
    locationName: string
    isPrimary: boolean
    name: string
    employmentTaxType: EmploymentTaxType | null
    payGroupId: string | null
  }
}

export type SalaryForPayChangeQueryVariables = Exact<{
  employeeId: Scalars['ID']
}>

export type SalaryForPayChangeQuery = {
  __typename?: 'Query'
  findEmployeeById: {
    __typename?: 'Employee'
    employment: {
      __typename?: 'Employment'
      employmentStatus:
        | { __typename: 'ActiveEmploymentStatus' }
        | { __typename: 'ApplicantEmploymentStatus' }
        | { __typename: 'DeletedEmploymentStatus' }
        | { __typename: 'DemoEmploymentStatus' }
        | { __typename: 'ImplementationEmploymentStatus' }
        | { __typename: 'LeaveOfAbsenceEmploymentStatus' }
        | { __typename: 'NeverEmployedEmploymentStatus' }
        | { __typename: 'PendingEmploymentStatus' }
        | { __typename: 'RehiredEmploymentStatus' }
        | { __typename: 'SeasonalEmploymentStatus' }
        | { __typename: 'TerminatedEmploymentStatus' }
    } | null
    salary: {
      __typename?: 'Salary'
      id: string
      employmentTaxType: EmploymentTaxType | null
      salaryAllocations: Array<{
        __typename?: 'SalaryAllocation'
        id: string
        rate: { __typename?: 'Money'; amount: any; currency: Currency }
        jobAssignment: {
          __typename?: 'JobAssignment'
          id: string
          locationName: string
          isPrimary: boolean
          name: string
          employmentTaxType: EmploymentTaxType | null
          payGroupId: string | null
        }
      }>
      activeSalaryRateOfPay: {
        __typename?: 'Money'
        amount: any
        currency: Currency
      } | null
    } | null
  } | null
}

export type GetActorPermissionsForEmployeesQueryVariables = Exact<{
  employeeIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type GetActorPermissionsForEmployeesQuery = {
  __typename?: 'Query'
  actorPermissionsForEmployees: Array<{
    __typename?: 'ActorPermissionsForEmployee'
    employeeId: string
    permissions: Array<HumanResourcesPermission>
  }>
}

export type CustomerSettingsQueryVariables = Exact<{
  settings: Array<Scalars['Int']> | Scalars['Int']
}>

export type CustomerSettingsQuery = {
  __typename?: 'Query'
  findCustomerSettings: Array<{
    __typename?: 'CustomerSetting'
    id: number
    value: string
  }>
}

export type GetEmploymentDetailsQueryVariables = Exact<{
  employeeId: Scalars['ID']
}>

export type GetEmploymentDetailsQuery = {
  __typename?: 'Query'
  findEmployeeById: {
    __typename?: 'Employee'
    user: {
      __typename?: 'User'
      name: { __typename?: 'PersonName'; formatted: string | null }
    } | null
    employment: {
      __typename?: 'Employment'
      employeeNumber: string | null
      employmentType: string | null
      employmentStatus:
        | { __typename: 'ActiveEmploymentStatus'; hireDate: string | null }
        | { __typename: 'ApplicantEmploymentStatus'; hireDate: string | null }
        | { __typename: 'DeletedEmploymentStatus'; hireDate: string | null }
        | { __typename: 'DemoEmploymentStatus'; hireDate: string | null }
        | {
            __typename: 'ImplementationEmploymentStatus'
            hireDate: string | null
          }
        | {
            __typename: 'LeaveOfAbsenceEmploymentStatus'
            hireDate: string | null
          }
        | {
            __typename: 'NeverEmployedEmploymentStatus'
            hireDate: string | null
          }
        | { __typename: 'PendingEmploymentStatus'; hireDate: string | null }
        | {
            __typename: 'RehiredEmploymentStatus'
            hireDate: string | null
            rehireDate: string | null
            dismissalPeriod: {
              __typename?: 'DateRange'
              endDate: any | null
              startDate: any
            } | null
          }
        | { __typename: 'SeasonalEmploymentStatus'; hireDate: string | null }
        | { __typename: 'TerminatedEmploymentStatus'; hireDate: string | null }
    } | null
  } | null
}

export type GetEmploymentStatusDetailsQueryVariables = Exact<{
  employeeId: Scalars['ID']
}>

export type GetEmploymentStatusDetailsQuery = {
  __typename?: 'Query'
  findEmployeeById: {
    __typename?: 'Employee'
    user: {
      __typename?: 'User'
      name: { __typename?: 'PersonName'; formatted: string | null }
    } | null
    employment: {
      __typename?: 'Employment'
      employmentStatus:
        | { __typename: 'ActiveEmploymentStatus' }
        | { __typename: 'ApplicantEmploymentStatus' }
        | { __typename: 'DeletedEmploymentStatus' }
        | { __typename: 'DemoEmploymentStatus' }
        | { __typename: 'ImplementationEmploymentStatus' }
        | {
            __typename: 'LeaveOfAbsenceEmploymentStatus'
            leaveType: string | null
            scheduledLeave: {
              __typename?: 'DateRange'
              endDate: any | null
              startDate: any
            } | null
          }
        | { __typename: 'NeverEmployedEmploymentStatus' }
        | { __typename: 'PendingEmploymentStatus' }
        | {
            __typename: 'RehiredEmploymentStatus'
            rehireDate: string | null
            dismissalPeriod: {
              __typename?: 'DateRange'
              endDate: any | null
              startDate: any
            } | null
          }
        | { __typename: 'SeasonalEmploymentStatus' }
        | {
            __typename: 'TerminatedEmploymentStatus'
            lastDayOfEmployment: string | null
            lastDayToReceiveBenefits: string | null
          }
      totalLengthOfService: {
        __typename?: 'DateRange'
        endDate: any | null
        startDate: any
      } | null
    } | null
    payHistory: {
      __typename?: 'EmployeePayHistory'
      mostRecentPaycheck: string | null
    }
  } | null
}

export type GetEmploymentStatusQueryVariables = Exact<{
  employeeId: Scalars['ID']
}>

export type GetEmploymentStatusQuery = {
  __typename?: 'Query'
  findEmployeeById: {
    __typename?: 'Employee'
    employment: {
      __typename?: 'Employment'
      employmentStatus:
        | { __typename: 'ActiveEmploymentStatus' }
        | { __typename: 'ApplicantEmploymentStatus' }
        | { __typename: 'DeletedEmploymentStatus' }
        | { __typename: 'DemoEmploymentStatus' }
        | { __typename: 'ImplementationEmploymentStatus' }
        | { __typename: 'LeaveOfAbsenceEmploymentStatus' }
        | { __typename: 'NeverEmployedEmploymentStatus' }
        | { __typename: 'PendingEmploymentStatus' }
        | { __typename: 'RehiredEmploymentStatus' }
        | { __typename: 'SeasonalEmploymentStatus' }
        | { __typename: 'TerminatedEmploymentStatus' }
    } | null
  } | null
}

export type JobAssignmentsListJobAssignmentFragment = {
  __typename?: 'JobAssignment'
  id: string
  name: string
  locationName: string
  isPrimary: boolean
  activeHourlyRateOfPay: {
    __typename?: 'Money'
    amount: any
    currency: Currency
  } | null
  pendingPayChange: {
    __typename?: 'PendingPayChange'
    id: string
    effectiveDate: any
    rate: { __typename?: 'Money'; amount: any; currency: Currency }
  } | null
}

export type JobAssignmentsListSalaryFragment = {
  __typename?: 'Salary'
  id: string
  activeSalaryRateOfPay: {
    __typename?: 'Money'
    amount: any
    currency: Currency
  } | null
  pendingPayChange: {
    __typename?: 'PendingPayChange'
    id: string
    effectiveDate: any
    rate: { __typename?: 'Money'; amount: any; currency: Currency }
  } | null
}

export type JobAssignmentsQueryVariables = Exact<{
  employeeId: Scalars['ID']
}>

export type JobAssignmentsQuery = {
  __typename?: 'Query'
  findEmployeeById: {
    __typename?: 'Employee'
    user: {
      __typename?: 'User'
      name: {
        __typename?: 'PersonName'
        first: string | null
        formatted: string | null
        chosen: string | null
      }
    } | null
    jobAssignments: Array<{
      __typename?: 'JobAssignment'
      id: string
      name: string
      locationName: string
      isPrimary: boolean
      activeHourlyRateOfPay: {
        __typename?: 'Money'
        amount: any
        currency: Currency
      } | null
      pendingPayChange: {
        __typename?: 'PendingPayChange'
        id: string
        effectiveDate: any
        rate: { __typename?: 'Money'; amount: any; currency: Currency }
      } | null
    }>
    jobAssignmentPayChangeErrors: Array<{
      __typename?: 'JobAssignment'
      id: string
    }>
    salary: {
      __typename?: 'Salary'
      id: string
      activeSalaryRateOfPay: {
        __typename?: 'Money'
        amount: any
        currency: Currency
      } | null
      pendingPayChange: {
        __typename?: 'PendingPayChange'
        id: string
        effectiveDate: any
        rate: { __typename?: 'Money'; amount: any; currency: Currency }
      } | null
    } | null
    salaryChangeErrors: { __typename?: 'Salary'; id: string } | null
  } | null
}

export type GetSupervisorsQueryVariables = Exact<{
  employeeId: Scalars['ID']
}>

export type GetSupervisorsQuery = {
  __typename?: 'Query'
  findEmployeeById: {
    __typename?: 'Employee'
    user: {
      __typename?: 'User'
      name: {
        __typename?: 'PersonName'
        chosen: string | null
        first: string | null
        formatted: string | null
      }
    } | null
    supervisors: Array<{
      __typename?: 'EmployeeSupervisor'
      id: string
      job: string | null
      location: string | null
      name: string
    }>
  } | null
}

export type SendJobMappingRequestMutationVariables = Exact<{
  employeeUuid: Scalars['ID']
  payrollJobId: LevelIdInput
  payrollLocationId: LevelIdInput
  attachedNote: InputMaybe<Scalars['String']>
}>

export type SendJobMappingRequestMutation = {
  __typename?: 'Mutation'
  sendJobMappingRequest: string | null
}

export type ApplyMappingMutationVariables = Exact<{
  payrollEmployeeId: Scalars['ID']
  restaurantUserId: Scalars['ID']
  locationId: Scalars['ID']
}>

export type ApplyMappingMutation = {
  __typename?: 'Mutation'
  applyMapping: string
}

export type GetEmployeeMappingStatusQueryVariables = Exact<{
  employeeUuid: Scalars['ID']
}>

export type GetEmployeeMappingStatusQuery = {
  __typename?: 'Query'
  getEmployeeMappingStatus: {
    __typename?: 'EmployeeMappingStatus'
    id: string
    email: string | null
    status: EmploymentStatusType
    name: {
      __typename?: 'PersonName'
      first: string | null
      last: string | null
      formatted: string | null
      chosen: string | null
    }
    locations: Array<{
      __typename?: 'EmployeeAtLocation'
      posLocationGuid: string | null
      payrollLocationName: string | null
      mappingStatus: MappingCondition
      posRestaurantUserGuid: string | null
      posEmail: string | null
      posMostRecentClockInDate: any | null
      posStatus: string | null
      payrollLocationId: {
        __typename?: 'LevelId'
        databaseId: number
        sequenceId: number
      } | null
      posUserName: {
        __typename?: 'PersonName'
        first: string | null
        last: string | null
        formatted: string | null
      } | null
      jobs: Array<{
        __typename?: 'EmployeeJob'
        payrollTitle: string | null
        mappingStatus: MappingCondition
        posTitle: string | null
        payrollId: {
          __typename?: 'LevelId'
          databaseId: number
          sequenceId: number
        } | null
        payrollRateOfPay: {
          __typename?: 'Money'
          amount: any
          currency: Currency
        } | null
        posRateOfPay: {
          __typename?: 'Money'
          amount: any
          currency: Currency
        } | null
      }>
    }>
    issues: Array<{
      __typename?: 'MappingIssue'
      type: IssueType
      severity: IssueSeverity
    }>
  }
}

export type GetPosMappingOptionsQueryVariables = Exact<{
  locationUuid: Scalars['ID']
  employeeUuid: Scalars['ID']
}>

export type GetPosMappingOptionsQuery = {
  __typename?: 'Query'
  getPosMappingOptions: Array<{
    __typename?: 'PosEmployee'
    email: string
    restaurantUserGuid: string
    mostRecentClockInDate: any | null
    externalEmployeeId: string | null
    archived: boolean
    userName: { __typename?: 'PersonName'; formatted: string | null }
    mappedToEmployee: {
      __typename?: 'PayrollEmployeeMapping'
      payrollEmployeeNumber: string | null
      name: { __typename?: 'PersonName'; formatted: string | null }
    } | null
  }>
}

export type GetNavigationEmployeeQueryVariables = Exact<{
  employeeId: Scalars['ID']
}>

export type GetNavigationEmployeeQuery = {
  __typename?: 'Query'
  findEmployeeById: {
    __typename?: 'Employee'
    contact: {
      __typename?: 'EmployeeContact'
      telephoneNumber: {
        __typename?: 'ContactTelephoneNumber'
        telephoneNumber: string
      } | null
    }
    user: {
      __typename?: 'User'
      email: string | null
      toastIdentityGuid: string | null
    } | null
    employment: {
      __typename?: 'Employment'
      employeeNumber: string | null
      employmentStatus:
        | {
            __typename: 'ActiveEmploymentStatus'
            adjustedHireDate: string | null
            hireDate: string | null
          }
        | {
            __typename: 'ApplicantEmploymentStatus'
            adjustedHireDate: string | null
            hireDate: string | null
          }
        | {
            __typename: 'DeletedEmploymentStatus'
            adjustedHireDate: string | null
            hireDate: string | null
          }
        | {
            __typename: 'DemoEmploymentStatus'
            adjustedHireDate: string | null
            hireDate: string | null
          }
        | {
            __typename: 'ImplementationEmploymentStatus'
            adjustedHireDate: string | null
            hireDate: string | null
          }
        | {
            __typename: 'LeaveOfAbsenceEmploymentStatus'
            adjustedHireDate: string | null
            hireDate: string | null
            scheduledLeave: {
              __typename?: 'DateRange'
              endDate: any | null
              startDate: any
            } | null
          }
        | {
            __typename: 'NeverEmployedEmploymentStatus'
            adjustedHireDate: string | null
            hireDate: string | null
          }
        | {
            __typename: 'PendingEmploymentStatus'
            adjustedHireDate: string | null
            hireDate: string | null
          }
        | {
            __typename: 'RehiredEmploymentStatus'
            adjustedHireDate: string | null
            hireDate: string | null
            rehireDate: string | null
          }
        | {
            __typename: 'SeasonalEmploymentStatus'
            adjustedHireDate: string | null
            hireDate: string | null
          }
        | {
            __typename: 'TerminatedEmploymentStatus'
            adjustedHireDate: string | null
            hireDate: string | null
            lastDayOfEmployment: string | null
          }
    } | null
  } | null
}

export type PayChangeLogEventFragment = {
  __typename?: 'PayChangeEvent'
  id: string
  createdAt: any | null
  effectiveAt: any | null
  jobName: string
  locationName: string
  status: PayChangeEventStatus
  actor: {
    __typename?: 'Actor'
    id: string
    jobName: string | null
    name: {
      __typename?: 'PersonName'
      first: string | null
      chosen: string | null
      last: string | null
    } | null
  } | null
  currentRate: {
    __typename?: 'AmountWithPayInterval'
    interval: PayInterval
    amount: { __typename?: 'Money'; amount: any; currency: Currency }
  }
  previousRate: {
    __typename?: 'AmountWithPayInterval'
    interval: PayInterval
    amount: { __typename?: 'Money'; amount: any; currency: Currency }
  } | null
}

export type PayChangeLogQueryVariables = Exact<{
  employeeId: Scalars['ID']
  pageToken: InputMaybe<Scalars['String']>
  pageSize: InputMaybe<Scalars['Int']>
  pageNumber: InputMaybe<Scalars['Int']>
  sort: InputMaybe<Array<PayChangeLogSortInput> | PayChangeLogSortInput>
}>

export type PayChangeLogQuery = {
  __typename?: 'Query'
  payChangeLog: {
    __typename?: 'PayChangeLog'
    previousPageToken: string | null
    currentPageToken: string
    nextPageToken: string | null
    totalResultCount: number
    data: Array<{
      __typename?: 'PayChangeEvent'
      id: string
      createdAt: any | null
      effectiveAt: any | null
      jobName: string
      locationName: string
      status: PayChangeEventStatus
      actor: {
        __typename?: 'Actor'
        id: string
        jobName: string | null
        name: {
          __typename?: 'PersonName'
          first: string | null
          chosen: string | null
          last: string | null
        } | null
      } | null
      currentRate: {
        __typename?: 'AmountWithPayInterval'
        interval: PayInterval
        amount: { __typename?: 'Money'; amount: any; currency: Currency }
      }
      previousRate: {
        __typename?: 'AmountWithPayInterval'
        interval: PayInterval
        amount: { __typename?: 'Money'; amount: any; currency: Currency }
      } | null
    }> | null
  } | null
}

export type EmployeePayHistoryQueryVariables = Exact<{
  employeeUuid: Scalars['ID']
  first: InputMaybe<Scalars['Int']>
  offset: InputMaybe<Scalars['Int']>
  filters: InputMaybe<PayHistoryFilters>
}>

export type EmployeePayHistoryQuery = {
  __typename?: 'Query'
  PayHistory: {
    __typename?: 'PayHistoryPage'
    payHistory: Array<{
      __typename?: 'PayDetails'
      gross: number
      net: number
      checkDate: string | null
      taxes: number
      payStubs: Array<{
        __typename?: 'PayStub'
        checkDate: string
        payPeriod: string
        payPeriodId: any
        payStubNumber: number
        employeeUuid: string
        fein: string | null
        viewPayStubUrl: string
        downloadPayStubUrl: string
      }>
    }> | null
    paging: {
      __typename?: 'Paged'
      total: number
      pageCount: number
      pageIndex: number
    }
  }
}

export type LatestEmployeePayHistoryQueryVariables = Exact<{
  employeeUuid: Scalars['ID']
}>

export type LatestEmployeePayHistoryQuery = {
  __typename?: 'Query'
  PayHistory: {
    __typename?: 'PayHistoryPage'
    latest: {
      __typename?: 'PayDetails'
      gross: number
      net: number
      checkDate: string | null
      payStubs: Array<{
        __typename?: 'PayStub'
        viewPayStubUrl: string
        downloadPaystubUrl: string
      }>
    } | null
  }
}

export type PayHistoryAuditQueryVariables = Exact<{
  employeeUuid: Scalars['ID']
  first: InputMaybe<Scalars['Int']>
  offset: InputMaybe<Scalars['Int']>
}>

export type PayHistoryAuditQuery = {
  __typename?: 'Query'
  PayHistoryAudit: {
    __typename?: 'PayHistoryAudit'
    paging: {
      __typename?: 'Paged'
      total: number
      pageCount: number
      pageIndex: number
    }
    records: Array<{
      __typename?: 'PayHistoryAuditRecord'
      payPeriod: string
      modifiedBy: string
      action: string
      modifiedDate: string
    }> | null
  }
}

export type PayHistoryFiltersQueryVariables = Exact<{
  employeeUuid: Scalars['ID']
}>

export type PayHistoryFiltersQuery = {
  __typename?: 'Query'
  PayHistory: {
    __typename?: 'PayHistoryPage'
    FeinList: Array<{ __typename?: 'FeinOption'; name: string; id: any }>
  }
}

export type PayHistoryReportQueryVariables = Exact<{
  employeeUuid: Scalars['ID']
  reportType: PayHistoryReportType
  from: InputMaybe<Scalars['SpaDateTime']>
  to: InputMaybe<Scalars['SpaDateTime']>
  feinId: InputMaybe<Scalars['LegacyId']>
}>

export type PayHistoryReportQuery = {
  __typename?: 'Query'
  PayHistoryReportHTML: string
}

export type RehireEmployeeNoChangesMutationVariables = Exact<{
  request: RehireEmployeeNoChangesInput
}>

export type RehireEmployeeNoChangesMutation = {
  __typename?: 'Mutation'
  rehireEmployeeNoChanges: string
}

export const ErroredPayChangesJobAssignmentFragmentDoc = gql`
  fragment ErroredPayChangesJobAssignment on JobAssignment {
    id
    name
    locationName
    activeHourlyRateOfPay {
      amount
      currency
    }
    pendingPayChange {
      id
      rate {
        amount
        currency
      }
      effectiveDate
    }
  }
`
export const ErroredPayChangesSalaryFragmentDoc = gql`
  fragment ErroredPayChangesSalary on Salary {
    id
    activeSalaryRateOfPay {
      amount
      currency
    }
    salaryAllocations {
      id
      jobAssignment {
        locationName
        name
      }
    }
    pendingPayChange {
      id
      rate {
        amount
        currency
      }
      effectiveDate
    }
  }
`
export const SalaryAllocationJobAssignmentForPayChangeFragmentDoc = gql`
  fragment SalaryAllocationJobAssignmentForPayChange on JobAssignment {
    id
    locationName
    isPrimary
    name
    employmentTaxType
    payGroupId
  }
`
export const SalaryAllocationForPayChangeFragmentDoc = gql`
  fragment SalaryAllocationForPayChange on SalaryAllocation {
    id
    rate {
      amount
      currency
    }
    jobAssignment {
      ...SalaryAllocationJobAssignmentForPayChange
    }
  }
  ${SalaryAllocationJobAssignmentForPayChangeFragmentDoc}
`
export const JobAssignmentsListJobAssignmentFragmentDoc = gql`
  fragment JobAssignmentsListJobAssignment on JobAssignment {
    id
    name
    locationName
    isPrimary
    activeHourlyRateOfPay {
      amount
      currency
    }
    pendingPayChange {
      id
      rate {
        amount
        currency
      }
      effectiveDate
    }
  }
`
export const JobAssignmentsListSalaryFragmentDoc = gql`
  fragment JobAssignmentsListSalary on Salary {
    id
    activeSalaryRateOfPay {
      amount
      currency
    }
    pendingPayChange {
      id
      rate {
        amount
        currency
      }
      effectiveDate
    }
  }
`
export const PayChangeLogEventFragmentDoc = gql`
  fragment PayChangeLogEvent on PayChangeEvent {
    id
    actor {
      id
      jobName
      name {
        first
        chosen
        last
      }
    }
    createdAt
    currentRate {
      amount {
        amount
        currency
      }
      interval
    }
    effectiveAt
    jobName
    locationName
    previousRate {
      amount {
        amount
        currency
      }
      interval
    }
    status
  }
`
export const AttachEarningsDocument = gql`
  mutation AttachEarnings(
    $employeeId: ID!
    $jobAssignmentId: ID!
    $lumpSumEarnings: [LumpSumEarningInput!]!
    $hourlyEarnings: [HourlyEarningInput!]!
  ) {
    saveAdditionalEarnings(
      employeeId: $employeeId
      jobAssignmentId: $jobAssignmentId
      lumpSumEarnings: $lumpSumEarnings
      hourlyEarnings: $hourlyEarnings
    ) {
      ... on Paycheck {
        date
      }
    }
  }
`
export type AttachEarningsMutationFn = Apollo.MutationFunction<
  AttachEarningsMutation,
  AttachEarningsMutationVariables
>

/**
 * __useAttachEarningsMutation__
 *
 * To run a mutation, you first call `useAttachEarningsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachEarningsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachEarningsMutation, { data, loading, error }] = useAttachEarningsMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      jobAssignmentId: // value for 'jobAssignmentId'
 *      lumpSumEarnings: // value for 'lumpSumEarnings'
 *      hourlyEarnings: // value for 'hourlyEarnings'
 *   },
 * });
 */
export function useAttachEarningsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttachEarningsMutation,
    AttachEarningsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AttachEarningsMutation,
    AttachEarningsMutationVariables
  >(AttachEarningsDocument, options)
}
export type AttachEarningsMutationHookResult = ReturnType<
  typeof useAttachEarningsMutation
>
export type AttachEarningsMutationResult =
  Apollo.MutationResult<AttachEarningsMutation>
export type AttachEarningsMutationOptions = Apollo.BaseMutationOptions<
  AttachEarningsMutation,
  AttachEarningsMutationVariables
>
export const ChangeHourlyPayDocument = gql`
  mutation ChangeHourlyPay(
    $employeeId: ID!
    $jobAssignmentId: ID!
    $changePayInput: ChangePayInput!
  ) {
    changeHourlyPay(
      employeeId: $employeeId
      jobAssignmentId: $jobAssignmentId
      changePayInput: $changePayInput
    ) {
      id
      activeHourlyRateOfPay {
        amount
        currency
      }
    }
  }
`
export type ChangeHourlyPayMutationFn = Apollo.MutationFunction<
  ChangeHourlyPayMutation,
  ChangeHourlyPayMutationVariables
>

/**
 * __useChangeHourlyPayMutation__
 *
 * To run a mutation, you first call `useChangeHourlyPayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeHourlyPayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeHourlyPayMutation, { data, loading, error }] = useChangeHourlyPayMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      jobAssignmentId: // value for 'jobAssignmentId'
 *      changePayInput: // value for 'changePayInput'
 *   },
 * });
 */
export function useChangeHourlyPayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeHourlyPayMutation,
    ChangeHourlyPayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ChangeHourlyPayMutation,
    ChangeHourlyPayMutationVariables
  >(ChangeHourlyPayDocument, options)
}
export type ChangeHourlyPayMutationHookResult = ReturnType<
  typeof useChangeHourlyPayMutation
>
export type ChangeHourlyPayMutationResult =
  Apollo.MutationResult<ChangeHourlyPayMutation>
export type ChangeHourlyPayMutationOptions = Apollo.BaseMutationOptions<
  ChangeHourlyPayMutation,
  ChangeHourlyPayMutationVariables
>
export const ChangeSalaryAllocationsDocument = gql`
  mutation ChangeSalaryAllocations(
    $employeeId: ID!
    $changeSalaryAllocationInputs: [ChangeSalaryAllocationInput!]!
  ) {
    changeSalaryAllocations(
      employeeId: $employeeId
      changeSalaryAllocations: $changeSalaryAllocationInputs
    ) {
      ... on Salary {
        id
        activeSalaryRateOfPay {
          amount
          currency
        }
      }
    }
  }
`
export type ChangeSalaryAllocationsMutationFn = Apollo.MutationFunction<
  ChangeSalaryAllocationsMutation,
  ChangeSalaryAllocationsMutationVariables
>

/**
 * __useChangeSalaryAllocationsMutation__
 *
 * To run a mutation, you first call `useChangeSalaryAllocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSalaryAllocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSalaryAllocationsMutation, { data, loading, error }] = useChangeSalaryAllocationsMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      changeSalaryAllocationInputs: // value for 'changeSalaryAllocationInputs'
 *   },
 * });
 */
export function useChangeSalaryAllocationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeSalaryAllocationsMutation,
    ChangeSalaryAllocationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ChangeSalaryAllocationsMutation,
    ChangeSalaryAllocationsMutationVariables
  >(ChangeSalaryAllocationsDocument, options)
}
export type ChangeSalaryAllocationsMutationHookResult = ReturnType<
  typeof useChangeSalaryAllocationsMutation
>
export type ChangeSalaryAllocationsMutationResult =
  Apollo.MutationResult<ChangeSalaryAllocationsMutation>
export type ChangeSalaryAllocationsMutationOptions = Apollo.BaseMutationOptions<
  ChangeSalaryAllocationsMutation,
  ChangeSalaryAllocationsMutationVariables
>
export const UpdateScheduledPayChangeStatusesDocument = gql`
  mutation UpdateScheduledPayChangeStatuses(
    $employeeId: ID!
    $scheduledPayChangeStatusInput: [PendingPayChangeStatusInput!]!
  ) {
    updatePendingPayChangeStatuses(
      employeeId: $employeeId
      updatePendingPayChangeStatusInput: $scheduledPayChangeStatusInput
    ) {
      ... on PendingPayChangeStatusResponse {
        __typename
      }
    }
  }
`
export type UpdateScheduledPayChangeStatusesMutationFn =
  Apollo.MutationFunction<
    UpdateScheduledPayChangeStatusesMutation,
    UpdateScheduledPayChangeStatusesMutationVariables
  >

/**
 * __useUpdateScheduledPayChangeStatusesMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledPayChangeStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledPayChangeStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledPayChangeStatusesMutation, { data, loading, error }] = useUpdateScheduledPayChangeStatusesMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      scheduledPayChangeStatusInput: // value for 'scheduledPayChangeStatusInput'
 *   },
 * });
 */
export function useUpdateScheduledPayChangeStatusesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScheduledPayChangeStatusesMutation,
    UpdateScheduledPayChangeStatusesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateScheduledPayChangeStatusesMutation,
    UpdateScheduledPayChangeStatusesMutationVariables
  >(UpdateScheduledPayChangeStatusesDocument, options)
}
export type UpdateScheduledPayChangeStatusesMutationHookResult = ReturnType<
  typeof useUpdateScheduledPayChangeStatusesMutation
>
export type UpdateScheduledPayChangeStatusesMutationResult =
  Apollo.MutationResult<UpdateScheduledPayChangeStatusesMutation>
export type UpdateScheduledPayChangeStatusesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateScheduledPayChangeStatusesMutation,
    UpdateScheduledPayChangeStatusesMutationVariables
  >
export const ActiveEarningCodesDocument = gql`
  query activeEarningCodes($employmentTaxType: EmploymentTaxType) {
    activeEarningCodes(employmentTaxType: $employmentTaxType) {
      isHourly
      value
      label
    }
  }
`

/**
 * __useActiveEarningCodesQuery__
 *
 * To run a query within a React component, call `useActiveEarningCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveEarningCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveEarningCodesQuery({
 *   variables: {
 *      employmentTaxType: // value for 'employmentTaxType'
 *   },
 * });
 */
export function useActiveEarningCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ActiveEarningCodesQuery,
    ActiveEarningCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ActiveEarningCodesQuery,
    ActiveEarningCodesQueryVariables
  >(ActiveEarningCodesDocument, options)
}
export function useActiveEarningCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveEarningCodesQuery,
    ActiveEarningCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ActiveEarningCodesQuery,
    ActiveEarningCodesQueryVariables
  >(ActiveEarningCodesDocument, options)
}
export type ActiveEarningCodesQueryHookResult = ReturnType<
  typeof useActiveEarningCodesQuery
>
export type ActiveEarningCodesLazyQueryHookResult = ReturnType<
  typeof useActiveEarningCodesLazyQuery
>
export type ActiveEarningCodesQueryResult = Apollo.QueryResult<
  ActiveEarningCodesQuery,
  ActiveEarningCodesQueryVariables
>
export const ErroredPayChangesDocument = gql`
  query erroredPayChanges($employeeId: ID!) {
    findEmployeeById(employeeId: $employeeId) {
      jobAssignmentPayChangeErrors {
        ...ErroredPayChangesJobAssignment
      }
      salaryChangeErrors {
        ...ErroredPayChangesSalary
      }
    }
  }
  ${ErroredPayChangesJobAssignmentFragmentDoc}
  ${ErroredPayChangesSalaryFragmentDoc}
`

/**
 * __useErroredPayChangesQuery__
 *
 * To run a query within a React component, call `useErroredPayChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useErroredPayChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useErroredPayChangesQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useErroredPayChangesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ErroredPayChangesQuery,
    ErroredPayChangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ErroredPayChangesQuery,
    ErroredPayChangesQueryVariables
  >(ErroredPayChangesDocument, options)
}
export function useErroredPayChangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ErroredPayChangesQuery,
    ErroredPayChangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ErroredPayChangesQuery,
    ErroredPayChangesQueryVariables
  >(ErroredPayChangesDocument, options)
}
export type ErroredPayChangesQueryHookResult = ReturnType<
  typeof useErroredPayChangesQuery
>
export type ErroredPayChangesLazyQueryHookResult = ReturnType<
  typeof useErroredPayChangesLazyQuery
>
export type ErroredPayChangesQueryResult = Apollo.QueryResult<
  ErroredPayChangesQuery,
  ErroredPayChangesQueryVariables
>
export const JobAssignmentForPayChangeDocument = gql`
  query jobAssignmentForPayChange($employeeId: ID!, $jobAssignmentIds: [ID!]) {
    jobAssignmentsById(
      employeeId: $employeeId
      jobAssignmentIds: $jobAssignmentIds
    ) {
      id
      name
      locationName
      employmentTaxType
      activeHourlyRateOfPay {
        amount
        currency
      }
      payGroupId
    }
  }
`

/**
 * __useJobAssignmentForPayChangeQuery__
 *
 * To run a query within a React component, call `useJobAssignmentForPayChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobAssignmentForPayChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobAssignmentForPayChangeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      jobAssignmentIds: // value for 'jobAssignmentIds'
 *   },
 * });
 */
export function useJobAssignmentForPayChangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobAssignmentForPayChangeQuery,
    JobAssignmentForPayChangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    JobAssignmentForPayChangeQuery,
    JobAssignmentForPayChangeQueryVariables
  >(JobAssignmentForPayChangeDocument, options)
}
export function useJobAssignmentForPayChangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobAssignmentForPayChangeQuery,
    JobAssignmentForPayChangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    JobAssignmentForPayChangeQuery,
    JobAssignmentForPayChangeQueryVariables
  >(JobAssignmentForPayChangeDocument, options)
}
export type JobAssignmentForPayChangeQueryHookResult = ReturnType<
  typeof useJobAssignmentForPayChangeQuery
>
export type JobAssignmentForPayChangeLazyQueryHookResult = ReturnType<
  typeof useJobAssignmentForPayChangeLazyQuery
>
export type JobAssignmentForPayChangeQueryResult = Apollo.QueryResult<
  JobAssignmentForPayChangeQuery,
  JobAssignmentForPayChangeQueryVariables
>
export const PayGroupCalendarByDateRangeDocument = gql`
  query payGroupCalendarByDateRange(
    $payGroupId: ID!
    $startDate: Date
    $endDate: Date
  ) {
    payGroupCalendarByDateRange(
      payGroupId: $payGroupId
      startDate: $startDate
      endDate: $endDate
    ) {
      __typename
      ... on PayGroupCalendar {
        payGroupId
        payPeriods {
          startDate
          checkDate
          isArchived
          status
        }
      }
      ... on Unauthorized {
        message
      }
    }
  }
`

/**
 * __usePayGroupCalendarByDateRangeQuery__
 *
 * To run a query within a React component, call `usePayGroupCalendarByDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayGroupCalendarByDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayGroupCalendarByDateRangeQuery({
 *   variables: {
 *      payGroupId: // value for 'payGroupId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function usePayGroupCalendarByDateRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayGroupCalendarByDateRangeQuery,
    PayGroupCalendarByDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PayGroupCalendarByDateRangeQuery,
    PayGroupCalendarByDateRangeQueryVariables
  >(PayGroupCalendarByDateRangeDocument, options)
}
export function usePayGroupCalendarByDateRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayGroupCalendarByDateRangeQuery,
    PayGroupCalendarByDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PayGroupCalendarByDateRangeQuery,
    PayGroupCalendarByDateRangeQueryVariables
  >(PayGroupCalendarByDateRangeDocument, options)
}
export type PayGroupCalendarByDateRangeQueryHookResult = ReturnType<
  typeof usePayGroupCalendarByDateRangeQuery
>
export type PayGroupCalendarByDateRangeLazyQueryHookResult = ReturnType<
  typeof usePayGroupCalendarByDateRangeLazyQuery
>
export type PayGroupCalendarByDateRangeQueryResult = Apollo.QueryResult<
  PayGroupCalendarByDateRangeQuery,
  PayGroupCalendarByDateRangeQueryVariables
>
export const RetroPayForHourlyPayChangeDocument = gql`
  query retroPayForHourlyPayChange($hourlyRetroPayInput: HourlyRetroPayInput!) {
    retroPayForHourlyPayChange(hourlyRetroPayInput: $hourlyRetroPayInput) {
      ... on HourlyRetroPay {
        payChangeDifference {
          amount {
            amount
            currency
          }
          interval
        }
        retroPay {
          amount
          currency
        }
        hoursWorked
      }
    }
  }
`

/**
 * __useRetroPayForHourlyPayChangeQuery__
 *
 * To run a query within a React component, call `useRetroPayForHourlyPayChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetroPayForHourlyPayChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetroPayForHourlyPayChangeQuery({
 *   variables: {
 *      hourlyRetroPayInput: // value for 'hourlyRetroPayInput'
 *   },
 * });
 */
export function useRetroPayForHourlyPayChangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    RetroPayForHourlyPayChangeQuery,
    RetroPayForHourlyPayChangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RetroPayForHourlyPayChangeQuery,
    RetroPayForHourlyPayChangeQueryVariables
  >(RetroPayForHourlyPayChangeDocument, options)
}
export function useRetroPayForHourlyPayChangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetroPayForHourlyPayChangeQuery,
    RetroPayForHourlyPayChangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RetroPayForHourlyPayChangeQuery,
    RetroPayForHourlyPayChangeQueryVariables
  >(RetroPayForHourlyPayChangeDocument, options)
}
export type RetroPayForHourlyPayChangeQueryHookResult = ReturnType<
  typeof useRetroPayForHourlyPayChangeQuery
>
export type RetroPayForHourlyPayChangeLazyQueryHookResult = ReturnType<
  typeof useRetroPayForHourlyPayChangeLazyQuery
>
export type RetroPayForHourlyPayChangeQueryResult = Apollo.QueryResult<
  RetroPayForHourlyPayChangeQuery,
  RetroPayForHourlyPayChangeQueryVariables
>
export const RetroPayForSalaryChangeDocument = gql`
  query retroPayForSalaryChange($salaryRetroPayInput: SalaryRetroPayInput!) {
    retroPayForSalaryChange(salaryRetroPayInput: $salaryRetroPayInput) {
      ... on SalaryRetroPay {
        payChangeDifference {
          amount {
            amount
            currency
          }
          interval
        }
        retroPay {
          amount
          currency
        }
        payPeriodEarnings {
          amount
          currency
        }
        payFrequency
      }
    }
  }
`

/**
 * __useRetroPayForSalaryChangeQuery__
 *
 * To run a query within a React component, call `useRetroPayForSalaryChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetroPayForSalaryChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetroPayForSalaryChangeQuery({
 *   variables: {
 *      salaryRetroPayInput: // value for 'salaryRetroPayInput'
 *   },
 * });
 */
export function useRetroPayForSalaryChangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    RetroPayForSalaryChangeQuery,
    RetroPayForSalaryChangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RetroPayForSalaryChangeQuery,
    RetroPayForSalaryChangeQueryVariables
  >(RetroPayForSalaryChangeDocument, options)
}
export function useRetroPayForSalaryChangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetroPayForSalaryChangeQuery,
    RetroPayForSalaryChangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RetroPayForSalaryChangeQuery,
    RetroPayForSalaryChangeQueryVariables
  >(RetroPayForSalaryChangeDocument, options)
}
export type RetroPayForSalaryChangeQueryHookResult = ReturnType<
  typeof useRetroPayForSalaryChangeQuery
>
export type RetroPayForSalaryChangeLazyQueryHookResult = ReturnType<
  typeof useRetroPayForSalaryChangeLazyQuery
>
export type RetroPayForSalaryChangeQueryResult = Apollo.QueryResult<
  RetroPayForSalaryChangeQuery,
  RetroPayForSalaryChangeQueryVariables
>
export const SalaryForPayChangeDocument = gql`
  query salaryForPayChange($employeeId: ID!) {
    findEmployeeById(employeeId: $employeeId) {
      employment {
        employmentStatus {
          __typename
        }
      }
      salary {
        id
        employmentTaxType
        salaryAllocations {
          ...SalaryAllocationForPayChange
        }
        activeSalaryRateOfPay {
          amount
          currency
        }
      }
    }
  }
  ${SalaryAllocationForPayChangeFragmentDoc}
`

/**
 * __useSalaryForPayChangeQuery__
 *
 * To run a query within a React component, call `useSalaryForPayChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalaryForPayChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalaryForPayChangeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useSalaryForPayChangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalaryForPayChangeQuery,
    SalaryForPayChangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SalaryForPayChangeQuery,
    SalaryForPayChangeQueryVariables
  >(SalaryForPayChangeDocument, options)
}
export function useSalaryForPayChangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalaryForPayChangeQuery,
    SalaryForPayChangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SalaryForPayChangeQuery,
    SalaryForPayChangeQueryVariables
  >(SalaryForPayChangeDocument, options)
}
export type SalaryForPayChangeQueryHookResult = ReturnType<
  typeof useSalaryForPayChangeQuery
>
export type SalaryForPayChangeLazyQueryHookResult = ReturnType<
  typeof useSalaryForPayChangeLazyQuery
>
export type SalaryForPayChangeQueryResult = Apollo.QueryResult<
  SalaryForPayChangeQuery,
  SalaryForPayChangeQueryVariables
>
export const GetActorPermissionsForEmployeesDocument = gql`
  query getActorPermissionsForEmployees($employeeIds: [ID!]!) {
    actorPermissionsForEmployees(employeeIds: $employeeIds) {
      employeeId
      permissions
    }
  }
`

/**
 * __useGetActorPermissionsForEmployeesQuery__
 *
 * To run a query within a React component, call `useGetActorPermissionsForEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActorPermissionsForEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActorPermissionsForEmployeesQuery({
 *   variables: {
 *      employeeIds: // value for 'employeeIds'
 *   },
 * });
 */
export function useGetActorPermissionsForEmployeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActorPermissionsForEmployeesQuery,
    GetActorPermissionsForEmployeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetActorPermissionsForEmployeesQuery,
    GetActorPermissionsForEmployeesQueryVariables
  >(GetActorPermissionsForEmployeesDocument, options)
}
export function useGetActorPermissionsForEmployeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActorPermissionsForEmployeesQuery,
    GetActorPermissionsForEmployeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetActorPermissionsForEmployeesQuery,
    GetActorPermissionsForEmployeesQueryVariables
  >(GetActorPermissionsForEmployeesDocument, options)
}
export type GetActorPermissionsForEmployeesQueryHookResult = ReturnType<
  typeof useGetActorPermissionsForEmployeesQuery
>
export type GetActorPermissionsForEmployeesLazyQueryHookResult = ReturnType<
  typeof useGetActorPermissionsForEmployeesLazyQuery
>
export type GetActorPermissionsForEmployeesQueryResult = Apollo.QueryResult<
  GetActorPermissionsForEmployeesQuery,
  GetActorPermissionsForEmployeesQueryVariables
>
export const CustomerSettingsDocument = gql`
  query CustomerSettings($settings: [Int!]!) {
    findCustomerSettings(settings: $settings) {
      id
      value
    }
  }
`

/**
 * __useCustomerSettingsQuery__
 *
 * To run a query within a React component, call `useCustomerSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSettingsQuery({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useCustomerSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomerSettingsQuery,
    CustomerSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomerSettingsQuery, CustomerSettingsQueryVariables>(
    CustomerSettingsDocument,
    options
  )
}
export function useCustomerSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerSettingsQuery,
    CustomerSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CustomerSettingsQuery,
    CustomerSettingsQueryVariables
  >(CustomerSettingsDocument, options)
}
export type CustomerSettingsQueryHookResult = ReturnType<
  typeof useCustomerSettingsQuery
>
export type CustomerSettingsLazyQueryHookResult = ReturnType<
  typeof useCustomerSettingsLazyQuery
>
export type CustomerSettingsQueryResult = Apollo.QueryResult<
  CustomerSettingsQuery,
  CustomerSettingsQueryVariables
>
export const GetEmploymentDetailsDocument = gql`
  query getEmploymentDetails($employeeId: ID!) {
    findEmployeeById(employeeId: $employeeId) {
      user {
        name {
          formatted
        }
      }
      employment {
        employeeNumber
        employmentType
        employmentStatus {
          __typename
          ... on ActiveEmploymentStatus {
            hireDate
          }
          ... on ApplicantEmploymentStatus {
            hireDate
          }
          ... on DeletedEmploymentStatus {
            hireDate
          }
          ... on DemoEmploymentStatus {
            hireDate
          }
          ... on ImplementationEmploymentStatus {
            hireDate
          }
          ... on LeaveOfAbsenceEmploymentStatus {
            hireDate
          }
          ... on NeverEmployedEmploymentStatus {
            hireDate
          }
          ... on PendingEmploymentStatus {
            hireDate
          }
          ... on RehiredEmploymentStatus {
            hireDate
            rehireDate
            dismissalPeriod {
              endDate
              startDate
            }
          }
          ... on SeasonalEmploymentStatus {
            hireDate
          }
          ... on TerminatedEmploymentStatus {
            hireDate
          }
        }
      }
    }
  }
`

/**
 * __useGetEmploymentDetailsQuery__
 *
 * To run a query within a React component, call `useGetEmploymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmploymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmploymentDetailsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetEmploymentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmploymentDetailsQuery,
    GetEmploymentDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetEmploymentDetailsQuery,
    GetEmploymentDetailsQueryVariables
  >(GetEmploymentDetailsDocument, options)
}
export function useGetEmploymentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmploymentDetailsQuery,
    GetEmploymentDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEmploymentDetailsQuery,
    GetEmploymentDetailsQueryVariables
  >(GetEmploymentDetailsDocument, options)
}
export type GetEmploymentDetailsQueryHookResult = ReturnType<
  typeof useGetEmploymentDetailsQuery
>
export type GetEmploymentDetailsLazyQueryHookResult = ReturnType<
  typeof useGetEmploymentDetailsLazyQuery
>
export type GetEmploymentDetailsQueryResult = Apollo.QueryResult<
  GetEmploymentDetailsQuery,
  GetEmploymentDetailsQueryVariables
>
export const GetEmploymentStatusDetailsDocument = gql`
  query getEmploymentStatusDetails($employeeId: ID!) {
    findEmployeeById(employeeId: $employeeId) {
      user {
        name {
          formatted
        }
      }
      employment {
        employmentStatus {
          __typename
          ... on LeaveOfAbsenceEmploymentStatus {
            leaveType
            scheduledLeave {
              endDate
              startDate
            }
          }
          ... on RehiredEmploymentStatus {
            rehireDate
            dismissalPeriod {
              endDate
              startDate
            }
          }
          ... on TerminatedEmploymentStatus {
            lastDayOfEmployment
            lastDayToReceiveBenefits
          }
        }
        totalLengthOfService {
          endDate
          startDate
        }
      }
      payHistory {
        mostRecentPaycheck
      }
    }
  }
`

/**
 * __useGetEmploymentStatusDetailsQuery__
 *
 * To run a query within a React component, call `useGetEmploymentStatusDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmploymentStatusDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmploymentStatusDetailsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetEmploymentStatusDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmploymentStatusDetailsQuery,
    GetEmploymentStatusDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetEmploymentStatusDetailsQuery,
    GetEmploymentStatusDetailsQueryVariables
  >(GetEmploymentStatusDetailsDocument, options)
}
export function useGetEmploymentStatusDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmploymentStatusDetailsQuery,
    GetEmploymentStatusDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEmploymentStatusDetailsQuery,
    GetEmploymentStatusDetailsQueryVariables
  >(GetEmploymentStatusDetailsDocument, options)
}
export type GetEmploymentStatusDetailsQueryHookResult = ReturnType<
  typeof useGetEmploymentStatusDetailsQuery
>
export type GetEmploymentStatusDetailsLazyQueryHookResult = ReturnType<
  typeof useGetEmploymentStatusDetailsLazyQuery
>
export type GetEmploymentStatusDetailsQueryResult = Apollo.QueryResult<
  GetEmploymentStatusDetailsQuery,
  GetEmploymentStatusDetailsQueryVariables
>
export const GetEmploymentStatusDocument = gql`
  query getEmploymentStatus($employeeId: ID!) {
    findEmployeeById(employeeId: $employeeId) {
      employment {
        employmentStatus {
          __typename
        }
      }
    }
  }
`

/**
 * __useGetEmploymentStatusQuery__
 *
 * To run a query within a React component, call `useGetEmploymentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmploymentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmploymentStatusQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetEmploymentStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmploymentStatusQuery,
    GetEmploymentStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetEmploymentStatusQuery,
    GetEmploymentStatusQueryVariables
  >(GetEmploymentStatusDocument, options)
}
export function useGetEmploymentStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmploymentStatusQuery,
    GetEmploymentStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEmploymentStatusQuery,
    GetEmploymentStatusQueryVariables
  >(GetEmploymentStatusDocument, options)
}
export type GetEmploymentStatusQueryHookResult = ReturnType<
  typeof useGetEmploymentStatusQuery
>
export type GetEmploymentStatusLazyQueryHookResult = ReturnType<
  typeof useGetEmploymentStatusLazyQuery
>
export type GetEmploymentStatusQueryResult = Apollo.QueryResult<
  GetEmploymentStatusQuery,
  GetEmploymentStatusQueryVariables
>
export const JobAssignmentsDocument = gql`
  query jobAssignments($employeeId: ID!) {
    findEmployeeById(employeeId: $employeeId) {
      user {
        name {
          first
          formatted
          chosen
        }
      }
      jobAssignments {
        ...JobAssignmentsListJobAssignment
      }
      jobAssignmentPayChangeErrors {
        id
      }
      salary {
        ...JobAssignmentsListSalary
      }
      salaryChangeErrors {
        id
      }
    }
  }
  ${JobAssignmentsListJobAssignmentFragmentDoc}
  ${JobAssignmentsListSalaryFragmentDoc}
`

/**
 * __useJobAssignmentsQuery__
 *
 * To run a query within a React component, call `useJobAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobAssignmentsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useJobAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobAssignmentsQuery,
    JobAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobAssignmentsQuery, JobAssignmentsQueryVariables>(
    JobAssignmentsDocument,
    options
  )
}
export function useJobAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobAssignmentsQuery,
    JobAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobAssignmentsQuery, JobAssignmentsQueryVariables>(
    JobAssignmentsDocument,
    options
  )
}
export type JobAssignmentsQueryHookResult = ReturnType<
  typeof useJobAssignmentsQuery
>
export type JobAssignmentsLazyQueryHookResult = ReturnType<
  typeof useJobAssignmentsLazyQuery
>
export type JobAssignmentsQueryResult = Apollo.QueryResult<
  JobAssignmentsQuery,
  JobAssignmentsQueryVariables
>
export const GetSupervisorsDocument = gql`
  query getSupervisors($employeeId: ID!) {
    findEmployeeById(employeeId: $employeeId) {
      user {
        name {
          chosen
          first
          formatted
        }
      }
      supervisors {
        id
        job
        location
        name
      }
    }
  }
`

/**
 * __useGetSupervisorsQuery__
 *
 * To run a query within a React component, call `useGetSupervisorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupervisorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupervisorsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetSupervisorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSupervisorsQuery,
    GetSupervisorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSupervisorsQuery, GetSupervisorsQueryVariables>(
    GetSupervisorsDocument,
    options
  )
}
export function useGetSupervisorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupervisorsQuery,
    GetSupervisorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSupervisorsQuery, GetSupervisorsQueryVariables>(
    GetSupervisorsDocument,
    options
  )
}
export type GetSupervisorsQueryHookResult = ReturnType<
  typeof useGetSupervisorsQuery
>
export type GetSupervisorsLazyQueryHookResult = ReturnType<
  typeof useGetSupervisorsLazyQuery
>
export type GetSupervisorsQueryResult = Apollo.QueryResult<
  GetSupervisorsQuery,
  GetSupervisorsQueryVariables
>
export const SendJobMappingRequestDocument = gql`
  mutation sendJobMappingRequest(
    $employeeUuid: ID!
    $payrollJobId: LevelIdInput!
    $payrollLocationId: LevelIdInput!
    $attachedNote: String
  ) {
    sendJobMappingRequest(
      employeeUuid: $employeeUuid
      payrollJobId: $payrollJobId
      payrollLocationId: $payrollLocationId
      attachedNote: $attachedNote
    )
  }
`
export type SendJobMappingRequestMutationFn = Apollo.MutationFunction<
  SendJobMappingRequestMutation,
  SendJobMappingRequestMutationVariables
>

/**
 * __useSendJobMappingRequestMutation__
 *
 * To run a mutation, you first call `useSendJobMappingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendJobMappingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendJobMappingRequestMutation, { data, loading, error }] = useSendJobMappingRequestMutation({
 *   variables: {
 *      employeeUuid: // value for 'employeeUuid'
 *      payrollJobId: // value for 'payrollJobId'
 *      payrollLocationId: // value for 'payrollLocationId'
 *      attachedNote: // value for 'attachedNote'
 *   },
 * });
 */
export function useSendJobMappingRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendJobMappingRequestMutation,
    SendJobMappingRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendJobMappingRequestMutation,
    SendJobMappingRequestMutationVariables
  >(SendJobMappingRequestDocument, options)
}
export type SendJobMappingRequestMutationHookResult = ReturnType<
  typeof useSendJobMappingRequestMutation
>
export type SendJobMappingRequestMutationResult =
  Apollo.MutationResult<SendJobMappingRequestMutation>
export type SendJobMappingRequestMutationOptions = Apollo.BaseMutationOptions<
  SendJobMappingRequestMutation,
  SendJobMappingRequestMutationVariables
>
export const ApplyMappingDocument = gql`
  mutation ApplyMapping(
    $payrollEmployeeId: ID!
    $restaurantUserId: ID!
    $locationId: ID!
  ) {
    applyMapping(
      payrollEmployeeId: $payrollEmployeeId
      restaurantUserId: $restaurantUserId
      locationId: $locationId
    )
  }
`
export type ApplyMappingMutationFn = Apollo.MutationFunction<
  ApplyMappingMutation,
  ApplyMappingMutationVariables
>

/**
 * __useApplyMappingMutation__
 *
 * To run a mutation, you first call `useApplyMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyMappingMutation, { data, loading, error }] = useApplyMappingMutation({
 *   variables: {
 *      payrollEmployeeId: // value for 'payrollEmployeeId'
 *      restaurantUserId: // value for 'restaurantUserId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useApplyMappingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyMappingMutation,
    ApplyMappingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ApplyMappingMutation,
    ApplyMappingMutationVariables
  >(ApplyMappingDocument, options)
}
export type ApplyMappingMutationHookResult = ReturnType<
  typeof useApplyMappingMutation
>
export type ApplyMappingMutationResult =
  Apollo.MutationResult<ApplyMappingMutation>
export type ApplyMappingMutationOptions = Apollo.BaseMutationOptions<
  ApplyMappingMutation,
  ApplyMappingMutationVariables
>
export const GetEmployeeMappingStatusDocument = gql`
  query getEmployeeMappingStatus($employeeUuid: ID!) {
    getEmployeeMappingStatus(employeeUuid: $employeeUuid) {
      id
      name {
        first
        last
        formatted
        chosen
      }
      email
      status
      locations {
        payrollLocationId {
          databaseId
          sequenceId
        }
        posLocationGuid
        payrollLocationName
        mappingStatus
        posRestaurantUserGuid
        posUserName {
          first
          last
          formatted
        }
        posEmail
        posMostRecentClockInDate
        posStatus
        jobs {
          payrollId {
            databaseId
            sequenceId
          }
          payrollTitle
          payrollRateOfPay {
            amount
            currency
          }
          mappingStatus
          posTitle
          posRateOfPay {
            amount
            currency
          }
        }
      }
      issues {
        type
        severity
      }
    }
  }
`

/**
 * __useGetEmployeeMappingStatusQuery__
 *
 * To run a query within a React component, call `useGetEmployeeMappingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeMappingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeMappingStatusQuery({
 *   variables: {
 *      employeeUuid: // value for 'employeeUuid'
 *   },
 * });
 */
export function useGetEmployeeMappingStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmployeeMappingStatusQuery,
    GetEmployeeMappingStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetEmployeeMappingStatusQuery,
    GetEmployeeMappingStatusQueryVariables
  >(GetEmployeeMappingStatusDocument, options)
}
export function useGetEmployeeMappingStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeeMappingStatusQuery,
    GetEmployeeMappingStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEmployeeMappingStatusQuery,
    GetEmployeeMappingStatusQueryVariables
  >(GetEmployeeMappingStatusDocument, options)
}
export type GetEmployeeMappingStatusQueryHookResult = ReturnType<
  typeof useGetEmployeeMappingStatusQuery
>
export type GetEmployeeMappingStatusLazyQueryHookResult = ReturnType<
  typeof useGetEmployeeMappingStatusLazyQuery
>
export type GetEmployeeMappingStatusQueryResult = Apollo.QueryResult<
  GetEmployeeMappingStatusQuery,
  GetEmployeeMappingStatusQueryVariables
>
export const GetPosMappingOptionsDocument = gql`
  query GetPosMappingOptions($locationUuid: ID!, $employeeUuid: ID!) {
    getPosMappingOptions(
      locationUuid: $locationUuid
      employeeUuid: $employeeUuid
    ) {
      userName {
        formatted
      }
      email
      restaurantUserGuid
      mostRecentClockInDate
      externalEmployeeId
      archived
      mappedToEmployee {
        name {
          formatted
        }
        payrollEmployeeNumber
      }
    }
  }
`

/**
 * __useGetPosMappingOptionsQuery__
 *
 * To run a query within a React component, call `useGetPosMappingOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPosMappingOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPosMappingOptionsQuery({
 *   variables: {
 *      locationUuid: // value for 'locationUuid'
 *      employeeUuid: // value for 'employeeUuid'
 *   },
 * });
 */
export function useGetPosMappingOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPosMappingOptionsQuery,
    GetPosMappingOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPosMappingOptionsQuery,
    GetPosMappingOptionsQueryVariables
  >(GetPosMappingOptionsDocument, options)
}
export function useGetPosMappingOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPosMappingOptionsQuery,
    GetPosMappingOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPosMappingOptionsQuery,
    GetPosMappingOptionsQueryVariables
  >(GetPosMappingOptionsDocument, options)
}
export type GetPosMappingOptionsQueryHookResult = ReturnType<
  typeof useGetPosMappingOptionsQuery
>
export type GetPosMappingOptionsLazyQueryHookResult = ReturnType<
  typeof useGetPosMappingOptionsLazyQuery
>
export type GetPosMappingOptionsQueryResult = Apollo.QueryResult<
  GetPosMappingOptionsQuery,
  GetPosMappingOptionsQueryVariables
>
export const GetNavigationEmployeeDocument = gql`
  query getNavigationEmployee($employeeId: ID!) {
    findEmployeeById(employeeId: $employeeId) {
      contact {
        telephoneNumber {
          telephoneNumber
        }
      }
      user {
        email
        toastIdentityGuid
      }
      employment {
        employeeNumber
        employmentStatus {
          __typename
          ... on ActiveEmploymentStatus {
            adjustedHireDate
            hireDate
          }
          ... on ApplicantEmploymentStatus {
            adjustedHireDate
            hireDate
          }
          ... on DeletedEmploymentStatus {
            adjustedHireDate
            hireDate
          }
          ... on DemoEmploymentStatus {
            adjustedHireDate
            hireDate
          }
          ... on ImplementationEmploymentStatus {
            adjustedHireDate
            hireDate
          }
          ... on LeaveOfAbsenceEmploymentStatus {
            adjustedHireDate
            hireDate
            scheduledLeave {
              endDate
              startDate
            }
          }
          ... on NeverEmployedEmploymentStatus {
            adjustedHireDate
            hireDate
          }
          ... on PendingEmploymentStatus {
            adjustedHireDate
            hireDate
          }
          ... on RehiredEmploymentStatus {
            adjustedHireDate
            hireDate
            rehireDate
          }
          ... on SeasonalEmploymentStatus {
            adjustedHireDate
            hireDate
          }
          ... on TerminatedEmploymentStatus {
            adjustedHireDate
            hireDate
            lastDayOfEmployment
          }
        }
      }
    }
  }
`

/**
 * __useGetNavigationEmployeeQuery__
 *
 * To run a query within a React component, call `useGetNavigationEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavigationEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavigationEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetNavigationEmployeeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNavigationEmployeeQuery,
    GetNavigationEmployeeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetNavigationEmployeeQuery,
    GetNavigationEmployeeQueryVariables
  >(GetNavigationEmployeeDocument, options)
}
export function useGetNavigationEmployeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNavigationEmployeeQuery,
    GetNavigationEmployeeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetNavigationEmployeeQuery,
    GetNavigationEmployeeQueryVariables
  >(GetNavigationEmployeeDocument, options)
}
export type GetNavigationEmployeeQueryHookResult = ReturnType<
  typeof useGetNavigationEmployeeQuery
>
export type GetNavigationEmployeeLazyQueryHookResult = ReturnType<
  typeof useGetNavigationEmployeeLazyQuery
>
export type GetNavigationEmployeeQueryResult = Apollo.QueryResult<
  GetNavigationEmployeeQuery,
  GetNavigationEmployeeQueryVariables
>
export const PayChangeLogDocument = gql`
  query payChangeLog(
    $employeeId: ID!
    $pageToken: String
    $pageSize: Int
    $pageNumber: Int
    $sort: [PayChangeLogSortInput!]
  ) {
    payChangeLog(
      employeeId: $employeeId
      pageToken: $pageToken
      pageSize: $pageSize
      pageNumber: $pageNumber
      sort: $sort
    ) {
      ... on PayChangeLog {
        previousPageToken
        currentPageToken
        nextPageToken
        data {
          ...PayChangeLogEvent
        }
        totalResultCount
      }
    }
  }
  ${PayChangeLogEventFragmentDoc}
`

/**
 * __usePayChangeLogQuery__
 *
 * To run a query within a React component, call `usePayChangeLogQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayChangeLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayChangeLogQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      pageToken: // value for 'pageToken'
 *      pageSize: // value for 'pageSize'
 *      pageNumber: // value for 'pageNumber'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePayChangeLogQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayChangeLogQuery,
    PayChangeLogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PayChangeLogQuery, PayChangeLogQueryVariables>(
    PayChangeLogDocument,
    options
  )
}
export function usePayChangeLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayChangeLogQuery,
    PayChangeLogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PayChangeLogQuery, PayChangeLogQueryVariables>(
    PayChangeLogDocument,
    options
  )
}
export type PayChangeLogQueryHookResult = ReturnType<
  typeof usePayChangeLogQuery
>
export type PayChangeLogLazyQueryHookResult = ReturnType<
  typeof usePayChangeLogLazyQuery
>
export type PayChangeLogQueryResult = Apollo.QueryResult<
  PayChangeLogQuery,
  PayChangeLogQueryVariables
>
export const EmployeePayHistoryDocument = gql`
  query EmployeePayHistory(
    $employeeUuid: ID!
    $first: Int
    $offset: Int
    $filters: PayHistoryFilters
  ) {
    PayHistory(
      employeeUuid: $employeeUuid
      first: $first
      offset: $offset
      filters: $filters
    ) {
      payHistory {
        gross
        net
        checkDate
        taxes
        payStubs {
          checkDate
          checkDate
          payPeriod
          payPeriodId
          payStubNumber
          employeeUuid
          fein
          viewPayStubUrl: downloadUrl
          downloadPayStubUrl: pdfDownloadUrl
        }
      }
      paging {
        total
        pageCount
        pageIndex
      }
    }
  }
`

/**
 * __useEmployeePayHistoryQuery__
 *
 * To run a query within a React component, call `useEmployeePayHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeePayHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeePayHistoryQuery({
 *   variables: {
 *      employeeUuid: // value for 'employeeUuid'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEmployeePayHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmployeePayHistoryQuery,
    EmployeePayHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EmployeePayHistoryQuery,
    EmployeePayHistoryQueryVariables
  >(EmployeePayHistoryDocument, options)
}
export function useEmployeePayHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployeePayHistoryQuery,
    EmployeePayHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EmployeePayHistoryQuery,
    EmployeePayHistoryQueryVariables
  >(EmployeePayHistoryDocument, options)
}
export type EmployeePayHistoryQueryHookResult = ReturnType<
  typeof useEmployeePayHistoryQuery
>
export type EmployeePayHistoryLazyQueryHookResult = ReturnType<
  typeof useEmployeePayHistoryLazyQuery
>
export type EmployeePayHistoryQueryResult = Apollo.QueryResult<
  EmployeePayHistoryQuery,
  EmployeePayHistoryQueryVariables
>
export const LatestEmployeePayHistoryDocument = gql`
  query LatestEmployeePayHistory($employeeUuid: ID!) {
    PayHistory(employeeUuid: $employeeUuid, first: 25, offset: 1) {
      latest {
        gross
        net
        checkDate
        payStubs {
          viewPayStubUrl: downloadUrl
          downloadPaystubUrl: pdfDownloadUrl
        }
      }
    }
  }
`

/**
 * __useLatestEmployeePayHistoryQuery__
 *
 * To run a query within a React component, call `useLatestEmployeePayHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestEmployeePayHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestEmployeePayHistoryQuery({
 *   variables: {
 *      employeeUuid: // value for 'employeeUuid'
 *   },
 * });
 */
export function useLatestEmployeePayHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LatestEmployeePayHistoryQuery,
    LatestEmployeePayHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LatestEmployeePayHistoryQuery,
    LatestEmployeePayHistoryQueryVariables
  >(LatestEmployeePayHistoryDocument, options)
}
export function useLatestEmployeePayHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestEmployeePayHistoryQuery,
    LatestEmployeePayHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LatestEmployeePayHistoryQuery,
    LatestEmployeePayHistoryQueryVariables
  >(LatestEmployeePayHistoryDocument, options)
}
export type LatestEmployeePayHistoryQueryHookResult = ReturnType<
  typeof useLatestEmployeePayHistoryQuery
>
export type LatestEmployeePayHistoryLazyQueryHookResult = ReturnType<
  typeof useLatestEmployeePayHistoryLazyQuery
>
export type LatestEmployeePayHistoryQueryResult = Apollo.QueryResult<
  LatestEmployeePayHistoryQuery,
  LatestEmployeePayHistoryQueryVariables
>
export const PayHistoryAuditDocument = gql`
  query PayHistoryAudit($employeeUuid: ID!, $first: Int, $offset: Int) {
    PayHistoryAudit(
      employeeUuid: $employeeUuid
      first: $first
      offset: $offset
    ) {
      paging {
        total
        pageCount
        pageIndex
      }
      records {
        payPeriod
        modifiedBy
        action
        modifiedDate
      }
    }
  }
`

/**
 * __usePayHistoryAuditQuery__
 *
 * To run a query within a React component, call `usePayHistoryAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayHistoryAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayHistoryAuditQuery({
 *   variables: {
 *      employeeUuid: // value for 'employeeUuid'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePayHistoryAuditQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayHistoryAuditQuery,
    PayHistoryAuditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PayHistoryAuditQuery, PayHistoryAuditQueryVariables>(
    PayHistoryAuditDocument,
    options
  )
}
export function usePayHistoryAuditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayHistoryAuditQuery,
    PayHistoryAuditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PayHistoryAuditQuery,
    PayHistoryAuditQueryVariables
  >(PayHistoryAuditDocument, options)
}
export type PayHistoryAuditQueryHookResult = ReturnType<
  typeof usePayHistoryAuditQuery
>
export type PayHistoryAuditLazyQueryHookResult = ReturnType<
  typeof usePayHistoryAuditLazyQuery
>
export type PayHistoryAuditQueryResult = Apollo.QueryResult<
  PayHistoryAuditQuery,
  PayHistoryAuditQueryVariables
>
export const PayHistoryFiltersDocument = gql`
  query PayHistoryFilters($employeeUuid: ID!) {
    PayHistory(employeeUuid: $employeeUuid) {
      FeinList {
        name
        id
      }
    }
  }
`

/**
 * __usePayHistoryFiltersQuery__
 *
 * To run a query within a React component, call `usePayHistoryFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayHistoryFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayHistoryFiltersQuery({
 *   variables: {
 *      employeeUuid: // value for 'employeeUuid'
 *   },
 * });
 */
export function usePayHistoryFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayHistoryFiltersQuery,
    PayHistoryFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PayHistoryFiltersQuery,
    PayHistoryFiltersQueryVariables
  >(PayHistoryFiltersDocument, options)
}
export function usePayHistoryFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayHistoryFiltersQuery,
    PayHistoryFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PayHistoryFiltersQuery,
    PayHistoryFiltersQueryVariables
  >(PayHistoryFiltersDocument, options)
}
export type PayHistoryFiltersQueryHookResult = ReturnType<
  typeof usePayHistoryFiltersQuery
>
export type PayHistoryFiltersLazyQueryHookResult = ReturnType<
  typeof usePayHistoryFiltersLazyQuery
>
export type PayHistoryFiltersQueryResult = Apollo.QueryResult<
  PayHistoryFiltersQuery,
  PayHistoryFiltersQueryVariables
>
export const PayHistoryReportDocument = gql`
  query PayHistoryReport(
    $employeeUuid: ID!
    $reportType: PayHistoryReportType!
    $from: SpaDateTime
    $to: SpaDateTime
    $feinId: LegacyId
  ) {
    PayHistoryReportHTML: PayHistoryReport(
      employeeUuid: $employeeUuid
      type: $reportType
      from: $from
      to: $to
      feinId: $feinId
    )
  }
`

/**
 * __usePayHistoryReportQuery__
 *
 * To run a query within a React component, call `usePayHistoryReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayHistoryReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayHistoryReportQuery({
 *   variables: {
 *      employeeUuid: // value for 'employeeUuid'
 *      reportType: // value for 'reportType'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      feinId: // value for 'feinId'
 *   },
 * });
 */
export function usePayHistoryReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayHistoryReportQuery,
    PayHistoryReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PayHistoryReportQuery, PayHistoryReportQueryVariables>(
    PayHistoryReportDocument,
    options
  )
}
export function usePayHistoryReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayHistoryReportQuery,
    PayHistoryReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PayHistoryReportQuery,
    PayHistoryReportQueryVariables
  >(PayHistoryReportDocument, options)
}
export type PayHistoryReportQueryHookResult = ReturnType<
  typeof usePayHistoryReportQuery
>
export type PayHistoryReportLazyQueryHookResult = ReturnType<
  typeof usePayHistoryReportLazyQuery
>
export type PayHistoryReportQueryResult = Apollo.QueryResult<
  PayHistoryReportQuery,
  PayHistoryReportQueryVariables
>
export const RehireEmployeeNoChangesDocument = gql`
  mutation RehireEmployeeNoChanges($request: RehireEmployeeNoChangesInput!) {
    rehireEmployeeNoChanges(request: $request)
  }
`
export type RehireEmployeeNoChangesMutationFn = Apollo.MutationFunction<
  RehireEmployeeNoChangesMutation,
  RehireEmployeeNoChangesMutationVariables
>

/**
 * __useRehireEmployeeNoChangesMutation__
 *
 * To run a mutation, you first call `useRehireEmployeeNoChangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRehireEmployeeNoChangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rehireEmployeeNoChangesMutation, { data, loading, error }] = useRehireEmployeeNoChangesMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRehireEmployeeNoChangesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RehireEmployeeNoChangesMutation,
    RehireEmployeeNoChangesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RehireEmployeeNoChangesMutation,
    RehireEmployeeNoChangesMutationVariables
  >(RehireEmployeeNoChangesDocument, options)
}
export type RehireEmployeeNoChangesMutationHookResult = ReturnType<
  typeof useRehireEmployeeNoChangesMutation
>
export type RehireEmployeeNoChangesMutationResult =
  Apollo.MutationResult<RehireEmployeeNoChangesMutation>
export type RehireEmployeeNoChangesMutationOptions = Apollo.BaseMutationOptions<
  RehireEmployeeNoChangesMutation,
  RehireEmployeeNoChangesMutationVariables
>
