import { parsePhoneNumberWithError } from 'libphonenumber-js'

export const validatePhoneNumber = (value: any): boolean => {
  if (value) {
    try {
      return parsePhoneNumberWithError(value.toString(), 'US').isValid()
    } catch (err) {
      return false
    }
  } else {
    return true
  }
}
