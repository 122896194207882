import * as React from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { AllEmploymentStatuses } from '@local/generated/graphql'
import { formatEmploymentDateString, isSupportedStatus } from '../../utils'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

type Props = {
  employeeNumber?: string | null
  employmentType?: string | null
  employmentStatus?: Partial<AllEmploymentStatuses> | null
}

export const EmploymentDetails: React.FunctionComponent<Props> = ({
  employeeNumber,
  employmentStatus,
  employmentType
}) => {
  const { t } = useTranslation('employees')
  const isUnsupportedStatus = !isSupportedStatus(employmentStatus)

  const marginClass = (): string =>
    isUnsupportedStatus || !employeeNumber ? 'mt-4' : 'mt-2.5'

  const rehireDate =
    employmentStatus?.__typename === 'RehiredEmploymentStatus'
      ? formatEmploymentDateString(employmentStatus.rehireDate)
      : undefined

  return (
    <div
      data-testid='employment-details'
      className={'w-full inline-flex flex-col'}
    >
      <EmploymentDetailsInfo
        testId='employee-number'
        title={t('employeeNumber')}
        innerClassNames='type-headline-3 font-semibold'
        subtitle={employeeNumber}
        disableSubtitle={
          isUnsupportedStatus &&
          employmentStatus?.__typename !== 'ImplementationEmploymentStatus'
        }
      />
      <EmploymentDetailsInfo
        testId='employment-type'
        title={t('employmentType')}
        subtitle={employmentType}
        marginClass={marginClass()}
        disableSubtitle={isUnsupportedStatus}
      />
      <EmploymentDetailsInfo
        testId='hire-date'
        title={t('originalHireDate')}
        subtitle={formatEmploymentDateString(employmentStatus?.hireDate)}
        marginClass={marginClass()}
        disableSubtitle={isUnsupportedStatus}
      />
      {rehireDate && (
        <EmploymentDetailsInfo
          testId='rehire-date'
          title={t('rehireDate')}
          subtitle={rehireDate}
          marginClass={marginClass()}
          disableSubtitle={isUnsupportedStatus || rehireDate === 'N/A'}
        />
      )}
    </div>
  )
}

const EmploymentDetailsInfo = ({
  title,
  testId,
  subtitle,
  marginClass,
  innerClassNames,
  disableSubtitle = false
}: React.PropsWithChildren<
  {
    title: string
    subtitle?: string | null
    marginClass?: string
    innerClassNames?: string
    disableSubtitle?: boolean
  } & TestIdentifiable
>) => {
  return (
    <div className={marginClass}>
      <div className='font-semibold mb-1'>{title}</div>
      <div
        className={cx(
          disableSubtitle || !subtitle
            ? 'text-disabled'
            : `text-default ${innerClassNames}`
        )}
        data-testid={`employment-details-${testId}`}
      >
        {disableSubtitle || !subtitle ? 'N/A' : subtitle}
      </div>
    </div>
  )
}
