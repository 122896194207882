import * as React from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import DatePicker from './components/DatePicker'
import OptionsSelector from './components/OptionsSelector'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { Form, useFormikContext } from 'formik'
import { trackAddEmployee } from '@local/track'

type Props = {
  onClose: () => void
  testId?: string
  isLoading: boolean

  rehireNameLabel: string
  rehireDescriptionLabel: string
  rehireDateHelpLabel: string
  rehireDateNoDashLabel: string
  rehireChangesLabel: string
  whatsNextLabel: string
  whatsNextRehireLabel: string
  continueLabel: string
  cancelButtonLabel: string
  rehireContinueLabel: string
  primaryHelperText?: string
  rehireOptions: RehireOption[]
}

export type RehireOption = {
  label: any
  value: 'true' | 'false'
  helpText: any
}

export type RehireDialogueValues = {
  rehireDate: string
  rehireChanges: boolean
}

const RehireDialogue = (props: Props) => {
  const {
    onClose,
    testId = 'rehireModal',
    isLoading,
    rehireNameLabel,
    rehireContinueLabel,
    rehireDescriptionLabel,
    rehireDateHelpLabel,
    rehireDateNoDashLabel,
    rehireChangesLabel,
    whatsNextLabel,
    whatsNextRehireLabel,
    primaryHelperText,
    continueLabel,
    cancelButtonLabel,
    rehireOptions
  } = props

  const {
    values: { rehireChanges },
    handleBlur
  } = useFormikContext<RehireDialogueValues>()

  const rehireTestId = testId || 'rehireModal'

  return (
    <Modal
      size='xxl'
      isOpen
      onRequestClose={onClose}
      className='text-left'
      testId={rehireTestId}
      overflowBehavior={'modal'}
    >
      <Form>
        <Modal.Header>{rehireNameLabel}</Modal.Header>
        <Modal.Body>
          <div>{rehireDescriptionLabel}</div>
          <DatePicker
            fieldName='rehireDate'
            required
            helperText={rehireDateHelpLabel}
            label={rehireDateNoDashLabel}
            onDayBlur={handleBlur}
          />
          <OptionsSelector
            required
            fieldName='rehireChanges'
            label={rehireChangesLabel}
            options={rehireOptions}
            modifiedHelperText={primaryHelperText}
          />
          <div className='font-semibold text-default type-default'>
            {whatsNextLabel}
          </div>
          <div className='font-default text-default type-default'>
            {whatsNextRehireLabel}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <RehireDialogueButtons
            isLoading={isLoading}
            rehireChanges={rehireChanges}
            testId={rehireTestId}
            onClose={onClose}
            firstName={rehireContinueLabel}
            continueLabel={continueLabel}
            cancelButtonLabel={cancelButtonLabel}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

type ButtonProps = {
  isLoading: boolean
  rehireChanges: boolean
  testId: string
  onClose: () => void
  firstName: string
  continueLabel: string
  cancelButtonLabel: string
}

const RehireDialogueButtons = (props: ButtonProps) => {
  const {
    rehireChanges,
    isLoading,
    testId = 'rehireModal',
    onClose,
    firstName,
    cancelButtonLabel,
    continueLabel
  } = props
  return (
    <ButtonGroup>
      <Button
        {...trackAddEmployee('cancel')}
        testId={`${testId}-cancel`}
        onClick={onClose}
        variant='link'
        className='mr-4'
      >
        {cancelButtonLabel}
      </Button>
      {rehireChanges ? (
        <SubmitButton
          isInProgress={isLoading}
          {...trackAddEmployee('rehire-with-changes')}
          iconRight={<ArrowForwardIcon accessibility='decorative' />}
          testId={`${testId}-continue`}
        >
          {continueLabel}
        </SubmitButton>
      ) : (
        <SubmitButton
          {...trackAddEmployee('rehire-no-changes')}
          data-toast-track-id='add-employee-rehire-no-changes'
          isInProgress={isLoading}
          testId={`${testId}-save`}
        >
          {firstName}
        </SubmitButton>
      )}
    </ButtonGroup>
  )
}

export { RehireDialogue, Props }
