import { atom, useRecoilValue, useSetRecoilState } from 'recoil'
import { useLocation, useParams } from 'react-router-dom'
import {
  MutationFunction,
  QueryClient,
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query'
import { useCurrentUser, useFeatureFlag } from '@local/ec-app'
import { getRawMetaContent, queryAs } from '@local/shared-services'
import { Employee, Overview } from './domain'
import { useApi } from '../ApiProvider'

const isOverviewExpandedState = atom({
  key: 'isOverviewExpanded',
  default: false
})

export const isBankAccountMutationPendingState = atom({
  key: 'isBankAccountMutationPending',
  default: false
})

export const useRouteParams = () =>
  useParams<{
    employeeId?: string
    companyCode?: string
    emergencyContactId?: string
  }>()
export const useEmergencyContactId = () => useRouteParams().emergencyContactId
export const useRouteParamOrMeta = (
  routeParam: keyof ReturnType<typeof useRouteParams>,
  metaName: string
) => {
  const result = useRouteParams()[routeParam] || null

  // Use the route first and fallback to a meta tag
  return result ?? getRawMetaContent(metaName) ?? ''
}
export const useEmployeeId = () =>
  useRouteParamOrMeta('employeeId', 'employee-uuid')
export const useCompanyCode = () =>
  useRouteParamOrMeta('companyCode', 'company-code')
export const useIsOverviewExpanded = () =>
  useRecoilValue(isOverviewExpandedState)
export const useSetOverviewExpanded = () =>
  useSetRecoilState(isOverviewExpandedState)

export const useHideEmail = () => useFeatureFlag('ec-elm-hide-email', false)

export const useIsEmployeeProfileNavEnabled = () =>
  useFeatureFlag('ec-hr-employee-profile-nav-enabled', true)

export const useIsViewingSelf = () => {
  const user = useCurrentUser()
  const employeeId = useEmployeeId()

  return user.employeeUuid === employeeId
}

export const useRoutedFromOnboardingChecklist = () => {
  const location = useLocation()
  return location.search.includes('isFromOnboarding=true')
}

/**
 * Updates the cached request in the react-query cache and then optionally invalidates
 * the request to be fetched again.
 * If `undefined` is returned from the data handler, nothing will happen.
 * Note, this does not account for "rolling back". Rollbacks should be handled in a mutation.
 */
export const useOptimisticQueryUpdate = <TResult, TArgs extends any[] = []>(
  key: Parameters<QueryClient['getQueryData']>[0],
  fn: (data: TResult, ...args: TArgs) => TResult,
  options: { invalidate?: boolean } = {}
) => {
  const { invalidate = true } = options
  const queryClient = useQueryClient()
  return (...args: TArgs) => {
    const updatedData = fn(queryClient.getQueryData(key) as TResult, ...args)

    if (updatedData !== undefined) {
      queryClient.setQueryData(key, updatedData)

      if (invalidate) {
        queryClient.invalidateQueries(key)
      }
    }
  }
}

/**
 * Creates a mutation that optimistically updates a query cache.
 * This includes data rollback if the mutation fails.
 */

// old
// MutationConfig<Result, unknown> & {

export const useOptimisticMutation = <Value, QueryData = Value, Result = void>(
  mutator: MutationFunction<Result, Value>,
  queryKey: QueryKey,
  options: any & {
    // Maps the given data back to the query cache.
    mapToQuery?: (queryData: QueryData, data: Value) => QueryData
  }
) => {
  const queryClient = useQueryClient()

  return useMutation<Result, unknown, Value, () => void>(mutator, {
    ...options,
    onMutate: (data, ...args) => {
      options?.onMutate?.(data, ...args) //eslint-disable-line
      queryClient.cancelQueries(queryKey)

      const currentData = queryClient.getQueryData(queryKey)

      // If no data, don't bother updating the cache
      if (currentData) {
        queryClient.setQueryData(
          queryKey,
          (oldData: QueryData | undefined) =>
            options?.mapToQuery?.(oldData!, data) ??
            (data as unknown as QueryData)
        )
      }

      return () => queryClient.setQueryData(queryKey, currentData)
    },
    onError: (error, data, rollback: () => void) => {
      options?.onError?.(error, data, rollback) //eslint-disable-line
      rollback()
    },
    onSettled: (...args) => {
      options?.onSettled?.(...args) // eslint-disable-line
      queryClient.invalidateQueries(queryKey)
    }
  })
}

export const useUpdateOverview = () => {
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()

  return useOptimisticQueryUpdate<Employee | null, [Partial<Overview>]>(
    ['employee', companyCode, employeeId],
    (employee, overview) =>
      employee
        ? {
            ...employee,
            overview: Overview.of({
              ...employee.overview,
              ...overview
            })
          }
        : null
  )
}

export const useToggleOverviewExpand = () => {
  const isOverviewExpanded = useIsOverviewExpanded()
  const setOverviewExpanded = useSetOverviewExpanded()

  return () => setOverviewExpanded(!isOverviewExpanded)
}

export const useCurrentEmployeeQuery = (
  options: { suspense?: boolean; backgroundFetch?: boolean } = {}
): { employee: Employee } => {
  const { suspense = true, backgroundFetch = true } = options
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()
  const queryClient = useQueryClient()
  const currentData = queryClient.getQueryData([
    'employee',
    companyCode,
    employeeId
  ])
  const api = useApi()

  // Only suspend if...
  // - explicitly passed in
  // - or there is no data for the current key
  // - or there is data but background fetch is set to false
  const shouldSuspend =
    typeof suspense === 'boolean' ? suspense : !currentData || !backgroundFetch

  return queryAs(
    'employee',
    useQuery(
      ['employee', companyCode, employeeId],
      () => api.findEmployee(companyCode, employeeId),
      {
        suspense: shouldSuspend
      }
    )
  )
}

export const useEmployee = (
  ...args: Parameters<typeof useCurrentEmployeeQuery>
) => useCurrentEmployeeQuery(...args).employee

export const useNewHireNextStepLink = () => {
  const employeeId = useEmployeeId()
  const companyCode = useCompanyCode()
  const api = useApi()

  return useQuery(['nextStepLink', companyCode, employeeId], () =>
    api.getNewHireNextStepLink(companyCode, employeeId)
  )
}
