import * as React from 'react'
import { isMobile } from 'react-device-detect'

export const useDeviceDetectProvider = () =>
  React.useContext(DeviceDetectContext)

export const DeviceDetectContext = React.createContext<{ isMobile: boolean }>({
  isMobile: false
})

export const DeviceDetectProvider = ({
  children
}: React.PropsWithChildren<{}>) => (
  <DeviceDetectContext.Provider value={{ isMobile }}>
    {children}
  </DeviceDetectContext.Provider>
)
