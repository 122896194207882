import * as React from 'react'
import { useSentryAppExceptionLogger } from '@local/ec-app'
import * as Sentry from '@sentry/browser'
import isObject from 'lodash/isObject'

import { OperationError } from './OperationError'

const UNKNOWN = 'Unknown'

/**
 * Returns a sentry logging function for logging OperationErrors.
 */

const noop = () => {}

export const useOperationErrorLogger = ({
  source,
  scopeFn = noop
}: {
  source: string
  scopeFn?: (scope: Sentry.Scope, error: OperationError) => void
}) => {
  const logger = React.useCallback(
    (scope: Sentry.Scope, error: OperationError) => {
      scope.setExtras({
        logSource: source,
        query: error.printQuery(),
        operationName: error.operation?.operationName ?? UNKNOWN,
        originalError: error.originalError?.message ?? null,
        result: error.result ?? UNKNOWN,
        variables: isObject(error.operation?.variables)
          ? JSON.stringify(error.operation!.variables)
          : UNKNOWN
      })
      scopeFn(scope, error)
    },
    [scopeFn, source]
  )

  return useSentryAppExceptionLogger<OperationError>(logger)
}
