import * as React from 'react'
import { BanquetLoader } from 'banquet-runtime-modules'
import { useEmployeeId } from '../../../hooks'

export const PaymentMethodPage = () => {
  const employeeId = useEmployeeId()

  return (
    <BanquetLoader
      className='w-full'
      name='employee-payment-method-spa'
      spaProps={{ employeeId: employeeId }}
    />
  )
}
