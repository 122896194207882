import * as React from 'react'
import Skeleton from 'react-loading-skeleton'
import { EmployeeErrorBoundary } from '../error/EmployeeErrorBoundary'
import { useIsEmployeeProfileNavEnabled } from './hooks'

import { OverviewView, NavigationView } from './EmployeeAppComponents'
import { Outlet } from 'react-router'

const fallback = (
  <div className='p-12'>
    <Skeleton height={250} />
  </div>
)

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      className='flex flex-wrap max-w-screen-lg sm:flex-wrap md:flex-nowrap lg:flex-nowrap'
      data-testid='employees-app'
    >
      {children}
    </div>
  )
}

export const EmployeeApp = () => {
  const isEmployeeProfileNavEnabled = useIsEmployeeProfileNavEnabled()

  const content = (
    <Layout>
      {!isEmployeeProfileNavEnabled && (
        <div className='w-full shadow md:w-auto bg-white'>
          <OverviewView />
          <NavigationView />
        </div>
      )}
      <Outlet />
    </Layout>
  )

  if (isEmployeeProfileNavEnabled) {
    return (
      <EmployeeErrorBoundary>
        <React.Suspense fallback={fallback}>{content}</React.Suspense>
      </EmployeeErrorBoundary>
    )
  }

  return <EmployeeErrorBoundary>{content}</EmployeeErrorBoundary>
}
