import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export function useQueryParams() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function useSetQueryParams() {
  const navigate = useNavigate()
  const params = useQueryParams()

  return (props: Record<string, string | null | undefined>) => {
    Object.keys(props).forEach((key) => {
      const value = props[key]
      if (value !== null && value !== undefined) {
        params.set(key, value)
      }
    })

    navigate({ search: params.toString() })
  }
}

export function useClearQueryParams() {
  const navigate = useNavigate()
  const params = useQueryParams()

  return (props: Record<string, string | null | undefined>) => {
    Object.keys(props).forEach((key) => {
      params.delete(key)
    })

    navigate({ search: params.toString() })
  }
}
