import * as React from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { AllEmploymentStatuses, DateRange } from '@local/generated/graphql'
import { ReadonlyText } from '@toasttab/buffet-pui-readonly'
import {
  formatEmploymentDateString,
  getEmploymentDateRangeString,
  getEmploymentDetails,
  getEmploymentSubDetails
} from '../../utils'
import { EmploymentStatusIndicator } from './EmploymentStatusIndicator'

const marginClassName = 'mt-3'
const disabledClassName = 'text-color-disabled'

type Props = {
  employmentStatus?: Partial<AllEmploymentStatuses> | null
  mostRecentPaycheck?: string | null
  totalLengthOfService?: DateRange | null
}

export const EmploymentStatusDetails: React.FunctionComponent<Props> = ({
  employmentStatus,
  mostRecentPaycheck,
  totalLengthOfService
}) => {
  const { t } = useTranslation('employees')
  const employmentDetails = getEmploymentDetails(employmentStatus)
  const employmentSubDetails = getEmploymentSubDetails(employmentStatus)

  switch (employmentStatus?.__typename) {
    case 'DemoEmploymentStatus':
      return (
        <>
          <EmploymentStatusIndicator
            employmentStatusType={employmentStatus?.__typename}
          />
          <NotApplicableReadonlyText label={t('mostRecentPaystub')} />
          <EmploymentLength totalLengthOfService={totalLengthOfService} />
        </>
      )

    case 'LeaveOfAbsenceEmploymentStatus':
      return (
        <>
          <EmploymentStatusIndicator
            employmentStatusType={employmentStatus?.__typename}
          />
          <EmploymentStatusReadonlyText
            label={t('leaveType')}
            value={employmentDetails}
          />
          <EmploymentStatusReadonlyText
            label={t('scheduledLeave')}
            value={employmentSubDetails}
          />
        </>
      )

    case 'RehiredEmploymentStatus':
      return (
        <>
          <EmploymentStatusIndicator
            employmentStatusType={employmentStatus?.__typename}
          />
          <EmploymentStatusPaystub mostRecentPaycheck={mostRecentPaycheck} />
          <EmploymentLength totalLengthOfService={totalLengthOfService} />
          <EmploymentStatusReadonlyText
            label={t('dismissalPeriod')}
            value={employmentSubDetails}
          />
        </>
      )

    case 'TerminatedEmploymentStatus':
      return (
        <>
          <EmploymentStatusIndicator
            employmentStatusType={employmentStatus?.__typename}
          />
          <EmploymentStatusReadonlyText
            label={t('lastDayOfEmployment')}
            value={employmentDetails}
          />
          <EmploymentStatusReadonlyText
            label={t('lastDayToReceiveBenefits')}
            value={employmentSubDetails}
          />
        </>
      )

    case 'ActiveEmploymentStatus':
    case 'SeasonalEmploymentStatus':
      return (
        <>
          <EmploymentStatusIndicator
            employmentStatusType={employmentStatus?.__typename}
          />
          <EmploymentStatusPaystub mostRecentPaycheck={mostRecentPaycheck} />
          <EmploymentLength totalLengthOfService={totalLengthOfService} />
        </>
      )

    default:
      return (
        <>
          <EmploymentStatusIndicator
            employmentStatusType={employmentStatus?.__typename}
          />
          <NotApplicableReadonlyText label={t('mostRecentPaystub')} />
          <NotApplicableReadonlyText label={t('employmentLength')} />
        </>
      )
  }
}

const EmploymentLength: React.FunctionComponent<{
  totalLengthOfService?: DateRange | null
}> = ({ totalLengthOfService }) => {
  const { t } = useTranslation('employees')
  const formattedTotalLengthOfService =
    getEmploymentDateRangeString(totalLengthOfService)

  return (
    <ReadonlyText
      label={t('employmentLength')}
      value={formattedTotalLengthOfService}
      containerClassName={marginClassName}
      className={cx(
        formattedTotalLengthOfService === 'N/A' ? disabledClassName : undefined
      )}
    />
  )
}

const EmploymentStatusPaystub: React.FunctionComponent<{
  mostRecentPaycheck?: string | null
}> = ({ mostRecentPaycheck }) => {
  const { t } = useTranslation('employees')

  return (
    <ReadonlyText
      label={t('mostRecentPaystub')}
      value={formatEmploymentDateString(mostRecentPaycheck)}
      containerClassName={marginClassName}
      className={cx(!mostRecentPaycheck ? disabledClassName : undefined)}
    />
  )
}

const NotApplicableReadonlyText: React.FunctionComponent<{
  label: string
}> = ({ label }) => {
  return (
    <ReadonlyText
      label={label}
      value='N/A'
      containerClassName={marginClassName}
      className={disabledClassName}
    />
  )
}

const EmploymentStatusReadonlyText: React.FunctionComponent<{
  label: string
  value: string
}> = ({ label, value }) => {
  return (
    <ReadonlyText
      label={label}
      value={value}
      containerClassName={marginClassName}
      className={cx(value === 'N/A' ? disabledClassName : undefined)}
    />
  )
}
