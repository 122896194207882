const getData = (url: string) =>
  fetch(url, { credentials: 'same-origin' })
    .then((r) => r.json())
    .catch((ex) => {
      console.error(ex)
    })

type useDataProps = {
  url: string
  callback(data: any): void
}

export default ({ url, callback }: useDataProps) => {
  getData(url).then((data) => callback(data))
}
