const en = {
  accessSensitiveInfoMessage:
    'To access sensitive information about your pay preference, please re-login into your Toast account.',
  active: 'Active',
  add: 'Add',
  addAdditionalPay: 'Add additional pay',
  addBankAccount: 'Add bank account',
  addHourlyPay: 'Add hourly pay',
  additionalPay: 'Additional pay',
  addJob: 'Add job',
  addSalary: 'Add salary',
  alertEmployeeUnverified:
    'This employee may not be able to make changes to their account or access employment documents with an unverified email. To verify this employee, send them login instructions and have them sign in to their Toast Payroll account.',
  allBusinesses: 'All businesses',
  allocatedSalary: 'Allocated salary',
  allPaystubs: 'All paystubs',
  amountTooLarge: 'Amount is too large',
  ANNUALAbbr: 'yr',
  annualSalary: 'Annual salary',
  ANNUALSalary: 'Annual salary',
  archived: 'archived',
  attachEarningsButtonLabel:
    'Attach {{ amount }} to {{ paycheckDate }} payroll',
  attachEarningsError:
    "{{ firstName }}'s pay has been updated but retroactive pay cannot be attached to the upcoming payroll at this time. Add an earning to {{ firstName }}'s paycheck during payroll processing. <0>Add an earning</0>",
  attachEarningsErrorSelf:
    'Your pay has been updated but retroactive pay cannot be attached to the upcoming payroll at this time. Add an earning to your paycheck during payroll processing. <0>Add an earning</0>',
  attachEarningsSuccess:
    "You have successfully updated {{ firstName }}'s pay and attached retroactive pay to the upcoming payroll",
  attachEarningsSuccessSelf:
    'You have successfully updated your pay and attached retroactive pay to the upcoming payroll.',
  attachingEarnings: 'Saving additional earnings...',
  audit: 'Audit',
  back: 'Back',
  backToOverview: 'Back to Overview',
  backToPaymentMethod: 'Back to Payment Method',
  bankAccounts: 'Bank accounts',
  BIWEEKLYSalary: 'Bi-weekly salary',
  business: 'Business',
  calculatingRetroPay: 'Calculating retro pay...',
  cancel: 'Cancel',
  cardErrorText: "Sorry, we are unable to load {{ fullName }}'s {{ details }}",
  changeAllocatedSalarySubtitle:
    'Change the allocated salary for each job. {{ employeeFirstName }} will receive a total annual salary for all jobs.',
  changePassword: 'Change password',
  changePasswordDescription: 'Change the password you use to log into Toast',
  changePay: 'Change pay',
  changePayCalendarHelperText:
    '{{ firstName }} will receive a paycheck with updated pay on {{ nextPaycheckDate }}',
  changePayCalendarHelperTextSelf:
    'You will receive a paycheck with updated pay on {{ nextPaycheckDate }}',
  changePayCloseConfirmationContent:
    '{{ employeeFirstName }} will continue to make {{ pay }}. Retroactive pay change has not been saved or attached to the upcoming payroll.',
  changePayCloseConfirmationContentSelf:
    'You will continue to make {{ pay }}. Retroactive pay change has not been saved or attached to the upcoming payroll.',
  changePayCloseConfirmationLeave: 'Leave pay change',
  changePayCloseConfirmationStay: 'Complete pay change',
  changePayCloseConfirmationTitle:
    "Do you want to complete updating {{ employeeFirstName }}'s pay?",
  changePayCloseConfirmationTitleSelf:
    'Do you want to complete updating your pay?',
  changePayError:
    "We couldn't save updates to {{ firstName }}'s pay. Please try again.",
  changePayErrorSelf: "We couldn't save updates to your pay. Please try again.",
  changePaySaved: "You have successfully updated {{ firstName }}'s pay",
  changePaySavedSelf: 'You have successfully updated your pay',
  checkDate: 'Check date',
  clockInPastDay: 'Last clock-in within 1 day',
  clockInPastNDays: 'Last clock-in {{daysSinceClockIn}} days ago',
  close: 'Close',
  companyAddress: 'Company address',
  companyName: 'Company name',
  companyPhoneNumber: 'Company phone number',
  completeYourEmployeesProfile: "Complete your employee's profile",
  confirm: 'Confirm',
  continue: 'Continue',
  continueReceivingPaperChecks: 'Continue receiving paper checks',
  createJob: 'Create job',
  dateOfFinalPaycheck: 'Date of final paycheck',
  dateRange: 'Date range',
  days: 'days',
  ddAuthorization1:
    'By selecting the Direct Deposit method, you authorize Toast Payroll & Team Management, on behalf of your employer, to direct deposit your earnings into the bank account(s) at the financial institution(s) you have designated. You also grant Toast Payroll & Team Management, and your employer, the right to correct any such electronic funds transfer resulting from an erroneous overpayment by debiting your bank account(s) to the extent of such overpayment.',
  ddAuthorization2:
    'Toast uses Plaid to authenticate your bank account for direct deposit. We may store bank account information to facilitate recurring direct deposit payments. Should you wish to revoke this authorization, you may do so by changing your election in Toast Payroll at any time. It may take up to two (2) pay periods for your election to become effective.',
  deductionsDisclaimer: 'Account for deductions',
  delete: 'Delete',
  deleteBankAccount: 'Delete bank account',
  deleteBankAccountMessage:
    'This bank account will be deleted and will no longer receive your paycheck',
  deleteBankAccountMultipleMessage:
    'This bank account will be deleted and will no longer receive you paycheck. The bank account on file will continue to receive your paycheck',
  deleted: 'Deleted',
  demo: 'Demo',
  detail: 'Detail',
  directDeposit: 'Direct deposit',
  directDepositEmployeeDescription: 'Automatic deposit to your bank account',
  directDepositEmployerDescription: "Enter employee's banking information",
  directDepositEmployerSetup:
    'To avoid printing employee paychecks, set up direct deposit for your employee.',
  directDepositIsActive: 'Direct deposit is active',
  directDepositLinkManageDescriptionEE:
    'This change will be reflected in your next pay cycle',
  directDepositLinkManageDescriptionER:
    "This employee's paycheck will be deposited into their bank account.",
  directDepositLinkNoAccountDescriptionEE:
    'Please add a bank account to start using direct deposit. Until a bank account is added, you will continue to receive paper checks',
  directDepositLinkNoAccountDescriptionER:
    "Please add a bank account on your employee's behalf to start using direct deposit. This employee will continue to receive paper checks until a bank account is added.",
  directDepositListAccountsMessage:
    'Paychecks will be deposited into your bank account(s). If at any time Toast is unable to deposit your paycheck into your bank account(s), you will receive a paper check until a new bank account is added.',
  directDepositSettings: 'Direct deposit settings',
  directDepositSetup:
    'To receive your paycheck faster, please use or activate your Toast Pay Card or set up direct deposit.',
  dismissalPeriod: 'Dismissal period',
  dollarPerHour: '$/hr',
  downloadMyToastApp: 'Download MyToast app',
  downloadMyToastConfirmationDescription:
    'A MyToast download link has been sent to your phone. You will not receive your paycheck to your Toast Pay Card unless it is activated through the MyToast mobile app.',
  downloadMyToastStep1: 'Download the MyToast mobile app',
  downloadMyToastStep2: 'Sign up for a Toast Pay Card',
  downloadMyToastStep3: 'Receive and activate your Toast Pay Card†',
  downloadMyToastSteps: 'Follow 3 easy steps to get your Toast Pay Card',
  earningCode: 'Earning code',
  earnings: 'Earnings',
  edit: 'Edit',
  editEmployeeAccount: 'Edit employee account information here.',
  editJob: 'Edit job',
  editToastPayrollAccountInfo:
    'Edit your Toast Payroll account information here',
  eeoAdditionalClassificationDescription:
    'Your employer may be subject to certain governmental recordkeeping and reporting requirements for the administration of civil rights laws and regulations. Submission of this information is voluntary. Employers are prohibited from subjecting employees who refuse to respond to any adverse treatment.',
  eeoClassificationDescription:
    'In order to comply with certain laws and regulations, your employer invites you to provide the following information. Submission of this information is voluntary.',
  eeoClassificationHeader: 'EEO-1 Voluntary Self-Identification',
  eeoRaceEthnicitySubheader: 'Race/ Ethnicity (For EEO-1 Reporting)',
  eeoSexSubheader: 'Sex (For EEO-1 Reporting)',
  effectiveDate: 'effective date',
  email: 'Email',
  emailOrUsername: 'Email or username',
  emailSendFailure:
    'There was an error trying to send the email. Please try again later.',
  emailSendSuccess:
    'An email with login instructions has been sent to this employee',
  emailSupport: 'Email Support',
  emailSupportJobMappingSubtext: 'Subject: Job Mapping Request',
  emailSupportSendSuccess:
    'POS sync requested. This is usually quick, but can take up to an hour.',
  emailSupportTitle: 'Email to Payroll Support',
  emailSupportToMapJob: 'Email payroll support to map this job',
  employee: 'employee',
  employeeFutureHireDate: 'This employee has a future hire date',
  employeeInvite: 'Employee Invite',
  employeeIsArchived: 'Archived employee',
  employeeIsLoading: 'Loading employee, please wait',
  employeeLogin: 'Employee login',
  employeeName: 'Employee Name',
  employeeNeedsToBeActive:
    'Employee needs to be set to active to receive a paycheck',
  employeeNeedsToBeActiveEmployeeSettings:
    'Employee needs to be set to active in profile settings to receive a paycheck',
  employeeNeedsToBeRehired:
    'Employee needs to be rehired to receive a paycheck',
  employeeNoExistingRecord: "This employee doesn't have an existing record",
  employeeNotFoundHeading: "We can't find this employee",
  employeeNotFoundSubheading1:
    "Looks like we can't find or display this profile.",
  employeeNotFoundSubheading2: 'Please contact your manager for help.',
  employeeNotHired: 'This employee was not hired',
  employeeNotHiredYet: "This employee hasn't been hired yet",
  employeeNumber: 'Employee number',
  employeeParam: 'Employee: {{name}}',
  employeeSettingUpPayroll: 'This employee is setting up payroll',
  employerParam: 'Employer: {{name}}',
  employmentDetails: 'Employment details',
  employmentEndDate: 'Employment end date',
  employmentLength: 'Length of employment',
  employmentLengthSinceLastHireDate:
    'Length of employment\nsince last hire date',
  employmentStartDate: 'Employment start date',
  employmentStatus: 'employment status',
  employmentSummary: 'Employment Summary',
  employmentSummarySupplement1:
    'Earnings and Hours represent totals of all earnings types (including regular pay, tips, paid sick time and paid time away from work).',
  employmentSummarySupplement2:
    'Please refer to the pay stubs or pay information for full breakdowns of earnings and hours worked.',
  employmentSummarySupplement3:
    'Earnings and hours are reflective of payrolls processed in Toast Payroll. To view all quarterly earnings broken down by earning code (i.e. earnings from hours worked vs. sick time vs. vacation, etc), visit {{-link}}, specify the year and the quarter.',
  employmentType: 'Employment type',
  fein: 'FEIN',
  filter: 'Filter',
  filterAppliedSupplement:
    'Try updating or clearing your filters to find paystubs',
  filterAppliedTitle: 'No paystubs match your filters',
  filteredPaystubs: 'Filtered paystubs',
  goBack: 'Go back',
  goToYourProfile: 'Go to your profile',
  grossPay: 'Gross pay',
  grossPayOwed: 'Gross pay owed',
  hired: 'Hired',
  hiredDate: 'Hired Date',
  hiredOn: 'Hired on',
  hourAbbr: 'hr',
  HOURAbbr: 'hr',
  hourlyPay: 'Hourly pay',
  hourlyPayDecreaseConfirmation:
    'It looks like {{firstName}} will receive an hourly pay decrease',
  hourlyPayDecreaseConfirmationSelf:
    'It looks like you will receive an hourly pay decrease',
  hourlyPayIncreaseConfirmation:
    'It looks like {{firstName}} will receive a significant hourly pay increase',
  hourlyPayIncreaseConfirmationSelf:
    'It looks like you will receive a significant hourly pay increase',
  hours: 'Hours',
  hoursAbbr: 'hrs',
  hoursClockedIn: 'Hours clocked in',
  hoursThisWeek: 'hours this week',
  hoursWorked: 'Hours worked',
  ifIssueContinuesContactCustomerCare1: 'If this issue continues,',
  ifIssueContinuesContactCustomerCare2: 'contact Customer Care',
  ignored: 'Ignored',
  ignoredEmployeeDescription:
    'This employee is not paid based on POS time entries',
  ignoredProfileJobMapping: 'Job has been ignored',
  ignoredProfileJobMappingDescription:
    'This employee is not paid based on POS time entries',
  ignoredProfileMapping: 'Profile has been ignored',
  ignoreMapping: 'Ignore Mapping',
  ignoreMappingSubtext:
    'If this payroll profile is not paid from POS hours, ignore them to keep things tidy',
  implementation: 'Implementation',
  informationNeededTitle: 'We need some information',
  institutionNotFoundHeader: "Can't find your bank account?",
  job: 'Job',
  jobMappingSuggestion:
    'Map {{firstName}} to a POS profile so hours from POS can come over for this job',
  jobNotMapped: 'Job Not Mapped',
  jobPendingSync: 'Job pending sync',
  jobPendingSyncDescription:
    'After the next sync, this employee’s jobs in POS will match their jobs in Payroll. Or, press the Sync to POS button to update this now.',
  jobsCardTitle: 'Jobs & Pay',
  lastDayOfEmployment: 'Last day of employment',
  lastDayToReceiveBenefits: 'Last day to receive benefits',
  learnMore: 'Learn more',
  leaveLength: 'Length of leave',
  leaveOfAbsence: 'Leave of Absence',
  leaveOfAbsenceOn: 'Leave of absence on',
  leaveType: 'Leave type',
  linked: 'Linked',
  loading: 'Loading...',
  location: 'Location',
  locationMapping: '{{locationName}} Location',
  locationNotMappedBody:
    'This location is not mapped. Please email care to fix this mapping',
  locationNotMappedTitle: 'LOCATION NOT MAPPED',
  mapped: 'Mapped',
  mappingChangeFailure:
    'Hmmm, we couldn’t save those changes. Please try again.',
  mappingChangeSuccess:
    '{{name}} was successfully mapped. All changes have been updated in Payroll and POS.',
  mappingError: 'Mapping Error',
  mappingIgnored: 'Ignored',
  mappingIgnoredSuccess:
    '{{name}} was successfully ignored. All changes have been updated in Payroll and POS.',
  MONTHLYSalary: 'Monthly salary',
  months: 'months',
  moreActions: 'Click for more actions',
  mostRecentClockIn: 'Last clock in {{date}}',
  mostRecentPay: 'most recent pay',
  mostRecentPayPeriodPriorToEmploymentEndDate:
    'Most recent pay periods prior to employment end date',
  mostRecentPayRateAtTimeOfEmployment:
    'Most recent pay rates at time of employment',
  mostRecentPaystub: 'Most recent paystub',
  mostRecentQuartersPriorToEmploymentEndDate:
    'Most recent quarters prior to employment end date',
  mustBePositive: 'Must be positive',
  navigateToDirectDeposit: 'navigate to direct deposit',
  negative: 'Negative',
  neverEmployed: 'Never employed',
  next: 'Next',
  no: 'No',
  noBankAccount: 'No Bank Account',
  noClockInPastMonth: 'No clock-in within last 30 days',
  noEmailInSystemError:
    "We don't have an email for this employee. This employee may not be able to make changes to their account or access employment documents. To have this employee access their Toast Payroll account, please add their new email.",
  noEmailOnFile: 'No email on file',
  noFilterAppliedSupplement:
    'If you believe you should have received pay, please contact your manager',
  noFilterAppliedTitle: "You haven't received pay yet",
  noJobs: "This employee doesn't have any jobs assigned",
  noJobsViewingSelf: 'You have no assigned jobs',
  noSupervisors: "We don't know who {{ firstName }} reports to yet",
  noSupervisorsCanAdd:
    "We don't know who {{ firstName }} reports to yet. Select who they report to.",
  noSupervisorsSelf: "We don't know who you report to",
  noSupervisorsSelfCanAdd:
    "We don't know who you report to. Select who you report to.",
  notMapped: 'Not Mapped',
  notReceivingCheck: 'This employee is not receiving a paycheck',
  okay: 'Okay',
  onboardingInfoNeeded:
    'Looks like we need more information about your employee to create their profile',
  onlyShowingIntegratedLocations:
    'Only showing POS locations that have been fully integrated into Toast Payroll.',
  originalHireDate: 'Original hire date',
  overtimeDisclaimer: 'Include overtime and tips',
  paidAsSalary: 'Paid as salary',
  paidOn: 'Paid on',
  paidOnDate: 'Paid on {{date}}',
  paperCheck: 'Paper check',
  paperCheckConfirmation: 'You are set to receive a paper check',
  paperCheckEmployeeDescription: 'Get a paper check from your employer',
  paperCheckEmployerConfirmation:
    'This employee is set to receive a paper check',
  paperCheckEmployerDescription: 'Print paper check for employee',
  password: 'Password',
  payChange: 'Pay change',
  payChangeRateConfirmation: 'Yes, this is the right amount',
  payChangeTerminatedMessage:
    'Retroactive pay cannot be attached to the upcoming payroll for a terminated employee. Add {{ employeeFirstName }} as an <0>employee during payroll processing</0> and then <1>add an earning</1> to account for this amount.',
  payChangeTerminatedTitle:
    '{{ employeeFirstName }} is no longer an employee at your business',
  payday: 'Payday',
  payHistoryAudit: 'Pay history audit',
  payHistoryDisclaimer:
    '* Take home pay reflects the amount paid on payroll. This may exclude cash tips, PayOuts, and other earnings.',
  payHistoryRecord: '{{action}} by {{user}} on {{date}}',
  payHistoryReport: 'Pay History Report',
  payHistoryReportDescription:
    'Report on all of your pay, earnings, and deductions over a period of time',
  payPeriod: 'Pay period',
  payPeriodStartDate: 'Pay period start date',
  payRates: 'Pay rates',
  payrollJob: 'PAYROLL JOB',
  payrollProfile: 'PAYROLL PROFILE',
  paystub: 'Paystub',
  payStubIndex: 'Paystub {{index}}',
  paystubs: 'Paystubs',
  pending: 'Pending',
  pendingSync: 'Pending Sync',
  phoneNumber: 'Phone Number',
  phoneNumberError: 'Please enter a valid phone number',
  plaidErrorHandlingHeader: "Can't add your bank account?",
  plaidFallbackModalDescription:
    'Enter your account and routing number to add your bank account and set up direct deposit',
  pleaseWaitToSyncAgain: 'Please wait to sync again',
  positive: 'Positive',
  posJob: 'POS JOB',
  posProfile: 'POS PROFILE',
  poweredByToast: 'powered by Toast',
  primary: 'Primary',
  profileNotMapped: 'Profile not mapped',
  profileSettings: 'Profile Settings',
  quarter: 'Quarter',
  quarterlyEarningsAndHoursWorked: 'Quarterly earnings and hours worked',
  QUARTERLYSalary: 'Quarterly salary',
  rateOfPay: 'Rate of pay',
  receivingCheck: 'This employee is receiving a paycheck',
  recentPay: 'Recent pay',
  rehire: 'Rehire',
  rehireChangesLabel: "Are there any changes to {{name}}'s job or pay?",
  rehireChangesNo: 'Rehire {{name}} with the same job and pay',
  rehireChangesYes: 'Review and update {{name}}’s details',
  rehired: 'Re-hired',
  rehireDate: 'Re-hire date',
  rehireDateHelpText:
    'The first date {{name}} will be eligible for pay and/or benefits',
  rehireDateNoDash: 'Rehire date',
  rehireDatePriorTermination:
    "Please choose a date after {{name}}'s termination date {{terminationDate}}",
  rehireDateRequired: 'Rehire date is required',
  rehireDateSixMonths: 'Please choose a date within the next 6 months',
  rehireDescription:
    'Rehiring {{name}} will set their status in Payroll and POS to active and they will be able to clock-in',
  rehiredOn: 'Re-hired on',
  rehireIncompleteEmployee:
    "{{name}}'s  profile is incomplete and must be reviewed and updated to continue their rehire.",
  rehireName: 'Rehire {{name}}',
  rehireSalaryHourlyEmployee:
    '{{name}} is a salary and hourly employee and must continue with the same job and pay. You can update this once they are rehired.',
  rehireSuccess: '{{name}} was successfully rehired',
  remainderPay: 'Remainder pay',
  removeAdditionalPay: 'Remove additional pay',
  replace: 'Replace',
  replaceBankAccount: 'Replace Bank Account?',
  replaceBankAccountMessage:
    'Your existing bank account will be replaced with your new bank account',
  replaceBankAccountMultipleMessage:
    'Your existing bank account will be replaced with your new bank account and current split settings will remain the same.',
  reportsTo: 'Reports to',
  reportType: 'Report type',
  requestSent: 'Request sent',
  required: 'Required',
  resend: 'Resend',
  resetFilters: 'Reset filters',
  resetPassword: 'Reset Password',
  resetPasswordDescription:
    'Employee will receive a reset password link to their email',
  resetPasswordFailure: 'Failed to reset password. Please try again',
  resetPasswordSuccess: 'Password reset sent',
  resolving: 'Resolving',
  retroactivePay: 'Retroactive pay',
  retroactivePayCaption:
    'Add or edit pay to be paid retroactively for hours worked in the past. Retro pay does not include overtime, taxes or deductions.',
  retroDeductionCaption:
    "To deduct the pay difference from {{firstName}}'s paycheck, add a deduction to their paycheck during payroll processing.",
  retroDeductionLegend: "You have decreased {{firstName}}'s pay",
  retroHoursWorkedInputHelperText:
    'Enter hours worked to attach to your upcoming payroll',
  retroLumpSumInputHelperText:
    'Does not include overtime, taxes, and deductions',
  retroPay: 'Retro pay',
  retroPayDisbursementAttachChoice:
    "Yes, save and attach retroactive pay to {{firstName}}'s next paycheck",
  retroPayDisbursementDoNotAttachChoice:
    "No, update {{firstName}}'s pay and account for the pay difference on my own",
  retroPayDisbursementLabel:
    'Do you want to pay the difference for hours worked in the past?',
  retroPayPanelCaption:
    "Unapproved hours will be updated with {{firstName}}'s new pay. Retro pay does not include overtime, taxes, or deductions.",
  retroPayPanelLegend: 'You have chosen a past date',
  retroPayPanelPaidHours: 'Past approved hours for {{dateRange}}',
  runReport: 'Run report',
  salaried: 'Salaried',
  salary: 'Salary',
  salaryAllocation: 'Salary allocation',
  salaryAllocationsAlert:
    'For salary allocated across locations, pay can only be changed effective immediately',
  salaryChange: "Applies to {{ employeeFirstName }}'s annual salary.",
  salaryChangeAppliesToMultipleJobs:
    "Applies to {{ employeeFirstName }}'s annual salary assigned to multiple jobs.",
  salaryChangeAppliesToMultipleJobsWithHourlyJobs:
    "Applies to {{ employeeFirstName }}'s annual salary assigned to multiple jobs. Hourly pay can be updated in the jobs & pay section.",
  salaryChangeWithHourlyJobs:
    "Applies to {{ employeeFirstName }}'s annual salary. Hourly pay can be updated in the jobs & pay section.",
  salaryDecreaseConfirmation:
    'It looks like {{firstName}} will receive a salary pay decrease',
  salaryDecreaseConfirmationSelf:
    'It looks like you will receive a salary pay decrease',
  salaryIncreaseConfirmation:
    'It looks like {{firstName}} will receive a significant salary pay increase',
  salaryIncreaseConfirmationSelf:
    'It looks like you will receive a significant salary pay increase',
  salaryPerPayPeriod: 'Salary per pay period',
  salaryUpdate: 'Salary update',
  save: 'Save',
  saved: 'Saved',
  saving: 'Saving',
  savingPayChange: 'Saving pay change...',
  scheduledLeave: 'Scheduled leave',
  searchByName: 'Search by name...',
  seasonal: 'Seasonal',
  selectAProfile: 'Select a profile',
  selectEarningCode: 'Select an earning code',
  selectUser: 'Select a user',
  SEMIANNUALSalary: 'Semi-annual salary',
  SEMIMONTHLYSalary: 'Semi-monthly salary',
  send: 'Send',
  sendADownloadLink: 'Send a download link',
  sendDownloadLinkError:
    'Something went wrong when sending the download link. Please try again later.',
  sendLinkToDownloadMyToastApp: 'Send me a link to download the MyToast app',
  sendResetPasswordLink: 'Send reset password link',
  setupDirectDeposit: 'Set up direct deposit',
  severanceGiven: 'Severance given',
  showing: 'Showing',
  smsDisclaimer:
    'Program is subject to Toast’s <0>Terms of Service</0> and <1>Privacy Statement</1>. Messaging and data rates may apply. Message frequency varies. Reply HELP for help and STOP to opt out.',
  smsPreferredMyself:
    'Mobile phone numbers are preferred, so you may receive SMS.',
  smsPreferredTeam:
    'Mobile phone numbers are preferred, so the employee may receive SMS.',
  socialSecurityNumber: 'Social Security number (SSN)',
  socialSecurityUsage: 'Social Security Usage',
  socialSecurityUsageSubtext:
    'Is the usage of this social security number for business reasons?',
  someRelationshipsNotMapped: 'SOME RELATIONSHIPS NOT MAPPED',
  somethingWentWrong:
    'Something went wrong. Try reloading the page or contacting support',
  sorrySomethingWentWrong: 'Sorry! Something went wrong.',
  splitDepositSettings: 'Split deposit settings',
  splitDirectDeposit: 'Split direct deposit',
  splitDirectDepositMessage:
    'Split your direct deposit into two bank accounts.',
  splitMethod: 'Split method',
  splitMethodAmountDescription: 'This account will receive entered amount.',
  splitMethodDescription: 'Split your direct deposit by amount or percentage.',
  splitMethodError: 'Percentage amounts need to add up to 100%',
  splitMethodHeader: 'Select how you split your direct deposit',
  splitMethodMinMax: 'Percentages must be between 0% and 100%',
  splitMethodRemainderDescription:
    'This account will receive the rest of your direct deposit.',
  splitMethodSaved: 'Successfully updated split deposit settings',
  ssn: 'SSN',
  status: 'Status',
  summary: 'Summary',
  syncToPOS: 'Sync to POS',
  syncToToastPos: 'Sync to Toast POS',
  takeHomePay: 'Take home pay',
  taxCustomerErrorInformationNeededMessage:
    'Sorry, we were unable to load your tax documents because of missing business information. \n' +
    '            Please contact your manager and ask them to confirm completeness of their Work Tax Location information.',
  taxEmployeeErrorInformationNeededMessage:
    'Toast needs some information to accurately file your taxes and pay you.\n' +
    '            Please go to your profile and fix the following issues:',
  taxMissingFilingStatus: 'Please fill in "Filing status"',
  taxUnhandledError:
    'Sorry, we were unable to load your tax documents. Please have your manager contact Toast Payroll Care Team.',
  taxWrongZipCode: 'ZIP code must be 5 or 9 digits',
  tellUsMoreAboutYourEmployee: 'Tell us more about your employee!',
  terminated: 'Terminated',
  terminatedOn: 'Terminated on',
  terminateOrLOA: 'Terminate or LOA',
  terminationReason: 'Termination reason',
  termsConditionsApply: '*Terms and conditions apply.',
  thanksForContact:
    'Thanks for contacting us, we are working on fixing this job mapping',
  today: 'Today',
  todayWithDate: 'Today, {{ formattedDate }}',
  totalAnnualSalary: 'Total annual salary',
  tryAgain: 'Try again',
  tryAgainLater: 'Please wait a few minutes and try again.',
  unarchivePrompt:
    'Unarchive this profile on Toast Web, or remap them to a new POS profile',
  unmapped: 'Unmapped',
  unsupportedCurrency: 'Unsupported currency',
  upcomingPay: 'upcoming pay',
  update: 'Update',
  updateAccountDetails: 'Update account details',
  userAlreadyMapped:
    'This user is mapped to Payroll profile {{name}} {{employeeNumber}}',
  userMapped: 'Mapped',
  userName: 'Username',
  view: 'View',
  viewMore: 'View more',
  viewPayChangeHistory: 'View pay change history',
  viewPayInformation: 'view pay information',
  viewPaystub: 'View paystub',
  viewReport: 'View report',
  WEEKLYSalary: 'Weekly salary',
  welcomeEmailDescription:
    'A welcome email will be sent with instructions on how to log into Toast Payroll.',
  whatIsYourPhoneNumberMyself: 'What is your phone number?',
  whatIsYourPhoneNumberTeam: "What is the employee's phone number?",
  whatsNext: "What's next?",
  whatsNextRehire:
    '{{name}} will receive an email to update their personal information and complete onboarding',
  whenShouldPayBeApplied: 'When should this take effect?',
  yearAbbr: 'yr',
  years: 'years',
  yes: 'Yes',
  yourEmployee: 'your employee'
}

export default en
