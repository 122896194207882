import * as React from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

export interface LoadSpinnerProps {
  message: string
}

export const LoadSpinner: React.FunctionComponent<LoadSpinnerProps> = ({
  message
}) => {
  return (
    <div
      className='flex flex-col items-center mt-10'
      data-testid='jobs-and-pay-loading-indicator'
    >
      <MerryGoRound size='xs' className='pin-center' />
      <p className='mt-8 type-default text-default pin-center'>{message}</p>
    </div>
  )
}
