import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ToastAccountIcon } from '@toasttab/buffet-pui-icons'
import { PersonName } from '@local/generated/graphql'
import { useGetClockInDateText } from './hooks'
import { getFormattedEmail } from './utils'

interface Props {
  name: PersonName
  email: string | null
  mostRecentClockInDate: Date | null
  isMapped: boolean
}

export const PayrollProfile = ({
  name,
  email,
  mostRecentClockInDate,
  isMapped
}: Props) => {
  const { t } = useTranslation('employees')
  const clockInText = useGetClockInDateText(mostRecentClockInDate)
  const formattedEmail = getFormattedEmail(email)

  return (
    <div className='flex items-center h-full justify-self-start'>
      <div className='mr-3'>
        <ToastAccountIcon accessibility='decorative' />
      </div>
      <div className='self-start break-normal'>
        <div className='font-semibold type-caption text-gray-50 type-overline'>
          {t('payrollProfile')}
        </div>
        <div className='mb-1 font-semibold type-default'>{name.formatted}</div>
        <div>
          {formattedEmail ? (
            <>
              {formattedEmail.username}
              <wbr />@{formattedEmail.domain}
            </>
          ) : (
            t('noEmailOnFile')
          )}
        </div>
        {isMapped && <div>{clockInText}</div>}
      </div>
    </div>
  )
}
