import * as React from 'react'
import { useState } from 'react'
import { useAttachToEsxButton } from '@toasttab/ec-legacy-esx-hooks'
import { useFeature } from '@toasttab/ec-features'
import { RehireDialogueController } from './RehireDialogueController'
import { useCompanyCode, useEmployee, useEmployeeId } from '../../../hooks'
import { RehireEmployeeModal } from '@toasttab/ec-employee-management'
import { useQueryClient } from 'react-query'
import { refreshEmployeeQuery } from '../../../profile/personal/components'

// This component controls hijacking the legacy "Rehire" button on the Status Change page (esx-web .cshtml file)
const StatusChangeRehireController = () => {
  const [isFullRehireModalOpen, setIsFullRehireModalOpen] =
    useState<boolean>(false)
  const [isInitialRehireDialogueOpen, setIsInitialRehireDialogueOpen] =
    useState<boolean>(false)
  const [fullRehireDate, setFullRehireDate] = React.useState<string>(
    new Date().toString()
  )
  const queryClient = useQueryClient()

  const companyCode = useCompanyCode()
  const { overview } = useEmployee()
  const employeeId = useEmployeeId()

  // Hijack the "Rehire+" button on the legacy Status Change page
  useAttachToEsxButton({
    'data-toast-ec-legacy-button-id': 'esx-status-change-rehire-button',
    onClick: () => setIsInitialRehireDialogueOpen(true),
    enabled: useFeature('ec-elm-add-employee', false)
  })

  return (
    <>
      <RehireEmployeeModal
        isOpen={isFullRehireModalOpen}
        employeeId={employeeId}
        initialRehireDate={fullRehireDate}
        onCancel={() => setIsFullRehireModalOpen(false)}
        onSuccess={() => {
          setIsFullRehireModalOpen(false)
          refreshEmployeeQuery(companyCode, employeeId, queryClient)
        }}
      />

      {isInitialRehireDialogueOpen && (
        <RehireDialogueController
          rehireDialogueOpen={isInitialRehireDialogueOpen}
          firstName={overview.chosenName ?? overview.firstName}
          lastName={overview.lastName}
          employeeId={employeeId}
          onSubmit={(changes) => {
            if (changes.rehireChanges) {
              setFullRehireDate(changes.rehireDate)
              setIsFullRehireModalOpen(true)
            }
            setIsInitialRehireDialogueOpen(false)
            refreshEmployeeQuery(companyCode, employeeId, queryClient)
          }}
          onClose={() => setIsInitialRehireDialogueOpen(false)}
        />
      )}
    </>
  )
}

export { StatusChangeRehireController }
