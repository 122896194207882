import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { formatPay } from '../employment/overview/jobs/components/utils'
import { MappingCondition, Money } from '@local/generated/graphql'
import { NOT_MAPPED_STATUSES } from './utils'

interface Props {
  mappingStatus: MappingCondition
  jobTitle: string | null
  rateOfPay: Money | null
  payrollEmployeeFirstName: string | null
  isProfileMapped: boolean
}

const GetPosJobContent = ({
  mappingStatus,
  jobTitle,
  rateOfPay,
  payrollEmployeeFirstName,
  isProfileMapped
}: Props) => {
  const { t } = useTranslation('employees')

  if (
    NOT_MAPPED_STATUSES.includes(mappingStatus) ||
    mappingStatus === 'MAPPED_USER_NOT_MAPPED'
  ) {
    const titleKey =
      isProfileMapped && mappingStatus === 'MAPPED_USER_NOT_MAPPED'
        ? 'jobPendingSync'
        : 'jobNotMapped'

    const textKey =
      isProfileMapped && mappingStatus === 'MAPPED_USER_NOT_MAPPED'
        ? 'jobPendingSyncDescription'
        : mappingStatus === 'MAPPED_USER_NOT_MAPPED'
        ? 'jobMappingSuggestion'
        : 'emailSupportToMapJob'

    return (
      <>
        <div className='mb-1 font-semibold'>{t(titleKey)}</div>
        <>
          {t(textKey, { firstName: payrollEmployeeFirstName || t('employee') })}
        </>
      </>
    )
  }

  if (mappingStatus === 'MAPPED_USER_MAPPING_IGNORED') {
    return (
      <div className='self-start'>
        <div className='mb-1 font-semibold'>
          {t('ignoredProfileJobMapping')}
        </div>
        {t('ignoredProfileJobMappingDescription')}
      </div>
    )
  }

  if (mappingStatus === 'MAPPED_USER_MAPPED') {
    return (
      <div className='self-start'>
        <div className='mb-1 font-semibold'>{jobTitle}</div>
        {rateOfPay?.amount >= 1000
          ? t('salaried')
          : formatPay(false, 'en-US', 'hr', rateOfPay)}
      </div>
    )
  }
}

export const PosJob = (props: Props) => {
  const { t } = useTranslation('employees')

  return (
    <div className='h-full justify-self-start'>
      <div className='font-semibold type-caption text-gray-50 type-overline'>
        {t('posJob')}
      </div>
      {GetPosJobContent(props)}
    </div>
  )
}
