import * as React from 'react'
import Heading from './heading'
import { useTranslation } from 'react-i18next'
import { DataTable } from '@toasttab/buffet-datatable'
import { ProcessWageDate } from '../utils/processWageDate'
import { RecentWage } from '../models/recentWage'

type RecentWageHistoryProps = {
  recentWages: RecentWage[]
}

export const RecentWageHistory = ({ recentWages }: RecentWageHistoryProps) => {
  const { t } = useTranslation('employees')

  const recentWagesTableSchema = {
    dates: {
      label: `${t('payPeriod')}`,
      renderCol(value: string) {
        return ProcessWageDate(value)
      }
    },
    amount: {
      label: `${t('earnings')}*`,
      renderCol(value: number) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(value)
      }
    },
    hours: {
      label: `${t('hoursWorked')}*`
    },
    payStubURL: {
      label: t('paystub'),
      renderCol(value: string) {
        return (
          <a
            href={value}
            target='_blank'
            rel='noopener noreferrer'
            className='text-link no-print'
          >
            {t('viewPaystub')}
          </a>
        )
      }
    }
  }

  return (
    <Heading
      title={t('recentPay')}
      caption={t('mostRecentPayPeriodPriorToEmploymentEndDate')}
    >
      <div className='w-full -mt-3'>
        <DataTable
          data={recentWages}
          hover={false}
          striped
          schema={recentWagesTableSchema}
        />
      </div>
      <p>
        {'* '}
        {t('employmentSummarySupplement1')} {t('employmentSummarySupplement2')}
      </p>
    </Heading>
  )
}
