import isObject from 'lodash/isObject'
import { print } from 'graphql'
import { Operation, DocumentNode } from '@apollo/client'
import { ConstructorOf, RequireKeys } from '@local/types'
import { TypeGuards } from '@local/shared-services'

export interface OperationErrorArgs<
  TError extends Error = Error,
  TResult extends Record<string, any> = Record<string, any>
> {
  query: DocumentNode
  operation?: Operation | null
  originalError?: TError | null
  isNetworkError?: boolean
  result?: TResult
}

/** General operation error with more information about the apollo operation.
 */
export class OperationError<
  TError extends Error = Error,
  TResult extends Record<string, any> = Record<string, any>
> extends Error {
  static readonly is = TypeGuards.fromKeys<OperationError>([
    'query',
    'operation'
  ])

  readonly query!: DocumentNode
  readonly operation!: Operation | null
  readonly originalError!: TError | null
  readonly result!: TResult | undefined
  readonly isNetworkError!: boolean

  constructor({
    query,
    operation = null,
    originalError = null,
    isNetworkError = false,
    result
  }: OperationErrorArgs<TError, TResult>) {
    super(
      Array.isArray(result?.errors)
        ? result?.errors.join('\n')
        : originalError?.message ??
            `Operation Error: ${operation?.operationName ?? print(query)}`
    )

    this.query = query
    this.operation = operation
    this.originalError = originalError
    this.result = result
    this.isNetworkError = isNetworkError
  }

  printQuery(): string {
    return print(this.query)
  }

  /**
   * Determines if the original error from an OperationError is an instance of the given error.
   */
  static hasError<TError extends Error>(
    operationError: any,
    originalError: ConstructorOf<TError>
  ): operationError is OperationError<TError> {
    return (
      OperationError.is(operationError) &&
      isObject(operationError.originalError) &&
      operationError.originalError instanceof originalError
    )
  }

  static hasUserErrors<
    TError extends Error,
    TResult extends Record<string, any>
  >(
    error: OperationError<TError, TResult>
  ): error is RequireKeys<
    OperationError<TError, TResult & { errors: string[] }>,
    'result'
  > {
    return (
      Array.isArray(error.result?.errors) &&
      Boolean(error.result?.errors?.length)
    )
  }
}
