import * as React from 'react'
import { AutoHideSpeed } from '@toasttab/buffet-pui-snackbars'
import {
  OperationRequestHandlersConfig,
  OperationRequestHandlersProvider
} from '@local/ec-app/apollo'
import { ApolloClientsProvider } from '@local/api/ApolloClientsProvider'

const options: Partial<OperationRequestHandlersConfig> = {
  genericErrorMessage:
    'There was an error with the requested operation. Please try again.',
  errorSnackBarOptions: {
    autoHideDuration: AutoHideSpeed.Slow,
    onDismiss: () => {}
  }
}

type Props = {
  children: React.ReactNode
}

export const ApolloConfigurationProvider = ({ children }: Props) => {
  return (
    <ApolloClientsProvider>
      <OperationRequestHandlersProvider options={options}>
        {children}
      </OperationRequestHandlersProvider>
    </ApolloClientsProvider>
  )
}
