import * as React from 'react'
import { Formik } from 'formik'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { useOperationRequestHandlers } from '@local/ec-app/apollo'
import {
  useAccountUserQuery,
  useAccountChangeDetailsMutation,
  useActorCanModifyEmail
} from '../hooks'
import { useHideEmail } from '../../../hooks'
import { ChangeDetails } from '../Domain'
import { FormContainer } from './FormContainer'
import { EmailSuffixIcon } from './EmailSuffixIcon'
import { useNavigateToAccountPage } from '../../personal/hooks'
import { getDisplayEmail } from '../../../utils'
export const DetailsForm = () => {
  const navigateToAccountPage = useNavigateToAccountPage()
  const { accountUser } = useAccountUserQuery()
  const { mutateAsync: changeDetailsMutation } =
    useAccountChangeDetailsMutation()
  const { withErrorSnackbar, withAll, withSuccessSnackbar } =
    useOperationRequestHandlers()
  /*
    A user can always edit their own email
    Before an email is verified, HR+ users and Admins can also modify it. Emails automatically verify when the user registers
    After an email is verified, only Admins and the user themself can modify it.
  */
  const canModifyEmail = useActorCanModifyEmail()
  return (
    <FormContainer testId='details-form-container' title=''>
      <Formik<ChangeDetails>
        initialValues={{
          userUuid: accountUser.id,
          username: accountUser.username,
          email: useHideEmail()
            ? getDisplayEmail(accountUser.email)
            : accountUser.email || ''
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={ChangeDetails.schema}
        onSubmit={(values) =>
          changeDetailsMutation({
            ...values,
            // Username should always be saved to the email
            username: values.email
          })
            .then(
              withAll(withSuccessSnackbar('Account details saved'), () =>
                navigateToAccountPage(false)
              )
            )
            .catch(withErrorSnackbar())
        }
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Panel>
              <p className='mb-6 font-bold type-headline-5'>Employee Login</p>
              {accountUser.showUsername() && (
                <div className='mb-2'>
                  <TextInputField
                    type='text'
                    value={props.values.username}
                    name='username'
                    label='Username'
                    size='lg'
                    testId='username'
                    invalid={props.errors.username != null}
                    errorText={props.errors.username}
                    disabled={true}
                  />
                </div>
              )}
              <div>
                <TextInputField
                  type='text'
                  value={props.values.email}
                  name='email'
                  label={
                    accountUser.showUsername() ? 'Email' : 'Email/Username'
                  }
                  suffix={<EmailSuffixIcon />}
                  suffixVariant='iconButton'
                  size='lg'
                  testId='email'
                  invalid={props.errors.email != null}
                  errorText={props.errors.email}
                  onInput={(e: React.FormEvent<HTMLInputElement>) => {
                    props.setFieldValue('username', e.currentTarget.value)
                  }}
                  disabled={!canModifyEmail}
                />

                <p className='my-1 type-caption text-secondary'>
                  {accountUser.isEmailVerified
                    ? 'Verified email'
                    : 'Unverified email'}
                </p>
              </div>
              <div className='flex justify-end'>
                <Button
                  onClick={() => navigateToAccountPage(true)}
                  variant='secondary'
                  className='mr-4'
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  testId='save'
                  disabled={props.isSubmitting || !canModifyEmail}
                >
                  Save
                </Button>
              </div>
            </Panel>
          </form>
        )}
      </Formik>
    </FormContainer>
  )
}
