import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'
import { DateTime } from 'luxon'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DataTable, DataTableSchema } from '@toasttab/buffet-datatable'
import { GeneralErrorBoundary } from '@local/ec-app'
import { ContactDetailsModal } from './components'
import { Field, FieldName, FieldSkeleton, FieldValue } from './components'
import { OptionView } from './components'
import { Option } from '../../domain'
import Skeleton from 'react-loading-skeleton'
import {
  AddIcon,
  AutorenewIcon,
  ChevronRightIcon,
  EditIcon,
  VisibilityOffIcon,
  VisibilityOnIcon
} from '@toasttab/buffet-pui-icons'
import { MASKED_SSN } from './shared/formHelpers'
import { toDate } from '../../../shared/formHelpers'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import {
  EmergencyContact,
  PostalAddress,
  SocialSecurityAuditEntryDisplay
} from './domain'
import { usePersonalProfile, useSocialSecurityQuery } from './hooks'
import { useEmployee, useIsEmployeeProfileNavEnabled } from '../../hooks'
import { useTranslation } from 'react-i18next'

interface SocialSecurityModalProps {
  onOpenChanged: (isOpen: boolean) => void
  url: string
}

const SocialSecurityModal = (props: SocialSecurityModalProps) => {
  const { onOpenChanged, url } = props

  const [confirmShowSocialSecurity, setConfirmShowSocialSecurity] =
    React.useState(false)

  const [showSsn, setShowSsn] = React.useState(false)

  const { socialSecurity, isSuccess } = useSocialSecurityQuery(
    url,
    confirmShowSocialSecurity
  )

  const schema: DataTableSchema<SocialSecurityAuditEntryDisplay> = {
    name: {
      label: 'User'
    },
    action: {
      label: 'Action'
    },
    socialSecurityNumber: {
      label: 'SSN',
      renderCol: (value) => {
        return value ? (
          <div style={{ width: '90px' }}>{showSsn ? value : MASKED_SSN}</div>
        ) : null
      }
    },
    date: {
      label: 'Date',
      renderCol: (value: DateTime) => value.toLocaleString(DateTime.DATE_SHORT)
    }
  }

  if (socialSecurity && isSuccess) {
    return (
      <Modal
        isOpen={true}
        size='xxl'
        onRequestClose={() => onOpenChanged(false)}
      >
        <Modal.Header>Social Security Number</Modal.Header>
        <Modal.Body>
          <div className='mb-1 text-secondary'>Social Security number</div>
          <div className='inline-flex items-center w-1/3 px-2 py-px mb-8 border rounded-sm flex-nowrap text-secondary'>
            <div className='flex-1' data-testid='ssn-modal-header-value'>
              {showSsn ? socialSecurity.number : MASKED_SSN}
            </div>
            <div
              className='cursor-pointer'
              onClick={() => setShowSsn(!showSsn)}
              data-testid='ssn-modal-header-masked-button'
            >
              {showSsn ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
            </div>
          </div>
          <DataTable<SocialSecurityAuditEntryDisplay>
            hidePaginationMenu={true}
            canPage={true}
            pageSize={10}
            getRowKey={(row) => row.id.toString()}
            schema={schema}
            data={
              socialSecurity.auditLog.items
                .map((a) => a.display())
                .toJS() as SocialSecurityAuditEntryDisplay[]
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => onOpenChanged(false)}
            data-testid='ssn-modal-close-btn'
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <ViewSsnModal
      onSubmit={() => {
        setConfirmShowSocialSecurity(true)
        onOpenChanged(true)
      }}
      onClose={() => {
        setConfirmShowSocialSecurity(false)
        onOpenChanged(false)
      }}
      isInProgress={isSuccess}
    />
  )
}

type Props = {
  onSubmit: () => void
  onClose: () => void
  isInProgress: boolean
}

export const ViewSsnModal = (props: Props) => {
  const { onSubmit, onClose, isInProgress } = props
  const { t } = useTranslation('employees')
  return (
    <Modal isOpen={true}>
      <Modal.Header>{t('socialSecurityUsage')}</Modal.Header>
      <Modal.Body data-testid='ssn-confirmation-modal'>
        {t('socialSecurityUsageSubtext')}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {t('no')}
        </Button>
        <Button
          variant='primary'
          onClick={onSubmit}
          data-testid='ssn-confirmation-modal-yes-button'
          disabled={isInProgress}
          iconLeft={
            isInProgress ? (
              <AutorenewIcon
                className='animate-spin'
                accessibility='decorative'
              />
            ) : null
          }
        >
          {t('yes')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const BasicView = () => {
  const navigate = useNavigate()
  const [showSocialSecurity, setShowSocialSecurity] = React.useState(false)
  const personalProfile = usePersonalProfile()
  const employee = useEmployee()
  const isEmployeeProfileNavEnabled = useIsEmployeeProfileNavEnabled()

  const editBasicInfo = () => navigate('basic')

  if (personalProfile && employee) {
    const { basic, options } = personalProfile

    return (
      <Panel
        label={isEmployeeProfileNavEnabled ? 'Personal Information' : 'Basic'}
        editable={employee.permissions.canEditSsn}
        onClick={editBasicInfo}
        panelId='basic-info'
      >
        <div id='ec-app'>
          {showSocialSecurity && (
            <SocialSecurityModalErrorBoundary
              onOpenChanged={setShowSocialSecurity}
            >
              <SocialSecurityModal
                url={personalProfile.links.socialSecurity}
                onOpenChanged={setShowSocialSecurity}
              />
            </SocialSecurityModalErrorBoundary>
          )}
        </div>

        <div className='flex flex-wrap'>
          <Field testId='first-name'>
            <FieldName>First name</FieldName>
            <FieldValue>{basic.firstName}</FieldValue>
          </Field>
          <Field testId='middle-name'>
            <FieldName>Middle name</FieldName>
            <FieldValue>{basic.middleName}</FieldValue>
          </Field>
          <Field testId='last-name'>
            <FieldName>Last name</FieldName>
            <FieldValue>{basic.lastName}</FieldValue>
          </Field>
          <Field testId='chosen-name'>
            <FieldName>Chosen name</FieldName>
            <FieldValue>{basic.chosenName}</FieldValue>
          </Field>
          {employee.permissions.canViewSsn && (
            <Field testId='ssn'>
              <FieldName>Social Security number</FieldName>
              <FieldValue>
                <button
                  className={cx(
                    'p-0 bg-transparent cursor-pointer text-primary-50',
                    !isEmployeeProfileNavEnabled && 'underline'
                  )}
                  onClick={() => setShowSocialSecurity(true)}
                  data-testid='retrieve-ssn-button'
                >
                  Retrieve SSN
                </button>
              </FieldValue>
            </Field>
          )}
          <Field testId='dob'>
            <FieldName>Date of birth</FieldName>
            <FieldValue>
              {toDate(basic.dateOfBirth)?.toLocaleString(DateTime.DATE_SHORT)}
            </FieldValue>
          </Field>
          <Field testId='gender'>
            <FieldName>Gender</FieldName>
            <FieldValue>
              <OptionView
                selectedValue={basic.gender}
                options={options.gender}
              />
            </FieldValue>
          </Field>
          <Field testId='marital-status'>
            <FieldName>Marital status</FieldName>
            <FieldValue>
              <OptionView
                selectedValue={basic.maritalStatus}
                options={options.maritalStatus}
              />
            </FieldValue>
          </Field>
          <Field testId='ethnicity'>
            <FieldName>Ethnicity</FieldName>
            <FieldValue>
              <OptionView
                selectedValue={basic.ethnicity}
                options={options.ethnicity}
              />
            </FieldValue>
          </Field>
          <Field testId='veteran-status'>
            <FieldName>Veteran status</FieldName>
            <FieldValue>
              {basic.veteranStatus.map((status, index) => (
                <React.Fragment key={'veteranStatus-' + index}>
                  {!!index && ', '}
                  <OptionView
                    selectedValue={status}
                    options={options.veteranStatus}
                  />
                </React.Fragment>
              ))}
            </FieldValue>
          </Field>
          <Field testId='disability'>
            <FieldName>Disability</FieldName>
            <FieldValue>
              <OptionView
                selectedValue={basic.disability}
                options={options.disability}
              />
            </FieldValue>
          </Field>
          <Field testId='license-num'>
            <FieldName>Drivers license number</FieldName>
            <FieldValue>{basic.driversLicense?.number}</FieldValue>
          </Field>
          <Field testId='license-state'>
            <FieldName>Driver license state</FieldName>
            <FieldValue>
              <OptionView
                selectedValue={basic.driversLicense?.state}
                options={options.statesInUnitedStates}
              />
            </FieldValue>
          </Field>
          <Field testId='license-expiration'>
            <FieldName>Driver license expiration</FieldName>
            <FieldValue>
              {toDate(basic.driversLicense?.expirationDate)?.toLocaleString(
                DateTime.DATE_SHORT
              )}
            </FieldValue>
          </Field>
        </div>
      </Panel>
    )
  } else {
    return (
      <Panel
        label={isEmployeeProfileNavEnabled ? 'Personal Information' : 'Basic'}
      >
        <FieldSkeleton repeat={14} />
      </Panel>
    )
  }
}

interface FieldColumnsProps<T> {
  keyPrefix: string
  list: Array<T>
  nameFunc: (value: T) => React.ReactNode
  valueFunc: (value: T) => React.ReactNode
}

const Fields = <T extends any>({
  keyPrefix,
  list,
  nameFunc,
  valueFunc
}: FieldColumnsProps<T>) => (
  <div className='flex flex-wrap'>
    {list.map((item, index) => {
      if (item) {
        return (
          <Field key={keyPrefix + '-' + index}>
            <FieldName>{nameFunc(item)}</FieldName>
            <FieldValue>{valueFunc(item)}</FieldValue>
          </Field>
        )
      } else {
        return null
      }
    })}
  </div>
)

const ContactView = () => {
  const navigate = useNavigate()
  const personalProfile = usePersonalProfile()
  const employee = useEmployee()

  const toggleEditContact = () => navigate('contact')

  if (personalProfile && employee) {
    const contact = personalProfile.contact
    const canEdit = employee.permissions.canEdit

    if (contact.primaryTelephone) {
      return (
        <Panel
          label='Contact Information'
          editable={canEdit}
          onClick={toggleEditContact}
          panelId='contactInformation'
        >
          <Field testId='primary-phone'>
            <FieldName>
              Phone number{contact.primaryTelephone.isMobile ? ' (mobile)' : ''}
            </FieldName>
            <FieldValue>{contact.primaryTelephone.number}</FieldValue>
          </Field>
        </Panel>
      )
    } else {
      return (
        <Panel
          label='Contact Information'
          editable={canEdit}
          onClick={toggleEditContact}
        >
          {''}
        </Panel>
      )
    }
  } else {
    return (
      <Panel label='Contact Information' editable={false}>
        <FieldSkeleton />
      </Panel>
    )
  }
}

type PanelProps = {
  label: string
  editable?: boolean
  onClick?: () => void
  panelId?: string
  children: React.ReactNode
}

const Panel = (props: PanelProps) => {
  const { label, children, editable = false, onClick, panelId } = props

  const oldStyles = 'p-8 -mx-8 md:mx-0 lg:p-12 mb-4 shadow-md bg-white rounded'

  const styles =
    'p-4 md:pt-8 md:p-10 mb-4 md:mb-6 shadow-md-flush md:shadow-md bg-white md:rounded'

  const isEditable = Boolean(editable && onClick)

  const isEmployeeProfileNavEnabled = useIsEmployeeProfileNavEnabled()

  return (
    <div
      className={isEmployeeProfileNavEnabled ? styles : oldStyles}
      data-testid={`${panelId}-panel`}
    >
      <div
        className={cx('flex items-center', {
          'justify-between mb-8': isEmployeeProfileNavEnabled,
          'mb-4': !isEmployeeProfileNavEnabled
        })}
        data-testid={`${panelId}-panel-container`}
      >
        <div
          className='pr-2 font-bold type-headline-5'
          data-testid={`${panelId}-panel-title`}
        >
          {label}
        </div>
        {isEditable &&
          (isEmployeeProfileNavEnabled ? (
            <Button
              className='flex-none'
              onClick={onClick}
              testId={`${panelId}-edit-btn`}
              variant='text-link'
            >
              Edit
            </Button>
          ) : (
            <IconButton
              icon={<EditIcon testId={`${panelId}-edit-icon`} />}
              testId={`${panelId}-edit-btn`}
              onClick={onClick}
            />
          ))}
      </div>
      {children}
    </div>
  )
}

interface AddressProps {
  label: string
  address: PostalAddress | null
  statesInUnitedStates: Array<Option>
  countries: Array<Option>
  addressType: 'home' | 'mailing'
}

const AddressSkeleton = () => (
  <Field>
    <FieldName>
      <Skeleton height={10} width={100} />
    </FieldName>
    <FieldValue>
      <div>
        <Skeleton height={15} width={100} />
      </div>
      <div>
        <Skeleton height={15} width={80} /> <Skeleton height={15} width={20} />
        <br />
        <Skeleton height={15} width={50} />
      </div>
    </FieldValue>
  </Field>
)

const AddressView = ({
  label,
  address,
  statesInUnitedStates,
  countries,
  addressType
}: AddressProps) => {
  if (address) {
    return (
      <Field>
        <FieldName>{label}</FieldName>
        <FieldValue>
          <div data-testid={`${addressType}-address-view`}>
            {address.address} {address.address2}
            <br />
            {address.locality},{' '}
            <OptionView
              options={statesInUnitedStates}
              selectedValue={address.administrativeArea}
              fallback={null}
            />{' '}
            {address.postalCode}
            <br />
            <OptionView
              options={countries}
              selectedValue={address.country}
              fallback={null}
            />
          </div>
        </FieldValue>
      </Field>
    )
  } else {
    return null
  }
}

const AddressesView = () => {
  const navigate = useNavigate()

  const goToAddressEdit = () => navigate('addresses')

  const personalProfile = usePersonalProfile()
  const employee = useEmployee()

  if (personalProfile && employee) {
    const addresses = personalProfile.addresses
    const statesInUnitedStates = personalProfile.options.statesInUnitedStates
    const countries = personalProfile.options.countries
    const canEdit = employee.permissions.canEdit

    if (addresses) {
      return (
        <Panel
          label='Addresses'
          onClick={goToAddressEdit}
          editable={canEdit}
          panelId='address'
        >
          <AddressView
            label='Primary address'
            address={addresses.homeAddress}
            statesInUnitedStates={statesInUnitedStates}
            countries={countries}
            addressType='home'
          />
          <AddressView
            label='Mailing address'
            address={addresses.mailingAddress}
            statesInUnitedStates={statesInUnitedStates}
            countries={countries}
            addressType='mailing'
          />
        </Panel>
      )
    } else {
      return (
        <Panel label='Addresses' onClick={goToAddressEdit} editable={canEdit}>
          <div>Add the employee's home and mailing addresses</div>
        </Panel>
      )
    }
  } else {
    return (
      <Panel label='Addresses'>
        <AddressSkeleton />
        <AddressSkeleton />
      </Panel>
    )
  }
}

const EmergencyContactsView = () => {
  const navigate = useNavigate()
  const [viewContactDetails, setViewContactDetails] = React.useState(false)
  const [currentEmergencyContact, setCurrentEmergencyContact] =
    React.useState<EmergencyContact>(undefined as unknown as EmergencyContact)
  const personalProfile = usePersonalProfile()
  const employee = useEmployee()

  const onContactClicked = (emergencyContact: EmergencyContact) => {
    setCurrentEmergencyContact(emergencyContact)
    setViewContactDetails(true)
  }

  const goToContactList = () => navigate('emergency-contacts')

  const goToCreateContact = () => navigate('emergency-contacts/new')

  if (personalProfile && employee) {
    const canEdit = employee.permissions.canEdit
    const emergencyContacts = personalProfile.emergencyContacts

    return (
      <Panel
        label='Emergency Contacts'
        onClick={goToContactList}
        editable={canEdit}
        panelId='emergencyContacts'
      >
        {viewContactDetails && (
          <ContactDetailsModal
            emergencyContact={currentEmergencyContact}
            isOpen={viewContactDetails}
            onClose={() => setViewContactDetails(false)}
          />
        )}
        <Fields
          keyPrefix='emergencyContact'
          list={emergencyContacts}
          nameFunc={(c) => <>{c.isPrimary ? 'Primary contact' : 'Contact'}</>}
          valueFunc={(c) => (
            <>
              <div className='block pb-2'>
                <div
                  className='cursor-pointer text-link'
                  onClick={() => onContactClicked(c)}
                >
                  {`${c.firstName} ${c.lastName} (${c.relation})`}
                </div>
              </div>
              {c.primaryTelephone}
            </>
          )}
        />
        <Button
          variant='secondary'
          iconLeft={<AddIcon />}
          onClick={goToCreateContact}
          testId='add-emergency-contact-btn'
        >
          Add contact
        </Button>
      </Panel>
    )
  } else {
    return (
      <Panel label='Emergency Contacts'>
        <div>
          <Skeleton height={10} width={50} />
          <br />
          <Skeleton height={15} width={110} />
          <br />
          <Skeleton height={15} width={100} />
        </div>
      </Panel>
    )
  }
}

type ConfirmationProps = {
  onClick: () => void
}

const OnboardedConfirmation = (props: ConfirmationProps) => {
  return (
    <div className='p-5 mb-5 bg-white rounded shadow-md'>
      <div className='p-0 m-0 font-semibold type-headline-3'>
        Thanks! A few more steps left
      </div>
      <div className='pb-8 mt-6 text-secondary'>
        We've got the basics. Fill out additional detail below, or head back to
        the dashboard to continue your onboarding steps.
      </div>
      <Button
        data-testid='back-to-dashboard-btn'
        onClick={props.onClick}
        iconRight={<ChevronRightIcon />}
      >
        Return to dashboard
      </Button>
    </div>
  )
}

type ErrorBoundaryProps = {
  children: React.ReactNode
  onOpenChanged: SocialSecurityModalProps['onOpenChanged']
}

const SocialSecurityModalErrorBoundary = (props: ErrorBoundaryProps) => {
  const { children, onOpenChanged } = props

  const modal = (
    <Modal isOpen={true} onRequestClose={() => onOpenChanged(false)}>
      <Modal.Header>Social Security Number</Modal.Header>
      <Modal.Body>Error loading social security: Server error</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onOpenChanged(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  )

  return (
    <GeneralErrorBoundary fallback={modal}>{children}</GeneralErrorBoundary>
  )
}
export {
  OnboardedConfirmation,
  EmergencyContactsView,
  AddressesView,
  ContactView,
  BasicView,
  SocialSecurityModalErrorBoundary
}
