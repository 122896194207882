import * as React from 'react'
import { Outlet } from 'react-router'
import { LayoutProvider } from '@toasttab/buffet-pui-config-templates'
import { Portal } from '@toasttab/ec-layout'
import { AppHeader } from './AppHeader'
import { AppNav } from './AppNav'
import { useNavigationStyles, useDisableContextMenuBar } from './hooks'

export const AppNavigation = () => {
  useNavigationStyles()
  useDisableContextMenuBar()

  return (
    <LayoutProvider>
      <Portal id='page-header' component={<AppHeader />} />
      <Portal id='page-sidebar' component={<AppNav />} />
      <Outlet />
    </LayoutProvider>
  )
}
