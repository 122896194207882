import * as yup from 'yup'
import { Overview } from './Overview'
import { NavigationTreeNode } from './NavigationTreeNode'

export class EmployeePermissions {
  static NONE = new EmployeePermissions(
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false
  )

  static schema = yup
    .object({
      canView: yup.boolean().default(false).defined(),
      canEdit: yup.boolean().default(false).defined(),
      canSyncToToastPos: yup.boolean().default(false).defined(),
      canShowSyncToToastPos: yup.boolean().default(false).defined(),
      canViewStatusChanges: yup.boolean().default(false).defined(),
      canEditStatusChanges: yup.boolean().default(false).defined(),
      canApproveStatusChanges: yup.boolean().default(false).defined(),
      canViewSsn: yup.boolean().default(false).defined(),
      canEditSsn: yup.boolean().default(false).defined()
    })
    .defined()

  static of(obj: any) {
    return EmployeePermissions.copyOf(
      EmployeePermissions.schema.validateSync(obj, { abortEarly: false })
    )
  }

  static copyOf(permissions: EmployeePermissions) {
    return new EmployeePermissions(
      permissions.canView,
      permissions.canEdit,
      permissions.canSyncToToastPos,
      permissions.canShowSyncToToastPos,
      permissions.canViewStatusChanges,
      permissions.canEditStatusChanges,
      permissions.canApproveStatusChanges,
      permissions.canViewSsn,
      permissions.canEditSsn
    )
  }

  private constructor(
    readonly canView: boolean,
    readonly canEdit: boolean,
    readonly canSyncToToastPos: boolean,
    readonly canShowSyncToToastPos: boolean,
    readonly canViewStatusChanges: boolean,
    readonly canEditStatusChanges: boolean,
    readonly canApproveStatusChanges: boolean,
    readonly canViewSsn: boolean,
    readonly canEditSsn: boolean
  ) {}
}

export class Employee {
  static schema = yup
    .object({
      employeeIsUser: yup.boolean().required(),
      overview: Overview.schema,
      navigation: yup.array(NavigationTreeNode.schema).defined(),
      permissions: EmployeePermissions.schema
    })
    .defined()

  static of(obj: any): Employee {
    const employee = Employee.schema.validateSync(obj, { abortEarly: false })

    return new Employee(
      employee.employeeIsUser,
      Overview.copyOf(employee.overview),
      employee.navigation as NavigationTreeNode[],
      employee.permissions
    )
  }

  private constructor(
    readonly employeeIsUser: boolean,
    readonly overview: Overview,
    readonly navigation: Array<NavigationTreeNode>,
    readonly permissions: EmployeePermissions
  ) {}
}
