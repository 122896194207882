import * as React from 'react'
import cx from 'classnames'
import Skeleton from 'react-loading-skeleton'

export const ReadonlyTextSkeleton = ({
  className
}: Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>) => {
  return (
    <div className={cx('flex flex-col', className)}>
      <Skeleton height='24px' width='80px' />
      <Skeleton className='mt-1' height='24px' width='160px' />
    </div>
  )
}
