import { parsePhoneNumberFromString } from 'libphonenumber-js'
import * as yup from 'yup'
import { StringSchema } from 'yup'
import { validatePhoneNumber } from './shared/validatePhoneNumber'

yup.addMethod<StringSchema>(yup.string, 'formattedPhone', function () {
  return this.transform(function (
    value: string,
    originalvalue: string
  ): string | null {
    if (value) {
      const phoneNumber = parsePhoneNumberFromString(value, 'US')
      if (phoneNumber && phoneNumber.isValid()) {
        return phoneNumber.country === 'US'
          ? phoneNumber.formatNational()
          : phoneNumber.formatInternational()
      } else {
        return originalvalue
      }
    } else {
      return null
    }
  })
})

yup.addMethod<StringSchema>(yup.string, 'phone', function (message?: string) {
  return this.test(
    'isPhoneNumber',
    message || 'Please enter a valid phone number',
    function (value: any): boolean {
      return validatePhoneNumber(value)
    }
  )
})

yup.addMethod<StringSchema>(yup.string, 'stripToNull', function () {
  return this.transform(function (value: string): string | null {
    const stripped = value ? value.trim() : ''
    if (stripped.length === 0) {
      return null
    } else {
      return stripped
    }
  })
})
