import * as React from 'react'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class PublisherEvent<_TPayload = unknown> {
  constructor(public readonly name: string) {}
}

export type PublisherEventPayload<TEvent> = TEvent extends PublisherEvent<
  infer TPayload
>
  ? TPayload
  : never

export type SubscriberHandler<TEvent extends PublisherEvent> = (
  payload: PublisherEventPayload<TEvent>
) => void

/**
 * Creates publisher and subscriber pattern that can be used to communicate between a top level component and child components.
 * One example is syncing error boundaries within the same component tree. Think of it as using higher order state management
 * but for events.
 * @example
 *
 * const {
 *   Provider: MyEventBoundary,
 *   useSubscription: useMyEventSubscription,
 *   usePublisher: useMyEventPublisher
 * } = makePublisherAndSubscriber()
 *
 * const MyCoolEvent = new PublisherEvent<void>('myCoolEvent')
 *
 * <MyEventBoundary>
 *   // {...deep within this tree}
 *   <MyComponent />
 *
 * </MyEventBoundary>
 *
 * const MyComponent = () => {
 *   const [someState, setSomeState] = React.useState(null)
 *   const clearState = () => setSomeState(null)
 *
 *   useMyEventSubscription(MyCoolEvent, () => {
 *     clearState()
 *   })
 *
 *   return <></>
 * }
 */
export const makePublisherAndSubscriber = () => {
  const Context = React.createContext<{
    publish: <TEvent extends PublisherEvent>(
      event: TEvent,
      payload: PublisherEventPayload<TEvent>
    ) => void
    subscribe: <TEvent extends PublisherEvent>(
      event: TEvent,
      handler: (payload: PublisherEventPayload<TEvent>) => void
    ) => () => void
  }>({
    publish: () => {},
    subscribe: () => () => {}
  })

  const Provider = ({
    children,
    onEvent
  }: React.PropsWithChildren<{
    onEvent?: (event: PublisherEvent, payload: unknown) => void
  }>) => {
    const [subscribers, setSubscribers] = React.useState<
      Map<string, Set<(payload: any) => void>>
    >(() => new Map())
    const subscribersRef = React.useRef(subscribers)

    subscribersRef.current = subscribers

    const provider = React.useMemo(
      () =>
        ({
          publish: (event, payload) => {
            for (const handler of subscribersRef.current?.get(event.name) ??
              []) {
              handler(payload)
            }

            if (onEvent) {
              onEvent(event, payload)
            }
          },
          subscribe: (event, handler) => {
            setSubscribers((subs) => {
              if (!subs.has(event.name)) {
                subs.set(event.name, new Set())
              }

              const handlers = subs.get(event.name)

              if (handlers) {
                handlers.add(handler)
              }

              return subs
            })

            return () => {
              setSubscribers((subs) => {
                const handlers = subs.get(event.name)

                if (handlers?.has(handler)) {
                  handlers.delete(handler)

                  if (!handlers.size) {
                    subs.delete(event.name)
                  }
                }

                return subs
              })
            }
          }
        } as React.ContextType<typeof Context>),
      [onEvent]
    )

    return <Context.Provider value={provider}>{children}</Context.Provider>
  }

  const useSubscription = <TEvent extends PublisherEvent>(
    event: TEvent,
    handler: SubscriberHandler<TEvent>
  ) => {
    const { subscribe } = React.useContext(Context)
    const handlerRef = React.useRef(handler)

    handlerRef.current = handler

    React.useEffect(
      () => subscribe(event, (...args) => handlerRef.current(...args)),
      [event, subscribe]
    )
  }

  const usePublisher = () => {
    const { publish } = React.useContext(Context)

    return publish
  }

  const Subscriber = <TEvent extends PublisherEvent>({
    event,
    handler
  }: {
    event: TEvent
    handler: SubscriberHandler<TEvent>
  }): null => {
    useSubscription(event, handler)

    return null
  }

  const Publisher = ({
    children
  }: {
    children: (
      publisher: React.ContextType<typeof Context>['publish']
    ) => React.ReactNode
  }) => {
    const publisher = usePublisher()

    return <>{children(publisher)}</>
  }

  return {
    Provider,
    Subscriber,
    Publisher,
    useSubscription,
    usePublisher
  }
}
