import * as yup from 'yup'
import { PostalAddress } from './PostalAddress'

export class Addresses {
  static schema = yup
    .object({
      homeAddress: PostalAddress.schema.nullable().default(null).defined(),
      mailingAddress: PostalAddress.schema.nullable().default(null).defined()
    })
    .defined()
    .nullable()

  static formSchema = yup
    .object({
      homeAddress: PostalAddress.homeAddressformSchema
        .nullable()
        .defined()
        .required('Home address is required'),
      mailingAddress: PostalAddress.schema.nullable().default(null).defined()
    })
    .required()

  static of(obj: any): Addresses {
    return this.copyOf(
      <Addresses>Addresses.schema.validateSync(obj, { abortEarly: false })
    )
  }

  static copyOf(valid: Addresses): Addresses {
    return new Addresses(
      valid.homeAddress ? PostalAddress.of(valid.homeAddress) : null,
      valid.mailingAddress ? PostalAddress.of(valid.mailingAddress) : null
    )
  }

  private constructor(
    readonly homeAddress: PostalAddress | null,
    readonly mailingAddress: PostalAddress | null
  ) {}

  public mailingAddressIsSameAsHomeAddress(): boolean {
    return this.homeAddress
      ? !this.mailingAddress || this.homeAddress.equals(this.mailingAddress)
      : true
  }
}
