import { Option } from '../../../domain/Option'
import * as React from 'react'
import { FieldBlankValue } from './Field'

export interface OptionViewProps {
  selectedValue: string | null | undefined
  options: Array<Option>
  fallback?: React.ReactElement | null
}

export const OptionView = ({
  options,
  selectedValue,
  fallback = <FieldBlankValue />
}: OptionViewProps) => {
  if (selectedValue) {
    const selected = options.filter(({ value }) => value === selectedValue)

    if (selected.length > 0) {
      return <>{selected[0].label}</>
    }
  }

  return fallback
}
