import * as React from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { CellProps as ReactTableCellProps, Column } from 'react-table'
import { ChevronRightIcon, ForkKnifeIcon } from '@toasttab/buffet-pui-icons'
import { Badge } from '@toasttab/buffet-pui-badge'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import {
  HumanResourcesPermission,
  JobAssignmentsListJobAssignmentFragment,
  JobAssignmentsListSalaryFragment
} from '@local/generated/graphql'
import { trackEmploymentOverview, TrackId } from '@local/track'
import { useIsMobile } from '@local/use-is-mobile'
import { isPaidHourly, usePayCellButtonLabel, usePayCellText } from './utils'
import { ChangePayButton } from './ChangePayButton'
import { HourlyPayChangeModal } from '../payChange/HourlyPayChangeModal'
import { SalaryChangeModal } from '../payChange/SalaryChangeModal'
import { useApi } from '../../../../../ApiProvider'
import { useNavigateTo } from '../../../../../navigation/hooks'
import { useHasPermission } from '../../hooks'

type CellProps =
  ReactTableCellProps<JobAssignmentsListJobAssignmentFragment> & {
    salary?: JobAssignmentsListSalaryFragment
    onPayChangeSuccess: () => void
    viewScheduledPayChange: (scheduledPayChangeId: string) => void
  }

export const MOBILE_HIDDEN_COLUMNS: string[] = ['pay']
export const VIEW_JOBS_COLUMNS: string[] = ['action']
const PRIMARY_ROW_OFFSET = 'mt-4'

export const JobsTableColumns: Column<JobAssignmentsListJobAssignmentFragment>[] =
  [
    {
      id: 'icon',
      // @ts-ignore
      className: 'flex text-secondary',
      width: 34,
      canResize: false,
      Cell: ({ row: { original: jobAssignment } }: CellProps) => {
        return (
          <ForkKnifeIcon
            className={cx(
              jobAssignment.isPrimary ? PRIMARY_ROW_OFFSET : undefined
            )}
            accessibility='decorative'
          />
        )
      }
    } as const,
    {
      id: 'name',
      accessor: 'name',
      Cell: ({
        row: { original: jobAssignment },
        salary,
        onPayChangeSuccess,
        viewScheduledPayChange
      }: CellProps) => {
        const isMobile = useIsMobile()
        return (
          <>
            <NameCell
              isPrimary={jobAssignment.isPrimary}
              name={jobAssignment.name}
              locationName={jobAssignment.locationName}
              id={jobAssignment.id}
            />
            {isMobile && (
              <PayCell
                {...trackEmploymentOverview('pay-edit')}
                jobAssignment={jobAssignment}
                salary={salary}
                onPayChangeSuccess={onPayChangeSuccess}
                viewScheduledPayChange={viewScheduledPayChange}
              />
            )}
          </>
        )
      }
    } as const,
    {
      id: 'pay',
      width: 150,
      // @ts-ignore
      canResize: false,
      Cell: ({
        row: { original: jobAssignment },
        salary,
        onPayChangeSuccess,
        viewScheduledPayChange
      }: CellProps) => {
        const isMobile = useIsMobile()
        return (
          !isMobile && (
            <PayCell
              {...trackEmploymentOverview('pay-edit')}
              className={cx(
                jobAssignment.isPrimary ? PRIMARY_ROW_OFFSET : undefined
              )}
              jobAssignment={jobAssignment}
              salary={salary}
              onPayChangeSuccess={onPayChangeSuccess}
              viewScheduledPayChange={viewScheduledPayChange}
            />
          )
        )
      }
    } as const,
    {
      id: 'action',
      accessor: 'id',
      // @ts-ignore
      className: 'text-right',
      width: 48,
      canResize: false,
      Cell: ({ row: { original: jobAssignment } }: CellProps) => (
        <RowAction
          isPrimaryJob={jobAssignment.isPrimary}
          jobId={jobAssignment.id}
        />
      )
    } as const
  ]

const NameCell: React.FunctionComponent<{
  isPrimary: boolean
  id: string
  name: string
  locationName: string
}> = ({ isPrimary, id, name, locationName }) => {
  const { t } = useTranslation('employees')
  return (
    <>
      {isPrimary ? (
        <Badge testId={`job-${id}-tag`} color='neutral2' variant='statusSm'>
          {t('primary')}
        </Badge>
      ) : undefined}
      <div
        data-testid={`job-${id}-name`}
        className='type-default font-semibold'
      >
        {name}
      </div>
      <div data-testid={`job-${id}-location`} className='pt-0 md:pt-1'>
        {locationName}
      </div>
    </>
  )
}

export interface PayCellProps {
  'data-toast-track-id': TrackId
  testId?: string
  className?: string
  jobAssignment: JobAssignmentsListJobAssignmentFragment
  salary?: JobAssignmentsListSalaryFragment
  onPayChangeSuccess: () => void
  viewScheduledPayChange: (scheduledPayChangeId: string) => void
}

export const PayCell: React.FunctionComponent<PayCellProps> = ({
  'data-toast-track-id': dataToastTrackId,
  testId,
  className,
  jobAssignment,
  salary,
  onPayChangeSuccess,
  viewScheduledPayChange
}) => {
  const isMobile = useIsMobile()
  const isHourlyPay = isPaidHourly(jobAssignment)
  const justifyRight =
    !useHasPermission(HumanResourcesPermission.JOB_ASSIGNMENT_EDIT) &&
    !isMobile &&
    isHourlyPay
  const canChangePay = useHasPermission(HumanResourcesPermission.PAY_EDIT)

  const formattedText = usePayCellText(canChangePay, jobAssignment, salary)
  const buttonLabel = usePayCellButtonLabel(
    canChangePay,
    formattedText.isPlaceholderText,
    !!formattedText.label,
    isPaidHourly(jobAssignment, salary)
  )

  const baseTestId = testId ?? `job-${jobAssignment.id}`

  const hasPendingPayChange = !!(
    jobAssignment?.pendingPayChange || salary?.pendingPayChange
  )
  const pendingPayChangeId =
    (jobAssignment?.pendingPayChange?.id || salary?.pendingPayChange?.id) ?? ''

  const [showPayChangeModal, setShowPayChangeModal] =
    React.useState<boolean>(false)

  return (
    <div className={cx('flex flex-col', className)}>
      {formattedText.label && (
        <div
          data-testid={`${baseTestId}-pay`}
          className={cx(justifyRight ? 'text-right' : '')}
        >
          {formattedText.label}
        </div>
      )}
      {buttonLabel && (
        <ChangePayButton
          data-toast-track-id={dataToastTrackId}
          testId={`${baseTestId}-change-pay`}
          className={cx(
            'pt-0',
            !!formattedText.label ? 'md:pt-1' : undefined,
            justifyRight ? '!justify-end' : ''
          )}
          jobId={jobAssignment.id}
          text={buttonLabel}
          onChangePayClick={() => {
            if (hasPendingPayChange) {
              viewScheduledPayChange(pendingPayChangeId)
            } else {
              setShowPayChangeModal(true)
            }
          }}
        />
      )}
      {isHourlyPay && showPayChangeModal && (
        <HourlyPayChangeModal
          jobAssignmentId={jobAssignment.id}
          onClose={() => setShowPayChangeModal(false)}
          onSuccess={onPayChangeSuccess}
        />
      )}
      {!isHourlyPay && showPayChangeModal && !!salary && (
        <SalaryChangeModal
          onClose={() => setShowPayChangeModal(false)}
          onSuccess={onPayChangeSuccess}
        />
      )}
    </div>
  )
}

const RowAction: React.FunctionComponent<{
  isPrimaryJob: boolean
  jobId: string
}> = ({ isPrimaryJob, jobId }) => {
  const { t } = useTranslation('employees')
  const canViewJob = useHasPermission(
    HumanResourcesPermission.JOB_ASSIGNMENT_EDIT
  )

  const api = useApi()
  const navigateTo = useNavigateTo()

  const navigateToJobAssignmentUrl = async (jobId: string) => {
    const esxWebGenericResponse = await api.getJobAssignmentUrl(jobId)
    if (esxWebGenericResponse?.url) {
      navigateTo(esxWebGenericResponse.url, true)
    }
  }

  return canViewJob ? (
    <IconButton
      {...trackEmploymentOverview('job-edit')}
      data-testid={`job-${jobId}-action-button`}
      className={cx(
        '-mr-3 !p-0',
        isPrimaryJob ? PRIMARY_ROW_OFFSET : undefined
      )}
      icon={
        <ChevronRightIcon
          testId={`job-${jobId}-action-icon`}
          className={'text-secondary'}
          aria-label={t('editJob')}
        />
      }
      onClick={() => navigateToJobAssignmentUrl(jobId)}
    />
  ) : null
}
