import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { LaunchIcon, SubtractCircleIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { RetroPayPanel } from '@local/change-pay'
import { trackPayChange } from '@local/track'
import { useShowRetroDeductionPanel } from './utils'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export const RetroDeductionPanel: React.FunctionComponent<
  {
    employeeFirstName: string
  } & TestIdentifiable
> = ({ employeeFirstName, testId }) => {
  const { t } = useTranslation('employees')
  const showDeductionPanel = useShowRetroDeductionPanel()

  return showDeductionPanel ? (
    <RetroPayPanel
      IconComponent={SubtractCircleIcon}
      legend={t('retroDeductionLegend', { firstName: employeeFirstName })}
      caption={t('retroDeductionCaption', { firstName: employeeFirstName })}
      footer={<Footer testId={`${testId}-footer`} />}
      testId={testId}
      t={t}
    />
  ) : null
}

const Footer: React.FunctionComponent<TestIdentifiable> = ({ testId }) => {
  const url =
    'https://central.toasttab.com/s/article/Toast-Payroll-Adding-or-Editing-Earnings-Deductions'
  const { t } = useTranslation('employees')
  return (
    <Button
      {...trackPayChange('deductions-link')}
      as='a'
      href={url}
      iconRight={<LaunchIcon />}
      rel='noopener noreferrer'
      target='_blank'
      variant='text-link'
      testId={testId}
    >
      {t('deductionsDisclaimer')}
    </Button>
  )
}
