import safeLazy from './shared/safeLazy'

const LazyPayHistoryApp = safeLazy(() => {
  return import('./employee/payHistory/PayHistoryApp')
})

const LazyTeamIndex = safeLazy(() => {
  return import('./employee/employment/position/EmploymentSummaryPage')
})

export { LazyPayHistoryApp, LazyTeamIndex }
