import * as yup from 'yup'
import { Money } from '@local/generated/graphql'
import { useTranslation } from 'react-i18next'

type SalaryAllocationSchema = {
  id: string
  rate: Money
}

const useSalaryAllocationSchema = () => {
  const { t } = useTranslation('employees')

  return yup
    .object({
      id: yup.string().required(t('required', 'Required')),
      rate: yup
        .object({
          amount: yup
            .number()
            .required(t('required', 'Required'))
            .positive(t('mustBePositive', 'Must be positive'))
            .max(99_999_999.99, t('amountTooLarge', 'Amount is too large')),
          currency: yup.string().required(t('required', 'Required'))
        })
        .required(t('required', 'Required'))
    })
    .default(undefined)
    .required(t('required', 'Required'))
}

export type SalaryAllocationsSchema = {
  salaryAllocations: SalaryAllocationSchema[]
  rateConfirmed: boolean
  rateConfirmationRequired: boolean
}

export const useSalaryAllocationsSchema = () => {
  const salaryAllocationSchema = useSalaryAllocationSchema()
  return yup.object({
    salaryAllocations: yup.array(salaryAllocationSchema).defined(),
    rateConfirmed: yup.boolean().when('rateConfirmationRequired', {
      is: true,
      then: yup.boolean().oneOf([true])
    })
  })
}
