import * as React from 'react'
import cx from 'classnames'
import { useIsEmployeeProfileNavEnabled } from '../../../hooks'

export interface FormContainerProps {
  title: string
  testId?: string
}

export const FormContainer = ({
  title,
  children,
  testId = 'form-container'
}: React.PropsWithChildren<FormContainerProps>) => {
  const isEmployeeProfileNavEnabled = useIsEmployeeProfileNavEnabled()
  return (
    <div
      data-testid={testId}
      className={cx(
        'flex-grow',
        isEmployeeProfileNavEnabled ? 'pb-12' : 'p-8 md:p-12'
      )}
    >
      <div className='mx-4 mt-0 mb-8 font-normal md:mx-0 type-headline-4'>
        {title}
      </div>
      <div>{children}</div>
    </div>
  )
}
