import * as React from 'react'
import cx from 'classnames'
import { TrackId } from '@local/track'
import { useIsEmploymentOverviewChangePayActive } from '../../hooks'
import { useApi } from '../../../../../ApiProvider'
import { useNavigateTo } from '../../../../../navigation/hooks'

interface Props {
  'data-toast-track-id': TrackId
  testId: string
  className?: string
  jobId: string
  text: string
  onChangePayClick: () => void
}

export const ChangePayButton: React.FunctionComponent<Props> = ({
  'data-toast-track-id': dataToastTrackId,
  testId,
  className,
  jobId,
  text,
  onChangePayClick
}) => {
  const isChangePayActive = useIsEmploymentOverviewChangePayActive()
  const api = useApi()
  const navigateTo = useNavigateTo()

  const navigateToChangePayUrl = async (jobId: string) => {
    const esxWebGenericResponse = await api.getChangePayUrl(jobId)
    if (esxWebGenericResponse?.url) {
      navigateTo(esxWebGenericResponse.url, true)
    }
  }

  return (
    <a
      data-testid={testId}
      data-toast-track-id={dataToastTrackId}
      className={cx('cursor-pointer whitespace-nowrap', className)}
      onClick={
        isChangePayActive
          ? onChangePayClick
          : () => navigateToChangePayUrl(jobId)
      }
    >
      {text}
    </a>
  )
}
