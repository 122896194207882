import * as React from 'react'
import { QueryErrorResetBoundary } from 'react-query'
import { useTranslation } from 'react-i18next'
import { ErrorBoundaryGroup } from '@local/ec-app'
import { LegacyPageBackHeader } from '@local/legacy-page-back-header'
import {
  HumanResourcesPermission,
  useGetActorPermissionsForEmployeesQuery,
  useGetEmploymentStatusQuery
} from '@local/generated/graphql'
import {
  EmploymentOverviewAppSuspenseBoundary,
  SuspendedEmploymentOverviewApp
} from './EmploymentOverviewAppSuspenseBoundary'
import { useCompanyCode, useEmployeeId } from '../../hooks'
import { EmployeeNotFound } from './components'
import { useHasPermission } from './hooks'
import { useActorPermissionsStore } from './ActorPermissionsStore'

export const EmploymentOverviewBackHeader: React.FunctionComponent = () => {
  const { t } = useTranslation('employees')
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()

  const { loading } = useGetEmploymentStatusQuery({
    variables: { employeeId }
  })

  const { data: actorPermissionsData } =
    useGetActorPermissionsForEmployeesQuery({
      variables: { employeeIds: [employeeId] }
    })
  const actorPermissions = actorPermissionsData?.actorPermissionsForEmployees[0]
  const setActorPermissions = useActorPermissionsStore(
    (x) => x.setActorPermissions
  )
  React.useEffect(() => {
    if (actorPermissions) {
      setActorPermissions(actorPermissions.permissions)
    }
  })

  const canViewEmployee = useHasPermission(
    HumanResourcesPermission.EMPLOYEE_VIEW
  )

  const employmentOverviewUrl = `/${companyCode}/employees/${employeeId}/employment`

  return (
    <QueryErrorResetBoundary>
      <ErrorBoundaryGroup>
        <EmploymentOverviewAppSuspenseBoundary>
          {loading ? (
            <SuspendedEmploymentOverviewApp />
          ) : !canViewEmployee ? (
            <EmployeeNotFound />
          ) : (
            <LegacyPageBackHeader
              testId='employment-overview-back-header'
              url={employmentOverviewUrl}
              text={t('backToOverview')}
            />
          )}
        </EmploymentOverviewAppSuspenseBoundary>
      </ErrorBoundaryGroup>
    </QueryErrorResetBoundary>
  )
}
