import * as React from 'react'
import { useFlexLayout, useRowSelect, useTable } from 'react-table'
import { useNavigate } from 'react-router-dom'
import {
  EmployeeSupervisor,
  useGetActorPermissionsForEmployeesQuery
} from '@local/generated/graphql'
import { OverviewCardTable } from '@local/overview-card'
import { useIsMobile } from '@local/use-is-mobile'
import { useCompanyCode } from '../../../../hooks'
import {
  MOBILE_HIDDEN_COLUMNS,
  SupervisorColumns
} from './SupervisorsTableColumns'

const PAGE_SIZE = 5

export interface Props {
  supervisors: Array<EmployeeSupervisor>
}

export const SupervisorsTable: React.FunctionComponent<Props> = ({
  supervisors
}) => {
  const { rows, prepareRow, setHiddenColumns } = useTable(
    {
      columns: SupervisorColumns,
      data: supervisors
    },
    useRowSelect,
    useFlexLayout
  )
  const supervisorIds = supervisors.map((supervisor) => supervisor.id)
  const { data } = useGetActorPermissionsForEmployeesQuery({
    variables: { employeeIds: supervisorIds }
  })

  const isMobile = useIsMobile()
  React.useEffect(() => {
    // @ts-ignore
    setHiddenColumns(isMobile ? MOBILE_HIDDEN_COLUMNS : [])
  }, [setHiddenColumns, isMobile])

  const companyCode = useCompanyCode()
  const navigate = useNavigate()

  const onSupervisorClick = React.useCallback(
    (supervisor: EmployeeSupervisor) =>
      navigate(`/${companyCode}/employees/${supervisor.id}/employment`),
    [navigate, companyCode]
  )

  return (
    <OverviewCardTable
      testId='supervisors'
      rows={rows}
      prepareRow={prepareRow}
      pageSize={PAGE_SIZE}
      userProps={{
        onClick: onSupervisorClick,
        permissions: data?.actorPermissionsForEmployees
      }}
    />
  )
}
