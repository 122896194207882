exports.__esModule = true
var en = {
  amountPerANNUAL: '{{amount}}/yr',
  amountPerHOUR: '{{amount}}/hr',
  cancel: 'Cancel',
  save: 'Save',
  saved: 'Saved',
  saving: 'Saving',
  yourEmployee: 'your employee'
}
exports['default'] = en
