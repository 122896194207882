import * as React from 'react'
import { EmployeeJob, LevelId } from '@local/generated/graphql'
import { PayrollJob } from './PayrollJob'
import { MappingStatus } from './MappingStatus'
import { PosJob } from './PosJob'
import { JobMappingAction } from './mappingActions/JobMappingAction'

interface Props {
  testId?: string
  job: EmployeeJob
  locationId: LevelId
  payrollEmployeeFirstName: string | null
  isProfileMapped: boolean
}

export const JobCard = ({
  testId,
  job,
  locationId,
  payrollEmployeeFirstName,
  isProfileMapped
}: Props) => {
  return (
    <div data-testid={testId} className={'grid grid-cols-4 items-center'}>
      <PayrollJob title={job.payrollTitle} rateOfPay={job.payrollRateOfPay} />
      <div className='justify-self-center'>
        <MappingStatus
          testId={'job-mapping-status'}
          status={job.mappingStatus}
          isProfileMapped={isProfileMapped}
          forEntity='job'
        />
      </div>
      <PosJob
        mappingStatus={job.mappingStatus}
        jobTitle={job.posTitle}
        rateOfPay={job.posRateOfPay}
        payrollEmployeeFirstName={payrollEmployeeFirstName}
        isProfileMapped={isProfileMapped}
      />
      <div className='justify-self-end'>
        <JobMappingAction
          mappingStatus={job.mappingStatus}
          payrollJobName={job.payrollTitle}
          payrollJobId={job.payrollId}
          payrollLocationId={locationId}
          isProfileMapped={isProfileMapped}
        />
      </div>
    </div>
  )
}
