import { List } from 'immutable'
import { Strings, Validations } from '@local/shared-services'

export class Page<T> {
  readonly next: string | null
  readonly previous: string | null

  constructor(readonly items: List<T>, next?: string, previous?: string) {
    Validations.assertNotNull(items, 'items cannot be null')
    this.next = Strings.stripToNull(next)
    this.previous = Strings.stripToNull(previous)
  }
}
