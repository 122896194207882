import * as React from 'react'
import { EcApplicationWrapper } from '@local/ec-app'
import './styles.css'
import './YupModules'
import { RecoilRoot } from 'recoil'
import { ReactQueryVersionTwoProvider } from './providers/ReactQueryVersionTwoProvider'
import { I18nConfigurationProvider } from './providers/I18nConfigurationProvider'
import { ApolloConfigurationProvider } from './providers/ApolloConfigurationProvider'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { DynamicPortalsUpdaterProvider } from '@local/layout'
import { SignatureCanvasProvider } from './SignatureCanvasProvider'
import { DeviceDetectProvider } from './DeviceDetectProvider'
import { PortalProvider } from '@toasttab/ec-layout'
import { App } from './App'

type Props = {
  name: string
}

const ApplicationRoot = ({ name }: Props) => {
  return (
    <ReactQueryVersionTwoProvider>
      <EcApplicationWrapper testId='ec-application-employees' name={name}>
        <I18nConfigurationProvider>
          <PortalProvider value='data-hr'>
            <RecoilRoot>
              <ApolloConfigurationProvider>
                <DeviceDetectProvider>
                  <SignatureCanvasProvider>
                    <DynamicPortalsUpdaterProvider>
                      <SnackBarProvider>
                        <App />
                      </SnackBarProvider>
                    </DynamicPortalsUpdaterProvider>
                  </SignatureCanvasProvider>
                </DeviceDetectProvider>
              </ApolloConfigurationProvider>
            </RecoilRoot>
          </PortalProvider>
        </I18nConfigurationProvider>
      </EcApplicationWrapper>
    </ReactQueryVersionTwoProvider>
  )
}

export default ApplicationRoot
