import {
  Currency,
  formatCurrency,
  Money as BuffetMoney
} from '@toasttab/buffet-pui-number-utilities'
import {
  Money as TEAMMoney,
  Currency as TEAMCurrency,
  JobAssignment,
  Salary
} from '@local/generated/graphql'
import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import { useTranslation } from 'react-i18next'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'

type payCellText = {
  label: string | undefined
  isPlaceholderText: boolean
}

export const usePayCellText = (
  canChangePay: boolean,
  jobAssignment?: Pick<JobAssignment, 'activeHourlyRateOfPay'>,
  salary?: Pick<Salary, 'activeSalaryRateOfPay'>
): payCellText => {
  const { t } = useTranslation('employees')
  const { locale } = useBuffetContext()

  if (!!salary?.activeSalaryRateOfPay?.amount) {
    return {
      label: formatPay(
        canChangePay,
        locale,
        t('yearAbbr'),
        salary.activeSalaryRateOfPay
      ),
      isPlaceholderText: false
    }
  }

  if (!!jobAssignment?.activeHourlyRateOfPay?.amount) {
    return {
      label: formatPay(
        canChangePay,
        locale,
        t('hourAbbr'),
        jobAssignment?.activeHourlyRateOfPay
      ),
      isPlaceholderText: false
    }
  }

  return { label: t('paidAsSalary'), isPlaceholderText: true }
}

export const usePayCellButtonLabel = (
  canChangePay: boolean,
  isPlaceholderText: boolean,
  isPayDisplayed: boolean,
  isPaidHourly: boolean
): string | undefined => {
  const { t } = useTranslation('employees')

  if (!canChangePay || isPlaceholderText) {
    return undefined
  }
  if (isPayDisplayed) {
    return t('changePay')
  }
  if (isPaidHourly) {
    return t('addHourlyPay')
  }
  return t('addSalary')
}

export const isPaidHourly = (
  jobAssignment?: Pick<JobAssignment, 'activeHourlyRateOfPay'>,
  salary?: Pick<Salary, 'activeSalaryRateOfPay'>
): boolean => {
  if (!!salary) {
    return false
  }
  if (!!jobAssignment?.activeHourlyRateOfPay?.amount) {
    return true
  }
  return false
}

export const formatPay = (
  canChangePay: boolean,
  locale: Locale,
  suffix: string,
  pay?: TEAMMoney | null
): string | undefined => {
  const payAmount = Number(pay?.amount)
  if (isNaN(payAmount) || (payAmount === 0 && canChangePay)) {
    return undefined
  }
  const transformedMoney = moneyTransformer(pay!)
  const currency = formatCurrency(transformedMoney, locale)
  return `${currency}/${suffix}`
}

const moneyTransformer = (incomingMoney: TEAMMoney): BuffetMoney => {
  return {
    amount: Number(incomingMoney.amount),
    currency: currencyTransformer(incomingMoney.currency)
  }
}

const currencyTransformer = (incomingCurrency: TEAMCurrency): Currency => {
  switch (incomingCurrency) {
    case 'CAD':
      return Currency.CAD
    case 'EUR':
      return Currency.EUR
    case 'GBP':
      return Currency.GBP
    case 'USD':
      return Currency.USD
    default:
      return Currency.USD
  }
}
