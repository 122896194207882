import * as React from 'react'
import { CellProps as ReactTableCellProps, Column } from 'react-table'
import { ToastAccountIcon } from '@toasttab/buffet-pui-icons'
import {
  ActorPermissionsForEmployee,
  EmployeeSupervisor,
  HumanResourcesPermission
} from '@local/generated/graphql'
import { trackEmploymentOverview } from '@local/track'
import { useIsMobile } from '@local/use-is-mobile'

type CellProps = ReactTableCellProps<EmployeeSupervisor> & {
  permissions: ActorPermissionsForEmployee[]
  onClick: (supervisor: EmployeeSupervisor) => void
}

export const MOBILE_HIDDEN_COLUMNS: string[] = ['location']

export const SupervisorColumns: Column<EmployeeSupervisor>[] = [
  {
    id: 'icon',
    // @ts-ignore
    className: 'flex-none text-secondary',
    width: 34,
    canResize: false,
    Cell: () => {
      return <ToastAccountIcon />
    }
  } as const,
  {
    id: 'name',
    // @ts-ignore
    className: 'flex flex-col',
    Cell: ({
      row: { original: supervisor },
      onClick,
      permissions
    }: CellProps) => {
      const isMobile = useIsMobile()
      return (
        <>
          <NameCell
            supervisor={supervisor}
            onClick={
              canViewSupervisor(supervisor.id, permissions)
                ? onClick
                : undefined
            }
          />
          {isMobile && <>{supervisor.location}</>}
        </>
      )
    }
  } as const,
  {
    id: 'location',
    accessor: 'location',
    // @ts-ignore
    className: 'text-right',
    Cell: ({ value: location }: CellProps) => {
      const isMobile = useIsMobile()
      return !isMobile && location
    }
  } as const
]

const NameCell: React.FunctionComponent<{
  supervisor: EmployeeSupervisor
  onClick: ((supervisor: EmployeeSupervisor) => void) | undefined
}> = ({ supervisor, onClick }) => {
  return (
    <>
      {onClick ? (
        <SupervisorLink supervisor={supervisor} onClick={onClick} />
      ) : (
        <div
          data-testid='supervisor-name'
          className='type-default font-semibold'
        >
          {supervisor.name}
        </div>
      )}
      {supervisor.job && (
        <div data-testid='supervisor-job' className='pt-0 md:pt-1'>
          {supervisor.job}
        </div>
      )}
    </>
  )
}

const SupervisorLink: React.FunctionComponent<{
  supervisor: EmployeeSupervisor
  onClick: (supervisor: EmployeeSupervisor) => void
}> = ({ supervisor, onClick }) => {
  return (
    <a
      {...trackEmploymentOverview('supervisor-view')}
      data-testid={`supervisor-name`}
      className='cursor-pointer font-semibold'
      onClick={() => onClick(supervisor)}
    >
      {supervisor.name}
    </a>
  )
}

export const canViewSupervisor = (
  supervisorId: string,
  permissions: ActorPermissionsForEmployee[]
) => {
  if (permissions) {
    return permissions
      .find((item) => item.employeeId === supervisorId)
      ?.permissions.some(
        (permission) => permission === HumanResourcesPermission.EMPLOYEE_VIEW
      )
  }
  return false
}
