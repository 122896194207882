import React from 'react'
import { TableRowContent } from '../domain'
import { ChevronRightIcon } from '@toasttab/buffet-pui-icons'
import Skeleton from 'react-loading-skeleton'

type Props = {
  rows: Array<TableRowContent>
  type: string
  isLoading: boolean
}

export const ActionTable = ({ rows: documents, type, isLoading }: Props) => {
  // the below will contain the footer of the table which has paging details
  // example: Viewing 1-10 of 10 documents
  const pagingMessage = `Viewing 1-${documents.length} of ${documents.length} ${
    documents.length > 1 ? type + 's' : type
  }`

  return (
    <>
      <div className='border-t border-b' data-tw>
        {isLoading ? (
          <Skeleton count={3} height={75} />
        ) : (
          documents.map((doc) => <ActionTableRow key={doc.id} content={doc} />)
        )}
      </div>
      <div className='flex flex-col mt-4'>
        <span className='px-2 text-center text-body-s text-secondary'>
          {isLoading ? <Skeleton /> : pagingMessage}
        </span>
      </div>
    </>
  )
}

type RowContentProps = {
  content: TableRowContent
}

const ActionTableRow = (rowContent: RowContentProps) => {
  const { title, subtitle, icon, link, state } = rowContent.content

  return (
    // </a>
    <div
      className='flex px-6 py-4 cursor-pointer bg-gray-0 odd:bg-white'
      data-test-value='document'
      data-test-state={state}
      onClick={(event) => {
        event.preventDefault()

        if (link) {
          // can no longer use history.push() here because it may link to something _not_ in the react app
          window.location.href = link
        }
      }}
    >
      <div className='self-center pr-4 text-primary-75'>{icon}</div>
      <div className='flex-1'>
        <div
          className={`pb-1 text-body ${
            icon === 'done' ? 'text-secondary' : 'text-black font-bold'
          }`}
        >
          {title}
        </div>
        <div className='type-subhead text-secondary text-body-s'>
          {subtitle}
        </div>
      </div>
      <div className='self-center text-secondary'>
        <ChevronRightIcon />
      </div>
    </div>
  )
}
