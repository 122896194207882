import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import { SignaturePad } from './components'
import {
  ChevronLeftIcon,
  DownloadIcon,
  ShareArrowIcon
} from '@toasttab/buffet-pui-icons'
import { useNavigate } from 'react-router-dom'
import DOMPurify from 'dompurify'
import { DocumentSignature } from './domain'
import { FormAlert } from '../profile/personal/components'
import { useApi } from '../../ApiProvider'
import { useEmployeeDocument } from './hooks'
import { useCompanyCode, useEmployeeId } from '../hooks'

type Props = {
  docId: number
}

export const SignDocument = ({ docId }: Props) => {
  const [documentSignatureToSave, setDocumentSignatureToSave] =
    React.useState<DocumentSignature | null>(null)

  const api = useApi()

  const companyCode = useCompanyCode()

  const employeeId = useEmployeeId()

  const { document } = useEmployeeDocument(companyCode, employeeId, docId, {
    suspense: false,
    onError: () =>
      setSubmitError(
        'There was an error loading this document. Please try again in a few moments.'
      )
  })

  const [signature, setSignature] = React.useState('')
  const [submitError, setSubmitError] = React.useState('')
  const navigate = useNavigate()
  // yes, i hate this as much as you do. restaurant owners can build documents in a WYSIWYG editor which generates HTML. this editor does sanitize itself as well
  // dompurify is used for ~good measure~ to ensure its truly pure
  const bodyHtml = { __html: DOMPurify.sanitize(document?.body ?? '') }
  const requiredDocumentsUrl = `/${companyCode}/employees/${employeeId}/required-documents`
  const needsToSignDoc = () =>
    document?.signatureRequired && !document?.isComplete && !signature

  const goToRequiredDocs = () => navigate(requiredDocumentsUrl)

  const submitButtonName = () =>
    document?.nextDocId ? 'Next Document' : 'Complete Documents'

  const saveDocument = () => {
    if (!document) {
      return
    }
    if (needsToSignDoc()) {
      setSubmitError(`Please sign below before clicking ${submitButtonName()}`)
    } else {
      setDocumentSignatureToSave(
        new DocumentSignature(
          docId,
          signature.substring(signature.indexOf(',') + 1)
        )
      )
    }
  }

  React.useEffect(() => {
    const sendDocumentSignature = (documentSignature: DocumentSignature) =>
      api.saveDocumentSignature(
        `/${companyCode}/employees/${employeeId}/required-documents/${docId}`,
        documentSignature
      )

    const goToNextPage = () => {
      if (document?.nextDocId) {
        navigate(`${requiredDocumentsUrl}/${document?.nextDocId}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        docId = document?.nextDocId
      } else {
        goToRequiredDocs()
      }
    }

    const submitDocSig = async () => {
      if (documentSignatureToSave) {
        sendDocumentSignature(documentSignatureToSave)
          .then(() => goToNextPage())
          .catch(() => {
            setSubmitError(
              'There was an error submitting the signature. Please try again in a few moments.'
            )
          })
      }
    }

    submitDocSig()
  }, [companyCode, docId, documentSignatureToSave, employeeId])

  return (
    <div
      className='px-6 pt-12 type-default md:px-40'
      data-testid='sign-documents-page'
    >
      <div className='flex'>
        <div className='relative mt-1 mr-4'>
          <a
            href={requiredDocumentsUrl}
            onClick={(event) => {
              event.preventDefault()
              goToRequiredDocs()
            }}
            style={{ color: 'inherit' }}
          >
            <ChevronLeftIcon />
          </a>
        </div>

        <div>
          <div className='font-semibold type-headline-1'>
            {document ? document?.name : 'Loading document...'}
            <p className='font-normal text-secondary type-default'>
              {document?.signatureRequired
                ? 'Please review and sign'
                : 'Please review'}
            </p>
          </div>
          {document?.letterheadS3Link && (
            <img
              style={{ maxWidth: '100%', objectFit: 'contain' }}
              src={document.letterheadS3Link}
              alt="company's letterhead"
            />
          )}
          <p
            className='mt-10 type-default text-default'
            dangerouslySetInnerHTML={bodyHtml}
          />
          <FormAlert error={submitError} />
          {document?.documentS3Link && (
            <div>
              <div className='mt-12 font-semibold type-headline-3'>
                Review This File
              </div>
              <p className='text-secondary type-default'>
                Please review the following file by downloading the document or
                opening in a new tab.
              </p>
              <div className='flex flex-row md:flex-row lg:flex-row'>
                <div className='p-1'>
                  <a href={document?.documentS3Link}>
                    <Button
                      variant='secondary'
                      iconLeft={<DownloadIcon className='inline-flex' />}
                    >
                      Download
                    </Button>
                  </a>
                </div>
                <div className='p-1'>
                  <a
                    href={document?.documentS3Link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Button
                      variant='secondary'
                      iconLeft={<ShareArrowIcon className='inline-flex' />}
                    >
                      Open
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          )}
          {document?.signatureRequired && (
            <div>
              <div className='mt-12 font-semibold type-headline-3'>
                Signature
              </div>
              <div className='pb-8 text-secondary type-default'>
                I hearby acknowledge I have downloaded, read and understand the
                information contained in this document.
              </div>
              {document?.isComplete ? (
                <div>
                  <div className='type-headline-4'>
                    You've already signed this document. You're all set here!
                  </div>
                </div>
              ) : (
                <div className='w-3 h-1 pb-32'>
                  <SignaturePad onUpdateSignature={setSignature} />
                </div>
              )}
            </div>
          )}

          <div>
            <div className='flex flex-wrap-reverse items-center justify-between pt-12'>
              <div>
                <Button variant='secondary' onClick={() => goToRequiredDocs()}>
                  Cancel
                </Button>
              </div>
              <div>
                {document ? (
                  <Button variant='primary' onClick={() => saveDocument()}>
                    {submitButtonName()}
                  </Button>
                ) : (
                  <Button variant='primary' disabled={true}>
                    {submitButtonName()}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default () => {
  const params = useParams<{ docId: string }>()

  const docId = parseInt(params.docId || '', 10)

  // this key causes the entire component to re-mount when the docId changes.
  // the fact that its here indicates there is loose state management somewhere
  // that isn't being flushed when docId changes but this component stays mounted.

  return <SignDocument key={docId} docId={docId} />
}
