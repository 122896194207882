import * as React from 'react'
import { create, StoreApi } from 'zustand'
import { useTranslation } from 'react-i18next'
import createContext from 'zustand/context'
import { useQuery } from 'react-query'

import { useEcWebClient } from '@local/api/ApolloClientsProvider'
import {
  EmployeePayHistoryDocument,
  EmployeePayHistoryQuery,
  EmployeePayHistoryQueryVariables,
  PayHistoryFilters,
  PayHistoryFiltersDocument,
  PayHistoryFiltersQuery,
  PayHistoryFiltersQueryVariables
} from '@local/generated/graphql'

import { useEmployeeId } from '../../../../hooks'

import {
  CreatePagedStore,
  PagedStore,
  usePagination
} from '../../stores/PagedStore'
import { MapPaystubs } from '../utils'
import { SpaDate } from '@local/shared-services'

const { Provider, useStore } = createContext<StoreApi<PayHistoryStore>>()

export interface PayHistoryStore extends PagedStore {
  filters: PayHistoryFilters
  employeeUuid: string
  applyFilters(filters: PayHistoryFilters): void
  clearFilters(): void
  hasFilters(): boolean
}

const DEFAULT_FILTERS: PayHistoryFilters = {
  feinId: null,
  to: SpaDate.fromToday().plus(14).toJSDate().toISOString(),
  from: SpaDate.fromToday().minus(365).toJSDate().toISOString()
}

const PayHistoryStoreProvider: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  const employeeId = useEmployeeId()

  return (
    <Provider
      createStore={() =>
        create<PayHistoryStore>((set, get) => ({
          ...CreatePagedStore(set),
          filters: DEFAULT_FILTERS,
          employeeUuid: employeeId,
          applyFilters: (filters: PayHistoryFilters) => set({ filters }),
          clearFilters: () => set({ filters: DEFAULT_FILTERS }),
          hasFilters: () => {
            const { from, to, feinId } = get().filters
            return (
              from !== undefined || to !== undefined || feinId !== undefined
            )
          }
        }))
      }
    >
      {children}
    </Provider>
  )
}

const usePayHistoryFilters = () => {
  const employeeUuid = useStore((state) => state.employeeUuid)
  const client = useEcWebClient()

  return useQuery(
    ['pay-history', employeeUuid],
    () =>
      client.query<PayHistoryFiltersQuery, PayHistoryFiltersQueryVariables>({
        query: PayHistoryFiltersDocument,
        variables: {
          employeeUuid
        }
      }),
    {
      suspense: true
    }
  )
}
const DEFAULT_PAYSTUBS: EmployeePayHistoryQuery['PayHistory']['payHistory'] = []

const useFormattedPayHistoryRecords = () => {
  const { data } = usePayHistoryQuery()

  const history = data?.data?.PayHistory.payHistory || DEFAULT_PAYSTUBS

  return React.useMemo(() => MapPaystubs(history), [history])
}

const usePayHistoryQuery = () => {
  const filters = useStore((state) => state.filters)
  const paging = useStore((state) => state.paging)
  const employeeUuid = useStore((state) => state.employeeUuid)
  const client = useEcWebClient()

  return useQuery(
    [
      'pay-history-records',
      employeeUuid,
      filters.feinId,
      filters.to,
      filters.from,
      paging.first,
      paging.offset
    ],
    async () =>
      client.query<EmployeePayHistoryQuery, EmployeePayHistoryQueryVariables>({
        query: EmployeePayHistoryDocument,
        variables: {
          employeeUuid,
          filters,
          ...paging
        }
      }),
    {
      suspense: true
    }
  )
}

const usePayHistoryPaging = () => {
  const records = usePayHistoryQuery()

  return usePagination(useStore, records.data?.data.PayHistory.paging)
}

/**
 * returns a list of available feins including an "all"
 *
 * This array will will always have at least one element (the default)
 */
const useFeinFilterOptions = () => {
  const { t } = useTranslation('employees')
  const { data } = usePayHistoryFilters()

  const feins =
    data?.data.PayHistory.FeinList.map((fein) => ({
      label: fein.name,
      value: fein.id
    })) ?? []

  feins.unshift({ label: t('allBusinesses'), value: 0 })

  return { feins, hasFeins: feins.length === 1 }
}

export {
  PayHistoryStoreProvider,
  usePayHistoryPaging,
  usePayHistoryQuery,
  useStore as usePayHistoryStore,
  useFormattedPayHistoryRecords,
  useFeinFilterOptions
}
