import * as React from 'react'
import i18next from 'i18next'
import { useTable } from 'react-table'
import { useTranslation } from 'react-i18next'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Modal } from '@toasttab/buffet-pui-modal'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  ErroredPayChangesJobAssignmentFragment,
  ErroredPayChangesSalaryFragment,
  PayInterval,
  PendingPayChangeStatus,
  UpdateScheduledPayChangeStatusesMutation,
  useErroredPayChangesQuery,
  useUpdateScheduledPayChangeStatusesMutation
} from '@local/generated/graphql'
import { trackErroredPayChange, useTracking } from '@local/track'
import { i18Formats } from '@local/ec-app'
import { PayChangeCardProps } from '@local/change-pay'
import { useEmployeeId } from '../../../../../hooks'
import { useJobAssignmentsStore } from '../../JobAssignmentsStore'
import { PayChangesColumns, PayChangesTable } from '../components'

type Props = {
  /** the function to call when the modal is closed */
  onClose: VoidFunction
  /** the function to call when the errors have been acknowledged */
  onErrorAcknowledge: VoidFunction
  testId?: string
}

export const ErroredPayChangesModal: React.FunctionComponent<Props> = ({
  onClose,
  onErrorAcknowledge,
  testId = 'errored-pay-changes-modal'
}) => {
  const { t } = useTranslation('scheduled-pay-changes')
  const employeeId = useEmployeeId()
  const employeeFirstName = useJobAssignmentsStore((x) => x.employeeFirstName)

  const { loading, data } = useErroredPayChangesQuery({
    variables: { employeeId }
  })

  const erroredPayChanges = useErroredPayChanges(
    data?.findEmployeeById?.jobAssignmentPayChangeErrors || [],
    data?.findEmployeeById?.salaryChangeErrors
  )

  const { rows, prepareRow } = useTable({
    columns: PayChangesColumns,
    data: erroredPayChanges
  })

  const [updatePendingPayChangeStatuses, { loading: saving }] =
    useUpdateScheduledPayChangeStatusesMutation()

  const onClick = useOnClick(
    erroredPayChanges,
    updatePendingPayChangeStatuses,
    onClose,
    onErrorAcknowledge
  )

  const { track: trackingHook } = useTracking()

  return loading || erroredPayChanges.length <= 0 ? null : (
    <Modal
      testId={testId}
      className='relative'
      isOpen={true}
      onRequestClose={() => {
        trackingHook('errored-pay-change-modal.close')
        onClose()
      }}
      position='pin-right'
    >
      <Modal.Header>
        {t('erroredModalHeader', 'Remove scheduled pay change(s)', {
          count: erroredPayChanges.length
        })}
      </Modal.Header>
      <Modal.Body className='space-y-3'>
        <SubHeader employeeFirstName={employeeFirstName} />
        <ModalAlert
          count={erroredPayChanges.length}
          employeeFirstName={employeeFirstName}
        />
        <PayChangesTable
          testId={`${testId}-table`}
          rows={rows}
          prepareRow={prepareRow}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className='flex flex-grow flex-col gap-y-4'>
          <RemoveErroredPayChangesButton
            count={erroredPayChanges.length}
            onClick={onClick}
            saving={saving}
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}

const SubHeader: React.FunctionComponent<{
  employeeFirstName: string | null
}> = ({ employeeFirstName }) => {
  const { t } = useTranslation('scheduled-pay-changes')
  return (
    <div className='pb-3'>
      {t(
        'erroredModalSubHeader',
        '{{firstName}} will continue making their current pay',
        {
          firstName:
            employeeFirstName ??
            i18next.format(t('yourEmployee'), i18Formats.CAPITALIZE)
        }
      )}
    </div>
  )
}

const ModalAlert: React.FunctionComponent<{
  count: number
  employeeFirstName: string
}> = ({ count, employeeFirstName }) => {
  const { t } = useTranslation('scheduled-pay-changes')
  return (
    <Alert
      title={t(
        'erroredModalAlertTitle',
        'Pay changes are no longer applicable',
        { count: count }
      )}
      variant='error'
    >
      {t(
        'erroredModalAlertContent',
        `{{firstName}}'s scheduled pay changes can no longer be applied due to recent changes in their jobs settings`,
        {
          count: count,
          firstName:
            employeeFirstName ??
            i18next.format(t('yourEmployee'), i18Formats.CAPITALIZE)
        }
      )}
    </Alert>
  )
}

const RemoveErroredPayChangesButton: React.FunctionComponent<{
  count: number
  onClick: VoidFunction
  saving: boolean
}> = ({ count, onClick, saving }) => {
  const { t } = useTranslation('scheduled-pay-changes')

  return (
    <Button
      {...trackErroredPayChange('acknowledge')}
      className='flex-grow'
      disabled={saving}
      onClick={onClick}
      size='lg'
      variant='destructive'
    >
      {t('erroredModalButton', 'Remove scheduled pay change(s)', {
        count: count
      })}
    </Button>
  )
}

const useOnClick: (
  erroredPayChanges: PayChangeCardProps[],
  updatePendingPayChangeStatuses: any,
  onClose: VoidFunction,
  onErrorAcknowledge: VoidFunction
) => VoidFunction = (
  erroredPayChanges,
  updatePendingPayChangeStatuses,
  onClose,
  onErrorAcknowledge
) => {
  const { t } = useTranslation('scheduled-pay-changes')
  const employeeId = useEmployeeId()
  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()
  return () => {
    updatePendingPayChangeStatuses({
      variables: {
        employeeId: employeeId,
        scheduledPayChangeStatusInput: erroredPayChanges.map(
          (erroredPayChange) => {
            return {
              pendingPayChangeId: erroredPayChange.id,
              pendingPayChangeStatus: PendingPayChangeStatus.ERROR_ACKNOWLEDGED
            }
          }
        )
      },
      onCompleted: (mutation: UpdateScheduledPayChangeStatusesMutation) => {
        if (
          mutation.updatePendingPayChangeStatuses?.__typename ===
          'PendingPayChangeStatusResponse'
        ) {
          showSuccessSnackBar(
            t(
              'erroredPayChangeAcknowledged',
              'You have successfully removed the failed pay change(s)',
              {
                count: erroredPayChanges.length
              }
            )
          )
          onErrorAcknowledge()
        } else {
          showErrorSnackBar(
            t(
              'erroredPayChangeAcknowledgeError',
              "We couldn't remove the failed pay change(s). Please try again.",
              {
                count: erroredPayChanges.length
              }
            )
          )
          onClose()
        }
      },
      onError: (_error: any) => {
        showErrorSnackBar(
          t(
            'erroredPayChangeAcknowledgeError',
            "We couldn't remove the failed pay change(s). Please try again.",
            {
              count: erroredPayChanges.length
            }
          )
        )
        onClose()
      }
    })
  }
}

const useErroredPayChanges: (
  jobAssignments: ErroredPayChangesJobAssignmentFragment[],
  salary?: ErroredPayChangesSalaryFragment | null
) => PayChangeCardProps[] = (jobAssignments, salary) => {
  const { t } = useTranslation([
    'scheduled-pay-changes',
    'pay-change',
    'common'
  ])

  let erroredPayChanges: PayChangeCardProps[] = jobAssignments.map(
    (jobAssignment) => {
      return {
        id: jobAssignment.pendingPayChange?.id || jobAssignment?.id,
        jobProps: {
          name:
            jobAssignment.name ??
            t('erroredJobDeleted', 'Job name and location not available'),
          locationName: jobAssignment.locationName
        },
        oldRateProps: {
          label: t('oldPay', 'Old pay'),
          rate: jobAssignment.activeHourlyRateOfPay,
          interval: PayInterval.HOUR
        },
        newRateProps: {
          label: t('newPay', 'New pay'),
          rate: jobAssignment.pendingPayChange?.rate ?? null,
          interval: PayInterval.HOUR
        },
        effectiveDateProps: {
          label: t('effectiveDate', 'Effective date', { ns: 'pay-change' }),
          effectiveDate: jobAssignment.pendingPayChange?.effectiveDate
        },
        t: t
      }
    }
  )

  if (!!salary) {
    erroredPayChanges.unshift({
      id: salary?.pendingPayChange?.id || salary?.id,
      jobProps: {
        name:
          !salary.salaryAllocations || salary.salaryAllocations.length === 0
            ? t('erroredJobDeleted', 'Job name and location not available')
            : salary.salaryAllocations.length === 1
            ? salary.salaryAllocations[0].jobAssignment.name
            : t('salaryUpdate', 'Salary update'),
        locationName:
          !salary.salaryAllocations || salary.salaryAllocations.length === 0
            ? undefined
            : salary.salaryAllocations.length === 1
            ? salary.salaryAllocations[0].jobAssignment.locationName
            : t('appliesToMultiple', 'Applies to multiple jobs')
      },
      oldRateProps: {
        label: t('oldPay', 'Old pay'),
        rate: salary.activeSalaryRateOfPay,
        interval: PayInterval.ANNUAL
      },
      newRateProps: {
        label: t('newPay', 'New pay'),
        rate: salary.pendingPayChange?.rate ?? null,
        interval: PayInterval.ANNUAL
      },
      effectiveDateProps: {
        label: t('effectiveDate', 'Effective date'),
        effectiveDate: salary.pendingPayChange?.effectiveDate
      },
      t: t
    })
  }

  return erroredPayChanges
}
