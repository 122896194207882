import * as yup from 'yup'
import { PersonalProfileOptions } from '../domain/PersonalProfile'
import { Option } from '../../../domain/Option'

export class PersonalProfileSettingsLinks {
  static schema = yup
    .object({
      personalProfile: yup.string().required()
    })
    .defined()

  static of(obj: any) {
    return <PersonalProfileSettingsLinks>(
      PersonalProfileSettingsLinks.schema.validateSync(obj, {
        abortEarly: false
      })
    )
  }

  constructor(readonly personalProfile: string) {}
}

export class PersonalProfileSettingsOptions {
  static optionsSchema = yup.array(Option.schema).defined()

  static schema = yup
    .object({
      workflowGroups: PersonalProfileOptions.optionsSchema,
      dailyOvertimeThreshold: PersonalProfileOptions.optionsSchema,
      employmentStatus: PersonalProfileOptions.optionsSchema
    })
    .defined()

  static of(obj: any) {
    return <PersonalProfileOptions>(
      PersonalProfileOptions.schema.validateSync(obj, { abortEarly: false })
    )
  }

  constructor(
    readonly workflowGroups: Array<Option>,
    readonly dailyOvertimeThreshold: Array<Option>,
    readonly employmentStatus: Array<Option>
  ) {}
}

export class PersonalProfileSettingsValues {
  static schema = yup
    .object({
      employmentStatus: yup.string().defined(),
      spokewareId: yup.string().ensure().stripToNull().defined().nullable(),
      workflowGroups: yup.array(yup.string().defined()).defined(),
      dailyOvertimeThreshold: yup.string().defined()
    })
    .defined()

  static of(obj: any): PersonalProfileSettingsValues {
    return PersonalProfileSettingsValues.copyOf(
      PersonalProfileSettingsValues.schema.validateSync(obj, {
        abortEarly: false
      })
    )
  }

  static copyOf(
    valid: PersonalProfileSettingsValues
  ): PersonalProfileSettingsValues {
    return new PersonalProfileSettingsValues(
      valid.employmentStatus,
      valid.spokewareId,
      valid.workflowGroups,
      valid.dailyOvertimeThreshold
    )
  }

  private constructor(
    readonly employmentStatus: string,
    readonly spokewareId: string | null,
    readonly workflowGroups: Array<string>,
    readonly dailyOvertimeThreshold: string
  ) {}
}

export class PersonalProfileSettings {
  static schema = yup
    .object({
      values: PersonalProfileSettingsValues.schema,
      options: PersonalProfileSettingsOptions.schema,
      links: PersonalProfileSettingsLinks.schema
    })
    .defined()

  static of(obj: any): PersonalProfileSettings {
    return PersonalProfileSettings.copyOf(
      PersonalProfileSettings.schema.validateSync(obj, { abortEarly: false })
    )
  }

  static copyOf(valid: PersonalProfileSettings): PersonalProfileSettings {
    return new PersonalProfileSettings(valid.values, valid.options, valid.links)
  }

  private constructor(
    readonly values: PersonalProfileSettingsValues,
    readonly options: PersonalProfileSettingsOptions,
    readonly links: PersonalProfileSettingsLinks
  ) {}
}

export class PhoneNumber {
  // TODO: ECHR-1544 Use yup
  constructor(readonly phoneNumber: string) {}
}
