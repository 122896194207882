import { useTranslation } from 'react-i18next'
import { getClockInDateText } from './utils'

export const useGetClockInDateText = (clockInDate: Date | null) => {
  const { t } = useTranslation('employees')

  return getClockInDateText(
    clockInDate,
    t('noClockInPastMonth'),
    t('clockInPastDay'),
    (daysSinceClockIn) => t('clockInPastNDays', { daysSinceClockIn })
  )
}
