import * as React from 'react'
import Heading from './heading'
import { useTranslation } from 'react-i18next'
import { Job } from '../models/job'
import { DataTable } from '@toasttab/buffet-datatable'

const FormatCurrency = (value: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(value)

type PayRatesSectionProps = {
  jobs: Job[]
}

export const PayRatesSection = ({ jobs }: PayRatesSectionProps) => {
  const { t } = useTranslation('employees')

  const payRatesTableSchema = {
    locationName: {
      label: t('location')
    },
    jobName: {
      label: t('job')
    },
    hourlyRate: {
      label: t('rateOfPay'),
      renderCol(value: string, row: Job) {
        try {
          const rate = parseFloat(value)

          if (rate === 0) {
            return (
              <span>
                {FormatCurrency(row.salary)}/{t('yearAbbr')}
              </span>
            )
          } else {
            return (
              <span>
                {FormatCurrency(rate)}/{t('hourAbbr')}
              </span>
            )
          }
        } catch (ex) {
          return (
            <span>
              {value}/{t('hourAbbr')}
            </span>
          )
        }
      }
    }
  }

  return (
    <Heading
      title={t('payRates')}
      caption={t('mostRecentPayRateAtTimeOfEmployment')}
    >
      <div className='w-full -mt-3'>
        <DataTable data={jobs} hover={false} schema={payRatesTableSchema} />
      </div>
    </Heading>
  )
}
