import * as yup from 'yup'
import { isoDate } from '../../../shared/yupHelpers'
import { DateTime } from 'luxon'
import {
  Address,
  formDate,
  i9RequiredDocs,
  Preparer,
  SOCIAL_SECURITY_REGEX,
  VALID_SSN_MSG
} from '../domain/TaxForms'
import { format } from '@toasttab/buffet-pui-date-utilities'

/*
  This file represents domain models the I9 AFTER to the updates required by the US Govt in Autumn 2023
 */
export const WorkAuthTypes2023 = {
  us_citizen: {
    label: '1. A citizen of the United States',
    value: 'us_citizen'
  },
  non_citizen_national: {
    label:
      '2. A noncitizen national of the United States <a href="https://www.uscis.gov/sites/default/files/document/forms/i-9instr.pdf">(See Instructions)</a>',
    value: 'noncitizen_national'
  },
  lawful_permanent_res: {
    label: '3. A lawful permanent resident (Enter USCIS or A-Number.)',
    value: 'lawful_permanent_resident'
  },
  alien_auth_to_work: {
    label:
      '4. A noncitizen (other than Item Numbers 2. and 3. above) authorized to work until (exp. date, if any)',
    value: 'alien_authorized'
  }
}

export class I92023Form {
  // this schema is used for API responses like when we GET the form for edit or read-only
  // We don't want to do much validation here in case the database has invalid data
  static schema = yup
    .object({
      // required fields
      isUsingEverify: yup.boolean(),
      firstName: yup.string(),
      lastName: yup.string(),
      address: Address.schema,
      dateOfBirth: isoDate().transform(
        (date: DateTime): string | null =>
          date && format(new Date(date.toLocaleString()), 'MM/dd/yyyy/')
      ),
      employeeSignatureBytes: yup.string(),
      employeeSignatureUrl: yup.string(),
      preparerCertification: yup.string(),
      preparerCount: yup.string(),
      requiredDocs: i9RequiredDocs.schema,
      dateSigned: isoDate().transform(
        (date: DateTime): string | null =>
          date && format(new Date(date.toLocaleString()), 'MM/dd/yyyy/')
      ),

      //optional fields
      middleInitial: yup.string(),
      otherLastNames: yup.string(),
      email: yup.string(),
      telephoneNumber: yup.string(),

      socialSecurityNumber: yup.string(),
      preparers: yup.array(Preparer.schema)
    })
    .defined()

  // this schema is used when the employee clicks "save"
  // We want to do a full validation here to avoid saving invalid data
  static commonFormSchema = yup
    .object({
      // required fields
      firstName: yup.string().required('First Name is required'),
      lastName: yup.string().required('Last Name is required'),
      address: Address.commonFormSchema.required(),
      dateOfBirth: formDate()
        .test(
          'pastDate',
          'Date of birth must be in the past',
          function (date: DateTime): date is DateTime {
            return date ? date.diffNow('days').days < 0 : true
          }
        )
        .required('Date of birth is required'),
      employeeSignatureBytes: yup.string().when('employeeSignatureUrl', {
        is: (val) => val,
        then: yup.string().nullable(),
        otherwise: yup.string().required('Please sign your name above')
      }),
      preparerCertification: yup
        .string()
        .required('Please select an option above')
        .oneOf(['no_preparer', 'used_preparer']),
      preparerCount: yup.string().required('Please indicate a preparer count'),
      requiredDocs: i9RequiredDocs.formSchema.required(),

      //optional fields
      middleInitial: yup.string(),
      otherLastNames: yup.string(),
      email: yup
        .string()
        .notRequired()
        .email('Please provide a valid email or leave it blank'),
      telephoneNumber: yup
        .string()
        .phone('Please provide a valid phone number or leave it blank'),
      socialSecurityNumber: yup
        .string()
        .stripToNull()
        .nullable()
        .when('isUsingEverify', {
          is: (val) => val === true,
          then: yup.string().required('Social Security Number is required'),
          otherwise: yup.string().nullable()
        })
        .matches(SOCIAL_SECURITY_REGEX, VALID_SSN_MSG),
      preparers: yup.array(Preparer.formSchema)
    })
    .defined()

  constructor(
    //required fields
    readonly isUsingEverify: boolean = false,
    readonly firstName: string | null,
    readonly lastName: string | null,
    readonly address: Address,
    readonly dateOfBirth: DateTime | null,
    readonly employeeSignatureBytes: string | '',
    readonly employeeSignatureUrl: string | '',
    readonly dateSigned: DateTime | null,
    readonly preparerCertification: string,
    readonly preparerCount: string,
    readonly preparers: Array<Preparer>,
    readonly requiredDocs: i9RequiredDocs,

    //optional fields
    readonly middleInitial: string | null,
    readonly otherLastNames: string | null,
    readonly email: string | null,
    readonly telephoneNumber: string | null,
    readonly socialSecurityNumber: string | null
  ) {}
}
