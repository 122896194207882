import * as yup from 'yup'
import { Objects } from '@local/shared-services'
import { ISO8859_1_SINGLE_BYTE_REGEX } from '../../../utils'

export class PostalAddress {
  static schema = yup
    .object({
      address: yup
        .string()
        .required('Street address is required')
        .max(255, 'Street address must be 255 characters or less'),
      address2: yup.string().max(255),
      locality: yup
        .string()
        .required('City is required')
        .max(255, 'City must be 255 characters or less'),
      administrativeArea: yup
        .string()
        .required('State is required')
        .max(255, 'State must be 255 characters or less'),
      postalCode: yup.string().required('Zip is required'),
      country: yup.string().required('Country is required'),
      localityGnisCode: yup.string().optional().nullable()
    })
    .defined()

  static formSchema = PostalAddress.schema.clone().shape({
    postalCode: yup
      .string()
      .required('Zip is required')
      .matches(/^[0-9]{5}$/, 'Zip must be 5 digits')
  })

  static homeAddressformSchema = PostalAddress.formSchema.clone().shape({
    address: yup
      .string()
      .nullable()
      .required('Street address is required')
      .matches(
        ISO8859_1_SINGLE_BYTE_REGEX,
        'Address cannot include emojis or special symbols'
      )
      .max(255, 'Street address must be 255 characters or less'),
    address2: yup
      .string()
      .optional()
      .matches(ISO8859_1_SINGLE_BYTE_REGEX, {
        message: 'Address cannot include emojis or special symbols',
        excludeEmptyString: true
      })
      .max(255),
    locality: yup
      .string()
      .required('City is required')
      .matches(
        ISO8859_1_SINGLE_BYTE_REGEX,
        'City cannot include emojis or special symbols'
      )
      .max(255, 'City must be 255 characters or less'),
    localityGnisCode: yup
      .string()
      .nullable()
      .required('City & Locality is required to ensure proper taxation')
  })

  static of(obj: any): PostalAddress {
    return PostalAddress.copyOf(
      PostalAddress.schema.validateSync(obj, { abortEarly: false })
    )
  }

  static copyOf(
    valid: Omit<PostalAddress, 'equals' | 'hashCode'>
  ): PostalAddress {
    return new PostalAddress(
      valid.address,
      valid.address2,
      valid.locality,
      valid.administrativeArea,
      valid.postalCode,
      valid.country,
      valid.localityGnisCode
    )
  }

  constructor(
    readonly address: string,
    readonly address2: string | undefined,
    readonly locality: string,
    readonly administrativeArea: string,
    readonly postalCode: string,
    readonly country: string,
    readonly localityGnisCode?: string | null
  ) {}

  public equals(other: any): boolean {
    if (other === null) {
      return false
    } else if (this === other) {
      return true
    } else if (other instanceof PostalAddress) {
      const otherAddress = <PostalAddress>other
      return (
        this.address === otherAddress.address &&
        (this.address2 ? this.address2 === otherAddress.address2 : true) &&
        this.locality === otherAddress.locality &&
        this.administrativeArea === otherAddress.administrativeArea &&
        this.postalCode === otherAddress.postalCode &&
        this.country === otherAddress.country &&
        this.localityGnisCode === otherAddress.localityGnisCode
      )
    } else {
      return false
    }
  }

  public hashCode(): number {
    let result = 17
    result = 31 * result + Objects.hashString(this.address)
    result = this.address2
      ? 31 * result + Objects.hashString(this.address2)
      : result
    result = 31 * result + Objects.hashString(this.locality)
    result = 31 * result + Objects.hashString(this.administrativeArea)
    result = 31 * result + Objects.hashString(this.postalCode)
    result = 31 * result + Objects.hashString(this.country)
    result = 31 * result + Objects.hashString(this.localityGnisCode || '')
    return result
  }
}
