import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { useQueryErrorResetBoundary } from 'react-query'
import { useTranslation } from 'react-i18next'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { TabletTeamIllustration } from '@toasttab/buffet-pui-illustrations'
import {
  GeneralErrorBoundary,
  GeneralErrorBoundaryContext
} from '@local/ec-app'

import { useSentryAppExceptionLogger } from '@local/ec-app'
import { useNewHireNextStepLink } from '../employee/hooks'
import { ERROR_STATE } from './ErrorStates'

export const EmployeeError = ({ reset }: { reset: () => void }) => {
  const { pathname } = useLocation()
  const { t } = useTranslation('employees')
  const lastPathnameRef = React.useRef(pathname)

  // When navigating away from the current path, we want
  // to reset the error boundary so it will render the page
  // the user navigated to.
  React.useEffect(() => {
    if (lastPathnameRef.current !== pathname) {
      reset()
    } else {
      lastPathnameRef.current = pathname
    }
  }, [pathname, lastPathnameRef, reset])

  return (
    <div className='flex-grow pb-12'>
      <Alert variant='error' className='w-full'>
        <div className='flex items-center justify-between flex-col md:flex-row'>
          {t('somethingWentWrong')}
          <Button
            className='w-full md:w-max mt-4 md:mt-0'
            variant='secondary'
            onClick={reset}
          >
            {t('tryAgain')}
          </Button>
        </div>
      </Alert>
    </div>
  )
}

export const EmployeeOnboardingError = ({ reset }: { reset: () => void }) => {
  const { pathname } = useLocation()
  const { t } = useTranslation('employees')
  const { data: nextStepLink } = useNewHireNextStepLink()
  const lastPathnameRef = React.useRef(pathname)
  const legacyMainPanel = document.getElementById('mainPanel')

  if (legacyMainPanel) {
    legacyMainPanel.style.display = 'none'
  }

  React.useEffect(() => {
    if (lastPathnameRef.current !== pathname) {
      reset()
    } else {
      lastPathnameRef.current = pathname
    }
  }, [pathname, lastPathnameRef, reset])

  return (
    <div className='inline-flex flex-col items-center space-y-4'>
      <TabletTeamIllustration />
      <div className='type-headline-3'>{t('tellUsMoreAboutYourEmployee')}</div>
      <div className='type-default text-secondary'>
        {t('onboardingInfoNeeded')}
      </div>
      <div>
        <a
          href={nextStepLink}
          aria-label="navigate to your employee's last new hire steps"
        >
          <Button
            iconRight={<ArrowForwardIcon />}
            className='w-full md:w-auto'
            size='auto'
          >
            {t('completeYourEmployeesProfile')}
          </Button>
        </a>
      </div>
    </div>
  )
}

/**
 * Error boundary that wraps the employee application.
 * This render an error message on the page. This error resets on
 * navigation to another page.
 */
export const EmployeeErrorBoundary = ({
  children
}: React.PropsWithChildren<{}>) => {
  const logException = useSentryAppExceptionLogger((scope) =>
    scope.setExtra('isEcSpaRenderingError', true)
  )
  const { reset } = useQueryErrorResetBoundary()

  const handler = (_error: Error, context: GeneralErrorBoundaryContext) => {
    const onboardingError = [
      ERROR_STATE.PAYMENT_METHOD_ERROR,
      ERROR_STATE.USER_NOT_FOUND_ERROR,
      ERROR_STATE.USER_INFO_NEEDED_ERROR
    ]
    const resetHandler = () => {
      reset()
      context.reset()
    }

    return {
      render: () =>
        onboardingError.some((value) => _error.message.includes(value)) ? (
          <EmployeeOnboardingError reset={resetHandler} />
        ) : (
          <EmployeeError reset={resetHandler} />
        )
    }
  }

  return (
    <GeneralErrorBoundary handler={handler} onError={logException}>
      {children}
    </GeneralErrorBoundary>
  )
}
