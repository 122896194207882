import * as yup from 'yup'

export enum AccountCommandType {
  CHANGE_PASSWORD,
  CHANGE_DETAILS
}

export class ChangePassword {
  readonly password!: string

  static schema = yup
    .object({
      password: yup.string().required()
    })
    .defined()
}

export class User {
  constructor(
    readonly id: string,
    readonly username: string,
    readonly email: string | null,
    readonly isEmailVerified: boolean,
    readonly toastIdentityGuid?: string | null
  ) {}

  static schema = yup
    .object({
      id: yup.string().required(),
      username: yup.string().required(),
      email: yup
        .string()
        .stripToNull()
        .nullable()
        .email()
        .default(null)
        .defined(),
      isEmailVerified: yup.boolean().required(),
      toastIdentityGuid: yup.string().nullable()
    })
    .defined()

  static copyOf(
    obj: Omit<User, 'isToastIdentityLinked' | 'showUsername'>
  ): User {
    return new User(
      obj.id,
      obj.username,
      obj.email,
      obj.isEmailVerified,
      obj.toastIdentityGuid
    )
  }

  static of(obj: any): User {
    return User.copyOf(User.schema.validateSync(obj, { abortEarly: false }))
  }

  static isUserMissingCriticalInfo(user: User | null): boolean {
    return !user?.username || !user?.email
  }

  showUsername(): boolean {
    return !this.isToastIdentityLinked() && this.username !== this.email
  }

  isToastIdentityLinked(): boolean {
    return this.toastIdentityGuid != null
  }
}

export class ChangeDetails {
  readonly userUuid!: string
  readonly username!: string
  readonly email!: string

  static schema = yup
    .object({
      userUuid: yup.string().required(),
      username: yup.string().nullable(),
      email: yup.string().email().required()
    })
    .defined()
}

export type AccountCommand = ChangePassword | ChangeDetails
