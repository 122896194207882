import * as React from 'react'
import Skeleton from 'react-loading-skeleton'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

import { AppHeaderTitle } from './AppHeaderTitle'
import { AppHeaderTabs } from './AppHeaderTabs'
import { MobileNavigationNodeSection } from './NavigationNodeSection'

export const AppHeader = ({ testId = 'page-header' }: TestIdentifiable) => (
  <>
    <div
      className='relative flex flex-col px-4 pt-6 pb-4 md:p-0 md:pb-6 md:pt-6 no-print'
      data-testid={testId}
    >
      <React.Suspense fallback={<Skeleton height={56} />}>
        <AppHeaderTitle />
      </React.Suspense>
      <React.Suspense fallback={<Skeleton height={53} />}>
        <AppHeaderTabs />
      </React.Suspense>
    </div>
    <div className='pb-6 nav__mobile no-print'>
      <MobileNavigationNodeSection />
    </div>
  </>
)
