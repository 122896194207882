import React from 'react'
import { STATE_OPTIONS } from '../shared/states'
import { SignaturePad } from './SignaturePad'
import { Preparer, Address } from '../domain/TaxForms'
import {
  SelectFormField,
  TextInputFormField
} from '../../profile/personal/components'
import { FormikErrors } from 'formik'

type PreparerProps = {
  preparer: Preparer
  setPreparer: (preparer: Preparer) => void
  index: number
  errors: FormikErrors<Preparer>
  disabled: boolean | undefined
  hasMiddleInitial: boolean | undefined
}

export const PreparerSection = (props: PreparerProps) => {
  const { preparer: p, setPreparer, index, errors } = props

  const setFirstName = (val: string) => setPreparer({ ...p, firstName: val })
  const setLastName = (val: string) => setPreparer({ ...p, lastName: val })
  const setMiddleInitial = (val: string) =>
    setPreparer({ ...p, middleInitial: val })
  const setSignature = (val: string) =>
    setPreparer({ ...p, preparerSignatureBytes: val })

  const setAddress = (val: string) =>
    setPreparer({
      ...p,
      address: new Address(
        val,
        p.address?.address2,
        p.address?.locality,
        p.address?.administrativeArea,
        p.address?.postalCode
      )
    })

  const setLocality = (val: string) =>
    setPreparer({
      ...p,
      address: new Address(
        p.address?.address,
        p.address?.address2,
        val,
        p.address?.administrativeArea,
        p.address?.postalCode
      )
    })

  const setZipCode = (val: string) =>
    setPreparer({
      ...p,
      address: new Address(
        p.address?.address,
        p.address?.address2,
        p.address?.locality,
        p.address?.administrativeArea,
        val
      )
    })

  const setState = (val: string) =>
    setPreparer({
      ...p,
      address: new Address(
        p.address?.address,
        p.address?.address2,
        p.address?.locality,
        val,
        p.address?.postalCode
      )
    })

  return (
    <div>
      <div
        className='mb-8 font-semibold type-headline-5'
        id={`preparer-section-title-${index + 1}`}
      >
        {`Preparer/Translator ${index + 1}`}
      </div>
      <div className='mb-4 text-normal font-semibold'>
        I attest, under penalty of perjury, that I have assisted in the
        completion of Section 1 of this form and that to the best of my
        knowledge the information is true and correct.
      </div>
      <label className='text-secondary'>
        Signature of Preparer or Translator
      </label>
      <div>
        {props.disabled ? (
          <div className={'p-3'}>
            <img
              src={props.preparer.preparerSignatureUrl}
              id={`preparer-signature-${index}`}
              alt='Preparer signature'
            />
          </div>
        ) : (
          <SignaturePad
            onUpdateSignature={(sigBytes) => {
              setSignature(sigBytes.substring(sigBytes.indexOf(',') + 1))
            }}
          />
        )}
      </div>
      {errors?.preparerSignatureBytes && (
        <div className='pt-2 mb-6 text-error type-caption'>
          Please have your translator sign their name above
        </div>
      )}
      <div className='mb-12 text-secondary'>
        {props.disabled ? (
          <div id={`preparer-date-signed-${index}`}>
            {`Signed on ${props.preparer.dateSigned}`}
          </div>
        ) : (
          <>Today's Date {new Date().toLocaleDateString()}</>
        )}
      </div>

      <TextInputFormField
        label='Last Name (Family Name)'
        value={p.lastName}
        onChange={(value) => setLastName(value)}
        errorMessage={errors?.lastName}
        testId={`p-${index}-last-name`}
        disabled={props.disabled}
      />

      <TextInputFormField
        label='First Name (Given Name)'
        value={p.firstName}
        onChange={(value) => setFirstName(value)}
        errorMessage={errors?.firstName}
        testId={`p-${index}-first-name`}
        disabled={props.disabled}
      />

      {props.hasMiddleInitial ? (
        <TextInputFormField
          label='Middle Initial (if any)'
          value={p.middleInitial}
          onChange={(value) => setMiddleInitial(value)}
          errorMessage={errors?.middleInitial}
          testId={`p-${index}-middle-initial`}
          disabled={props.disabled}
        />
      ) : null}

      <TextInputFormField
        label='Address (Street Number and Name)'
        value={p.address?.address}
        onChange={(value) => setAddress(value)}
        errorMessage={errors?.address?.address}
        testId={`p-${index}-address`}
        disabled={props.disabled}
      />

      <TextInputFormField
        label='City or Town'
        value={p.address?.locality}
        onChange={(value) => setLocality(value)}
        errorMessage={errors?.address?.locality}
        testId={`p-${index}-locality`}
        disabled={props.disabled}
      />

      <SelectFormField
        label='State'
        options={STATE_OPTIONS}
        selectedVal={p.address?.administrativeArea}
        onChange={(value) => setState(value)}
        errorText={errors?.address?.administrativeArea}
        id={`p-${index}-state`}
        disabled={props.disabled}
      />

      <TextInputFormField
        label='ZIP Code'
        value={p.address?.postalCode}
        onChange={(value) => setZipCode(value)}
        errorMessage={errors?.address?.postalCode}
        testId={`p-${index}-zip`}
        disabled={props.disabled}
      />
    </div>
  )
}
