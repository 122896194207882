import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useFeature } from '@toasttab/ec-features'
import { RehireEmployeeModal } from '@toasttab/ec-employee-management'
import { trackEmploymentOverview } from '@local/track'
import {
  HumanResourcesPermission,
  useGetEmploymentStatusDetailsQuery
} from '@local/generated/graphql'
import {
  EmploymentStatusDetails,
  EmploymentStatusLoadingState
} from './components'
import { useEmployee, useEmployeeId } from '../../../hooks'
import { useHasPermission, useStatusChange } from '../hooks'
import { useNavigateTo } from '../../../../navigation/hooks'
import { Overview } from '../../../domain'
import { OverviewCardBoundary, StatusOverviewCard } from '../components'
import { RehireDialogueController } from '../rehireDialogue/RehireDialogueController'

export const EmploymentStatusCard = () => {
  const { t } = useTranslation('employees')
  const { overview } = useEmployee()
  const isRehireFeatureEnabled = useFeature('ec-elm-add-employee')
  const employeeId = useEmployeeId()
  const {
    loading,
    data,
    refetch: employmentStatusDetailsRefetch
  } = useGetEmploymentStatusDetailsQuery({
    variables: { employeeId }
  })

  const [isRehireDialogueOpen, setIsRehireDialogueOpen] =
    React.useState<boolean>(false)

  const [fullRehireModalOpen, setFullRehireModalOpen] =
    React.useState<boolean>(false)

  const [fullRehireDate, setFullRehireDate] = React.useState<string>(
    Date.now().toString()
  )

  const employmentStatus = data?.findEmployeeById?.employment?.employmentStatus
  const isTerminated =
    employmentStatus?.__typename === 'TerminatedEmploymentStatus'

  const iconType = isTerminated ? (
    <Button
      testId='rehire-btn'
      iconLeft={<AutorenewIcon accessibility='decorative' />}
      variant='link'
    >
      {t('rehire')}
    </Button>
  ) : undefined

  const onActionClick = useActionClick(isTerminated, () =>
    setIsRehireDialogueOpen(true)
  )

  const onStatusActionClick = useNavigateToLegacyStatusChangePage()

  return (
    <div data-testid='employment-status-card' className='grid'>
      <OverviewCardBoundary
        cardTitle={t('status')}
        errorText={t('cardErrorText', {
          fullName: Overview.getName(overview),
          details: t('employmentStatus')
        })}
        loadingView={<EmploymentStatusLoadingState />}
        onActionClick={onActionClick}
      >
        <StatusOverviewCard
          {...trackEmploymentOverview('status-edit')}
          cardTitle={t('status')}
          terminated={isTerminated}
          iconType={iconType}
          onAbort={onActionClick}
          onRehireActionClick={onActionClick}
          onStatusActionClick={onStatusActionClick}
        >
          {loading ? (
            <EmploymentStatusLoadingState />
          ) : (
            <EmploymentStatusDetails
              employmentStatus={employmentStatus}
              mostRecentPaycheck={
                data?.findEmployeeById?.payHistory?.mostRecentPaycheck
              }
              totalLengthOfService={
                data?.findEmployeeById?.employment?.totalLengthOfService
              }
            />
          )}
        </StatusOverviewCard>
        {isRehireFeatureEnabled && (
          <RehireEmployeeModal
            isOpen={fullRehireModalOpen}
            employeeId={employeeId}
            initialRehireDate={fullRehireDate}
            onCancel={() => setFullRehireModalOpen(false)}
            onSuccess={() => {
              setFullRehireModalOpen(false)
              employmentStatusDetailsRefetch()
            }}
          />
        )}
        {isRehireDialogueOpen && isRehireFeatureEnabled ? (
          <RehireDialogueController
            rehireDialogueOpen={isRehireDialogueOpen}
            firstName={overview.chosenName ?? overview.firstName}
            lastName={overview.lastName}
            employeeId={employeeId}
            onSubmit={(changes) => {
              if (changes.rehireChanges) {
                setFullRehireDate(changes.rehireDate)
                setFullRehireModalOpen(true)
              }
              setIsRehireDialogueOpen(false)
              employmentStatusDetailsRefetch()
            }}
            onClose={() => setIsRehireDialogueOpen(false)}
          />
        ) : null}
      </OverviewCardBoundary>
    </div>
  )
}

const useActionClick = (
  isTerminated: boolean,
  setIsRehireDialogueOpen: VoidFunction
) => {
  const isRehireFeatureEnabled = useFeature('ec-elm-add-employee')
  const userHasAccessToStatusChanges = useHasPermission(
    HumanResourcesPermission.EMPLOYMENT_STATUS_EDIT
  )
  const navigateTo = useNavigateTo()
  const { data: esxResponse } = useStatusChange()
  const navigateToLegacyStatusChangePage = () => {
    if (esxResponse?.url) {
      navigateTo(esxResponse.url, true)
    }
  }
  return userHasAccessToStatusChanges
    ? isRehireFeatureEnabled && isTerminated
      ? setIsRehireDialogueOpen
      : navigateToLegacyStatusChangePage
    : undefined
}

const useNavigateToLegacyStatusChangePage = () => {
  const userHasAccessToStatusChanges = useHasPermission(
    HumanResourcesPermission.EMPLOYMENT_STATUS_EDIT
  )
  const navigateTo = useNavigateTo()
  const { data: esxResponse } = useStatusChange()
  const navigateToLegacyStatusChangePage = () => {
    if (esxResponse?.url) {
      navigateTo(esxResponse.url, true)
    }
  }

  return userHasAccessToStatusChanges
    ? navigateToLegacyStatusChangePage
    : undefined
}
