import * as React from 'react'
import { CurrencyField } from '@toasttab/buffet-pui-forms'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { Currency, Money } from '@local/generated/graphql'
import { TrackId } from '@local/track'

export type SalaryAllocationInputProps = {
  /** data-toast-track-id for the rate input field*/
  'data-toast-track-id'?: TrackId
  name: string
  label: string
  rate: Money
  salaryCurrency: Currency
  unsupportedCurrencyMessage: string
} & TestIdentifiable

export const SalaryAllocationInput: React.FunctionComponent<
  SalaryAllocationInputProps
> = ({
  'data-toast-track-id': dataToastTrackId,
  testId,
  name,
  label,
  rate,
  salaryCurrency,
  unsupportedCurrencyMessage
}) => {
  return (
    <CurrencyField
      data-toast-track-id={dataToastTrackId}
      name={name}
      label={label}
      currency={rate.currency}
      decimalScale={2}
      thousandSeparator
      allowNegative={false}
      testId={testId}
      readOnly={salaryCurrency !== rate.currency}
      helperText={
        salaryCurrency !== rate.currency
          ? unsupportedCurrencyMessage
          : undefined
      }
    />
  )
}
