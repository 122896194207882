import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { TextInputFormField } from './TextInputFormField'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import { AsYouType } from 'libphonenumber-js'
import { FormContainer } from './FormContainer'
import { skipPhoneReformat } from '../shared/formHelpers'
import { EmergencyContact } from '../domain'
import { useEmergencyContactId } from '../../../hooks'
import {
  useAddContact,
  useEditContact,
  useEmergencyContact,
  useManageContacts,
  useNavigateToEmergencyContactsPage,
  useNavigateToProfilePage,
  usePersonalProfile,
  useUpdatePersonalProfile
} from '../hooks'
import { useApi } from '../../../../ApiProvider'

type Props = {
  action: 'AddFromProfile' | 'AddFromEmergencyContacts' | 'Edit'
}

export const EditEmergencyContactInfoForm = (props: Props) => {
  const { action } = props
  const navigateToProfilePage = useNavigateToProfilePage()
  const navigateToEmergencyContactsPage = useNavigateToEmergencyContactsPage()
  const onClose =
    action === 'AddFromEmergencyContacts'
      ? navigateToEmergencyContactsPage
      : navigateToProfilePage

  const onAddFromEmergencyContactsSubmit = useOnAddFromEmergencyContactsSubmit()
  const onAddFromProfileSubmit = useOnAddFromProfile()
  const onEditSubmit = useOnEditSubmit()

  const onSubmit =
    action === 'AddFromEmergencyContacts'
      ? onAddFromEmergencyContactsSubmit
      : action === 'Edit'
      ? onEditSubmit
      : onAddFromProfileSubmit

  const contactId = useEmergencyContactId()
  const { contactToEdit } = useManageContacts()
  const emergencyContact =
    useEmergencyContact(contactId ? parseInt(contactId, 10) : -1) ||
    contactToEdit

  const [showError, setShowError] = useState(false)

  const { values, setFieldValue, errors, handleSubmit } = useFormik({
    initialValues: emergencyContact || ({} as EmergencyContact),
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: EmergencyContact.schema,
    onSubmit: (contact) => onSubmit(EmergencyContact.of(contact), onClose),
    enableReinitialize: true
  })

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      window.scrollTo(0, 0)
      setShowError(true)
    } else {
      setShowError(false)
    }
  }, [errors])

  return (
    <FormContainer
      headerText={`${action === 'Edit' ? action : 'Add'} Emergency Contact`}
      error={showError ? 'Please fix the errors below' : null}
    >
      <TextInputFormField
        label='First name'
        value={values.firstName || ''}
        onChange={(value) => setFieldValue('firstName', value)}
        errorMessage={errors.firstName}
        testId='emergency-contact-firstname'
      />
      <TextInputFormField
        label='Last name'
        value={values.lastName || ''}
        onChange={(value) => setFieldValue('lastName', value)}
        errorMessage={errors.lastName}
        testId='emergency-contact-lastname'
      />
      <TextInputFormField
        label='Relationship'
        value={values.relation || ''}
        onChange={(value) => setFieldValue('relation', value)}
        errorMessage={errors.relation}
        testId='emergency-contact-relationship'
      />
      <TextInputFormField
        label='Phone number'
        value={values.primaryTelephone}
        onChange={(value) => {
          const skipFormat = skipPhoneReformat(values.primaryTelephone, value)
          setFieldValue(
            'primaryTelephone',
            skipFormat ? value : new AsYouType('US').input(value)
          )
        }}
        errorMessage={errors.primaryTelephone || ''}
        testId='emergency-contact-phonenumber'
      />
      <TextInputFormField
        label='Email'
        value={values.email || ''}
        onChange={(value) => setFieldValue('email', value)}
        errorMessage={errors.email}
        optional
        testId='emergency-contact-email'
      />
      <div className='mb-4 type-large'>
        <Checkbox
          label='Set as the primary emergency contact'
          checked={values.isPrimary}
          onChange={() => setFieldValue('isPrimary', !values.isPrimary)}
          testId='emergency-contact-set-primary-checkbox'
        />
      </div>
      <div className='flex justify-between'>
        <Button onClick={() => onClose()} variant='secondary'>
          Cancel
        </Button>
        <div className='float-right'>
          <Button
            onClick={() => handleSubmit()}
            testId='emergency-contact-save-btn'
          >
            Save changes
          </Button>
        </div>
      </div>
    </FormContainer>
  )
}

const useOnAddFromEmergencyContactsSubmit = (): ((
  emergencyContact: EmergencyContact,
  onClose: VoidFunction
) => void) => {
  const addContact = useAddContact()
  return (emergencyContact: EmergencyContact, onClose: VoidFunction) => {
    addContact(emergencyContact)
    onClose()
  }
}

const useOnEditSubmit = (): ((
  emergencyContact: EmergencyContact,
  onClose: VoidFunction
) => void) => {
  const editContact = useEditContact()
  return (emergencyContact: EmergencyContact, onClose: VoidFunction) => {
    editContact(emergencyContact)
    onClose()
  }
}

const useOnAddFromProfile = (): ((
  emergencyContact: EmergencyContact,
  onClose: VoidFunction
) => void) => {
  const api = useApi()
  const updatePersonalProfile = useUpdatePersonalProfile()
  const personalProfile = usePersonalProfile()

  const updatedEmergencyContacts = (
    emergencyContact: EmergencyContact,
    emergencyContacts: Array<EmergencyContact>
  ): Array<EmergencyContact> => {
    if (emergencyContact.isPrimary) {
      return [
        emergencyContact,
        ...emergencyContacts.map((c) => c.setPrimary(false))
      ]
    } else {
      // if a user submits a size 1-length contact list, default it to primary to
      if (emergencyContacts.length === 0) {
        emergencyContact = emergencyContact.setPrimary(true)
      }
      return [emergencyContact, ...emergencyContacts].sort((c) =>
        c.isPrimary ? 0 : 1
      )
    }
  }

  return async (contact: EmergencyContact, onClose: VoidFunction) => {
    const emergencyContacts = personalProfile?.emergencyContacts
    const links = personalProfile?.links

    if (emergencyContacts && links) {
      const updatedContacts = updatedEmergencyContacts(
        contact,
        emergencyContacts
      )

      await api.saveEmergencyContacts(links.emergencyContacts, updatedContacts)

      updatePersonalProfile({ emergencyContacts: updatedContacts })

      onClose()
    }
  }
}
