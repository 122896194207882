import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PosProfile } from './PosProfile'
import { Button } from '@toasttab/buffet-pui-buttons'
import { EditIcon } from '@toasttab/buffet-pui-icons'
import { EditMappingSection } from './EditMappingSection'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { MappingCondition, PersonName } from '@local/generated/graphql'
import { useHrClient } from '@local/api/ApolloClientsProvider'
import { useApplyMappingMutation } from '@local/generated/graphql'
import { useEmployee, useEmployeeId } from '../hooks'

interface Props {
  mappingStatus: MappingCondition
  payrollUserName: PersonName
  posUserName: PersonName | null
  email: string | null
  mostRecentClockInDate: Date | null
  locationUuid: string
  posStatus: string | null
}

export const PosProfileSection = ({
  mappingStatus,
  payrollUserName,
  posUserName,
  email,
  mostRecentClockInDate,
  locationUuid,
  posStatus
}: Props) => {
  const { t } = useTranslation('employees')
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  const [editingMapping, setEditingMapping] = useState(false)
  const client = useHrClient()
  const employeeId = useEmployeeId()
  const employeeIsLinked = useEmployee().overview.toastIdentityGuid != null

  const [applyMappingMutation] = useApplyMappingMutation({
    client,
    refetchQueries: 'active'
  })

  const applyMapping = (restaurantUserGuid: string | null) => {
    applyMappingMutation({
      variables: {
        restaurantUserId: restaurantUserGuid || '',
        payrollEmployeeId: employeeId,
        locationId: locationUuid
      }
    })
      .then(() => {
        showSuccessSnackBar(
          t(
            // 'Ignored' users have a null restaurantUserGuid
            restaurantUserGuid == null
              ? 'mappingIgnoredSuccess'
              : 'mappingChangeSuccess',
            { name: payrollUserName.formatted }
          )
        )
        setEditingMapping(false)
      })
      .catch(() => showErrorSnackBar(t('mappingChangeFailure')))
  }

  return (
    <div
      className='flex items-center justify-between'
      data-testid='pos-profile-section'
    >
      {editingMapping ? (
        <EditMappingSection
          applyMapping={applyMapping}
          setEditingMapping={setEditingMapping}
          locationUuid={locationUuid}
        />
      ) : (
        <>
          <PosProfile
            mappingStatus={mappingStatus}
            userName={posUserName}
            email={email}
            mostRecentClockInDate={mostRecentClockInDate}
            employeeIsArchived={posStatus === 'Archived'}
          />
          {!employeeIsLinked && (
            // Linked users are "locked in"
            <Button
              testId='edit-mapping-button'
              iconLeft={<EditIcon accessibility='decorative' />}
              onClick={() => setEditingMapping(true)}
              variant='link'
            >
              {t('edit')}
            </Button>
          )}
        </>
      )}
    </div>
  )
}
