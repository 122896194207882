import * as React from 'react'
import cx from 'classnames'
import Skeleton from 'react-loading-skeleton'
import { useIsEmployeeProfileNavEnabled } from '../../../hooks'

const NON_BREAKING_SPACE = '\u00A0'

export type FieldProps = React.PropsWithChildren<{
  testId?: string
}>

export const Field = ({ children, testId }: FieldProps) => {
  const isEmployeeProfileNavEnabled = useIsEmployeeProfileNavEnabled()

  return (
    <div
      className={cx('w-full', 'sm:w-1/2', 'min-h-20', {
        'md:w-1/2 lg:w-1/2 xl:w-1/2x': isEmployeeProfileNavEnabled,
        'md:w-1/3 lg:w-1/3 xl:w-1/3x': !isEmployeeProfileNavEnabled
      })}
      data-testid={testId}
    >
      {children}
    </div>
  )
}
export const FieldName = ({ children }: FieldProps) => {
  const isEmployeeProfileNavEnabled = useIsEmployeeProfileNavEnabled()

  return (
    <div
      className={cx('mb-1 type-default', {
        'text-default font-semibold': !isEmployeeProfileNavEnabled,
        'text-secondary': isEmployeeProfileNavEnabled
      })}
    >
      {children}
    </div>
  )
}

export const FieldBlankValue = () => {
  const isEmployeeProfileNavEnabled = useIsEmployeeProfileNavEnabled()

  return isEmployeeProfileNavEnabled ? (
    <span className='text-disabled'>No answer</span>
  ) : null
}

export type FieldValueProps = React.PropsWithChildren<{}>

export const FieldValue = ({
  children = NON_BREAKING_SPACE
}: FieldValueProps) => {
  const isEmployeeProfileNavEnabled = useIsEmployeeProfileNavEnabled()
  // This only accounts for direct empty values... child components that
  // return null are NOT accounted for here.
  const hasEmptyValue =
    children === NON_BREAKING_SPACE || children == null || children === ''

  return (
    <div
      className={cx('py-2 type-default md:py-1 text-default', {
        'type-large mb-4': isEmployeeProfileNavEnabled,
        'mb-6': !isEmployeeProfileNavEnabled
      })}
    >
      {hasEmptyValue ? <FieldBlankValue /> : children}
    </div>
  )
}

export interface FieldSkeletonProps {
  repeat?: number
}

export const FieldSkeleton = ({ repeat = 1 }: FieldSkeletonProps) => (
  <div className='flex flex-wrap'>
    {Array.from({ length: repeat }, (v, i) => (
      <Field key={i}>
        <FieldName>
          <Skeleton height={10} width={100} />
        </FieldName>
        <FieldValue>
          <Skeleton height={15} width={80} />
        </FieldValue>
      </Field>
    ))}
  </div>
)
