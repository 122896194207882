import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CardSelectorGroupField } from '@toasttab/buffet-pui-forms'
import { trackPayChange } from '@local/track'
import { useShowRetroPayDisbursementOptions } from './utils'

type Props = {
  employeeFirstName: string
}

export const RetroPayDisbursementOptions: React.FunctionComponent<Props> = ({
  employeeFirstName
}) => {
  const { t } = useTranslation('employees')

  const showDisbursementOptions = useShowRetroPayDisbursementOptions()

  return showDisbursementOptions ? (
    <div className='grid gap-y-4'>
      <CardSelectorGroupField
        {...trackPayChange('retro-disbursement')}
        testId='retro-pay-disbursement-selector-group'
        label={t('retroPayDisbursementLabel')}
        name={'attachEarnings'}
        options={[
          {
            value: 'true',
            className: 'font-normal',
            contents: `${t('retroPayDisbursementAttachChoice', {
              firstName: employeeFirstName
            })}`
          },
          {
            value: 'false',
            className: 'font-normal',
            contents: `${t('retroPayDisbursementDoNotAttachChoice', {
              firstName: employeeFirstName
            })}`
          }
        ]}
      />
    </div>
  ) : null
}
