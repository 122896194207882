import * as React from 'react'
import { DatePicker as BuffetDatePicker } from '@toasttab/buffet-pui-date-picker'
import { useField } from 'formik'
import { useIsMobile } from '@local/use-is-mobile'

type Props = Omit<React.ComponentProps<typeof BuffetDatePicker>, 'onChange'> & {
  fieldName: string
  onChange?: (date: string | undefined) => void
}

const DatePicker = (props: Props) => {
  const isMobile = useIsMobile()

  const { fieldName, testId, errorText, invalid, name, onChange, ...rest } =
    props

  const [, meta, helpers] = useField(fieldName || '')

  const { error, value, touched } = meta

  const displayError = errorText ?? touched ? error : undefined

  const displayInvalid = invalid ?? touched ? !!error : false

  return (
    <BuffetDatePicker
      {...rest}
      testId={testId || fieldName}
      containerClassName={isMobile ? 'w-full mt-2 mb-2' : 'w-80 mt-4 mb-4'}
      name={name || fieldName || ''}
      invalid={displayInvalid}
      errorText={displayError}
      value={new Date(value)}
      onChange={(dateValue) => {
        if (fieldName) helpers.setValue(dateValue?.toString())
        if (onChange) onChange(dateValue?.toString())
      }}
      onDayBlur={() => helpers.setTouched(true)}
      size='lg'
    />
  )
}
export default DatePicker
