import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { RetroPayPanel, RetroPayPanelProps } from '@local/change-pay'
import { formatHrCurrency, formatPaidHoursDateRange } from './utils'
import { PayChangeSchema } from '../../domain'
import { usePayChangeModalStore } from '../../PayChangeModalStore'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'

export const BaseRetroPayPanel: React.FunctionComponent<RetroPayPanelProps> = (
  props
) => {
  const { t } = useTranslation('employees')
  const { locale } = useBuffetContext()

  const retroPay = usePayChangeModalStore((x) => x.retroPay)
  const { values } = useFormikContext<PayChangeSchema>()

  return !!retroPay && Number(retroPay.retroPay.amount) !== 0 ? (
    <RetroPayPanel
      paidHoursDateRangeText={t(
        'retroPayPanelPaidHours',
        'Past approved hours for {{dateRange}}',
        {
          dateRange: formatPaidHoursDateRange(values.effectiveDate, locale)
        }
      )}
      retroPayLabel={t('retroPay', 'Retro pay')}
      retroPay={formatHrCurrency(retroPay.retroPay, locale)}
      payDifferenceLabel={t('payChange', 'Pay change')}
      hoursWorkedLabel={t('hoursClockedIn', 'Hours clocked in')}
      hoursWorkedText={
        'hoursWorked' in retroPay
          ? `${retroPay.hoursWorked} ${t('hoursAbbr', 'hrs')}`
          : undefined
      }
      salaryLabel={t('salaryPerPayPeriod')}
      salaryText={
        'payPeriodEarnings' in retroPay
          ? formatHrCurrency(retroPay.payPeriodEarnings, locale)
          : undefined
      }
      {...props}
    />
  ) : null
}
