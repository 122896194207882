import * as React from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { DateRangePickerField, SelectField } from '@toasttab/buffet-pui-forms'
import { DateRange } from '@toasttab/buffet-pui-date-utilities'

import {
  useFeinFilterOptions,
  useFormattedPayHistoryRecords,
  usePayHistoryStore
} from '../stores/PayHistoryStore'
import { useScreenSize, ScreenSize } from '@toasttab/use-screen-size'
import { ModalHooksFor } from '@local/modal-provider'
import { FilterIcon, PayCheckIcon } from '@toasttab/buffet-pui-icons'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { SpaDate } from '@local/shared-services'
import { PayHistoryFilters } from '@local/generated/graphql'
import { FormAutoSubmit } from '../../../helpers/FormAutoSubmit'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'

type PayHistoryFiltersForm = {
  from?: Date
  to?: Date
  feinId?: number
}

export const Mapper = {
  ToPayHistoryFilters: (filters: PayHistoryFiltersForm): PayHistoryFilters => ({
    feinId: filters.feinId ?? null,
    to: filters.to?.toISOString() ?? null,
    from: filters.from?.toISOString() ?? null
  }),
  ToPayHistoryFiltersForm: (
    filters: PayHistoryFilters
  ): PayHistoryFiltersForm => ({
    to: !filters.to ? undefined : SpaDate.fromString(filters.to).toJSDate(),
    from: !filters.from
      ? undefined
      : SpaDate.fromString(filters.from).toJSDate(),
    feinId: filters.feinId ?? undefined
  })
}

export const Filters = () => {
  const applyFilters = usePayHistoryStore((store) => store.applyFilters)
  const clearFilters = usePayHistoryStore((store) => store.clearFilters)
  const filters = usePayHistoryStore((store) => store.filters)

  return (
    <Formik<PayHistoryFiltersForm>
      initialValues={Mapper.ToPayHistoryFiltersForm(filters)}
      onReset={clearFilters}
      onSubmit={(values) => applyFilters(Mapper.ToPayHistoryFilters(values))}
    >
      <Form>
        <FormAutoSubmit />
        <ResponsiveFilters />
        <React.Suspense fallback={''}>
          <EmptyState />
        </React.Suspense>
      </Form>
    </Formik>
  )
}

const ResponsiveFilters = () => {
  const screen = useScreenSize()

  if (screen > ScreenSize.SM) {
    return (
      <React.Suspense
        fallback={
          <LargeFieldContainer>
            <Skeleton className='h-10 md:w-1/2 lg:w-1/4' />
            <Skeleton className='h-10 md:w-1/2 lg:w-1/4' />
          </LargeFieldContainer>
        }
      >
        <LargeFilters />
      </React.Suspense>
    )
  }
  return <SmallFilters />
}

const EmptyState = () => {
  const { t } = useTranslation('employees')
  const paystubs = useFormattedPayHistoryRecords()

  if (paystubs.length === 0) {
    return (
      <div className='flex flex-col items-center max-w-screen-sm p-8 mx-auto space-y-3 text-center'>
        <PayCheckIcon className='text-secondary' />
        <div className='text-default'>{t('filterAppliedTitle')}</div>
        <div className='text-default type-subhead'>
          {t('filterAppliedSupplement')}
        </div>
        <Button variant='secondary' type='reset'>
          {t('resetFilters')}
        </Button>
      </div>
    )
  }
  return null
}

const { useOpenModal, useIsModalOpen, useCloseModal, useAutoClose } =
  ModalHooksFor<string>('pay-history-filters')

const SmallFilters = () => {
  const { t } = useTranslation('employees')
  const isOpen = useIsModalOpen()
  const close = useCloseModal()
  const open = useOpenModal()
  useAutoClose()
  const { submitForm } = useFormikContext<PayHistoryFiltersForm>()

  const applyFilters = () => {
    submitForm()
    close()
  }

  //TODO(mlee): should this be if the form is dirty?
  const hasFiltersApplied = usePayHistoryStore((store) => store.hasFilters())

  return (
    <>
      <div className='flex items-center justify-between'>
        <div>
          <span className='text-secondary'>{`${t('showing')}: `}</span>
          {hasFiltersApplied ? t('filteredPaystubs') : t('allPaystubs')}
        </div>
        <IconButton
          onClick={() => open()}
          icon={<FilterIcon className='text-secondary' />}
        />
      </div>

      <Modal
        isOpen={isOpen}
        shouldCloseOnEsc
        onRequestClose={close}
        shouldCloseOnOverlayClick={false}
      >
        <ModalHeader>Filters</ModalHeader>
        <ModalBody>
          <div className='pt-1 space-y-4'>
            <FilterFormFields />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='flex w-full space-x-4'>
            <div className='flex-grow'>
              <Button onClick={close} variant='secondary' className='w-full'>
                {t('cancel')}
              </Button>
            </div>
            <div className='flex-grow'>
              <Button className='w-full' onClick={applyFilters}>
                {t('filter')}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

const FeinFilterField = () => {
  const { t } = useTranslation('employees')
  const { hasFeins, feins } = useFeinFilterOptions()

  if (hasFeins) return null

  return (
    <SelectField
      aria-labelledby={t('business')}
      className='md:w-1/2 lg:w-1/4'
      testId={'business'}
      placeholder={t('business')}
      options={feins}
      name='feinId'
    />
  )
}

export const FilterFormFields = () => {
  const { locale } = useBuffetContext()
  const { t } = useTranslation('employees')

  return (
    <>
      <DateRangePickerField
        className='md:w-1/2 lg:w-1/4'
        name='dates'
        locale={locale}
        fromFieldName='from'
        toFieldName='to'
        formatValue={(range) => FormatDateRange(range, t('dateRange'))}
        placeholder={t('dateRange')}
        testId='DateRangePicker'
      />
      <FeinFilterField />
    </>
  )
}

export const FormatDateRange = (
  { from, to }: DateRange,
  defaultText: string
) => {
  if (!from || !to) return defaultText
  return `${from.toLocaleDateString()} - ${to.toLocaleDateString()}`
}

export const LargeFilters = () => (
  <LargeFieldContainer>
    <FilterFormFields />
  </LargeFieldContainer>
)

const LargeFieldContainer: React.FunctionComponent<React.PropsWithChildren> = ({
  children
}) => (
  <div className='flex flex-col w-full space-y-4 md:flex-row md:space-x-4 md:space-y-0'>
    {children}
  </div>
)
