import * as React from 'react'
import {
  CalendarTodayIcon,
  PhoneIcon,
  EmailIcon,
  CredentialsIcon,
  CheckCircleSelectedIcon,
  ScheduleIcon
} from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { SecondaryNavSection } from '@toasttab/buffet-pui-navigation'
import { useEmployeeId, useHideEmail } from '../employee/hooks'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import { GeneralErrorBoundary } from '@local/ec-app'
import {
  AllEmploymentStatuses,
  useGetNavigationEmployeeQuery
} from '@local/generated/graphql'
import { formatEmploymentDate } from '../employee/employment/overview/utils'
import { getDisplayEmail } from '../employee/utils'

export const NavigationProfile = () => {
  return (
    <GeneralErrorBoundary fallback={NavigationProfileFallback}>
      <NavigationProfileView />
    </GeneralErrorBoundary>
  )
}

export const NavigationProfileFallback = () => {
  return <></>
}

export const NavigationProfileView = () => {
  const { t } = useTranslation('employees')
  const employeeId = useEmployeeId()
  const { loading, data } = useGetNavigationEmployeeQuery({
    variables: { employeeId }
  })

  const employee = data?.findEmployeeById
  const employmentStatus = employee?.employment?.employmentStatus
  const employmentDate = GetEmploymentDate(employmentStatus)
  const employmentFormattedDate = employmentDate.getFormattedDate()
  const employmentDuration = GetEmploymentDuration(
    employmentDate.date,
    employmentStatus
  )
  const runTooltip = GetTooltipContent(employmentStatus?.__typename)
  const displayEmail = useHideEmail()
    ? getDisplayEmail(employee?.user?.email || '')
    : employee?.user?.email || ''
  if (loading) {
    return <NavigationProfileFallback />
  }

  if (!data?.findEmployeeById) {
    return null
  }

  return (
    <SecondaryNavSection>
      <div data-testid='navigation-profile'>
        {employmentFormattedDate && (
          <div className='flex flex-row items-center'>
            <CalendarTodayIcon
              accessibility='decorative'
              className='text-secondary'
            />
            <div className='pl-2.5' data-testid='employment-date'>
              {employmentFormattedDate}
            </div>
          </div>
        )}
        {employmentDuration && (
          <div className={'mr-3'}>
            <Tooltip content={() => runTooltip}>
              <div
                className='flex flex-row items-center mt-2'
                data-testid='employment-duration'
              >
                <ScheduleIcon
                  accessibility='decorative'
                  className='text-secondary'
                />
                <div className='pl-2.5'>{employmentDuration}</div>
              </div>
            </Tooltip>
          </div>
        )}
        {employee?.contact?.telephoneNumber?.telephoneNumber && (
          <div className='flex flex-row items-center mt-2'>
            <PhoneIcon accessibility='decorative' className='text-secondary' />
            <div
              className='pl-2.5'
              title={employee.contact.telephoneNumber.telephoneNumber}
            >
              {employee.contact.telephoneNumber.telephoneNumber}
            </div>
          </div>
        )}
        {employee?.user?.email && (
          <div className='flex flex-row items-center -mb-2'>
            <EmailIcon accessibility='decorative' className='text-secondary' />
            {displayEmail ? (
              <Button
                as='a'
                className='-ml-4 truncate md:-ml-2'
                href={`mailto:${employee.user.email}`}
                title={employee.user.email ?? ''}
                variant='link'
              >
                {employee.user.email}
              </Button>
            ) : (
              <div className='pl-2.5 my-1'>{t('noEmailOnFile')}</div>
            )}
          </div>
        )}
        {employee?.employment?.employeeNumber && (
          <div
            className='flex flex-row items-center mt-2'
            data-testid='employee-num-row'
          >
            <CredentialsIcon
              accessibility='decorative'
              className='text-secondary'
            />
            <div
              className='pl-2.5'
              title={employee.employment.employeeNumber ?? ''}
            >
              {employee.employment.employeeNumber}
            </div>
          </div>
        )}
        {employee?.user?.toastIdentityGuid && (
          <div className='flex flex-row items-center mt-2'>
            <CheckCircleSelectedIcon
              accessibility='decorative'
              className='text-secondary'
            />
            <div className='pl-2.5' title='LinkedUser'>
              {t('linked')}
            </div>
          </div>
        )}
      </div>
    </SecondaryNavSection>
  )
}

const GetEmploymentDuration = (
  date: Date | undefined,
  employmentStatus?: Partial<AllEmploymentStatuses>
): string | null => {
  const { t } = useTranslation('employees')

  const isEmploymentDateInPast = moment() >= moment(date)

  const validDate = moment(date).isValid()
  const validYear = moment(date).format('YYYY') !== '0000'

  if (
    !employmentStatus ||
    !date ||
    !validDate ||
    !validYear ||
    !isEmploymentDateInPast
  ) {
    return null
  }

  const terminated =
    employmentStatus.__typename === 'TerminatedEmploymentStatus'

  const startDateToCalc = terminated
    ? moment(employmentStatus.hireDate)
    : moment(date)

  const endDateToCalc = terminated ? moment(date) : moment()

  const years = endDateToCalc.diff(startDateToCalc, 'years')
  const yearsText = years > 0 ? `${years} ${t('years')}, ` : ''
  endDateToCalc.subtract(years, 'years')

  const months = endDateToCalc.diff(startDateToCalc, 'months')
  const monthsText = months > 0 ? `${months} ${t('months')}, ` : ''
  endDateToCalc.subtract(months, 'months')

  const days = endDateToCalc.diff(startDateToCalc, 'days')
  const daysText = `${days} ${t('days')}`

  return `${yearsText}${monthsText}${daysText}`
}

const GetEmploymentDate = (
  employmentStatus?: Partial<AllEmploymentStatuses> | null
): EmploymentDate => {
  const { t } = useTranslation('employees')

  switch (employmentStatus?.__typename) {
    case 'RehiredEmploymentStatus':
      return new EmploymentDate(
        `${t('rehiredOn')} `,
        !!employmentStatus?.rehireDate
          ? new Date(employmentStatus.rehireDate)
          : undefined,
        `${t('rehired')}`
      )

    case 'LeaveOfAbsenceEmploymentStatus':
      return new EmploymentDate(
        `${t('leaveOfAbsenceOn')} `,
        !!employmentStatus?.scheduledLeave?.startDate
          ? new Date(employmentStatus.scheduledLeave.startDate)
          : undefined,
        `${t('leaveOfAbsence')}`
      )

    case 'TerminatedEmploymentStatus':
      return new EmploymentDate(
        `${t('terminatedOn')} `,
        !!employmentStatus?.lastDayOfEmployment
          ? new Date(employmentStatus.lastDayOfEmployment)
          : undefined,
        `${t('terminated')}`
      )

    default:
      return new EmploymentDate(
        `${t('hiredOn')} `,
        !!employmentStatus?.adjustedHireDate
          ? new Date(employmentStatus.adjustedHireDate)
          : !!employmentStatus?.hireDate
          ? new Date(employmentStatus.hireDate)
          : undefined,
        `${t('hired')}`
      )
  }
}

class EmploymentDate {
  constructor(
    readonly prefix: string,
    readonly date: Date | undefined,
    readonly defaultFormattedDateString: string
  ) {}

  getFormattedDate(): string | undefined {
    if (!!this.date) {
      return `${this.prefix}${formatEmploymentDate(this.date, undefined, {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'UTC'
      })}`
    } else {
      return this.defaultFormattedDateString
    }
  }
}

const GetTooltipContent = (employmentStatusType?: string | undefined) => {
  const { t } = useTranslation('employees')

  const getTooltipText = () => {
    switch (employmentStatusType) {
      case 'RehiredEmploymentStatus':
        return t('employmentLengthSinceLastHireDate')
      case 'LeaveOfAbsenceEmploymentStatus':
        return t('leaveLength')
      default:
        return t('employmentLength')
    }
  }

  return (
    <div
      className={'whitespace-pre-wrap'}
      data-testid='employment-duration-tooltip'
    >
      {getTooltipText()}
    </div>
  )
}
