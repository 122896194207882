import * as React from 'react'
import { Alert } from '@toasttab/buffet-pui-alerts'
import {
  Field,
  FieldName,
  FieldValue,
  FormContainer,
  ProfileOnboadingForm
} from '../profile/personal/components'
import Skeleton from 'react-loading-skeleton'
import { useCompanyCode } from '../hooks'
import { GeneralErrorBoundary } from '@local/ec-app'

const ProfileOnboarding = () => {
  const companyCode = useCompanyCode()

  return <ProfileOnboadingForm companyCode={companyCode} />
}

const ProfileOnboardingError = () => (
  <div className='flex-grow p-8 md:p-12'>
    <Alert variant='error' className='w-full'>
      Error loading onboarding
    </Alert>
  </div>
)

const ProfileOnboardingLoader = () => (
  <FormContainer headerText='Personal Profile'>
    <div className='pb-6'>
      <div className='p-0 mb-8 font-semibold type-headline-3'>
        <Skeleton height={20} width={150} />
      </div>
      <p className='pt-0 pb-3 mt-0'>
        <Skeleton height={12} width={200} />
      </p>
      {Array.from({ length: 5 }, (_v, i) => (
        <Field key={`personal-profile-field-${i}`}>
          <FieldName key={i}>
            <Skeleton height={10} width={100} />
          </FieldName>
          <FieldValue>
            <Skeleton height={15} width={80} />
          </FieldValue>
        </Field>
      ))}
    </div>
  </FormContainer>
)

export const ProfileOnboadingPage = () => (
  <GeneralErrorBoundary fallback={<ProfileOnboardingError />}>
    <React.Suspense fallback={<ProfileOnboardingLoader />}>
      <ProfileOnboarding />
    </React.Suspense>
  </GeneralErrorBoundary>
)
