import * as React from 'react'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { NAV_APP_HEADER_PAGE_ACTIONS_ID } from './common'
import { useWindowProvider } from '@local/ec-app'
import { DynamicPortal } from '@local/layout'

export const PageActionsContentSection = ({
  testId = 'page-actions-content'
}: TestIdentifiable) => (
  <div
    className={`${NAV_APP_HEADER_PAGE_ACTIONS_ID} w-full`}
    data-testid={testId}
  />
)

export const PageActionsContent = ({
  children
}: React.PropsWithChildren<{}>) => {
  const { document } = useWindowProvider()

  return (
    <DynamicPortal
      container={document}
      selector={`.${NAV_APP_HEADER_PAGE_ACTIONS_ID}`}
    >
      {children}
    </DynamicPortal>
  )
}
