import * as React from 'react'
import _merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import { DeepPartial } from '@local/types'

/**
 * Creates a React provider that provides configurations/options.
 * These providers are hierarchical and any child providers can extend
 * the configuration of it's parent immutably. This allows for a global
 * configuration that can then be extend for specific parts of an application
 * at multiple levels.
 * @example
 * const [MyOptionsProvider, useMyOptions] = makeOptionsProvider({ someValue: 'test', someOtherValue: 123 })
 *
 * const PrintOptions = () => {
 *   const options = useMyOptions()
 *   return <div>{JSON.stringify(options)}</div>
 * }
 *
 * const App = () => (
 *   <MyOptionsProvider options={{ someValue: 'test', someOtherValue: 555}}>
 *     <PrintOptions />
 *     <MyOptionsProvider options={{ someValue: 'nice' }}>
 *       <PrintOptions />
 *     </MyOptionsProvider>
 *   </MyOptionsProvider>
 * )
 *
 * // Results in...
 * // <div>{'someValue': 'test', someOtherValue: 555}</div>
 * // <div>{'someValue': 'nice', someOtherValue: 555}</div>
 */
export function makeOptionsProvider<
  TOptions,
  TPartialOptions = DeepPartial<TOptions>
>(
  defaultValue: TOptions,
  config: {
    merge?: (parent: TOptions, current: TPartialOptions) => TOptions
  } = {}
) {
  const { merge = _merge } = config
  const OptionsProviderContext = React.createContext<TOptions>(defaultValue)
  const useOptions = () => React.useContext(OptionsProviderContext)

  const OptionsProvider = ({
    children,
    options
  }: React.PropsWithChildren<{
    options?: TPartialOptions
  }>) => {
    const parentOptions = useOptions()
    // #perf - The deep clone is USUALLY expensive but these options shouldn't
    // be that large to cause any performance issues.
    const newOptions = React.useMemo(
      () => merge(cloneDeep(parentOptions), options ?? {}),
      [parentOptions, options]
    )

    return (
      <OptionsProviderContext.Provider value={newOptions}>
        {children}
      </OptionsProviderContext.Provider>
    )
  }

  return [OptionsProvider, useOptions, OptionsProviderContext] as const
}
