import {
  RenderItemProps,
  SelectItemContainer,
  SelectOption
} from '@toasttab/buffet-pui-select'
import * as React from 'react'
import { PayrollEmployeeMapping } from '@local/generated/graphql'
import { getClockInDateText, getFormattedEmail } from '../utils'

interface MappingSelectOption extends SelectOption {
  archived: boolean
  email: string
  mostRecentClockInDate: Date | null
  mappedTo: PayrollEmployeeMapping
}

export const MappingSelectRenderItem = ({
  item,
  index,
  itemProps,
  highlightedIndex,
  listRef,
  listContentRef
}: RenderItemProps<string, MappingSelectOption>) => {
  const clockInText = getClockInDateText(item.mostRecentClockInDate)

  const { label, email, archived, disabled, mappedTo, value } = item
  const { name, payrollEmployeeNumber } = mappedTo ?? {}

  const formattedName = name ? name.formatted : ''
  const formattedEmail = getFormattedEmail(email)
  const payrollEmployeeNumberTag = payrollEmployeeNumber
    ? ` #${payrollEmployeeNumber}`
    : ''

  // TODO: ELM-557 - Translate this page.
  //  Since this option is rendered conditionally, the translation hook crashes the page when we change our search criteria.
  //  How can we intelligently get that prop in here?
  return (
    <SelectItemContainer
      index={index}
      itemProps={itemProps}
      highlightedIndex={highlightedIndex}
      key={`${value}`}
      testId={`mapping-dropdown-option-${index}`}
      listRef={listRef}
      listContentRef={listContentRef}
    >
      <div>
        {label}
        {archived && ' (archived)'}
        <div className='text-gray-75 type-caption'>
          {disabled ? (
            `This user is mapped to Payroll profile ${formattedName} ${payrollEmployeeNumberTag}`
          ) : (
            <>
              <div>
                {formattedEmail
                  ? `${formattedEmail.username}@${formattedEmail.domain}`
                  : 'No email on file'}
              </div>
              <div>{clockInText}</div>
            </>
          )}
        </div>
      </div>
    </SelectItemContainer>
  )
}
