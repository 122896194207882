import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import ApplicationRoot from './ApplicationRoot'
import { domElementGetter } from '@toasttab/ec-layout'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-hr',
  rootComponent: ApplicationRoot,
  portalContainers: ['banquetPortalsContainer'],
  domElementGetter: domElementGetter.application
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'ec-human-resources-spa'
