import * as React from 'react'
import { useLocation } from 'react-router-dom'
import cx from 'classnames'
import { ActionButtons } from './components'
import { usePersonalProfile } from './hooks'
import { useIsEmployeeProfileNavEnabled } from '../../hooks'
import {
  OnboardedConfirmation,
  EmergencyContactsView,
  AddressesView,
  ContactView,
  BasicView
} from './PersonalProfilePageComponents'

const PersonalProfilePage = () => {
  const { state } = useLocation()
  const isOnboarded = !!state?.isOnboarded

  const personalProfile = usePersonalProfile()
  const links = personalProfile?.links
  const showNav = useIsEmployeeProfileNavEnabled()

  const onGoToDashboard = () => {
    window.location.assign(links.dashboard)
  }

  return (
    <div className={cx('flex-grow', showNav ? 'pb-12' : 'p-8 md:p-12')}>
      {isOnboarded && links?.dashboard && (
        <OnboardedConfirmation onClick={onGoToDashboard} />
      )}

      {showNav ? (
        <ActionButtons />
      ) : (
        <div className='flex justify-between pb-5 mt-0 mb-6 border-b-4 border-black'>
          <span className='font-semibold type-headline-1'>
            Personal Profile
          </span>
          <ActionButtons />
        </div>
      )}

      <BasicView />
      <ContactView />
      <AddressesView />
      <EmergencyContactsView />
    </div>
  )
}

export { PersonalProfilePage }
