import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { EarningsSchema, useEarningsSchema } from './EarningsSchema'

export type PayChangeSchema = {
  currentFormId:
    | 'pay-change-form'
    | 'attach-earnings-form'
    | 'cancellation-confirmation'
  rate: number
  currency: string
  rateConfirmationRequired: boolean
  rateConfirmed: boolean
  effectiveDate: Date
  attachEarnings?: 'true' | 'false'
  earnings?: EarningsSchema
}

export const usePayChangeSchema = () => {
  const { t } = useTranslation('employees')
  const earningsSchema = useEarningsSchema()

  return yup.object({
    rate: yup
      .number()
      .required(t('required', 'Required'))
      .positive(t('mustBePositive', 'Must be positive'))
      .max(99_999_999.99, t('amountTooLarge', 'Amount is too large')),
    currency: yup.string().required(),
    rateConfirmed: yup.boolean().when('rateConfirmationRequired', {
      is: true,
      then: yup.boolean().oneOf([true])
    }),
    effectiveDate: yup.date(),
    earnings: yup.object({}).when('currentFormId', {
      is: 'attach-earnings-form',
      then: () => earningsSchema
    })
  })
}
