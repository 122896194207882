import * as React from 'react'
import { EmailSupportButton } from './EmailSupportButton'
import { LevelId, MappingCondition } from '@local/generated/graphql'
import { NOT_MAPPED_STATUSES } from '../utils'

interface Props {
  mappingStatus: MappingCondition
  payrollJobName?: string | null
  payrollJobId?: LevelId | null
  payrollLocationId: LevelId
  isProfileMapped: boolean
}

export const JobMappingAction = ({
  mappingStatus,
  payrollJobName,
  payrollJobId,
  payrollLocationId,
  isProfileMapped
}: Props) => {
  const showEmailSupportButton = NOT_MAPPED_STATUSES.includes(mappingStatus)

  return showEmailSupportButton ? (
    <EmailSupportButton
      payrollJobName={payrollJobName!}
      payrollJobId={payrollJobId!}
      payrollLocationId={payrollLocationId}
    />
  ) : null
}
