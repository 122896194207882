import * as React from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'

type Props = {
  employmentStatusType?: string | null
}

export const EmploymentStatusIndicator: React.FunctionComponent<Props> = ({
  employmentStatusType
}) => {
  return (
    <div>
      <div
        data-testid='employment-status-status-indicator'
        className={`w-full inline-flex items-start rounded space-x-2 pl-4 ${getBackgroundColor(
          employmentStatusType
        )}`}
      >
        <span className='type-default flex-grow py-3 pr-4'>
          <div className='flex items-center'>
            <div className='flex-auto'>
              <div
                className={cx(
                  `type-overline font-bold flex-auto ${getTextColor(
                    employmentStatusType
                  )}`
                )}
                data-testid='employment-status-status-text'
              >
                {useStatusText(employmentStatusType)}
              </div>
              <div
                className='type-caption flex-auto text-secondary'
                data-testid='employment-status-status-detail-text'
              >
                {useStatusDetailText(employmentStatusType)}
              </div>
            </div>
            <StatusInfoTooltip employmentStatusType={employmentStatusType} />
          </div>
        </span>
      </div>
    </div>
  )
}

const getTextColor = (employmentStatusType?: string | null): string =>
  employmentStatusType === 'ActiveEmploymentStatus' ||
  employmentStatusType === 'RehiredEmploymentStatus'
    ? 'text-color-success'
    : 'text-color-default'

const getBackgroundColor = (employmentStatusType?: string | null): string => {
  switch (employmentStatusType) {
    case 'TerminatedEmploymentStatus':
      return 'bg-gray-25'

    case 'DemoEmploymentStatus':
    case 'ImplementationEmploymentStatus':
      return 'bg-lavender-0'

    case 'ActiveEmploymentStatus':
    case 'RehiredEmploymentStatus':
      return 'bg-success-0'

    case 'PendingEmploymentStatus':
    case 'DeletedEmploymentStatus':
    case 'ApplicantEmploymentStatus':
    case 'NeverEmployedEmploymentStatus':
      return 'bg-warning-0'

    default:
      return 'bg-neutral-0'
  }
}

const useStatusDetailText = (employmentStatusType?: string | null): string => {
  const { t } = useTranslation('employees')
  switch (employmentStatusType) {
    case 'ApplicantEmploymentStatus':
      return t('employeeNotHiredYet')

    case 'PendingEmploymentStatus':
      return t('employeeFutureHireDate')

    case 'ImplementationEmploymentStatus':
      return t('employeeSettingUpPayroll')

    case 'DeletedEmploymentStatus':
      return t('employeeNoExistingRecord')

    case 'NeverEmployedEmploymentStatus':
      return t('employeeNotHired')

    case 'ActiveEmploymentStatus':
    case 'RehiredEmploymentStatus':
      return t('receivingCheck')

    case 'DemoEmploymentStatus':
    case 'SeasonalEmploymentStatus':
    case 'TerminatedEmploymentStatus':
    case 'LeaveOfAbsenceEmploymentStatus':
      return t('notReceivingCheck')

    default:
      return ''
  }
}

const useStatusText = (employmentStatusType?: string | null): string => {
  const { t } = useTranslation('employees')
  switch (employmentStatusType) {
    case 'ApplicantEmploymentStatus':
      return t('applicant')

    case 'DeletedEmploymentStatus':
      return t('deleted')

    case 'DemoEmploymentStatus':
      return t('demo')

    case 'ImplementationEmploymentStatus':
      return t('implementation')

    case 'LeaveOfAbsenceEmploymentStatus':
      return t('leaveOfAbsence')

    case 'NeverEmployedEmploymentStatus':
      return t('neverEmployed')

    case 'PendingEmploymentStatus':
      return t('pending')

    case 'SeasonalEmploymentStatus':
      return t('seasonal')

    case 'TerminatedEmploymentStatus':
      return t('terminated')

    case 'RehiredEmploymentStatus':
    case 'ActiveEmploymentStatus':
      return t('active')

    default:
      return ''
  }
}

const StatusInfoTooltip: React.FunctionComponent<Props> = ({
  employmentStatusType
}) => {
  const { t } = useTranslation('employees')

  switch (employmentStatusType) {
    case 'TerminatedEmploymentStatus':
      return (
        <InfoTooltip className='max-w-xs'>
          {t('employeeNeedsToBeRehired')}
        </InfoTooltip>
      )

    case 'LeaveOfAbsenceEmploymentStatus':
      return (
        <InfoTooltip
          className='max-w-xs'
          data-testid='employee-status-info-tooltip'
        >
          {t('employeeNeedsToBeActive')}
        </InfoTooltip>
      )

    case 'DemoEmploymentStatus':
    case 'SeasonalEmploymentStatus':
      return (
        <InfoTooltip className='max-w-xs'>
          {t('employeeNeedsToBeActiveEmployeeSettings')}
        </InfoTooltip>
      )

    default:
      return null
  }
}
