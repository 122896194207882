import * as React from 'react'
import { TFunction } from 'i18next'
import { Trans, useTranslation } from 'react-i18next'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { ScheduledOrdersIcon } from '@toasttab/buffet-pui-icons'
import {
  JobAssignmentsListJobAssignmentFragment,
  JobAssignmentsListSalaryFragment
} from '@local/generated/graphql'
import { trackEmploymentOverview } from '@local/track'
import { formatEmploymentDate } from '../../../utils'
import { useJobAssignmentsStore } from '../../JobAssignmentsStore'

export type Props = {
  salary?: JobAssignmentsListSalaryFragment | null
  hourlyJobs: JobAssignmentsListJobAssignmentFragment[]
  viewScheduledPayChanges: () => void
}

export const ScheduledPayChangesAlert: React.FunctionComponent<Props> = ({
  salary,
  hourlyJobs,
  viewScheduledPayChanges
}) => {
  const alertMessage = useAlertMessage(
    viewScheduledPayChanges,
    hourlyJobs,
    salary
  )

  return !!hourlyJobs.length || salary ? (
    <Alert
      testId='scheduled-pay-change-alert'
      className='w-full mt-3'
      showIcon={false}
      variant='info'
    >
      <>
        <ScheduledOrdersIcon
          testId='scheduled-pay-change-alert-orders-icon'
          accessibility='decorative'
        />{' '}
        {alertMessage}
      </>
    </Alert>
  ) : null
}

const useAlertMessage = (
  viewScheduledPayChanges: VoidFunction,
  hourlyJobs: JobAssignmentsListJobAssignmentFragment[],
  salary?: JobAssignmentsListSalaryFragment | null
): string | React.JSX.Element => {
  const { t } = useTranslation('scheduled-pay-changes')
  const employeeFirstName = useJobAssignmentsStore((x) => x.employeeFirstName)

  const ViewButton = (
    <button
      {...trackEmploymentOverview('scheduled-pay-change-view')}
      data-testid={'view-scheduled-pay-change-button'}
      className={'underline font-semibold'}
      onClick={() => viewScheduledPayChanges()}
    />
  )

  if (hourlyJobs.length > 1 || (hourlyJobs.length === 1 && salary)) {
    return (
      <Trans
        t={t}
        i18nKey='scheduledAlert'
        defaults='{{ count }} scheduled pay changes.<0>View</0>'
        count={hourlyJobs.length + (salary ? 1 : 0)}
        components={[ViewButton]}
      />
    )
  }

  if (salary) {
    return formatSalaryMessage(
      t,
      ViewButton,
      employeeFirstName,
      salary.pendingPayChange?.effectiveDate
    )
  }

  if (hourlyJobs.length === 1 && !salary) {
    return formatHourlyMessage(
      t,
      ViewButton,
      employeeFirstName,
      hourlyJobs[0].name,
      hourlyJobs[0].pendingPayChange?.effectiveDate
    )
  }

  return ''
}

const formatHourlyMessage = (
  t: TFunction,
  viewButton: React.JSX.Element,
  employeeFirstName?: string | null,
  jobName?: string | null,
  effectiveDate?: Date | null
): string | React.JSX.Element => {
  const formattedEffectiveDate = effectiveDate
    ? formatEmploymentDate(effectiveDate, undefined, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'UTC'
      })
    : effectiveDate

  if (!employeeFirstName || !formattedEffectiveDate || !jobName) {
    return (
      <Trans
        t={t}
        i18nKey='scheduledAlert'
        defaults='There is a scheduled pay change.<0>View</0>'
        components={[viewButton]}
      />
    )
  } else {
    return (
      <Trans
        t={t}
        i18nKey='scheduledHourlyAlert'
        defaults="There is a scheduled pay change for {{ employeeFirstName }}'s {{ jobName }} job on {{ date }}.<0>View</0>"
        values={{
          employeeFirstName: employeeFirstName,
          jobName: jobName,
          date: formattedEffectiveDate
        }}
        components={[viewButton]}
      />
    )
  }
}

const formatSalaryMessage = (
  t: TFunction,
  viewButton: React.JSX.Element,
  employeeFirstName?: string | null,
  effectiveDate?: Date | null
): string | React.JSX.Element => {
  const formattedEffectiveDate = effectiveDate
    ? formatEmploymentDate(effectiveDate, undefined, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'UTC'
      })
    : effectiveDate

  if (!employeeFirstName || !formattedEffectiveDate) {
    return (
      <Trans
        t={t}
        i18nKey='scheduledAlert'
        defaults='There is a scheduled pay change.<0>View</0>'
        components={[viewButton]}
      />
    )
  }

  return (
    <Trans
      t={t}
      i18nKey='scheduledSalaryAlert'
      defaults="There is a scheduled pay change for {{ employeeFirstName }}'s salary on {{ date }}.<0>View</0>"
      values={{
        employeeFirstName: employeeFirstName,
        date: formattedEffectiveDate
      }}
      components={[viewButton]}
    />
  )
}
