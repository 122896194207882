import { useMemo } from 'react'
import makeApi from './makeApi'
import {
  useRestClient,
  useHrClient,
  useLoginClient
} from '@local/api/ApolloClientsProvider'

export const useApi = () => {
  const restClient = useRestClient()
  const hrClient = useHrClient()
  const loginClient = useLoginClient()

  const api = useMemo(() => {
    return makeApi({ restClient, loginClient, hrClient })
  }, [restClient, hrClient, loginClient])

  return api
}
