import * as React from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import { useFeature } from '@toasttab/ec-features'
import { EmployeeApp } from './employee/EmployeeApp'
import SignDocument from './employee/onboarding/SignDocument'
import RequiredDocuments from './employee/onboarding/RequiredDocuments'
import {
  W4CompletePage,
  W4LandingPage,
  W4OnboardingApp
} from './employee/onboarding/W4OnboardingApp'
import { AppNavigation } from './navigation/AppNavigation'
import { useIsEmployeeProfileNavEnabled } from './employee/hooks'
import { LazyPayHistoryApp, LazyTeamIndex } from './lazy-pages'
import { EmploymentOverviewApp } from './employee/employment/overview/EmploymentOverviewApp'
import { useIsEmploymentOverviewReadonlyActive } from './employee/employment/overview/hooks'
import { MappingStatusApp } from './employee/posMapping/MappingStatusApp'
import { ProfileOnboadingPage } from './employee/onboarding/ProfileOnboardingPage'
import {
  AccountMenuPage,
  DetailsForm,
  PasswordForm
} from './employee/profile/account/components'
import { PayHistory } from './employee/payHistory/pages/PayHistory'
import { EmploymentSummary } from './employee/employment/position/employmentSummary'
import { RouteObject } from 'react-router'
import { WorkOpportunityTaxCredit } from './employee/profile/personal/WorkOpportunityTaxCredit'
import { EmploymentOverviewBackHeader } from './employee/employment/overview/EmploymentOverviewBackHeader'
import { PersonalProfileSettingsPage } from './employee/profile/personal/settings/PersonalProfileSettingsPage'
import { BasicForm } from './employee/profile/personal/components/BasicForm'
import { AddressesForm } from './employee/profile/personal/components/AddressesForm'
import { ContactForm } from './employee/profile/personal/components/ContactForm'
import { PersonalProfilePage } from './employee/profile/personal/PersonalProfilePage'
import { ManageEmergencyContacts } from './employee/profile/personal/emergency-contacts/ManageEmergencyContacts'
import { EditEmergencyContactInfoForm } from './employee/profile/personal/components'
import { PaymentMethodBackHeader } from './employee/employment/paymentMethod/PaymentMethodBackHeader'
import { PaymentMethodPage } from './employee/employment/paymentMethod/paymentMethod/PaymentMethod'
import { I92023 } from './employee/onboarding/i92023/I92023'
import { StatusChangeRehireController } from './employee/employment/overview/rehireDialogue/StatusChangeRehireController'

export const App = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  )
}

export const AppRoutes = () => {
  const hasEmployeeProfileNav = useIsEmployeeProfileNavEnabled()

  const isEmploymentOverviewReadonlyActive =
    useIsEmploymentOverviewReadonlyActive()

  const isSymmetryPayrollFormsActive = useFeature('ec-hr-spf-w4', true)

  const legacyNavigationOnlyRoutes = [
    '/Employee/gross2Netv2.aspx',
    '/Employee/OpenEnrollment.aspx',
    '/Employee/PositionDetail.aspx',
    '/Benefits/OpenEnrollment.aspx',

    '/mvc/:companyCode/MySelf/AssetsAndExpenses/*',
    '/mvc/:companyCode/MySelf/Benefits/*',
    '/mvc/:companyCode/MySelf/CareerProfile/*',
    '/mvc/:companyCode/MySelf/DocumentsAndForms/*',
    '/mvc/:companyCode/MySelf/Pay/*',
    '/mvc/:companyCode/MySelf/Performance/*',
    '/mvc/:companyCode/MySelf/PersonalProfile/*',
    '/mvc/:companyCode/MySelf/StatusChanges/*',
    '/mvc/:companyCode/MySelf/TimeAndLabor/*',

    '/mvc/:companyCode/Team/AssetsAndExpenses/*',
    '/mvc/:companyCode/Team/Benefits/*',
    '/mvc/:companyCode/Team/CareerProfile/*',
    '/mvc/:companyCode/Team/Pay/*',
    '/mvc/:companyCode/Team/PersonalProfile/*',
    '/mvc/:companyCode/Team/Performance/*',
    '/mvc/:companyCode/Team/StatusChanges/*',
    '/mvc/:companyCode/Team/TimeAndLabor/*',
    '/mvc/:companyCode/Team/DocumentsAndForms/*'
  ].map((path) => {
    return {
      path: path,
      element: <AppNavigation />
    }
  })

  // We want to hijack a legacy esx-web button in the new flow. This mounts a controller that does so.
  const legacyStatusChangePageRoutes = [
    '/mvc/:companyCode/Team/StatusChanges/*',
    '/mvc/:companyCode/MySelf/StatusChanges/*'
  ].map((path: string) => ({
    path,
    element: <StatusChangeRehireController />
  }))

  const routes: RouteObject[] = [
    {
      path: '/:companyCode/employees/:employeeId/required-documents',
      element: <RequiredDocuments />
    },
    {
      path: '/:companyCode/employees/:employeeId/required-documents/:docId',
      element: <SignDocument />
    },
    {
      path: '/:companyCode/employees/:employeeId/forms/i9',
      element: <I92023 pageIsInEditMode={false} />
    },
    {
      path: '/:companyCode/employees/:employeeId/forms/i9/edit',
      element: <I92023 pageIsInEditMode />
    },
    {
      path: '/:companyCode/employees/:employeeId/forms/w4/landing',
      element: <W4LandingPage />
    },
    {
      path: '/:companyCode/employees/:employeeId/pay/history',
      element: <LazyPayHistoryApp />,
      children: [
        {
          path: '/:companyCode/employees/:employeeId/pay/history',
          element: <PayHistory />
        }
      ]
    },
    ...[
      '/mvc/:companyCode/MySelf/CareerProfile/EmploymentSummary/:employee_id',
      '/mvc/:companyCode/Team/CareerProfile/EmploymentSummary/:employee_id'
    ].map((path) => {
      return {
        path: path,
        element: <LazyTeamIndex />,
        children: [
          {
            path: path,
            element: <EmploymentSummary />
          }
        ]
      }
    }),
    {
      element: <EmployeeApp />,
      children: [
        ...(isEmploymentOverviewReadonlyActive
          ? [
              ...[
                '/mvc/:companyCode/MySelf/CareerProfile/WorkPosts/*',
                '/mvc/:companyCode/MySelf/Pay/PayChange/*',
                '/mvc/:companyCode/Myself/CareerProfile/Position/*',
                '/mvc/:companyCode/MySelf/StatusChanges/*',
                '/mvc/:companyCode/Team/CareerProfile/WorkPosts/*',
                '/mvc/:companyCode/Team/Pay/PayChange/*',
                '/mvc/:companyCode/Team/CareerProfile/Position/*',
                '/mvc/:companyCode/Team/StatusChanges/*',
                '/Employee/PositionDetail.aspx'
              ].map((path) => {
                return {
                  path: path,
                  element: <EmploymentOverviewBackHeader />
                }
              }),
              {
                path: '/:companyCode/employees/:employeeId/employment',
                element: <EmploymentOverviewApp />
              }
            ]
          : [{}]),
        {
          path: '/:companyCode/employees/:employeeId/mappingStatus',
          element: <MappingStatusApp />
        },
        {
          path: '/:companyCode/employees/:employeeId/account',
          element: <AccountMenuPage />
        },
        {
          path: '/:companyCode/employees/:employeeId/account/details',
          element: <DetailsForm />
        },
        {
          path: '/:companyCode/employees/:employeeId/account/password',
          element: <PasswordForm />
        },
        {
          path: '/:companyCode/employees/:employeeId/onboarding',
          element: <ProfileOnboadingPage />
        },
        isSymmetryPayrollFormsActive
          ? {
              path: '/:companyCode/employees/:employeeId/forms/w4',
              element: <W4OnboardingApp />
            }
          : {},
        {
          path: '/:companyCode/employees/:employeeId/forms/w4/complete',
          element: <W4CompletePage />
        },
        {
          path: '/:companyCode/employees/:employeeId/wotc',
          element: <WorkOpportunityTaxCredit />
        },
        {
          path: '/:companyCode/employees/:employeeId/profile',
          element: <PersonalProfilePage />
        },
        {
          path: '/:companyCode/employees/:employeeId/profile/settings',
          element: <PersonalProfileSettingsPage />
        },
        {
          path: '/:companyCode/employees/:employeeId/profile/basic',
          element: <BasicForm />
        },
        {
          path: '/:companyCode/employees/:employeeId/profile/addresses',
          element: <AddressesForm />
        },
        {
          path: '/:companyCode/employees/:employeeId/profile/contact',
          element: <ContactForm />
        },
        {
          path: '/:companyCode/employees/:employeeId/profile/emergency-contacts',
          element: <ManageEmergencyContacts />
        },
        {
          path: '/:companyCode/employees/:employeeId/profile/emergency-contacts/new-local',
          element: (
            <EditEmergencyContactInfoForm action='AddFromEmergencyContacts' />
          )
        },
        {
          path: '/:companyCode/employees/:employeeId/profile/emergency-contacts/new',
          element: <EditEmergencyContactInfoForm action='AddFromProfile' />
        },
        {
          path: '/:companyCode/employees/:employeeId/profile/emergency-contacts/:emergencyContactId',
          element: <EditEmergencyContactInfoForm action='Edit' />
        },
        {
          path: '/:companyCode/employees/:employeeId/paymentMethod',
          element: <PaymentMethodPage />
        },
        {
          path: '/:companyCode/employees/:employeeId/payment-method',
          element: <PaymentMethodPage />
        },
        ...[
          '/mvc/:companyCode/MySelf/Pay/DirectDeposits/*',
          '/mvc/:companyCode/Team/Pay/DirectDeposits/*'
        ].map((path) => {
          return {
            path: path,
            element: <PaymentMethodBackHeader />
          }
        })
      ]
    }
  ]

  const legacyStatusChangeRoutes = useRoutes(legacyStatusChangePageRoutes)

  const displayRoutes = useRoutes(
    hasEmployeeProfileNav
      ? [
          ...legacyNavigationOnlyRoutes,
          {
            element: <AppNavigation />,
            children: routes
          }
        ]
      : routes
  )

  // Routes are hierarchical. If we only used one object, only ONE route could ever be applied.
  // We have one route that always needs to be applied (Navigation) and another that conditionally needs to be applied (StatusChange)
  return (
    <>
      {legacyStatusChangeRoutes}
      {displayRoutes}
    </>
  )
}
