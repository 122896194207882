import * as React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { Formik } from 'formik'
import { ChangeDetails } from '../Domain'
import {
  useAccountChangeDetailsMutation,
  useAccountUserQuery,
  useActorCanModifyEmail
} from '../hooks'
import { useHideEmail } from '../../../hooks'
import { useOperationRequestHandlers } from '@local/ec-app/apollo'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { Button } from '@toasttab/buffet-pui-buttons'
import { getDisplayEmail } from '../../../utils'

interface EmailChangeModalProps {
  isOpen: boolean
  onClose: () => void
}

const EMAIL_UPDATE_SUBHEADER =
  'The new email address will receive instructions on how to log into Toast Payroll.'

export const EmailChangeModal = ({
  isOpen,
  onClose,
  testId = 'email-change-modal'
}: EmailChangeModalProps & TestIdentifiable) => {
  const canModifyEmail = useActorCanModifyEmail()
  const { accountUser } = useAccountUserQuery()
  const { mutateAsync: changeDetailsMutation } =
    useAccountChangeDetailsMutation()
  const { withAll, withSuccessSnackbar } = useOperationRequestHandlers()

  return (
    <Formik<ChangeDetails>
      initialValues={{
        userUuid: accountUser.id,
        username: accountUser.username,
        email: useHideEmail()
          ? getDisplayEmail(accountUser.email)
          : accountUser.email || ''
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={ChangeDetails.schema}
      onSubmit={(values, { setFieldError }) =>
        changeDetailsMutation({
          ...values,
          // Username should always be saved to the email
          username: values.email
        })
          .then(
            withAll(withSuccessSnackbar('Account details saved'), () =>
              onClose()
            )
          )
          .catch((error) => {
            setFieldError('email', error.message)
          })
      }
    >
      {(props) => (
        <Modal
          isOpen={isOpen}
          overflowBehavior='modal'
          shouldCloseOnEsc
          onRequestClose={onClose}
          size='xxl'
          testId={`${testId}`}
        >
          <ModalHeader hideCloseIcon>
            <div data-testid={`${testId}-header-text`}>
              {accountUser.email ? 'Update email' : 'Add email'}
            </div>
          </ModalHeader>

          <form onSubmit={props.handleSubmit}>
            <ModalBody>
              <div className='mb-6 type-default text-secondary'>
                {EMAIL_UPDATE_SUBHEADER}
              </div>
              {accountUser.showUsername() && accountUser.email && (
                <div className='mb-5'>
                  <TextInputField
                    type='text'
                    value={props.values.username}
                    name='username'
                    label='Username'
                    size='lg'
                    testId={`${testId}-username`}
                    invalid={props.errors.username != null}
                    errorText={props.errors.username}
                    disabled={true}
                  />
                </div>
              )}
              <div>
                <TextInputField
                  type='text'
                  value={props.values.email}
                  name='email'
                  label='Email'
                  suffixVariant='iconButton'
                  size='lg'
                  testId={`${testId}-email`}
                  invalid={props.errors.email != null}
                  errorText={props.errors.email}
                  onInput={(e: React.FormEvent<HTMLInputElement>) => {
                    props.setFieldValue('username', e.currentTarget.value)
                  }}
                  disabled={!canModifyEmail}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                testId={`${testId}-cancel`}
                onClick={onClose}
                variant='link'
                className='mr-4'
              >
                Cancel
              </Button>
              <Button
                type='submit'
                testId={`${testId}-save`}
                disabled={props.isSubmitting || !canModifyEmail}
              >
                {accountUser.email ? 'Update' : 'Save'}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
