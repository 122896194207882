const LEADING_DELIMITER_REGEX = /^\//

export const splitPath = (path: string): string[] => {
  return path.replace(LEADING_DELIMITER_REGEX, '').split('/')
}

export const getPathMatchSegments = (
  path: string,
  childPath: string,
  comparator: (partA: string, partB: string) => boolean
): {
  path: string[]
  matchedSegments: number
  totalSegments: number
} => {
  const pathParts = path.replace(LEADING_DELIMITER_REGEX, '').split('/')
  const childPathParts = childPath
    .replace(LEADING_DELIMITER_REGEX, '')
    .split('/')
  const matchedPath: string[] = []

  for (const [index, pathPart] of pathParts.entries()) {
    if (comparator(pathPart, childPathParts[index])) {
      matchedPath.push(pathPart)
    } else {
      break
    }
  }

  return {
    path: matchedPath,
    matchedSegments: matchedPath.length,
    totalSegments: childPathParts.length
  }
}

export const removeUrlSearch = (url: string) => url.split('?')[0] ?? null
