import * as React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { Formik } from 'formik'
import { ChangePassword } from '../Domain'
import { useAccountChangePasswordMutation } from '../hooks'
import { useOperationRequestHandlers } from '@local/ec-app/apollo'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { Button } from '@toasttab/buffet-pui-buttons'
import {
  CHANGE_PASSWORD_FAILED_MESSAGE,
  CHANGE_PASSWORD_SUCCESS_MESSAGE,
  passwordRules
} from './PasswordForm'
import { FieldRequirements } from './FieldRequirements'
import { useIsViewingSelf } from '../../../hooks'

interface PasswordChangeModalProps {
  isOpen: boolean
  onClose: () => void
}

export const PasswordChangeModal = ({
  isOpen,
  onClose,
  testId = 'password-change-modal'
}: PasswordChangeModalProps & TestIdentifiable) => {
  const isViewingSelf = useIsViewingSelf()
  const { mutateAsync: changePassword } = useAccountChangePasswordMutation()
  const { withAll, withSuccessSnackbar, withErrorSnackbar } =
    useOperationRequestHandlers()

  const [passwordFocused, setPasswordFocused] = React.useState(false)

  return (
    <Formik<ChangePassword>
      initialValues={{
        password: ''
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={ChangePassword.schema}
      onSubmit={(values) =>
        changePassword(values).then(
          withAll(withSuccessSnackbar(CHANGE_PASSWORD_SUCCESS_MESSAGE), () =>
            onClose()
          ),
          withErrorSnackbar({
            genericErrorMessage: CHANGE_PASSWORD_FAILED_MESSAGE
          })
        )
      }
    >
      {(props) => (
        <Modal
          isOpen={isOpen}
          overflowBehavior='modal'
          shouldCloseOnEsc
          onRequestClose={onClose}
          size='xxl'
          testId={`${testId}`}
        >
          <ModalHeader hideCloseIcon>
            <div data-testid={`${testId}-header-text`}>Set Password</div>
          </ModalHeader>

          <form onSubmit={props.handleSubmit}>
            <ModalBody>
              <div className='mb-5'>
                <TextInputField
                  type='password'
                  name='password'
                  label='Password'
                  testId={`${testId}-password`}
                  invalid={props.errors.password != null}
                  errorText={props.errors.password}
                  onFocus={() => setPasswordFocused(true)}
                  onBlur={(event) => {
                    setPasswordFocused(event.target.value !== '')
                  }}
                  placeholder={
                    passwordFocused || props.errors.password
                      ? ''
                      : '............'
                  }
                />
              </div>
              {passwordFocused && (
                <div className='mb-6'>
                  <FieldRequirements
                    value={props.values.password}
                    rules={passwordRules}
                  />
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                testId={`${testId}-cancel`}
                onClick={onClose}
                variant='link'
                className='mr-4'
              >
                Cancel
              </Button>
              <Button
                type='submit'
                testId={`${testId}-save`}
                disabled={props.isSubmitting || !isViewingSelf}
              >
                Update
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
