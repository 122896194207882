import * as React from 'react'
// @ts-ignore
import SignatureCanvas from 'react-signature-canvas'

const SignatureCanvasContext =
  React.createContext<React.ComponentType<any>>(SignatureCanvas)

export const SignatureCanvasProvider = ({
  children,
  value = SignatureCanvas
}: React.PropsWithChildren<{ value?: React.ComponentType }>) => (
  <SignatureCanvasContext.Provider value={value}>
    {children}
  </SignatureCanvasContext.Provider>
)

export const useSignatureCanvas = () => React.useContext(SignatureCanvasContext)
