import * as React from 'react'
import { ReactNode } from 'react'
import cx from 'classnames'
import {
  PayCheckIcon,
  ScheduledOrdersIcon,
  SubtractCircleIcon
} from '@toasttab/buffet-pui-icons'
import { DividingLine, Panel } from '@toasttab/buffet-pui-config-templates'
import { ReadonlyText } from '@toasttab/buffet-pui-readonly'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import {
  EarningsInput,
  EarningsInputProps
} from '../earnings-input/EarningsInput'
import { RateDifference } from '@local/rate-difference'
import { TFunction } from 'i18next'
import { AmountWithPayInterval } from '@local/generated/graphql'

export type RetroPayPanelProps = {
  /** the class name to apply to the panel container */
  containerClassName?: string
  /** translation function */
  t: TFunction
  /** the icon component displayed next to the legend. the default is the ScheduledOrdersIcon */
  IconComponent?:
    | typeof PayCheckIcon
    | typeof ScheduledOrdersIcon
    | typeof SubtractCircleIcon
  /** the text to display at the top of the retro pay panel */
  legend: string
  /** the text to display just below the legend */
  caption?: string
  /** the formatted date range for which the retro pay was calculated  */
  paidHoursDateRangeText?: string
  /** the label for the retro pay field */
  retroPayLabel?: string
  /** the formatted string for the amount of retro pay owed */
  retroPay?: string
  /** the label for the pay difference field */
  payDifferenceLabel?: string
  /** the old rate for use in displaying the pay difference field */
  oldRate?: AmountWithPayInterval
  /** the new rate for use in displaying the pay difference field */
  newRate?: AmountWithPayInterval
  /** the label for the hours worked field */
  hoursWorkedLabel?: string
  /** the formatted number of hours used to calculate the retro pay */
  hoursWorkedText?: string
  /** the label for the salary field */
  salaryLabel?: string
  /** the formatted salary per pay period */
  salaryText?: string
  /** props for editing the retro pay*/
  earningsInputProps?: EarningsInputProps
  /** a footer component to put at the bottom of the panel */
  footer?: ReactNode
} & TestIdentifiable

export const RetroPayPanel: React.FunctionComponent<RetroPayPanelProps> = ({
  testId,
  containerClassName,
  t,
  IconComponent = ScheduledOrdersIcon,
  legend,
  caption,
  paidHoursDateRangeText,
  retroPayLabel,
  retroPay,
  payDifferenceLabel,
  oldRate,
  newRate,
  hoursWorkedLabel,
  hoursWorkedText,
  salaryLabel,
  salaryText,
  earningsInputProps,
  footer
}) => {
  // p-6
  return (
    <Panel
      className={cx(
        'space-y-4 text-default !mb-0 !p-6',
        footer && '!pb-4',
        containerClassName
      )}
      testId={testId}
      noElevation
    >
      <div>
        <p className='flex items-center font-semibold'>
          <IconComponent className='text-secondary mr-2.5' />
          {legend}
        </p>
        {caption && <p>{caption}</p>}
      </div>
      {paidHoursDateRangeText && (
        <p className='type-overline text-secondary'>{paidHoursDateRangeText}</p>
      )}
      {retroPayLabel && retroPay && (
        <RetroPay label={retroPayLabel} value={retroPay} />
      )}
      <div className='flex flex-row justify-between'>
        {payDifferenceLabel && oldRate && newRate && (
          <PayDifference
            label={payDifferenceLabel}
            oldRate={oldRate}
            newRate={newRate}
            t={t}
          />
        )}
        {hoursWorkedLabel && hoursWorkedText && (
          <ReadonlyText label={hoursWorkedLabel} value={hoursWorkedText} />
        )}
        {salaryLabel && salaryText && (
          <ReadonlyText label={salaryLabel} value={salaryText} />
        )}
      </div>
      {earningsInputProps && (
        <>
          <DividingLine />
          <EarningsInput {...earningsInputProps} />
        </>
      )}
      {footer && (
        <>
          <DividingLine />
          {footer}
        </>
      )}
    </Panel>
  )
}

type RetroPayProps = {
  label: string
  value: string
}

const RetroPay: React.FunctionComponent<RetroPayProps> = ({ label, value }) => {
  return (
    <div className='space-y-1'>
      <div className='font-semibold'>{label}</div>
      <div className='type-headline-3 font-medium break-all'>{value}</div>
    </div>
  )
}

type PayDifferenceProps = {
  label: string
  oldRate: AmountWithPayInterval
  newRate: AmountWithPayInterval
  t: TFunction
}

const PayDifference: React.FunctionComponent<PayDifferenceProps> = ({
  label,
  oldRate,
  newRate,
  t
}) => {
  return (
    <>
      <ReadonlyText
        label={label}
        value={<RateDifference oldRate={oldRate} newRate={newRate} t={t} />}
      />
    </>
  )
}
