import Logo from './logo'
import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const Header = () => {
  const { t } = useTranslation('employees')

  return (
    <>
      <div className='mb-12 print-only'>
        <Logo />
      </div>
      <div className='flex items-center justify-between mb-12'>
        <div>
          <div className='type-headline-1'>{t('employmentSummary')}</div>
        </div>
        <div className='no-print type-default'>
          <Button variant='secondary' onClick={() => window.print()}>
            Print
          </Button>
        </div>
      </div>
    </>
  )
}
