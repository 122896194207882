import * as yup from 'yup'
import { futureDate, isoDate } from '../../../../shared/yupHelpers'
import { DateTime } from 'luxon'

export class DriversLicense {
  // Schema used to validate api response
  static schema = yup
    .object({
      state: yup
        .string()
        .ensure()
        .stripToNull()
        .nullable()
        .default(null)
        .defined(),
      number: yup
        .string()
        .ensure()
        .stripToNull()
        .nullable()
        .default(null)
        .defined(),
      expirationDate: isoDate().nullable().default(null).defined()
    })
    .nullable()
    .default(null)
    .defined()

  static formSchema = DriversLicense.schema.clone().shape({
    state: yup.string().required('State is a required field'),
    number: yup.string().required('Number is a required field'),
    expirationDate: futureDate('Expiration date is required')
  })

  static of(obj: any): DriversLicense {
    return DriversLicense.copyOf(
      DriversLicense.schema.validateSync(obj, {
        abortEarly: false
      }) as DriversLicense
    )
  }

  static fromForm(obj: any): DriversLicense {
    return DriversLicense.copyOf(
      DriversLicense.formSchema.validateSync(obj, {
        abortEarly: false
      }) as DriversLicense
    )
  }

  static copyOf(valid: DriversLicense): DriversLicense {
    return new DriversLicense(valid.state, valid.number, valid.expirationDate)
  }

  private constructor(
    readonly state: string | null,
    readonly number: string | null,
    readonly expirationDate: DateTime | string | null
  ) {}
}
