import * as yup from 'yup'

const w4StatusSchema = {
  complete: yup.bool().required(),
  link: yup.string().required()
}

interface W4Status {
  complete: boolean
  link: string
}

export class W4CompletionStatus {
  static schema = yup.object({
    federal: yup.object(w4StatusSchema).required(),
    state: yup.object(w4StatusSchema).required()
  })

  static of(obj: any): W4CompletionStatus {
    return W4CompletionStatus.copyOf(
      <W4CompletionStatus>(
        W4CompletionStatus.schema.validateSync(obj, { abortEarly: false })
      )
    )
  }

  static copyOf(valid: W4CompletionStatus): W4CompletionStatus {
    return valid
  }

  constructor(readonly federal: W4Status, readonly state: W4Status) {}
}
