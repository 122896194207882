import * as React from 'react'
import Skeleton from 'react-loading-skeleton'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

type ParagraphSkeletonProps = {
  repeat: number
  height: number
  className?: string
  lastLineShorter?: boolean
} & TestIdentifiable

export const ParagraphSkeleton = ({
  repeat,
  className,
  height,
  testId,
  lastLineShorter
}: ParagraphSkeletonProps) => (
  <div data-testid={testId}>
    {Array.from({ length: repeat }, (v, i) => (
      <div key={i} className={className}>
        {lastLineShorter && i !== 0 && i === repeat - 1 ? (
          <div className='w-2/3'>
            <Skeleton height={height} />
          </div>
        ) : (
          <Skeleton height={height} />
        )}
      </div>
    ))}
  </div>
)

ParagraphSkeleton.defaultProps = {
  className: undefined,
  repeat: 1,
  testId: undefined
}
