import * as React from 'react'
import './styles.css'
import { usePayHistoryReport } from './stores/PayHistoryReportStore'

import { FocusBody } from '@toasttab/buffet-pui-config-templates'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { CloseIcon } from '@toasttab/buffet-pui-icons'
import { useKeyPressAction } from '@local/use-keypress'
import { useHidePendo } from '@local/use-pendo'
import { useScrollLock } from '@local/use-scroll-lock'

export const PayHistoryReport = () => {
  const { data: query, isLoading, isOpen, close } = usePayHistoryReport()

  useKeyPressAction('Escape', close)
  useHidePendo(isOpen)
  useScrollLock(isOpen)

  if (!isOpen) return null
  if (isLoading) return null
  if (query?.data === undefined) return null

  return (
    <div className='pt-12 fixed top-0 left-0 right-0 bottom-0 z-10 bg-gray-0'>
      <FocusBody className='relative'>
        <IconButton
          className='absolute -ml-12 -mt-1'
          icon={<CloseIcon size='md' className='text-secondary' />}
          onClick={close}
        />
        <div className='type-headline-3 mb-6 border-b pb-4'>
          Employee pay history detail report
        </div>
        <CardContainer>
          <div
            id='view-payroll-history-report'
            dangerouslySetInnerHTML={{
              __html: query.data.PayHistoryReportHTML
            }}
          />
        </CardContainer>
      </FocusBody>
    </div>
  )
}
