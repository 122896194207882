import * as React from 'react'
import { useField } from 'formik'
import { CurrencyField, SelectField } from '@toasttab/buffet-pui-forms'
import { NumberInputField } from '@toasttab/buffet-pui-forms'
import { Button } from '@toasttab/buffet-pui-buttons'
import { SubtractCircleIcon } from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { TrackId } from '@local/track'
import { Currency } from '@local/generated/graphql'

export type EarningsInputProps = {
  earningCodeProps: {
    /** data-toast-track-id for the earning code input field*/
    'data-toast-track-id'?: TrackId
    /** the list of earning code options */
    options: { label: string; value: string; isHourly: boolean }[]
    /** the label for the earning code select field */
    label: string
    /** the form values name for the earning code select field */
    name: string
    /** the placeholder for the earning code select field */
    placeholder: string
  } & TestIdentifiable
  hoursProps: {
    /** data-toast-track-id for the hoursWorked input field */
    'data-toast-track-id'?: TrackId
    /** the label for the hoursWorked input field */
    label: string
    /** the form values name for the hoursWorked input field */
    name: string
    /** the suffix for the hoursWorked input field */
    suffix: string
    /** the helper text for the hoursWorked input field */
    helperText?: string
  } & TestIdentifiable
  hourlyRateProps: {
    /** data-toast-track-id for the hourly rate input field */
    'data-toast-track-id'?: TrackId
    /** the currency of the hourly rate input field */
    currency: Currency
    /** the helper text for the hourly rate input field */
    label: string
    /** the form values name for the hourly rate input field */
    name: string
    /** the suffix for the hourly rates input field */
    suffix: string
  } & TestIdentifiable
  lumpSumProps: {
    /** data-toast-track-id for the lumpSum (Gross Pay || Additional Pay) input field */
    'data-toast-track-id'?: TrackId
    /** the currency of the lumpSum (Gross Pay || Additional Pay) input field */
    currency: Currency
    /** the helper text for the lumpSum (Gross Pay || Additional Pay) input field */
    helperText?: string
    /** the label for the lumpSum (Gross Pay || Additional Pay) input field */
    label: string
    /** the form values name for the lumpSum (Gross Pay | Additional Pay) input field */
    name: string
  } & TestIdentifiable
  buttonProps?: {
    /** button track id for reporting */
    'data-toast-track-id'?: TrackId
    /** the label for the button */
    label?: string
    /** the action to be taken when button is clicked */
    onClick?: VoidFunction
  } & TestIdentifiable
}

export const EarningsInput: React.FunctionComponent<EarningsInputProps> = ({
  earningCodeProps,
  hoursProps,
  hourlyRateProps,
  lumpSumProps,
  buttonProps
}) => {
  const [field] = useField(earningCodeProps)

  const isHourly = field?.value?.isHourly

  const showEarningFields = isHourly !== undefined

  return (
    <div className='space-y-3'>
      <SelectField
        required
        {...earningCodeProps}
        itemToValue={(item) => {
          return item
        }}
      />

      {hoursProps && showEarningFields && isHourly && (
        <NumberInputField required {...hoursProps} allowNegative={false} />
      )}

      {hourlyRateProps && showEarningFields && isHourly && (
        <CurrencyField
          required
          {...hourlyRateProps}
          allowNegative={false}
          prefix={undefined}
        />
      )}

      {lumpSumProps && showEarningFields && !isHourly && (
        <CurrencyField required {...lumpSumProps} allowNegative={false} />
      )}

      {buttonProps && (
        <Button
          data-toast-track-id={buttonProps['data-toast-track-id']}
          testId={buttonProps.testId}
          variant='text-link'
          className='pt-0 mt-0'
          iconLeft={<SubtractCircleIcon accessibility='decorative' />}
          onClick={buttonProps.onClick}
        >
          {buttonProps.label}
        </Button>
      )}
    </div>
  )
}
