import * as React from 'react'
import { useMemoizedObject } from '@toasttab/buffet-utils'
import { OverviewCardErrorBoundary } from './OverviewCardErrorBoundary'
import { OverviewCardSuspenseBoundary } from './OverviewCardSuspenseBoundary'

export const OverviewCardContext = React.createContext<{
  onActionClick?: () => void
  cardTitle: string
  loadingView?: React.ReactNode
  errorText: string
}>({
  cardTitle: '',
  errorText: '',
  loadingView: null,
  onActionClick: () => {}
})

export const useOverviewCardContext = () =>
  React.useContext(OverviewCardContext)

export const OverviewCardBoundary = ({
  cardTitle,
  onActionClick,
  loadingView,
  errorText,
  children
}: React.PropsWithChildren<React.ContextType<typeof OverviewCardContext>>) => {
  const provider = useMemoizedObject({
    cardTitle,
    errorText,
    loadingView,
    onActionClick
  })

  return (
    <OverviewCardContext.Provider value={provider}>
      <OverviewCardErrorBoundary>
        <OverviewCardSuspenseBoundary>{children}</OverviewCardSuspenseBoundary>
      </OverviewCardErrorBoundary>
    </OverviewCardContext.Provider>
  )
}
