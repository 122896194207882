import * as React from 'react'
import { ComponentType } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { Button } from '@toasttab/buffet-pui-buttons'
import { CurrencyField } from '@toasttab/buffet-pui-forms'
import { Modal } from '@toasttab/buffet-pui-modal'
import { noop } from '@toasttab/buffet-utils'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  Currency,
  usePayGroupCalendarByDateRangeQuery
} from '@local/generated/graphql'
import { trackPayChange } from '@local/track'
import { TerminatedEmployeeAlert } from '../retroPay'
import { LoadSpinner } from '../../../components'
import { PayChangeDatePickerField } from './PayChangeDatePickerField'
import { RetroDeductionPanel, RetroPayDisbursementOptions } from '../retroPay'
import { useEmployeeId, useIsViewingSelf } from '../../../../../../hooks'
import { normalizeAndSortPayPeriods } from '../../utils'
import { useShowRetroPayDisbursementOptions } from '../retroPay/utils'
import { PayChangeSchema } from '../../domain'
import { useJobAssignmentsStore } from '../../../JobAssignmentsStore'
import { usePayChangeModalStore } from '../../PayChangeModalStore'
import { PayChangeThresholdAlert } from './components'

export type PayChangeRetroPayPanelProps = {
  employeeId: string
  jobAssignmentId?: string
}

type PayChangeModalContentsProps = {
  title: string
  subtitle: string
  rateLabel: string
  RetroPayPanel: ComponentType<PayChangeRetroPayPanelProps>
  jobAssignmentId?: string
  employmentStatus?: string
  payGroupId?: string | null
}

export const PayChangeModalContents: React.FunctionComponent<
  PayChangeModalContentsProps
> = ({
  title,
  subtitle,
  rateLabel,
  RetroPayPanel,
  jobAssignmentId,
  employmentStatus,
  payGroupId
}) => {
  const { t } = useTranslation('employees')

  const employeeId = useEmployeeId()

  const { data, loading } = usePayGroupCalendarByDateRangeQuery({
    // todo team-765 update here when payGroupId is not-nullable from the findEmployeeById response
    variables: {
      payGroupId: payGroupId ? payGroupId : '',
      startDate: null,
      endDate: null
    }
  })
  const payPeriods = normalizeAndSortPayPeriods(
    data?.payGroupCalendarByDateRange
  )

  const employeeFirstName = useJobAssignmentsStore((x) => x.employeeFirstName)
  const saving = usePayChangeModalStore((x) => x.saving)

  const { initialValues, values, setFieldTouched, setFieldValue } =
    useFormikContext<PayChangeSchema>()

  return (
    <>
      <Modal.Header>
        <div data-testid='pay-change-modal-header'>{title}</div>
      </Modal.Header>
      <Modal.Body data-testid='pay-change-modal-contents' className='relative'>
        <div className='grid gap-y-6'>
          <div
            className='type-default font-normal text-secondary'
            data-testid='pay-change-modal-sub-header'
          >
            {subtitle}
          </div>
          {loading ? (
            <LoadSpinner message={t('loading', 'Loading...')} />
          ) : saving ? (
            <LoadSpinner
              message={t('savingPayChange', 'Saving pay change...')}
            />
          ) : (
            <>
              <TerminatedEmployeeAlert
                employeeFirstName={employeeFirstName}
                employmentStatus={employmentStatus}
              />
              <PayChangeCurrencyField label={rateLabel} />
              <PayChangeDatePickerField
                employeeFirstName={employeeFirstName}
                employmentStatus={employmentStatus}
                sortedPayPeriods={payPeriods || []}
              />
              <RetroPayPanel
                employeeId={employeeId}
                jobAssignmentId={jobAssignmentId}
              />
              <RetroPayDisbursementOptions
                employeeFirstName={employeeFirstName}
              />
              <RetroDeductionPanel employeeFirstName={employeeFirstName} />
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='flex flex-grow flex-col gap-y-4'>
          <PayChangeThresholdAlert
            initialRate={initialValues.rate}
            newRate={values.rate}
            rateConfirmed={values.rateConfirmed}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
          <Actions employmentStatus={employmentStatus} />
        </div>
      </Modal.Footer>
    </>
  )
}

const PayChangeCurrencyField: React.FunctionComponent<{
  label: string
}> = ({ label }) => {
  return (
    <CurrencyField
      {...trackPayChange('rate')}
      testId='pay-change-modal-pay-rate-input'
      label={label}
      name='rate'
      required={true}
      // removal of hardcoded currency is blocked by support of internationalization of payroll processing
      currency={Currency.USD}
      decimalScale={2}
      thousandSeparator
      allowNegative={false}
    />
  )
}

const Actions: React.FunctionComponent<{ employmentStatus?: string }> = ({
  employmentStatus
}) => {
  const { t } = useTranslation('employees')

  const { initialValues, isValid, setFieldValue, values } =
    useFormikContext<PayChangeSchema>()

  const showDisbursementOptions =
    useShowRetroPayDisbursementOptions(employmentStatus)
  const shouldSave =
    !showDisbursementOptions || !(values.attachEarnings === 'true')

  const label = shouldSave ? t('save', 'Save') : t('next', 'Next')

  const loading = usePayChangeModalStore((x) => x.loading)
  const saving = usePayChangeModalStore((x) => x.saving)
  const dirty =
    Number(initialValues.rate) !== Number(values.rate) &&
    (showDisbursementOptions ? values.attachEarnings !== undefined : true)
  const disabled = loading || saving || !dirty || !isValid

  const retroPay = usePayChangeModalStore((x) => x.retroPay)
  const isHourlyEmployee = usePayChangeModalStore((x) => x.isHourly)

  const onClick = shouldSave
    ? noop
    : () => {
        setFieldValue('currentFormId', 'attach-earnings-form')
        if (!values.earnings) {
          setFieldValue('earnings', {
            retro: {
              lumpSumAmount: retroPay?.retroPay.amount,
              hourlyRate: isHourlyEmployee
                ? retroPay?.payChangeDifference?.amount?.amount
                : '',
              hoursWorked:
                isHourlyEmployee && !!retroPay && 'hoursWorked' in retroPay
                  ? retroPay?.hoursWorked
                  : '',
              currency: retroPay?.retroPay.currency
            },
            earnings: []
          })
        }
      }
  const type = shouldSave ? 'submit' : undefined

  return (
    <Button
      {...trackPayChange('save')}
      testId='pay-change-modal-save'
      className='flex-grow'
      disabled={disabled}
      form='pay-change-form'
      onClick={onClick}
      size='lg'
      type={type}
    >
      {label}
    </Button>
  )
}

export const usePayChangeSnackBar = () => {
  const { t } = useTranslation('employees')
  const employeeFirstName = useJobAssignmentsStore((x) => x.employeeFirstName)

  const {
    showSuccessSnackBar: baseShowSuccessSnackBar,
    showErrorSnackBar: baseShowErrorSnackBar
  } = useSnackBar()
  const isViewingSelf = useIsViewingSelf()

  return {
    showSuccessSnackBar: () => {
      baseShowSuccessSnackBar(
        isViewingSelf
          ? t('changePaySavedSelf')
          : t('changePaySaved', {
              firstName: employeeFirstName
            }),
        {
          testId: 'pay-change-saved-successfully-snackbar'
        }
      )
    },
    showErrorSnackBar: () => {
      baseShowErrorSnackBar(
        isViewingSelf
          ? t('changePayErrorSelf')
          : t('changePayError', {
              firstName: employeeFirstName
            }),
        {
          testId: 'pay-change-saved-error-snackbar'
        }
      )
    }
  }
}
