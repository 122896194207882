import * as React from 'react'
import { Formik } from 'formik'
import { ChangePassword } from '../Domain'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { FieldRequirements, FieldRule } from './FieldRequirements'
import { Button } from '@toasttab/buffet-pui-buttons'
import { FormContainer } from './FormContainer'
import { Link } from 'react-router-dom'
import {
  useAccountChangePasswordMutation,
  useAccountResetPasswordMutation
} from '../hooks'
import {
  useCompanyCode,
  useEmployee,
  useEmployeeId,
  useIsViewingSelf
} from '../../../hooks'
import { SendArrowIcon } from '@toasttab/buffet-pui-icons'
import { useOperationRequestHandlers } from '@local/ec-app/apollo'
import { useNavigateToAccountPage } from '../../personal/hooks'

export const CHANGE_PASSWORD_FAILED_MESSAGE = 'Failed to save password.'
export const CHANGE_PASSWORD_SUCCESS_MESSAGE = 'Password changed'

export const passwordRules: Array<FieldRule> = [
  FieldRule.of({
    label: 'One number',
    isMandatory: true,
    condition: (value: string) => /[0-9]+/g.test(value)
  }),
  FieldRule.of({
    label: 'One letter',
    isMandatory: true,
    condition: (value: string) => /[a-zA-Z]+/g.test(value)
  }),
  FieldRule.of({
    label: '7 characters minimum',
    isMandatory: false,
    condition: (value: string) => value.length >= 7
  })
]

export const PasswordResetForm = () => {
  const navigateToAccountPage = useNavigateToAccountPage()
  const employee = useEmployee()
  const { status, mutateAsync: resetPasswordMutation } =
    useAccountResetPasswordMutation()
  const { showErrorSnackBar } = useSnackBar()
  const { withErrorSnackbar, withSuccessSnackbar, withAll } =
    useOperationRequestHandlers()

  const onResetPassword = async () => {
    if (employee?.overview?.emailAddress) {
      resetPasswordMutation(employee.overview.emailAddress).then(
        withAll(withSuccessSnackbar('Password reset sent'), () =>
          navigateToAccountPage(true)
        ),
        withErrorSnackbar({
          genericErrorMessage: 'Failed to reset password. Please try again'
        })
      )
    } else {
      showErrorSnackBar('Loading employee, please wait')
    }
  }

  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()

  return (
    <FormContainer testId='reset-password-form' title='Reset Password'>
      <div className='p-5 mb-6 bg-white border rounded-sm'>
        To send a password reset email, you must update a user's username to
        match their email address. You can do so by visiting &nbsp;
        <Link to={`/${companyCode}/employees/${employeeId}/account/details`}>
          update account details
        </Link>
        &nbsp; on the previous page.
      </div>
      <div className='flex justify-between'>
        <Button onClick={() => navigateToAccountPage(true)} variant='secondary'>
          Cancel
        </Button>
        <Button
          type='submit'
          testId='save'
          onClick={onResetPassword}
          disabled={status === 'loading'}
          iconLeft={<SendArrowIcon />}
        >
          Send reset password link
        </Button>
      </div>
    </FormContainer>
  )
}

export const PasswordChangeForm = () => {
  const [passwordFocused, setPasswordFocused] = React.useState(false)
  const { mutateAsync: changePassword } = useAccountChangePasswordMutation()
  const { withErrorSnackbar, withSuccessSnackbar, withAll } =
    useOperationRequestHandlers()
  const navigateToAccountPage = useNavigateToAccountPage()

  return (
    <FormContainer title='Set Password'>
      <Formik<ChangePassword>
        initialValues={{
          password: ''
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={ChangePassword.schema}
        onSubmit={(values) =>
          changePassword(values).then(
            withAll(withSuccessSnackbar(CHANGE_PASSWORD_SUCCESS_MESSAGE), () =>
              navigateToAccountPage(false)
            ),
            withErrorSnackbar({
              genericErrorMessage: CHANGE_PASSWORD_FAILED_MESSAGE
            })
          )
        }
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className='mb-6'>
              <TextInputField
                type='password'
                name='password'
                label='Password'
                testId='password'
                invalid={props.errors.password != null}
                errorText={props.errors.password}
                onFocus={() => setPasswordFocused(true)}
                onBlur={(event) => {
                  setPasswordFocused(event.target.value !== '')
                }}
                placeholder={
                  passwordFocused || props.errors.password ? '' : '............'
                }
              />
            </div>
            {passwordFocused && (
              <div className='mb-6'>
                <FieldRequirements
                  value={props.values.password}
                  rules={passwordRules}
                />
              </div>
            )}
            <div className='flex justify-between'>
              <Button
                onClick={() => navigateToAccountPage(true)}
                variant='secondary'
              >
                Cancel
              </Button>
              <Button type='submit' testId='save' disabled={props.isSubmitting}>
                Save changes
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </FormContainer>
  )
}

export const PasswordForm = () => {
  const isViewingSelf = useIsViewingSelf()

  if (isViewingSelf) {
    return <PasswordChangeForm />
  } else {
    return <PasswordResetForm />
  }
}
