import React, { createRef, useEffect, useState } from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { Alert } from '@toasttab/buffet-pui-alerts'
import {
  ChevronRightIcon,
  DoneIcon,
  RestaurantMenuIcon,
  WarningOutlineDisplayIcon
} from '@toasttab/buffet-pui-icons'
import { useQuery, useMutation } from 'react-query'
import { Button } from '@toasttab/buffet-pui-buttons'
import { TableRowContent, W4CompletionStatus } from './domain'
import { ActionTablePage } from './components'
import Skeleton from 'react-loading-skeleton'
import { useWindowProvider, GeneralErrorBoundary } from '@local/ec-app'
import { useEmployeeId, useCompanyCode } from '../hooks'
import { useApi } from '../../ApiProvider'
import { isApolloError } from '@apollo/client'
import { OperationError } from '@local/ec-app/apollo'
import { useTranslation } from 'react-i18next'
import { EmployeeOnboardingError } from '../../error/EmployeeErrorBoundary'
import './css/spf.css'
import './css/spf-responsive.css'

const W4FormsFrameLoader = () => (
  <Alert variant='neutral' className='w-full'>
    Loading forms
  </Alert>
)

const W4FormsFrameError = () => (
  <Alert variant='error' className='w-full'>
    Error loading forms
  </Alert>
)

export const W4FormsFrame = () => {
  const frameRef = createRef<HTMLIFrameElement>()
  const [frameHeight, setFrameHeight] = useState(700)
  const [noW4sToDo, setNoW4sToDo] = useState(false)
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()
  const api = useApi()

  const { data: registration } = useQuery(
    ['registration', companyCode, employeeId],
    () => api.getRegistration(companyCode, employeeId),
    {
      staleTime: Infinity
    }
  )

  /* The iFrame has dynamic content and in order to have a non-scrolling, but correctly sized frame, we need to check
   * the size of the contained document and resize the iFrame accordingly. There is no way to do this except check the
   * height of the element on an interval, which is done here using useQuery.
   */
  useQuery(
    'frameSizer',
    () => frameRef.current?.contentWindow?.document?.body?.scrollHeight || 0,
    {
      onSuccess: (height) => {
        if (height !== frameHeight) {
          if (frameHeight > 700) {
            setFrameHeight(height)
          } else {
            setFrameHeight(700)
          }
        }
      },
      refetchInterval: 100,
      suspense: false
    }
  )

  useEffect(() => {
    const current = frameRef.current
    if (current) {
      if (frameHeight > 0) {
        current.style.height = frameHeight + 'px'
      } else {
        current.style.height = 'auto'
      }
    }
  }, [frameHeight, frameRef])

  const checkForNoW4s = () => {
    if (frameRef.current) {
      const iframe = frameRef.current

      if (iframe && iframe.contentWindow) {
        const noW4Required = Array.from(
          iframe.contentWindow.document.getElementsByTagName('b')
        ).some(
          (bTag: any) =>
            bTag.textContent === 'There are no required sections to complete!'
        )
        if (noW4Required) {
          iframe.remove()
          setNoW4sToDo(true)
        } else {
          iframe.hidden = false
        }
      }
    }
  }

  const postProcessIframe = () => {
    checkForNoW4s()
  }

  if (noW4sToDo) {
    return <NoW4sTodo />
  }

  return (
    <div className='w-full h-full'>
      <iframe
        src={registration!.registrationUrl}
        ref={frameRef}
        name='forms'
        id='forms'
        title='W4Onboarding'
        onLoad={postProcessIframe}
        className='w-full h-full p-0 m-0'
      />
    </div>
  )
}

const W4Loader = () => (
  <div className='flex-grow p-8 md:p-12'>
    <div className='pb-5 mt-0 font-bold border-black border-b-3 type-headline-2'>
      Marking your W-4 as complete...
    </div>
    <div className='pb-5 mt-0 type-large'>
      <Skeleton count={2} />
    </div>
    <Skeleton width={218} height={40} />
  </div>
)

export const W4CompletePage = () => (
  <GeneralErrorBoundary
    fallback={
      <W4Error
        errorTitle='There was an issue marking your W-4 as complete.'
        errorMessage='Please reach out to your HR representative for assistance. You can also try refreshing the page.'
      />
    }
  >
    <React.Suspense fallback={<W4Loader />}>
      <W4Complete />
    </React.Suspense>
  </GeneralErrorBoundary>
)

export const NoW4sTodo = () => {
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()
  const completeUrl = `/${companyCode}/employees/${employeeId}/forms/w4/complete`
  const window = useWindowProvider()

  return (
    <div className='flex-grow pt-1 md:pt-6'>
      <div className='pb-5 mt-0 font-bold border-black border-b-3 type-headline-2'>
        No State W-4s to Fill Out
      </div>
      <div className='pb-5 mt-0 type-large'>
        Your state does not require you to fill out State W-4 forms. Click
        continue to mark this step complete and continue onboarding.
      </div>
      <Button
        iconRight={<ChevronRightIcon />}
        onClick={() => (window.top!.location.href = completeUrl)}
      >
        Continue
      </Button>
    </div>
  )
}

export const W4Complete = () => {
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()
  const window = useWindowProvider()
  const api = useApi()

  const { mutate: completeW4 } = useMutation(() =>
    api.completeW4(companyCode, employeeId)
  )

  React.useEffect(() => {
    completeW4()
  }, [completeW4])

  return (
    <div className='flex-grow p-8 md:p-12'>
      <div className='pb-5 mt-0 font-bold border-black border-b-3 type-headline-2'>
        W-4 Complete
      </div>
      <div className='pb-5 mt-0 type-large'>
        You've completed your State W-4 forms. Click the button below to return
        to the dashboard.
      </div>
      <Button
        iconRight={<ChevronRightIcon />}
        onClick={() =>
          (window.top!.location.href = `/${companyCode}/dashboard`)
        }
      >
        Return to dashboard
      </Button>
    </div>
  )
}

export const W4LandingPage = () => {
  const companyCode = useCompanyCode()
  const employeeId = useEmployeeId()
  const api = useApi()

  const { isError, isLoading, data } = useQuery<W4CompletionStatus, Error>(
    ['w4completions', companyCode, employeeId],
    async () => api.getW4CompletionStatus(companyCode, employeeId),
    {
      suspense: false,
      useErrorBoundary: false
    }
  )

  const createRows = (w4CompletionStatus: W4CompletionStatus) => {
    return [
      new TableRowContent(
        'Federal W-4',
        'Your federal withholdings',
        w4CompletionStatus.federal.complete ? (
          <DoneIcon />
        ) : (
          <RestaurantMenuIcon />
        ),
        w4CompletionStatus.federal.link
      ),
      new TableRowContent(
        'State W-4',
        'Your state withholdings',
        w4CompletionStatus.state.complete ? (
          <DoneIcon />
        ) : (
          <RestaurantMenuIcon />
        ),
        w4CompletionStatus.state.link
      )
    ]
  }

  const goBack = () => {
    window.location.href = `/${companyCode}/dashboard`
  }

  const rows = data ? createRows(data) : []

  return (
    <ActionTablePage
      testId='w4-onboading-app-action-table'
      backButtonFn={goBack}
      title='Your W-4 Progress'
      subtitle='Make sure you’re taxed correctly'
      allActionsComplete={
        (data?.federal.complete && data?.state.complete) || false
      }
      allActionsCompleteFn={goBack}
      allActionsCompleteBtnTitle='Go back to dashboard'
      tableRowContent={rows}
      tableRowType='W-4 Form'
      isLoading={isLoading}
      isError={isError}
    />
  )
}

interface LocalizedW4ErrorProps {
  errorTitle?: string
  errorMessage?: string
  errorList?: string[]
  buttonLink?: string
  buttonText?: string
}

const W4Error = (props: React.PropsWithChildren<LocalizedW4ErrorProps>) => {
  const { t } = useTranslation('employees')

  return (
    <div className='flex-grow p-8 md:p-12'>
      <div className='pb-5 mt-0 font-bold border-black border-b-3 type-headline-2 flex items-center'>
        <WarningOutlineDisplayIcon />
        {props.errorTitle && t(props.errorTitle)}
      </div>
      <div className='pb-5 mt-0 type-large'>
        {props.errorMessage && t(props.errorMessage)}
        {props.errorList && (
          <ul>
            <br />
            {props.errorList.map((errorText) => (
              <li>&bull; {t(errorText)}</li>
            ))}
          </ul>
        )}
      </div>

      {props.buttonLink && (
        <Button
          onClick={() =>
            (window.top!.location.href = t(String(props.buttonLink)))
          }
          iconRight={<ChevronRightIcon />}
        >
          {props.buttonText && t(props.buttonText)}
        </Button>
      )}
      <div>{props.children}</div>
    </div>
  )
}

const mapErrorToW4Error = (
  apolloServerError: OperationError<Error, Record<string, any>>,
  employeeUuid: string,
  companyCode: string
): LocalizedW4ErrorProps => {
  const errorResult = apolloServerError.result!
  const errorType = errorResult.type || ''

  if (errorType.includes('employeeMissingRequiredInformation')) {
    return {
      errorTitle: 'informationNeededTitle',
      errorMessage: 'taxEmployeeErrorInformationNeededMessage',
      errorList: errorResult.errors,
      buttonLink: `/${companyCode}/employees/${employeeUuid}/profile`,
      buttonText: 'goToYourProfile'
    }
  } else if (errorType.includes('customerMissingRequiredInformation')) {
    return {
      errorMessage: 'taxCustomerErrorInformationNeededMessage'
    }
  } else if (errorResult.errors.includes('humanResources.wrongZipCode')) {
    return {
      errorMessage: 'taxWrongZipCode'
    }
  } else if (
    errorResult.errors.includes('humanResources.missingFilingStatus')
  ) {
    return {
      errorMessage: 'taxMissingFilingStatus'
    }
  }

  console.error('Unhandled W4 error', JSON.stringify(errorResult))
  return {
    errorMessage: 'taxUnhandledError'
  }
}

export const W4OnboardingApp = () => {
  const employeeUuid = useEmployeeId()
  const companyCode = useCompanyCode()
  const handler = (error: Error) => {
    const isApolloNetworkError =
      isApolloError(error) &&
      OperationError.is(error.networkError) &&
      error.networkError.result

    if (isApolloNetworkError) {
      const serverError: OperationError = error.networkError as OperationError<
        Error,
        Record<string, any>
      >
      const w4Error = mapErrorToW4Error(serverError, employeeUuid, companyCode)

      return {
        render: () =>
          serverError.result?.status === 500 &&
          serverError.result?.message?.includes(
            '[NamedValidationError(name=username, error=Required)])'
          ) ? (
            <EmployeeOnboardingError reset={() => {}} />
          ) : (
            <W4Error {...w4Error} />
          )
      }
    }

    return {
      render: () => <W4FormsFrameError />
    }
  }

  return (
    <CardContainer testId='w4-onboarding-container' className='flex-grow'>
      <div className='pb-5 mt-0 font-bold border-black border-b-3 type-headline-2'>
        W-4 Filing
      </div>
      <GeneralErrorBoundary
        fallback={<W4FormsFrameError />}
        toKey={(error: Error) => error.name}
        handler={handler}
      >
        <React.Suspense fallback={<W4FormsFrameLoader />}>
          <W4FormsFrame />
        </React.Suspense>
      </GeneralErrorBoundary>
    </CardContainer>
  )
}
