import * as React from 'react'
import { FormikValues } from 'formik'
import { Address } from '@toasttab/buffet-patterns-address-lookup'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { NumberInput } from '@toasttab/buffet-pui-text-input'
import { useFeature } from '@toasttab/ec-features'
import { TextInputFormField } from './TextInputFormField'
import { AddressLookupForm } from './AddressLookupForm'
import { FormikErrors } from 'formik/dist/types'
import { formSectionHeader } from '../shared/formHelpers'
import { SelectFormField } from './SelectFormField'
import { LocalityCodeOption, Option } from '../../../domain'
import { PostalAddress } from '../domain'

export const USA = 'USA'
export const US = 'US'

export interface AddressValues {
  address?: string
  address2?: string
  administrativeArea?: string
  country?: string
  locality?: string
  postalCode?: string
  localityGnisCode?: string
}

export interface AddressFormProps {
  label: string
  instructions: string
  countries: Array<Option>
  statesInUnitedStates: Array<Option>
  values: FormikValues
  setFieldValue: (field: string, value: any) => any
  errors: FormikErrors<PostalAddress> | string
  addressType: 'home' | 'mailing'
  localityGnisCodes?: LocalityCodeOption[]
  required?: boolean
}

export const AddressForm = ({
  label,
  instructions,
  values,
  setFieldValue,
  countries,
  localityGnisCodes = [],
  statesInUnitedStates,
  errors,
  addressType,
  required
}: AddressFormProps) => {
  const isUsingAddressAutocomplete = useFeature(
    'ec-hr-employee-profile-address-autocomplete',
    false
  )
  let errorDetails: FormikErrors<PostalAddress> = {}
  let formErrorMsg = null
  if (typeof errors === 'string') {
    formErrorMsg = errors
  } else {
    errorDetails = errors
  }
  const [hasHadPostalCode, setHasHadPostalCode] = React.useState(false)

  const setAddress = (add: Address) => {
    setFieldValue('localityGnisCode', null)
    if (
      add.stateCode &&
      add.address1 &&
      add.city &&
      add.zipCode &&
      add.country
    ) {
      const country = add.country === US ? USA : add.country
      setFieldValue('address', add.address1)
      setFieldValue('address2', add.address2)
      setFieldValue('locality', add.city)
      setFieldValue('administrativeArea', add.stateCode)
      setFieldValue('postalCode', add.zipCode)
      setFieldValue('country', country)
    } else {
      setFieldValue('address', undefined)
      setFieldValue('address2', undefined)
      setFieldValue('locality', undefined)
      setFieldValue('administrativeArea', undefined)
      setFieldValue('postalCode', undefined)
      setFieldValue('country', undefined)
    }
  }

  const hasPostalCode = values.postalCode && values.postalCode.match(/[0-9]{5}/)
  React.useEffect(() => {
    setHasHadPostalCode(hasHadPostalCode || hasPostalCode)
  }, [hasHadPostalCode, hasPostalCode])

  return (
    <div>
      {formSectionHeader(label, instructions)}
      {formErrorMsg && (
        <div className='mb-6' data-testid='address-error'>
          <Alert variant='error' className='w-full'>
            {formErrorMsg}
          </Alert>
        </div>
      )}
      {isUsingAddressAutocomplete && (
        <AddressLookupForm
          label='Address'
          testId={`${addressType}-address-lookup`}
          placeholder='Please enter an address'
          value={values}
          onChange={(value) => setAddress(value)}
          errorMessage={errorDetails.address}
        />
      )}
      <TextInputFormField
        required={required}
        label='Street Address'
        value={values.address}
        disabled={!values.address && isUsingAddressAutocomplete}
        onChange={(value) => setFieldValue('address', value)}
        errorMessage={errorDetails.address}
        testId={`${addressType}-line-1`}
        containerClassName='mb-6 w-full xl:w-1/2'
      />
      <TextInputFormField
        label='Street Address 2'
        value={values.address2}
        disabled={!values.address && isUsingAddressAutocomplete}
        onChange={(value) => setFieldValue('address2', value)}
        errorMessage={errorDetails.address2}
        optional={true}
        testId={`${addressType}-line-2`}
        containerClassName='mb-6 w-full xl:w-1/2'
      />
      <TextInputFormField
        required={required}
        label='City'
        value={values.locality}
        disabled={!values.locality && isUsingAddressAutocomplete}
        onChange={(value) => setFieldValue('locality', value)}
        errorMessage={errorDetails.locality}
        testId={`${addressType}-city`}
      />
      <SelectFormField
        required={required}
        label='State'
        options={statesInUnitedStates}
        selectedVal={values.administrativeArea}
        disabled={!values.administrativeArea && isUsingAddressAutocomplete}
        onChange={(value) => setFieldValue('administrativeArea', value)}
        errorText={errorDetails.administrativeArea}
        id={`${addressType}-state`}
        autoComplete='address-level1'
      />
      <NumberInput
        required={required}
        label='Zip'
        value={values.postalCode}
        disabled={!values.postalCode && isUsingAddressAutocomplete}
        onChange={(event: any) => {
          setFieldValue('localityGnisCode', null)
          setFieldValue('postalCode', event.formattedValue)
        }}
        errorText={errorDetails.postalCode}
        allowEmptyFormatting={true}
        format='#####'
        name='zip'
        testId={`input-${addressType}-zip`}
        invalid={!!errorDetails.postalCode}
        className='tabular-nums'
        containerClassName='mb-6 w-full lg:w-1/2 xl:w-1/3'
      />
      <SelectFormField
        required={required}
        label='Country'
        options={countries}
        selectedVal={values.country}
        disabled={!values.country && isUsingAddressAutocomplete}
        onChange={(value) => setFieldValue('country', value)}
        id={`${addressType}-country`}
        errorText={errorDetails.country}
        autoComplete='country-name'
      />
      {addressType === 'home' && hasHadPostalCode && (
        <SelectFormField
          required={required}
          label='City & Locality'
          options={Array.from(
            localityGnisCodes.map((loc) => ({
              label: loc.gnisCaption,
              value: loc.gnisCode
            }))
          )}
          disabled={!values.address && isUsingAddressAutocomplete}
          selectedVal={values.localityGnisCode}
          onChange={(value) => setFieldValue('localityGnisCode', value)}
          id={`${addressType}-locality-gnis`}
          errorText={errorDetails.localityGnisCode}
        />
      )}
    </div>
  )
}
