import makeBareApi from './makeBareApi'

import makeHrApi from './makeHrApi'
import type { HrClient } from './makeHrApi'

import makeLoginApi from './makeLoginApi'
import type { LoginClient } from './makeLoginApi'

import makeRestApi from './makeRestApi'
import type { RestClient } from './makeRestApi'

type Args = {
  hrClient: HrClient
  loginClient: LoginClient
  restClient: RestClient
}

const makeApi = ({ restClient, hrClient, loginClient }: Args) => {
  const bareApi = makeBareApi()
  const hrApi = makeHrApi(hrClient)
  const loginApi = makeLoginApi(loginClient)
  const restApi = makeRestApi(restClient)

  const api = {
    ...bareApi,
    ...hrApi,
    ...loginApi,
    ...restApi
  }

  return api
}

export default makeApi
