/**
 * Gets the value from a signed cookie in the format of s:<value>.<value-hash>
 */

const getSignedCookieValue = (cookie: unknown) => {
  if (typeof cookie === 'string') {
    return cookie?.match(/^s:(.*)\..*$/)?.[1] ?? ''
  }
  return ''
}

export { getSignedCookieValue }
