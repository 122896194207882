import * as React from 'react'
import { MenuItemSkeleton } from './MenuItem'
import Skeleton from 'react-loading-skeleton'
import { FormContainer } from './FormContainer'
import { useEmployee, useIsViewingSelf, useHideEmail } from '../../../hooks'
import { BaseCard } from '@toasttab/buffet-pui-card'
import {
  useAccountUserQuery,
  useActorCanResetPassword,
  useActorCanModifyEmail,
  useSendWelcomeEmailMutation,
  useAccountResetPasswordMutation
} from '../hooks'
import { EmailSuffixIcon } from './EmailSuffixIcon'
import { Button } from '@toasttab/buffet-pui-buttons'
import { EmailChangeModal } from './EmailChangeModal'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useOperationRequestHandlers } from '@local/ec-app/apollo'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { PasswordChangeModal } from './PasswordChangeModal'
import { useTranslation } from 'react-i18next'
import { getDisplayEmail } from '../../../utils'

const PendingAccountMenu = () => (
  <FormContainer title='Account'>
    <div>
      <div className='flex items-center justify-center mb-5'>
        <div className='type-large'>
          <div className='flex mr-8'>
            <Skeleton width={15} height={15} />
            <div className='ml-3'>
              <Skeleton width={80} height={15} />
            </div>
          </div>
        </div>
        <div className='text-center type-large'>
          <div className='flex'>
            <Skeleton width={15} height={15} />
            <div className='ml-3'>
              <Skeleton width={80} height={15} />
            </div>
          </div>
        </div>
      </div>
      <MenuItemSkeleton />
      <MenuItemSkeleton />
    </div>
  </FormContainer>
)

interface PasswordCardSectionProps {
  headerText: string
  descriptionText: string
  buttonText: string
  disableButton: boolean
  onClick: () => void
}

const PasswordCardSection = ({
  headerText,
  descriptionText,
  buttonText,
  onClick,
  disableButton,
  testId
}: PasswordCardSectionProps & TestIdentifiable) => {
  return (
    <section
      className='pt-6 mt-6 border-t-2'
      data-testid={`${testId}-password-card-section`}
    >
      <div className='flex flex-row items-center justify-between'>
        <div>
          <div className='mb-1 font-semibold'>{headerText}</div>
          <div className='flex flex-row space-x-1 text-secondary'>
            {descriptionText}
          </div>
        </div>

        <div>
          <Button
            size='auto'
            variant='link'
            disabled={disableButton}
            onClick={onClick}
            data-testid={`${testId}-password-submit`}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </section>
  )
}

const AccountMenu = () => {
  const { t } = useTranslation('employees')
  const employee = useEmployee()
  const { accountUser } = useAccountUserQuery()
  const isViewingSelf = useIsViewingSelf()
  const [isEmailModalOpen, setIsEmailModalOpen] = React.useState<boolean>(false)
  const [isPasswordModalOpen, setIsPasswordModalOpen] =
    React.useState<boolean>(false)
  const canModifyEmail = useActorCanModifyEmail()
  const { mutateAsync: sendWelcomeEmailMutation, status: welcomeEmailStatus } =
    useSendWelcomeEmailMutation()
  const { withAll, withSuccessSnackbar, withErrorSnackbar } =
    useOperationRequestHandlers()
  const canResetPassword = useActorCanResetPassword()
  const { mutateAsync: resetPasswordMutation, status: resetPasswordStatus } =
    useAccountResetPasswordMutation()
  const { showErrorSnackBar } = useSnackBar()

  const closeEmailModal = () => setIsEmailModalOpen(false)
  const openEmailModal = () => setIsEmailModalOpen(true)

  const closePasswordModal = () => setIsPasswordModalOpen(false)
  const openPasswordModal = () => setIsPasswordModalOpen(true)

  const sendWelcomeEmail = () => {
    sendWelcomeEmailMutation().then(
      withAll(withSuccessSnackbar(t('emailSendSuccess'))),
      withErrorSnackbar({
        genericErrorMessage: t('emailSendFailure')
      })
    )
  }

  const sendResetPassword = () => {
    if (employee?.overview?.emailAddress) {
      resetPasswordMutation(employee.overview.emailAddress).then(
        withAll(withSuccessSnackbar(t('resetPasswordSuccess'))),
        withErrorSnackbar({
          genericErrorMessage: t('resetPasswordFailure')
        })
      )
    } else {
      showErrorSnackBar(t('employeeIsLoading'))
    }
  }

  const PasswordComponent = () => {
    if (isViewingSelf && !accountUser.isToastIdentityLinked()) {
      return (
        <PasswordCardSection
          headerText={t('password')}
          descriptionText={t('changePasswordDescription')}
          buttonText={t('changePassword')}
          onClick={openPasswordModal}
          disableButton={false}
          testId='update'
        />
      )
    } else if (
      !isViewingSelf &&
      canResetPassword &&
      !accountUser.isEmailVerified
    ) {
      return (
        <PasswordCardSection
          headerText={t('employeeInvite')}
          descriptionText={t('welcomeEmailDescription')}
          buttonText={t('resend')}
          onClick={sendWelcomeEmail}
          disableButton={welcomeEmailStatus === 'loading'}
          testId='welcome-email'
        />
      )
    } else if (!isViewingSelf && canResetPassword) {
      return (
        <PasswordCardSection
          headerText={t('resetPassword')}
          descriptionText={t('resetPasswordDescription')}
          buttonText={t('sendResetPasswordLink')}
          onClick={sendResetPassword}
          disableButton={resetPasswordStatus === 'loading'}
          testId='reset'
        />
      )
    } else {
      return null
    }
  }

  const EmailChangeBaseComponent = () => {
    const { t } = useTranslation('employees')
    const displayEmail = useHideEmail()
      ? getDisplayEmail(employee.overview.emailAddress)
      : employee.overview.emailAddress
    if (!accountUser.email) {
      return (
        <section>
          <Alert showIcon testId='unverified_employee_alert' variant='error'>
            <div className='flex flex-row items-center justify-between'>
              <p>{t('noEmailInSystemError')}</p>
              <Button
                variant='link'
                onClick={openEmailModal}
                data-testid='add-email'
              >
                {t('add')}
              </Button>
            </div>
          </Alert>
        </section>
      )
    } else {
      return (
        <>
          {!isViewingSelf && !accountUser.isEmailVerified && (
            <section className='mb-6'>
              <Alert
                showIcon
                testId='unverified-employee-alert'
                variant='warning'
              >
                {t('alertEmployeeUnverified')}
              </Alert>
            </section>
          )}

          {accountUser.showUsername() ? (
            <section className='mb-6' data-testid='account-username'>
              <div className='mb-1 font-semibold'>{t('userName')}</div>
              <div className='flex flex-row space-x-1'>
                <div>{accountUser.username}</div>
              </div>
            </section>
          ) : null}

          <section data-testid='account-email'>
            <div className='flex flex-row items-center justify-between'>
              <div>
                <div className='mb-1 font-semibold'>
                  {accountUser.email === accountUser.username
                    ? t('emailOrUsername')
                    : t('email')}
                </div>
                <div className='flex flex-row space-x-1'>
                  <div>{displayEmail ? displayEmail : t('noEmailOnFile')}</div>
                  <EmailSuffixIcon />
                </div>
              </div>

              {canModifyEmail && (
                <Button
                  data-testid='update-email'
                  onClick={openEmailModal}
                  size='auto'
                  variant='link'
                >
                  {t('update')}
                </Button>
              )}
            </div>
          </section>

          <PasswordComponent />
        </>
      )
    }
  }

  return (
    <>
      <EmailChangeModal isOpen={isEmailModalOpen} onClose={closeEmailModal} />
      <PasswordChangeModal
        isOpen={isPasswordModalOpen}
        onClose={closePasswordModal}
      />
      <div data-testId='account-menu-page' className='w-full'>
        <BaseCard>
          <section className='mb-6'>
            <div className='mb-4 font-bold md:mb-2 type-headline-5 text-default'>
              {t('employeeLogin')}
            </div>

            {accountUser.email && (
              <div className='text-secondary'>
                {isViewingSelf
                  ? t('editEmployeeAccount')
                  : t('editToastPayrollAccountInfo')}
              </div>
            )}
          </section>
          <EmailChangeBaseComponent />
        </BaseCard>
      </div>
    </>
  )
}

export const AccountMenuPage = () => (
  <React.Suspense fallback={<PendingAccountMenu />}>
    <AccountMenu />
  </React.Suspense>
)
