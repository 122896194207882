import * as React from 'react'
import cx from 'classnames'
import { DashboardCard, DashboardCardProps } from '@toasttab/buffet-pui-card'
import { ChevronRightIcon, IconProps } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { TrackId } from '@local/track'

export interface StatusOverviewCardProps extends DashboardCardProps {
  'data-toast-track-id'?: TrackId
  onRehireActionClick?: () => void
  onStatusActionClick?: () => void
  cardTitle?: string
  terminated: boolean
  iconType?: React.FunctionComponentElement<IconProps>
}

export const StatusOverviewCard = ({
  onRehireActionClick,
  onStatusActionClick,
  terminated,
  cardAction: propCardAction,
  children,
  cardTitle,
  iconType = (
    <ChevronRightIcon
      aria-label='status'
      className='text-secondary !p-1'
      data-testid='overview-card-edit'
    />
  ),
  className,
  'data-toast-track-id': dataToastTrackId,
  ...props
}: React.PropsWithChildren<StatusOverviewCardProps>) => {
  const title =
    terminated && onStatusActionClick ? (
      <div className='flex items-center'>
        {cardTitle}
        <IconButton
          data-toast-track-id={dataToastTrackId}
          className='m-3 !p-0'
          icon={
            <ChevronRightIcon
              aria-label='status'
              className='text-secondary !p-1'
              data-testid='overview-card-edit'
            />
          }
          onClick={() => onStatusActionClick()}
          textClassName='text-secondary'
        />
      </div>
    ) : (
      cardTitle
    )

  // This can be overwritten if need be...
  // There is one use case where we don't use a default chevron...
  const cardAction =
    propCardAction ??
    (onRehireActionClick ? (
      <IconButton
        data-toast-track-id={dataToastTrackId}
        className='-m-3 !p-0'
        icon={iconType}
        onClick={() => onRehireActionClick()}
        textClassName='text-secondary'
      />
    ) : undefined)

  return (
    <DashboardCard
      {...props}
      cardTitle={title}
      className={cx('h-full bg-white', className)}
      cardAction={cardAction}
    >
      {children}
    </DashboardCard>
  )
}
