import * as React from 'react'
import Skeleton from 'react-loading-skeleton'

export const MenuButton = ({ children }: React.PropsWithChildren<{}>) => (
  <div className='p-5 mb-6 bg-white border rounded-sm'>{children}</div>
)

export interface MenuItemProps {
  instructions: string
  testId?: string
}

export const MenuItem = ({
  children,
  instructions,
  testId = 'account'
}: React.PropsWithChildren<MenuItemProps>) => {
  return (
    <MenuButton>
      <div data-testid={`${testId}-menu-item`}>
        <div className='block pb-4 headline-3 font-bold cursor-pointer text-primary-50'>
          {children}
        </div>
        <div>{instructions}</div>
      </div>
    </MenuButton>
  )
}

export const MenuItemSkeleton = () => (
  <MenuButton>
    <div className='mb-6'>
      <Skeleton width={300} height={25} />
    </div>
    <Skeleton height={15} />
  </MenuButton>
)
