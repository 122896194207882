import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useFeature } from '@toasttab/ec-features'
import { LegacyPageBackHeader } from '@local/legacy-page-back-header'
import ReauthContainer from './reauth/ReauthContainer'
import { useCompanyCode, useEmployeeId } from '../../hooks'
import { ERROR_STATE } from '../../../error/ErrorStates'
import { useAccountUserQuery } from '../../profile/account/hooks'
import { useNavigateTo } from '../../../navigation/hooks'
import { User } from '../../profile/account/Domain'

export const PaymentMethodBackHeader: React.FunctionComponent = () => {
  const { accountUser } = useAccountUserQuery()
  const { t } = useTranslation('employees')

  const isPaymentMethodEnabled = useFeature(
    'ec-hr-pay-preferences-enabled',
    true
  )

  const employeeId = useEmployeeId()
  const companyCode = useCompanyCode()

  const paymentMethodPath = `/${companyCode}/employees/${employeeId}/paymentMethod`

  if (User.isUserMissingCriticalInfo(accountUser)) {
    throw new Error(ERROR_STATE.USER_INFO_NEEDED_ERROR)
  }

  const navigateTo = useNavigateTo()
  const handleCancel = () => {
    navigateTo(`/${companyCode}/employees/${employeeId}/employment`, false)
  }
  return (
    <ReauthContainer onCancel={handleCancel}>
      {isPaymentMethodEnabled && (
        <LegacyPageBackHeader
          testId='payment-method-back-header'
          url={paymentMethodPath}
          text={t('backToPaymentMethod')}
        />
      )}
    </ReauthContainer>
  )
}
