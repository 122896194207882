import * as yup from 'yup'
import { TelephoneNumber } from './TelephoneNumber'

export class Contact {
  static schema = yup
    .object({
      primaryTelephone: TelephoneNumber.schema
        .nullable()
        .default(null)
        .defined()
    })
    .defined()

  static formSchema = yup
    .object({
      primaryTelephone: TelephoneNumber.schema
        .nullable()
        .required('Primary telephone is required')
    })
    .required()

  static of(obj: any): Contact {
    return this.copyOf(Contact.schema.validateSync(obj, { abortEarly: false }))
  }

  static copyOf(contact: Contact): Contact {
    return new Contact(contact.primaryTelephone)
  }

  private constructor(readonly primaryTelephone: TelephoneNumber | null) {}
}
