import * as React from 'react'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { VisibilityOffIcon, VisibilityOnIcon } from '@toasttab/buffet-pui-icons'

export type PeekableFieldProps = {
  title: string
  secret: string
  hidden?: boolean
  mask: string
}

type ActionTypes = { type: 'HIDE' } | { type: 'SHOW' }

const PeekableFieldReducer = (
  state: { value: string; hidden: boolean; secret: string; mask: string },
  action: ActionTypes
) => {
  switch (action.type) {
    case 'HIDE':
      return { ...state, value: state.mask, hidden: true }
    case 'SHOW':
      return { ...state, value: state.secret, hidden: false }
    default:
      throw new Error('unhandled action type')
  }
}

export default ({ title, secret, hidden = true, mask }: PeekableFieldProps) => {
  const [state, dispatch] = React.useReducer(PeekableFieldReducer, {
    mask,
    secret,
    hidden,
    value: mask
  })
  return (
    <div className='flex w-full mb-3 sm:w-1/2'>
      <div className='flex items-start flex-1 font-bold uppercase text-darken-56 type-caption'>
        {title}
      </div>
      <div className='flex items-center justify-start flex-1'>
        <div>
          <span className={`no-print tabular-nums`}>{state.value}</span>
          <span className='print-only'>{state.secret}</span>
        </div>

        {state.hidden ? (
          <IconButton
            className='mr-2 -my-4'
            icon={<VisibilityOnIcon />}
            onClick={() => dispatch({ type: 'SHOW' })}
          />
        ) : (
          <IconButton
            className='mr-2 -my-4'
            icon={<VisibilityOffIcon />}
            onClick={() => dispatch({ type: 'HIDE' })}
          />
        )}
      </div>
    </div>
  )
}
