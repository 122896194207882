import * as React from 'react'
import { OverviewRow } from './OverviewRow'
import { ExampleOverviewCardLoader, OverviewCard } from './components'
import Skeleton from 'react-loading-skeleton'

export const EmploymentOverviewAppSuspenseBoundary = ({
  children
}: React.PropsWithChildren<{}>) => {
  return (
    <React.Suspense fallback={<SuspendedEmploymentOverviewApp />}>
      {children}
    </React.Suspense>
  )
}

export const SuspendedEmploymentOverviewApp = () => {
  return (
    <div
      data-testid='suspended-employment-overview'
      className='grid grid-cols-1 gap-4 w-full'
    >
      <OverviewRow>
        <OverviewCard cardTitle={<Skeleton width='160px' />}>
          <ExampleOverviewCardLoader />
        </OverviewCard>
        <OverviewCard cardTitle={<Skeleton width='160px' />}>
          <ExampleOverviewCardLoader />
        </OverviewCard>
      </OverviewRow>
      <OverviewRow>
        <OverviewCard cardTitle={<Skeleton width='160px' />}>
          <ExampleOverviewCardLoader />
        </OverviewCard>
      </OverviewRow>
    </div>
  )
}
