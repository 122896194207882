import * as React from 'react'
import { isApolloError } from '@apollo/client'
import { useSnackBar, SnackBarOptions } from '@toasttab/buffet-pui-snackbars'
import {
  LazyFunction,
  LazyValue,
  makeOptionsProvider
} from '@local/shared-services'
import { useMemoizedObject } from '@toasttab/buffet-utils'
import { OperationError } from './OperationError'
import over from 'lodash/over'

export const GENERIC_SERVER_ERROR = 'An error occurred. Please try again.'

type SnackBarMessage = string | React.ReactNode

export interface OperationRequestHandlersConfig {
  errorSnackBarOptions: SnackBarOptions
  successSnackBarOptions: SnackBarOptions
  genericErrorMessage: LazyFunction<SnackBarMessage, [Error]>
  extractOperationErrorMessages: (
    operationError: OperationError
  ) => SnackBarMessage | null
}

const unknownError = ['Oops. Something went wrong.']
/**
 * Converts the `OperationError` to an array of strings. If the error is already an array of strings, it will return it.
 * Otherwise, it will look for a localized error from the API and map that to a string
 * @param operationError the error to convert
 */
const errorsToString = (operationError: OperationError): string[] => {
  const errors = operationError.result?.errors
  if (Array.isArray(errors)) {
    return errors.flatMap((err) => {
      if (typeof err === 'string') {
        return [err]
      } else {
        return (
          err.extensions?.response?.body?.errors.map((err: any) =>
            err.toString()
          ) || unknownError
        )
      }
    })
  } else {
    return unknownError
  }
}

const getOperationErrorMessage = (operationError: OperationError) => {
  if (OperationError.hasUserErrors(operationError)) {
    const errors = errorsToString(operationError)

    return errors.length > 1 ? (
      <ul>
        {errors.map((err) => (
          <li key={err}>- {err}</li>
        ))}
      </ul>
    ) : (
      errors[0] ?? null
    )
  }

  return null
}

const [
  OperationRequestHandlersProvider,
  useOperationRequestHandlerOptions,
  OperationRequestHandlersContext
] = makeOptionsProvider<OperationRequestHandlersConfig>({
  genericErrorMessage: GENERIC_SERVER_ERROR,
  extractOperationErrorMessages: getOperationErrorMessage,
  errorSnackBarOptions: {},
  successSnackBarOptions: {}
})

export {
  OperationRequestHandlersProvider,
  useOperationRequestHandlerOptions,
  OperationRequestHandlersContext
}

/**
 * Returns a map of handlers for handling operation success/errors scenarios.
 * These handlers can be configured by the `OperationRequestHandlersProvider`
 * and can be scoped to override certain values anywhere in a component tree.
 *
 * The main goal of these handlers is to have consistent request handling
 * throughout the application.
 *
 * Multiple handlers can be combined using the `withAll` function.
 *
 * @example
 * const {
 *   withSuccessSnackbar,
 *   withErrorSnackbar
 * } = useOperationRequestHandlers()
 *
 * myRequest().then(
 *   withSuccessSnackbar('Success!'),
 *   withErrorSnackbar({ genericErrorMessage: 'The request failed!' }))
 *
 * // Multiple handlers
 * myRequest().then(
 *   withAll(
 *     withSuccessSnackbar('Success!'),
 *     () => history.goto('/otherPage')))
 */
export const useOperationRequestHandlers = () => {
  const snackBarRef = React.useRef<ReturnType<typeof useSnackBar>>()
  const { genericErrorMessage, errorSnackBarOptions, successSnackBarOptions } =
    useOperationRequestHandlerOptions()

  snackBarRef.current = useSnackBar()

  /**
   * Displays a success snackbar on request sucess.
   */
  const withSuccessSnackbar: <TResponse>(
    message: LazyFunction<SnackBarMessage>,
    options?: SnackBarOptions & {
      onShow?: (message: SnackBarMessage, options: SnackBarOptions) => void
    }
  ) => (response: TResponse) => void = React.useCallback(
    (message, options = {}) =>
      (response) => {
        const {
          onShow = snackBarRef.current!.showSuccessSnackBar,
          ...localSnackBarOptions
        } = options
        const snackBarOptions = Object.assign(
          {},
          successSnackBarOptions,
          localSnackBarOptions
        )

        onShow(LazyValue.resolveFunction(message, response), snackBarOptions)
      },
    [snackBarRef, successSnackBarOptions]
  )

  /**
   * Displays an snackbar on request failure.
   * If the error contains server errors, those errors will be
   * displayed in a snackbar message. If there are no server errors,
   * the value of `genericErrorMessage` will be used. Server errors
   * take precedence.
   */
  const withErrorSnackbar = React.useCallback(
    (
        options: {
          genericErrorMessage?: OperationRequestHandlersConfig['genericErrorMessage']
        } & SnackBarOptions & {
            onShow?: (
              message: SnackBarMessage,
              options: SnackBarOptions
            ) => void
          } = {}
      ) =>
      (error: Error) => {
        const {
          genericErrorMessage: errorMessage = genericErrorMessage,
          onShow = snackBarRef.current!.showErrorSnackBar,
          ...localSnackBarOptions
        } = options
        let message: SnackBarMessage | null = null
        const snackBarOptions = Object.assign(
          {},
          errorSnackBarOptions,
          localSnackBarOptions
        )

        if (isApolloError(error)) {
          if (OperationError.is(error.networkError)) {
            message = getOperationErrorMessage(error.networkError)
          }
        } else if (OperationError.is(error)) {
          message = getOperationErrorMessage(error)
        }

        onShow(
          message ?? LazyValue.resolveFunction(errorMessage, error),
          snackBarOptions
        )
      },
    [snackBarRef, errorSnackBarOptions, genericErrorMessage]
  )

  return useMemoizedObject({
    withErrorSnackbar,
    withSuccessSnackbar,
    withAll: over
  })
}
