import * as React from 'react'
import { FieldArray } from 'formik'
import { DividingLine, Panel } from '@toasttab/buffet-pui-config-templates'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import {
  JobAssignment,
  MoneyInput,
  SalaryAllocation
} from '@local/generated/graphql'
import { TrackId } from '@local/track'
import { SalaryAllocationInput } from './SalaryAllocationInput'

export type SalaryAllocationPanelProps = {
  /** data-toast-track-id for the rate input field*/
  'data-toast-track-id'?: TrackId
  containerClassName?: string
  legend: string
  caption: string
  salary: MoneyInput
  salaryLabel: string
  allocationInputLabel: string
  allocations: Array<
    Pick<SalaryAllocation, 'id' | 'rate'> & {
      jobAssignment: Pick<JobAssignment, 'name' | 'locationName'>
    }
  >
  unsupportedCurrencyMessage: string
} & TestIdentifiable

export const SalaryAllocationPanel: React.FunctionComponent<
  SalaryAllocationPanelProps
> = ({
  'data-toast-track-id': dataToastTrackId,
  testId,
  containerClassName,
  legend,
  caption,
  salary,
  salaryLabel,
  allocationInputLabel,
  allocations,
  unsupportedCurrencyMessage
}) => {
  const { locale } = useBuffetContext()

  return (
    <Panel
      className={`text-default !p-6 ${containerClassName}`}
      noElevation
      testId={testId}
    >
      <div className='space-y-4 mb-8'>
        <div>
          <p className='flex items-center font-semibold'>{legend}</p>
          <p> {caption} </p>
        </div>
        <TotalAnnualSalary
          label={salaryLabel}
          value={formatCurrency(salary, locale)}
        />
      </div>
      <div className='mt-8'>
        <FieldArray
          name='salaryAllocations'
          render={() =>
            allocations?.map((allocation, index) => (
              <div key={index}>
                <div className='-my-2'>
                  <DividingLine />
                </div>
                <div className='space-y-4'>
                  <SalaryAllocationInputLabel
                    name={allocation.jobAssignment.name}
                    locationName={allocation.jobAssignment.locationName}
                  />
                  <SalaryAllocationInput
                    data-toast-track-id={dataToastTrackId}
                    testId={`allocations-input-${index}-rate`}
                    name={`salaryAllocations.${index}.rate.amount`}
                    rate={allocation.rate}
                    label={allocationInputLabel}
                    salaryCurrency={salary.currency}
                    unsupportedCurrencyMessage={unsupportedCurrencyMessage}
                  />
                </div>
              </div>
            ))
          }
        />
      </div>
    </Panel>
  )
}

type TotalAnnualSalaryProps = {
  label: string
  value: string
}

const TotalAnnualSalary: React.FunctionComponent<TotalAnnualSalaryProps> = ({
  label,
  value
}) => {
  return (
    <div className='space-y-1'>
      <div className='font-semibold'>{label}</div>
      <div className='type-headline-3 font-medium break-all'>{value}</div>
    </div>
  )
}

type SalaryAllocationInputLabelProps = {
  name: string
  locationName: string
}

const SalaryAllocationInputLabel: React.FunctionComponent<
  SalaryAllocationInputLabelProps
> = ({ name, locationName }) => {
  return (
    <div className='space-y-1'>
      <div className='font-semibold'>{name}</div>
      <div>{locationName}</div>
    </div>
  )
}
