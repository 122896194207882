import * as React from 'react'
import cx from 'classnames'
import {
  ActiveModifiers,
  DayModifiers,
  ModifiersClassNames
} from 'react-day-picker'
import { isToday } from 'date-fns'
import {
  DatePickerField,
  DatePickerFieldProps
} from '@toasttab/buffet-pui-forms'
import {
  FormValuesWithName,
  TypedName
} from '@toasttab/buffet-pui-forms/dist/types/commonTypes'
import { BuffetCaption } from '@toasttab/buffet-pui-calendar'
import { format } from '@toasttab/buffet-pui-date-utilities'
import { RemoveFields } from '@toasttab/buffet-shared-types'

type HighlightedDateVariant = 'dash' | 'solid' | 'fill'

export type HighlightedDatesSet = {
  label: string
  variant: HighlightedDateVariant
  highlightedDates: Date[]
}

export type DatePickerFieldHighlightedDatesProps<
  FormValues extends FormValuesWithName = string
> = {
  /** label for the current day */
  currentDayLabel: string
  /** date sets to highlight dates, such as paydays */
  highlightedDatesSets?: HighlightedDatesSet[]
} & RemoveFields<DatePickerFieldProps, 'name'> &
  TypedName<FormValues>

export const DatePickerFieldHighlightedDates = <
  FormValues extends FormValuesWithName = string
>(
  props: DatePickerFieldHighlightedDatesProps<FormValues>
) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
    props.value
  )
  const { highlightedDatesSets, currentDayLabel } = props
  const originalOnChange = props.onChange || props.onSelect

  const dayModifiers: DayModifiers = {}
  const modifiersClassNames: ModifiersClassNames = {}
  highlightedDatesSets?.forEach((highlightedDatesSet, index) => {
    dayModifiers[index] = highlightedDatesSet.highlightedDates
    modifiersClassNames[index] =
      highlightedDatesSet.variant === 'dash'
        ? 'border border-primary-75 border-dashed'
        : highlightedDatesSet.variant === 'solid'
        ? 'border border-primary-75'
        : ''
  })

  return (
    <DatePickerField
      {...props}
      showOutsideDays
      value={selectedDate}
      onChange={(day, selectedDay, activeModifiers, event) => {
        setSelectedDate(day)
        originalOnChange?.(day, selectedDay, activeModifiers, event)
      }}
      modifiers={dayModifiers}
      modifiersClassNames={{
        today: isToday(selectedDate!) ? '' : 'bg-primary-0',
        ...modifiersClassNames
      }}
      required={true}
      labels={{
        labelDay: (day: Date, activeModifiers: ActiveModifiers): string => {
          const ariaLabels = []
          highlightedDatesSets?.forEach((highlightedDatesSet, index) => {
            if (activeModifiers[index] === true) {
              ariaLabels.push(highlightedDatesSet.label)
            }
          })
          if (activeModifiers.today) {
            ariaLabels.push('Today')
          }
          const ariaLabelSuffix =
            ariaLabels.length > 0 ? ariaLabels.join(' - ') : undefined
          return `${format(day, 'do MMMM (EEEE)', props.locale)}${
            ariaLabelSuffix ? `- ${ariaLabelSuffix}` : ''
          }`
        }
      }}
      components={{
        Caption: (props) => (
          <div>
            <div className='flex flex-row flex-wrap type-caption mx-6 mt-3.5 pb-4 mb-4 gap-3 border-b'>
              {highlightedDatesSets?.map((highlightedDatesSet) => (
                <LegendEntry
                  key={highlightedDatesSet.label}
                  type={highlightedDatesSet.variant}
                >
                  {highlightedDatesSet.label}
                </LegendEntry>
              ))}
              <LegendEntry key={currentDayLabel} type='fill'>
                {currentDayLabel}
              </LegendEntry>
            </div>
            <BuffetCaption {...props} />
          </div>
        )
      }}
    />
  )
}

const LegendEntry: React.FunctionComponent<
  React.PropsWithChildren<{
    type: HighlightedDateVariant
  }>
> = ({ type, children }) => (
  <div className='flex flex-row'>
    <LegendSymbol type={type} />
    <div className='text-secondary ml-1.5'>{children}</div>
  </div>
)

const LegendSymbol: React.FunctionComponent<{
  type: HighlightedDateVariant
}> = ({ type }) => (
  <div
    className={cx(
      'inline-block align-middle rounded-full w-4 h-4',
      type === 'fill' ? 'bg-primary-0' : 'border border-primary-75',
      type === 'dash' && 'border-dashed'
    )}
  />
)
