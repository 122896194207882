import * as React from 'react'
import { useAccountUserQuery, useActorCanModifyEmail } from '../hooks'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import {
  CheckCircleSelectedIcon,
  InfoIcon,
  WarningOutlineIcon
} from '@toasttab/buffet-pui-icons'

export const EmailSuffixIcon = () => {
  const { accountUser } = useAccountUserQuery()

  /*
    A user can always edit their own email
    Before an email is verified, HR+ users and Admins can also modify it. Emails automatically verify when the user registers
    After an email is verified, only Admins and the user themself can modify it.
  */
  const canModifyEmail = useActorCanModifyEmail()

  if (accountUser.isEmailVerified) {
    if (canModifyEmail) {
      return (
        <CheckCircleSelectedIcon
          className='mr-2 text-secondary'
          testId='verified-email-check'
        />
      )
    }
    return (
      <Tooltip
        content={() =>
          'Only this employee or a Toast admin can edit this email.'
        }
      >
        <IconButton
          icon={
            <InfoIcon className='text-secondary' testId='verified-email-info' />
          }
          contained
        />
      </Tooltip>
    )
  }
  return (
    <WarningOutlineIcon
      className='mr-2 text-secondary'
      testId='verified-email-warning'
    />
  )
}
