import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  ChevronRightIcon,
  DownloadIcon,
  MoreHorizIcon
} from '@toasttab/buffet-pui-icons'
import { Pagination } from '@toasttab/buffet-pui-pagination'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import {
  Table,
  Row,
  Cell,
  HeadingCell,
  Head,
  Body
} from '@toasttab/buffet-pui-table'
import { ButtonGroup, IconButton } from '@toasttab/buffet-pui-buttons'
import { MenuDropdown } from '@toasttab/buffet-pui-dropdowns'

import { usePayHistoryPaging } from '../stores/PayHistoryStore'
import { FormattedPayHistoryRecord } from '../utils'

export const PaystubsTable = ({
  paystubs
}: {
  paystubs: Array<FormattedPayHistoryRecord>
}) => {
  const pagination = usePayHistoryPaging()
  const { t } = useTranslation('employees')

  return (
    <>
      <div className='space-y-3 border-b divide-y'>
        <Table variant='plain'>
          <Head>
            <HeadingCell>{t('checkDate')}</HeadingCell>
            <HeadingCell>{t('business')}</HeadingCell>
            <HeadingCell className='text-right'>{t('grossPay')}</HeadingCell>
            <HeadingCell className='text-right'>
              {`${t('takeHomePay')}*`}
            </HeadingCell>
            <HeadingCell></HeadingCell>
          </Head>
          <Body>
            {paystubs.map((record) => (
              <Row key={record.id}>
                <Cell data-testid='check-date'>
                  {record.formattedCheckDate}
                </Cell>

                <Cell data-testid='fein'>
                  {record.allBusinesses.length > 1 ? (
                    <Tooltip content={() => record.allBusinesses.join(', ')}>
                      {record.business}
                    </Tooltip>
                  ) : (
                    record.business
                  )}
                </Cell>
                <Cell className='text-right' data-testid='gross'>
                  {record.formattedGross}
                </Cell>
                <Cell className='text-right' data-testid='net'>
                  {record.formattedNet}
                </Cell>
                <Cell>
                  <div className='-mt-2'>
                    {record.view.length === 1 ? (
                      <ButtonGroup className='flex justify-end'>
                        <IconButton
                          data-testid='paystub-download'
                          as='a'
                          href={record.download[0]}
                          icon={<DownloadIcon className='text-secondary' />}
                        />

                        <IconButton
                          data-testid='paystub-link'
                          as='a'
                          href={record.view[0]}
                          icon={<ChevronRightIcon className='text-secondary' />}
                          target={'_blank'}
                        />
                      </ButtonGroup>
                    ) : (
                      <div className='flex justify-end'>
                        <MenuDropdown
                          renderToggle={(props) => (
                            <IconButton
                              {...props}
                              icon={
                                <MoreHorizIcon className='text-secondary' />
                              }
                            />
                          )}
                        >
                          {record.view.map((viewUrl, idx) => (
                            <ButtonGroup
                              className='flex items-center justify-evenly'
                              key={viewUrl}
                            >
                              <div className='flex-grow px-4 whitespace-nowrap'>
                                {record.name[idx]}
                              </div>
                              <IconButton
                                data-testid='paystub-download'
                                as='a'
                                href={record.download[idx]}
                                icon={
                                  <DownloadIcon className='text-secondary' />
                                }
                              />
                              <IconButton
                                data-testid='paystub-link'
                                as='a'
                                href={record.view[idx]}
                                icon={
                                  <ChevronRightIcon className='text-secondary' />
                                }
                                target={'_blank'}
                              />
                            </ButtonGroup>
                          ))}
                        </MenuDropdown>
                      </div>
                    )}
                  </div>
                </Cell>
              </Row>
            ))}
          </Body>
        </Table>
      </div>
      {pagination.showPagination ? (
        <div className='flex flex-col items-center'>
          <Pagination {...pagination} />
        </div>
      ) : null}
    </>
  )
}
