import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { formatPay } from '../employment/overview/jobs/components/utils'
import { Money } from '@local/generated/graphql'
import { ForkKnifeIcon } from '@toasttab/buffet-pui-icons'

interface Props {
  title: string | null
  rateOfPay: Money | null
}

export const PayrollJob: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation('employees')

  return (
    <div className='h-full justify-self-start flex items-center'>
      <div className='mr-3'>
        <ForkKnifeIcon />
      </div>
      <div className='self-start'>
        <div className='font-semibold type-caption text-gray-50 type-overline'>
          {t('payrollJob')}
        </div>
        <div className='font-semibold mb-1'>{props.title}</div>
        {props.rateOfPay?.amount >= 1000
          ? t('salaried')
          : formatPay(false, 'en-US', 'hr', props.rateOfPay)}
      </div>
    </div>
  )
}
