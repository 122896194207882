import * as React from 'react'
import { DataTable, DataTableSchema } from '@toasttab/buffet-datatable'
import { IconButton, Button } from '@toasttab/buffet-pui-buttons'
import { Modal } from '@toasttab/buffet-pui-modal'
import { RadioButton } from '@toasttab/buffet-pui-radio-group'
import {
  AddIcon,
  DeleteIcon,
  ChevronLeftIcon,
  EditIcon
} from '@toasttab/buffet-pui-icons'
import { FormContainer } from '../components'
import { EmergencyContact } from '../domain'
import {
  useUpdatePersonalProfile,
  useShowConfirmDeleteContact,
  useCancelContactChangeAction,
  useDeleteContact,
  usePersonalProfile,
  usePrimaryContact,
  useSetPrimaryContact,
  useManageContacts,
  useShowEditContact,
  useShowAddContact,
  useNavigateToProfilePage
} from '../hooks'
import { Objects } from '@local/shared-services'
import { useApi } from '../../../../ApiProvider'
import { useNavigate } from 'react-router-dom'

export const ManageEmergencyContacts = () => {
  const navigate = useNavigate()
  const onEdit = (contactId: string | number) => {
    navigate(`${contactId}`)
  }
  const onAddContact = () => {
    navigate('new-local')
  }
  const navigateToProfilePage = useNavigateToProfilePage()

  const personalProfile = usePersonalProfile()
  const updatePersonalProfile = useUpdatePersonalProfile()
  const showEditContact = useShowEditContact()
  const showAddContact = useShowAddContact()
  const { links } = personalProfile ?? {}
  const primaryContact = usePrimaryContact()
  const setPrimaryContact = useSetPrimaryContact()
  const showConfirmDeleteContact = useShowConfirmDeleteContact()
  const { showConfirmDelete, currentContacts } = useManageContacts()
  const cancelContactChange = useCancelContactChangeAction()
  const deleteContact = useDeleteContact()
  const api = useApi()

  const onDone = async () => {
    if (links) {
      await api.saveEmergencyContacts(links.emergencyContacts, currentContacts)

      updatePersonalProfile({ emergencyContacts: currentContacts })

      navigateToProfilePage()
    }
  }

  const firstNameColumn = {
    label: 'Name',
    renderCol: (
      _firstName: string | undefined,
      emergencyContact: EmergencyContact
    ) => `${emergencyContact.firstName} ${emergencyContact.lastName}`
  }

  const editDeleteColumn = {
    label: '',
    renderCol: (_id: number | null, emergencyContact: EmergencyContact) => (
      <>
        <IconButton
          icon={<EditIcon />}
          onClick={() => {
            const id = emergencyContact.emergencyContactId
            showEditContact(emergencyContact)
            if (id) onEdit(id)
          }}
        />
        <IconButton
          icon={<DeleteIcon />}
          onClick={() => showConfirmDeleteContact(emergencyContact)}
          data-testid={`${emergencyContact.firstName}${emergencyContact.lastName}-delete-button`}
        />
      </>
    )
  }

  const schema: DataTableSchema<EmergencyContact> = {
    firstName: firstNameColumn,
    relation: {
      label: 'Relationship'
    },
    primaryTelephone: {
      label: 'Phone'
    },
    isPrimary: {
      label: 'Primary Contact',
      renderCol: (
        _isPrimary: boolean | undefined,
        emergencyContact: EmergencyContact
      ) => (
        <RadioButton
          label='Primary Contact'
          hideLabel
          checked={Objects.isShallowEqualTo(primaryContact, emergencyContact, {
            keys: ['firstName', 'lastName', 'email']
          })}
          onChange={() => setPrimaryContact(emergencyContact)}
          name='isPrimary'
          testId={`${emergencyContact.firstName}${emergencyContact.lastName}-primary-radio`}
        />
      )
    },
    emergencyContactId: editDeleteColumn
  }

  const mobileSchema: DataTableSchema<EmergencyContact> = {
    firstName: firstNameColumn,
    emergencyContactId: editDeleteColumn
  }

  // This is light years faster than the default data table hash algorithm...
  const getRowKey = (contact: EmergencyContact) => JSON.stringify(contact)

  const headerText = (
    <>
      <IconButton
        aria-label='Back'
        icon={<ChevronLeftIcon />}
        onClick={navigateToProfilePage}
      />
      Manage Emergency Contacts
    </>
  )

  return (
    <>
      <FormContainer headerText={headerText}>
        <div className='mt-0 font-bold type-headline-5'>
          Emergency Contact List
        </div>
        <div className='py-6'>
          <div className='hidden md:block'>
            <DataTable
              schema={schema}
              data={currentContacts}
              getRowKey={getRowKey}
            />
          </div>
          <div className='md:hidden'>
            <DataTable
              schema={mobileSchema}
              data={currentContacts}
              getRowKey={getRowKey}
            />
          </div>
        </div>
        <div className='flex justify-between'>
          <Button
            iconLeft={<AddIcon />}
            variant='secondary'
            onClick={() => {
              showAddContact()
              onAddContact()
            }}
          >
            Add Contact
          </Button>
          <div className='float-right'>
            <Button onClick={onDone} testId='edit-emergency-contact-done-btn'>
              Done
            </Button>
          </div>
        </div>
      </FormContainer>

      {showConfirmDelete && (
        <Modal isOpen>
          <Modal.Header>Remove Emergency Contact</Modal.Header>
          <Modal.Body>
            Are you sure you'd like to remove this employee's emergency contact?
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => cancelContactChange()}>
              Cancel
            </Button>
            <Button
              iconRight={<DeleteIcon />}
              onClick={() => deleteContact()}
              data-testid='remove-emergency-contact-modal-button'
            >
              Remove emergency contact
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}
